import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { APIService } from '../../../../../api/api.service';
import { NotifierService } from '../../../../../_services/notifier.service';

declare let window: any;

@Component({
  //moduleId: module.id.toString(),
  selector: 'sg-reports',
  templateUrl: './sg.view.reports.component.html',
})
export class SGReportsComponent implements OnInit, OnDestroy {
  @Input() sgFilter: any;
  @Input() filterRequired: boolean;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  sgList: any = [];

  funRef: any;
  innerData: any = null;

  accountId: any;
  regionId: any;
  currentMessage: any;

  sgheader: any = [
    {
      id: 'sg_id',
      name: 'Security Group ID',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'loadInnerData',
    },
    {
      id: 'sg_name',
      name: 'Security Group Name',
    },
    { id: 'vpc_id', name: 'VPC ID' },
    { id: 'inbound_count', name: 'Inbound Rules Count' },
    { id: 'outbound_count', name: 'Outbound Rules Count' },
  ];

  sgInnerHeader: any = [
    {
      id: 'protocol',
      name: 'Protocol',
    },
    {
      id: 'FromPort',
      name: 'Port Range',
    },
    {
      id: 'ipv4',
      name: 'Source IPV4',
    },
    {
      id: 'ipv6',
      name: 'Source IPV6',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.funRef = {
      loadInnerData: this.loadInnerData,
    };

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    await this.loadSGs();
    this.notifier.loading(false);
  }

  async loadSGs(nextToken: any = '') {
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      account_id: this.accountId,
      region: this.regionId,
      output_format: 'json',
      portal: 'swayam',
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/reports/securitygroup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.sgList = result.message;
      if (this.filterRequired) {
        this.sgList = this.sgList.filter((sg: any) => {
          return this.sgFilter.includes(sg.sg_id);
        });
      }
      this.sgList.forEach((sg: any) => {
        sg.inbound_count = sg.inbound_rules.length;
        sg.outbound_count = sg.outbound_rules.length;
      });
    } else {
      this.notifier.alert('Info', '', result.message, 'info', 5000);
    }
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel('sgList', 'Security Groups', 'csv');
  }

  async loadInnerData(ref: any, id: any) {
    ref.innerData = id;
  }

  goBack() {
    this.innerData = null;
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
