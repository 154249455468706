import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

import * as moment from 'moment';
declare let window: any;

@Component({
  selector: 'ec2-billing',
  templateUrl: 'ec2.billing.component.html',
  styleUrls: ['./../../billing.component.css'],
})
export class EC2BillingComponent implements OnInit, OnDestroy, OnChanges {
  @Input('activeAccount') activeAccount: any;
  @Input('monthYear') monthYear: any;
  @Input('diff_days') diff_days: any;

  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;

  ec2Data: any = [];
  ec2Show: boolean = false;
  projectTagNameEC2: boolean = false;
  mapMigratedEC2: boolean = false;
  projectTagNameEBS: boolean = false;
  mapMigratedEBS: boolean = false;
  ec2Headers: any = [
    {
      id: 'masterAccountId',
      name: 'Master account ID',
      export: true,
    },
    {
      id: 'lid_instanceId',
      name: 'Instance ID',
      export: true,
    },
    {
      id: 'instance_name',
      name: 'Tag Name',
      export: true,
    },
    {
      id: 'projectTagName',
      name: 'Project Tag Name',
      export: true,
      displayConditional: true,
    },
    {
      id: 'mapMigrated',
      name: 'Map Migrated Tag Name',
      export: true,
      displayConditional: true,
    },
    {
      id: 'admin_instance_type',
      name: 'Instance Type',
      export: true,
    },
    {
      id: 'cpu',
      name: 'CPU',
      export: true,
    },
    {
      id: 'ram',
      name: 'Ram',
      export: true,
    },
    {
      id: 'os',
      name: 'OS',
      export: true,
    },
    {
      id: 'region',
      name: 'Region',
      export: true,
    },
    {
      id: 'RI',
      name: 'RI Applied',
      export: true,
    },
    {
      id: 'billingType',
      name: 'Billing Type',
      export: true,
      alter: (value: any, param: any) => {
        let billingType: string = '';
        if (value[param] == 'Dis-RI') {
          billingType = 'OD-Discount';
        } else if (value[param] == 'M-RI') {
          billingType = 'M-Reserved';
        } else if (value[param] == 'Customer RI') {
          billingType = 'Cust-Reserved';
        } else {
          billingType = value[param];
        }
        return billingType;
      },
    },
    {
      id: 'hourly_cost',
      name: 'AWS Cost/Hr',
      export: false,
      alter: (value: any, param: string) => {
        if (value[param] == 'null' || value['billingType'] == 'Dis-RI') {
          return '-';
        }
        if (value['RI'] && value['RI'].toLowerCase() == 'yes') {
          if (value['billingType'] != 'unUsedCustomerRI') {
            if (value['billingType'] == 'Customer RI(Unused RI)') {
              return `<span>${Number(value['riHourlyCost']).toFixed(4)}</span>`;
            } else {
              return `<span class='line_through'>${Number(value[param]).toFixed(
                4
              )}</span> <span>${Number(value['riHourlyCost']).toFixed(
                4
              )}</span>`;
            }
          } else {
            return Number(value['riHourlyCost'].toFixed(4));
          }
        } else {
          return Number(value[param]).toFixed(4);
        }
      },
    },
    {
      id: 'hourly_cost',
      name: 'AWS Cost/Hr',
      export: true,
      hide: true,
      alter: (value: any, param: string) => {
        if (value[param] == 'null' || value['billingType'] == 'Dis-RI') {
          return '-';
        }
        if (value['RI'] && value['RI'].toLowerCase() == 'yes') {
          if (value['billingType'] != 'unUsedCustomerRI') {
            return `<span>${Number(value[param]).toFixed(4)}</span>`;
          } else {
            return Number(value['riHourlyCost'].toFixed(4));
          }
        } else {
          return Number(value[param]).toFixed(4);
        }
      },
    },
    {
      id: 'total_hours',
      name: 'AWS Total Hours',
      export: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? '-' : Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'total_cost',
      name: 'AWS Total Cost',
      export: false,
      alter: (value: any, param: string) => {
        if (isNaN(value[param])) {
          return '-';
        } else if (value['RI'] && value['RI'].toLowerCase() == 'yes') {
          if (value['billingType'] != 'unUsedCustomerRI') {
            if (value['billingType'] == 'Customer RI(Unused RI)') {
              return `<span>${Number(value['riTotalCost']).toFixed(4)}</span>`;
            } else {
              return `<span class='line_through'>${Number(value[param]).toFixed(
                4
              )}</span> <span>${Number(value['riTotalCost']).toFixed(
                4
              )}</span>`;
            }
          } else {
            return Number(value['riTotalCost'].toFixed(4));
          }
        } else {
          return Number(value[param]).toFixed(4);
        }
      },
    },
    {
      id: 'total_cost',
      name: 'AWS Total Cost',
      hide: true,
      export: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? '-' : Number(value[param]).toFixed(4);
      },
    },
    {
      id: 'riHourlyCost',
      name: 'RI Rate',
      export: true,
      hide: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? '-' : Number(value[param]).toFixed(4);
      },
    },
    {
      id: 'riTotalCost',
      name: 'Final Total Cost',
      hide: true,
      export: true,
      alter: (value: any, param: string) => {
        return value['RI'] && value['RI'].toLowerCase() == 'yes'
          ? value['billingType'] == 'unUsedCustomerRI'
            ? `${Number(value['total_cost']).toFixed(4)}`
            : Number(value[param]).toFixed(4)
          : Number(value['total_cost']).toFixed(4);
      },
    },
  ];
  ebsHeaders: any = [
    {
      id: 'lid_volumeId',
      name: 'Volume ID',
      export: true,
    },
    {
      id: 'volume_name',
      name: 'Tag Name',
      export: true,
    },
    {
      id: 'projectTagNameEBS',
      name: 'Project Tag Name',
      export: true,
      displayConditional: true,
    },
    {
      id: 'mapMigratedEBS',
      name: 'Map Migrated Tag Name',
      export: true,
      displayConditional: true,
    },
    {
      id: 'volume_type',
      name: 'Volume Type',
      export: true,
    },
    {
      id: 'gb_cost',
      name: 'GB Cost',
      export: true,
      alter: (value: any) => {
        return isNaN(value) ? '0' : Number(value).toFixed(8);
      },
    },
    {
      id: 'total_storage',
      name: 'Total Storage',
      export: true,
      alter: (value: any) => {
        return isNaN(value) ? '0' : Number(value).toFixed(8);
      },
    },
    {
      id: 'total_cost',
      name: 'Total Cost',
      export: true,
      alter: (value: any) => {
        return isNaN(value) ? '0' : Number(value).toFixed(8);
      },
    },
  ];
  othersHeaders: any = [
    {
      id: 'lid_resourceId',
      name: 'Resource ID',
      export: true,
    },
    {
      id: 'resource_name',
      name: 'Resource Name',
      export: true,
    },
    {
      id: 'total_Gb_or_Cost',
      name: 'Total GB',
      export: true,
      alter: (value: any) => {
        return Number(value).toFixed(2);
      },
    },
    {
      id: 'total_cost',
      name: 'Total GB Cost',
      export: true,
      alter: (value: any) => {
        return Number(value).toFixed(2);
      },
    },
  ];

  ebsData: any = [];
  ebsShow: boolean = false;

  othersData: any = [];
  othersShow: boolean = false;

  accountId: any;
  regionId: any;
  currentMessage: any;
  page_destroyed: boolean = false;

  showConditionalElement = false;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeAccount) {
      this.nextTokens = {
        ec2: null,
        ebs: null,
        others: null,
      };
      this.ec2Data = [];
      this.ebsData = [];
      this.othersData = [];
      if (this.ec2Show) {
        this.loadEC2Data(true);
      }
      if (this.ebsShow) {
        this.loadEBSData(true);
      }
      if (this.othersShow) {
        this.loadOthersData(true);
      }
    }
  }

  ngOnInit(): void {
    if (!this.monthYear || !this.activeAccount) {
      this.notifier.alert('Info', '', 'Failed to Load Data', 'info', 5000);
    }
  }

  nextTokens: any = {
    ec2: null,
    ebs: null,
    others: null,
  };

  getValue(value: string) {
    if (value == 'projectTagName') {
      return this.projectTagNameEC2;
    }
    if (value == 'mapMigrated') {
      return this.mapMigratedEC2;
    }
    if (value == 'projectTagNameEBS') {
      return this.projectTagNameEBS;
    }
    if (value == 'mapMigratedEBS') {
      return this.mapMigratedEBS;
    }
    return false;
  }

  filterHeaders(header: any) {
    return header.filter((head: any) => {
      return !head.hide;
    }).length;
  }

  getTotalOthers(data: any) {
    let total: number = 0;
    data.forEach((element: any) => {
      total += Number(element['total_cost']);
    });

    return Number(total).toFixed(4);
  }

  getTotalEBS(data: any) {
    let total: any = {
      total: 0,
      gb_cost: 0,
      storage: 0,
    };
    data.forEach((element: any) => {
      total['total'] += Number(element['total_cost']);
      total['gb_cost'] += Number(element['gb_cost']);
      total['storage'] += Number(element['total_storage']);
    });

    total['total'] = Number(total['total']).toFixed(4);
    total['gb_cost'] = Number(total['gb_cost']).toFixed(4);
    total['storage'] = Number(total['storage']).toFixed(4);

    return total;
  }
  getTotalEC2(data: any) {
    let total: number = 0;
    data.forEach((element: any) => {
      if (isNaN(element['total_cost'])) {
        total += 0;
      } else if (element['RI'] && element['RI'].toLowerCase() == 'yes') {
        total += Number(element['riTotalCost']);
      } else {
        total += Number(element['total_cost']);
      }
    });

    return Number(total).toFixed(4);
  }

  async loadEC2Data(show: boolean = false) {
    if (!show) {
      if (this.ec2Show) {
        this.ec2Show = false;
        this.nextTokens['ec2'] = null;
        return;
      }
      if (this.page_destroyed && !this.ec2Show) {
        this.nextTokens['ec2'] = null;
        return;
      }
    }
    if (!this.nextTokens['ec2']) {
      this.mapMigratedEC2 = false;
      this.projectTagNameEC2 = false;
      this.ec2Data = [];
    }
    if (this.page_destroyed) {
      return;
    }
    this.ec2Show = true;
    this.notifier.loading(true);
    let data: any = {
      month_year: this.monthYear,
      lid: this.activeAccount.lid,
      userType: 'C',
      masterAccId: $('#masterAccountSelect')
        ? $('#masterAccountSelect').val()
        : undefined,
      more_instances: this.nextTokens['ec2']
        ? this.nextTokens['ec2']
        : undefined,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      if (result.items && Array.isArray(result.items)) {
        this.ec2Data = [...this.ec2Data, ...result.items];
        for (let index = 0; index < this.ec2Data.length; index++) {
          let ec2 = this.ec2Data[index];
          if (ec2.projectTagName && !this.projectTagNameEC2) {
            this.projectTagNameEC2 = true;
          }
          if (ec2.mapMigrated && !this.mapMigratedEC2) {
            this.mapMigratedEC2 = true;
          }
          if (this.projectTagNameEC2 && this.mapMigratedEC2) {
            break;
          }
        }
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    if (result.more_instances) {
      this.nextTokens['ec2'] = result.more_instances;
    } else {
      this.nextTokens['ec2'] = null;
    }

    this.notifier.loading(false);
  }

  exportData: any = [];
  async exportEC2(lastEvalutedKey: any = null) {
    this.notifier.loading(true);
    if (!lastEvalutedKey) {
      this.exportData = [];
    }
    let data: any = {
      month_year: this.monthYear,
      lid: this.activeAccount.lid,
      userType: 'C',
      masterAccId: $('#masterAccountSelect')
        ? $('#masterAccountSelect').val()
        : undefined,
      more_instances: lastEvalutedKey ? lastEvalutedKey : undefined,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.exportData = [...this.exportData, ...result.items];
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    if (result.more_instances) {
      await this.exportEC2(result.more_instances);
    } else {
      this.downloadEC2();
    }

    this.notifier.loading(false);
  }

  downloadEC2() {
    for (let index = 0; index < this.exportData.length; index++) {
      let ec2 = this.exportData[index];
      if (ec2.projectTagNameEC2 && !this.projectTagNameEC2) {
        this.projectTagNameEC2 = true;
      }
      if (ec2.mapMigrated && !this.mapMigratedEC2) {
        this.mapMigratedEC2 = true;
      }
      if (this.projectTagNameEC2 && this.mapMigratedEC2) {
        break;
      }
    }
    let total: any = {
      riTotalCost: 0,
      total_hours: 0,
    };
    let element = document.createElement('table');
    element.innerHTML += `
        <thead>
          <tr>
            <td>
              Month Year: ${this.monthYear}
            </td>
            <td>
              Account ID: ${this.activeAccount.lid}
            </td>
          </tr>
          <tr>
            <th>Sl. No.</th>
            ${this.ec2Headers.map((h: any) => {
              if (h.export) {
                if (h.displayConditional) {
                  if (!(h.displayConditional && this.getValue(h.id))) {
                    return '';
                  } else {
                    return `<th>${h.name}</th>`;
                  }
                } else {
                  return `<th>${h.name}</th>`;
                }
              } else {
                return '';
              }
            })}
          </tr>
        </thead>
        <tbody>
            ${this.exportData.map((ec2: any, index: any) => {
              return `
                <tr>
                  <td>${index + 1}</td>
                  ${this.ec2Headers.map((h: any) => {
                    if (h.export) {
                      if (h.displayConditional) {
                        if (!(h.displayConditional && this.getValue(h.id))) {
                          return '';
                        }
                      }
                    } else {
                      return '';
                    }
                    if (
                      (h.id == 'riTotalCost' || h.id == 'total_hours') &&
                      h.export
                    ) {
                      let value = h.alter ? h.alter(ec2, h.id) : ec2[h.id];
                      value = parseFloat(value);
                      total[h.id] += value;
                    }
                    return h.export
                      ? `
                      <td>
                        <span>${
                          ((h.alter ? h.alter(ec2, h.id) : ec2[h.id]) != '0' &&
                            (h.alter ? h.alter(ec2, h.id) : ec2[h.id])) != 0
                            ? h.alter
                              ? h.alter(ec2, h.id)
                              : ec2[h.id] != undefined && ec2[h.id] != null
                              ? ec2[h.id]
                              : ''
                            : '-'
                        }</span>
                      </td>
                    `
                      : '';
                  })}
                </tr>
              `;
            })}
            <tr>
                ${this.ec2Headers.map((h: any, index: any) => {
                  if (
                    index + 1 < this.ec2Headers.length &&
                    this.ec2Headers[index + 1].id == 'total_hours'
                  ) {
                    return '<td>SUB TOTAL</td>';
                  }
                  if (
                    (h.id == 'riTotalCost' || h.id == 'total_hours') &&
                    h.export
                  ) {
                    return h.export
                      ? `
                      <td>
                        <span>${total[h.id]}</span>
                      </td>
                    `
                      : '';
                  }
                  return '<td></td>';
                })}
            </tr>
        </tbody>
      `;
    window.exportToExcelElement(
      element,
      `${this.activeAccount.lid}_${this.monthYear}`,
      'csv'
    );
    this.exportData = [];
  }

  async loadEBSData(show: boolean = false) {
    if (!show) {
      if (this.ebsShow) {
        this.ebsShow = false;
        this.nextTokens['ebs'] = null;
        return;
      }
      if (this.page_destroyed && !this.ebsShow) {
        this.nextTokens['ebs'] = null;
        return;
      }
    }
    if (!this.nextTokens['ebs']) {
      this.ebsData = [];
      this.mapMigratedEBS = false;
      this.projectTagNameEBS = false;
    }
    if (this.page_destroyed) {
      return;
    }
    this.ebsShow = true;
    this.notifier.loading(true);
    let data: any = {
      month_year: this.monthYear,
      lid: this.activeAccount.lid,
      masterAccId: $('#masterAccountSelect')
        ? $('#masterAccountSelect').val()
        : undefined,
      userType: 'C',
      more_volumes: this.nextTokens['ebs'] ? this.nextTokens['ebs'] : undefined,
    };

    if (this.diff_days > 0) {
      data = {
        action: 'fetchVolumes',
        month_year: this.monthYear,
        lid: this.activeAccount.lid,
        userType: 'C',
        masterAccId: $('#masterAccountSelect').val(),
        more_instances: this.nextTokens['ebs']
          ? this.nextTokens['ebs']
          : undefined,
      };
    }

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/${
      this.diff_days > 0 ? 'ec2breakup' : 'volumedetails'
    }`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      ((result.status == '1' || result.s == '1') &&
        result.Items &&
        result.Items.length > 0) ||
      (result.volumesList && result.volumesList.length > 0)
    ) {
      if (this.diff_days > 0) {
        this.ebsData = [...this.ebsData, ...result.volumesList];
      } else {
        this.ebsData = [...this.ebsData, ...result.Items];
      }
      this.ebsData = this.ebsData.map((ebs: any) => {
        ebs.projectTagNameEBS = ebs.projectTagName;
        delete ebs.projectTagName;
        ebs.mapMigratedEBS = ebs.mapMigrated;
        delete ebs.mapMigrated;
        return ebs;
      });
      for (let index = 0; index < this.ebsData.length; index++) {
        let ec2 = this.ebsData[index];
        // ec2.projectTagNameEBS = `Project Tag Name ${index}`;
        // ec2.mapMigratedEBS = `Map Migrate Tag Name ${index}`;
        if (ec2.projectTagNameEBS && !this.projectTagNameEBS) {
          this.projectTagNameEBS = true;
        }
        if (ec2.mapMigratedEBS && !this.mapMigratedEBS) {
          this.mapMigratedEBS = true;
        }
        if (this.projectTagNameEBS && this.mapMigratedEBS) {
          break;
        }
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    if (result.more_instances || result.more_volumes) {
      this.nextTokens['ebs'] = result.more_instances || result.more_volumes;
    } else {
      this.nextTokens['ebs'] = null;
    }

    this.notifier.loading(false);
  }

  async exportEBS(nextToken: any = null) {
    this.notifier.loading(true);
    if (!nextToken) {
      this.exportData = [];
    }
    let data: any = {
      month_year: this.monthYear,
      lid: this.activeAccount.lid,
      masterAccId: $('#masterAccountSelect')
        ? $('#masterAccountSelect').val()
        : undefined,
      userType: 'C',
      more_instances: nextToken ? nextToken : undefined,
    };

    if (this.diff_days > 0) {
      data = {
        month_year: this.monthYear,
        lid: this.activeAccount.lid,
        userType: 'C',
        masterAccId: $('#masterAccountSelect').val(),
        more_instances: nextToken ? nextToken : undefined,
        action: 'fetchVolumes',
      };
    }

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/${
      this.diff_days > 0 ? 'ec2breakup' : 'volumedetails'
    }`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1' || result.Items.length > 0) {
      let dt: any = result.Items || result.volumesList;
      dt = dt.map((ebs: any) => {
        ebs.projectTagNameEBS = ebs.projectTagName;
        delete ebs.projectTagName;
        ebs.mapMigratedEBS = ebs.mapMigrated;
        delete ebs.mapMigrated;
        return ebs;
      });
      this.exportData = [...this.exportData, ...dt];
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    if (result.more_instances) {
      await this.exportEBS(result.more_instances);
    } else {
      this.downloadEBS();
    }
    this.notifier.loading(false);
  }

  async loadOthersData(show: boolean = false) {
    if (!show) {
      if (this.othersShow) {
        this.othersShow = false;
        this.nextTokens['others'] = null;
        return;
      }
      if (this.page_destroyed && !this.othersShow) {
        this.nextTokens['others'] = null;
        return;
      }
    }
    if (!this.nextTokens['others']) {
      this.othersData = [];
    }
    if (this.page_destroyed) {
      return;
    }
    this.othersShow = true;
    this.notifier.loading(true);
    let data = {
      month_year: this.monthYear,
      lid: this.activeAccount.lid,
      masterAccId: $('#masterAccountSelect')
        ? $('#masterAccountSelect').val()
        : undefined,
      action: this.diff_days > 0 ? 'fetchOtherDetails' : undefined,
      more_instances: this.nextTokens['others']
        ? this.nextTokens['others']
        : undefined,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/${
      this.diff_days > 0 ? 'ec2breakup' : 'updatedusagedetails'
    }`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      (result.status == '1' || result.s == '1') &&
      ((result.Items && result.Items.length > 0) ||
        (result.othersDataList && result.othersDataList.length > 0))
    ) {
      if (this.diff_days > 0) {
        this.othersData = [...this.othersData, ...result.othersDataList];
      } else {
        this.othersData = [...this.othersData, ...result.items];
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    if (result.more_instances) {
      this.nextTokens['others'] = result.more_instances;
    } else {
      this.nextTokens['others'] = null;
    }
    this.notifier.loading(false);
  }

  downloadEBS() {
    let total: any = {
      gb_cost: 0,
      total_storage: 0,
      total_cost: 0,
    };
    for (let index = 0; index < this.exportData.length; index++) {
      let ec2 = this.exportData[index];
      if (ec2.projectTagNameEBS && !this.projectTagNameEBS) {
        this.projectTagNameEBS = true;
      }
      if (ec2.mapMigratedEBS && !this.mapMigratedEBS) {
        this.mapMigratedEBS = true;
      }
      if (this.projectTagNameEBS && this.mapMigratedEBS) {
        break;
      }
    }
    let element = document.createElement('table');
    element.innerHTML += `
        <thead>
          <tr>
            <td>
              Month Year: ${this.monthYear}
            </td>
            <td>
              Account ID: ${this.activeAccount.lid}
            </td>
          </tr>
          <tr>
            <th>Sl. No.</th>
            ${this.ebsHeaders.map((h: any) => {
              if (h.export) {
                if (h.displayConditional) {
                  if (!(h.displayConditional && this.getValue(h.id))) {
                    return '';
                  } else {
                    return `<th>${h.name}</th>`;
                  }
                } else {
                  return `<th>${h.name}</th>`;
                }
              } else {
                return '';
              }
            })}
          </tr>
        </thead>
        <tbody>
            ${this.exportData.map((ec2: any, index: any) => {
              return `
                <tr>
                  <td>${index + 1}</td>
                  ${this.ebsHeaders.map((h: any) => {
                    if (h.export) {
                      if (h.displayConditional) {
                        if (!(h.displayConditional && this.getValue(h.id))) {
                          return '';
                        }
                      }
                    } else {
                      return '';
                    }
                    if (
                      (h.id == 'total_cost' ||
                        h.id == 'total_storage' ||
                        h.id == 'gb_cost') &&
                      h.export
                    ) {
                      let value = h.alter ? h.alter(ec2[h.id]) : ec2[h.id];
                      value = parseFloat(value);
                      total[h.id] += value;
                    }
                    return h.export
                      ? `
                      <td>
                        <span>${
                          ((h.alter ? h.alter(ec2[h.id]) : ec2[h.id]) != '0' &&
                            (h.alter ? h.alter(ec2[h.id]) : ec2[h.id])) != 0
                            ? h.alter
                              ? h.alter(ec2[h.id])
                              : ec2[h.id] != undefined && ec2[h.id] != null
                              ? ec2[h.id]
                              : ''
                            : '-'
                        }</span>
                      </td>
                    `
                      : '';
                  })}
                </tr>
              `;
            })}
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>SUB TOTAL</td>
                ${this.ebsHeaders.map((h: any, index: any) => {
                  if (
                    (h.id == 'total_cost' ||
                      h.id == 'total_storage' ||
                      h.id == 'gb_cost') &&
                    h.export
                  ) {
                    return h.export
                      ? `
                      <td>
                        <span>${total[h.id]}</span>
                      </td>
                    `
                      : '';
                  }
                  return '';
                  // return '<td></td>';
                })}
            </tr>
        </tbody>
      `;
    window.exportToExcelElement(
      element,
      `${this.activeAccount.lid}_${this.monthYear}`,
      'csv'
    );
    this.exportData = [];
  }

  async exportOthers() {
    this.notifier.loading(true);
    let total: any = {
      total_cost: 0,
    };
    let data: any = {
      month_year: this.monthYear,
      lid: this.activeAccount.lid,
      masterAccId: $('#masterAccountSelect')
        ? $('#masterAccountSelect').val()
        : undefined,
      more_instances: this.nextTokens['others']
        ? this.nextTokens['others']
        : undefined,
      action: 'fetchTotalOtherDetails',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/${
      this.diff_days > 0 ? 'ec2breakup' : 'updatedusagedetails'
    }`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1' || result.Items.length > 0) {
      let dt: any = result.Items || result.othersDataList;
      let element = document.createElement('table');
      let index = 0;

      element.innerHTML += `
        <thead>
          <tr>
            <td>
              Month Year: ${this.monthYear}
            </td>
            <td>
              Account ID: ${this.activeAccount.lid}
            </td>
          </tr>
          <tr>
            <th>Sl. No.</th>
            ${this.othersHeaders.map((h: any) => {
              return h.export ? `<th>${h.name}</th>` : '';
            })}
          </tr>
        </thead>
        <tbody>
            ${dt.map((ec2: any) => {
              if (ec2['total_cost'] == '0' || ec2['total_cost'] == 0) {
                return '';
              }
              index += 1;
              return ` <tr>
                  <td>${index}</td>
                  ${this.othersHeaders.map((h: any) => {
                    if (h.id == 'total_cost' && h.export) {
                      let value = h.alter ? h.alter(ec2[h.id]) : ec2[h.id];
                      value = parseFloat(value);
                      total[h.id] += value;
                    }
                    return h.export
                      ? `
                      <td>
                        <span>${
                          ((h.alter ? h.alter(ec2[h.id]) : ec2[h.id]) != '0' &&
                            (h.alter ? h.alter(ec2[h.id]) : ec2[h.id])) != 0
                            ? h.alter
                              ? h.alter(ec2[h.id])
                              : ec2[h.id]
                            : '-'
                        }</span>
                      </td>
                    `
                      : '';
                  })}
                </tr>
              `;
            })}
            <tr>
                <td></td>
                ${this.othersHeaders.map((h: any, index: any) => {
                  if (
                    index + 1 < this.othersHeaders.length &&
                    this.othersHeaders[index + 1].id === 'total_cost'
                  ) {
                    return '<td>SUB TOTAL</td>';
                  }
                  if (h.id == 'total_cost' && h.export) {
                    return h.export
                      ? `
                      <td>
                        <span>${total[h.id]}</span>
                      </td>
                    `
                      : '';
                  }
                  return '<td></td>';
                })}
            </tr>
        </tbody>
      `;
      window.exportToExcelElement(
        element,
        `${this.activeAccount.lid}_${this.monthYear}`,
        'csv'
      );
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  export(table_name: any, file_name: any) {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    if (this.diff_days > 0) {
      this.showConditionalElement = true;
    }

    setTimeout(() => {
      window.exportToExcel(
        table_name,
        file_name + `_${this.activeAccount.lid}`,
        'csv'
      );
      setTimeout(() => {
        this.showConditionalElement = false;
      }, 100);
    }, 100);
  }

  ngOnDestroy(): void {
    this.page_destroyed = true;
  }
}
