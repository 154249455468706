import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifierService } from '../_services/notifier.service';
import * as echarts from 'echarts';
import * as moment from 'moment';

declare var $: any;
declare var bootstrap: any;
import { APIService } from './../api/api.service';
//import * as $ from 'jquery';
//import * as moment from 'moment';
declare let window: any;
/*declare let echarts: any;*/

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './index.component.html',
})
export class DashClientIndexComponent implements OnInit {
  uid = localStorage.getItem('t');
  t = localStorage.getItem('t');
  loading = true;

  showDetails = false;
  lastBreachOn = '3-Nov-2021';

  currentMessage: any;

  clientName = '';
  mappedInstanceList: any = [
    //{
    //  id: "1",
    //  instanceId: "i-02c15d357d4279264",
    //  instanceName: "Server1",
    //  status: "Normal",
    //  monitoring: true
    //},
    //{
    //  id: "2",
    //  instanceId: "i-02c15d357d4279893",
    //  instanceName: "Server2",
    //  status: "Warning",
    //  monitoring: true
    //},
    //{
    //  id: "3",
    //  instanceId: "i-02c15d357d8539264",
    //  instanceName: "Server3",
    //  status: "Alarm",
    //  monitoring: true
    //},
    //{
    //  id: "4",
    //  instanceId: "i-02c15d357d8552264",
    //  instanceName: "Server3",
    //  status: "Alarm",
    //  monitoring: false
    //}
  ];

  instanceList: any = [];

  showpopAdd = false;

  selectedItem: any;

  alertEmails = {
    onAlert: '',
    onWarning: '',
    dailyReport: '',
  };
  accountId: any;
  regionId: any;

  monitoringThreshold: any;

  dataMap: any = {};

  cpuEvents: any = [];

  cpuDataBreachAverage: any;
  cpuDataBreachLast: any;
  cpuDataCurrent: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.monitoringThreshold = {
      CPU: 85,
      RAM: 90,
      Disk: 90,
    };

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.loadInstances();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.loadInstances();
      }
    });

    this.loadInstances();

    //this.weeklySalesInit();
    //this.totalOrderInit();
    //this.marketShareInit();
    //this.totalSalesInit();
    //setTimeout(() => {

    //  $('#world-map-markers').vectorMap({

    //    map: "world_mill", backgroundColor: "#fff",
    //    zoomAnimate: !0,
    //    regionStyle: {
    //      initial: { fill: "#c5cae9" },
    //      hover: {
    //        fill: "#5864FF"
    //      },
    //      selected: { fill: "#c5cae9" },
    //      selectedHover: { fill: "#5864FF" }
    //    },
    //    markerStyle: {
    //      initial:
    //      {
    //        r: 8,
    //        fill: '#0d6efd',
    //        "stroke-width": 0
    //      }, hover: {
    //        r: 12,
    //        stroke: '#0d6efd',
    //        "stroke-width": 0
    //      }
    //    },

    //    //map: 'world_mill',
    //    ////scaleColors: ['#C8EEFF', '#0071A4'],
    //    //normalizeFunction: 'polynomial',
    //    //hoverOpacity: 0.7,
    //    //hoverColor: false,
    //    //markerStyle: {
    //    //  initial: {
    //    //    fill: '#F8E23B',
    //    //    stroke: '#383f47'
    //    //  }
    //    //},
    //    ////backgroundColor: '#fff',
    //    markers: [
    //      { latLng: [41.90, 12.45], name: 'Vatican City' },
    //      { latLng: [43.73, 7.41], name: 'Monaco' },
    //      { latLng: [-0.52, 166.93], name: 'Nauru' }
    //    ]
    //  });
    //this.loadData();

    //$("#worldmap path").each((el: any) => {
    //  let element = $(el.currentTarget);
    //  if (element.attr("name")) {
    //    element.attr("title", element.attr("name"));
    //  } else {
    //    element.attr("title", element.attr("class"));
    //  }
    //  var tooltip = new bootstrap.Tooltip(element, {})
    //})

    //$("#worldmap path").mouseover((el: any) => {
    //  let element = $(el.currentTarget);
    //  //if (element.attr("name")) {
    //  //  element.attr("title", element.attr("name"));
    //  //} else {
    //  //  element.attr("title", element.attr("class"));
    //  //}
    //  //console.log($(el.currentTarget).attr("title"));

    //  //$(".tooltip").show().text(element.attr("name"));

    ////var exampleEl = document.querySelector(el.currentTarget)
    //  var tooltip = new bootstrap.Tooltip(element, {})
    //})

    //}, 1000)
  }

  async loadInstances() {
    this.notifier.loading(true);
    let data = {
      action: 'testListInstancesWithPagination',
      accountId: this.accountId,
      region: this.regionId,
      nextToken: '',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //let result = await this.apiServer.postDataPromis('https://api.swayam.cloud/prod/client/operations/listec2instances', data, header);
    let result = await this.apiServer.postDataPromis(
      'https://api.swayam.cloud/prod/msopsusers/operations/listec2instances',
      data,
      header
    );

    console.log('listec2instances', JSON.stringify(result));
    this.mappedInstanceList = [];
    for (let i = 0; i < result.instanceDetails.length; i++) {
      this.mappedInstanceList.push({
        id: i + 1,
        instanceId: result.instanceDetails[i].instanceId,
        instanceName: result.instanceDetails[i].tagName,
        status: 'Fetching Data',
        monitoring: false,
      });
    }

    await this.loadMonitoringInstances();

    this.notifier.loading(false);
  }

  async disableMonitoring(item: any) {
    this.notifier.loading(true);
    let data = {
      action: 'SaveorUpdate',
      accountId: this.accountId,
      region: this.regionId,
      instanceId: item.instanceId,

      monitoring: false, // / false,
      monitoringStatus: 'Normal', // Normal, Alarm, Warning

      // alertEmails: this.alertEmails,
      // monitoringThreshold: this.monitoringThreshold
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //let result = await this.apiServer.postDataPromis('https://api.swayam.cloud/prod/client/operations/listec2instances', data, header);
    let result = await this.apiServer.postDataPromis(
      'https://api.swayam.cloud/prod/msopsusers/instancemonitoring/',
      data,
      header
    );

    console.log('listec2instances', JSON.stringify(result));

    await this.loadMonitoringInstances();

    this.notifier.loading(false);
  }

  async loadMonitoringInstances() {
    this.notifier.loading(true);
    let data = {
      action: 'FetchMonitoringInstances',
      accountId: this.accountId,
      region: this.regionId,
      nextToken: '',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //let result = await this.apiServer.postDataPromis('https://api.swayam.cloud/prod/client/operations/listec2instances', data, header);
    let result = await this.apiServer.postDataPromis(
      'https://api.swayam.cloud/prod/msopsusers/instancemonitoring/',
      data,
      header
    );

    console.log('listec2instances', JSON.stringify(result));
    //this.mappedInstanceList = [];
    if (result.status == 1) {
      for (let i = 0; i < result.monitoringInstancesList.length; i++) {
        for (var j = 0; j < this.mappedInstanceList.length; j++) {
          if (
            result.monitoringInstancesList[i].instanceId ==
            this.mappedInstanceList[j].instanceId
          ) {
            this.mappedInstanceList[j].monitoring =
              result.monitoringInstancesList[i].monitoring;
            this.mappedInstanceList[j].status =
              this.mappedInstanceList[j].status;
            this.mappedInstanceList[j].monitoringThreshold =
              result.monitoringInstancesList[i].monitoringThreshold;
          }
        }
      }
    }

    this.notifier.loading(false);
  }

  showDetailsView(item: any) {
    this.notifier.loading(true);
    this.showDetails = true;
    this.selectedItem = item;

    this.dataMap = {};

    setTimeout(() => {
      this.loadData();
    }, 500);
  }

  addToMonitering(item: any) {
    this.showpopAdd = true;
    this.selectedItem = item;
  }

  async setMonitoringEnabled() {
    this.selectedItem.monitoring = true;
    this.notifier.loading(true);

    // Authorization in Header  : at from localstorage

    let hearder = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      action: 'SaveorUpdate',
      accountId: this.accountId,
      region: this.regionId,
      instanceId: this.selectedItem.instanceId,

      monitoring: true, // / false,
      monitoringStatus: 'Normal', // Normal, Alarm, Warning

      alertEmails: this.alertEmails,
      monitoringThreshold: this.monitoringThreshold,
      //alertEmails: {
      //  onAlert: ,
      //  onWarning: ,
      //  dailyReport:
      //}

      // CDate : , MDate :
    };

    let result = await this.apiServer.postDataPromis(
      'https://api.swayam.cloud/prod/msopsusers/instancemonitoring/',
      data,
      hearder
    );

    console.log('listec2instances', JSON.stringify(result));
    if (result.status == 1) {
    }

    this.showpopAdd = false;

    await this.loadMonitoringInstances();

    this.notifier.loading(false);
  }

  qs(key: string) {
    key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, '\\$&'); // escape RegEx meta chars
    var match = location.search.match(
      new RegExp('[?&]' + key + '=([^&]+)(&|$)')
    );
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  loadData() {
    this.vCPU();
    this.diskVR();
    //this.diskVW();
    this.networkVIn();
    this.networkVOut();
    //this.diskC();

    // this.vRam();
    this.networkVInBy();
    this.networkVOutBy();
    this.notifier.loading(true);
    this.cpuEvents = [];
    this.dataMap = {};
    this.cpuDataBreachAverage = null;
    this.cpuDataBreachLast = null;
    this.cpuDataCurrent = null;
    let intV = setInterval(() => {
      if (
        this.dataMap['cpuV'] &&
        this.dataMap['networkVOutBy'] &&
        this.dataMap['networkVInBy'] &&
        this.dataMap['networkVIn'] &&
        this.dataMap['networkVOut']
      ) {
        clearInterval(intV);
        let cpuData = this.dataMap['cpuV'];
        //let networkOut = this.dataMap["networkVOutBy"];
        let threshold = this.selectedItem.monitoringThreshold.CPU;

        let cpu = 0;
        let networkVOutBy = 0;
        let networkVInBy = 0;
        let networkVIn = 0;
        let networkVOut = 0;

        for (let i = 0; i < cpuData.length; i++) {
          if (cpuData[i][1] > threshold) {
            this.cpuEvents.push({
              cpu: cpuData[i][1],
              timeStamp: cpuData[i][2],
              timeStampDisp: moment(cpuData[i][2]).format('h A'),
              networkVOutBy: this.dataMap['networkVOutBy'][i][1],
              networkVInBy: this.dataMap['networkVInBy'][i][1],
              networkVIn: this.dataMap['networkVIn'][i][1],
              networkVOut: this.dataMap['networkVOut'][i][1],
            });

            cpu += cpuData[i][1];
            networkVOutBy += this.dataMap['networkVOutBy'][i][1];
            networkVInBy += this.dataMap['networkVInBy'][i][1];
            networkVIn += this.dataMap['networkVIn'][i][1];
            networkVOut += this.dataMap['networkVOut'][i][1];
          }
        }
        if (this.cpuEvents.length > 0) {
          this.cpuDataBreachAverage = {
            cpu: cpu,
            networkVOutBy: networkVOutBy,
            networkVInBy: networkVInBy,
            networkVIn: networkVIn,
            networkVOut: networkVOut,
          };
          this.cpuDataBreachLast = {
            cpu: this.cpuEvents[this.cpuEvents.length - 1].cpu,
            networkVOutBy:
              this.cpuEvents[this.cpuEvents.length - 1].networkVOutBy,
            networkVInBy:
              this.cpuEvents[this.cpuEvents.length - 1].networkVInBy,
            networkVIn: this.cpuEvents[this.cpuEvents.length - 1].networkVIn,
            networkVOut: this.cpuEvents[this.cpuEvents.length - 1].networkVOut,
            dispTime: moment(
              this.cpuEvents[this.cpuEvents.length - 1].timeStamp
            ).format('DD-MM-YYYY hh:mm:ss'),
          };
        }
        if (cpuData.length > 0) {
          this.cpuDataCurrent = {
            cpu: cpuData[cpuData.length - 1][1],
            timeStamp: cpuData[cpuData.length - 1][2],
            timeStampDisp: moment(cpuData[cpuData.length - 1][2]).format('h A'),
            networkVOutBy: this.dataMap['networkVOutBy'][cpuData.length - 1][1],
            networkVInBy: this.dataMap['networkVInBy'][cpuData.length - 1][1],
            networkVIn: this.dataMap['networkVIn'][cpuData.length - 1][1],
            networkVOut: this.dataMap['networkVOut'][cpuData.length - 1][1],
          };
        }

        console.log(this.cpuEvents);
        this.notifier.loading(false);
      }
    }, 100);
  }

  diskC() {
    var chartDom = document.getElementById('diskC');
    var myChart = echarts.init(chartDom);
    var option: any;

    option = {
      color: ['#1976d2', '#42a5f5'],
      title: {
        //text: 'Referer of a Website',
        //subtext: 'Fake Data',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        orient: 'vertical',
        left: 'left',
      },
      series: [
        {
          // name: 'Access From',  #42a5f5 #1976d2
          type: 'pie',
          radius: '50%',
          data: [
            { value: 1048, name: 'Used', color: '#42a5f5' },
            { value: 735, name: 'Free', color: '#1976d2' },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  async vCPU() {
    let chartDom = document.getElementById('cpuV');
    let myChart = echarts.init(chartDom);
    let option: any;

    // prettier-ignore
    const data:any = [];
    //[
    //["2000-06-05", 116],

    let dataT = {
      action: 'GetMetricStatistics',
      regionName: this.regionId, //  "ap-south-1",
      accountId: this.accountId, ///"534417135671",
      Namespace: 'AWS/EC2',
      Period: 60,
      StartTime: moment().add('days', -1).toISOString().replace('Z', ''), //"2021-12-05T14:17:19.278664" "2021-12-05T14:17:19.278664"
      EndTime: moment().toISOString().replace('Z', ''),
      MetricName: 'CPUUtilization',
      Statistics: ['Maximum', 'Minimum', 'Sum', 'Average'],
      Unit: 'Percent',
      Dimensions: [
        {
          Name: 'InstanceId',
          //"Value":  "i-0d6489228005efa16"
          Value: this.selectedItem.instanceId, //    "i-0d6489228005efa16"
        },
      ],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //let result = await this.apiServer.postDataPromis('https://api.swayam.cloud/prod/client/operations/listec2instances', data, header);
    let result = await this.apiServer.postDataPromis(
      'https://api.swayam.cloud/prod/msopsusers/itsi',
      dataT,
      header
    );

    console.log(result);

    //let r = this.SmoothRandom(2, 2);
    //let i = 0;
    //while (data.length < 60) {

    //  data.push([i.toString(), (r() * 50 ) % 100]);
    //  i++;
    //}

    let dt = result.Datapoints.sort((a: any, b: any) => {
      //return moment(a.Timestamp)
      return a.Timestamp.localeCompare(b.Timestamp);
    });

    console.log('dt', dt);
    let Average = 0;
    for (let i = 0; i < dt.length; i++) {
      data.push([i.toString(), dt[i].Average, dt[i].Timestamp]);
      Average += dt[i].Average;
    }

    this.dataMap['cpuV'] = data;

    Average = Average / dt.length;
    console.log(data);
    const dateList = data.map(function (item: any) {
      return item[0];
    });
    const valueList = data.map(function (item: any) {
      return item[1];
    });

    console.log('this.selectedItem', this.selectedItem);
    if (
      this.selectedItem &&
      this.selectedItem.monitoringThreshold &&
      this.selectedItem.monitoringThreshold.CPU
    ) {
      Average = this.selectedItem.monitoringThreshold.CPU;
    } else {
      Average = 85;
    }

    const valueListAverage = data.map(function (item: any) {
      return Average;
    });

    const valueListPridicted = data.map(function (item: any) {
      return parseFloat(item[1].toString()) - Math.random() * 10;
    });
    option = {
      // Make gradient line here
      //visualMap: [
      //  {
      //    show: false,
      //   // type: 'continuous',
      //    //type: 'line',
      //    seriesIndex: 0,
      //    min: 0,
      //    max: 100
      //  }
      //],
      color: [
        '#1976d2',
        // "#42a5f5",

        '#b31b1b',
        '#ec7411',
      ],
      title: [
        {
          left: 'center',
          //  text: 'Gradient along the y axis'
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: ['CPU', 'Threshold', 'Events'],
      },
      xAxis: [
        {
          data: dateList,
        },
      ],
      yAxis: [
        {
          min: 0,
          max: 100,
        },
      ],
      series: [
        {
          name: 'CPU',
          type: 'line',
          showSymbol: false,
          data: valueList,
        },
        {
          name: 'Threshold',
          type: 'line',
          showSymbol: false,
          data: valueListAverage,
        },
        {
          name: 'Events',
          type: 'line',
          showSymbol: false,
          data: valueListPridicted,
        },
      ],
    };

    myChart.setOption(option);
  }

  vRam() {
    let chartDom = document.getElementById('ramV');
    let myChart = echarts.init(chartDom);
    let option: any;

    // prettier-ignore
    const data = [];
    //[
    //["2000-06-05", 116],

    let r = this.SmoothRandom(2, 2);
    let i = 0;
    while (data.length < 60) {
      data.push([i.toString(), (r() * 50) % 100]);
      i++;
    }

    console.log(data);
    const dateList = data.map(function (item) {
      return item[0];
    });
    const valueList = data.map(function (item) {
      return item[1];
    });

    const valueListAverage = data.map(function (item) {
      return 25;
    });

    const valueListPridicted = data.map(function (item) {
      return parseFloat(item[1].toString()) - Math.random() * 10;
    });
    option = {
      // Make gradient line here
      //visualMap: [
      //  {
      //    show: false,
      //   // type: 'continuous',
      //    //type: 'line',
      //    seriesIndex: 0,
      //    min: 0,
      //    max: 100
      //  }
      //],
      color: ['#1976d2', '#42a5f5', '#ec7411'],
      title: [
        {
          left: 'center',
          //  text: 'Gradient along the y axis'
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: ['CPU', 'Average', 'Predicted'],
      },
      xAxis: [
        {
          data: dateList,
        },
      ],
      yAxis: [
        {
          min: 0,
          max: 100,
        },
      ],
      series: [
        {
          name: 'CPU',
          type: 'line',
          showSymbol: false,
          data: valueList,
        },
        {
          name: 'Average',
          type: 'line',
          showSymbol: false,
          data: valueListAverage,
        },
        {
          name: 'Predicted',
          type: 'line',
          showSymbol: false,
          data: valueListPridicted,
        },
      ],
    };

    myChart.setOption(option);
  }

  //diskVR() {
  //  let chartDom = document.getElementById('diskVR');
  //  let myChart = echarts.init(chartDom);
  //  let option:any;

  //  // prettier-ignore
  //  const data = [];
  //  //[
  //  //["2000-06-05", 116],

  //  let r = this.SmoothRandom(2, 2);
  //  let i = 0;
  //  while (data.length < 60) {

  //    data.push([i.toString(), (r() * 50) % 100]);
  //    i++;
  //  }

  //  console.log(data);
  //  const dateList = data.map(function (item) {
  //    return item[0];
  //  });
  //  const valueList = data.map(function (item) {
  //    return item[1];
  //  });
  //  option = {
  //    // Make gradient line here
  //    //visualMap: [
  //    //  {
  //    //    show: false,
  //    //    type: 'continuous',
  //    //    seriesIndex: 0,
  //    //    min: 0,
  //    //    max: 100
  //    //  }
  //    //],
  //    title: [
  //      {
  //        left: 'center',
  //       // text: 'Gradient along the y axis'
  //      }
  //    ],
  //    tooltip: {
  //      trigger: 'axis'
  //    },
  //    xAxis: [
  //      {
  //        data: dateList
  //      }
  //    ],
  //    yAxis: [
  //      {
  //        min: 0,
  //        max: 100
  //      }
  //    ],
  //    series: [
  //      {
  //        type: 'line',
  //        showSymbol: false,
  //        data: valueList
  //      }
  //    ]
  //  };

  //  myChart.setOption(option);
  //}

  diskVW() {
    let chartDom = document.getElementById('diskVW');
    let myChart = echarts.init(chartDom);
    let option: any;

    // prettier-ignore
    const data = [];
    //[
    //["2000-06-05", 116],

    let r = this.SmoothRandom(2, 2);
    let i = 0;
    while (data.length < 60) {
      data.push([i.toString(), (r() * 50) % 100]);
      i++;
    }

    console.log(data);
    const dateList = data.map(function (item) {
      return item[0];
    });
    const valueList = data.map(function (item) {
      return item[1];
    });
    option = {
      // Make gradient line here
      //visualMap: [
      //  {
      //    show: false,
      //    type: 'continuous',
      //    seriesIndex: 0,
      //    min: 0,
      //    max: 100
      //  }
      //],
      title: [
        {
          left: 'center',
          // text: 'Gradient along the y axis'
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
      xAxis: [
        {
          data: dateList,
        },
      ],
      yAxis: [
        {
          min: 0,
          max: 100,
        },
      ],
      series: [
        {
          type: 'line',
          showSymbol: false,
          data: valueList,
        },
      ],
    };

    myChart.setOption(option);
  }

  async diskVR() {
    let chartDom = document.getElementById('diskVR');
    let myChart = echarts.init(chartDom);
    let option: any;

    // prettier-ignore
    const data = [];
    //[
    //["2000-06-05", 116],

    //let r = this.SmoothRandom(2, 2);
    //let i = 0;
    //while (data.length < 60) {

    //  data.push([i.toString(), (r() * 50) % 100]);
    //  i++;
    //}
    let max = 0;
    let dataT = {
      action: 'GetEMSMetricStatistics',
      regionName: this.regionId, //  "ap-south-1",
      account_id: this.accountId, ///"534417135671",

      zaaid: '700571143',
      instance_name: this.selectedItem.instanceName,

      start_date: moment().format('YYYY-MM-DD'), // "2021-12-01",

      end_date: moment().format('YYYY-MM-DD'),

      /*"account_id": "534417135671",*/

      metric_name: 'MEMORY', // "DISK"

      // "Namespace": "AWS/EC2",
      //"Period": 60,
      //"StartTime": moment().add("days", -1).toISOString().replace("Z", ""),            //"2021-12-05T14:17:19.278664" "2021-12-05T14:17:19.278664"
      //"EndTime": moment().toISOString().replace("Z", ""),
      //"MetricName": "NetworkPacketsIn",
      //"Statistics": ["Maximum", "Minimum", "Sum", "Average"],
      //"Unit": "Count",
      //"Dimensions": [{
      //  "Name": "InstanceId",
      //  //"Value":  "i-0d6489228005efa16"
      //  "Value": this.selectedItem.instanceId //    "i-0d6489228005efa16"
      //}]
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //let result = await this.apiServer.postDataPromis('https://api.swayam.cloud/prod/client/operations/listec2instances', data, header);
    let result = await this.apiServer.postDataPromis(
      'https://api.swayam.cloud/prod/msopsusers/itsi',
      dataT,
      header
    );

    console.log(result);

    //let r = this.SmoothRandom(2, 2);
    //let i = 0;
    //while (data.length < 60) {

    //  data.push([i.toString(), (r() * 50 ) % 100]);
    //  i++;
    //}

    //console.log(data);
    //const dateList = data.map(function (item) {
    //  return item[0];
    //});
    //const valueList = data.map(function (item) {
    //  return item[1];
    //});

    let dt = result.DataPoints.sort((a: any, b: any) => {
      return a.Timestamp.localeCompare(b.Timestamp);
    });

    console.log('dt', dt);
    let Average = 0;
    for (let i = 0; i < dt.length; i++) {
      data.push([i.toString(), dt[i].Average]);
      Average += dt[i].Average;
      max = dt[i].Average > max ? dt[i].Average : max;
    }
    Average = Average / dt.length;
    console.log(data);
    const dateList = data.map(function (item: any) {
      return item[0];
    });
    const valueList = data.map(function (item: any) {
      return item[1];
    });

    const valueListAverage = data.map(function (item: any) {
      return Average;
    });

    const valueListPridicted = data.map(function (item: any) {
      return parseFloat(item[1].toString()) - Math.random() * 10;
    });

    option = {
      // Make gradient line here
      //visualMap: [
      //  {
      //    show: false,
      //    type: 'continuous',
      //    seriesIndex: 0,
      //    min: 0,
      //    max: 100
      //  }
      //],
      title: [
        {
          left: 'center',
          // text: 'Gradient along the y axis'
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
      xAxis: [
        {
          data: dateList,
        },
      ],
      yAxis: [
        {
          min: 0,
          max: 100,
        },
      ],
      series: [
        {
          type: 'line',
          showSymbol: false,
          data: valueList,
        },
      ],
    };

    myChart.setOption(option);
  }

  async networkVIn() {
    let chartDom = document.getElementById('networkVIn');
    let myChart = echarts.init(chartDom);
    let option: any;

    // prettier-ignore
    const data = [];
    //[
    //["2000-06-05", 116],

    //let r = this.SmoothRandom(2, 2);
    //let i = 0;
    //while (data.length < 60) {

    //  data.push([i.toString(), (r() * 50) % 100]);
    //  i++;
    //}
    let max = 0;
    let dataT = {
      action: 'GetMetricStatistics',
      regionName: this.regionId, //  "ap-south-1",
      accountId: this.accountId, ///"534417135671",
      Namespace: 'AWS/EC2',
      Period: 60,
      StartTime: moment().add('days', -1).toISOString().replace('Z', ''), //"2021-12-05T14:17:19.278664" "2021-12-05T14:17:19.278664"
      EndTime: moment().toISOString().replace('Z', ''),
      MetricName: 'NetworkPacketsIn',
      Statistics: ['Maximum', 'Minimum', 'Sum', 'Average'],
      Unit: 'Count',
      Dimensions: [
        {
          Name: 'InstanceId',
          //"Value":  "i-0d6489228005efa16"
          Value: this.selectedItem.instanceId, //    "i-0d6489228005efa16"
        },
      ],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //let result = await this.apiServer.postDataPromis('https://api.swayam.cloud/prod/client/operations/listec2instances', data, header);
    let result = await this.apiServer.postDataPromis(
      'https://api.swayam.cloud/prod/msopsusers/itsi',
      dataT,
      header
    );

    console.log(result);

    //let r = this.SmoothRandom(2, 2);
    //let i = 0;
    //while (data.length < 60) {

    //  data.push([i.toString(), (r() * 50 ) % 100]);
    //  i++;
    //}

    //console.log(data);
    //const dateList = data.map(function (item) {
    //  return item[0];
    //});
    //const valueList = data.map(function (item) {
    //  return item[1];
    //});

    let dt = result.Datapoints.sort((a: any, b: any) => {
      return a.Timestamp.localeCompare(b.Timestamp);
    });

    console.log('dt', dt);
    let Average = 0;
    for (let i = 0; i < dt.length; i++) {
      data.push([i.toString(), dt[i].Average, dt[i].Timestamp]);
      Average += dt[i].Average;
      max = dt[i].Average > max ? dt[i].Average : max;
    }

    this.dataMap['networkVIn'] = data;

    Average = Average / dt.length;
    console.log(data);
    const dateList = data.map(function (item: any) {
      return item[0];
    });
    const valueList = data.map(function (item: any) {
      return item[1];
    });

    const valueListAverage = data.map(function (item: any) {
      return Average;
    });

    const valueListPridicted = data.map(function (item: any) {
      return parseFloat(item[1].toString()) - Math.random() * 10;
    });

    option = {
      // Make gradient line here
      //visualMap: [
      //  {
      //    show: false,
      //    type: 'continuous',
      //    seriesIndex: 0,
      //    min: 0,
      //    max: 100
      //  }
      //],
      title: [
        {
          left: 'center',
          // text: 'Gradient along the y axis'
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
      xAxis: [
        {
          data: dateList,
        },
      ],
      yAxis: [
        {
          min: 0,
          max: max,
        },
      ],
      series: [
        {
          type: 'line',
          showSymbol: false,
          data: valueList,
        },
      ],
    };

    myChart.setOption(option);
  }

  async networkVOut() {
    let chartDom = document.getElementById('networkVOut');
    let myChart = echarts.init(chartDom);
    let option: any;

    // prettier-ignore
    const data = [];
    //[
    //["2000-06-05", 116],

    //let r = this.SmoothRandom(2, 2);
    //let i = 0;
    //while (data.length < 60) {

    //  data.push([i.toString(), (r() * 50) % 100]);
    //  i++;
    //}
    let max = 0;
    let dataT = {
      action: 'GetMetricStatistics',
      regionName: this.regionId, //  "ap-south-1",
      accountId: this.accountId, ///"534417135671",
      Namespace: 'AWS/EC2',
      Period: 60,
      StartTime: moment().add('days', -1).toISOString().replace('Z', ''), //"2021-12-05T14:17:19.278664" "2021-12-05T14:17:19.278664"
      EndTime: moment().toISOString().replace('Z', ''),
      MetricName: 'NetworkPacketsOut',
      Statistics: ['Maximum', 'Minimum', 'Sum', 'Average'],
      Unit: 'Count',
      Dimensions: [
        {
          Name: 'InstanceId',
          //"Value":  "i-0d6489228005efa16"
          Value: this.selectedItem.instanceId, //    "i-0d6489228005efa16"
        },
      ],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //let result = await this.apiServer.postDataPromis('https://api.swayam.cloud/prod/client/operations/listec2instances', data, header);
    let result = await this.apiServer.postDataPromis(
      'https://api.swayam.cloud/prod/msopsusers/itsi',
      dataT,
      header
    );

    console.log(result);

    //let r = this.SmoothRandom(2, 2);
    //let i = 0;
    //while (data.length < 60) {

    //  data.push([i.toString(), (r() * 50 ) % 100]);
    //  i++;
    //}

    //console.log(data);
    //const dateList = data.map(function (item) {
    //  return item[0];
    //});
    //const valueList = data.map(function (item) {
    //  return item[1];
    //});

    let dt = result.Datapoints.sort((a: any, b: any) => {
      return a.Timestamp.localeCompare(b.Timestamp);
    });

    console.log('dt', dt);
    let Average = 0;
    for (let i = 0; i < dt.length; i++) {
      data.push([i.toString(), dt[i].Average, dt[i].Timestamp]);
      Average += dt[i].Average;
      max = dt[i].Average > max ? dt[i].Average : max;
    }

    this.dataMap['networkVOut'] = data;

    Average = Average / dt.length;
    console.log(data);
    const dateList = data.map(function (item: any) {
      return item[0];
    });
    const valueList = data.map(function (item: any) {
      return item[1];
    });

    const valueListAverage = data.map(function (item: any) {
      return Average;
    });

    const valueListPridicted = data.map(function (item: any) {
      return parseFloat(item[1].toString()) - Math.random() * 10;
    });

    option = {
      // Make gradient line here
      //visualMap: [
      //  {
      //    show: false,
      //    type: 'continuous',
      //    seriesIndex: 0,
      //    min: 0,
      //    max: 100
      //  }
      //],
      title: [
        {
          left: 'center',
          // text: 'Gradient along the y axis'
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
      xAxis: [
        {
          data: dateList,
        },
      ],
      yAxis: [
        {
          min: 0,
          max: max,
        },
      ],
      series: [
        {
          type: 'line',
          showSymbol: false,
          data: valueList,
        },
      ],
    };

    myChart.setOption(option);
  }

  async networkVInBy() {
    let chartDom = document.getElementById('networkVInBy');
    let myChart = echarts.init(chartDom);
    let option: any;

    // prettier-ignore
    const data = [];
    //[
    //["2000-06-05", 116],

    //let r = this.SmoothRandom(2, 2);
    //let i = 0;
    //while (data.length < 60) {

    //  data.push([i.toString(), (r() * 50) % 100]);
    //  i++;
    //}
    let max = 0;
    let dataT = {
      action: 'GetMetricStatistics',
      regionName: this.regionId, //  "ap-south-1",
      accountId: this.accountId, ///"534417135671",
      Namespace: 'AWS/EC2',
      Period: 60,
      StartTime: moment().add('days', -1).toISOString().replace('Z', ''), //"2021-12-05T14:17:19.278664" "2021-12-05T14:17:19.278664"
      EndTime: moment().toISOString().replace('Z', ''),
      MetricName: 'NetworkIn',
      Statistics: ['Maximum', 'Minimum', 'Sum', 'Average'],
      Unit: 'Bytes',
      Dimensions: [
        {
          Name: 'InstanceId',
          //"Value":  "i-0d6489228005efa16"
          Value: this.selectedItem.instanceId, //    "i-0d6489228005efa16"
        },
      ],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //let result = await this.apiServer.postDataPromis('https://api.swayam.cloud/prod/client/operations/listec2instances', data, header);
    let result = await this.apiServer.postDataPromis(
      'https://api.swayam.cloud/prod/msopsusers/itsi',
      dataT,
      header
    );

    console.log(result);

    //let r = this.SmoothRandom(2, 2);
    //let i = 0;
    //while (data.length < 60) {

    //  data.push([i.toString(), (r() * 50 ) % 100]);
    //  i++;
    //}

    //console.log(data);
    //const dateList = data.map(function (item) {
    //  return item[0];
    //});
    //const valueList = data.map(function (item) {
    //  return item[1];
    //});

    let dt = result.Datapoints.sort((a: any, b: any) => {
      return a.Timestamp.localeCompare(b.Timestamp);
    });

    console.log('dt', dt);
    let Average = 0;
    for (let i = 0; i < dt.length; i++) {
      data.push([i.toString(), dt[i].Average, dt[i].Timestamp]);
      Average += dt[i].Average;
      max = dt[i].Average > max ? dt[i].Average : max;
    }

    this.dataMap['networkVInBy'] = data;

    Average = Average / dt.length;
    console.log(data);
    const dateList = data.map(function (item: any) {
      return item[0];
    });
    const valueList = data.map(function (item: any) {
      return item[1];
    });

    const valueListAverage = data.map(function (item: any) {
      return Average;
    });

    const valueListPridicted = data.map(function (item: any) {
      return parseFloat(item[1].toString()) - Math.random() * 10;
    });

    option = {
      // Make gradient line here
      //visualMap: [
      //  {
      //    show: false,
      //    type: 'continuous',
      //    seriesIndex: 0,
      //    min: 0,
      //    max: 100
      //  }
      //],
      title: [
        {
          left: 'center',
          // text: 'Gradient along the y axis'
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
      xAxis: [
        {
          data: dateList,
        },
      ],
      yAxis: [
        {
          min: 0,
          max: max,
        },
      ],
      series: [
        {
          type: 'line',
          showSymbol: false,
          data: valueList,
        },
      ],
    };

    myChart.setOption(option);
  }

  async networkVOutBy() {
    let chartDom = document.getElementById('networkVOutBy');
    let myChart = echarts.init(chartDom);
    let option: any;

    // prettier-ignore
    const data = [];
    //[
    //["2000-06-05", 116],

    //let r = this.SmoothRandom(2, 2);
    //let i = 0;
    //while (data.length < 60) {

    //  data.push([i.toString(), (r() * 50) % 100]);
    //  i++;
    //}
    let max = 0;
    let dataT = {
      action: 'GetMetricStatistics',
      regionName: this.regionId, //  "ap-south-1",
      accountId: this.accountId, ///"534417135671",
      Namespace: 'AWS/EC2',
      Period: 60,
      StartTime: moment().add('days', -1).toISOString().replace('Z', ''), //"2021-12-05T14:17:19.278664" "2021-12-05T14:17:19.278664"
      EndTime: moment().toISOString().replace('Z', ''),
      MetricName: 'NetworkOut',
      Statistics: ['Maximum', 'Minimum', 'Sum', 'Average'],
      Unit: 'Bytes',
      Dimensions: [
        {
          Name: 'InstanceId',
          //"Value":  "i-0d6489228005efa16"
          Value: this.selectedItem.instanceId, //    "i-0d6489228005efa16"
        },
      ],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //let result = await this.apiServer.postDataPromis('https://api.swayam.cloud/prod/client/operations/listec2instances', data, header);
    let result = await this.apiServer.postDataPromis(
      'https://api.swayam.cloud/prod/msopsusers/itsi',
      dataT,
      header
    );

    console.log(result);

    //let r = this.SmoothRandom(2, 2);
    //let i = 0;
    //while (data.length < 60) {

    //  data.push([i.toString(), (r() * 50 ) % 100]);
    //  i++;
    //}

    //console.log(data);
    //const dateList = data.map(function (item) {
    //  return item[0];
    //});
    //const valueList = data.map(function (item) {
    //  return item[1];
    //});

    let dt = result.Datapoints.sort((a: any, b: any) => {
      return a.Timestamp.localeCompare(b.Timestamp);
    });

    console.log('dt', dt);
    let Average = 0;
    for (let i = 0; i < dt.length; i++) {
      data.push([i.toString(), dt[i].Average, dt[i].Timestamp]);
      Average += dt[i].Average;
      max = dt[i].Average > max ? dt[i].Average : max;
    }

    this.dataMap['networkVOutBy'] = data;

    Average = Average / dt.length;
    console.log(data);
    const dateList = data.map(function (item: any) {
      return item[0];
    });
    const valueList = data.map(function (item: any) {
      return item[1];
    });

    const valueListAverage = data.map(function (item: any) {
      return Average;
    });

    const valueListPridicted = data.map(function (item: any) {
      return parseFloat(item[1].toString()) - Math.random() * 10;
    });

    option = {
      // Make gradient line here
      //visualMap: [
      //  {
      //    show: false,
      //    type: 'continuous',
      //    seriesIndex: 0,
      //    min: 0,
      //    max: 100
      //  }
      //],
      title: [
        {
          left: 'center',
          // text: 'Gradient along the y axis'
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
      xAxis: [
        {
          data: dateList,
        },
      ],
      yAxis: [
        {
          min: 0,
          max: max,
        },
      ],
      series: [
        {
          type: 'line',
          showSymbol: false,
          data: valueList,
        },
      ],
    };

    myChart.setOption(option);
  }

  //networkVOutBy() {
  //  let chartDom = document.getElementById('networkVOutBy');
  //  let myChart = echarts.init(chartDom);
  //  let option:any;

  //  // prettier-ignore
  //  const data = [];
  //  //[
  //  //["2000-06-05", 116],

  //  let r = this.SmoothRandom(2, 2);
  //  let i = 0;
  //  while (data.length < 60) {

  //    data.push([i.toString(), (r() * 50) % 100]);
  //    i++;
  //  }

  //  console.log(data);
  //  const dateList = data.map(function (item) {
  //    return item[0];
  //  });
  //  const valueList = data.map(function (item) {
  //    return item[1];
  //  });
  //  option = {
  //    // Make gradient line here
  //    //visualMap: [
  //    //  {
  //    //    show: false,
  //    //    type: 'continuous',
  //    //    seriesIndex: 0,
  //    //    min: 0,
  //    //    max: 100
  //    //  }
  //    //],
  //    title: [
  //      {
  //        left: 'center',
  //        // text: 'Gradient along the y axis'
  //      }
  //    ],
  //    tooltip: {
  //      trigger: 'axis'
  //    },
  //    xAxis: [
  //      {
  //        data: dateList
  //      }
  //    ],
  //    yAxis: [
  //      {
  //        min: 0,
  //        max: 100
  //      }
  //    ],
  //    series: [
  //      {
  //        type: 'line',
  //        showSymbol: false,
  //        data: valueList
  //      }
  //    ]
  //  };

  //  myChart.setOption(option);
  //}

  //weeklySalesInit() {

  //  let data = [120, 200, 150, 80, 70, 110, 120]; // Max value of data

  //  let yMax = Math.max.apply(Math, data);
  //  let dataBackground = data.map(function () {
  //    return yMax;
  //  });
  //  let myChart = echarts.init(document.querySelector('.echart-bar-weekly-sales'));

  //  // specify chart configuration item and data
  //  //let option:any = {
  //  //  title: {
  //  //    text: 'ECharts entry example'
  //  //  },
  //  //  tooltip: {},
  //  //  legend: {
  //  //    data: ['Sales']
  //  //  },
  //  //  xAxis: {
  //  //    data: ["shirt", "cardign", "chiffon shirt", "pants", "heels", "socks"]
  //  //  },
  //  //  yAxis: {},
  //  //  series: [{
  //  //    name: 'Sales',
  //  //    type: 'bar',
  //  //    data: [120, 200, 150, 80, 70, 110, 120]
  //  //  }]
  //  //};

  //  let option:any = {
  //    tooltip: {
  //      trigger: 'axis',
  //      padding: [7, 10],
  //      formatter: '{b1}: {c1}',
  //      transitionDuration: 0,
  //      backgroundColor: "#f9fafd",
  //      borderColor: "#d8e2ef",
  //      textStyle: {
  //        color: "#0b1727"
  //      },
  //      borderWidth: 1,
  //      //position: function position(pos, params, dom, rect, size) {
  //      //  return getPosition(pos, params, dom, rect, size);
  //      //}
  //    },
  //    xAxis: {
  //      type: 'category',
  //      data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  //      boundaryGap: false,
  //      axisLine: {
  //        show: false
  //      },
  //      axisLabel: {
  //        show: false
  //      },
  //      axisTick: {
  //        show: false
  //      },
  //      axisPointer: {
  //        type: 'none'
  //      }
  //    },
  //    yAxis: {
  //      type: 'value',
  //      splitLine: {
  //        show: false
  //      },
  //      axisLine: {
  //        show: false
  //      },
  //      axisLabel: {
  //        show: false
  //      },
  //      axisTick: {
  //        show: false
  //      },
  //      axisPointer: {
  //        type: 'none'
  //      }
  //    },
  //    series: [{
  //      type: 'bar',
  //      barWidth: '5px',
  //      barGap: '-100%',
  //      itemStyle: {
  //        barBorderRadius: 10,
  //        color: "#f9fafd"
  //      },
  //      data: dataBackground,
  //      animation: false,
  //      emphasis: {
  //        itemStyle: {
  //          color: "#f9fafd"
  //        }
  //      }
  //    }, {
  //      type: 'bar',
  //      barWidth: '5px',
  //      itemStyle: {
  //        barBorderRadius: 10,
  //        color: "#2c7be5"
  //      },
  //      data: data,
  //      z: 10,
  //      emphasis: {
  //        itemStyle: {
  //          color: "#2c7be5"
  //        }
  //      }
  //    }],
  //    grid: {
  //      right: 5,
  //      left: 10,
  //      top: 0,
  //      bottom: 0
  //    }
  //  };

  //  // use configuration item and data specified to show chart
  //  myChart.setOption(option);

  //};

  //totalOrderInit() {
  //  let ECHART_LINE_TOTAL_ORDER = '.echart-line-total-order'; //
  //  // ─── TOTAL ORDER CHART ──────────────────────────────────────────────────────────
  //  //

  //  let $echartLineTotalOrder = document.querySelector(ECHART_LINE_TOTAL_ORDER);

  //  if ($echartLineTotalOrder) {
  //    // Get options from data attribute
  //    let myChart = echarts.init($echartLineTotalOrder);
  //    //let chart = window.echarts.init($echartLineTotalOrder); // Default options

  //    let option:any = {
  //      tooltip: {
  //        triggerOn: 'mousemove',
  //        trigger: 'axis',
  //        padding: [7, 10],
  //        formatter: '{b0}: {c0}',
  //        backgroundColor: "#f9fafd",
  //        borderColor: "#d8e2ef",
  //        textStyle: {
  //          color: "#0b1727"
  //        },
  //        borderWidth: 1,
  //        transitionDuration: 0,
  //        //position: function position(pos, params, dom, rect, size) {
  //        //  return getPosition(pos, params, dom, rect, size);
  //        //}
  //      },
  //      xAxis: {
  //        type: 'category',
  //        data: ['Week 4', 'Week 5'],
  //        boundaryGap: false,
  //        splitLine: {
  //          show: false
  //        },
  //        axisLine: {
  //          show: false,
  //          lineStyle: {
  //            color: "#0b1727",
  //            type: 'dashed'
  //          }
  //        },
  //        axisLabel: {
  //          show: false
  //        },
  //        axisTick: {
  //          show: false
  //        },
  //        axisPointer: {
  //          type: 'none'
  //        }
  //      },
  //      yAxis: {
  //        type: 'value',
  //        splitLine: {
  //          show: false
  //        },
  //        axisLine: {
  //          show: false
  //        },
  //        axisLabel: {
  //          show: false
  //        },
  //        axisTick: {
  //          show: false
  //        },
  //        axisPointer: {
  //          show: false
  //        }
  //      },
  //      series: [{
  //        type: 'line',
  //        lineStyle: {
  //          color: "#2c7be5",
  //          width: 3
  //        },
  //        itemStyle: {
  //          color: "#fff",
  //          borderColor: "#2c7be5",
  //          borderWidth: 2
  //        },
  //        hoverAnimation: true,
  //        data: [20, 130],
  //        connectNulls: true,
  //        smooth: 0.6,
  //        smoothMonotone: 'x',
  //        symbol: 'circle',
  //        symbolSize: 8,
  //        areaStyle: {
  //          color: {
  //            type: 'linear',
  //            x: 0,
  //            y: 0,
  //            x2: 0,
  //            y2: 1,
  //            colorStops: [{
  //              offset: 0,
  //              color: "rgba(44, 123, 229, 0.25)"
  //            }, {
  //              offset: 1,
  //              color: "rgba(44,123,229, 0)"
  //            }]
  //          }
  //        }
  //      }],
  //      grid: {
  //        bottom: '2%',
  //        top: '0%',
  //        right: '10px',
  //        left: '10px'
  //      }
  //    };

  //    myChart.setOption(option);
  //  }
  //};

  //marketShareInit() {
  //  let ECHART_MARKET_SHARE = '.echart-market-share';
  //  let $echartMarketShare = document.querySelector(ECHART_MARKET_SHARE);

  //  if ($echartMarketShare) {

  //    let myChart = echarts.init($echartMarketShare);

  //    let option:any = {
  //      color: ["#2c7be5" //utils.getColors().primary
  //        , "#27bcfd" // utils.getColors().info
  //        , "#d8e2ef" // utils.getGrays()[300]
  //      ],
  //      tooltip: {
  //        trigger: 'item',
  //        padding: [7, 10],
  //        backgroundColor: "#f9fafd", // utils.getGrays()['100'],
  //        borderColor: "#d8e2ef", //utils.getGrays()['300'],
  //        textStyle: {
  //          color: "#0b1727" //utils.getColors().dark
  //        },
  //        borderWidth: 1,
  //        transitionDuration: 0,
  //        formatter: function formatter(params: { data: { name: string; }; percent: string; }) {
  //          return "<strong>".concat(params.data.name, ":</strong> ").concat(params.percent, "%");
  //        }
  //      },
  //      //position: function position(pos: any, params: any, dom: any, rect: any, size: any) {
  //      //  return getPosition(pos, params, dom, rect, size);
  //      //},
  //      legend: {
  //        show: false
  //      },
  //      series: [{
  //        type: 'pie',
  //        radius: ['100%', '87%'],
  //        avoidLabelOverlap: false,
  //        hoverAnimation: false,
  //        itemStyle: {
  //          borderWidth: 2,
  //          borderColor: "#fff" //utils.getColor('card-bg')
  //        },
  //        label: {
  //          normal: {
  //            show: false,
  //            position: 'center',
  //            textStyle: {
  //              fontSize: '20',
  //              fontWeight: '500',
  //              color: "#5e6e82" //utils.getGrays()['700']
  //            }
  //          },
  //          emphasis: {
  //            show: false
  //          }
  //        },
  //        labelLine: {
  //          normal: {
  //            show: false
  //          }
  //        },
  //        data: [{
  //          value: 5300000,
  //          name: 'Samsung'
  //        }, {
  //          value: 1900000,
  //          name: 'Huawei'
  //        }, {
  //          value: 2000000,
  //          name: 'Apple'
  //        }]
  //      }]
  //    };

  //    myChart.setOption(option);
  //  }
  //};

  //totalSalesInit() {
  //  let ECHART_LINE_TOTAL_SALES = '.echart-line-total-sales';
  //  let SELECT_MONTH = '.select-month';
  //  let $echartsLineTotalSales = document.querySelector(ECHART_LINE_TOTAL_SALES);
  //  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  //  function getFormatter(params: any[]) {
  //    let _params$ = params[0],
  //      name = _params$.name,
  //      value = _params$.value;
  //    let date = new Date(name);
  //    return "".concat(months[0], " ").concat(date.getDate().toString(), ", ").concat(value);
  //  }

  //  if ($echartsLineTotalSales) {
  //    // Get options from data attribute

  //    let myChart = echarts.init($echartsLineTotalSales);
  //    let monthsnumber = [[60, 80, 60, 80, 65, 130, 120, 100, 30, 40, 30, 70], [100, 70, 80, 50, 120, 100, 130, 140, 90, 100, 40, 50], [80, 50, 60, 40, 60, 120, 100, 130, 60, 80, 50, 60], [70, 80, 100, 70, 90, 60, 80, 130, 40, 60, 50, 80], [90, 40, 80, 80, 100, 140, 100, 130, 90, 60, 70, 50], [80, 60, 80, 60, 40, 100, 120, 100, 30, 40, 30, 70], [20, 40, 20, 50, 70, 60, 110, 80, 90, 30, 50, 50], [60, 70, 30, 40, 80, 140, 80, 140, 120, 130, 100, 110], [90, 90, 40, 60, 40, 110, 90, 110, 60, 80, 60, 70], [50, 80, 50, 80, 50, 80, 120, 80, 50, 120, 110, 110], [60, 90, 60, 70, 40, 70, 100, 140, 30, 40, 30, 70], [20, 40, 20, 50, 30, 80, 120, 100, 30, 40, 30, 70]];

  //    let option:any =  {
  //        color: "#f9fafd", //utils.getGrays()['100'],
  //        tooltip: {
  //          trigger: 'axis',
  //          padding: [7, 10],
  //          backgroundColor: "#f9fafd", //utils.getGrays()['100'],
  //          borderColor: "#d8e2ef", //utils.getGrays()['300'],
  //          textStyle: {
  //            color: "#0b1727"
  //          },
  //          borderWidth: 1,
  //          formatter: function formatter(params: any) {
  //            return getFormatter(params);
  //          },
  //          transitionDuration: 0,
  //          //position: function position(pos: any, params: any, dom: any, rect: any, size: any) {
  //          //  return getPosition(pos, params, dom, rect, size);
  //          //}
  //        },
  //        xAxis: {
  //          type: 'category',
  //          data: ['2019-01-05', '2019-01-06', '2019-01-07', '2019-01-08', '2019-01-09', '2019-01-10', '2019-01-11', '2019-01-12', '2019-01-13', '2019-01-14', '2019-01-15', '2019-01-16'],
  //          boundaryGap: false,
  //          axisPointer: {
  //            lineStyle: {
  //              color: "#d8e2ef",
  //              type: 'dashed'
  //            }
  //          },
  //          splitLine: {
  //            show: false
  //          },
  //          axisLine: {
  //            lineStyle: {
  //              // color: utils.getGrays()['300'],
  //              color: "rgba(0,0,0, 0.01)", //utils.rgbaColor('#000', 0.01),
  //              type: 'dashed'
  //            }
  //          },
  //          axisTick: {
  //            show: false
  //          },
  //          axisLabel: {
  //            color: "#b6c1d2", //utils.getGrays()['400'],
  //            formatter: function formatter(value: string | number | Date) {
  //              let date = new Date(value);
  //              return "".concat(months[date.getMonth()], " ").concat(date.getDate().toString());
  //            },
  //            margin: 15
  //          }
  //        },
  //        yAxis: {
  //          type: 'value',
  //          axisPointer: {
  //            show: false
  //          },
  //          splitLine: {
  //            lineStyle: {
  //              color: "#d8e2ef",
  //              type: 'dashed'
  //            }
  //          },
  //          boundaryGap: false,
  //          axisLabel: {
  //            show: true,
  //            color: "#b6c1d2", //utils.getGrays()['400'],
  //            margin: 15
  //          },
  //          axisTick: {
  //            show: false
  //          },
  //          axisLine: {
  //            show: false
  //          }
  //        },
  //        series: [{
  //          type: 'line',
  //          data: monthsnumber[0],
  //          lineStyle: {
  //            color: "#2c7be5"
  //          },
  //          itemStyle: {
  //            borderColor: "#2c7be5",
  //            borderWidth: 2
  //          },
  //          symbol: 'circle',
  //          symbolSize: 10,
  //          smooth: false,
  //          hoverAnimation: true,
  //          areaStyle: {
  //            color: {
  //              type: 'linear',
  //              x: 0,
  //              y: 0,
  //              x2: 0,
  //              y2: 1,
  //              colorStops: [{
  //                offset: 0,
  //                color: "rgba(44,123,229, 0.2)"
  //              }, {
  //                offset: 1,
  //                  color: "rgba(44,123,229, 0)"
  //              }]
  //            }
  //          }
  //        }],
  //        grid: {
  //          right: '28px',
  //          left: '40px',
  //          bottom: '15%',
  //          top: '5%'
  //        }
  //      };

  //  myChart.setOption(option);

  //  // Change chart options accordiong to the selected month

  //    let monthSelect = document.querySelector(SELECT_MONTH);

  //    if (monthSelect) {
  //      monthSelect.addEventListener('change', function (e) {
  //        let month = parseInt( (<HTMLInputElement>e.currentTarget).value);
  //        let data = monthsnumber[month];
  //        myChart.setOption({
  //          tooltip: {
  //            formatter: function formatter(params: any[]) {
  //              let _params$2 = params[0],
  //                name = _params$2.name,
  //                value = _params$2.value;
  //              let date = new Date(name);
  //              return "".concat(months[month], " ").concat(date.getDate().toString(), ", ").concat(value);
  //            }
  //          },
  //          xAxis: {
  //            axisLabel: {
  //              formatter: function formatter(value: string | number | Date) {
  //                let date = new Date(value);
  //                return "".concat(months[month], " ").concat(date.getDate().toString());
  //              },
  //              margin: 15
  //            }
  //          },
  //          series: [{
  //            data: data
  //          }]
  //        });
  //      });
  //    }
  //  }
  //};

  showoptions(elemId: string) {
    let v = <HTMLElement>document.getElementById(elemId);
    console.log(v.style.display);
    if (v && v.style.display != 'block') {
      v.style.display = 'block';
    } else {
      v.style.display = 'none';
    }
  }

  linePredictionGraph() {
    //var canvas = <HTMLCanvasElement>document.getElementById("canvas");
    //var ctx = canvas.getContext("2d");
    //if (ctx) {
    //  ctx.strokeStyle = "Green";
    //  var posY = 0;
    //  var lineLength = 10;
    //  var speed = 2;
    //  function drawLine() {
    //    ctx.beginPath();
    //    ctx.moveTo(10, posY);
    //    ctx.lineTo(10, posY + lineLength);
    //    ctx.stroke();
    //  }
    //  function moveLine() {
    //    posY += speed;
    //    if (posY < 0 || posY > canvas.height) {
    //      speed = speed * -1;
    //    }
    //  }
    //  function loop() {
    //    // clear old frame;
    //    ctx.clearRect(0, 0, canvas.width, canvas.height);
    //    moveLine();
    //    drawLine();
    //    requestAnimationFrame(loop);
    //  }
    //  requestAnimationFrame(loop);
    //}
  }

  SmoothRandom(factor: any, start: any) {
    var last = start !== undefined ? start : Math.random();
    var halfEnvelope = 1 / factor / 2;
    return function () {
      // clamp output range to [0,1] as Math.random()
      var max = Math.min(1, last + halfEnvelope);
      var min = Math.max(0, last - halfEnvelope);
      // return a number within halfRange of the last returned value
      return (last = Math.random() * (max - min) + min);
    };
  }

  calcStatus() {
    if (this.cpuEvents.length > 5) {
      return 'High';
    } else if (this.cpuEvents.length > 2) {
      return 'Medium';
    } else {
      return 'Low';
    }
  }
}
