import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'nTableNested',
  templateUrl: 'nTable.html',
  //styleUrls: ['./hero-detail.component.css']
})
export class nTableNestedComponent implements OnInit {
  @Input() headers?: any;
  @Input() nestedHeader?: any;
  @Input() nestedLoadData?: any;
 /* @Input() loadNestedOut?: any;*/
  @Input() data?: any;
  @Input() modalTitle?: any;

  @Input() showModal?: any;

  @Output() hideModal = new EventEmitter<boolean>();


  close(value: boolean) {
    this.hideModal.emit(value);
  }
  funRef: any;
  constructor() {}

  ngOnInit() {
    if (!this.data) {
      this.data = [];
    }
    // console.log("headers", this.headers);
    // console.log("data", this.data);
    // console.log("modaltitle", this.modalTitle);
    // console.log("showmodal", this.showModal);
    this.funRef = {
      loadNested : this.loadNested
    }
  }



  getHeaderNested() {
    return this.nestedHeader;
  }

  hideNestedNested(event: any) {
   
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  loadNested(ref: any, param: any) {

    param.showNested = true;   
    ref.nestedLoadData(ref, param);
  }
}
