import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'multi-select-dropdown',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.css'],
})
export class multiSelectDropdownComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input('options') options: any;
  @Input('title') title: string;
  @Output('update') update = new EventEmitter<any>();

  searchText: string;

  ngOnInit(): void {
    this.initProcess();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.initProcess();
    }
  }

  initProcess() {
    this.options = this.options.map((option: any, index: number) => {
      option['index'] = index;
      return option;
    });
  }

  getSelected(index?: number) {
    let dt = this.options.filter((option: any) => {
      return option.selected;
    });

    if (index) {
      dt = dt.slice(0, index);
    }

    return dt;
  }

  removeOption(index: number) {
    this.onUpdate('remove-item', index);
  }

  addOption(index: number) {
    this.onUpdate('add-item', index);
  }

  onUpdate(event: any, data: any) {
    this.update.emit({ event: event, data: data });
  }

  filterSearchResult() {
    if (!this.searchText || this.searchText == '') {
      return this.options;
    }

    return this.options.filter((option: any) => {
      return (
        option.value.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
      );
    });
  }

  alterOption(event: any, option: any, index: any) {
    event.stopPropagation();
    if (option.selected) {
      this.removeOption(index);
    } else {
      this.addOption(index);
    }
  }

  showoptions: boolean = false;

  removeOptions() {
    if (!this.showoptions) {
      return;
    }

    document.body.removeEventListener('click', this.checkClick);
    this.searchText = '';
    this.showoptions = false;
  }

  checkClick(event: any) {
    if (
      document
        .querySelector('.minfy-ui-multi-select-dropdown')
        .contains(event.target)
    ) {
      return;
    }
    this.removeOptions();
  }

  showOptions() {
    if (this.showoptions) {
      this.removeOptions();
      return;
    }
    document.body.addEventListener('click', (event) => {
      this.checkClick(event);
    });
    this.showoptions = true;
  }

  ngOnDestroy(): void {
    this.removeOptions();
  }
}
