<div>
  <div class="form-group row">
    <label>Rule Name</label>
    <div class="form-edit">
      <input type="text" class="form-control" [(ngModel)]="rule.rule_name">
    </div>
  </div>
  <div class="form-group row" *ngIf="action != 'update_backup_rule'">
    <label>Backup Type</label>
    <div class="backup_types_container" style="margin: 10px">
      <div class="backup_type_container pointer" [ngClass]="{
                  backup_type_selected: rule.backup_type == 'backup_by_tag'
                }">
        <div class="backup_type_box" (click)="rule.backup_type = 'backup_by_tag'">
          <div>
            <div class="backup_type_text">Tag Wise</div>
            <div class="backup_type_desc">Backup will be performed based on the tags associated with the resources
            </div>
          </div>
          <div>
            <div class="indicator">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row" *ngIf="action == 'update_backup_rule'">
    <label>Backup Type</label>
    <div class="form-edit">
      <input type="text" [ngModel]="rule.backup_type || 'backup_by_tag'" class="form-control" disabled>
    </div>
  </div>
  <div class="form-group row" *ngIf="action == 'update_backup_rule'">
    <label>Backup Status</label>
    <div class="form-edit">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="true"
          [(ngModel)]="rule.status">
        <label class="form-check-label" for="flexRadioDefault1">
          Enable
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="false"
          [(ngModel)]="rule.status">
        <label class="form-check-label" for="flexRadioDefault2">
          Disable
        </label>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap; gap: 20px;">
    <div class="form-group row">
      <label>Backup Frequency</label>
      <div class="form-edit" style="display: flex; justify-content: flex-start; align-items: center; gap: 8px;">
        <input type="number" class="form-control" style="width: 150px;" [(ngModel)]="retentions.backup_frequency.value">
        <select class="form-select" [(ngModel)]="retentions.backup_frequency.type">
          <option value="minutes">Minutes</option>
          <option value="hours">Hours</option>
          <option value="days">Days</option>
          <option value="months">Months</option>
          <option value="years">Years</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label>Backup Retention</label>
      <div class="form-edit" style="display: flex; justify-content: flex-start; align-items: center; gap: 8px;">
        <input type="number" class="form-control" style="width: 150px;" [(ngModel)]="retentions.backup_retention.value">
        <select class="form-select" [(ngModel)]="retentions.backup_retention.type">
          <option value="minutes">Minutes</option>
          <option value="hours">Hours</option>
          <option value="days">Days</option>
          <option value="months">Months</option>
          <option value="years">Years</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label>Log Retention</label>
      <div class="form-edit" style="display: flex; justify-content: flex-start; align-items: center; gap: 8px;">
        <input type="number" class="form-control" style="width: 150px;" [(ngModel)]="retentions.log_retention.value">
        <select class="form-select" [(ngModel)]="retentions.log_retention.type">
          <option value="minutes">Minutes</option>
          <option value="hours">Hours</option>
          <option value="days">Days</option>
          <option value="months">Months</option>
          <option value="years">Years</option>
        </select>
      </div>
    </div>
  </div>
  <div class="form-group row" *ngIf="action == 'update_backup_rule'">
    <label>Resources</label>
    <div class="form-edit">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th *ngFor="let h of rulesHeaders">{{h.name}}</th>
            <th></th>
          </tr>
        </thead>

        <tbody [ngStyle]="{'display': rule.tag_key_value_pairs.length > 0 ? 'table-row-group': ''}">
          <tr *ngFor="let i of rule.tag_key_value_pairs; let j = index">
            <td *ngFor="let h of rulesHeaders">
              <input type="text" class="form-control" [(ngModel)]="i[h.id]">
            </td>
            <td><span class="btn btn-light" (click)="removeResource(j)"><i class="fa fa-times"></i></span></td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': rule.tag_key_value_pairs.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="rulesHeaders.length + 1">
              <div style="text-align: center;">No Resources Found.

              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="form-edit" style="display: flex; justify-content: flex-end;">
        <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="addResource()">Add
          Resource</button>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <div class="form-edit" style="display: flex; justify-content: flex-start;">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="updateRule()">{{action ==
        'update_backup_rule' ?
        'Update' : 'Create'}}</button>
    </div>
  </div>
</div>