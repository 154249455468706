<div class="card mt-0">
  <div class="card-body">
    <div class="review-alert mb-3"
      *ngIf="questions.length - fetchTotalAnsweredQuestions().length > 0 && pillers.length > 0 && !isMilestone">
      <div>
        <i class="fa fa-info-circle" aria-hidden="true"></i>
      </div>
      <div class="alert-body">
        <div>
          <div class="alert-title">Continue Reviewing</div>
          <div>You have unanswered questions. Continue reviewing and answer the questions for each pillar.</div>
        </div>
        <div>
          <button class="btn btn-primary-soft continue-lenses" *ngIf="lenses.length > 0">
            <span>Continue Reviewing</span>
            <i class="fa fa-caret-down" aria-hidden="true"></i>
            <div class="continue-lenses-absolute">
              <div *ngFor="let piller of pillers" class="button-lens" (click)="showQuestions(piller['piller'])">
                {{ pillersData[piller['piller']] }}
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="card mt-0">
      <div class="card-header flex-between">
        <h5>Lens Overview</h5>
      </div>
      <div class="card-body">
        <div class="progress-container mb-3">
          <label>Questions Answered ({{
            filterQuestions(selectedLens, 'lensId', true).length
            }}/{{
            filterQuestions(selectedLens, 'lensId', false).length
            }})</label>
          <div class="flex-left">
            <div class="progress">
              <div [ngStyle]="{
                  'background-color': piller['color'],
                  width:
                    loaded ? (this.filterQuestions(piller['piller'], 'PillarId', true)
                      .length /
                      this.filterQuestions(
                        selectedLens,
                        'lensId',
                        false
                      ).length) *
                      100 +
                    '%' : '0%'
                }" class="progress-indicator" *ngFor="let piller of pillers">
                <div class="progress-tooltop">
                  <div class="flex-between">
                    <div class="white-no-wrap">
                      {{ pillersData[piller['piller']] }}
                    </div>
                    <div>
                      {{
                      filterQuestions(piller['piller'], 'PillarId', true)
                      .length
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="progress-container mb-3">
          <label>Risks</label>
          <div class="flex-left">
            <div class="progress">
              <div [ngStyle]="{
                  'background-color': '#c94d6d',
                  width: loaded ? 
                    (fetchQuestionsRisks('High').length /
                      filterQuestions(selectedLens, 'lensId', false).length) *
                      100 +
                    '%' : '0%'
                }" class="progress-indicator">
                <div class="progress-tooltop">
                  <h5>High</h5>
                  <div *ngFor="let piller of pillers">
                    <div class="flex-between">
                      <div class="white-no-wrap">
                        {{ pillersData[piller['piller']] }}
                      </div>
                      <div>
                        {{
                        fetchQuestionsRisks('High', piller['piller']).length
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div [ngStyle]="{
                  'background-color': '#e48b1f',
                  width: loaded ?
                    (fetchQuestionsRisks('Medium').length /
                      filterQuestions(selectedLens, 'lensId', false).length) *
                      100 +
                    '%' : '0%'
                }" class="progress-indicator">
                <div class="progress-tooltop">
                  <h5>Medium</h5>
                  <div *ngFor="let piller of pillers">
                    <div class="flex-between">
                      <div class="white-no-wrap">
                        {{ pillersData[piller['piller']] }}
                      </div>
                      <div>
                        {{
                        fetchQuestionsRisks('Medium', piller['piller']).length
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div [ngStyle]="{
                  'background-color': '#e7c443',
                  width: loaded ? 
                    (fetchQuestionsRisks('Low').length /
                      filterQuestions(selectedLens, 'lensId', false).length) *
                      100 +
                    '%' : '0%'
                }" class="progress-indicator">
                <div class="progress-tooltop">
                  <h5>Low</h5>
                  <div *ngFor="let piller of pillers">
                    <div class="flex-between">
                      <div class="white-no-wrap">
                        {{ pillersData[piller['piller']] }}
                      </div>
                      <div>
                        {{
                        fetchQuestionsRisks('Low', piller['piller']).length
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h5>Pillars</h5>
      </div>
      <div class="card-body" *ngIf="!loading['questions']">
        <table class="table table-docs">
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th>Name</th>
              <th>Questions Answered</th>
              <th>High Risks</th>
              <th>Medium Risks</th>
              <th>Low Risks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let piller of pillers; let i = index">
              <td>{{ i + 1 }}</td>
              <td>
                <span class="nav-link" (click)="selectPiller.emit(piller['piller'])">{{ pillersData[piller['piller']]
                  }}</span>
              </td>
              <td>
                {{ filterQuestions(piller['piller'], 'PillarId', true).length }}/{{ filterQuestions(piller['piller'],
                'PillarId', false).length }}
              </td>
              <td>{{ fetchQuestionsRisks('High', piller['piller']).length }}</td>
              <td>{{ fetchQuestionsRisks('Medium', piller['piller']).length }}</td>
              <td>{{ fetchQuestionsRisks('Low', piller['piller']).length }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body" *ngIf="loading['questions']">
        <div class="loading progress"></div>
      </div>
    </div>
  </div>
</div>