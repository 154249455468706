<div class="card">
  <h5 class="card-header flex-between">
    <div class="flex-start">
      <button class="btn btn-light" (click)="goBack()">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        Back</button>
      <span>
        {{ milestone ? milestone['name'] : selectedWorkload.name}} - {{ lensesData[selectedLens] }}
      </span>
    </div>

    <button class="btn btn-light" style="float: right;" *ngIf="!isMilestone" (click)="resetQuestion()">Reset
    </button>
  </h5>
  <div class="card-body">
    <div class="quiz-app">
      <ng-container *ngIf="showSidebar">
        <div class="pillers_container" style="position: relative;">
          <div style="padding: 10px; text-align: center;">PILLARS</div>
          <ng-container *ngIf="!loading['pillars']">
            <div class="piller" (click)="changePiller(piller)" [ngClass]="{selected: selectedPiller == piller}"
              *ngFor="let piller of pillers">
              <span>{{pillersData[piller]}}</span> <span
                style="background-color: #5864FF; border-radius: 20px; color: white; padding: 3px 10px; font-weight: bold; font-size: 0.8em;">{{filterQuestions(piller,
                'PillarId', true).length}}/{{filterQuestions(piller, 'PillarId').length}}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="loading['pillars']">
            <div class="piller loading_shadhow" style="margin-bottom: 12px;">
              <span style="padding: 5px 100px;"></span>
            </div>
            <div class="piller loading_shadhow" style="margin-bottom: 12px;">
              <span style="padding: 5px 100px;"></span>
            </div>
            <div class="piller loading_shadhow" style="margin-bottom: 12px;">
              <span style="padding: 5px 100px;"></span>
            </div>
            <div class="piller loading_shadhow" style="margin-bottom: 12px;">
              <span style="padding: 5px 100px;"></span>
            </div>
          </ng-container>
          <div class="hideSidebar" (click)="showSidebar = false">
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!showSidebar">
        <div class="hidden_sidebar" (click)="showSidebar = true">
          <i class="fa fa-bars" style="margin-top: 10px;" aria-hidden="true"></i>
        </div>
      </ng-container>
      <div class="questions_container">
        <ng-container *ngIf="questions.length > 0 && filterQuestions().length > 0">
          <div class="quiz_actions">
            <button class="btn btn-light" *ngIf="currentQuestionIndexs[selectedLens][selectedPiller] > 0"
              (click)="prev()">
              < Prev</button>
                <button class="btn btn-light"
                  *ngIf="filterQuestions().length - 1 != currentQuestionIndexs[selectedLens][selectedPiller]"
                  (click)="next()">Next
                  ></button>
                <button class="btn btn-light" *ngIf="!isMilestone"
                  (click)="save(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]])">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 14px;"
                    *ngIf="loading['saving']" />
                  Save</button>

          </div>
          <div class="question">
            <div class="question_title">
              {{filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]].QuestionTitle}}</div>
            <div class="options_container">
              <div class="option"
                [ngClass]="{selected_option_contaniner: checkIfChoiceIsSelected(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option.ChoiceId), 'hover-option': !isMilestone}"
                (click)="selectOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], j)"
                *ngFor="let option of filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]].Choices; let j = index">
                <div class="pointer-none">
                  <input type="checkbox"
                    [attr.checked]="checkIfChoiceIsSelected(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option.ChoiceId) ? true : null"
                    *ngIf="option.Title != 'None of these'" [attr.disabled]="isMilestone ? true: null">
                  <input type="radio" id="option_{{j}}"
                    [attr.checked]="checkIfChoiceIsSelected(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option.ChoiceId) == true ? true : null"
                    *ngIf="option.Title == 'None of these'" [attr.disabled]="isMilestone ? true: null">
                </div>
                <div style="flex: 1;">
                  <div class="option_text">
                    <label for="option_{{j}}"></label>
                    {{option.Title}}
                  </div>
                  <div class="option_description" *ngIf="option.Description && option.Description != ''">
                    {{ option.Description }}
                  </div>

                  <!-- Severity  -->
                  <div class="reject_margin reject_margin_top"
                    *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option) && getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['severity']">
                    <div class="sevirity">
                      <span class="bold">Risk: </span>
                      <span
                        [ngClass]="{red_fancy: getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['severity'] == 'High', yellow_fancy: getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['severity'] == 'Low', orange_fancy: getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['severity'] == 'Medium'}">
                        {{getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option)['severity']}}
                      </span>
                    </div>
                  </div>
                  <ng-container *ngIf="option.Title != 'None of these'">
                    <!-- Evidence -->
                    <div class="reject_margin reject_margin_top"
                      *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option) && getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['source'] != 'Manual'">
                      <ng-container *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                      option)['evidence']">
                        <div class="bold">Evidence</div>
                        <div class="flex-start" *ngFor="let evidence of getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option)['evidence']">
                          <ng-container *ngIf="evidence['message'] != 'Swayam Findings'">
                            <div *ngIf="evidence['status'] === true">
                              <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>
                            </div>
                            <div *ngIf="evidence['status'] === false">
                              <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>
                            </div>
                          </ng-container>
                          <div>{{ evidence['message'] }}
                            <span>
                              <div *ngIf="evidence['info']" (click)="preventDefault($event)"
                                style="vertical-align: top; display: inline-block; position: relative; overflow: hidden;">
                                <ng-container>
                                  <div class="failed_ami" (mouseenter)="setPosition($event)">
                                    <div class="status_container"
                                      style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                                      <span class="nav-link" style="display: inline-block;">
                                        Info
                                      </span>
                                    </div>
                                    <div class="failed_ami_message_container"
                                      [ngStyle]="{right: position.x ? position.x : 0, top: position.y ? position.y : 0, height: position.height, width: position.width}">
                                      <div class="message_back">
                                        <div>
                                          <div class="message_grid"
                                            [ngStyle]="{'max-width': position.overflow ? position.overflowwidth : '', 'overflow-x': position.overflow ? 'auto' : 'visible'}">
                                            <div style="width: 600px; white-space: pre-wrap;">
                                              {{evidence['info']}}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="message_container">
                                        <div>
                                          <div class="message_grid"
                                            [ngStyle]="{'max-width': position.overflow ? position.overflowwidth : '', 'overflow-x': position.overflow ? 'auto' : 'visible'}">
                                            <div style="width: 600px; white-space: pre-wrap;">
                                              {{evidence['info']}}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </span>
                          </div>
                          <div *ngIf="evidence['swayam_link']">
                            <a routerLink="/{{evidence['swayam_link']}}" target="_blank">Swayam Link <i
                                class="fa fa-external-link" aria-hidden="true"></i></a>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option)['evidence']">
                        <div class="bold">Evidence</div>
                        <div *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                          option)['status']">
                          {{getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                          option)['metaData']}}
                        </div>
                        <div *ngIf="!getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                          option)['status']">
                          {{getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                          option)['failurereason']}}
                        </div>
                      </ng-container>
                      <div class="flex-start" *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option)['evidenceStatus'] == undefined && !isMilestone">
                        <button class="btn btn-primary"
                          (click)="validateOption($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option), true)">
                          Valid
                        </button>
                        <button
                          (click)="validateOption($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option), false)"
                          class="btn btn-primary">
                          Invalid
                        </button>
                      </div>
                      <div class="flex-start" *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option)['evidenceStatus'] != undefined">
                        Evidence is {{
                        getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option)['evidenceStatus'] ? 'valid' : 'invalid' }}
                        <div class="nav-link" *ngIf="!isMilestone" (click)="resetEvidence($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option))"><i class="fa fa-pencil" aria-hidden="true"></i> Change</div>
                      </div>
                    </div>

                    <!-- Decision -->
                    <div class="reject_margin reject_margin_top" *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option) && getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['evidenceStatus'] == false && !(isMilestone && !getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                          option)['decision'] != undefined)">
                      <div class="bold">Decision</div>
                      <div class="flex-start" *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                          option)['decision'] == undefined  && !isMilestone">
                        <button class="btn btn-primary"
                          (click)="fixOption($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option), 'Remediate')">
                          Remediate
                        </button>
                        <!-- <button class="btn btn-primary"
                          (click)="fixOption($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option), 'Wont Fix')">
                          Won't Fix
                        </button> -->
                        <button
                          (click)="fixOption($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option), 'Not Applicable')"
                          class="btn btn-primary">
                          Not Applicable
                        </button>
                      </div>
                      <div class="flex-start" style="padding: 0;" *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                          option)['decision'] != undefined">
                        {{
                        getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option)['decision'] }}
                        <div class="nav-link" *ngIf="!isMilestone" (click)="resetFix($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                          option))"><i class="fa fa-pencil" aria-hidden="true"></i> Change</div>
                      </div>
                    </div>

                    <!-- Non Swayam Choices -->
                    <div class="reject_margin reject_margin_top" *ngIf="!getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option) && getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                          option)['Status'] != 'SELECTED' && !(isMilestone && getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                          option)['Status'] != 'NOT_APPLICABLE')">
                      <div class="bold">Decision</div>
                      <div class="flex-start" *ngIf="getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                          option)['Status'] == 'UNSELECTED' && !isMilestone">
                        <!-- <button class="btn btn-primary"
                          (click)="fixOption($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option), 'Remediate')">
                          Remediate
                        </button> -->
                        <!-- <button class="btn btn-primary"
                          (click)="fixOption($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option), 'Wont Fix')">
                          Won't Fix
                        </button> -->
                        <button (click)="fixOption($event, getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                          option), 'NOT_APPLICABLE', 'Status')" class="btn btn-primary">
                          Not Applicable
                        </button>
                      </div>
                      <div class="flex-start" style="padding: 0;" *ngIf="getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                          option)['Status'] == 'NOT_APPLICABLE'">
                        {{
                        getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option)['Status'] }}
                        <div class=" nav-link" *ngIf="!isMilestone" (click)="resetFix($event, getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                          option), 'Status', 'UNSELECTED')"><i class="fa fa-pencil"
                            aria-hidden="true"></i> Change
                        </div>
                      </div>
                    </div>

                    <!-- Improvement Plan  -->
                    <div class="reject_margin reject_margin_top"
                      *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option) && (getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['source'] == 'Manual' || (getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['decision'] && getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['decision'] != 'Not Applicable'))">
                      <div class="bold">Improvement Plan</div>
                      <div>
                        {{getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option)['improvementPlan']}}
                      </div>
                      <div *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                          option)['link']" (click)="preventDefault($event, false)">
                        <a target="_blank" [attr.href]="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                          option)['link']">Reference <i class="fa fa-external-link" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>

                    <!-- Non Swayam Choices - Source ['Manual'] -->
                    <div class="reject_margin reject_margin_top" *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option) && getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['source'] == 'Manual' && getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                                              option)['Status'] != 'SELECTED' && !(isMilestone && getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                                              option)['Status'] != 'NOT_APPLICABLE')">
                      <div class="bold">Decision</div>
                      <div class="flex-start" *ngIf="getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                                              option)['Status'] == 'UNSELECTED' && !isMilestone">
                        <!-- <button class="btn btn-primary"
                                              (click)="fixOption($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option), 'Remediate')">
                                              Remediate
                                            </button> -->
                        <!-- <button class="btn btn-primary"
                                              (click)="fixOption($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option), 'Wont Fix')">
                                              Won't Fix
                                            </button> -->
                        <button (click)="fixOption($event, getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                                              option), 'NOT_APPLICABLE', 'Status')"
                          class="btn btn-primary">
                          Not Applicable
                        </button>
                      </div>
                      <div class="flex-start" style="padding: 0;" *ngIf="getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                                              option)['Status'] == 'NOT_APPLICABLE'">
                        {{
                        getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option)['Status'] }}
                        <div class="nav-link" *ngIf="!isMilestone" (click)="resetFix($event, getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                                              option), 'Status', 'UNSELECTED')"><i class="fa fa-pencil"
                            aria-hidden="true"></i> Change
                        </div>
                      </div>
                    </div>

                    <!-- Best Practices -->
                    <div class="reject_margin reject_margin_top"
                      *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option) && getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['decision'] && getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['decision'] != 'Not Applicable'">
                      <div class="bold">Best Practices</div>
                      <div class="flex-start practice" style="gap: 10px; padding: 5px;"
                        (click)="alterPratice($event, practice)"
                        [ngStyle]="{'pointer-events': isMilestone ? 'none' : 'auto' }"
                        *ngFor="let practice of getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['best_practices']">
                        <div>
                          <ng-container *ngIf="!practice['selected']">
                            <i class="fa-regular fa-square"></i>
                          </ng-container>
                          <ng-container *ngIf="practice['selected']">
                            <i class="fa-regular fa-square-check"></i>
                          </ng-container>
                        </div>
                        <div>
                          {{ practice['practice'] }}
                        </div>
                      </div>
                    </div>

                    <!-- Not Applicable Reason -->
                    <div *ngIf="getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                                              option)['Status'] == 'NOT_APPLICABLE'"
                      class="reject_margin reject_margin_top">
                      <div class="bold">Reason</div>
                      <div class="flex-start" style="padding: 0;" *ngIf="getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                                              option)['reason_dummy'] != undefined && !isMilestone">
                        <select (click)="preventDefault($event)" class="form-select" [(ngModel)]="getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                                                                      option)['reason_dummy']">
                          <option *ngFor="let reason of getObjectKeys(reasonsData)" [ngValue]="reason">
                            {{ reasonsData[reason] }}
                          </option>
                        </select>
                        <button class="btn btn-primary" (click)="resetReason($event,getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                            option), false)">
                          Submit
                        </button>
                        <button *ngIf="getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                            option)['Reason'] != ''" (click)="preventDefault($event) && getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                            option)['reason_dummy'] = undefined" class="btn btn-primary">
                          Cancel
                        </button>
                      </div>
                      <div class="flex-start" style="padding: 0;" *ngIf="getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                                              option)['reason_dummy'] == undefined">
                        {{
                        reasonsData[getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option)['Reason']] }}
                        <div class="nav-link" *ngIf="!isMilestone" (click)="resetReason($event,getOptionSummary(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                                            option), true)"><i class="fa fa-pencil" aria-hidden="true"></i> Change
                        </div>

                      </div>
                    </div>

                    <!-- Comments -->
                    <div class="reject_margin reject_margin_top"
                      *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option) && (getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['decision'] || getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['evidenceStatus'])">
                      <div class="bold">Comment</div>
                      <div>
                        <div
                          *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['comments'] != '' && !getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['edit']">
                          {{getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                          option)['comments']}} <span class="nav-link" *ngIf="!isMilestone"
                            style="display: inline-block;"
                            (click)="editComment($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option))"><i
                              class="fa fa-pencil" aria-hidden="true"></i>
                            Edit</span></div>

                        <div
                          *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['edit'] && !isMilestone"
                          class="flex-start" style="padding: 0;">
                          <input type="text" class="form-control"
                            [(ngModel)]="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['comments']"
                            (click)="preventDefault($event)">
                          <button class="btn btn-primary"
                            (click)="preventDefault($event) && getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['edit'] = undefined">
                            Comment
                          </button>
                        </div>
                      </div>
                      <div class="flex-start" style="padding: 0;"
                        *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['comments'] == '' && !isMilestone">
                        <input type="text" class="form-control" (click)="preventDefault($event)"
                          [(ngModel)]="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['dummy_comment']">
                        <button class="btn btn-primary"
                          (click)="addComment($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option))">
                          Comment
                        </button>
                      </div>
                    </div>

                  </ng-container>

                  <!-- Comments for None of These -->
                  <div class="reject_margin reject_margin_top"
                    *ngIf="option.Title == 'None of these' && checkIfChoiceIsSelected(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option.ChoiceId) == true">
                    <div class="bold">Comment</div>
                    <div
                      *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['comments'] != ''">
                      <div
                        *ngIf="!getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['edit']">
                        {{getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]],
                        option)['comments']}} <span class="nav-link" *ngIf="!isMilestone" style="display: inline-block;"
                          (click)="editComment($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option))"><i
                            class="fa fa-pencil" aria-hidden="true"></i>
                          Edit</span></div>

                      <div
                        *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['edit'] && !isMilestone"
                        class="flex-start">
                        <input type="text" class="form-control"
                          [(ngModel)]="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['comments']"
                          (click)="preventDefault($event)">
                        <button class="btn btn-primary"
                          (click)="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['edit'] = undefined && preventDefault($event)">
                          Comment
                        </button>
                      </div>
                    </div>
                    <div class="flex-start"
                      *ngIf="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['comments'] == '' && !isMilestone">
                      <input type="text" class="form-control" (click)="preventDefault($event)"
                        [(ngModel)]="getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option)['dummy_comment']">
                      <button class="btn btn-primary"
                        (click)="addComment($event, getSwayamOption(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]], option))">
                        Comment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- question bottom actions -->
          <div class="quiz_actions">
            <button class="btn btn-light" *ngIf="currentQuestionIndexs[selectedLens][selectedPiller] > 0"
              (click)="prev()">
              < Prev</button>
                <button class="btn btn-light"
                  *ngIf="filterQuestions().length - 1 != currentQuestionIndexs[selectedLens][selectedPiller]"
                  (click)="next()">Next
                  ></button>
                <button class="btn btn-light" *ngIf="!isMilestone"
                  (click)="save(filterQuestions()[currentQuestionIndexs[selectedLens][selectedPiller]])">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 14px;"
                    *ngIf="loading['saving']" />
                  Save</button>
          </div>
        </ng-container>
        <ng-container *ngIf="!loading['questions'] && filterQuestions().length == 0">
          <div style="text-align: center;">
            No Questions Found
          </div>
        </ng-container>
        <ng-container *ngIf="loading['questions']">
          <div class="question" style="pointer-events: none; margin: 50px 0px;">
            <div class="question_title loading_shadhow" style="padding: 8px; width: 100%;">
            </div>
            <div class="options_container" style="width: 100%;">
              <div class="option" style="width: 100%;">
                <div style="width: 100%;">
                  <div class="option_text loading_shadhow" style="margin-bottom: 8px; padding: 7px; width: 70%;">
                  </div>
                  <div class="option_description loading_shadhow"
                    style="margin-bottom: 8px; padding: 5px; width: 100%;">
                  </div>
                  <div class="option_description loading_shadhow"
                    style="margin-bottom: 8px; padding: 5px; width: 100%;">
                  </div>
                  <div class="option_description loading_shadhow" style="margin-bottom: 8px; padding: 5px; width: 30%;">
                  </div>
                </div>
              </div>
              <div class="option" style="width: 100%;">
                <div style="width: 100%;">
                  <div class="option_text loading_shadhow" style="margin-bottom: 8px; padding: 7px; width: 70%;">
                  </div>
                  <div class="option_description loading_shadhow"
                    style="margin-bottom: 8px; padding: 5px; width: 100%;">
                  </div>
                  <div class="option_description loading_shadhow"
                    style="margin-bottom: 8px; padding: 5px; width: 100%;">
                  </div>
                  <div class="option_description loading_shadhow" style="margin-bottom: 8px; padding: 5px; width: 30%;">
                  </div>
                </div>
              </div>
              <div class="option" style="width: 100%;">
                <div style="width: 100%;">
                  <div class="option_text loading_shadhow" style="margin-bottom: 8px; padding: 7px; width: 70%;">
                  </div>
                  <div class="option_description loading_shadhow"
                    style="margin-bottom: 8px; padding: 5px; width: 100%;">
                  </div>
                  <div class="option_description loading_shadhow"
                    style="margin-bottom: 8px; padding: 5px; width: 100%;">
                  </div>
                  <div class="option_description loading_shadhow" style="margin-bottom: 8px; padding: 5px; width: 30%;">
                  </div>
                </div>
              </div>
              <div class="option" style="width: 100%;">
                <div style="width: 100%;">
                  <div class="option_text loading_shadhow" style="margin-bottom: 8px; padding: 7px; width: 70%;">
                  </div>
                  <div class="option_description loading_shadhow"
                    style="margin-bottom: 8px; padding: 5px; width: 100%;">
                  </div>
                  <div class="option_description loading_shadhow"
                    style="margin-bottom: 8px; padding: 5px; width: 100%;">
                  </div>
                  <div class="option_description loading_shadhow" style="margin-bottom: 8px; padding: 5px; width: 30%;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="question_switcher">
        <div class="question_slider">
          <div style="margin-left: 80px; padding: 10px;">QUESTIONS</div>
          <div *ngFor="let question of filterQuestions(); let i = index" class="question_number"
            (click)="changeQuestion(i)">
            <div style="font-weight: bold;" class="question_no">
              <span
                [ngClass]="{current_question: i == currentQuestionIndexs[selectedLens][selectedPiller], answerd_question: checkIfAnswered(question), un_answerd_question: !checkIfAnswered(question)}">
                {{i + 1}}
              </span>
            </div>
            <div style="white-space: nowrap;" class="question_switcher_text_container">
              <span class="question_switcher_text" title="{{question.QuestionTitle}}"
                [ngStyle]="{'font-weight': i == currentQuestionIndexs[selectedLens][selectedPiller] ? 'bold': 'normal'}">
                {{question.QuestionTitle}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-save-dialog *ngIf="unsavedChanges['status']" (popupAction)="popupAction($event)"></app-save-dialog>