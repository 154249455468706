import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

declare let window: any;

@Component({
  selector: 'app-validate-change-request',
  templateUrl: './validate-change-request.component.html',
  styleUrls: ['./validate-change-request.component.css'],
})
export class ValidateChangeRequestComponent implements OnInit {
  account_id: string;
  region_id: string;
  guid: string;

  constructor(private route: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((s: any) => {
      this.account_id = s['account_id'];
      this.region_id = s['region_id'];
      this.guid = s['guid'];
    });

    this.load();
  }

  otpForm: boolean = false;
  async load() {
    console.log(this.account_id, this.region_id, this.guid);
  }
}
