<div class="alarm_screen">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4>{{ budgetRaw['action'] == 'save' ? 'Add' : 'Update' }} Budget</h4>
    </div>
    <div class="alarm_body">
      <div class="row">
        <label>LID</label>
        <span
          style="display: flex; justify-content: space-between; align-items: center; gap: 10px; padding-right: 0px;">
          <select style="flex: 1;" class="form-control" [(ngModel)]="budgetRaw.lid" id="linkedAccountIDSelect"
            [ngStyle]="{display: budgetRaw['action'] == 'save' ? 'block': 'none'}" data-live-search="true"
            (change)="loadBills()">
            <option [attr.data-content]="getHTML(lid)" [ngValue]="lid.lid" *ngFor="let lid of lids"
              [attr.disabled]="checkForExists(lid) ? true: null">
            </option>
          </select>
          <img *ngIf="fetchingLinkedAccountIDs" src="../assets/img/loading_2.svg" alt="loading scans"
            style="width: 18px; margin: 10px" />
          <span *ngIf="budgetRaw['action'] == 'update'" style="font-weight: bold;">{{budgetRaw['lid']}}
            {{budgetRaw['accountName'] != '-' ? '(' + budgetRaw['accountName'] + ')' : ''}}</span>
        </span>
        <span style="font-weight: bold; color: rgb(201, 77, 109);"
          *ngIf="budgetRaw['action'] == 'save' && checkForExists(budgetRaw['lid'])">
          Budget already exists with selected LID
        </span>
      </div>
      <div class="row">
        <label>Budget</label>
        <input type="number" [(ngModel)]="budgetRaw.budget" name="" id="" class="form-control"
          pattern="[\+\-]?\d+(\.\d+)?" title="Only Numbers and dot(.)  is allowed" />
        <span style="display: flex; justify-content: flex-start; align-items: center; gap: 10px; padding: 8px 12px;">
          <div>
            <strong>Previous Month: </strong>
            <span>{{bills[previous_year]}}</span>
          </div>
          <div>
            <strong>Current Month: </strong>
            <span>{{bills[current_year]}}</span>
          </div>
          <div *ngIf="loading['bills']">
            <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px;" />
          </div>
        </span>
      </div>
      <div class="row">
        <label>Threshold (%)</label>
        <input type="number" min="0" max="100" maxlength="3" [(ngModel)]="budgetRaw.threshHold" name="" id=""
          class="form-control" />
      </div>
      <div class="row">
        <label>Daily Variance</label>
        <select name="" id="" class="form-select" style="margin-left: 11px;" [(ngModel)]="budgetRaw.dailyVariance">
          <option [ngValue]="option.id" *ngFor="let option of variances">{{ option['name'] }}</option>
        </select>
      </div>
      <div class="row">
        <label>Email IDs</label>
        <div class="additional_contacts">
          <div class="additional_contact" style="width: auto" *ngFor="
              let contact of budgetRaw.eid;
              let i = index;
              trackBy: trackByFn
            ">
            <input type="text" name="contact_value" class="form-control" *ngIf="budgetRaw.eid.length - 3 < i; i"
              (keyup)="checkForLast(contact, i)" [(ngModel)]="budgetRaw.eid[i]" />
            <input type="text" name="contact_value" class="form-control" *ngIf="budgetRaw.eid.length - 3 >= i"
              [(ngModel)]="budgetRaw.eid[i]" />
            <div>
              <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add" *ngIf="budgetRaw.eid.length == i + 1"
                (click)="addAdditionalContact()"></i>
            </div>
            <div>
              <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove" *ngIf="budgetRaw.eid.length > 1"
                (click)="deleteSelectedAdditionalContact(i)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alarm_actions">
      <div style="display: flex; justify-content: flex-end">
        <button class="btn"
          [attr.disabled]="(updating || checkForExists(budgetRaw['lid'])) && this.budgetRaw['action'] == 'save' ? true : null"
          [ngStyle]="{opacity: (updating || checkForExists(budgetRaw['lid'])) && this.budgetRaw['action'] == 'save' ? '0.6' : '1'}"
          (click)="updateBudget()" style="background-color: #5864ff; color: white">
          <img *ngIf="updating" src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px;" />
          {{ budgetRaw['action'] == 'save' ? 'Add' : 'Update' }}
        </button>
        <button class="btn btn-light" (click)="close(false)">Cancel</button>
      </div>
    </div>
  </div>
</div>