<div class="card">
  <h5 class="card-header">Upload BOM</h5>

  <div class="card-body" [ngStyle]="{'display': stepCount == 1 ? 'block' : 'none'}">

    <div style="text-align:center">


      <div style="text-align: center;">
        <div
          style="text-align: center;border: 3px dashed #cccccc7d;width: 299px;padding: 0px 50px 50px 50px;margin: auto;/* box-shadow: 0px 1px 6px 2px #80808024; */margin-top: 25px;">

          <span onclick="$('#bomFile').click()"
            style="color: #5864ffd9; font-size: 180px; display: block; text-align: center; height: 209px; padding: 0;"><i
              aria-hidden="true" class="fa fa-cloud-upload"></i></span>
          <span *ngIf="bomFile && bomFile.fileName">
            {{bomFile.fileName}}
          </span>
          <button class="btn btn-light"
            style="background-color: #5864ffd9;color: white;padding: 10px 10px;margin-top: 10px;width: 221px;margin-left: -17px;"
            *ngIf="bomFile" (click)="uploadFile()"> Update and Process </button>
        </div>
      </div>


      <!--<span onclick="$('#bomFile').click()" style="
    color: #5864ff;
    font-size: 180px;
    display: block;
    text-align: center;
"><i aria-hidden="true" class="fa fa-cloud-upload"></i></span>

      <button class="btn btn-light" style="background-color: #5864ff; color: white; padding:25px" (click)="uploadFile()">
        Update and
        Process
      </button>-->


    </div>


    <div style="display: none; ">
      <div>
        <input type="file" id="bomFile" (change)="setFile($event)">
      </div>
    </div>

  </div>

  <div class="card-body" [ngStyle]="{'display': stepCount == 2 ? 'block' : 'none'}">
    <div>
      <div style="display: flex; justify-content: flex-end; align-items: center; gap: 8px;">
        <button style="background-color: #5864ff; color: white;" class="btn btn-light'"
          (click)="stepCount = 1">Back</button>
        <button class="btn btn-light" style="background-color: #5864ff; color: white;"
          (click)="submitHeaders()">Next</button>

      </div>
      <table>
        <tbody>
          <tr *ngFor="let header of headerList; let i = index">
            <td>{{header.headerKey}}</td>
            <td>
              <select class="form-control" [(ngModel)]="header.headerValue">
                <option value="none">None</option>
                <option value='cpu'>CPU</option>
                <option value='disk'>DISK</option>
                <option value='instanceType'>Instance Type</option>
                <option value='os'>OS</option>
                <option value='ram'>RAM</option>
                <option value='unit'>Unit</option>
                <option value='region'>Region</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card-body" [ngStyle]="{'display': stepCount == 3 ? 'block' : 'none'}">
    <div>
      <div style="display: flex; gap: 8px; justify-content: flex-end; align-items: center;">
        <button style="background-color: #5864ff; color: white;" class="btn btn-light'"
          (click)="stepCount = stepCount - 1">Back</button>
        <button style="background-color: #5864ff; color: white;" class="btn btn-light'" (click)="addBOMEntry()">Add BOM
          Details</button>
        <button style="background-color: #5864ff; color: white;" class="btn btn-light'" (click)="submitBOMDetails()"
          *ngIf="!loading_recom && mappedData.length > 0">Submit</button>
      </div>
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th *ngFor="let h of headers">
              {{h.name}}
            </th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody [ngStyle]="{'display' : mappedData.length > 0 ? 'table-row-group' : 'nonw'}">
          <tr *ngFor="let i of mappedData;let index = index">
            <td>{{index + 1}}</td>
            <td>
              <select class="form-select" (change)="getEC2Pricing(i)" [(ngModel)]="i.os" data-live-search="true">
                <option *ngFor="let os of platformTypes" value="{{os}}">{{os}}</option>
              </select>
            </td>
            <td>
              <select class="form-select" (change)="changeInstanceType(i)" [(ngModel)]="i.instanceType"
                data-live-search="true">
                <option *ngFor="let type of instanceTypes" value="{{type}}">{{type}}</option>
              </select>
            </td>
            <td>
              <select class="form-select" [(ngModel)]="i.region" data-live-search="true">
                <option *ngFor="let region of regions" value="{{region.id}}">{{region.name}}</option>
              </select>
            </td>
            <td>
              <input class="form-control" [disabled]="true" type="text" [ngModel]="i.cpu">
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="i.disk">
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="i.quantity">
            </td>
            <td>
              <input class="form-control" [disabled]="true" type="text" [ngModel]="i.ram">
            </td>
            <td>
              <select class="form-select" [(ngModel)]="i.billingType" (change)="getEC2Pricing(i)">
                <option *ngFor="let type of billingTypes" value="{{type}}">{{type}}</option>
              </select>
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="i.usageHours">
            </td>
            <td>
              <span type="text">
                <span *ngIf="i.hourlyCost == 'LOADING_ICON'" class='center'>
                  <img src="../assets/img/loading_2.svg" alt="loading scans" width="18"
                    style="width: 25px; margin: 10px;">
                </span>
                <span *ngIf="i.hourlyCost != 'LOADING_ICON'" class='center'>
                  <span>{{i.hourlyCost}}</span>
                </span>
              </span>
            </td>
            <td>
              <span type="text">
                <span *ngIf="i.hourlyCost == 'LOADING_ICON'" class='center'>
                  <img src="../assets/img/loading_2.svg" alt="loading scans" width="18"
                    style="width: 25px; margin: 10px;">
                </span>
                <span *ngIf="i.hourlyCost != 'LOADING_ICON'" class='center'>
                  <span>{{getTotal(i)}}</span>
                </span>
              </span>
            </td>
            <td><span class="btn"><i class="fa fa-trash" (click)="deleteDetail(index)"></i></span></td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': mappedData.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="headers.length + 2">
              <div style="text-align: center;">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<error-upload-bom *ngIf="fileExists" (hideModel)="uploadFileError($event)"></error-upload-bom>
<update-bom *ngIf="selectedBOM" [action]="'local'" [bom]="selectedBOM" (hideModel)="hideModel($event)"></update-bom>