<div class="card">
  <div class="card-header" style="display: flex; justify-content: space-between; align-items: center">
    <div *ngIf="!activeAccount && !analytics">
      Billing Data
    </div>
    <div *ngIf="activeAccount">
      <button class="btn btn-light" (click)="goBack()">&lt; Back</button>
      <span>Bills - {{ activeAccount.lid }}
        {{
        activeAccount.accname ? "(" + activeAccount.accname + ")" : ""
        }}</span>
    </div>
    <!-- <button style="float: right;" class="btn btn_blue" (click)="downloadAl()">Download All</button> -->

  </div>
  <div class="card-body">
    <div
      [ngStyle]="{'justify-content': diffDays > 0 ? 'space-between': 'flex-end', display: !analytics ? 'flex' : 'none'}">
      <div [ngStyle]="{display: diffDays > 0 ? 'block': 'none'}">
        <span style="font-weight: bold">Estimated Usage Details: </span><span>{{ startOf }} - {{ endOf }}</span>
      </div>
      <div style="display: flex; gap: 10px; align-items: center">
        <div [ngStyle]="{display: diffDays > 0 ? 'block': 'none'}">
          <span style="font-weight: bold">Master Accounts: </span>
          <span>
            <select style="width: 300px" class="form-control" id="masterAccountSelect" data-live-search="true"
              (change)="fetchMasterAccounts()">
              <option [value]="account" *ngFor="let account of masterAccounts">
                {{ account }}
              </option>
            </select>
          </span>
        </div>
        <input class="datetimepicker" type="text" [(ngModel)]="dateRange" />
      </div>
    </div>
  </div>
</div>
<div class="card" [ngStyle]="{ display: !activeAccount && !analytics ? 'block' : 'none' }">
  <div class="card-body">
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="
              let h of diffDays > 0 ? latestDataSummaryheader : summaryHeader
            ">
            {{ h.name }}
          </th>
          <td>Analytical Report</td>
        </tr>
      </thead>

      <tbody [ngStyle]="{ display: summary.length > 0 ? 'table-row-group' : 'none' }">
        <tr *ngFor="let i of summary; let j = index">
          <td>{{ j + 1 }}</td>
          <td *ngFor="
              let h of diffDays > 0 ? latestDataSummaryheader : summaryHeader
            ">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span>
                {{ i[h.id] }}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{ i[h.id] }}
            </span>
          </td>
          <td>
            <span class="btn btn-light" (click)="showAnalytics(i)">
              Analytical Report
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{
          display: summary.length == 0 ? 'table-row-group' : 'none'
        }">
        <tr>
          <td [attr.colspan]="
              (diffDays > 0
                ? latestDataSummaryheader.length
                : summaryHeader.length) + 2
            ">
            <div style="text-align: center">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="card" *ngIf="activeAccount && !analytics">
  <div class="card-header" style="display: flex; justify-content: space-between; align-items: center">
    <div>

    </div>
    <div style="display: flex; justify-content: flex-end; align-items: center;">
      <span style="margin-right: 10px" [innerHTML]="'Total: ' + getTotal() || 0"></span>
      <div>
        <button class="btn btn-secondary" id="dropdownMenuLinkID">
          Download
          <div class="dropdownMenuLink">
            <div class="dropdown-item" (click)="exportPDF($event)">PDF</div>
            <div class="dropdown-item" (click)="loadEC2Ddata($event)">CSV</div>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="billing_summary">
      <div class="service" (click)="billingOf = 'ec2'" [ngClass]="{ 'active-service': billingOf == 'ec2' }">
        <div class="service_container">
          <img src="./../../../assets/img/sawyam test icons.png" alt="" />
          <div class="esrvice_name">AWS Elastic Compute Cloud</div>
        </div>
        <div *ngIf="!servicesData['ec2']['discountedCost']" class="bold_text">
          ${{ servicesData["ec2"]["service-cost"] || 0 }}
        </div>
        <div *ngIf="servicesData['ec2']['discountedCost']">
          $<span *ngIf="
              servicesData['ec2']['service-cost'] >
              servicesData['ec2']['discountedCost']
            "><span class="line_through">{{
              servicesData["ec2"]["service-cost"] || 0
              }}</span><span class="bold_text">{{
              servicesData["ec2"]["discountedCost"] || 0
              }}</span></span>
          <span *ngIf="
              servicesData['ec2']['service-cost'] <
              servicesData['ec2']['discountedCost']
            "><span class="line_through">{{
              servicesData["ec2"]["discountedCost"] || 0
              }}</span><span class="bold_text">{{
              servicesData["ec2"]["service-cost"] || 0
              }}</span></span>
        </div>
      </div>
      <div class="service" (click)="billingOf = 'rds'" [ngClass]="{ 'active-service': billingOf == 'rds' }">
        <div class="service_container">
          <img src="./../../../assets/img/aws-rds.png" alt="" />
          <div class="esrvice_name">AWS Relational Database Service</div>
        </div>
        <div *ngIf="!servicesData['rds']['discountedCost']" class="bold_text">
          ${{ servicesData["rds"]["service-cost"] || 0 }}
        </div>
        <div *ngIf="servicesData['rds']['discountedCost']">
          $<span *ngIf="
              servicesData['rds']['service-cost'] >
              servicesData['rds']['discountedCost']
            "><span class="line_through">{{
              servicesData["rds"]["service-cost"] || 0
              }}</span><span claas="bold_text">{{
              servicesData["rds"]["discountedCost"] || 0
              }}</span></span>
          <span *ngIf="
              servicesData['rds']['service-cost'] <
              servicesData['rds']['discountedCost']
            "><span class="line_through">{{
              servicesData["rds"]["discountedCost"] || 0
              }}</span><span class="bold_text">{{
              servicesData["rds"]["service-cost"] || 0
              }}</span></span>
        </div>
      </div>
      <div class="service" (click)="billingOf = 'cloudfront'"
        [ngClass]="{ 'active-service': billingOf == 'cloudfront' }">
        <div class="service_container">
          <img src="./../../../assets/img/aws-cloudfront.png" alt="" />
          <div class="esrvice_name">AWS CloudFront</div>
        </div>
        <div *ngIf="!servicesData['cloudfront']['discountedCost']" class="bold_text">
          ${{ servicesData["cloudfront"]["service-cost"] || 0 }}
        </div>
        <div *ngIf="servicesData['cloudfront']['discountedCost']">
          $<span *ngIf="
              servicesData['cloudfront']['service-cost'] >
              servicesData['cloudfront']['discountedCost']
            "><span class="line_through">{{
              servicesData["cloudfront"]["service-cost"] || 0
              }}</span><span class="bold_text">{{
              servicesData["cloudfront"]["discountedCost"] || 0
              }}</span></span>
          <span *ngIf="
              servicesData['cloudfront']['service-cost'] <
              servicesData['cloudfront']['discountedCost']
            "><span class="line_through">{{
              servicesData["cloudfront"]["discountedCost"] || 0
              }}</span><span class="bold_text">{{
              servicesData["cloudfront"]["service-cost"] || 0
              }}</span></span>
        </div>
      </div>
      <div class="service" (click)="billingOf = 'others'" [ngClass]="{ 'active-service': billingOf == 'others' }">
        <div class="service_container">
          <img src="./../../../assets/img/aws-others.png" alt="" />
          <div class="esrvice_name">Others</div>
        </div>
        <div *ngIf="!servicesData['others']['discountedCost']" class="bold_text">
          ${{ servicesData["others"]["service-cost"] || 0 }}
        </div>
        <div *ngIf="servicesData['others']['discountedCost']">
          $<span *ngIf="
                        servicesData['others']['service-cost'] >
                        servicesData['others']['discountedCost']
                      "><span class="line_through">{{
              servicesData["others"]["service-cost"] || 0
              }}</span><span class="bold_text">{{
              servicesData["others"]["discountedCost"] || 0
              }}</span></span>
          <span *ngIf="
                        servicesData['others']['service-cost'] <
                        servicesData['others']['discountedCost']
                      "><span class="line_through">{{
              servicesData["others"]["discountedCost"] || 0
              }}</span><span class="bold_text">{{
              servicesData["others"]["service-cost"] || 0
              }}</span></span>
          <span *ngIf="
                        servicesData['others']['service-cost'] ==
                        servicesData['others']['discountedCost']
                      "><span class="bold_text">{{
              servicesData["others"]["service-cost"] || 0
              }}</span></span>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="billingOf == 'ec2'">
      <ec2-billing [activeAccount]="activeAccount" [diff_days]="diffDays" [monthYear]="dateRange"></ec2-billing>
    </div>
    <div class="row" *ngIf="billingOf == 'rds'">
      <rds-billing [activeAccount]="activeAccount" [diff_days]="diffDays" [monthYear]="dateRange"></rds-billing>
    </div>
    <div class="row" *ngIf="billingOf == 'cloudfront'">
      <cloudfront-billing [activeAccount]="activeAccount" [diffDays]="diffDays" [monthYear]="dateRange">
      </cloudfront-billing>
    </div>
    <div class="row" style="margin-top: 10px" *ngIf="billingOf == 'others'">
      <div style="display: flex; justify-content: right" *ngIf="servicesData.others.services.length > 0">
        <button class="btn btn-light" style="background-color: #5864ff; color: white" (click)="export()">
          Export
        </button>
      </div>
      <table class="table table-docs table-hover" id="others-table" style="margin-top: 20px"
        [ngStyle]="{ display: billingOf == 'others' ? 'table' : 'none' }">
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of billsHeader">{{ h.name }}</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let i of servicesData.others.services; let j = index"
            [ngStyle]="{ display: getOthers(i) ? 'table-row' : 'none' }">
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of billsHeader">
              <span *ngIf="h.clickCheck && h.clickCheck(i[h.id])" class="btn" (click)="callFunction(h.click, i)">
                <span [innerHTML]="i[h.id] || 0">
                </span>
              </span>
              <span *ngIf="h.clickCheck && !h.clickCheck(i[h.id])">
                <span [innerHTML]="i[h.id] || 0">
                </span>
              </span>
              <span *ngIf="!h.clickCheck" [innerHTML]="i[h.id] || 0">
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<analytics-billing *ngIf="analytics" [activeAccount]="analytics" (hideModal)="hideAnalytics($event)">
</analytics-billing>

<!-- <download-all-billing *ngIf="downloadAll" [region_name]="regionId" [month_year]="dateRange" [data]="downloadAll"
  (hideModal)="cancelDownloadAll($event)">
</download-all-billing> -->