import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { APIService } from '../../api/api.service';
import { NotifierService } from '../../_services/notifier.service';

import * as moment from 'moment';

declare let flatpickr: any;
declare let window: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './view.scheduler.component.html',
  styleUrls: ['./scheduler.component.css'],
})
export class viewSchedulertComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  showCreateShedule = false;
  showLog = false;

  funRef: any = {};
  accountId: any;
  regionId: any = 'ap-south-1';
  currentMessage: any;

  showInnerData: boolean = false;
  scheduleHeaders: any = [
    {
      id: 'name',
      name: 'Name',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
    { id: 'action', name: 'Action' },
    {
      id: 'desc',
      name: 'desc',
    },
    {
      id: 'startDateTime',
      name: 'Start Date',
      sort: { sort: true, direction: 'Asc' },
      pipe: this.filterDate,
      transform: (data: any, value: any): String => {
        return data[value]
          ? moment(data[value]).utc().format('DD-MMM-YYYY hh:mm A')
          : '-';
      },
    },
    {
      id: 'endDateTime',
      name: 'End Date',
      sort: { sort: true, direction: 'Asc' },
      pipe: this.filterDate,
      transform: (data: any, value: any): String => {
        return data[value]
          ? moment(data[value]).utc().format('DD-MMM-YYYY hh:mm A')
          : '-';
      },
    },
    { id: 'action', name: 'Action' },
    {
      id: 'status',
      name: 'Status',
      transform: (data: any, value: any): String => {
        return data[value] == 'P' ? 'Paused' : 'Active';
      },
    },
  ];

  targetEC2Header: any = [
    {
      id: 'instanceId',
      name: 'Instance Id',
      show: true,
      //sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      //style: { width: 0 },
      //click: 'showData',
    },
    {
      id: 'tagName',
      name: 'Name',
      show: true,
      type: 'String',
      filter: true,
    },
    {
      id: 'instanceType',
      name: 'Instance Type',
      show: true,
      type: 'String',
      filter: true,
    },
    {
      id: 'publicIp',
      name: 'Private IP / Public IP',
      show: true,
      type: 'String',
      filter: false,
    },
    {
      id: 'instanceStatus',
      name: 'Instance Status',
      show: true,
      type: 'String',
      filter: true,
    },
  ];

  cShedule: any = {
    _id: '0',
    frequency: 'Once',
    rule: {
      monthyRepeat: 'Days',
      frequencyWeekDay: {
        Friday: false,
        Monday: false,
        Saturday: false,
        Sunday: false,
        Thursday: false,
        Tuesday: false,
        Wednesday: false,
      },
      dayOfMonth: [],
    },
    retention: {
      unit: 'Always',
      value: 1,
    },
    alerts: {
      onSuccess: '',
      onError: '',
      others: '',
    },
  };

  dayOfMonth: any = [];
  instancesList: any = [];
  rdsList: any = [];
  rdsClusterList: any = [];
  tagList: any = [];
  scheduleRules: any = [];

  subView = 'Basic Details';
  public regions = [
    { id: 'ap-south-1', name: 'Asia Pacific (Mumbai) ap-south-1' },
    { id: 'us-east-1', name: 'US East (N. Virginia) us-east-1' },
    { id: 'us-east-2', name: 'US East (Ohio) us-east-2' },
    { id: 'us-west-1', name: 'US West (N. California) us-west-1' },
    { id: 'us-west-2', name: 'US West (Oregon)us-west-2' },
    { id: 'ap-northeast-3', name: 'Asia Pacific (Osaka-Local) ap-northeast-3' },
    { id: 'ap-northeast-2', name: 'Asia Pacific (Seoul) ap-northeast-2' },
    { id: 'ap-southeast-1', name: 'Asia Pacific (Singapore) ap-southeast-1' },
    { id: 'ap-southeast-2', name: 'Asia Pacific (Sydney) ap-southeast-2' },
    { id: 'ap-northeast-1', name: 'Asia Pacific (Tokyo) ap-northeast-1' },
    { id: 'ca-central-1', name: 'Canada (Central) ca-central-1' },
    { id: 'eu-central-1', name: 'Europe (Frankfurt) eu-central-1' },
    { id: 'eu-west-1', name: 'Europe (Ireland) eu-west-1' },
    { id: 'eu-west-2', name: 'Europe (London) eu-west-2' },
    { id: 'eu-west-3', name: 'Europe (Paris) eu-west-3' },
    { id: 'eu-north-1', name: 'Europe (Stockholm) eu-north-1' },
    { id: 'me-south-1', name: 'Middle East (Bahrain) me-south-1' },
    { id: 'sa-east-1', name: 'South America (São Paulo) sa-east-1' },
    { id: 'ap-east-1', name: 'Asia Pacific (Hong Kong) ap-east-1' },
    { id: 'af-south-1', name: 'Africa (Cape Town) af-south-1' },
    { id: 'ap-southeast-3', name: 'Asia Pacific (Jakarta) ap-southeast-3' },
    { id: 'eu-south-1', name: 'Europe (Milan) eu-south-1' },
  ];
  public selectedRegion = 'Asia Pacific (Mumbai) ap-south-1';
  public scheduleMaps: any;

  public logShData: any;
  public logData: any;
  public otpModal = false;
  public otpValue = '';

  public cancelOTP = false;
  public otpValid = false;
  public otpValided = false;
  public optErrorMSG = '';
  public otpPurpose = '';
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.funRef = {
      showData: this.showData,
    };

    for (let i = 1; i <= 31; i++) {
      this.dayOfMonth.push(i);
    }
    this.dayOfMonth.push('Last');

    this.accountId = localStorage.getItem('accountId');
    //this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        //this.regionId = d.value;
        //this.load();
      }
    });

    setTimeout(() => {
      flatpickr('.datetimepicker', { dateFormat: 'd-M-Y' });
      //let start_date = moment().startOf('month').format('DD-MMM-YYYY');
      //let end_date = moment().startOf('day').format('DD-MMM-YYYY');

      //this.dateRange = start_date + ' to ' + end_date;
      //flatpickr('.datetimepicker', {
      //  dateFormat: 'd-M-Y',
      //  mode: 'range',
      //  maxDate: new Date(),
      //  showMonths: 2,
      //  onChange: (selectedDates: any, dateStr: any, instance: any) => {
      //    if (!dateStr.includes('to')) return;
      //    this.dateRange = dateStr;
      //    this.load();
      //  },
      //});
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, 500);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async showData(ref: any, id: any) {
    window.analyticsSendEventClick('scheduler', 'View Schedule Details');
    console.log('showData', id);
    ref.subView = 'Basic Details';
    ref.cShedule = JSON.parse(JSON.stringify(id));
    ref.cShedule.startDate = moment(ref.cShedule.startDate)
      .utc()
      .format('DD-MMM-YYYY');
    ref.cShedule.startDateTime = moment(ref.cShedule.startDateTime)
      .utc()
      .format('HH:mm');

    ref.cShedule.endDate = moment(ref.cShedule.endDate)
      .utc()
      .format('DD-MMM-YYYY');
    ref.cShedule.endDateTime = moment(ref.cShedule.endDateTime)
      .utc()
      .format('HH:mm');

    if (!ref.cShedule.alerts) {
      ref.cShedule.alerts = {
        onSuccess: '',
        onError: '',
        others: '',
      };
    }

    ref.notifier.loading(true);
    //await Promise.all([ref.loadEC2(), ref.loadRDS()]); //, this.loadTags()
    await ref.loadEC2();
    ref.notifier.loading(false);
    ref.showCreateShedule = true;
    setTimeout(() => {
      flatpickr('.datetimepicker', { dateFormat: 'd-M-Y' });
      flatpickr('.datetimepickerTime', {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
      });
    }, 500);

    //ref.notifier.loading(true);

    //let data = {
    //  accountId: ref.accountId,
    //  region: ref.regionId,
    //  instanceId: id.instanceId
    //};

    //let header = {
    //  Authorization: localStorage.getItem('t'),
    //};
    ////https://api.swayam.cloud/v3/admin/support
    ////let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/support`;
    //let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/operations/ebs`;

    //let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    //console.log(result);

    //if (result.status == '1' || result.s == '1') {
    //  //ref.kbDetails = result.page;
    //  //ref.showDetailKB = true;

    //  ref.notifier.messageBox(
    //    'More Instance Info!',
    //    `<div style="display: flex; gap: 9px; align-items: center;"><strong>Instance Tag:</strong>  ` +
    //    result.instanceName +
    //    '</div>'
    //  );
    //} else {
    //  //ref.notifier.messageBox("More Instance Info!", "sdasdasdasda");

    //  ref.notifier.alert('Info', '', result.error, 'info', 5000);
    //}
    //ref.notifier.loading(false);
  }

  async load() {
    this.notifier.loading(true);
    await this.loadAllShedule();
    //await Promise.all([this.loadEC2(), this.loadRDS()]); //, this.loadTags()
    this.loadEC2();
    this.notifier.loading(false);
  }

  async CreateShedule() {
    window.analyticsSendEventClick('scheduler', 'Create New Schedule');
    this.subView = 'Basic Details';
    this.cShedule = {
      _id: '0',
      frequency: 'Once',
      rule: {
        monthyRepeat: 'Days',
        frequencyWeekDay: {
          Friday: false,
          Monday: false,
          Saturday: false,
          Sunday: false,
          Thursday: false,
          Tuesday: false,
          Wednesday: false,
        },
        dayOfMonth: [],
        frequencyMonthDay: {
          First: false,
          Second: false,
          Third: false,
          Fourth: false,
          Last: false,
        },
      },
      alerts: {
        onSuccess: '',
        onError: '',
        others: '',
      },
    };
    this.notifier.loading(true);
    this.loadEC2();
    //await Promise.all([this.loadEC2(), this.loadRDS()]); //, this.loadTags()
    this.notifier.loading(false);
    this.showCreateShedule = true;

    setTimeout(() => {
      flatpickr('.datetimepicker', { dateFormat: 'd-M-Y' });
      flatpickr('.datetimepickerTime', {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
      });
    }, 500);
  }

  CloseCreateShedule() {
    this.showCreateShedule = false;
    this.scheduleMaps = [];
    this.load();
  }

  async loadMinfySupportTickets() {
    this.notifier.loading(true);
    try {
      let data = {
        account_id: this.accountId,
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };

      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        //} else {
        //  this.notifier.alert('Info', '', result.error_message, 'info', 5000);
        //}
      } else {
        this.notifier.alert(
          'Error',
          '',
          '', //selectedAccountData.msg,
          'error',
          5000
        );
      }
    } catch (err: any) {
      console.log(err);
      this.notifier.alert('Info', '', err.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  filterDate(dateString: any) {
    return moment(dateString).utc().format('DD-MMM-YYYY HH:MM:SS');
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel('view-tickets-table', 'ticketlist', 'csv');
  }

  print_r(object: any, html: any) {
    if (html) return '<pre>' + JSON.stringify(object, null, 4) + '</pre>';
    else return JSON.stringify(object, null, 4);
  }

  async loadEC2(nextToken?: any) {
    if (!nextToken) {
      this.instancesList = [];
    }
    this.notifier.loading(true);
    let data = {
      action: 'testListInstancesWithPagination',
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.instancesList = [...this.instancesList, ...result.instanceDetails];
      let dt: any = [];
      this.instancesList.forEach((instance: any, index: number) => {
        instance['actionStart'] = false;
        if (instance.instanceStatus == 'running') {
          dt.push(instance);
        }
        instance.ipdata =
          (instance.privateIp ? instance.privateIp.toString() : '') +
          (instance.publicIp ? '/' + instance.publicIp.toString() : '');
      });
      if (result.nextToken && result.nextToken != '') {
        await this.loadEC2(result.nextToken);
      }
      //if (dt.length > 0) {
      //  await this.fetchInstanceAZ(dt);
      //}
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async loadRDS() {
    this.notifier.loading(true);
    let data = {
      emailId: 'rubeena.j@minfytech.com',
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listrds`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.rdsList = result.rdsDetailsList;
      this.rdsClusterList = result.clusterDetailsList;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async loadTags() {
    this.notifier.loading(true);
    let data = {
      action: 'listEc2Tags',
      accountId: this.accountId,
      region: this.regionId,
      nextToken: '',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.tagList = result.tagKeyList;
      //setTimeout(() => {
      //  $('#tagListID').selectpicker('refresh');
      //}, 100);
      //await this.fetchSubmittedTags();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  changeTragetType(nType: String) {
    this.cShedule.targetType = nType;
    //this.cShedule.target = [];
  }

  async createShedule(draft: boolean = false) {
    window.analyticsSendEventClick(
      'scheduler',
      'Save Schedule ' + (draft ? 'draft' : '')
    );
    this.notifier.loading(true);

    this.cShedule.status = 'A';

    if (this.cShedule.rule.frequency == 'Once') {
      this.cShedule.endDate = this.cShedule.startDate;
      this.cShedule.endDateTime = this.cShedule.startDateTime;
    }
    let res: any = await this.optValidateFor(
      'Create Shedule',
      'OTP for Create a Shedule'
    );
    console.log('changeTragetType', res);
    if (!res) {
      this.notifier.loading(false);
      return;
    }

    let data = {
      accountId: this.accountId,
      regionId: this.regionId,
      cShedule: this.cShedule,
      id: this.cShedule._id,
      draft: draft,
      urlAction: '/api/scheduler/save',
    };

    let header = {
      Authorization: 'Bearer ' + localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    //let apiURL = `${APIService.API_ENDPOINTMongo}/api/scheduler/save`; http://43.204.90.89:14189/api/map/api/scheduler/save
    let apiURL = `${APIService.API_ENDPOINTScheduler}`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    console.log(result);

    if (result.s == '1') {
      this.notifier.alert(
        'Success',
        '',
        'Schedule Rule saved successfully',
        'success',
        5000
      );
      this.showCreateShedule = false;
      this.loadAllShedule();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async loadAllShedule() {
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      regionId: this.regionId,
      urlAction: '/api/scheduler/get/all',
    };

    let header = {
      Authorization: 'Bearer ' + localStorage.getItem('t'),
    };
    //http://api.swayam.cloud/v3/admin/support
    //let apiURL = `${APIService.API_ENDPOINTMongo}/api/scheduler/get/all`;
    let apiURL = `${APIService.API_ENDPOINTScheduler}`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    console.log(result);

    if (result.s == '1') {
      this.scheduleRules = result.shedulerRules;
      //setTimeout(() => {
      //  $('#tagListID').selectpicker('refresh');
      //}, 100);
      //await this.fetchSubmittedTags();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  onChangePush(data: any, item: string) {
    console.log('onChangePush', data, item);
    let index = data.indexOf(item);
    if (index == -1) {
      data.push(item);
    } else {
      data.splice(index, 1);
    }
  }

  showViewTargets() {
    this.subView = 'Select Targets';
    this.cShedule.startDate = $('#startDate').val();
    this.cShedule.startDateTime = $('#startDateTime').val();

    this.cShedule.endDate = $('#endDate').val();
    this.cShedule.endDateTime = $('#endDateTime').val();

    this.cShedule.targetType = 'EC2';

    //for (let i = 0; i < this.cShedule.target.length; i++) {
    //  for (let j = 0; j < this.cShedule.target[i].region.length; j++) {
    //    for (let k = 0; k < this.cShedule.target[i].region[j].length; k++) {

    //    }
    //  }
    //}

    this.cdRef.detectChanges();
  }

  backToBasic() {
    this.subView = 'Basic Details';
    setTimeout(() => {
      flatpickr('.datetimepicker', { dateFormat: 'd-M-Y' });
      flatpickr('.datetimepickerTime', {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
      });
    }, 500);
  }

  changeWeekDay(wDay: string) {
    console.log('wDay', this.cShedule.rule.frequencyWeekDay[wDay]);
    this.cShedule.rule.frequencyWeekDay[wDay] =
      !this.cShedule.rule.frequencyWeekDay[wDay];
    console.log('wDay', this.cShedule.rule.frequencyWeekDay[wDay]);
  }

  changeMonthDay(wDay: string) {
    console.log('wDay', this.cShedule.rule.frequencyMonthDay[wDay]);
    this.cShedule.rule.frequencyMonthDay[wDay] =
      !this.cShedule.rule.frequencyMonthDay[wDay];
    console.log('wDay', this.cShedule.rule.frequencyMonthDay[wDay]);
  }

  setMonthRepeat(w: string) {
    console.log('setMonthRepeat', w);
    this.cShedule.rule.monthyRepeat = w;
  }

  clone(sh: any) {
    window.analyticsSendEventClick('scheduler', 'Clone Schedule');
    let newShedule = JSON.parse(JSON.stringify(sh));

    newShedule._id = '0';
    newShedule.name = newShedule.name + ' Clone';

    console.log('showData', sh);
    this.subView = 'Basic Details';
    this.cShedule = newShedule;

    //this.cShedule.startDateTime = moment(this.cShedule.startDateTime).format("DD-MMM-YYYY HH:mm")

    this.cShedule.startDate = moment(this.cShedule.startDate)
      .utc()
      .format('DD-MMM-YYYY');
    this.cShedule.startDateTime = moment(this.cShedule.startDateTime)
      .utc()
      .format('HH:mm');

    this.cShedule.endDate = moment(this.cShedule.endDate)
      .utc()
      .format('DD-MMM-YYYY');
    this.cShedule.endDateTime = moment(this.cShedule.endDateTime)
      .utc()
      .format('HH:mm');

    this.showCreateShedule = true;
    setTimeout(() => {
      flatpickr('.datetimepicker', { dateFormat: 'd-M-Y' });
      flatpickr('.datetimepickerTime', {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
      });
    }, 500);
  }

  async delete(sh: any) {
    window.analyticsSendEventClick('scheduler', 'Delete Schedule ');

    //if (!confirm("are you sure want to delete the schedule rule!")) {
    //  return;
    //}

    this.notifier.loading(true);

    let res: any = await this.optValidateFor(
      'Delete Shedule',
      'OTP for Delete Shedule : ' + sh.name
    );
    if (!res) return;

    let data = {
      accountId: sh.accountId,
      id: sh._id,
      urlAction: '/api/scheduler/delete',
    };

    let header = {
      Authorization: 'Bearer ' + localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTScheduler}`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    console.log(result);

    if (result.s == '1') {
      this.notifier.alert(
        'Success',
        '',
        'Schedule Rule deleted successfully',
        'success',
        5000
      );
      this.showCreateShedule = false;
      this.loadAllShedule();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async pause(sh: any) {
    window.analyticsSendEventClick('scheduler', 'Pause Schedule');
    if (sh.status == 'A') {
      //if (!confirm("are you sure want to pause the schedule rule!")) {
      //  return;
      //}

      let res: any = await this.optValidateFor(
        'Pause Schedule',
        'OTP for Pause Schedule : ' + sh.name
      );
      if (!res) return;
    } else {
      //if (!confirm("are you sure want to activate the schedule rule!")) {
      //  return;
      //}

      let res: any = await this.optValidateFor(
        'Activate Schedule',
        'OTP for Activate Schedule : ' + sh.name
      );
      if (!res) return;
    }

    this.notifier.loading(true);

    let data = {
      accountId: sh.accountId,
      id: sh._id,
      urlAction: '/api/scheduler/pause',
    };

    let header = {
      Authorization: 'Bearer ' + localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTScheduler}`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    console.log(result);

    if (result.s == '1') {
      if (result.shedulerRule.status == 'P') {
        this.notifier.alert(
          'Success',
          '',
          'Schedule Rule Paused successfully',
          'success',
          5000
        );
      } else {
        this.notifier.alert(
          'Success',
          '',
          'Schedule Rule Activated successfully',
          'success',
          5000
        );
      }
      this.showCreateShedule = false;
      this.loadAllShedule();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  mapInstanceToTarget(item: any, targetType = '') {
    let instanceId = item;

    if (!targetType) {
      targetType = this.cShedule.targetType;
    }

    if (targetType == 'EC2') instanceId = item.instanceId;
    if (targetType == 'RDS') instanceId = item.dbInstanceIdentifier;

    let regionIndex = -1;
    for (let i = 0; i < this.cShedule.target.length; i++) {
      if (this.cShedule.target[i].regionId == this.regionId) {
        regionIndex = i;
        break;
      }
    }
    if (regionIndex == -1) {
      regionIndex = this.cShedule.target.length;
      this.cShedule.target.push({
        regionId: this.regionId,
        regionName: this.selectedRegion,
        RDS: [],
        EC2: [],
        Tags: [],

        RDSD: [],
        EC2D: [],
        TagsD: [],
      });
    }

    let index =
      this.cShedule.target[regionIndex][targetType].indexOf(instanceId);
    if (index == -1) {
      this.animateAdd(instanceId);
      setTimeout(() => {
        this.cShedule.target[regionIndex][targetType].push(instanceId);
        this.cShedule.target[regionIndex][targetType + 'D'].push(item);
        console.log(
          targetType + 'D',
          this.cShedule.target[regionIndex][targetType + 'D']
        );

        this.cdRef.detectChanges();
      }, 400);
    } else {
      this.cShedule.target[regionIndex][targetType].splice(index, 1);
      this.cShedule.target[regionIndex][targetType + 'D'].splice(index, 1);
      if (
        this.cShedule.target[regionIndex].RDS.length == 0 &&
        this.cShedule.target[regionIndex].EC2.length == 0 &&
        this.cShedule.target[regionIndex].Tags.length == 0
      ) {
        this.cShedule.target.splice(regionIndex, 1);
      }
    }
    this.cdRef.detectChanges();
  }

  animateAdd(instanceId: string) {
    instanceId = '#' + instanceId;
    let item = $(instanceId);
    //let offset = item.offset();
    let offset = item[0].getBoundingClientRect();
    let top = offset.top; //- $(window).scrollTop()
    $(instanceId).animate(
      {
        //top: (top + 200) + "px"
        opacity: 0.5,
      },
      {
        duration: 500,
        step: function (now, fx) {
          //console.log("now", now);
          let t = Math.ceil(top - 300 * (1 - now)) + 'px';
          //console.log("step", t);
          $(instanceId).css('top', t);
        },
        start: function () {
          //console.log("start", top);
          item.css('top', top + 'px');
          item.css('position', 'fixed');
        },
        complete: function () {
          $(instanceId).css('position', 'static');
          $(instanceId).css('top', 'auto');
          $(instanceId).css('opacity', '1');
        },
      }
    );
  }

  checkIf(instanceId: string, nType: string) {
    if (!this.cShedule.target) this.cShedule.target = [];
    let regionIndex = -1;
    for (let i = 0; i < this.cShedule.target.length; i++) {
      if (this.cShedule.target[i].regionId == this.regionId) {
        regionIndex = i;
        break;
      }
    }

    if (!this.cShedule.target[regionIndex]) return false;

    let index = this.cShedule.target[regionIndex][nType].indexOf(instanceId);
    if (index == -1) return false;
    return true;
  }

  async setRegion(i: any) {
    this.regionId = i.id;
    this.selectedRegion = i.name;
    this.notifier.loading(true);
    //await Promise.all([this.loadEC2(), this.loadRDS()]); //, this.loadTags()
    this.loadEC2();
    this.notifier.loading(false);

    this.cdRef.detectChanges();
  }

  async showCalculatedSchedule() {
    this.notifier.loading(true);

    let data = {
      accountId: this.accountId,
      regionId: this.regionId,
      cShedule: this.cShedule,
      id: this.cShedule._id,
      draft: this.cShedule.draft,
      loadData: true,
      urlAction: '/api/scheduler/save',
    };

    let header = {
      Authorization: 'Bearer ' + localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    //let apiURL = `${APIService.API_ENDPOINTMongo}/api/scheduler/save`; http://43.204.90.89:14189/api/map/api/scheduler/save
    let apiURL = `${APIService.API_ENDPOINTScheduler}`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    console.log(result);

    if (result.s == '1') {
      //this.notifier.alert('Success', '', "Schedule Rule saved successfully", 'success', 5000);
      //this.showCreateShedule = false;
      //this.loadAllShedule();
      this.scheduleMaps = result.em;
      console.log('em', result.em);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  convertToIST(d: any) {
    try {
      return moment(d)
        .utc()
        .add('minutes', 330)
        .format('dddd DD-MMM-YYYY hh:mm A');
      console.log('convertToIST', d);
      //return moment(d).utc().format("DD-MMM-YYYY hh:mm A")
    } catch (e: any) {
      console.log(e.stack);
      return '';
    }
  }

  convetToString(d: any) {
    try {
      for (let i = 0; i < d.length; i++) {
        delete d[i].Tags;
        delete d[i].RDSD;
        delete d[i].EC2D;
        delete d[i].TagsD;
      }
      return JSON.stringify(d, null, 4);
    } catch (e: any) {
      console.log(e.stack);
      return '';
    }
  }

  async showLogData(sh: any) {
    window.analyticsSendEventClick('scheduler', 'View Schedule Log');
    this.showLog = true;
    //Get Log Data
    //api/scheduler/get/log

    this.notifier.loading(true);

    let data = {
      accountId: sh.accountId,
      id: sh._id,
      urlAction: '/api/scheduler/get/log',
    };

    let header = {
      Authorization: 'Bearer ' + localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTScheduler}`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    console.log(result);

    if (result.s == '1') {
      this.logShData = result.shedulerRule;
      this.logData = result.shedulerRuleMap;

      for (let i = 0; i < this.logData.length; i++) {
        let dt = [];

        for (let q = 0; q < this.logData[i].lastRun.m.length; q++) {
          if (this.logData[i].action == 'Stop') {
            dt.push(...this.logData[i].lastRun.m[q].StoppingInstances);
          } else if (this.logData[i].action == 'Start') {
            dt.push(...this.logData[i].lastRun.m[q].StartingInstances);
          }
        }

        for (let k = 0; k < this.logData[i].target.length; k++) {
          for (let z = 0; z < this.logData[i].target[k].EC2D.length; z++) {
            let d = dt.filter(
              (a) =>
                a.InstanceId == this.logData[i].target[k].EC2D[z].instanceId
            );
            if (d.length > 0) {
              this.logData[i].target[k].EC2D[z].previousState =
                d[0].PreviousState.Name;
              this.logData[i].target[k].EC2D[z].currentState =
                d[0].CurrentState.Name;
            }
          }
        }
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async sendOtp(purpose: string, description: string) {
    try {
      let data = {
        action: 'sendOtp',
        email: this.userId,
        purpose: purpose,
        description: description,
        accountId: this.accountId,
      };
      let header = {
        Authorization: 'Bearer ' + localStorage.getItem('t'),
      };
      let apiURL = APIService.API_ENDPOINTSchedulerOtp;
      let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

      return result;
    } catch (e) {
      return { s: 0, error: e };
    }
  }

  async validateOtp(purpose: string, otp: string) {
    try {
      let data = {
        action: 'validateOtp',
        purpose: purpose,
        otp: otp,
        accountId: this.accountId,
      };
      let header = {
        Authorization: 'Bearer ' + localStorage.getItem('t'),
      };
      let apiURL = APIService.API_ENDPOINTSchedulerOtp;
      let result = await this.apiServer.postDataPromisNew(apiURL, data, header);
      return result;
    } catch (e) {
      return { s: 0, error: e };
    }
  }

  cancelOTPClick() {
    this.cancelOTP = true;
    this.otpModal = false;
  }

  optValidateFor(purpose: string, description: string) {
    return new Promise(async (resolve, rejects) => {
      this.cancelOTP = false;
      this.otpValided = false;
      this.otpValid = false;

      if (APIService.OTP_Enabled === false) {
        return resolve(true);
      }

      let res: any = await this.sendOtp(purpose, description);

      if (res.s != 1) {
        this.notifier.alert(
          'Error',
          '',
          'Error Please Try Againe.',
          'error',
          5000
        ); // TODO : Fix Error Message, put more Detail
        return resolve(false);
      }
      this.otpPurpose = purpose;

      this.otpModal = true;
      let si = setInterval(() => {
        console.log(
          'optValidateFor',
          this.cancelOTP,
          this.otpValided,
          this.otpValid
        );
        if (this.cancelOTP) {
          clearInterval(si);
          this.otpModal = false;
          return resolve(false);
        }

        if (this.otpValided && this.otpValid) {
          clearInterval(si);
          this.otpModal = false;
          return resolve(true);
        }
      }, 100);
    });
  }

  async confirmOTPClick() {
    if (!this.otpValue || this.otpValue.length != 8) {
      this.optErrorMSG = 'Please Enter OTP';
    }
    let resOTP: any = await this.validateOtp(this.otpPurpose, this.otpValue);
    this.notifier.loading(false);
    if (resOTP.s != 1) {
      this.optErrorMSG = 'Error OTP do not Match!';
      return;
    }

    this.otpValided = true;
    this.otpValid = true;
    this.otpValue = '';
  }

  //let attempt = 3;

  //while (true) {
  //  this.notifier.loading(false);
  //  let otp = prompt(description);

  //  if (!otp || otp.length > 14) {
  //    this.notifier.alert('Info', '', 'Error Please Try Againe.', 'info', 5000);
  //    continue;
  //  }
  //  this.notifier.loading(true);
  //  let resOTP: any = await this.validateOtp(purpose, otp);
  //  this.notifier.loading(false);

  //  if (resOTP.s != 1) {
  //    attempt--;
  //    this.notifier.alert('Info', '', 'Error Wrong OTP.', 'info', 5000);  // TODO : Fix Error Message, put more Detail
  //    if (attempt == 0) {
  //      this.notifier.loading(false);
  //      return false;
  //    }
  //    continue;
  //  }
  //  else {
  //    this.notifier.loading(false);
  //    break;
  //  }

  //}

  //async optValidateFor(purpose: string, description: string) {

  //  let res: any = await this.sendOtp(purpose, description);

  //  if (res.s != 1) {
  //    this.notifier.alert('Info', '', 'Error Please Try Againe.', 'info', 5000);  // TODO : Fix Error Message, put more Detail
  //    return false;
  //  }

  //  let attempt = 3;

  //  while (true) {
  //    this.notifier.loading(false);
  //    let otp = prompt(description);

  //    if (!otp || otp.length > 14) {
  //      this.notifier.alert('Info', '', 'Error Please Try Againe.', 'info', 5000);
  //      continue;
  //    }
  //    this.notifier.loading(true);
  //    let resOTP: any = await this.validateOtp(purpose, otp);
  //    this.notifier.loading(false);

  //    if (resOTP.s != 1) {
  //      attempt--;
  //      this.notifier.alert('Info', '', 'Error Wrong OTP.', 'info', 5000);  // TODO : Fix Error Message, put more Detail
  //      if (attempt == 0) {
  //        this.notifier.loading(false);
  //        return false;
  //      }
  //      continue;
  //    }
  //    else {
  //      this.notifier.loading(false);
  //      break;
  //    }

  //  }

  //  return true;
  //}
}
