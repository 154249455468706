<div class="alarm_screen" style="box-shadow:  1px 3px 1px #80808024 ">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform:capitalize">{{action == 'add' ? 'Create' : action}} Policy</h4>
    </div>
    <div class="alarm_body" style=" padding: 1px 25px 5px 25px">
      <div class="row">
        <label>Policy Name</label>
        <input type="text" class="form-control" *ngIf="action == 'add'" [(ngModel)]="policy.policyName">
        <input type="text" class="form-control" *ngIf="action == 'update'" [attr.disabled]="true"
          [ngModel]="policy.policyName">
      </div>
      <div class="row">
        <label>Services Selected</label>
        <select class="form-control" (change)="checkOfServices()" multiple id="servicesList" data-live-search="true">
          <option *ngFor="let service of services" value="{{service}}">{{service}}
          </option>
        </select>
      </div>
      <div class="row">
        <label>Access Type</label>
        <div class="pt-2">
          <input type="radio" [(ngModel)]="policy.accessType" value="readOnly" id="policyAccessReadOnly"
            name="policyAccessType">
          <label for="policyAccessReadOnly">Read Only</label>
        </div>
        <div class="pt-2">
          <input type="radio" [(ngModel)]="policy.accessType" value="readandwrite" id="policyAccessReadAndWrite"
            name="policyAccessType">
          <label for="policyAccessReadAndWrite">Read and Write</label>
        </div>
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end; gap: 8px;">
          <button class="btn" (click)="updateEntry()" [ngClass]="{'click-disabled': !writeAccess}"
            [attr.disabled]="!writeAccess ? true : null"
            style="background-color: #5864ff; color: white; text-transform:capitalize;">
            {{action == 'add' ? 'Create' : 'Update'}}
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>


  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>