import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../../../api/api.service';
import { NotifierService } from '../../../../../_services/notifier.service';

declare let $: any;

@Component({
  selector: 'update-ec2threshhold',
  templateUrl: './update.ec2threshhold.component.html',
  styleUrls: ['./update.ec2threshhold.component.css'],
})
export class UpdateEC2ThreshholdComponent implements OnInit, OnDestroy {
  @ViewChild('minfy_ui_cbs_budgets_update') attachmentElement: ElementRef;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading: any = {};
  accountId: any;
  regionId: any;
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  @Input() budget: any;
  budgetRaw: any;
  destroyed: boolean = false;
  current_year: string;
  previous_year: string;
  instances: any = [];
  instanceSelectionModal: boolean = false;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.budgetRaw = JSON.parse(JSON.stringify(this.budget));
    if (this.budgetRaw['eid'].length == 0) {
      this.budgetRaw['eid'] = [''];
    }
    this.loadLinkedAccountIDs();
    let current_year = moment().format('YYYY-MM');
    let previous_year = moment().subtract(1, 'months').format('YYYY-MM');
    this.current_year = current_year;
    this.previous_year = previous_year;

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed = true;
  }

  close(event: any) {
    if (event) {
      delete this.budgetRaw['mid'];
      this.hideModel.emit(this.budgetRaw);
    } else {
      this.hideModel.emit(false);
    }
  }

  deleteSelectedAdditionalContact(index: number) {
    this.budgetRaw['eid'].splice(index, 1);
  }

  checkForLast(value: any, index: number) {
    if (
      value != '' &&
      value.length > 0 &&
      this.budgetRaw['eid'].length - 1 == index
    ) {
      this.budgetRaw['eid'].push('');
    } else if (
      value == '' &&
      value.length == 0 &&
      this.budgetRaw['eid'].length - 2 == index &&
      this.budgetRaw['eid'][this.budgetRaw['eid'].length - 1] == ''
    ) {
      this.budgetRaw['eid'].splice(this.budgetRaw['eid'].length - 1, 1);
    }
  }

  addAdditionalContact() {
    this.budgetRaw['eid'].push('');
  }

  trackByFn() {
    return true;
  }

  fetchingLinkedAccountIDs: boolean = false;
  async loadLinkedAccountIDs() {
    this.fetchingLinkedAccountIDs = true;
    let data: any;
    if (this.budgetRaw['action'] != 'update') {
      data = {
        a: 'fetchec2usage',
        lid: this.budgetRaw.lid,
        mid: this.budgetRaw.mid.accountId,
      };
    } else {
      data = {
        a: 'fetchsingleusage',
        lid: this.budgetRaw.lid,
        mid: this.budgetRaw.mid.accountId,
        os: this.budgetRaw.os,
        region: this.budgetRaw.region,
        instanceType: this.budgetRaw.instanceType,
      };
    }
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/costvariance`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if ((result.status == '1' || result.s == '1') && result.data) {
      result.data = result.data.map((instance: any) => {
        instance = { ...instance, ...instance._id };
        delete instance._id;
        if (
          instance['os'] == this.budgetRaw['os'] &&
          instance['instanceType'] == this.budgetRaw['instanceType'] &&
          instance['region'] == this.budgetRaw['region']
        ) {
          instance['selected'] = true;
        } else {
          instance['selected'] = false;
        }
        instance['awsTotalHours'] = instance['awsTotalHours'].toFixed(2);
        return instance;
      });
      this.instances = result.data;
    } else {
      this.instances = [];
      this.notifier.alert(
        'Error',
        '',
        result.error || result.msg,
        'error',
        5000
      );
    }
    this.fetchingLinkedAccountIDs = false;
  }

  updating: boolean = false;
  async updateBudget() {
    if (this.budgetRaw.hours == null) {
      this.notifier.alert(
        'Error',
        '',
        'Please enter valid Hours value',
        'error',
        5000
      );
      return;
    }
    if (!this.budgetRaw.hours || this.budgetRaw.hours <= 0) {
      this.notifier.alert(
        'Error',
        '',
        'Please enter Hours Value',
        'error',
        5000
      );
      return;
    }
    if (this.budgetRaw.threshHold == null) {
      this.notifier.alert(
        'Error',
        '',
        'Please enter valid ThreshHold value',
        'error',
        5000
      );
      return;
    }
    if (!this.budgetRaw.threshHold || this.budgetRaw.threshHold <= 0) {
      this.notifier.alert(
        'Error',
        '',
        'Please enter ThreshHold Value',
        'error',
        5000
      );
      return;
    }
    if (
      this.budgetRaw.eid.filter((email: any) => {
        return email != '';
      }).length == 0
    ) {
      this.notifier.alert(
        'Error',
        '',
        'Please provide at leats 1 Email ID',
        'error',
        5000
      );
      return;
    }
    this.updating = true;
    let data: any;
    if (this.budgetRaw['action'] != 'update') {
      data = {
        mid: this.budgetRaw.mid.accountId,
        lid: this.budgetRaw.lid,
        a: 'saveall',
        data: this.instances
          .filter((instance: any) => {
            return instance.selected;
          })
          .map((instance: any) => {
            instance['eid'] = this.budgetRaw.eid
              .filter((email: any) => {
                return email != '';
              })
              .join(',');
            instance['hours'] = this.budgetRaw['hours'];
            instance['threshHold'] = this.budgetRaw['threshHold'];
            instance['region'] = this.budgetRaw['region'];
            return instance;
          }),
      };
    } else {
      data = {
        mid: this.budgetRaw.mid.accountId,
        lid: this.budgetRaw.lid,
        a: 'update',
        os: this.budgetRaw['os'],
        region: this.budgetRaw['region'],
        instanceType: this.budgetRaw['instanceType'],
        eid: this.budgetRaw.eid
          .filter((email: any) => {
            return email != '';
          })
          .join(','),
        hours: this.budgetRaw['hours'],
        threshHold: this.budgetRaw['threshHold'],
      };
    }

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2usagealarm`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.status == '1' || result.s == '1') {
      this.close(true);
    } else {
      this.notifier.alert(
        'Error',
        '',
        result.error || result.msg,
        'error',
        5000
      );
    }

    this.updating = false;
  }

  getSelectedInstances() {
    return this.instances.filter((instance: any) => {
      return instance.selected;
    });
  }
}
