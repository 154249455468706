import { Component, OnInit } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

declare let window: any;

@Component({
  //moduleId: module.id.toString(),
  selector: 'uptime-reports',
  templateUrl: './uptime.view.reports.component.html',
})
export class UptimeReportsComponent implements OnInit {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  uptimeList: any = [];
  reportType: string = 'Weekly';

  accountId: any;
  regionId: any;
  currentMessage: any;

  uptimeHeader: any = [
    {
      id: 'tagName',
      name: 'Instance Name',
    },
    {
      id: 'instanceId',
      name: 'Instance ID',
    },
    {
      id: 'instanceType',
      name: 'Instance Type',
    },
    {
      id: 'availability_percentage',
      name: 'Availability Percentage',
    },
    {
      id: 'availability_duration',
      name: 'Availability Duration',
    },
    {
      id: 'alarm_count',
      name: 'Alarm Count',
    },
    {
      id: 'down_count',
      name: 'Down Count',
    },
    {
      id: 'downtime_duration',
      name: 'Downtime Duration',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }
  async load() {
    this.notifier.loading(true);
    this.uptimeList = [];
    await this.loadInstances();
    this.at = '';
  }

  async loadInstances(nextToken: any = '') {
    if (nextToken == '') {
      this.uptimeList = [];
    }
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      action: 'listMonitorsWithPagination',
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken == '' ? undefined : nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      result.instanceDetails = result.instanceDetails.map((instance: any) => {
        return {
          ...instance,
          ...{
            maintenance_percentage: 'LOADING_CHECK',
            alarm_count: 'LOADING_CHECK',
            availability_duration: 'LOADING_CHECK',
            unmanaged_percentage: 'LOADING_CHECK',
            unmanaged_duration: 'LOADING_CHECK',
            downtime_duration: 'LOADING_CHECK',
            down_count: 'LOADING_CHECK',
            availability_percentage: 'LOADING_CHECK',
            maintenance_duration: 'LOADING_CHECK',
            mttr: 'LOADING_CHECK',
            mtbf: 'LOADING_CHECK',
            monitorId: 'LOADING_CHECK',
            monitorType: 'LOADING_CHECK',
            monitorName: 'LOADING_CHECK',
          },
        };
      });
      this.uptimeList = [...this.uptimeList, ...result.instanceDetails];
      await this.loadUptimes(result.instanceDetails);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    if (result.nextToken && result.nextToken != '') {
      await this.loadInstances(result.nextToken);
    }
  }

  at: any = '';

  async loadUptimes(instances: any) {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { site_247_project_id } = selectedAccountData.data;
        let data = {
          reportType: this.reportType,
          action: 'fetchUptimeReport',
          zaaid: site_247_project_id,
          instanceList: instances.map((instance: any) => {
            return instance.tagName;
          }),
          at: this.at,
        };
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        //https://api.swayam.cloud/v3/admin/support
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2cpudetails`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (result.status == '1' || result.s == '1') {
          result.summaryReportList.forEach(async (summary: any) => {
            let uptime = this.uptimeList.find((i: any) => {
              return i.tagName == summary.monitorName;
            });
            uptime = { ...uptime, ...summary };
            let uptimeIndex = this.uptimeList.findIndex((i: any) => {
              return i.tagName == summary.monitorName;
            });
            this.uptimeList[uptimeIndex] = uptime;
            this.at = result.at;
          });
        } else {
          this.notifier.alert('Info', '', result.error, 'info', 5000);
        }
      } else {
        this.notifier.alert(
          'Error',
          '',
          selectedAccountData.msg,
          'error',
          5000
        );
      }
    } catch (err: any) {
      console.log(err);
      this.notifier.alert('Info', '', err.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel('uptimeTable', 'Uptime Report', 'csv');
  }

  callFunction(id: any, id1: any) {}
}
