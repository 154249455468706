import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../../..//api/api.service';
import { NotifierService } from '../../../../../_services/notifier.service';

declare let window: any;

@Component({
  templateUrl: './ageingsnapsots.component.html',
  selector: 'snapshots-aging-report',
})
export class AgeingSnapshotsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  oldEBSList: any = [];
  oldEBSLoading: boolean = true;

  oldEBSHeaders: any = [
    {
      id: 'snapshotId',
      name: 'Snapshot ID',
      filter: true,
    },
    {
      id: 'volumeId',
      name: 'Volume ID',
      filter: true,
    },
    {
      id: 'description',
      name: 'Description',
    },
    {
      id: 'volumeSize',
      name: 'Size',
    },
    {
      id: 'retention',
      name: 'Created Days Ago',
      filter: true,
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null) {
        return;
      }

      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.loadOldEBS();
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel(
      'ageingSnapshotsTable',
      'Snapshot Ageing Report',
      'csv'
    );
  }

  async loadOldEBS(nextToken: String = '') {
    this.notifier.loading(true);
    if (nextToken == '') {
      this.oldEBSLoading = true;
      this.oldEBSList = [];
    }
    let data = {
      accountId: this.accountId,
      emailid: this.userId,
      action: 'OldEBS',
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/opportunities`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (!result) {
      this.oldEBSLoading = false;
      return false;
    }

    if (result.status == '1' || result.s == '1') {
      this.oldEBSList = [...this.oldEBSList, ...result.oldEBSsnapshotList];
    } else if (nextToken == '' && !result.nextToken) {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.nextToken || (result.error && result.error.includes('Failed'))) {
      if (result.nextToken) {
        this.loadOldEBS(result.nextToken);
      } else {
        this.loadOldEBS(nextToken);
      }
    } else {
      this.oldEBSLoading = false;
    }
    this.notifier.loading(false);
    return true;
  }
}
