<div class="alarm_screen">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4>Delete Budget</h4>
    </div>
    <div class="alarm_body">
      <div class="row">
        Are you sure you want to delete the budget for {{lid}}?
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" [attr.disabled]="deleting ? true : null" [ngStyle]="{opacity: deleting ? '0.6' : '1'}"
            (click)="uploadFile()" style="background-color: #5864ff; color: white">
            <img *ngIf="deleting" src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px;" />
            Delete
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>