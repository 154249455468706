import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

declare let window: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './view.bom.component.html',
})
export class viewBOMComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  bomList: any = [];
  selectedBOM: any;
  deleteBOM: any;
  bomAction: string = 'update';
  accountId: any;
  regionId: any;
  currentMessage: any;

  headers: any = [
    {
      id: 'os',
      name: 'OS',
    },
    { id: 'instanceType', name: 'Instance Type' },
    { id: 'region', name: 'Region' },
    { id: 'cpu', name: 'CPU' },
    {
      id: 'disk',
      name: 'Disk',
    },
    {
      id: 'quantity',
      name: 'Quantity',
    },
    {
      id: 'ram',
      name: 'Ram',
    },
    {
      id: 'billingType',
      name: 'Billing Type',
    },
    {
      id: 'usageHours',
      name: 'Usage in Hours',
    },
    {
      id: 'hourlyCost',
      name: 'Cost Per Hour',
    },
    {
      id: 'totalCost',
      name: 'Total Cost',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  updateBOMEntry(param: any) {
    this.bomAction = 'update';
    this.selectedBOM = param;
  }

  addBOMEntry() {
    this.bomAction = 'add';
    this.selectedBOM = {
      instanceType: '',
      os: '',
      cpu: '',
      disk: '',
      quantity: '',
      region: this.regionId,
      uuid: window.uuidv4(),
      ram: '',
      billingType: '',
      usageHours: '',
      hourlyCost: '0',
      total_cost: '0',
    };
  }

  getTotal(value: any) {
    let total =
      (isNaN(value['hourlyCost']) ? 0 : Number(value['hourlyCost'])) *
      (isNaN(value['quantity']) ? 0 : Number(value['quantity'])) *
      (isNaN(value['usageHours']) ? 0 : Number(value['usageHours']));
    return total.toFixed(2);
  }

  hideModel(event: any) {
    if (event) {
      this.load();
    }
    this.selectedBOM = null;
  }

  triggerDeleteEntry(param: any) {
    this.deleteBOM = param;
  }

  hideDeleteModel(event: any) {
    if (event) {
      this.load();
    }
    this.deleteBOM = null;
  }

  async load() {
    this.notifier.loading(true);
    let data = { accountId: this.accountId, action: 'fetchBOMDetails' };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.bomList = result.BOMDetailsList;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  exportBOM() {
    let element = document.createElement('table');
    element.innerHTML += `
        <thead>
          <tr>
            <th>Sl. No.</th>
            ${this.headers
              .map((h: any) => {
                return `<th>${h.name}</th>`;
              })
              .join('')}
          </tr>
        </thead>
        <tbody>
            ${this.bomList.map((ec2: any, index: any) => {
              return `
                <tr>
                  <td>${index + 1}</td>
                  ${this.headers.map((h: any) => {
                    return `
                      <td>
                        <span>${ec2[h.id]}</span>
                      </td>
                    `;
                  })}
                </tr>
              `;
            })}
        </tbody>
      `;
    window.exportToExcelElement(element, `BOM Details`, 'csv');
  }

  callFunction(name: any, param: any) {
    return;
  }
}
