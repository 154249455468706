import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
declare let $: any;
import * as moment from 'moment';

@Component({
  selector: 'mrr-update',
  templateUrl: './update.mrr.component.html',
  styleUrls: [
    '../../../../views/cbs/enableri/update/update.enableri.component.css',
  ],
})
export class MRRUpdateComponent implements OnInit, OnDestroy {
  selectedServiceNames: any[] = [];
  isAllSelect: boolean = false;
  serviceNamelist: any;
  currentMessage: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  mid: any;
  date: any;
  @Output() hideModel = new EventEmitter<any>();
  @Input('action') action: any;
  @Input() mrr: any;
  @Input() unsaved: any;
  masterAccounts: any[];

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.mrr = { ...this.mrr };
    this.accountId = localStorage.getItem('accountId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      }
    });
    this.fetchMasterAccounts();
    this.date = moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('MM-YYYY');
    this.serviceNames();
  }

  load() {}

  async updateEntry() {
    if (this.action.trim() == 'add') {
      let serviceNames = $('#serviceNameId').val();

      for (let i = 0; i < serviceNames.length; i++) {
        this.selectedServiceNames.push({
          serviceName: serviceNames[i],
          mrrDiscount: this.mrr.mrrDiscount,
        });
      }
      this.close({
        lid: this.accountId,
        a: 'add',
        mrrList: this.selectedServiceNames,
      });
      return;
    }
    this.notifier.loading(true);
    let data: any = {
      lid: this.accountId,
      a: 'update',
      serviceName: this.mrr.serviceName,
      mrrDiscount: this.mrr.mrrDiscount,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/monthlydev`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.close(true);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async serviceNames() {
    this.notifier.loading(true);
    let data: any = {
      masterAccId: this.mid,
      lid: [this.accountId],
      action: 'supportPlanDetails',
      month_year: this.date,
      userType: 'C',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/getservicelevelbill`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    let list: any;
    this.serviceNamelist = [];
    if (result.status == '1' || result.s == '1') {
      list = result['billDetails']['items'][0]['services'];
      let i = 1;

      for (let ls in list) {
        this.serviceNamelist.push(list[ls]['service_name']);
      }
      setTimeout(async () => {
        $('#serviceNameId').selectpicker();
        if (this.action.trim() == 'update' || this.mrr.serviceName.length > 0) {
          $('#serviceNameId').val([this.mrr.serviceName]);
          $('#serviceNameId').selectpicker('refresh');
        } else if (this.action.trim() == 'add' && this.unsaved.length > 0) {
          const selectElement = document.getElementById(
            'serviceNameId'
          ) as HTMLSelectElement;

          const optionsToDisable = this.unsaved.map(
            (item: { serviceName: string }) => item.serviceName
          );

          for (let i = 0; i < selectElement.options.length; i++) {
            if (optionsToDisable.includes(selectElement.options[i].value)) {
              selectElement.options[i].disabled = true;
              selectElement.options[i].style.color = 'grey';
              selectElement.options[i].style.backgroundColor = '#f5f5f5';
            }
          }
          $('#serviceNameId').selectpicker('refresh');
        }
      }, 100);
    } else {
    }

    this.notifier.loading(false);
  }

  async fetchMasterAccounts() {
    this.masterAccounts = [];
    this.notifier.loading(true);
    let data = {
      lid: this.accountId,
      userType: 'C',
      action: 'fetchMasterAccList',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = result.masterAccList;
      this.mid = this.masterAccounts[0];
      this.notifier.loading(false);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.notifier.loading(false);
    }
  }
  selectAllOpt() {
    this.isAllSelect = !this.isAllSelect;
    setTimeout(async () => {
      $('#serviceNameId').selectpicker();
      if (this.isAllSelect) {
        $('#serviceNameId').val(this.serviceNamelist);
      } else {
        $('#serviceNameId').val([]);
      }
      $('#serviceNameId').selectpicker('refresh');
    }, 100);
  }
  ngOnDestroy(): void {}

  trackByFn() {
    return true;
  }

  close(dataAction: any) {
    console.log('close function' + dataAction);
    if (dataAction) {
      this.hideModel.emit(dataAction);
    } else {
      this.hideModel.emit(dataAction);
    }
  }
}
