<div class="card">
  <h5 class="card-header">Inventory Report</h5>
  <div class="card-body">
    <div style="display: flex; align-items: center; justify-content: flex-end;">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;"
        (click)="getmonthlybackupreport()">Export</button>
    </div>
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="ec2-details-tab" data-bs-toggle="tab" data-bs-target="#ec2-details"
          type="button" role="tab" aria-controls="ec2-details" aria-selected="true">
          EC2
        </button>
        <button class="nav-link" id="rds-details-tab" data-bs-toggle="tab" data-bs-target="#rds-details" type="button"
          role="tab" aria-controls="rds-details" aria-selected="false">
          RDS
        </button>
        <button class="nav-link" id="volume-details-tab" data-bs-toggle="tab" data-bs-target="#volume-details"
          type="button" role="tab" aria-controls="volume-details" aria-selected="false">
          Volume
        </button>
        <button class="nav-link" id="elastic-ips-details-tab" data-bs-toggle="tab" data-bs-target="#elastic-ips-details"
          type="button" role="tab" aria-controls="elastic-ips-details" aria-selected="false">
          Elastic IP
        </button>
        <button class="nav-link" id="load-balancer-details-tab" data-bs-toggle="tab"
          data-bs-target="#load-balancer-details" type="button" role="tab" aria-controls="load-balancer-details"
          aria-selected="false">
          Load Balancer
        </button>
      </div>
    </nav>

    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="ec2-details" role="tabpanel" aria-labelledby="ec2-details-tab">
        <div class="card" style="border-top: none !important">
          <!--<h5 class="card-header"></h5>-->

          <div class="card-body" id="billDetailsTabledetaildatareports_ec2">
            <!--<h5 class="card-title">Case Details</h5>-->

            <table class="table table-docs table-hover" id="monthly_table_ec2">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of ec2Headers">{{ h.name }}</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let i of ec2List; let j = index">
                  <td>{{ j + 1 }}</td>
                  <td *ngFor="let h of ec2Headers">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span>
                        {{ i[h.id] }}
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      {{ i[h.id] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="rds-details" role="tabpanel" aria-labelledby="rds-details-tab">
        <div class="card" style="border-top: none !important">
          <!--<h5 class="card-header"></h5>-->
          <div class="card-body" id="billDetailsTabledetaildatareports_rds">
            <!--<h5 class="card-title">Case Details</h5>-->

            <table class="table table-docs table-hover" id="monthly_table_rds">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of rdsHeaders">{{ h.name }}</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let i of rdsList; let j = index">
                  <td>{{ j + 1 }}</td>
                  <td *ngFor="let h of rdsHeaders">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span>
                        {{ i[h.id] }}
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      {{ i[h.id] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="volume-details" role="tabpanel" aria-labelledby="volume-details-tab">
        <div class="card" style="border-top: none !important">
          <!--<h5 class="card-header"></h5>-->
          <div class="card-body" id="billDetailsTabledetaildatareports_volume">
            <!--<h5 class="card-title">Case Details</h5>-->

            <table class="table table-docs table-hover" id="monthly_table_volume">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of volumeHeaders">{{ h.name }}</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let i of volumeList; let j = index">
                  <td>{{ j + 1 }}</td>
                  <td *ngFor="let h of volumeHeaders">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span>
                        {{ i[h.id] }}
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      {{ i[h.id] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="elastic-ips-details" role="tabpanel" aria-labelledby="elastic-ips-details-tab">
        <div class="card" style="border-top: none !important">
          <!--<h5 class="card-header"></h5>-->
          <div class="card-body" id="billDetailsTabledetaildatareport_elasticip">
            <!--<h5 class="card-title">Case Details</h5>-->

            <table class="table table-docs table-hover" id="monthly_table_elasticip">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of elasticIPsHeader">{{ h.name }}</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let i of elasticIPsList; let j = index">
                  <td>{{ j + 1 }}</td>
                  <td *ngFor="let h of elasticIPsHeader">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span>
                        {{ i[h.id] }}
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      {{ i[h.id] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="load-balancer-details" role="tabpanel" aria-labelledby="load-balancer-details-tab">
        <div class="card" style="border-top: none !important">
          <!--<h5 class="card-header"></h5>-->
          <div class="card-body" id="billDetailsTabledetaildatareport_loadbalancer">
            <!--<h5 class="card-title">Case Details</h5>-->

            <table class="table table-docs table-hover" id="monthly_table_loadbalancer">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of loadHeader">{{ h.name }}</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let i of loadList; let j = index">
                  <td>{{ j + 1 }}</td>
                  <td *ngFor="let h of loadHeader">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span>
                        {{ i[h.id] }}
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      {{ i[h.id] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>