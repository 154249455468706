import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';

declare let $: any;

@Component({
  selector: 'app-minfyriwr',
  templateUrl: './minfyriwr.component.html',
  styleUrls: ['./minfyriwr.component.css']
})
export class MinfyriwrComponent implements OnInit {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  linkedAccounts: any = [];
  masterAccounts: any = [];

  accountId: any;
  regionId: any;
  currentMessage: any;

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    $('#linkedAccountList').selectpicker();
    $('#masterAccountList').selectpicker();
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    this.notifier.loading(true);
    await this.fetchMasterAccounts();
  }

  async fetchMasterAccounts() {
    this.notifier.loading(true);
    let data = { action: 'fetchMasterAccountList' };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/prod/msopsusers/billing/fetchmasteraccountlist`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = result.masterAccountIDList;
      setTimeout(async () => {
        $('#masterAccountList').selectpicker();
        $('#masterAccountList').val(this.masterAccounts[0]);
        $('#masterAccountList').selectpicker('refresh');
        await this.fetchChildAccounts();
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.notifier.loading(false);
    }
  }

  async fetchChildAccounts() {
    this.notifier.loading(true);
    let data = {
      action: 'fetchAllChildAccountList',
      masterAccountID: $('#masterAccountList').val(),
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/prod/msopsusers/billing/fetchmasteraccountlist`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.linkedAccounts = result.childAccountDetailsList;
      setTimeout(async () => {
        $('#linkedAccountList').selectpicker('refresh');
        this.fetchSelectedChildAccounts();
      }, 100);
    } else {
      this.linkedAccounts = [];
      setTimeout(async () => {
        $('#linkedAccountList').selectpicker('refresh');
        this.fetchSelectedChildAccounts();
      }, 100);
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    // this.notifier.loading(false);
  }

  async fetchSelectedChildAccounts() {
    this.notifier.loading(true);
    let data = {
      a: 'fetch',
      mid: $('#masterAccountList').val(),
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/wrlids`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      setTimeout(() => {
        $('#linkedAccountList').val(result.lids);
        $('#linkedAccountList').selectpicker('refresh');
      }, 100);
    } else {
      $('#linkedAccountList').val([]);
      $('#linkedAccountList').selectpicker('refresh');
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async submitAccounts() {
    this.notifier.loading(true);
    let data = {
      a: 'add',
      mid: $('#masterAccountList').val(),
      lids: $('#linkedAccountList').val(),
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/wrlids`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

}
