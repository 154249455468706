<div class="alarm_screen" style="box-shadow:  1px 3px 1px #80808024 ">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform:capitalize">{{action == 'save' ? 'Create' : action}} EC2 Group</h4>
    </div>
    <div class="alarm_body" style=" padding: 1px 25px 5px 25px">
      <div class="row">
        <label>Client ID</label>
        <input type="text" class="form-control" [attr.disabled]="userId" [ngModel]="userId">
      </div>
      <div class="row">
        <label>Account ID</label>
        <input type="text" class="form-control" [attr.disabled]="true" [ngModel]="accountId">
      </div>

      <div class="row">
        <label>EC2 Group Name</label>
        <input type="text" class="form-control" *ngIf="action == 'save'" [(ngModel)]="group.groupName">
        <input type="text" class="form-control" *ngIf="action == 'update'" [attr.disabled]="true"
          [ngModel]="group.groupName">
      </div>
      <div class="row">
        <label>Select Instances List</label>
        <select class="form-control" multiple id="instancesList" data-live-search="true">
          <option *ngFor="let instance of instances" value="{{instance.instanceId}}">{{instance.instanceId}}
            {{instance.tagName ? '(' + instance.tagName + ')' : '(' + instance.instanceName + ')'}}</option>
        </select>
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end; gap: 8px;">
          <button class="btn" (click)="updateEntry()" [ngClass]="{'click-disabled': !writeAccess}"
            [attr.disabled]="!writeAccess ? true : null"
            style="background-color: #5864ff; color: white; text-transform:capitalize;">
            {{action == 'save' ? 'Create' : 'Update'}}
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>


  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>