<div class="card">
  <h5 class="card-header">
    <div *ngIf="oldEBSLoading">
      <img src="../assets/img/loading_2.svg" alt="loading rules" style="width: 25px; margin-right: 10px;">
      <span>Snapshot Ageing Report</span>
    </div>
    <div *ngIf="!oldEBSLoading">
      <span>Snapshot Ageing Report</span>
    </div>
  </h5>
  <div class="card-body">
    <div style="padding: 8px; display: flex; justify-content: flex-end;">
      <div style="text-align: right;"><button class="btn btn-light" style="background: #5864FF; color: white;"
          (click)="export()">Export</button></div>
    </div>
    <table class="table table-docs table-hover" id="ageingSnapshotsTable">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of oldEBSHeaders">{{h.name}}</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': oldEBSList.length > 0 ? 'table-row-group': ''}">
        <tr *ngFor="let i of oldEBSList; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of oldEBSHeaders">
            <span *ngIf="!h.click">
              <span *ngIf="!h.color">
                <span>{{i[h.id]}}</span>
              </span>
            </span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="oldEBSList.length == 0"
        [ngStyle]="{'display': oldEBSList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="oldEBSHeaders.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>