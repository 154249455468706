import { Component, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

import * as moment from 'moment';

declare let $: any;
declare let window: any;

@Component({
  selector: 'amis-launch-instance',
  templateUrl: 'amis.launch.component.html',
  styleUrls: [
    './../../launch.instance.component.css',
    './../../../../upload.bom.component.css',
  ],
})
export class AMIsLaunchInstanceComponent implements OnInit, OnDestroy {
  @Input('instance') instance: any;
  @Input('os') os: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.instance.image_id = '';
    let promises = [];
    this.notifier.loading(true);
    promises.push(this.loadAMIs());
    promises.push(this.loadAMIRoles());
    Promise.all(promises).then((value: any) => {
      this.notifier.loading(false);
    });
  }

  amis: any = [];
  roles: any = [];

  async loadAMIs() {
    let data: any = {
      action: 'list_amis',
      os: this.os[0].os,
      conditions: [
        this.os[0].os,
        this.instance.region_id,
        // this.instance.instance_type,
      ],
      instance_type: this.instance.instance_type,
      region_id: this.instance.region_id,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/admin/operations/ec2/create`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1') {
      this.amis = result.amis;
      if (this.amis.length == 1) {
        this.instance.image_id = this.amis[0]['ami_id'];
      }
    }
  }
  async loadAMIRoles() {
    let data: any = {
      action: 'list_ec2_roles',
      region_id: this.instance.region_id,
      account_id: this.instance.account_id,
      login_user_email: this.userId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'BOMv2.Components.Launch.Components.AMI',
      'AMI Roles Fetched'
    );
    let apiURL = `${APIService.API_ENDPOINTV3}/admin/operations/ec2/create`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1') {
      this.roles = result.ec2_roles;
      if (this.roles.length == 1) {
        this.instance.iam_role = this.roles[0]['arn'];
      }
    }
  }

  loadAMIS() {
    return this.amis.map((ami: any) => {
      return `${ami.ami_id} (${ami.os})`;
    });
  }

  updateReports(event: any) {
    if (event.event == 'add-item') {
      if (event.key == 'AMI') {
        this.instance.image_id = event.data;
      }
      if (event.key == 'Role') {
        this.instance.iam_role = event.data;
      }
    }
  }

  ngOnDestroy(): void {}
}
