import {
  Component,
  OnDestroy,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import { param } from 'jquery';

declare let $: any;
declare let window: any;
declare let Jhxlsx: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './pnldash.component.html',
  styleUrls: ['.././erp-pl-report.component.css'],
})
export class PnlDashComponent {
  accountId: any;
  regionId: any;
  currentMessage: any;
  dashData: any = [];
  rate: any;
  res: any = [];

  loadMasterAccounts() {}
  back() {}
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  cfrc_start_date: any;
  cfrc_end_date: any;
  dateRange: any;
  masterAccounts: any = [];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.load(),
      component: this,
    };

    let temp = moment();
    $('#monthDate').val(`${temp.format('MM')}-${temp.format('YYYY')}`);
    $('#monthDate')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm-yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        // this.convrate();
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        // this.convrate();
        this.load();
      }
    });
  }
  async loadDash() {
    this.notifier.loading(true);
    console.log("it's entering");
    let data = {
      a: 'fetchpnl',
      m: $('#monthDate').val(),
      isDevUser: '0',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchchildaccwithcosts/fetchchildaccwithcostsv2`;
    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.loading(false);
      this.dashData = result;
      console.log(this.dashData);
    } else {
      console.log(result);
      this.dashData = [];
      console.log(this.dashData);
    }

    // if (result.lek && Object.keys(result.lek).length > 0) {
    //   this.loadMasterAccounts(result.lek);
    // }
  }

  async load() {
    this.notifier.loading(true);
    await this.getinfo();
    // await this.convrate();
    this.notifier.loading(false);
  }

  async getinfo() {
    await this.loadDash();
    await this.convrate();
  }

  async convrate() {
    this.notifier.loading(true);
    let data = {
      a: 'fetch',
      monthYear: $('#monthDate').val(),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/currencyrate`;
    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.loading(false);
      this.res = result.rate;
      if (result.error) {
        this.res = '-';
      }
    } else {
      this.res = '-';
    }
  }
}
