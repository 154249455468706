<div class="card">
  <h5 class="card-header">EBS</h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">
            {{h.name}}
            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,ebsList)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
          <th>Tags</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display' : ebsList.length > 0 ? 'table-row-group': 'none'}"
        *ngFor="let i of ebsList; let j = index">
        <tr *ngIf="notifier.filterTableColums(headers,i)">
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span>
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
          <td><span class="btn" (click)="showTags(i)">Manage Tags</span></td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': ebsList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 2">
            <div style="text-align: center;">No EBS Volumes Found</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex-end" *ngIf="nextToken">
      <button class="swayam_blue btn btn-light" (click)="load()">Next</button>
    </div>
  </div>
</div>
<tags-modal *ngIf="showTagsModal" modalTitle="Tags" instanceID={{showTagsModal.volumeId}} accountID={{accountId}}
  regionID={{regionId}} serviceName="ebs" (hideModal)="hideMdoal($event)"></tags-modal>