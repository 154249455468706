<div class="card">
  <div class="card-header flex-between">
    <h5>PO Alerts</h5>
    <div class="flex-start">
      <button class="btn btn-light flex-center" (click)="loadAlerts()">
        <i class="fa fa-refresh"></i>
        <div>Refresh</div>
      </button>
      <button class="btn btn-primary-soft flex-center" (click)="add()">
        <i class="fa fa-plus"></i>
        <div>Add</div>
      </button>
    </div>
  </div>
  <div class="card-body">
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">
            {{ h.name }}
            <span
              *ngIf="h.sort && h.sort.sort"
              (click)="notifier.sortTable(h, alerts)"
            >
              <i
                class="fa fa-sort-amount-desc"
                aria-hidden="true"
                *ngIf="h.sort.direction == 'Des'"
              ></i>
              <i
                class="fa fa-sort-amount-asc"
                aria-hidden="true"
                *ngIf="h.sort.direction == 'Asc'"
              ></i>
            </span>
            <span *ngIf="h.filter" class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody
        [ngStyle]="{ display: alerts.length > 0 ? 'table-row-group' : 'none' }"
      >
        <ng-container *ngFor="let i of alerts; let j = index">
          <tr *ngIf="notifier.filterTableColums(headers, i)">
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of headers">
              <ng-container *ngIf="h.type == 'string'">
                <span *ngIf="!h.click">{{
                  h.alter ? h.alter(i, h.id) : i[h.id]
                }}</span>
              </ng-container>
              <ng-container *ngIf="h.type == 'status'">
                <div
                  class="flex-start status-container {{ i[h.id].toLowerCase() }}"
                >
                  <div class="round-indicator {{ i[h.id].toLowerCase() }}"></div>
                  <div>{{ i[h.id] }}</div>
                </div>
              </ng-container>
              <ng-container *ngIf="h.type == 'arrayOfLinks'">
                <ng-container *ngIf="i[h.id] && i[h.id].length > 0">
                  <span
                    *ngFor="let j of i[h.id]"
                    class="nav-link"
                    (click)="callFunction(h.click, j)"
                    >{{ j }}</span
                  >
                </ng-container>
                <ng-container *ngIf="!i[h.id] || i[h.id].length == 0">
                  <span>-</span>
                </ng-container>
              </ng-container>
            </td>
            <td>
              <span class="nav-link flex-center" (click)="update(i)">
                <i class="fa fa-pencil"></i>
                <div>Update</div>
              </span>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tbody *ngIf="alerts.length == 0 && !loading">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div style="text-align: center">No Alerts Found</div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="loading">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div style="text-align: center">
              <img
                src="../assets/img/loading_2.svg"
                alt="loading scans"
                width="18"
                style="width: 25px; margin: 10px"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<po-report-add
  *ngIf="updateEntry && action"
  [entry]="updateEntry"
  [action]="action"
  (hideModel)="loadAlerts()"
></po-report-add>
