import { Component, NgZone, OnInit } from '@angular/core';
import * as moment from 'moment';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

declare let $: any;
declare let window: any;

@Component({
  selector: 'app-erp-integrations',
  templateUrl: './erp-integrations.component.html',
  styleUrls: ['./erp-integrations.component.css'],
})
export class ErpIntegrationsComponent implements OnInit {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  erps: any = [];
  addErp: boolean = false;

  accountId: any;
  regionId: any;
  currentMessage: any;

  headers: any = [
    {
      id: 'customerName',
      name: 'Customer Name',
    },
    {
      id: 'customerID',
      name: 'Customer ID',
    },
    {
      id: 'erpOrderId',
      name: 'ERP Sales Order Number',
    },
    {
      id: 'accountId',
      name: 'AWS Account ID',
    },
    {
      id: 'currencyCode',
      name: 'Currency Code',
    },
    {
      id: 'swayamInvoice',
      name: 'Swayam Invoice Amount',
    },
    {
      id: 'awsInvoice',
      name: 'AWS Invoice Amount',
    },
    {
      id: 'diff',
      name: 'Difference',
    },
  ];

  funRef: any = {};
  deleteRI: any;
  unsavedUpdateId: number = -1;

  constructor(
    private notifier: NotifierService,
    private apiService: APIService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.funRef = {};

    $('.datetimepicker').val(moment().startOf('month').format('MM-YYYY'));
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm-yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) => {
        this.load();
      },
      component: this,
    };
  }

  async load() {
    this.notifier.loading(true);
    // this.testLoad();

    let data = { monthYear: $('.datetimepicker').val(), a: 'fetch' };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/asiscustomers`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      result.data = result.data.map((erp: any) => {
        erp['customerID'] = erp['acntId_custId'].split('_')[1];
        erp['accountId'] = erp['acntId_custId'].split('_')[0];
        erp['diff'] = (erp['awsInvoice'] - erp['swayamInvoice']).toFixed(2);
        return erp;
      });
      this.erps = result.data;
    } else if (result.error) {
      this.erps = [];
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  // async testLoad() {
  //   let header = {
  //     Authorization:
  //       'Basic ' +
  //       window.btoa('POWERBI:Ghyb5f4gqDbY7HQaYbOmcU0ZYBzh30CRGa8TuB1E7Qg='),
  //   };
  //   let apiURL = `http://52.172.209.32:9980/MinfyDev/OData/Company(%27Minfy%20Technologies%20Pvt.%20Ltd%27)/ASISSwayamIntegration`;
  //   let result = await this.apiService.getDataPromisXML(apiURL, {}, header);

  //   console.log(result);
  // }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel(
      'erp-table',
      `erp-${$('.datetimepicker').val()}`,
      'csv'
    );
  }

  updateTrigger() {
    this.addErp = true;
  }

  hideModel(event: any) {
    this.addErp = false;
    if (event) {
      this.load();
    }
  }

  callFunction(param: any, id: any) {
    this.funRef[param](this, id);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
