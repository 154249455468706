import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  NgZone,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FileUploadService } from 'src/app/fileupload/fileupload.service';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import { regions } from './../../../dash/dash.component';
import * as moment from 'moment';

declare let $: any;
declare let window: any;
@Component({
  styleUrls: ['./upload.bom.component.css'],
  templateUrl: './upload.bom.component.html',
})
export class UploadBOMv2Component implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  @ViewChild('file') attachmentElement: ElementRef;
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  regionId: any;
  currentMessage: any;
  bomFile: any;
  fileExists: boolean = false;
  mappedData: any = [];
  dateRange: any = { date: '' };
  stepCount: number = 1;
  deleteBOM: any;
  selectedBOM: any;
  billingTypes: any = [];
  regions: any;
  pricingStrategy: any = {};
  launch: any;
  processing: boolean = false;
  processFailed: any = {};
  funRef: any = {};

  headerList: any = [];

  uplaodBOMLoading: boolean = false;

  plans: any = [
    {
      plan: 'OD',
      duration: 2,
      slug: 'odCosts',
    },
    {
      plan: '1YNU',
      duration: 12,
      slug: 'ri1Yr',
    },

    {
      plan: '1YCNU',
      duration: 12,
      slug: 'riconv1Yr',
    },
    { plan: 'S1YNU', duration: 12, slug: 'cost_S1YNU' },
    { plan: 'S1YAU', duration: 12, slug: 'cost_S1YAU' },
    { plan: 'S1YPU', duration: 12, slug: 'cost_S1YPU' },
    {
      plan: '3YNU',
      duration: 36,
      slug: 'ri3Yr',
    },
    {
      plan: '3YCNU',
      duration: 36,
      slug: 'riconv3Yr',
    },
    { plan: 'S3YNU', duration: 36, slug: 'cost_S3YNU' },
    { plan: 'S3YAU', duration: 36, slug: 'cost_S3YAU' },
    { plan: 'S3YPU', duration: 36, slug: 'cost_S3YPU' },
    {
      plan: '1 YNU Spot Min',
      duration: 12,
    },
    {
      plan: '1 YCNU Spot Min',
      duration: 12,
    },
    {
      plan: '1 YNU Spot Max',
      duration: 12,
    },
    {
      plan: '1 YCNU Spot Max',
      duration: 12,
    },
    {
      plan: '3 YNU Spot Min',
      duration: 36,
    },
    {
      plan: '3 YCNU Spot Min',
      duration: 36,
    },
    {
      plan: '3 YNU Spot Max',
      duration: 36,
    },
    {
      plan: '3 YCNU Spot Max',
      duration: 36,
    },
  ];

  headers: any = [
    {
      id: 'os',
      name: 'OS',
    },
    { id: 'instanceType', name: 'Instance Type' },
    { id: 'region', name: 'Region' },
    { id: 'cpu', name: 'CPU' },
    {
      id: 'disk',
      name: 'Disk',
    },
    {
      id: 'quantity',
      name: 'Quantity',
    },
    {
      id: 'ram',
      name: 'Ram',
    },
    {
      id: 'billingType',
      name: 'Billing Type',
    },
    {
      id: 'usageHours',
      name: 'Usage in Hours',
    },
    {
      id: 'hourlyCost',
      name: 'Cost Per Hour',
    },
    {
      id: 'totalCost',
      name: 'Total Cost',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone,
    public fileUploadService: FileUploadService
  ) {}

  ngOnInit(): void {
    // this.fetchPlatformsTypes();
    // this.fetchInstanceTypes();
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.regions = regions;

    this.funRef = {
      loadBOM: this.loadBOM,
    };

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.fetchBOMs();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.fetchBOMs();
      }
    });
    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.addToFileList(docName, fileSize, targetDocBase64),
      component: this,
    };
  }

  destroyed: boolean = false;
  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }

  draging: boolean = false;
  dropedFile: any;
  uploadStatus: any = {
    status: 'NO_FILE_SELECTED',
    percentage: 0,
    uploadsize: 0,
    totalsize: 0,
  };
  dragIn(event: any) {
    event.preventDefault();
    this.draging = true;
  }

  showFile(event: any) {
    event.preventDefault();
    this.draging = false;
    this.dropedFile = event.dataTransfer.files[0];
    this.uploadStatus.status = 'FILE_SELECTED';
  }

  manualUploadTrigger(event: any) {
    event.preventDefault();
    this.attachmentElement.nativeElement.click();
  }

  manualUpload(event: any) {
    event.preventDefault();
    this.draging = false;
    this.dropedFile = event.target.files[0];
    this.uploadStatus.status = 'FILE_SELECTED';
  }

  cancelUpload() {
    this.dropedFile = null;
    $('#bomFile').val('');
  }

  getPercentage(val?: number) {
    if (!val) {
      val = (this.uploadStatus.uploadsize / this.uploadStatus.totalsize) * 100;
    }
    var r = 25;
    var c = Math.PI * (r * 2);

    if (val < 0) {
      val = 0;
    }
    if (val > 100) {
      val = 100;
    }

    let pct = ((100 - val) / 100) * c;

    return pct;
  }

  values: any = [
    {
      id: 'dashboard',
      name: 'Dashboard',
    },
    {
      id: 'prod',
      name: 'Prod',
    },
    {
      id: 'dev',
      name: 'Dev',
    },
    {
      id: 'uat',
      name: 'UAT',
    },
    {
      id: 'network',
      name: 'Network',
    },
    {
      id: 'storage',
      name: 'Storage',
    },
    {
      id: 'awsNativeSecurity',
      name: 'AWS Native Security',
    },
    {
      id: 'dr',
      name: 'DR',
    },
    {
      id: 'exclude',
      name: 'Exclude',
    },
  ];

  launchInstance(os: any) {
    this.launch = os;
  }

  selectableHeaders(headerList: any) {
    return headerList.filter((header: any) => {
      return header.id != '';
    });
  }

  refresh(event: any) {
    this.reset(event);
  }

  boms: any = [];
  bomSelected: any;
  fetchingBOMs: boolean = false;
  bomHeaders: any = [
    {
      id: 'file_name',
      name: 'File Name',
      click: 'loadBOM',
    },
    {
      id: 'start_time',
      name: 'Start Time',
    },
    {
      id: 'end_time',
      name: 'End Time',
    },
    {
      id: 'status',
      name: 'Status',
    },
  ];

  async fetchBOMs() {
    this.fetchingBOMs = true;
    this.bomSelected = null;
    this.boms = [];
    let selectedClientData = JSON.parse(localStorage.getItem('clientList'));
    let client_id = selectedClientData.find((client: any) => {
      return client.emailId == localStorage.getItem('selectedClientList');
    })['clientIdList'];
    let data = {
      action: 'list_boms',
      client_id: client_id,
      account_id: this.accountId,
      region_id: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'BOMs Fetched');
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/process`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      result.boms = result.boms.map((bom: any) => {
        bom['start_time'] = moment(bom['start_time']).format(
          'MMM DD, YYYY hh:mm A'
        );
        if (
          moment(bom['end_time'], 'DD-MM-YYYY hh:mm A')
            .format('MMM DD, YYYY hh:mm A')
            .toLowerCase() == 'invalid date'
        ) {
          bom['end_time'] = bom['end_time']
            ? moment(bom['end_time'], 'DD-MM-YYYY HH:MM:SS').format(
                'MMM DD, YYYY hh:mm A'
              )
            : '-';
        } else {
          bom['end_time'] = bom['end_time']
            ? moment(bom['end_time'], 'DD-MM-YYYY hh:mm A').format(
                'MMM DD, YYYY hh:mm A'
              )
            : '-';
        }
        return bom;
      });
      this.boms = result.boms;
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.fetchingBOMs = false;
  }

  loadBOM(ref: any, value: any) {
    ref.bomSelected = value;
    this.bomFile = null;
    this.launch = null;
    $('#bomFile').val('');
    this.stepsCompleted = 1;
    this.processing = false;
    this.processFailed = null;
    this.uplaodBOMLoading = false;
    ref.getBOMStatus();
  }

  newBOM() {
    this.stepsCompleted = 0;
    this.stepCount = 1;
    this.pricingStrategy = {};
    this.headerList = [];
    this.bomFile = null;
    this.dropedFile = null;
    this.launch = null;
    $('#bomFile').val('');
    this.bomSelected = {
      dollar_value: '',
      exchange_type: '',
      stage: '',
      status: 'NEW',
      start_time: '',
      file_name: '',
      s3_key: '',
      bom_id: '',
    };
  }

  downloadingBOM: string = null;
  async downloadBOM(bom_id: string, s3_key: string) {
    this.downloadingBOM = bom_id;
    let data = {
      action: 'download_bom',
      s3_key: s3_key,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'BOM Downloaded');

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/process`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      var link = document.createElement('a');
      link.download = s3_key + '.csv';
      link.href = result.download_url;
      link.click();
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }

    this.downloadingBOM = null;
  }

  resetBOMWizard() {
    this.bomSelected = null;
    this.stepsCompleted = 1;
    this.stepCount = 1;
    this.processing = false;
    this.processFailed = null;
    this.uplaodBOMLoading = false;
    this.dollarValue.type = 'fixed';
  }

  statusLoading: boolean = true;
  s3_key: boolean;
  stepsCompleted: number = 0;
  async getBOMStatus(loader: boolean = true) {
    if (loader) {
      this.notifier.loading(true);
    }
    let selectedClientData = JSON.parse(localStorage.getItem('clientList'));
    let client_id = selectedClientData.find((client: any) => {
      return client.emailId == localStorage.getItem('selectedClientList');
    })['clientIdList'];
    let data = {
      action: 'bom_status',
      client_id: client_id,
      account_id: this.accountId,
      region_id: this.regionId,
      bom_id: this.bomSelected['bom_id'],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'BOMStatus Fetched');
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/process`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    this.processing = false;
    if (loader) {
      this.notifier.loading(false);
    }
    if (result.status == '1' || result.s == '1') {
      if (result.details) {
        let dt = result.details;
        if (dt.dollar_value) {
          this.dollarValue.value = dt.dollar_value;
        }
        if (dt.exchange_type) {
          this.dollarValue.type = dt.exchange_type;
        }
        if (dt.start_date) {
          this.dateRange.date = moment(dt.start_date, 'DD-MM-YYYY').format(
            'DD-MMM-YYYY'
          );
        }
        if (dt.end_date) {
          this.dateRange.end_date = dt.end_date;
        }
        this.s3_key = dt.s3_key;
        this.stepsCompleted = Number(dt.stage);
        if (dt.status == 'Process Failed') {
          this.stepsCompleted = 3;
        }
        if (dt.stage == '1') {
          this.uploadStatus.status = 'SCANNING';
          await this.processFile(dt);
        }
        if (dt.stage == '2') {
          if (dt.sheet_mappings) {
            this.headerList = dt.sheet_mappings;
            this.headerList.forEach((header: any) => {
              if (header.value != '' && header.value != 'exclude') {
                this.pricingStrategy[header.key] = {
                  env_type: header.id,
                  plans: [],
                  start_date: '',
                  end_date: '',
                };
              }
            });
          }
          this.stepCount = 3;
        }
        if (dt.stage == '3') {
          if (dt.sheet_mappings) {
            this.headerList = dt.sheet_mappings;
          }
          if (dt.pricing_strategy) {
            this.pricingStrategy = dt.pricing_strategy;
          }
          this.stepCount = 4;
        }
        if (dt.stage == '4') {
          if (dt.status == 'Process started') {
            if (loader) {
              this.stepCount = 4;
            }
            this.processing = true;
            let interval: any;
            interval = setTimeout(() => {
              if (this.destroyed || this.bomSelected == null) {
                clearInterval(interval);
                return;
              }
              this.getBOMStatus(false);
            }, 3000);
          } else if (dt.status == 'Process Failed') {
            if (loader) {
              this.stepCount = 4;
            }
            this.processing = false;
            this.processFailed = {
              error_code: dt.error_code,
              error: dt.error,
            };
          } else if (dt.status == 'Process completed') {
            this.bomSelected['status'] = 'Process completed';
            if (
              !this.bomSelected['file_name'] ||
              this.bomSelected['file_name'] == ''
            ) {
              this.bomSelected['file_name'] = this.dropedFile.name;
            }
            this.stepCount = 5;
            this.processing = false;
          }
          if (dt.sheet_mappings) {
            this.headerList = dt.sheet_mappings;
          }
          if (dt.pricing_strategy) {
            this.pricingStrategy = dt.pricing_strategy;
          }
        }
        if (this.stepsCompleted >= 3) {
          if (this.pricingStrategy) {
            Object.keys(this.pricingStrategy).forEach((key: any) => {
              if (
                this.pricingStrategy[key] &&
                this.pricingStrategy[key]['plans'].length > 0
              ) {
                this.pricingStrategy[key]['plans'] = this.pricingStrategy[key][
                  'plans'
                ].map((strat: any) => {
                  if (strat.plan == 'OD' && strat.period == 'Months') {
                    let moment_obj = moment(
                      this.pricingStrategy[key]['start_date'],
                      'DD-MM-YYYY'
                    ).add(strat.duration, 'days');
                    strat.duration = moment
                      .duration(
                        moment_obj.diff(
                          moment(
                            this.pricingStrategy[key]['start_date'],
                            'DD-MM-YYYY'
                          )
                        )
                      )
                      .asMonths();
                    strat.duration = Math.floor(strat.duration);
                  }
                  return strat;
                });
              }
            });
          }
        }
      }
    } else {
      this.notifier.alert('Error', '', result.error_message, 'error', 5000);
    }
  }

  setHeaderName(header: any) {
    let obj = this.values.find((value: any) => {
      return value.id == header.id;
    });
    header.value = obj.name;
  }

  async getURL(override: boolean = false) {
    if (this.bomSelected['status'] == 'Process completed') {
      return;
    }
    this.notifier.loading(true);
    let selectedClientData = JSON.parse(localStorage.getItem('clientList'));
    let client_id = selectedClientData.find((client: any) => {
      return client.emailId == localStorage.getItem('selectedClientList');
    })['clientIdList'];
    let data = {
      action: 'initiate_upload',
      client_id: client_id,
      account_id: this.accountId,
      overwrite: override,
      file_name: this.dropedFile.name,
      region_id: this.regionId,
      bom_id:
        this.bomSelected['bom_id'] && this.bomSelected['bom_id'] != ''
          ? this.bomSelected['bom_id']
          : undefined,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'URL Fetched');
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/process`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.bomSelected['bom_id'] = result['bom_id'];
      this.notifier.loading(false);
      let response = await this.uploadFileAPI(result.upload_url);
      this.processFile(result);
    } else {
      if (result.error_message == 'BOM already exist for this account.') {
        this.fileExists = true;
        this.notifier.loading(false);
      } else {
        this.notifier.alert('Error', '', result.error_message, 'error', 5000);
      }
    }
    this.notifier.loading(false);
  }

  async uploadFileAPI(url: string) {
    return new Promise((resolve: any, reject: any) => {
      this.uploadStatus.status = 'UPLOADING';
      this.uploadStatus.uploadsize = 0;
      this.uploadStatus.totalsize = this.dropedFile.size;
      this.uploadStatus.percentage = 0;
      this.fileUploadService
        .uploadFile(url, this.dropedFile)
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              let progress = Math.round((event.loaded / event.total) * 100);
              this.uploadStatus.uploadsize = event.loaded;
              this.uploadStatus.totalsize = event.total;
              this.uploadStatus.percentage = progress;
              break;
            case HttpEventType.Response:
              resolve(true);
          }
        });
    });
  }

  async processFile(response: any) {
    this.uploadStatus.status = 'SCANNING';
    let data = {
      action: 'get_sheets',
      s3_key: response.s3_key,
      region_id: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'ProcessFiles Fetched');
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/process `;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      result.status == '1' ||
      result.s == '1' ||
      result.success == 1 ||
      result.success == '1'
    ) {
      this.headerList = result.sheets.map((sheet: any) => {
        return {
          key: sheet,
          value: 'Exclude',
          id: 'exclude',
        };
      });
      this.stepCount = 2;
      this.stepsCompleted = 1;
      this.processing = false;
      this.processFailed = null;
      this.uplaodBOMLoading = false;
      this.dollarValue.type = 'fixed';
      this.uploadStatus.status = 'FILE_SELECTED';
    } else {
      if (result.error_message) {
        this.notifier.alert('Error', '', result.error_message, 'error', 5000);
      } else {
        this.notifier.alert(
          'Error',
          '',
          'Failed, Please try again..',
          'error',
          5000
        );
      }
      this.uploadStatus.status = 'FILE_SELECTED';
    }
  }

  // Save Sheet Mappings
  async saveMappings() {
    this.uplaodBOMLoading = true;

    let selectedClientData = JSON.parse(localStorage.getItem('clientList'));

    let client_id = selectedClientData.find((client: any) => {
      return client.emailId == localStorage.getItem('selectedClientList');
    })['clientIdList'];
    let data = {
      action: 'save_sheet_mappings',
      sheet_mappings: this.headerList,
      client_id: client_id,
      account_id: this.accountId,
      region_id: this.regionId,
      bom_id: this.bomSelected['bom_id'],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'Mappings Saved');
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/process `;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1') {
      this.headerList.forEach((header: any) => {
        if (header.value != '' && header.value != 'exclude') {
          this.pricingStrategy[header.key] = {
            env_type: header.id,
            plans: [],
            start_date: '',
            end_date: '',
          };
        }
      });
      this.stepCount = 3;
    } else {
      this.notifier.alert('Error', '', result.error_message, 'error', 5000);
    }
    this.uplaodBOMLoading = false;
  }

  async saveStrategy() {
    return new Promise(async (resolve: any, reject: any) => {
      this.uplaodBOMLoading = true;

      let selectedClientData = JSON.parse(localStorage.getItem('clientList'));

      let client_id = selectedClientData.find((client: any) => {
        return client.emailId == localStorage.getItem('selectedClientList');
      })['clientIdList'];
      if (this.pricingStrategy['undefined']) {
        delete this.pricingStrategy['undefined'];
      }
      let temp: any = JSON.parse(JSON.stringify(this.pricingStrategy));

      Object.keys(temp).forEach((key: any) => {
        if (temp[key]['start_date']) {
          temp[key]['start_date'] = moment(
            temp[key]['start_date'],
            'DD-MMM-YYYY'
          ).format('DD-MM-YYYY');
        }
        if (temp[key] && temp[key]['plans'].length > 0) {
          temp[key]['plans'] = temp[key]['plans'].map((strat: any) => {
            if (strat.plan == 'OD' && strat.period == 'Months') {
              let moment_obj = moment(
                this.pricingStrategy[key]['start_date'],
                'DD-MMM-YYYY'
              ).add(strat.duration, 'months');
              strat.duration = moment
                .duration(
                  moment_obj.diff(
                    moment(
                      this.pricingStrategy[key]['start_date'],
                      'DD-MMM-YYYY'
                    )
                  )
                )
                .asDays();
              strat.duration = Math.ceil(strat.duration);
            }
            return strat;
          });
        }
      });

      let data = {
        action: 'save_pricing_strategy',
        pricing_strategy: temp,
        client_id: client_id,
        account_id: this.accountId,
        region_id: this.regionId,
        bom_id: this.bomSelected['bom_id'],
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      window.analyticsSendEventClick('BOMv2.Upload', 'Pricing_Strategy Saved');
      let apiURL = `${APIService.API_ENDPOINTV3}/admin/bom/process`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      this.uplaodBOMLoading = false;

      if (result.status == '1') {
        resolve(true);
      } else {
        reject(false);
        this.notifier.alert('Error', '', result.error_message, 'error', 5000);
      }
    });
  }

  getArray(plan: string) {
    return Array(this.getDuration(plan).duration)
      .fill(1)
      .map((x, i) => i); // [0,1,2,3,4]
  }

  getDuration(plan_str: string) {
    return this.plans.find((plan: any) => {
      return plan.plan == plan_str;
    });
  }

  validateData() {
    if (this.stepCount == 3) {
      let check = this.validatePricingStrategy();
      if (check) {
        this.stepCount = 4;
      } else {
      }
    }
  }

  checkForKeyValue(obj: any, key: string) {
    if (!obj[key] || obj[key] == '' || obj[key] == 0) {
      return false;
    }
    return true;
  }

  validatePricingStrategy() {
    let check = true;
    Object.keys(this.pricingStrategy).forEach((env: any) => {
      this.pricingStrategy[env]['plans'].forEach((strategy: any) => {
        if (strategy.plan == 'OD') {
          strategy['error'] = {};
          if (!this.checkForKeyValue(strategy, 'duration')) {
            strategy['error']['duration'] = 'Duration(in days) is mandatory';
            check = false;
          } else {
            if (strategy['error']['duration']) {
              delete strategy['error']['duration'];
            }
          }
          if (!this.checkForKeyValue(strategy, 'hours_per_month')) {
            strategy['error']['hours_per_month'] =
              'Hours Per Month(in hours) is mandatory';
            check = false;
          } else {
            if (strategy['error']['hours_per_month']) {
              delete strategy['error']['hours_per_month'];
            }
          }
          if (check) {
            delete strategy['error'];
          }
        }
      });
    });
    return check;
  }

  dollarValue: any = { value: '', type: 'fixed' };
  async finalize() {
    try {
      let start_response = await this.saveStrategy();
      if (!start_response) {
        return false;
      }
    } catch (err) {
      return false;
    }

    let selectedClientData = JSON.parse(localStorage.getItem('clientList'));

    let client_id = selectedClientData.find((client: any) => {
      return client.emailId == localStorage.getItem('selectedClientList');
    })['clientIdList'];

    this.uplaodBOMLoading = true;

    let data = {
      action: 'process_file',
      client_id: client_id,
      account_id: this.accountId,
      region_id: this.regionId,
      dollar_value: this.dollarValue.value.toString(),
      exchange_type: this.dollarValue.type,
      bom_id: this.bomSelected['bom_id'],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'Process_Files Finalized');
    let apiURL = `${APIService.API_ENDPOINTV3}/admin/bom/process`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1') {
      this.processing = true;
      this.getBOMStatus(false);
    } else {
      this.notifier.alert('Error', '', result.error_message, 'error', 5000);
    }
    this.uplaodBOMLoading = false;
    return true;
  }

  humanFileSize(bytes: any, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );
    return bytes.toFixed(dp) + ' ' + units[u];
  }

  instanceTypes: any = [];
  async fetchInstanceTypes() {
    this.notifier.loading(true);
    let data = { action: 'fetchInstanceTypes' };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'EC2InstanceTypes Fetched');
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.instanceTypes = result.instanceTypeList;
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }
  }

  async convertFiles(file: any) {
    let promises: any = [];
    if (file.type.indexOf('csv') < 0) {
      this.notifier.alert(
        'Info',
        '',
        'CSV file is only supported',
        'info',
        5000
      );
      return;
    }
    this.notifier.loading(true);
    let filePromise = new Promise(async (resolve: any, reject: any) => {
      var fileToLoad = file;

      var fileReader = new FileReader();
      var base64: any;
      let fileName = file.name;
      let fileSize = file.size;
      fileReader.onload = (fileLoadedEvent: any) => {
        base64 = fileLoadedEvent.target.result;
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn(
            fileName,
            fileSize,
            base64
          );
        });
        resolve({ fileName: fileName, fileSize: fileSize, data: base64 });
      };

      fileReader.readAsDataURL(fileToLoad);
      return;
    });
    Promise.all(promises).then((fileContents) => {
      this.notifier.loading(false);
    });
  }

  async getFile(file: any) {
    await this.convertFiles(file);
  }

  async reset(event: any) {
    this.bomFile = null;
    this.launch = null;
    $('#bomFile').val('');
    this.processFailed = null;
    this.mappedData = [];
    if (event) {
      if (this.stepCount == 4) {
        await this.getBOMStatus(false);
      } else {
        this.stepCount = 1;
        await this.getBOMStatus(true);
      }
    } else {
      this.stepCount = 1;
      this.stepsCompleted = 0;
    }
  }

  niceBytes(x: any) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  addToFileList(docName: string, fileSize: any, base64Data: any) {
    this.bomFile = {
      fileName: docName,
      fileSize: fileSize,
      data: base64Data,
    };
  }

  uploadFileError(event: any) {
    if (event) {
      this.getURL(true);
    }
    this.fileExists = false;
  }

  async setFile(event: any) {
    await this.convertFiles(event.target.files[0]);
  }

  hideModel(event: any) {
    if (event) {
      this.mappedData.push(event);
    }
    this.selectedBOM = null;
  }

  addBOMEntry() {
    this.selectedBOM = {
      instanceType: '',
      os: '',
      cpu: '',
      disk: '',
      quantity: '',
      region: this.regionId,
      uuid: window.uuidv4(),
      ram: '',
      billingType: '',
      usageHours: '',
      hourlyCost: '0',
      totalCost: '0',
    };
  }

  async changeInstanceType(bom: any) {
    await this.getEC2Pricing(bom);
    await this.fetchCPURAM(bom);
  }

  async fetchCPURAM(value: any) {
    if (value['instanceType'] == '') {
      return;
    }
    this.notifier.loading(true);
    let data = {
      action: 'fetchCpuRam',
      instanceType: value['instanceType'],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'CPURAM Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      value['cpu'] = result.cpu;
      value['ram'] = result.ram;
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }

    this.notifier.loading(false);
  }

  getTotal(value: any) {
    let total =
      (isNaN(value['hourlyCost']) ? 0 : Number(value['hourlyCost'])) *
      (isNaN(value['quantity']) ? 0 : Number(value['quantity'])) *
      (isNaN(value['usageHours']) ? 0 : Number(value['usageHours']));
    return total.toFixed(2);
  }

  retry_count: number = 0;
  loading_recom: boolean = true;
  async loadEC2Pricings(values: any) {
    this.loading_recom = true;
    let promises: any = [];
    values.forEach((value: any) => {
      promises.push(this.getEC2Pricing(value));
    });
    Promise.all(promises).then((values: any) => {
      this.loading_recom = false;
      promises = [];
    });
  }

  async getEC2Pricing(value: any) {
    return new Promise(async (resolve: any, reject: any) => {
      if (
        value['instanceType'] == '' ||
        value['os'] == '' ||
        value['billingType'] == ''
      ) {
        resolve(true);
      }
      this.notifier.loading(true);
      let data = {
        action: 'fetchRiPrice',
        region: value['region'],
        accountId: this.accountId,
        instanceType: value['instanceType'],
        os: value['os'],
        riType: value['billingType'],
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      window.analyticsSendEventClick('BOMv2.Upload', 'EC2Prices Fetched');
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2pricing`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        value['hourlyCost'] = result.onDemandCost.toString();
        value['totalCost'] =
          value['hourlyCost'] *
          (isNaN(value['quantity']) ? 0 : Number(value['quantity'])) *
          (isNaN(value['usageHours']) ? 0 : Number(value['usageHours']));
        value['totalCost'] = Number(value['totalCost']).toFixed(2);
      } else {
        this.notifier.alert('Error', '', result.error, 'error', 5000);
      }

      this.notifier.loading(false);
      resolve(true);
    });
  }

  async uploadFile(replace: boolean = false) {
    this.notifier.loading(true);
    let data = {
      base64: this.bomFile.data,
      fileName: this.bomFile.fileName,
      accountId: this.accountId,
      replaceExisting: replace,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'File Uploaded');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/uploadfile`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.retry_count = 0;

      if (result.msg == 'Already File Exists.') {
        this.fileExists = true;
      } else if (result.msg.toLowerCase() == 'y') {
        this.notifier.alert(
          'Success',
          '',
          'File Uploaded Successfully!!',
          'success',
          5000
        );
        this.notifier.alert('Info', '', 'Mapping Headers...', 'info', 8000);
        setTimeout(() => {
          this.fetchHeaders();
        }, 8000);
      } else {
        this.notifier.alert('Warning', '', result.msg, 'error', 5000);
      }
    } else {
      if (this.retry_count < 3) {
        await this.uploadFile(replace);
        this.retry_count++;
      } else {
        this.retry_count = 0;

        this.notifier.alert('Error', '', result.error, 'error', 5000);
      }
    }

    this.notifier.loading(false);
  }

  async fetchHeaders() {
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      action: 'headersList',
      fileName: this.bomFile.fileName,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'Headers Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.retry_count = 0;

      let dt = result.bomHeadersList;
      this.headerList = dt.map((haeder: any) => {
        return { key: haeder, id: 'exclude', value: 'Exclude' };
      });
      this.stepCount = 2;
    } else {
      if (this.retry_count < 3) {
        await this.fetchHeaders();
        this.retry_count++;
      } else {
        this.retry_count = 0;

        this.notifier.alert('Error', '', result.error, 'error', 5000);
      }
    }

    this.notifier.loading(false);
  }

  platformTypes: any = [];
  async fetchPlatformsTypes() {
    this.notifier.loading(true);
    let data = { action: 'fetchPlatformDetails', accountId: this.accountId };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'PlatformsTypes Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.platformTypes = result.billingTypeList;
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }
    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  async submitHeaders() {
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      action: 'mapHeaders',
      fileName: this.bomFile.fileName,
      headerMapList: this.headerList
        .filter((header: any) => {
          if (header.headerValue != 'none' && header.headerValue != '') {
            return true;
          } else {
            return false;
          }
        })
        .map((header: any) => {
          return {
            headerKey: header.headerValue,
            headerValue: header.headerKey,
          };
        }),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'Headers Submitted');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Info', '', 'Mapping Headers...', 'info', 8000);
      setTimeout(async () => {
        await this.fetchMappedData();
        this.stepCount = 3;
      }, 8000);
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }

    this.notifier.loading(false);
  }

  deleteDetail(i: any) {
    this.mappedData.splice(i, 1);
  }

  async fetchMappedData() {
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      action: 'fetchBOMMappedDetails',
      fileName: this.bomFile.fileName,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'BOMMappedDetails Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.retry_count = 0;
      this.mappedData = result.BOMDetailsList;
      this.mappedData = this.mappedData.map((data: any) => {
        data['hourlyCost'] = 'LOADING_ICON';
        data['totalCost'] = '0.0';
        return data;
      });
      this.loadEC2Pricings(this.mappedData);
      if (result.msg == 'Data not found') {
        if (this.retry_count < 3) {
          await this.fetchMappedData();
          this.retry_count++;
        } else {
          this.retry_count = 0;

          this.stepCount = 1;
          this.notifier.alert(
            'Error',
            '',
            result.msg + ', Please Try Again',
            'error',
            5000
          );
        }
        this.notifier.alert('Error', '', result.msg, 'error', 5000);
      } else {
        await this.fetchBillingTypes();
      }
    }

    this.notifier.loading(false);
  }

  async fetchBillingTypes() {
    this.notifier.loading(true);
    let data = { accountId: this.accountId, action: 'fetchBillingType' };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'BillingTypes Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.billingTypes = result.billingTypeList;
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }
    this.notifier.loading(false);
  }

  async submitBOMDetails() {
    this.notifier.loading(true);
    let data: any = {
      accountId: this.accountId,
      action: 'saveBOMDetails',
      bomDetailsList: this.mappedData,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('BOMv2.Upload', 'BOMDetails Submitted');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.reset(true);
      this.notifier.alert(
        'Success',
        '',
        result.msg + ', <a href="/dash/bom/view">View BOM Details</a>',
        'success',
        -1
      );
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }
    this.notifier.loading(false);
  }
}
