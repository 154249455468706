import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'start-ec2-ticket',
  templateUrl: './ticket.ec2startstop.component.html',
  styleUrls: ['./ticket.ec2startstop.component.css'],
})
export class BetaTicketEC2Start implements OnInit, OnDestroy {
  loading: boolean = false;
  @Input('message') message: String;
  @Input('title') title: String;
  @Output() hideModal = new EventEmitter<boolean>();
  constructor() {}
  ngOnInit(): void {}

  yes() {
    this.loading = true;
    this.hideModal.emit(true);
  }

  no() {
    this.hideModal.emit(false);
  }
  ngOnDestroy(): void {}
}
