import { Component, OnDestroy, OnInit, Input } from '@angular/core';

@Component({
  selector: 'instances-selection',
  templateUrl: './instances.component.html',
  styleUrls: ['./instances.component.css'],
})
export class InstancesSelectionComponent implements OnInit, OnDestroy {
  @Input('instances') instances: any;
  @Input('selection') selection: string;
  constructor() {}
  ngOnInit(): void {}

  checkForSelection(i: number) {
    if (this.selection == 'multiple') {
      return;
    }
    this.instances.forEach((instance: any, index: number) => {
      if (index != i) {
        instance.selected = false;
      }
    });
  }

  ngOnDestroy(): void {}
}
