<div class="amis_body">
  <div class="amis_more">
    <div>
      <div class="mb-3">
        <label for="" style="font-size: 0.9em; font-weight: bold;">Tags</label>
        <div style="margin: 10px 0px; width: 100%; display: grid; grid-template-columns: 1fr 1fr 200px; gap: 20px;"
          *ngFor="let tag of tags; let i = index">
          <div>
            <div>
              <label for="">
                Key
              </label>
            </div>
            <input type="text" name="" id="" placeholder="Key" [(ngModel)]="tag.Key">
          </div>
          <div>
            <div>
              <label for="">
                Value
              </label>
            </div>
            <input type="text" name="" id="" placeholder="Value" [(ngModel)]="tag.Value">
          </div>
          <div class="link" (click)="removeTag(i)" style="display: flex; align-items: center;">
            <div>
              <i class="fa fa-times" aria-hidden="true"></i>
              <span style="padding-left: 5px;">Remove</span>
            </div>
          </div>
        </div>
        <div class="link" (click)="addTag()"
          style="display: flex; justify-content: flex-end; align-items: center; gap: 5px;">
          <i class="fa fa-plus" aria-hidden="true"></i>
          <span>Add</span>
        </div>
        <div class="error-text" *ngIf="instance.errors && instance.errors.tags">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{instance.errors.tags}}
        </div>
      </div>
      <div class="mb-3">
        <label for="" style="font-size: 0.9em; font-weight: bold;">Key Pair</label>
        <launch-select-dropdown [options]="keyPairs" (update)="updateReports($event)" key="key_name"
          [selected]="instance.key_name" title="Key Pair"></launch-select-dropdown>
        <div class="error-text" *ngIf="instance.errors && instance.errors.key_name && !instance.key_name">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i> {{instance.errors.key_name}}
        </div>
      </div>
    </div>
  </div>
</div>