import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'cbs-costvariance',
  templateUrl: './costvariance.component.html',
  styleUrls: ['./costvariance.component.css'],
})
export class CostvarianceComponent implements OnInit {
  headers: any = [
    {
      id: 'mid',
      name: 'Master Account ID',
    },
    {
      id: 'lid',
      name: 'Linked Account ID',
      click: 'loadCostVariance',
    },
    {
      id: 'accountName',
      name: 'Account Name',
    },
  ];
  selectedLinkedAccount: any;
  selectedMasterAccount: any;
  variance: any = [];
  funRef: any = {};
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.fetchCostVariance();
    this.funRef = {
      loadCostVariance: this.loadCostVariance,
    };
  }

  loadCostVariance(ref: any, value: any) {
    ref.selectedLinkedAccount = value.lid;
    ref.selectedMasterAccount = value.mid;
  }

  fetching: boolean = true;
  async fetchCostVariance() {
    this.fetching = true;
    let data = {
      a: 'fetchtopvariance',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/admin/billing/costvariance`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.variance = result.data;
      if (this.variance.length > 0) {
        this.headers.push({
          id: 'cost1',
          name: moment(this.variance[0]['day1']['date']).format('MMM DD, YYYY'),
        });
        this.headers.push({
          id: 'cost2',
          name: moment(this.variance[0]['day2']['date']).format('MMM DD, YYYY'),
        });
        this.headers.push({
          id: 'variance',
          name: 'Variance (%)',
        });
      }
      result.data = result.data.map((date: any) => {
        date['cost1'] = date['day1']['totalbill'].toFixed(2);
        date['cost2'] = date['day2']['totalbill'].toFixed(2);
        date['variance'] = date['variance'].toFixed(2);
        return date;
      });
      this.variance[0];
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.fetching = false;
  }

  close(event: any) {
    this.selectedLinkedAccount = null;
    this.selectedMasterAccount = null;
  }

  callFunction(value: any, param: any) {
    this.funRef[value](this, param);
  }
}
