import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-cr-details',
  templateUrl: './cr-details.component.html',
  styleUrls: ['./cr-details.component.css'],
})
export class CrDetailsComponent implements OnInit {
  constructor() {}
  @Input('cr_detail') cr_detail: any;
  @Input('account_id') account_id: any;
  @Input('region_id') region_id: any;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();
  sr: any;
  userId = localStorage.getItem('eId');
  loading: boolean = false;
  loadingCR: boolean = false;

  async ngOnInit() {
    this.sr = await this.loadSR();
  }

  hide() {
    this.hideModel.emit(true);
  }

  checkForReviewer() {
    return this.cr_detail['metadata']['emails'].indexOf(this.userId) > -1;
  }

  parseDate(date: string) {
    return moment(date).format('MMM DD, YYYY hh:mm A');
  }

  async loadSR() {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const account = selectedAccountData.data;
        return window.btoa(
          JSON.stringify({
            jira_project_id: account.jira_project_id,
            jira_organization_id: account.jira_org_id,
          })
        );
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.account_id;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }
}
