<div class="alarm_screen" style="box-shadow: 1px 3px 1px #80808024">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform: capitalize">
        {{ action == "update" ? "update" : "add" }} CFRC
      </h4>
    </div>
    <div class="alarm_body" style="padding: 1px 25px 5px 25px">
      <div class="row">
        <div class="p-0">
          <div
            [ngStyle]="{
              display: action == 'add' || action == 'update' ? 'block' : 'none'
            }"
          >
            <label>CFRC Period: </label>
            <input
              type="text"
              id="dataRangePicker"
              [(ngModel)]="cfrc.dateRange"
              class="form-control"
              style="width: 300px"
              placeholder="Select date"
            />
          </div>
        </div>
        <div class="p-0">
          <label for="">Region</label>
          <select
            id="regionId"
            class="form-control selectpicker"
            data-live-search="true"
            [disabled]="action == 'update'"
          >
            <option value="" disabled>--Select the Region--</option>
            <option *ngFor="let region of enabledRegion" value="{{ region }}">
              {{ region }}
            </option>
          </select>
        </div>
        <div class="p-0">
          <label for="">Usage Type</label>
          <select
            id="usageTypeId"
            class="form-control selectpicker"
            data-live-search="true"
            [disabled]="action == 'update'"
          >
            <option value="" disabled>--Select the Usage Type--</option>
            <option *ngFor="let usage of usageTypes" value="{{ usage }}">
              {{ usage }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <label>Cost per Hours</label>
        <input
          type="number"
          class="form-control"
          [(ngModel)]="cfrc.costPerHour"
        />
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button
            class="btn"
            (click)="updateEntry()"
            style="
              background-color: #5864ff;
              color: white;
              text-transform: capitalize;
              margin: right 20px;
            "
          >
            {{ action == "add" ? "add" : action }}
          </button>
          <button class="btn btn-light" (click)="close(false)">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>
</div>
