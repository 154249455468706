<div class="card mt-20" style="border: none; position: relative; height: 100%;">
  <div class="card-header">
    <span class="title" style="font-size: 22px; line-height: 45px; vertical-align: middle; position: relative;">Master
      Summary</span>
    <button class="btn btn-primary-soft" style="float: right; margin: 0 10px" (click)="refresh()">
      <i class="fa fa-refresh" aria-hidden="true"></i>
      Refresh
    </button>
    <button class="btn btn-success-soft" style="float: right; margin: 0 10px"
      *ngIf="(!fetching && !nextToken && this.summary.length > 0) || (!fetching && selectedMasterAccount)"
      (click)="export()"><i class="fa fa-download" aria-hidden="true"></i>
      Download</button>
  </div>
  <div class="card-body" [ngStyle]="{display: selectedMasterAccount ? 'none' : 'block'}">
    <div style="display: flex; justify-content: flex-end; align-items: center; margin: 10px 0px;">
      <input type="text" id="monthDate" [(ngModel)]="dateRange">
    </div>
    <div style="margin: 10px 0px; padding: 10px; border-radius: 10px; box-shadow: 1px 1px 3px 1px grey;">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th *ngFor="let h_ of CBSPortal ? CBSSummaryHeader : summaryHeader">{{ h_.name }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td *ngFor="let h_ of CBSPortal ? CBSSummaryHeader : summaryHeader">
              <span *ngIf="!h_.click" style="font-weight: bold;"
                [innerHTML]="h_.alter ? h_.alter(sumAccount(), h_.id) : sumAccount()[h_.id]"> </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <table class="table table-docs table-hover" id="master_accounts_table">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of CBSPortal ? CBSHeader :headers">
            {{ h.name }}
            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,summary)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody [ngStyle]="{'display': summary.length > 0 ? 'table-row-group' : 'none'}">
        <ng-container *ngFor="let i of summary; let j = index">
          <tr *ngIf="notifier.filterTableColums(CBSPortal ? CBSHeader :headers ,i)">
            <td>{{j + 1}}</td>
            <td *ngFor="let h of CBSPortal ? CBSHeader :headers">
              <span *ngIf="!h.click">{{h.alter ? h.alter(i, h.id) : i[h.id]}}</span>
              <span class="btn" *ngIf="h.click" (click)="callFunction(h.click, i)">{{h.alter ? h.alter(i, h.id) :
                i[h.id]}}</span>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tbody *ngIf="summary.length == 0 && !fetching">
        <tr>
          <td [attr.colspan]=" (CBSPortal ? CBSHeader :headers ).length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="text-align: center;" *ngIf="fetching">
      <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" style="width: 25px; margin: 10px" />
    </div>

  </div>
  <div class="card-body" *ngIf="selectedMasterAccount">
    <div style="padding: 5px; display: flex; justify-content: flex-start; align-items: center; gap: 6px;">
      <span style="color: blue; cursor: pointer;" (click)="selectedMasterAccount = null">Master Accounts</span>
      <span>></span>
      <span>{{selectedMasterAccount.masterAccountId}}</span>
    </div>
    <!-- <div style="margin: 10px 0px; padding: 10px; border-radius: 10px; box-shadow: 1px 1px 3px 1px grey;">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th *ngFor="let h_ of CBSPortal ? CBSSummaryHeader : summaryHeader">{{ h_.name }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td *ngFor="let h_ of CBSPortal ? CBSSummaryHeader : summaryHeader">
              <span *ngIf="!h_.click" style="font-weight: bold;"
                [innerHTML]="h_.alter ? h_.alter(sumAccount(), h_.id) : sumAccount()[h_.id]"> </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->
    <table class="table table-docs table-hover" id="linked_data_table">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of lidAccountsHeader">
            {{ h.name }}
            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,selectedMasterAccount.lids)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody [ngStyle]="{'display': selectedMasterAccount.lids.length > 0 ? 'table-row-group' : 'none'}">
        <ng-container *ngFor="let i of selectedMasterAccount.lids; let j = index">
          <tr *ngIf="notifier.filterTableColums(lidAccountsHeader ,i)">
            <td>{{j + 1}}</td>
            <td *ngFor="let h of lidAccountsHeader">
              <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"></span>
              <span class="btn" *ngIf="h.click" (click)="callFunction(h.click, i)" [innerHTML]="h.alter ? h.alter(i, h.id) :
                i[h.id]"></span>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tbody *ngIf="selectedMasterAccount.lids.length == 0 && !fetching">
        <tr>
          <td [attr.colspan]="(lidAccountsHeader ).length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="text-align: center;" *ngIf="fetching">
      <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" style="width: 25px; margin: 10px" />
    </div>

  </div>
</div>