<div class="alarm_screen" style="box-shadow: 1px 3px 1px #80808024">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform: capitalize">
        {{ action == "update" ? "update" : "add" }} MRR
      </h4>
    </div>
    <div class="alarm_body" style="padding: 1px 25px 5px 25px">
      <div class="row">
        <div class="flex-between p-0">
          <label>Service Name</label>
          <div class="p-0 flex-start" *ngIf="action !== 'update'">
            <input
              type="checkbox"
              name=""
              (click)="selectAllOpt()"
              id="service_name"
            />
            <label for="service_name">Select All</label>
          </div>
        </div>
        <div class="p-0">
          <select
            id="serviceNameId"
            class="form-control selectpicker"
            data-live-search="true"
            [disabled]="action == 'update'"
            multiple
          >
            <option value="" disabled>--Select the service name--</option>
            <option *ngFor="let name of serviceNamelist" value="{{ name }}">
              {{ name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <label>MRR Discount</label>
        <input
          type="number"
          class="form-control"
          [(ngModel)]="mrr.mrrDiscount"
        />
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button
            class="btn"
            (click)="updateEntry()"
            style="
              background-color: #5864ff;
              color: white;
              text-transform: capitalize;
              margin: right 20px;
            "
          >
            {{ action == "add" ? "add" : action }}
          </button>
          <button class="btn btn-light" (click)="close(false)">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>
</div>
