import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';

@Component({
  selector: 'app-po-alert',
  templateUrl: './po-alert.component.html',
  styleUrls: ['./po-alert.component.css'],
})
export class PoAlertComponent implements OnInit {
  alerts: any = [];
  loading: boolean = true;
  updateEntry: any = null;
  action: any = null;

  headers: any = [
    {
      id: 'customerName',
      name: 'Client Name',
      filter: true,
      type: 'string',
    },
    {
      id: 'docNumber',
      name: 'Doc Number',
      filter: true,
      type: 'string',
    },
    {
      id: 'startDate',
      name: 'Start Date',
      filter: true,
      type: 'string',
    },
    {
      id: 'expiryDate',
      name: 'End Date',
      filter: true,
      type: 'string',
    },
    {
      id: 'docs',
      name: 'Documents',
      type: 'arrayOfLinks',
      click: 'downloadDoc',
    },
    {
      id: 'status',
      name: 'Status',
      filter: true,
      type: 'status',
    },
  ];

  funRef: any = {};

  constructor(
    private apiService: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.funRef = {
      downloadDoc: this.downloadDoc,
    };
    this.loadAlerts();
  }

  async loadAlerts() {
    this.updateEntry = null;
    this.action = null;
    this.loading = true;
    this.alerts = [];
    let data: any = {
      a: 'fetch',
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/admin/billing/poalerts`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == '1' || result.s == '1') {
      this.alerts = result.data;
    }

    this.loading = false;
  }

  async downloadDoc(ref: any, item: any) {
    let data: any = { a: 'getFile', fileName: item };
    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/admin/billing/poalerts`;

    let result: any = await ref.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == '1' || result.s == '1') {
      let link: string = result?.preUrl?.url;
      if(link) {
        let a: any = document.createElement('a');
        a.href = link;
        a.download = true;
        a.target = '_blank';
        a.click();
      }
    }
  }

  update(item: any) {
    this.action = 'update';
    this.updateEntry = item;
  }

  add() {
    this.action = 'add';
    this.updateEntry = {
      customerName: '',
      docNumber: '',
      startDate: '',
      expiryDate: '',
      receivers: [],
      docs: [],
    };
  }

  callFunction(param: any, value: any) {
    this.funRef[param](this, value);
  }
}
