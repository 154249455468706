<div class="card" style="border-top: none !important">
  <div class="card-header">
    <span>Uptime Report</span>
  </div>

  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->
    <div style="display: flex; justify-content: flex-end; align-items: center; gap: 10px;">
      <select class="form-control" style="width: 100px;" name="reportType" [(ngModel)]="reportType"
        (change)="loadInstances()">
        <option value="Weekly">Weekly</option>
        <option value="Monthly">Monthly</option>
        <option value="Quarterly">Quarterly</option>
        <option value="Yearly">Yearly</option>
      </select>
      <div style="display: flex; align-items: center; justify-content: flex-end;" *ngIf="uptimeList.length > 0">
        <button class="btn btn-light" style="background-color: #5864FF; color: white;"
          (click)="export()">Export</button>
      </div>
    </div>

    <table class="table table-docs table-hover" id="uptimeTable">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of uptimeHeader">{{ h.name }}</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let i of uptimeList; let j = index">
          <td>{{ j + 1 }}</td>
          <td *ngFor="let h of uptimeHeader">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span>
                {{ i[h.id] }}
              </span>
            </span>
            <span *ngIf="!h.click">
              <span *ngIf="i[h.id] == 'LOADING_CHECK'">
                <img src="../assets/img/loading.svg"
                    style="margin-right: 10px; width: 40px; height: 40px;" alt="" />
              </span>
              <span *ngIf="i[h.id] != 'LOADING_CHECK'">
                {{i[h.id]}}
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>