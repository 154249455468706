import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'minfyui-new-cr-otp-validation',
  templateUrl: './otpvalidation.component.html',
  styleUrls: ['./otpvalidation.component.css'],
})
export class OtpvalidationComponent implements OnInit {
  @Input('otpValidationObj') otpValidationObj: any;
  @Input('accountId') accountId: any;
  @Input('region') region: any;
  @Input('request_id') request_id: string;
  otp: string;
  @Output('hideModal') hideModal = new EventEmitter<boolean>();
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';

  ngOnInit(): void {}

  hideModalCaller(event: boolean) {
    this.hideModal.emit(event);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  loadingCR: boolean = false;
  async validateOTP() {
    this.loadingCR = true;
    let data = {
      action: 'ec2_change_request_validate',
      id_token: localStorage.getItem('idT'),
      request_id: this.request_id,
      otp: this.otp,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.message, 'success', 5000);
      this.hideModalCaller(true);
    } else {
      this.notifier.alert('Success', '', result.error_message, 'success', 5000);
    }
    this.loadingCR = false;
  }
}
