<table class="table table-docs table-hover" id="master_accounts_table">
  <thead>
    <tr>
      <th>Sl. No.</th>
      <th *ngFor="let h of headers">
        {{ h.name }}
        <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,groups)">

          <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
          <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
        </span>

        <span *ngIf="h.filter " class="inputseach">
          <input type="text" [(ngModel)]="h.filterText" />
          <i class="fa fa-search" aria-hidden="true"></i>
        </span>
      </th>
    </tr>
  </thead>
  <tbody [ngStyle]="{'display': groups.length > 0 ? 'table-row-group' : 'none'}">
    <ng-container *ngFor="let i of groups; let j = index">
      <tr *ngIf="notifier.filterTableColums(headers ,i)">
        <td>{{j + 1}}</td>
        <td *ngFor="let h of headers">
          <span *ngIf="!h.click">{{h.alter ? h.alter(i, h.id) : i[h.id]}}</span>
          <span class="btn" *ngIf="h.click" (click)="callFunction(h.click, i)">{{h.alter ? h.alter(i, h.id) :
            i[h.id]}}</span>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tbody *ngIf="groups.length == 0 && !fetching">
    <tr>
      <td [attr.colspan]=" (headers ).length + 1">
        <div style="text-align: center;">No Groups Found</div>
      </td>
    </tr>
  </tbody>
</table>
<div style="text-align: center;" *ngIf="fetching">
  <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" style="width: 25px; margin: 10px" />
</div>