import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

import * as moment from 'moment';
declare let window: any;

@Component({
  selector: 'cloudfront-billing',
  templateUrl: 'cloudfront.billing.component.html',
  styleUrls: ['./../../billing.component.css'],
})
export class CloudFrontBillingComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input('activeAccount') activeAccount: any;
  @Input('monthYear') monthYear: any;
  @Input('diffDays') diffDays: any;

  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;

  regions: any = [];
  showConditionalElement = false;

  headers: any = [
    {
      id: 'description',
      name: 'Description',
    },
    {
      id: 'totalUsage',
      name: 'Usage',
    },
    {
      id: 'totalCost',
      name: 'Total Cost',
      alter: (value: any, param: string) => {
        return isNaN(value[param])
          ? '-'
          : Number(value[param]).toFixed(2) !=
              Number(value['totalUpdatedCost']).toFixed(2) &&
            !isNaN(value['totalUpdatedCost']) &&
            this.diffDays > 0
          ? `<span class='line_through'>${Number(value[param]).toFixed(
              2
            )}</span> <span>${Number(value['totalUpdatedCost']).toFixed(
              2
            )}</span>`
          : `<span>${Number(value[param]).toFixed(2)}</span>`;
      },
    },
  ];
  exportHeaders: any = [
    {
      id: 'region',
      name: 'Region',
      export: true,
      display: true,
    },
    {
      id: 'description',
      name: 'Description',
      export: true,
      display: true,
    },
    {
      id: 'totalUsage',
      name: 'Usage',
      export: true,
      display: true,
    },
    {
      id: 'totalCost',
      name: 'Total Cost',
      conditionalShow: false,
      export: false,
      display: true,
      alter: (value: any, param: string) => {
        console.log(this.diffDays);
        return isNaN(value[param])
          ? '-'
          : Number(value[param]).toFixed(2) !=
              Number(value['totalUpdatedCost']).toFixed(2) && this.diffDays > 0
          ? `<span class='line_through'>${Number(value[param]).toFixed(
              2
            )}</span> <span>${Number(value['totalUpdatedCost']).toFixed(
              2
            )}</span>`
          : `<span>${Number(value[param]).toFixed(2)}</span>`;
      },
    },
    {
      id: 'totalCost',
      name: 'Total Cost',
      export: true,
      conditionalShow: false,
      display: false,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? '-' : Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'totalUpdatedCost',
      name: 'Discount',
      export: true,
      display: false,
      conditionalShow: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param])
          ? '-'
          : (Number(value['totalCost']) - Number(value[param])).toFixed(2);
      },
    },
    {
      id: 'totalUpdatedCost',
      name: 'Final Cost',
      export: true,
      display: false,
      conditionalShow: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? '-' : Number(value[param]).toFixed(2);
      },
    },
  ];

  accountId: any;
  regionId: any;
  currentMessage: any;
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeAccount) {
      if (!this.monthYear || !this.activeAccount) {
        this.notifier.alert('Info', '', 'Failed to Load Data', 'info', 5000);
      } else {
        this.loadData();
      }
    }
  }

  ngOnInit(): void {}

  export(region: any) {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    if (this.diffDays > 0) {
      this.showConditionalElement = true;
    }

    setTimeout(() => {
      window.exportToExcel(
        'regionExportData',
        `cloudfront_${this.activeAccount.lid}`,
        'csv'
      );
      setTimeout(() => {
        this.showConditionalElement = false;
      }, 100);
    }, 100);
  }

  // async loadRegions() {
  //   this.notifier.loading(true);
  //   let data = {
  //     month_year: this.monthYear,
  //     lid: this.activeAccount.lid,
  //     masterAccId: $('#masterAccountSelect')
  //       ? $('#masterAccountSelect').val()
  //       : undefined,
  //     userType: 'C',
  //     action: 'fetchCloudFrontRegions',
  //   };
  //   let header = {
  //     Authorization: localStorage.getItem('t'),
  //   };
  //   let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

  //   let result = await this.apiServer.postDataPromis(apiURL, data, header);

  //   if (result.status == '1' || result.s == '1') {
  //     let dt = result.regionList;
  //     this.regions = dt.map((region: any) => {
  //       let data: any = [];
  //       return { name: region, expanded: false, data: data };
  //     });
  //     this.regions.length > 0 && this.loadData();
  //   } else {
  //     this.notifier.alert('Info', '', result.error, 'info', 5000);
  //   }
  //   this.notifier.loading(false);
  // }

  exportData: any = [];
  async loadData() {
    this.notifier.loading(true);
    let data = {
      month_year: this.monthYear,
      masterAccId: $('#masterAccountSelect')
        ? $('#masterAccountSelect').val()
        : undefined,
      lid: this.activeAccount.lid,
      userType: 'C',
      action: 'fetchCloudFrontData',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      let dt = result.cloudFrontDetailsList;
      this.exportData = result.cloudFrontDetailsList;
      this.regions = this.regions.map((region: any) => {
        region.data = [];
        return region;
      });
      if (dt) {
        dt.forEach((i: any) => {
          let region = this.regions.find((region: any) => {
            return region.name == i.region;
          });
          if (region) {
            region.data.push(i);
          } else {
            this.regions.push({
              data: [],
              name: i.region,
            });
          }
        });
      }
    }

    this.notifier.loading(false);
  }

  showData(region: any) {
    region.expanded = !region.expanded;
  }

  ngOnDestroy(): void {}
}
