<div class="card">
  <h5 class="card-header">Enable Master Account</h5>
  <div class="card-body">
    <div class="progress_bar">
      <div class="progress" [ngStyle]="{'width': ((step / steps) * 100) + '%'}"></div>
    </div>
    <div *ngIf="step == 1">
      <form (submit)="reviewAccount()">
        <div>
          <label class="label">Master Account ID</label>
          <input type="text" class="form-control" name="masteraccountid" [(ngModel)]="mai">
        </div>
        <div>
          <label class="label">Account Name</label>
          <input type="text" class="form-control" name="masteraccountname" [(ngModel)]="an">
        </div>
        <div>
          <label class="label">Bucket Name</label>
          <input type="text" class="form-control" name="bucketname" [(ngModel)]="bn">
        </div>
        <div>
          <label class="label">Folder Name</label>
          <input type="text" class="form-control" name="foldername" [(ngModel)]="folderName">
        </div>
        <div>
          <label class="label">Region</label>
          <select class="form-control" id="regionsList" name="regions" [(ngModel)]="region" data-live-search="true">
            <option *ngFor="let region of regions" value="{{region.id}}">{{region.name}}</option>
          </select>
        </div>
        <div style="display: flex; justify-content: flex-start; gap: 8px; align-items: center;">
          <button class="btn btn-light" style="background-color: #5864FF; color: white;" type="submit">Review</button>
        </div>
      </form>
    </div>
    <div *ngIf="step == 2">
      <form (submit)="sendOTP()">
        <div style="display: flex; gap: 8px;">
          <div>Master Account ID</div>
          <div style="font-weight: bold;">{{mai}}</div>
        </div>
        <div style="display: flex; gap: 8px;">
          <div>Account Name</div>
          <div style="font-weight: bold;">{{an}}</div>
        </div>
        <div style="display: flex; gap: 8px;">
          <div>Bucket Name</div>
          <div style="font-weight: bold;">{{bn}}</div>
        </div>
        <div style="display: flex; gap: 8px;">
          <div>Folder Name</div>
          <div style="font-weight: bold;">{{folderName}}</div>
        </div>
        <div style="display: flex; gap: 8px;">
          <div>Region</div>
          <div style="font-weight: bold;">{{region}}</div>
        </div>
        <div style="display: flex; justify-content: flex-start; gap: 8px; align-items: center;">
          <button class="btn btn-light" type="button" (click)="step = 1">Back</button>
          <button class="btn btn-light" style="background-color: #5864FF; color: white;" type="submit">Submit</button>
        </div>
      </form>
    </div>
    <div *ngIf="step == 3">
      <form (submit)="validateOTP()" name="validateOTPForm">
        <div>
          <label class="label" name="otp">OTP</label>
          <input name="otp" type="text" class="form-control" [(ngModel)]="otp">
        </div>
        <div style="display: flex; justify-content: flex-start; gap: 4px; align-items: center;">
          <button class="btn btn-light" type="button" (click)="step = 2">Back</button>
          <button class="btn btn-light" type="button" (click)="sendOTP()">Send Again</button>
          <button class="btn btn-light" style="background-color: #5864FF; color: white;" type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>