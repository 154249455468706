import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

declare let $: any;
declare let window: any;
@Component({
  templateUrl: './ridetails.component.html',
})
export class ridetailsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  s3List: any = [];
  filterType: string = 'ALL';

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  funRef: any = {};
  dateRange: string;

  headers: any = [
    {
      id: 'os',
      name: 'OS',
    },
    { id: 'type', name: 'Instance Type' },
    { id: 'totalHours', name: 'Total Hours' },
    { id: 'utilizedHours', name: 'Utilized Hours' },
    {
      id: 'message',
      name: 'Status',
    },
    {
      id: 'quantity',
      name: 'RI Purchased',
      click: 'loadRIPurchased',
    },
  ];

  riPurshasedHeader: any = [
    {
      id: 'rInstanceId',
      name: 'RI Instance ID',
    },
    {
      id: 'offeringType',
      name: 'Offering Type',
    },
    {
      id: 'offeringClass',
      name: 'Offering Class',
    },
    {
      id: 'durationInSeconds',
      name: 'Duration (years)',
    },
    {
      id: 'expiryPeriod',
      name: 'Expiry Period',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.funRef = {
      loadRIPurchased: this.loadRIPurchased,
    };

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null) {
        return;
      }

      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load(true);
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load(true);
      }
    });

    $('.datetimepicker').val(moment().startOf('month').format('MM-YYYY'));
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm-yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) => {
        this.load();
      },
      component: this,
    };
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load(refresh: boolean = false) {
    if (refresh) {
      this.s3List = [];
    }
    this.notifier.loading(true);
    let monthYear = moment().startOf('month').format('MM-YYYY');

    let dRange = $('.datetimepicker').val();
    if (dRange) {
      dRange = dRange.toString().split('-');
      monthYear = dRange[0] + '-' + dRange[1];
    }
    this.dateRange = monthYear;
    let data = {
      action: 'FetchRiUtilization',
      region: this.regionId,
      accountId: this.accountId,
      monthYear: monthYear,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/riutilization`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.statusCode == '1' || result.s == '1') {
      result.riCoverageResponses = result.riCoverageResponses.map(
        (instance: any) => {
          instance.riDetailsList = instance.riDetailsList.map((ri: any) => {
            if (ri.durationInSeconds) {
              ri.durationInSeconds = (
                (Number(ri.durationInSeconds) * 1000) /
                31540000000
              ).toFixed();
            }
            let temp = ri.expiryPeriod;
            if (ri.expiryPeriod) {
              ri.expiryPeriod = moment(
                ri.expiryPeriod,
                'dd-MM-yyyy HH:mm:ss'
              ).format('MMM DD, YYYY hh:mm A');
            }
            if (ri.expiryPeriod == 'Invalid date') {
              ri.expiryPeriod = temp;
            }
            return ri;
          });
          return instance;
        }
      );
      this.s3List = result.riCoverageResponses;
    } else {
      this.s3List = [];
    }

    this.notifier.loading(false);
  }

  riPurchasedModal: any;
  loadRIPurchased(ref: any, value: any) {
    ref.riPurchasedModal = value.riDetailsList;
  }

  hidePop(event: any) {
    this.riPurchasedModal = null;
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
