<div class="minfyui-change-request-ui">
  <div class="main_contaniner">
    <div class="minfy-change-request-container">
      <div class="change_request_header">
        <div style="cursor: pointer; float: right; font-size: 1.2em">
          <i class="fa fa-times clickable" aria-hidden="true" (click)="hideModalCaller(false)"></i>
        </div>
        <div class="header_bold">Change Request - OTP Validation</div>
        <div class="header_description">
          <div>Account ID: {{ accountId }}</div>
          <div>Region: {{ region }}</div>
        </div>
      </div>
      <div class="change_request_body">
        <div class="step_3_container">
          <h5 style="text-align: center;">OTP Validation</h5>
          <p style="text-align: center;">Please confirm the initiation of the Change Request by validating OTP</p>
          <div class="row">
            <div class="form-group">
              <input type="text" class="form-control" [(ngModel)]="otp">
            </div>
          </div>
        </div>
      </div>
      <div class="change_request_footer">
        <button class="btn btn-primary-soft" (click)="validateOTP()" [attr.disabled]="loadingCR ? true : null"
          [ngStyle]="{ 'padding-left': loadingCR ? '30px' : undefined }" style="
                position: relative;
                ">
          <span *ngIf="loadingCR" style="
                              position: absolute;
                              top: 50%;
                              left: 5px;
                              transform: translateY(-50%);
                            ">
            <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 16px" />
          </span>
          <span style="color: inherit">Validate</span>
        </button>
      </div>
    </div>
  </div>
</div>