<!-- toggler -->
<!--<a class="navbar-brand" >

</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>-->
<!--<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
<button class="btn btn-primary" type="submit">Search</button> *ngIf="searchOnList(i.groupName,accountGroupSearchTerm)" -->
<!--<button class="btn float-end" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
  <i class="bi bi-arrow-left-square-fill fs-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"></i>
</button>-->
<!--<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>-->
<!--<div class="leftmenu offcanvas offcanvas-start  show" tabindex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="false">
  <div class="offcanvas-header">
    <h6 class="offcanvas-title d-none d-sm-block" id="offcanvas" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
      <img style="width:50px" src="../assets/img/logo.png" />
    </h6>
  </div>-->
<div class="leftmenu">
  <div class="offcanvas-body px-0" style="max-height: 100%; overflow-y: auto">
    <button
      class="menuCloseButton"
      (click)="hideLeftMenu()"
      id="menuClosebtn"
      style="
        background: none;
        border: none;
        margin-left: 15px;
        margin-bottom: 5px;
      "
    >
      <i class="fa fa-arrow-circle-right fa-rotate-180"></i>
    </button>

    <button
      class=""
      (click)="showLeftMenu()"
      id="menuShowbtn"
      style="
        display: none;
        background: none;
        border: none;
        margin-left: 15px;
        margin-bottom: 5px;
      "
    >
      <i class="fa fa-arrow-circle-right"></i>
    </button>

    <ul
      class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start"
      id="menu"
    >
      <li class="dropdown" *ngIf="checkIfAvaForUser('cloudSupport')">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Cloud+Support.svg"
            alt=""
            style="width: 40px"
          />
          Support
        </a>
        <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdown">
          <li>
            <h3 class="sub-menu-title">Support</h3>
            <hr />
          </li>
          <li
            *ngIf="
              checkIfAvaForUser('cloudSupport:ticket:create') && writeAccess
            "
          >
            <a class="dropdown-item" routerLink="/dash/ticket/create">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Create+Ticket.svg"
                style="width: 40px"
                alt=""
              /><span>Create Ticket</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cloudSupport:ticket:list')">
            <a class="dropdown-item" routerLink="/dash/ticket/list">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/View+Ticket.svg"
                style="width: 40px"
                alt=""
              /><span>View Tickets</span>
            </a>
          </li>

          <li
            *ngIf="
              checkIfAvaForUser('cloudSupport:knowledgebase') &&
              urlPrefix == 'admin'
            "
          >
            <a class="dropdown-item" routerLink="/dash/knowledgebase">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Knowledge+Base.svg"
                style="width: 40px"
                alt=""
              /><span>Knowledge Base</span>
            </a>
          </li>
        </ul>
      </li>

      <li class="dropdown" *ngIf="checkIfAvaForUser('Operations')">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Operations+(Manage+Cloud+Resources).svg"
            alt=""
            style="width: 40px"
          />
          Operations
        </a>

        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">Operations</h3>
            <hr />
          </li>

          <li *ngIf="checkIfAvaForUser('Operations:ec2')">
            <a class="dropdown-item" routerLink="/dash/operations/ec2">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+(Manage+EC2+Instances).svg"
                alt=""
                style="width: 40px"
              />
              <span>EC2</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Operations:tags')">
            <a class="dropdown-item" routerLink="/dash/operations/tags">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Tags.svg"
                style="width: 40px"
                alt=""
              /><span>Tags</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Operations:rds')">
            <a class="dropdown-item" routerLink="/dash/operations/rds">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+RDS.svg"
                style="width: 40px"
                alt=""
              /><span>RDS</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Operations:ebs')">
            <a class="dropdown-item" routerLink="/dash/operations/ebs">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+EBS.svg"
                style="width: 40px"
                alt=""
              /><span>EBS</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Operations:securityGroups')">
            <a class="dropdown-item" routerLink="/dash/operations/sg">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Security+Group.svg"
                style="width: 40px"
                alt=""
              /><span>Security Groups</span>
            </a>
          </li>
          <!-- <li *ngIf="checkIfAvaForUser('Operations:ServiceIntelligence')">
            <a class="dropdown-item" routerLink="/dash/operations/itsi">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Service+Intelligence.svg"
                style="width: 40px" alt="" /><span>Service Intelligence</span>
            </a>
          </li> -->
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('CostOptimization')">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Cost+Optimization.svg"
            style="width: 40px"
            alt=""
          />
          Cost Controls
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">Cost Controls</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('CostOptimization:ec2')">
            <a class="dropdown-item" routerLink="/dash/costopt/ec2">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>EC2</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BetaCostOptimization:ebs')">
            <a class="dropdown-item" routerLink="/dash/costoptimization/ebs">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+EBS.svg"
                style="width: 40px"
                alt=""
              /><span>EBS</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('CostOptimization:other')">
            <a class="dropdown-item" routerLink="/dash/costopt/other">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Other+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>Other</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('CostOptimization:floatingReport')">
            <a class="dropdown-item" routerLink="/dash/costopt/floating">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Floating+Report.svg"
                style="width: 40px"
                alt=""
              /><span>Floating Report</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('Compliance')">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Compliance.svg"
            style="width: 40px"
            alt=""
          />
          Compliance
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          style="min-width: 300px"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">Compliance</h3>
            <hr />
          </li>
          <li
            class="divider"
            style="border-top: 0px solid transparent"
            (click)="expandDropdown($event, 'infra')"
          >
            Infra
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </li>
          <ng-container *ngIf="expanded['infra']">
            <li *ngIf="checkIfAvaForUser('BetaComplaincec:ec2')">
              <a class="dropdown-item" routerLink="/dash/compliance/ec2">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                  style="width: 40px"
                  alt=""
                /><span>EC2</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Compliance:ebs')">
              <a class="dropdown-item" routerLink="/dash/compliance/ebs">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+EBS.svg"
                  style="width: 40px"
                  alt=""
                /><span>EBS</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('BetaComplaincec:rds')">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/findings"
                [queryParams]="{ audit_type: 'rds' }"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+RDS.svg"
                  style="width: 40px"
                  alt=""
                /><span>RDS</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Compliance:tags')">
              <a class="dropdown-item" routerLink="/dash/compliance/tags">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Tags.svg"
                  style="width: 40px"
                  alt=""
                /><span>Tags</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Compliance:s3')">
              <a class="dropdown-item" routerLink="/dash/compliance/s3">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+S3.svg"
                  style="width: 40px"
                  alt=""
                /><span>S3</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('s3:age_report`') && writeAccess">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/s3/age_report"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+S3.svg"
                  style="width: 40px"
                  alt=""
                /><span>S3 Age Report</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('BetaComplaince:lambda')">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/findings"
                [queryParams]="{ audit_type: 'lambda' }"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                  style="width: 40px"
                  alt=""
                /><span>Lambda Compliance</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('BetaComplaince:cloudwatch')">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/findings"
                [queryParams]="{ audit_type: 'cloudwatch' }"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                  style="width: 40px"
                  alt=""
                /><span>Cloudwatch Compliance</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('BetaComplaince:cloudfront')">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/findings"
                [queryParams]="{ audit_type: 'cloudfront' }"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                  style="width: 40px"
                  alt=""
                /><span>Cloudfront Compliance</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('BetaComplaince:apigateway')">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/findings"
                [queryParams]="{ audit_type: 'apigateway' }"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                  style="width: 40px"
                  alt=""
                /><span>API Gateway Compliance</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('BetaComplaince:dynamodb')">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/findings"
                [queryParams]="{ audit_type: 'dynamo' }"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                  style="width: 40px"
                  alt=""
                /><span>DynamoDB Compliance</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('BetaComplaince:cft')">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/findings"
                [queryParams]="{ audit_type: 'cft' }"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                  style="width: 40px"
                  alt=""
                /><span>Cloud Formation Compliance</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('BetaComplaince:ses')">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/findings"
                [queryParams]="{ audit_type: 'ses' }"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                  style="width: 40px"
                  alt=""
                /><span>SES Compliance</span>
              </a>
            </li>
          </ng-container>
          <li class="divider" (click)="expandDropdown($event, 'security')">
            Security
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </li>
          <ng-container *ngIf="expanded['security']">
            <li *ngIf="checkIfAvaForUser('Compliance:iam')">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/findings"
                [queryParams]="{ audit_type: 'iam' }"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg"
                  style="width: 40px"
                  alt=""
                /><span>IAM</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('iam:users`') && writeAccess">
              <a class="dropdown-item" routerLink="/dash/compliance/iam/users">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg"
                  style="width: 40px"
                  alt=""
                /><span>IAM Users</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('iam:age_report`') && writeAccess">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/iam/age_report"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg"
                  style="width: 40px"
                  alt=""
                /><span>IAM Access Key Age Report</span>
              </a>
            </li>

            <li *ngIf="checkIfAvaForUser('Compliance:securityGroups')">
              <a class="dropdown-item" routerLink="/dash/compliance/sg">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Tags.svg"
                  style="width: 40px"
                  alt=""
                /><span>Security Groups</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Compliance:inspector')">
              <a class="dropdown-item" routerLink="/dash/compliance/inspector">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Inspector.svg"
                  style="width: 40px"
                  alt=""
                /><span>Inspector</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Compliance:securityhub')">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/securityhub"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Security+Hub.svg"
                  style="width: 40px"
                  alt=""
                /><span>Security Hub</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('BetaComplaince:kms')">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/findings"
                [queryParams]="{ audit_type: 'kms' }"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                  style="width: 40px"
                  alt=""
                /><span>KMS Compliance</span>
              </a>
            </li>
          </ng-container>

          <li class="divider" (click)="expandDropdown($event, 'maintenance')">
            Maintenance
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </li>
          <ng-container *ngIf="expanded['maintenance']">
            <li *ngIf="checkIfAvaForUser('Compliance:cloudTrail')">
              <a class="dropdown-item" routerLink="/dash/compliance/cloudtrail">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Cloud+Trail.svg"
                  style="width: 40px"
                  alt=""
                /><span>Cloud Trail</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Compliance:config')">
              <a class="dropdown-item" routerLink="/dash/compliance/config">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Settings.svg"
                  style="width: 40px"
                  alt=""
                /><span>Config</span>
              </a>
            </li>

            <li *ngIf="checkIfAvaForUser('Compliance:patchReport')">
              <a class="dropdown-item" routerLink="/dash/compliance/patch">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Patch+Report.svg"
                  style="width: 40px"
                  alt=""
                /><span>Patch Report</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('Compliance:advisor')">
              <a class="dropdown-item" routerLink="/dash/compliance/advisor">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Trusted+Advisor.svg"
                  style="width: 40px"
                  alt=""
                /><span>Trusted Advisor</span>
              </a>
            </li>
            <li *ngIf="checkIfAvaForUser('BetaComplaince:cloudtrail')">
              <a
                class="dropdown-item"
                routerLink="/dash/compliance/findings"
                [queryParams]="{ audit_type: 'cloudtrail' }"
              >
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                  style="width: 40px"
                  alt=""
                /><span>Cloudtrail Compliance</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('SLAReport')">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/SLA+Report.svg"
            style="width: 40px"
            alt=""
          />
          SLA Report
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">SLA Report</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('SLAReport:createdVsResolved')">
            <a class="dropdown-item" routerLink="/dash/sla/createdvsresolved">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(Created+vs+Resolved).svg"
                style="width: 40px"
                alt=""
              /><span>Created vs Resolved</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('SLAReport:firstResponseSLA')">
            <a class="dropdown-item" routerLink="/dash/sla/firstresponsesla">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(First+Response+SLA).svg"
                style="width: 40px"
                alt=""
              /><span>First Response SLA</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('SLAReport:resolutionSLA')">
            <a class="dropdown-item" routerLink="/dash/sla/resolutionsla">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(Resolution+SLA).svg"
                style="width: 40px"
                alt=""
              /><span>Resolution SLA</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item" *ngIf="checkIfAvaForUser('Monitoring')">
        <a
          routerLink="/dash/monitoring"
          class="nav-link text-truncate"
          title="Monitoring"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Monitoring.svg"
            style="width: 40px"
            alt=""
          />
          Monitoring
        </a>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('Anomalies')">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Anomalies.svg"
            style="width: 40px"
            alt=""
          />
          Anomalies
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">Anomalies</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('Anomalies:dashboard')">
            <a class="dropdown-item" routerLink="/dash/anomalies/dashboard">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Dashboard</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Anomalies:history')">
            <a class="dropdown-item" routerLink="/dash/anomalies/history">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/History.svg"
                style="width: 40px"
                alt=""
              /><span> Trigger History </span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Anomalies:expections')">
            <a class="dropdown-item" routerLink="/dash/anomalies/exceptions">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Exceptions.svg"
                style="width: 40px"
                alt=""
              /><span>Exceptions</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('BOM') && BOMAccess">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/BOM.svg"
            style="width: 40px"
            alt=""
          />
          BOM
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">BOM</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('BOM:uploadBOM') && writeAccess">
            <a class="dropdown-item" routerLink="/dash/bom/upload">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Upload+BOM.svg"
                style="width: 40px"
                alt=""
              /><span>Upload BOM</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BOM:ViewBOMDetails')">
            <a class="dropdown-item" routerLink="/dash/bom/view">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/View+BOM+Details.svg"
                style="width: 40px"
                alt=""
              /><span>View BOM Details</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BOM:DeltaReport')">
            <a class="dropdown-item" routerLink="/dash/bom/deltareport">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Delta+Report.svg"
                style="width: 40px"
                alt=""
              /><span>Delta Report</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('BOM') && BOMAccess">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/BOM.svg"
            style="width: 40px"
            alt=""
          />
          BOM v2
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">BOM v2</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('BOM:uploadBOM') && writeAccess">
            <a class="dropdown-item" routerLink="/dash/bom/uploadv2">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Upload+BOM.svg"
                style="width: 40px"
                alt=""
              /><span>Upload BOM</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BOM:viewCRs') && writeAccess">
            <a class="dropdown-item" routerLink="/dash/bom/changerequests">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Upload+BOM.svg"
                style="width: 40px"
                alt=""
              /><span>Change Requests</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('Automation')">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/BOM.svg"
            style="width: 40px"
            alt=""
          />
          Automation
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">Automation</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('Automation:Scheduler') && writeAccess">
            <a
              class="dropdown-item"
              routerLink="/dash/beta/automation/scheduler"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Upload+BOM.svg"
                style="width: 40px"
                alt=""
              /><span>Scheduler</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('BackupReport')">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Backup.svg"
            style="width: 40px"
            alt=""
          />
          Backup Report
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">Backup Report</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('BackupReport:Snapshots')">
            <a class="dropdown-item" routerLink="/dash/backupreport">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Backup.svg"
                style="width: 40px"
                alt=""
              /><span>Snapshots</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BackupReport:AMIs')">
            <a class="dropdown-item" routerLink="/dash/amis">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Backup.svg"
                style="width: 40px"
                alt=""
              /><span>AMIs</span>
            </a>
          </li>
        </ul>
      </li>
      <!--<li class="nav-item" *ngIf="checkIfAvaForUser('BackupReport')">
    <a routerLink="/dash/backupreport" class="nav-link text-truncate" title="Backup Report">
      <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Backup.svg"
           style="width: 40px;" alt=""> Backup Report
    </a>
  </li>-->

      <li class="dropdown" *ngIf="checkIfAvaForUser('Billing')">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Billing.svg"
            style="width: 40px"
            alt=""
          />
          Billing
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">Billing</h3>
            <hr />
          </li>
          <!-- <li *ngIf="checkIfAvaForUser('Billing:dashboard')">
            <a class="dropdown-item" routerLink="/dash/billing/dashboard">
              <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg"
                style="width: 40px" alt="" /><span>Dashboard</span>
            </a>
          </li> -->
          <li *ngIf="checkIfAvaForUser('Billing:monthlyBill')">
            <a class="dropdown-item" routerLink="/dash/billing">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg"
                style="width: 40px"
                alt=""
              /><span>Monthly Bill</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BetaBilling:dashboard')">
            <a class="dropdown-item" routerLink="/dash/billing-daily">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg"
                style="width: 40px"
                alt=""
              /><span>Daily Cost Variance</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Billing:RIOrderReport')">
            <a class="dropdown-item" routerLink="/dash/billing/ri">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg"
                style="width: 40px"
                alt=""
              /><span>RI Order Report</span>
            </a>
          </li>

          <li *ngIf="checkIfAvaForUser('Billing:RIOrderReport')">
            <a class="dropdown-item" routerLink="/dash/billing/budgets">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg"
                style="width: 40px"
                alt=""
              /><span>Budgets</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('Reports')">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Report.svg"
            style="width: 40px"
            alt=""
          />
          Reports
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">Reports</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('Reports:view')">
            <a class="dropdown-item" routerLink="/dash/reports/view">
              <i class="fa fa-eye"></i><span>View Reports</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Reports:enable') && writeAccess">
            <a class="dropdown-item" routerLink="/dash/reports/enable">
              <i class="fa fa-cart-plus"></i><span>Enable Reports</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="betaAccess">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Report.svg"
            style="width: 40px"
            alt=""
          />
          Beta
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">Beta</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('BetaCostOptimization:tags')">
            <a
              class="dropdown-item"
              routerLink="/dash/beta/costoptimization/tags"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>Tags Cost Controls</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BetaCostOptimization:rds')">
            <a
              class="dropdown-item"
              routerLink="/dash/beta/costoptimization/rds"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>RDS Cost Controls</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('iam:groups`') && writeAccess">
            <a class="dropdown-item" routerLink="/dash/beta/iam/groups">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg"
                style="width: 40px"
                alt=""
              /><span>IAM Groups</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BetaBilling:ri_details')">
            <a class="dropdown-item" routerLink="/dash/beta/ridetails">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg"
                style="width: 40px"
                alt=""
              /><span>RI Details</span>
            </a>
          </li>
          <!-- <li *ngIf="checkIfAvaForUser('BetaOperations:ec2')">
            <a class="dropdown-item" routerLink="/dash/beta/operations/ec2">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+(Manage+EC2+Instances).svg"
                style="width: 40px" alt="" /><span>EC2 Operations</span>
            </a>
          </li> -->
          <li *ngIf="checkIfAvaForUser('BetaComplaince:dynamodb')">
            <a
              class="dropdown-item"
              routerLink="/dash/beta/costoptimization/ec2/all"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>EC2 Metrics Data</span>
            </a>
          </li>
          <!-- <li *ngIf="checkIfAvaForUser('cbs:ec2threshhold')">
            <a class="dropdown-item" routerLink="/dash/cbs/ec2threshhold">
              <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px" alt="" /><span>EC2 Usage Alarms</span>
            </a>
          </li> -->
          <li *ngIf="checkIfAvaForUser('cbs:wafr')">
            <a class="dropdown-item" routerLink="/dash/beta/wafr">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>WAFR</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('security:ssb')">
            <a class="dropdown-item" routerLink="/dash/ssb">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>SSB</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BetaComplaince:s3')">
            <a
              class="dropdown-item"
              routerLink="/dash/compliance/findings"
              [queryParams]="{ audit_type: 's3' }"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>S3 Compliance</span>
            </a>
          </li>
          <li
            *ngIf="
              checkIfAvaForUser('BetaComplaince:Security-Groups-Compliance')
            "
          >
            <a
              class="dropdown-item"
              routerLink="/dash/compliance/findings"
              [queryParams]="{ audit_type: 'sg' }"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>Security Groups Compliance</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BetaComplaince:kubernetes')">
            <a
              class="dropdown-item"
              routerLink="/dash/compliance/findings"
              [queryParams]="{ audit_type: 'kube' }"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>Kubernetes Compliance</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BetaComplaince:es')">
            <a
              class="dropdown-item"
              routerLink="/dash/compliance/findings"
              [queryParams]="{ audit_type: 'es' }"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>Elastic Search Compliance</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BetaComplaince:sns')">
            <a
              class="dropdown-item"
              routerLink="/dash/compliance/findings"
              [queryParams]="{ audit_type: 'sns' }"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>SNS Compliance</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BetaComplaince:redshift')">
            <a
              class="dropdown-item"
              routerLink="/dash/compliance/findings"
              [queryParams]="{ audit_type: 'redshift' }"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>Redshift Compliance</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BetaComplaince:elb')">
            <a
              class="dropdown-item"
              routerLink="/dash/compliance/findings"
              [queryParams]="{ audit_type: 'elb' }"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>ELB Compliance</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('BetaComplaince:route53')">
            <a
              class="dropdown-item"
              routerLink="/dash/compliance/findings"
              [queryParams]="{ audit_type: 'route53' }"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg"
                style="width: 40px"
                alt=""
              /><span>Route 53 Compliance</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('Settings')">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Settings.svg"
            style="width: 40px"
            alt=""
          />
          Settings
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">Settings</h3>
            <hr />
          </li>
          <li
            *ngIf="
              checkIfAvaForUser('Settings:viewInvitedUser') && ut == 'admin'
            "
          >
            <a class="dropdown-item" routerLink="/dash/settings/users/view">
              <i class="fa fa-server"></i><span>Users</span>
            </a>
          </li>
          <li
            *ngIf="
              checkIfAvaForUser('Settings:operations/ec2groups') &&
              urlPrefix == 'client' &&
              ut == 'admin'
            "
          >
            <a class="dropdown-item" routerLink="/dash/settings/ec2group/view">
              <i class="fa fa-server"></i><span> EC2 Groups </span>
            </a>
          </li>
          <li
            *ngIf="
              checkIfAvaForUser('Settings:policies') &&
              urlPrefix == 'client' &&
              ut == 'admin'
            "
          >
            <a class="dropdown-item" routerLink="/dash/settings/policies/view">
              <i class="fa fa-server"></i><span>Policies</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Settings:auditTrail')">
            <a class="dropdown-item" routerLink="/dash/settings/audittrail">
              <i class="fa fa-file-text"></i><span> Audit Trail </span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Settings:userManagement')">
            <a class="dropdown-item" routerLink="/dash/settings/profile">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/User.svg"
                style="width: 40px"
                alt=""
              /><span>User Management</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

<div class="" style="position: fixed; top: 0; width: 100%; z-index: 99">
  <div class="row">
    <div class="col" style="padding-right: 0">
      <nav class="navbar navbar-expand-lg top-menu">
        <div class="container-fluid">
          <div class="nav-item" style="width: 175px">
            <a
              routerLink="/dash/"
              class="nav-link text-truncate"
              style="padding: 0"
            >
              <img
                style="width: 115px"
                src="../assets/img/swayam_logo_v3.0.svg"
              />
            </a>
          </div>

          <form
            class="flex-grow-1"
            name="topsearchbox"
            style="position: relative"
            id="topsearchbox"
          >
            <div class="input-group">
              <input
                name="searchValue"
                type="text"
                class="form-control"
                [(ngModel)]="searchValue"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="btnGroupAddon"
                (input)="onSearchChange()"
                (focus)="showSearch()"
                maxlength="20"
              />
              <!--(focusout)="hideSearch()"-->
              <div class="input-group-text" id="btnGroupAddon">
                <i class="icon bi-search" aria-hidden="true"></i>
              </div>
            </div>
          </form>

          <div
            id="searchbackground"
            (click)="hideSearch()"
            *ngIf="showSearchResult && searchValue && searchValue.length > 1"
          ></div>
          <div
            id="searchResult"
            *ngIf="showSearchResult && searchValue && searchValue.length > 1"
          >
            <div class="container">
              <div class="row">
                <div class="col">
                  <h3>Search Results for '{{ searchValue }}'</h3>
                </div>
              </div>
              <div class="row">
                <!--<div class="col-4" style="padding:0">
                  <ul class="list-group">
                    <li class="list-group-item" *ngFor="let i of searchData.category">{{i.name}}</li>
                 
                  </ul>
                </div>-->
                <div class="col-12">
                  <ul class="list-group">
                    <li class="list-group-item" *ngFor="let i of searchResults">
                      <div (click)="goTO(i.link)" style="cursor: pointer">
                        <img
                          src="{{ i.icon }}"
                          class="icon"
                          style="height: 25px"
                        />
                        {{ i.name }}
                        <p>
                          {{ i.desc }}
                        </p>
                      </div>
                    </li>
                    <!--active-->
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
              <ng-container *ngIf="!DevPortal">
                <li
                  class="nav-item dropdown searchbox"
                  id="accountGroups"
                  *ngIf="
                    accountGroups &&
                    accountGroups.list &&
                    accountGroups.list.length > 0
                  "
                  style="border-left: 1px solid #fff"
                >
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ accountGroups.selected }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <div class="flex-grow-1">
                        <div class="input-group">
                          <i
                            _ngcontent-bob-c49=""
                            aria-hidden="true"
                            class="icon bi-search"
                            style="position: absolute; right: 10px"
                          ></i>
                          <input
                            type="text"
                            style="
                              border-radius: 0;
                              margin-top: -10px;
                              background: transparent;
                            "
                            class="form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="btnGroupAddon"
                            [(ngModel)]="accountGroupSearchTerm"
                          />
                        </div>
                      </div>
                      <content *ngFor="let i of accountGroups.list">
                        <div
                          *ngIf="
                            searchOnList(i.groupName, accountGroupSearchTerm)
                          "
                          (click)="selectAccountGroup(i)"
                        >
                          <span class="dropdown-item">{{ i.groupName }}</span>
                        </div>
                      </content>
                    </li>
                  </ul>
                </li>

                <li
                  class="nav-item dropdown searchbox"
                  id="clientNames"
                  *ngIf="
                    clientNames &&
                    clientNames.list &&
                    clientNames.list.length > 0
                  "
                >
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ clientNames.selected }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <div class="flex-grow-1">
                        <div class="input-group">
                          <i
                            _ngcontent-bob-c49=""
                            aria-hidden="true"
                            class="icon bi-search"
                            style="position: absolute; right: 10px"
                          ></i>
                          <input
                            type="text"
                            style="
                              border-radius: 0;
                              margin-top: -10px;
                              background: transparent;
                            "
                            class="form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="btnGroupAddon"
                            [(ngModel)]="clientNameSearchTerm"
                          />
                        </div>
                      </div>
                      <content *ngFor="let i of clientNames.list">
                        <div
                          *ngIf="searchOnList(i.cmpName, clientNameSearchTerm)"
                          (click)="selectclientName(i)"
                        >
                          <span class="dropdown-item">{{ i.cmpName }}</span>
                        </div>
                      </content>
                    </li>
                  </ul>
                </li>

                <li
                  class="nav-item dropdown searchbox"
                  id="accountIds"
                  *ngIf="accountIds"
                >
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ accountIds.selected }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <div class="flex-grow-1">
                        <div class="input-group">
                          <i
                            _ngcontent-bob-c49=""
                            aria-hidden="true"
                            class="icon bi-search"
                            style="position: absolute; right: 10px"
                          ></i>
                          <input
                            type="text"
                            style="
                              border-radius: 0;
                              margin-top: -10px;
                              background: transparent;
                            "
                            class="form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="btnGroupAddon"
                            [(ngModel)]="accountIdSearchTerm"
                          />
                        </div>
                      </div>
                      <content *ngFor="let i of accountIds.list">
                        <div
                          *ngIf="
                            searchOnList(i.accountName, accountIdSearchTerm)
                          "
                          (click)="selectAccountId(i)"
                        >
                          <span class="dropdown-item"
                            >{{ i.accountName }} ({{ i.accountId }})</span
                          >
                        </div>
                      </content>
                    </li>
                  </ul>
                </li>
              </ng-container>

              <li
                class="nav-item dropdown searchbox"
                id="regions"
                style="width: 250px"
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ selectedRegion }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li *ngFor="let i of regions">
                    <span class="dropdown-item" (click)="setRegion(i)">{{
                      i.name
                    }}</span>
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown">
                <a
                  class="d-block text-decoration-none dropdown-toggle"
                  id="dropdownUser1"
                  style="color: #fff; margin-top: 10px"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <!--<img src="../../assets/img/passport.png" class="rounded-circle" width="32" height="32" />-->
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <span
                    style="color: white; font-family: inherit; margin: 0px 10px"
                    >{{ username }}</span
                  >
                </a>
                <ul
                  class="dropdown-menu text-small"
                  aria-labelledby="dropdownUser1"
                  style="
                    position: absolute;
                    inset: 0px auto auto 0px;
                    transform: translate(-25px, 34px);
                  "
                  data-popper-placement="bottom-start"
                >
                  <!--<li><a class="dropdown-item" href="#">Settings</a></li>
                  <li><a class="dropdown-item" href="#">Profile</a></li>-->
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <button class="dropdown-item" (click)="logout()">
                      Sign out
                    </button>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="dropdown text-end"></div>

            <div style="width: 75px"></div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>

<div class="" style="position: fixed; top: 0; width: 100%; z-index: 99">
  <div class="row">
    <div class="col" style="padding-right: 0">
      <nav class="navbar navbar-expand-lg top-menu">
        <div class="container-fluid">
          <div class="nav-item" style="width: 175px">
            <a
              routerLink="/dash/"
              class="nav-link text-truncate"
              style="padding: 0"
            >
              <img
                style="width: 115px"
                src="../assets/img/swayam_logo_v3.0.svg"
              />
            </a>
          </div>

          <form
            class="flex-grow-1"
            name="topsearchbox"
            style="position: relative"
            id="topsearchbox"
          >
            <div class="input-group">
              <input
                name="searchValue"
                type="text"
                class="form-control"
                [(ngModel)]="searchValue"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="btnGroupAddon"
                (input)="onSearchChange()"
                (focus)="showSearch()"
                maxlength="20"
              />
              <!--(focusout)="hideSearch()"-->
              <div class="input-group-text" id="btnGroupAddon">
                <i class="icon bi-search" aria-hidden="true"></i>
              </div>
            </div>
          </form>

          <div
            id="searchbackground"
            (click)="hideSearch()"
            *ngIf="showSearchResult && searchValue && searchValue.length > 1"
          ></div>
          <div
            id="searchResult"
            *ngIf="showSearchResult && searchValue && searchValue.length > 1"
          >
            <div class="container">
              <div class="row">
                <div class="col">
                  <h3>Search Results for '{{ searchValue }}'</h3>
                </div>
              </div>
              <div class="row">
                <!--<div class="col-4" style="padding:0">
                  <ul class="list-group">
                    <li class="list-group-item" *ngFor="let i of searchData.category">{{i.name}}</li>
                 
                  </ul>
                </div>-->
                <div class="col-12">
                  <ul class="list-group">
                    <li class="list-group-item" *ngFor="let i of searchResults">
                      <div (click)="goTO(i.link)" style="cursor: pointer">
                        <img
                          src="{{ i.icon }}"
                          class="icon"
                          style="height: 25px"
                        />
                        {{ i.name }}
                        <p>
                          {{ i.desc }}
                        </p>
                      </div>
                    </li>
                    <!--active-->
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
              <li
                class="nav-item dropdown searchbox"
                id="accountGroups"
                *ngIf="
                  accountGroups &&
                  accountGroups.list &&
                  accountGroups.list.length > 0
                "
                style="border-left: 1px solid #fff"
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ accountGroups.selected }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div class="flex-grow-1">
                      <div class="input-group">
                        <i
                          _ngcontent-bob-c49=""
                          aria-hidden="true"
                          class="icon bi-search"
                          style="position: absolute; right: 10px"
                        ></i>
                        <input
                          type="text"
                          style="
                            border-radius: 0;
                            margin-top: -10px;
                            background: transparent;
                          "
                          class="form-control"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="btnGroupAddon"
                          [(ngModel)]="accountGroupSearchTerm"
                        />
                      </div>
                    </div>
                    <content *ngFor="let i of accountGroups.list">
                      <div
                        *ngIf="
                          searchOnList(i.groupName, accountGroupSearchTerm)
                        "
                        (click)="selectAccountGroup(i)"
                      >
                        <span class="dropdown-item">{{ i.groupName }}</span>
                      </div>
                    </content>
                  </li>
                </ul>
              </li>

              <li
                class="nav-item dropdown searchbox"
                id="clientNames"
                *ngIf="
                  clientNames && clientNames.list && clientNames.list.length > 0
                "
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ clientNames.selected }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div class="flex-grow-1">
                      <div class="input-group">
                        <i
                          _ngcontent-bob-c49=""
                          aria-hidden="true"
                          class="icon bi-search"
                          style="position: absolute; right: 10px"
                        ></i>
                        <input
                          type="text"
                          style="
                            border-radius: 0;
                            margin-top: -10px;
                            background: transparent;
                          "
                          class="form-control"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="btnGroupAddon"
                          [(ngModel)]="clientNameSearchTerm"
                        />
                      </div>
                    </div>
                    <content *ngFor="let i of clientNames.list">
                      <div
                        *ngIf="searchOnList(i.cmpName, clientNameSearchTerm)"
                        (click)="selectclientName(i)"
                      >
                        <span class="dropdown-item">{{ i.cmpName }}</span>
                      </div>
                    </content>
                  </li>
                </ul>
              </li>

              <li
                class="nav-item dropdown searchbox"
                id="accountIds"
                *ngIf="accountIds"
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ accountIds.selected }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div class="flex-grow-1">
                      <div class="input-group">
                        <i
                          _ngcontent-bob-c49=""
                          aria-hidden="true"
                          class="icon bi-search"
                          style="position: absolute; right: 10px"
                        ></i>
                        <input
                          type="text"
                          style="
                            border-radius: 0;
                            margin-top: -10px;
                            background: transparent;
                          "
                          class="form-control"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="btnGroupAddon"
                          [(ngModel)]="accountIdSearchTerm"
                        />
                      </div>
                    </div>
                    <content *ngFor="let i of accountIds.list">
                      <div
                        *ngIf="searchOnList(i.accountName, accountIdSearchTerm)"
                        (click)="selectAccountId(i)"
                      >
                        <span class="dropdown-item"
                          >{{ i.accountName }} ({{ i.accountId }})</span
                        >
                      </div>
                    </content>
                  </li>
                </ul>
              </li>

              <li
                class="nav-item dropdown searchbox"
                id="regions"
                style="width: 250px"
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ selectedRegion }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li *ngFor="let i of regions">
                    <span class="dropdown-item" (click)="setRegion(i)">{{
                      i.name
                    }}</span>
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown">
                <a
                  class="d-block text-decoration-none dropdown-toggle"
                  id="dropdownUser1"
                  style="color: #fff; margin-top: 10px"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <!--<img src="../../assets/img/passport.png" class="rounded-circle" width="32" height="32" />-->
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <span
                    style="color: white; font-family: inherit; margin: 0px 10px"
                    >{{ username }}</span
                  >
                </a>
                <ul
                  class="dropdown-menu text-small"
                  aria-labelledby="dropdownUser1"
                  style="
                    position: absolute;
                    inset: 0px auto auto 0px;
                    transform: translate(-25px, 34px);
                  "
                  data-popper-placement="bottom-start"
                >
                  <!--<li><a class="dropdown-item" href="#">Settings</a></li>
                  <li><a class="dropdown-item" href="#">Profile</a></li>-->
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <button class="dropdown-item" (click)="logout()">
                      Sign out
                    </button>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="dropdown text-end"></div>

            <div style="width: 75px"></div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>

<div class="content min-vh-100 rightcontent">
  <router-outlet></router-outlet>
</div>

<!--<router-outlet></router-outlet>-->
