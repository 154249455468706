<div class="card">
  <div class="card-body">
    <div style="display: flex; justify-content: right;" *ngIf="rdsData.length > 0">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="export()">Export</button>
    </div>
    <table class="table table-docs table-hover" id="rds-table">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <ng-container *ngFor="let h of rdsHeaders">
            <th *ngIf="!h.conditionalShow || showConditionalElement">{{ h.name }}</th>
          </ng-container>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': rdsData.length > 0 ? 'table-row-group' : 'none'}">
        <tr *ngFor="let i of rdsData; let j = index">
          <td>{{j + 1}}</td>
          <ng-container *ngFor="let h of rdsHeaders">
            <td *ngIf="!h.conditionalShow || showConditionalElement">
              <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"> </span>
            </td>
          </ng-container>
        </tr>
      </tbody>
      <tbody *ngIf="rdsData.length == 0">
        <tr>
          <td [attr.colspan]="rdsHeaders.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="display: flex; justify-content: right;" *ngIf="loadMore">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;"
        (click)="loadRDSData()">Next</button>
    </div>
  </div>
</div>