<div class="card">
  <h5 class="card-header">Security Hub</h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->
    <div style="display: flex; justify-content: flex-end;">
      <input class="datetimepicker" type="text" [(ngModel)]="dateRange" />
    </div>

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">
            {{h.name}}
            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,securityHubList)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': securityHubList.length > 0 ? 'table-row-group' : 'none'}"
        *ngFor="let i of securityHubList; let j = index">
        <tr *ngIf="notifier.filterTableColums(headers,i)">
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                <span *ngIf="h.customDisp" [innerHTML]="h.dispFun(i[h.id])"></span>
                <span *ngIf="!h.customDisp"> {{i[h.id]}}</span>

              </span>
            </span>
            <span *ngIf="!h.click">
              <span *ngIf="h.customDisp" [innerHTML]="h.dispFun(i[h.id])"
                [ngStyle]="{'color': h.color && h.color(h.dispFun(i[h.id]))}"></span>
              <span *ngIf="!h.customDisp"> {{i[h.id]}}</span>
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': securityHubList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="nextToken"
      style="display: flex; justify-content: flex-end; align-items: center; gap: 10px; margin-top: 10px;">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="load()">Next</button>
    </div>
  </div>
</div>