<div class="card">
  <h5 class="card-header">Disk Details</h5>
  <div class="card-body">
    <div class="row" style="display: flex; justify-content: flex-end">
      <div style="width: 33%;">
        <select class="form-control" id="instanceID" style="width: 33%" data-live-search="true"
          (change)="getDiskUtilization($event)">
          <option *ngFor="let i of instances" value="{{ i }}">{{ i }}</option>
        </select>
      </div>
      <div class="card" style="border-top: none !important">
        <div class="card-body">
          <div style="display: flex; align-items: center; justify-content: flex-end;"
            *ngIf="diskUtilization.length > 0">
            <button class="btn btn-light" style="background-color: #5864FF; color: white;"
              (click)="export()">Export</button>
          </div>
          <table class="table table-docs table-hover" id="diskTable">
            <thead>
              <tr>
                <th>S.N.</th>
                <th *ngFor="let h of diskUtilizationHeader">{{ h.name }}</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let i of diskUtilization; let j = index">
                <td>{{ j + 1 }}</td>
                <td *ngFor="let h of diskUtilizationHeader">
                  <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                    <span>
                      {{ i[h.id] }}
                    </span>
                  </span>
                  <span *ngIf="!h.click">
                    {{ i[h.id] }}
                  </span>
                </td>
              </tr>
              <ng-container *ngIf="diskUtilization.length == 0">
                <tr>
                  <td style="text-align: center;" [attr.colspan]=" diskUtilizationHeader.length + 1">
                    No Disks Found
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>