import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import * as moment from 'moment';

declare let Jhxlsx: any;

@Component({
  templateUrl: './pnlsummary.component.html',
  styleUrls: ['./pnlsummary.component.css'],
})
export class PnlSummaryComponent implements OnInit, OnDestroy {
  currentMessage: any;
  accountId: string;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  dates: string[] = [];
  result_data: any;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  colHeader = [
    {
      name: 'Count of Master Account',
      id: 'masterCount',
      link:true
    },
    {
      name: 'Count of Child Account',
      id: 'childCount',
    },
    {
      name: 'Total Usage',
      id: 'totalUsage',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      name: 'Total Purchase',
      id: 'totalPurchase',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      name: 'Total Sale',
      id: 'totalSale',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      name: 'S-PnL',
      id: 'pnl',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      name: 'S-PnL(%)',
      id: 'pnlPercentage',
      alter: (item: any, param: string) => {
        return `${item[param]}%`;
      },
    },
    {
      name: 'Unbilled Cost',
      id: 'unBilledCost',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      name: 'PnL',
      id: 'plForUnbill',
      alter: (item: any, param: string) => {
        return `$${item[param]}`;
      },
    },
    {
      name: 'PnL(%)',
      id: 'plPercentageForUnbill',
      alter: (item: any, param: string) => {
        return `${item[param]}%`;
      },
    },
  ];

  ngOnInit(): void {
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      this.fetchData();
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      }
    });
    this.dates.push(
      moment().subtract(2, 'month').startOf('month').format('MM-YYYY')
    );

    for (let i = 1; i >= 0; i--) {
      this.dates.push(
        moment().subtract(i, 'month').startOf('month').format('MM-YYYY')
      );
    }
  }

  async fetchData() {
    this.notifier.loading(true);
    let data = {
      a: 'pnlSummary',
      isDevUser: '0',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiUrl = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchchildaccwithcosts/fetchchildaccwithcostsv2`;
    let result = await this.apiServer.postDataPromis(apiUrl, data, header);
    if (result.status == '1' || result.s == '1') {
      if (result.pnlSummary) {
        this.result_data = result.pnlSummary;

        this.notifier.loading(false);
      } else {
        this.notifier.alert('Info', '', 'data is missing.', 'info', 5000);
        this.notifier.loading(false);
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      //   this.process_data = {};
      this.notifier.loading(false);
    }
  }
  exportToCSV(maps: any) {
    this.notifier.loading(true);
    const updateDate: string[] = [];
    this.dates.forEach((date) => {
      updateDate.push(date);
    });
    let data: any = [
      {
        sheetName: 'PnL Summary Report',
        data: [
          [
            { text: 'Details' },

            ...updateDate.map((date) => {
              return { text: date };
            }),
          ],
        ],
      },
    ];
    let sheetData: any = maps;
    let headers: any = this.colHeader;

    headers.forEach((h: any) => {
      let data_: any = [];
      data_.push({ text: h.name });
      this.dates.forEach((d: any) => {
        data_.push({ text: sheetData[d][h.id] });
      });
      data[0]['data'].push(data_);
    });

    var options = {
      fileName: 'PnL Summary Report',
    };

    Jhxlsx.export(data, options);
    this.notifier.loading(false);
  }
  ngOnDestroy() {
    this.currentMessage.unsubscribe();
  }
}
