import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-otp-validation-create-cr',
  templateUrl: './otp-validation-create-cr.component.html',
  styleUrls: ['./otp-validation-create-cr.component.css'],
})
export class OtpValidationCreateCrComponent implements OnInit, OnDestroy {
  @Output('setMainHeight') setMainHeight: any = new EventEmitter<number>();
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';

  @Input('cr_data') cr_data: any;

  constructor() {}

  ngOnInit(): void {
    this.cr_data['otp'] = '';
    setTimeout(() => {
      this.setMainContainerHeight();
    }, 1);
  }

  setMainContainerHeight(height: number = undefined) {
    this.setMainHeight.emit(height);
  }
  ngOnDestroy(): void {
    delete this.cr_data['otp'];
  }
}
