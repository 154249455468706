<div class="card">
  <div class="card-header">
    <h5>
      <button class="btn btn-light" (click)="hideModel()">
        < Back</button>
          Create Pipeline
    </h5>
  </div>
  <div class="card-body create_container">
    <div class="grid-3">
      <div>
        <label class="label-text" for="">Pipeline Name</label>
        <span>
          <input type="text" [(ngModel)]="pipelinePayload['module_name']" id="">
        </span>
      </div>
    </div>
    <div class="grid-3">
      <div>
        <label class="label-text" for="">Desecription</label>
        <span>
          <textarea cols="30" rows="5" [(ngModel)]="pipelinePayload['description']"></textarea>
        </span>
      </div>
    </div>
    <div class="grid-3">
      <div>
        <label class="label-text" for="">Runtime</label>
        <span>
          <select [(ngModel)]="pipelinePayload['runtime']">
            <option value="Python">Python</option>
            <option value="Java">Java</option>
          </select>
        </span>
      </div>
    </div>
    <div *ngFor="let env of getObjectKeys(pipelinePayload['build_environment_variables'])" class="env-container">
      <div class="header-text">
        <label class="label-text" style="text-transform: capitalize;" for="">{{ env }}</label>
      </div>
      <div class="grid-3">
        <div *ngFor="let obj of pipelinePayload['build_environment_variables'][env]">
          <label class="label-text" for="">{{ obj['name'] }}</label>
          <span>
            <input type="text" [ngModel]="obj['value']" [attr.disabled]="true">
          </span>
        </div>
      </div>
    </div>
    <div class="env_container" style="text-align: right; margin-bottom: 30px;">
      <button class="btn btn-primary-soft" (click)="fetchPipeline()">
        Create
      </button>
    </div>
  </div>
</div>