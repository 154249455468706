import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

declare var hljs: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './exceptions.anomalies.component.html',
})
export class exceptionsAnomaliesComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  exceptions: any = [];
  updateRule: any;

  funRef: any;

  accountId: any;
  regionId: any;
  currentMessage: any;

  headers: any = [
    {
      id: 'exception_name',
      name: 'Rule Name',
    },
    { id: 'monitor_id', name: 'Monitor ID' },
    { id: 'monitor_name', name: 'Monitor Name' },
    { id: 'monitor_type', name: 'Monitor Type' },
    { id: 'attribute_name', name: 'Attribute Name' },
    { id: 'time_range', name: 'Time Range' },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.funRef = {};

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    await this.loadExceptions();
    this.notifier.loading(false);
  }

  async loadExceptions() {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { site_247_project_id } = selectedAccountData.data;
        let data = {
          action: 'get_exception_rules',
          zaaid: site_247_project_id,
          rule_type: 'exception',
        };
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        //https://api.swayam.cloud/v3/admin/support
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/anomalies`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        console.log(result);

        if (result.status == '1' || result.s == '1') {
          let dt = result.rules.rules;
          if (dt.length == 0) return;
          for (let i = 0; i <= dt; i++) {
            dt[i]['time_range'];
          }
          dt = dt.map((rule: any) => {
            let string = '';
            let time_ranges = rule['time_range'].split(',');
            for (let i = 0; i <= time_ranges.length; i++) {
              if (!time_ranges[i]) {
                continue;
              }
              if (!(time_ranges[i].indexOf('false') > -1)) {
                string += time_ranges[i].split(':')[0] + ', ';
              }
            }
            string = string.slice(0, string.length - 2);
            rule['time_range'] = string;
            return rule;
          });
          this.exceptions = dt;
        } else {
          this.notifier.alert('Info', '', result.error, 'info', 5000);
        }
      } else {
        this.notifier.alert(
          'Error',
          '',
          selectedAccountData.msg,
          'error',
          5000
        );
      }
    } catch (err: any) {
      console.log(err);
      this.notifier.alert('Info', '', err.msg, 'info', 5000);
    }
  }

  triggerUpdateRule(param: any) {
    if (!this.writeAccess) {
      return;
    }
    this.updateRule = param;
  }
  hideUpdateException(event: any) {
    this.updateRule = null;
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  async showData(ref: any, id: any) {
    ref.activeMonitor = id;
    ref.notifier.loading(true);
    await ref.loadAnomalies(ref);
    ref.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
