<style>
  a.nav-link {
    color: blue;
    cursor: pointer;
  }
  a.nav-link:hover {
    text-decoration: underline;
  }
</style>

<div class="card">
  <h5
    class="card-header"
    style="
      display: flex;
      justify-content: flex-center;
      align-items: center;
      gap: 8px;
    "
  >
    <!-- <button class="btn btn-light" *ngIf="selectedMID" (click)="back()">
      Back
    </button> -->
    PnL Dashboard
  </h5>
  <div class="card-body">
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 8px;
      "
    >
      <div style="margin: 8px">
        <span>Conversion Rate:</span>
        <span>
          {{ res }}
        </span>
      </div>
      <div style="display: flex">
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            gap: 8px;
          "
        >
          <div>
            <span>Month Year:</span>
            <span>
              <input
                type="text"
                id="monthDate"
                class="form-control"
                #datepicker
              />
            </span>
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            gap: 8px;
          "
        >
          <button
            class="btn btn-light"
            (click)="loadDash()"
            style="background-color: #5864ff; color: white; margin: 4px"
          >
            Refresh
          </button>
        </div>
      </div>
    </div>
    <div style="max-width: 100%; overflow: auto">
      <!-- <p>Dashboard</p> -->
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <!-- <th>S.N.</th> -->
            <th>Count of Mstrs</th>
            <th>Count of Child Account</th>
            <th>Total Usage</th>
            <th>Total Purchase</th>
            <th>Total Sale</th>
            <th>S-PnL</th>
            <th>S-PnL(%)</th>
            <th>Unbilled Cost</th>
            <th>PnL</th>
            <th>PnL (%)</th>
          </tr>
        </thead>

        <tbody *ngIf="dashData.length != 0">
          <tr>
            <!-- <td>{{ i + 1 }}</td> -->
            <td>
              <a
                class="nav-link"
                routerLink="/cbs/pnl-report"
                [queryParams]="{ 'month-year': datepicker.value }"
                >{{ dashData.masterCount }}</a
              >
            </td>
            <td>{{ dashData.childCount }}</td>
            <td>{{ dashData.totalUsage }}</td>
            <td>{{ dashData.totalPurchase }}</td>
            <td>{{ dashData.totalSale }}</td>
            <td>{{ dashData.pnl }}</td>
            <td>{{ dashData.pnlPercentage }}</td>
            <td>{{ dashData.unBilledCost }}</td>
            <td>{{ dashData.plForUnbill }}</td>
            <td>{{ dashData.plPercentageForUnbill }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="dashData.length == 0">
          <tr>
            <td colspan="100%">
              <div style="text-align: center">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
