import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'header-print',
  templateUrl: './header.html',
  //styleUrls: ['./hero-detail.component.css']
})
export class headerPrintComponent implements OnInit {
  @Input() inviNo?:any;

  constructor() { }

  ngOnInit() {
    console.log("inviNo", this.inviNo);
  }

}
