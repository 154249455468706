<div class="card">
  <h5 class="card-header">View BOM Details</h5>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; gap: 8px; align-items: center;">
      <button style="background-color: #5864ff; color: white;" *ngIf="bomList.length > 0" class="btn btn-light'"
        (click)="addBOMEntry()">Add BOM
        Details</button>
      <button style="background-color: #5864ff; color: white;" *ngIf="bomList.length > 0" class="btn btn-light'"
        (click)="exportBOM()">Export</button>
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">{{h.name}}</th>
          <th>Update</th>
          <th>Delete</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': bomList.length > 0 ? 'table-row-group': 'none'}">
        <tr *ngFor="let i of bomList; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
          <td><span class="btn" (click)="updateBOMEntry(i)"><i class="fa fa-pencil"></i></span></td>
          <td><span class="btn" (click)="triggerDeleteEntry(i)"><i class="fa fa-trash"></i></span></td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': bomList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 3">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<update-bom *ngIf="selectedBOM" [action]="bomAction" [bom]="selectedBOM" (hideModel)="hideModel($event)"></update-bom>
<delete-bom *ngIf="deleteBOM" [bom]="deleteBOM" (hideModel)="hideDeleteModel($event)"></delete-bom>