<div class="card mt-20" style="border: none; position: relative; height: 100%;">
  <h5 class="card-header">EC2 SP cross account</h5>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; align-items: center;    gap: 10px;">
      <button class="btn btn-primary-soft" (click)="load()">Refresh</button>
      <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="addPurchase()">Add
        Purchase Details</button>
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}
            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,planData)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>

          </th>
          <th>Update</th>
          <th>Delete</th>
        </tr>
      </thead>

      <tbody *ngFor="let i of planData; let j = index"
        [ngStyle]="{'display': planData.length > 0 ? 'table-row-group' : 'none'}">
        <tr *ngIf="notifier.filterTableColums(headers, i)">
          <td>{{j + 1}}</td>
          <td *ngFor="let h of headers">
            <ng-container *ngIf="h.id === 'lids'; else defaultCell">
              <span class="btn" style="text-decoration: underline; cursor: pointer;"
                (click)="openModal(i[h.id])">View</span>

            </ng-container>
            <ng-template #defaultCell>
              <span *ngIf="h.id !== 'ramp' && !h.click">{{h.alter ? h.alter(i, h.id) : i[h.id]}}</span>
              <span *ngIf="h.id !== 'ramp' && h.click" class="btn" (click)="callFunction(h.click, i)">
                {{h.alter ? h.alter(i, h.id) : i[h.id]}}
              </span>
              <span *ngIf="h.id === 'ramp'">
                {{ i[h.id] ? (h.alter ? h.alter(i, h.id) : i[h.id]) : '-' }}
              </span>
            </ng-template>
          </td>
          <td>
            <span (click)="updateTrigger(i)" class="btn btn-light">
              <i class="fa fa-pencil"></i>
            </span>
          </td>
          <td>
            <span (click)="delete_selected(i)" class="btn btn-light">
              <i class="fa fa-trash"></i>
            </span>
          </td>
        </tr>
      </tbody>

      <tbody [ngStyle]="{'display': planData.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 3">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="delete"
  style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); backdrop-filter: blur(5px); display: flex; align-items: center; justify-content: center;">
  <div class="card"
    style="height: 200px; width: 60%; padding: 18px; background: white; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);justify-content: space-between;">
    <h4>Are you sure you want to delete?</h4>
    <div style="text-align: end;">
      <button class="btn btn-danger" style="margin: 0px 4px;" (click)="deleteTrigger(indexdelete)">Delete</button>
      <button class="btn" style="margin: 0px 4px; background-color: rgb(228, 230, 232);"
        (click)="delete = false">Cancel</button>
    </div>
  </div>
</div>

<!-- Modal HTML -->
<div *ngIf="showModal" class="modal-overlay">
  <div class="card modal-card">
    <h4>Child Account Details</h4>
    <div class="modal-body">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of selectedLids">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>          
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    </div>
  </div>
</div>



<ec2-cross-update *ngIf="updateRI" [action]="updateAction" [ri]="updateRI" (hideModel)="closeUpdateTrigger($event)">
</ec2-cross-update>

<style>
  .view-link {
    color: #007bff;
    /* Blue color */
    text-decoration: underline;
    /* Underline the text */
    cursor: pointer;
    /* Change cursor to pointer on hover */
  }

  .view-link:hover {
    text-decoration: none;
    /* Remove underline on hover if you prefer */
  }

  /* Overlay for the modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  /* Modal card styling */
  .modal-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 60%;
    /* Set your desired width */
    max-width: 800px;
    max-height: 80%;
    overflow-y: auto;
    /* Allows scrolling if content overflows */
  }

  /* Optional: Style for the modal header and footer */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  /* Close button styling */
  .btn-secondary {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 5px 15px;
    cursor: pointer;
  }

  .btn-secondary:hover {
    background-color: #5a6268;
  }
</style>