import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { APIService } from './../api/api.service';
import { NotifierService } from './../_services/notifier.service';

declare let window: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  otpRequired: boolean = false;
  public loginForm = new UntypedFormGroup({
    userName: new UntypedFormControl('addet0260@gmail.com', []),
    password: new UntypedFormControl('', []),
  });

  public otpForm = new UntypedFormGroup({
    otpText: new UntypedFormControl('1234', []),
  });

  get f() {
    return this.loginForm.controls;
  }

  get o() {
    return this.otpForm.controls;
  }

  register: boolean = false;

  loading = false;

  public username = '';
  public password = '';
  public otpText = '';

  forgotPassword: boolean = false;

  public uid = localStorage.getItem('t');
  public t = localStorage.getItem('t');
  public returnUrl = '';
  public role = 'Admin';

  public hideRoleSelection = false;

  isInvalid = false;
  error = '';

  showpopOTP = false;
  otp = '';
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService,
    private http: HttpClient
  ) {}

  redirectURL: any;
  ngOnInit(): void {
    //localStorage.clear();
    this.route.queryParams.subscribe((res) => {
      this.redirectURL = JSON.parse(JSON.stringify(res));
      if (this.redirectURL['redirectURL'].indexOf('?') > -1) {
        this.redirectURL['redirectURL']
          .split('?')[1]
          .split('&')
          .map((query: any) => {
            this.redirectURL[query.split('=')[0]] = query.split('=')[1];
          });
      }
      this.redirectURL['redirectURL'] =
        this.redirectURL['redirectURL'].split('?')[0];
    });
    this.ifTokenIsValid();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
    // this.cleanup();
  }

  cleanup() {
    localStorage.removeItem('accountListNew');
    localStorage.removeItem('selectedClientNameData');
    localStorage.removeItem('selectedClientName');
    localStorage.removeItem('accountGroups');
    localStorage.removeItem('selectedaccountIds');
    localStorage.removeItem('accountIdData');
    localStorage.removeItem('accountId');
  }

  async ifTokenIsValid() {
    this.loading = true;
    let data = {
      //fromIndex: 1,
      //toIndex: '10',
      clientemailid: localStorage.getItem('eId'),
      accountid: localStorage.getItem('accountId'),
      action: 'check',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/tokenvalidation`;

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);
    console.log('result', result); // && result.errorStatus != 200

    if (result.s == '1') {
      const permission = localStorage.getItem('fullpnlpermision');
      const menuPemission = localStorage.getItem('submenupnl');
      //this.router.navigate(['/dash']);
      if (this.redirectURL) {
        let temp = this.redirectURL['redirectURL'];
        //menu permission access

        if (!temp) {
          console.log('token validation');
          if (permission == '0' && menuPemission.length != 0) {
            this.router.navigate(['/cbs/' + menuPemission.split(',')[0]]);
            return false;
          } else {
            this.router.navigate(['/cbs/mastersummary']);
            return false;
          }
        }
        delete this.redirectURL['redirectURL'];
        console.log([temp], { queryParams: this.redirectURL });
        this.router.navigate([temp], { queryParams: this.redirectURL });
      } else {
        console.log('token validation else');
        if (permission == '0' && menuPemission.length != 0) {
          this.router.navigate(['/cbs/' + menuPemission.split(',')[0]]);
          return false;
        } else {
          this.router.navigate(['/cbs/mastersummary']);
        }
      }
    } else {
      //localStorage.clear();
    }

    this.loading = false;
    return true;
  }

  async login() {
    //this.notifier.alert("Info", "", "selector as an optional parameter", "info");
    //this.notifier.alert("Success", "", "selector as an optional parameter", "success");
    //this.notifier.alert("Warning", "", "selector as an optional parameter", "warning");
    //this.notifier.alert("Error", "", "selector as an optional parameter", "error");

    this.loginForm.markAsDirty();

    Object.keys(this.loginForm.controls).forEach((key) => {
      this.loginForm.controls[key].markAsDirty();
    });

    /*    this.loading = true;*/

    this.notifier.loading(true);

    if (this.username == '' || this.password == '') {
      this.error = 'Please Enter User Name and Password!';
      this.notifier.alert(
        'Error',
        '',
        'Please Enter User Name and Password',
        'error',
        5000
      );
      this.isInvalid = true;
      return false;
    }

    this.loading = true;

    let data = {
      eId: this.username.trim(),
      pswd: this.password.trim(),
      ut: 'client',
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/client/users/loginv2`;

    if (this.role == 'Admin') {
      //apiURL = `${APIService.API_ENDPOINT}/loginv2`;
      apiURL = `${APIService.API_ENDPOINTV3}/admin/users/loginv2`;

      data = {
        eId: this.username,
        pswd: this.password,
        ut: 'msops',
      };
    }

    let result = await this.apiServer.postDataPromis(apiURL, data);

    if (result.status == '1' || result.s == '1') {
      //this.showpopOTP = true;

      localStorage.removeItem('accountGroupdetails');

      localStorage.setItem(
        'menuList',
        JSON.stringify(result.mList ? result.mList : {})
      );

      // localStorage.setItem('t', result.at);

      localStorage.setItem('t', result.at);
      localStorage.setItem('clientId', result.cid);
      // localStorage.setItem('t', result.at);

      localStorage.setItem('ut', result.ut);
      localStorage.setItem('un', result.un);
      localStorage.setItem('ph', result.ph);

      localStorage.setItem('com', result.com);
      localStorage.setItem('des', result.des);
      localStorage.setItem('eId', result.eId);
      localStorage.setItem('acT', result.acT);
      localStorage.setItem('role', this.role);

      localStorage.setItem('accountDetailsList', result.accountDetailsList);
      try {
        window.analyticsMapUser(result.eId);
      } catch (e) {
        console.log(e);
      }
      if (!result.mfaRequired) {
        window.analyticsMapUser(result.eId);
        localStorage.setItem('t', result.at);
        this.fetchPnlPermission(result.eId, result.at);
        let permission = localStorage.getItem('fullpnlpermision');
        let menuPemission = localStorage.getItem('submenupnl');
        console.log('login');
        if (permission == '0' && menuPemission.length != 0) {
          this.router.navigate(['/cbs/' + menuPemission.split(',')[0]]);
          return false;
        } else {
          this.router.navigate(['/cbs/mastersummary']);
        }
      } else {
        if (result.msg == 'Success') {
          window.analyticsMapUser(result.eId);

          // await this.sendOPT();
          this.otpRequired = true;
        }
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
    return false;
  }

  // async sendOPT() {
  //   this.notifier.loading(true);
  //   let data = {
  //     ac: 'sendOtp',
  //     un: localStorage.getItem('un'),
  //     eId: localStorage.getItem('eId'),
  //     ut: localStorage.getItem('ut'),
  //   };

  //   let apiURL = `${APIService.API_ENDPOINTV3}/admin/users/loginotp`;

  //   // if (this.role == 'Admin') {
  //   //   //apiURL = `${APIService.API_ENDPOINT}/loginv2`;
  //   //   apiURL = `${APIService.API_ENDPOINTV3}/admin/users/loginv2`;

  //   //   data['ut'] = 'msops';
  //   // }

  //   let result = await this.apiServer.postDataPromis(apiURL, data);

  //   if (result.status == '1' || result.s == '1') {
  //   } else {
  //     this.notifier.alert('Info', '', result.msg, 'info', 5000);
  //   }
  //   this.notifier.loading(false);
  // }

  async validateOTP(event?: any) {
    if (!event && this.otpText.trim().length < 6) return false;
    this.notifier.loading(true);
    let data = {
      ac: 'validateOtp',
      un: localStorage.getItem('un'),
      eId: localStorage.getItem('eId'),
      ut: this.role == 'Admin' ? 'msops' : 'client',
      otp: this.otpText.trim(),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/admin/users/loginv2`;

    // if (this.role == 'Admin') {

    //   //apiURL = `${APIService.API_ENDPOINT}/loginv2`;
    //   apiURL = `${APIService.API_ENDPOINTV3}/admin/users/loginv2`;

    //   data['ut'] = 'msops';
    // }

    let result = await this.apiServer.postDataPromis(apiURL, data);

    if (result.status == '1' || result.s == '1') {
      await this.fetchPnlPermission(localStorage.getItem('eId'), result.at);
      if (result.msg == 'Please try again with Valid OTP') {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      } else {
        localStorage.setItem('t', result.at);
        localStorage.setItem('role', this.role);
        localStorage.setItem('idT', result.id);
        let permission = localStorage.getItem('fullpnlpermision');
        let menuPemission = localStorage.getItem('submenupnl');
        console.log('validate OTP');
        if (permission == '0' && menuPemission.length != 0) {
          this.router.navigate(['/cbs/' + menuPemission.split(',')[0]]);
          return false;
        } else {
          this.router.navigate(['/cbs/mastersummary']);
        }
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
    return true;
  }

  async loginOPT() {
    Object.keys(this.loginForm.controls).forEach((key) => {
      this.loginForm.controls[key].markAsDirty();
    });

    this.loading = true;
    if (this.otp == '') {
      this.notifier.alert('Info', '', 'Please Enter OTP', 'info', 5000);
      return false;
    }

    this.loading = true;

    let data = {
      ac: 'sendOtp',
      un: localStorage.getItem('un'), // "Naresh Reddy",
      eId: localStorage.getItem('eId'), // "nareshreddy.nalla@minfytech.com",
      ut: 'user',
    };

    let apiURL = `${APIService.API_ENDPOINT}/loginv2`;

    if (this.role == 'Admin') {
      //apiURL = `${APIService.API_ENDPOINT}/loginv2`;
      apiURL = `${APIService.API_ENDPOINTAdmin}/loginv2`;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data);

    console.log('a', result);

    if (result.status == '1') {
      this.showpopOTP = true;

      localStorage.removeItem('accountGroupdetails');

      localStorage.setItem(
        'menuList',
        JSON.stringify(result.mList ? result.mList : {})
      );

      localStorage.setItem('t', result.at);

      localStorage.setItem('t', result.at);
      localStorage.setItem('t', result.at);

      localStorage.setItem('ut', result.ut);
      localStorage.setItem('un', result.un);
      localStorage.setItem('ph', result.ph);

      localStorage.setItem('com', result.com);
      localStorage.setItem('des', result.des);
      localStorage.setItem('eId', result.eId);
      localStorage.setItem('acT', result.acT);
      localStorage.setItem('idT', result.id);
      localStorage.setItem('accountDetailsList', result.accountDetailsList);

      let ut = localStorage.getItem('ut');

      if (result.ut == 'admin') {
        this.router.navigate(['/dash']);
        return true;
      } else if (result.ut == 'superadmin') {
        this.router.navigate(['/dashc']);
        return true;
      } else if (result.ut == 'user') {
        this.router.navigate(['/client']);
        return true;
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      console.log(result.msg);

      this.loading = false;
      this.error = result.msg;
    }
    return false;
  }

  async fetchPnlPermission(eId: any, at: any) {
    let data = {
      a: 'pnlPermission',
      email: eId,
    };
    let header = {
      Authorization: at,
    };

    let apiURL = `https://api.swayam.cloud/v3/admin/billing/fetchchildaccwithcosts/fetchchildaccwithcostsv2`;

    // let result = await this.http.post(apiURL, data, { headers }).toPromise();
    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      localStorage.setItem('fullpnlpermision', result.fetchAll);
      localStorage.setItem('submenupnl', result.menus);
    }
    // else {
    //   this.notifier.alert('Info', '', result.msg, 'info', 5000);
    //   console.log(result.msg);
    // }
  }
}
