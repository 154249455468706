import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css', './../../warquiz.component.css'],
})
export class QuestionsComponent implements OnInit {
  @Input('workload') selectedWorkload: any;
  @Input('questions') questions: any;
  @Input('pillers') pillers: any;
  @Input('lens') selectedLens: any;
  @Input('piller') selectedPiller: any;
  @Input('indexs') currentQuestionIndexs: any;
  @Input('isMilestone') isMilestone: any;
  @Input('milestone') milestone: any;
  unsavedChanges: any = {
    status: false,
    callback: null,
  };

  @Output('removePiller') removePiller: any = new EventEmitter<boolean>();

  loading: any = {};
  currentQuestion: any;

  showSidebar: boolean = true;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  pillersData: any = {
    costOptimization: 'Cost Optimization',
    operationalExcellence: 'Operational Excellence',
    performance: 'Performance',
    reliability: 'Reliability',
    security: 'Security',
    sustainability: 'Sustainability',
  };

  lensesData: any = {
    softwareasaservice: 'SaaS Lens',
    wellarchitected: 'AWS Well-Architected Framework',
    serverless: 'Serverless Lens',
    foundationaltechnicalreview: 'FTR Lens',
  };

  reasonsData: any = {
    OUT_OF_SCOPE: 'Out of Scope',
    BUSINESS_PRIORITIES: 'Business Priorities',
    ARCHITECTURE_CONSTRAINTS: 'Architecture Constraints',
    OTHER: 'Others',
    NONE: 'None',
  };

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.currentQuestion = JSON.parse(
        JSON.stringify(
          this.filterQuestions()[
            this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        )
      );
    }, 100);
  }

  getObjectKeys(obj: any) {
    return Object.keys(obj);
  }

  removeDummyComments(question: any) {
    if (
      JSON.stringify(question).includes('dummy_comment') ||
      question['SelectedChoices'][0].endsWith('_no')
    ) {
      let check = false;
      question['SwayamChoices'] = question['SwayamChoices'].map(
        (choice: any) => {
          if (
            choice['choiceId'].endsWith('_no') &&
            question['SelectedChoices'][0].endsWith('_no') &&
            choice['edit']
          ) {
            check = true;
          }
          if (choice['dummy_comment']) {
            choice['comments'] = choice['dummy_comment'];
            choice['dummy_comment'] = undefined;
            choice['edit'] = undefined;
          }
          return choice;
        }
      );

      if (check) {
        question['SelectedChoices'] = this.currentQuestion['SelectedChoices'];
        question['SwayamChoices'] = this.currentQuestion['SwayamChoices'];
        question['ChoiceAnswerSummaries'] =
          this.currentQuestion['ChoiceAnswerSummaries'];
        question['Choices'] = this.currentQuestion['Choices'];
      }
    }
  }

  async changePiller(piller: any) {
    if (this.questions.length == 0 || this.filterQuestions().length == 0) {
      this.selectedPiller = piller;
      this.currentQuestion = null;
    }
    if (this.filterQuestions().length == 0) {
      return;
    }
    let current_question =
      this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ];
    if (
      JSON.stringify(this.currentQuestion) ==
        JSON.stringify(current_question) ||
      this.currentQuestion == null
    ) {
      this.selectedPiller = piller;
      if (this.filterQuestions().length == 0) {
        this.currentQuestion = null;
        return;
      }
      this.currentQuestion = JSON.parse(
        JSON.stringify(
          this.filterQuestions()[
            this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        )
      );
    } else {
      this.unsavedChanges['status'] = true;
      this.unsavedChanges['callback'] = () => {
        this.changePiller(piller);
      };
    }
  }

  async popupAction(event: string) {
    if (event) {
      if (event == 'SAVE') {
        let current_question =
          this.filterQuestions()[
            this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ];
        await this.save(current_question);
        this.unsavedChanges['callback']();
      } else if (event == 'SKIP') {
        this.resetQuestion();
        this.unsavedChanges['callback']();
      }
      this.unsavedChanges['status'] = false;
    }
  }

  async changeQuestion(index: number) {
    let current_question =
      this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ];
    if (
      JSON.stringify(this.currentQuestion) ==
        JSON.stringify(current_question) ||
      this.currentQuestion == null
    ) {
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] =
        index;
      this.currentQuestion = JSON.parse(
        JSON.stringify(
          this.filterQuestions()[
            this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        )
      );
    } else {
      this.unsavedChanges['status'] = true;
      this.unsavedChanges['callback'] = () => {
        this.changeQuestion(index);
      };
    }
  }

  async save(question: any) {
    if (this.isMilestone) {
      return false;
    }
    if (question.SelectedChoices.length == 0) {
      this.notifier.alert('Info', '', 'Please select a choice', 'info', 5000);
      return false;
    }
    this.loading['saving'] = true;
    this.removeDummyComments(question);

    let data: any = {
      action: 'answer_question',
      payload: question,
    };

    let headers: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    this.loading['saving'] = false;
    if (result.status == '1' || result.s == '1') {
      this.currentQuestion = JSON.parse(JSON.stringify(question));
      return true;
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
      return false;
    }
  }

  editComment(event: any, comment: any) {
    event.preventDefault();
    event.stopPropagation();
    comment['edit'] = true;
  }

  filterQuestions(
    value: any = undefined,
    type: string = undefined,
    answered: boolean = false
  ) {
    return this.questions.filter((question: any) => {
      if (type) {
        if (answered) {
          if (type == 'PillarId') {
            return (
              question[type] == value &&
              question['SelectedChoices'].length > 0 &&
              question['lensId'] == this.selectedLens
            );
          }
          return (
            question[type] == value && question['SelectedChoices'].length > 0
          );
        } else {
          if (type == 'PillarId') {
            return (
              question[type] == value && question['lensId'] == this.selectedLens
            );
          }
          return question[type] == value;
        }
      } else {
        return (
          question.PillarId == this.selectedPiller &&
          question.lensId == this.selectedLens
        );
      }
    });
  }

  getSwayamOption(question: any, choice: any) {
    if (!question['SwayamChoices']) {
      return null;
    }

    return question['SwayamChoices'].find((choice_: any) => {
      return choice_['choiceId'] == choice['ChoiceId'];
    });
  }

  getOptionSummary(question: any, choice: any) {
    if (!question['ChoiceAnswerSummaries']) {
      return null;
    }

    return question['ChoiceAnswerSummaries'].find((choice_: any) => {
      return choice_['ChoiceId'] == choice['ChoiceId'];
    });
  }

  preventDefault(event: any, prevent: boolean = true) {
    if (prevent) {
      event.preventDefault();
    }
    event.stopPropagation();
    return true;
  }

  position: any = {
    x: '0px',
    y: '0px',
    overflow: false,
    overflowwidth: '0px',
    pointer: '0px',
    width: '',
    height: '',
  };
  setPosition(event: any) {
    if (
      window.innerWidth <
      event.target.querySelector('.message_back').offsetWidth
    ) {
      this.position.overflow = true;

      if (
        window.innerWidth >
        event.target.querySelector('.message_back').offsetWidth - 110
      ) {
        this.position.overflowwidth = window.innerWidth - 50 + 'px';
      } else {
        this.position.overflowwidth = window.innerWidth - 110 + 'px';
      }
    }
    if (
      window.innerWidth - event.clientX >
      event.target.querySelector('.message_back').offsetWidth / 2
    ) {
      this.position.x =
        window.innerWidth -
        event.clientX -
        event.target.querySelector('.message_back').offsetWidth / 2 +
        'px';
    } else {
      this.position.x = 60 + 'px';
    }
    this.position.pointer =
      window.innerWidth -
      event.clientX +
      event.offsetX -
      event.target.offsetWidth +
      'px';
    this.position.y =
      event.clientY + event.target.offsetHeight - event.offsetY + 'px';
    this.position.width =
      event.target.querySelector('.message_grid').offsetWidth + 30 + 'px';
    this.position.height =
      event.target.querySelector('.message_grid').offsetHeight + 30 + 'px';
    if (
      window.innerHeight - event.clientY <
      event.target.querySelector('.message_grid').offsetHeight
    ) {
      this.position.height = window.innerHeight - event.clientY;
    }
  }

  selectOption(question: any, i: number) {
    if (this.isMilestone) {
      return;
    }
    let choice = question.Choices[i];
    let noneoption: any;
    question.Choices.forEach((choice: any) => {
      if (choice.Title == 'None of these') {
        noneoption = choice;
      }
      if (choice['reject_reason']) {
        choice['reject_reason'] = undefined;
      }
    });
    let noneOption = question.SelectedChoices.findIndex((choice: any) => {
      return choice == noneoption.ChoiceId;
    });

    if (noneOption != null && noneOption > -1) {
      question.SelectedChoices.splice(noneOption, 1);
      let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
        return choice_['ChoiceId'] == noneoption.ChoiceId;
      });
      if (summary) {
        summary['Status'] = 'UNSELECTED';
      }
    }
    if (choice.Title != 'None of these') {
      let answered = question.SelectedChoices.findIndex((selected: any) => {
        return selected == choice['ChoiceId'];
      });
      if (answered != null && answered > -1) {
        if (question['SwayamChoices']) {
          let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
            return choice_['ChoiceId'] == choice['ChoiceId'];
          });
          if (summary) {
            summary['Status'] = 'UNSELECTED';
          }
          question.SelectedChoices.splice(answered, 1);
        } else {
          question.SelectedChoices.splice(answered, 1);
          let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
            return choice_['ChoiceId'] == choice.ChoiceId;
          });
          if (summary) {
            summary['Status'] = 'UNSELECTED';
          }
        }
      } else {
        question.SelectedChoices.push(choice.ChoiceId);
        let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
          return choice_['ChoiceId'] == choice.ChoiceId;
        });
        let temp = question['SwayamChoices'].find((cho: any) => {
          return cho['choiceId'] == choice['ChoiceId'] && cho['status'];
        });
        if (summary) {
          summary['Status'] = 'SELECTED';
        }
        if (temp) {
          temp['decision'] = undefined;
        }
      }
      return;
    }

    if (
      question.SelectedChoices.length == 1 &&
      question.SelectedChoices[0].endsWith('_no')
    ) {
      question.SelectedChoices = [];
    } else {
      let check = confirm(
        'Selecting this will clear all previous entries on this form. Do you want to continue?'
      );
      if (check) {
        question.SelectedChoices = [choice.ChoiceId];
        question.ChoiceAnswerSummaries.forEach((choice_: any) => {
          if (choice_['ChoiceId'] == choice.ChoiceId) {
            choice_['Status'] = 'SELECTED';
          } else {
            choice_['Status'] = 'UNSELECTED';
          }
        });
        return;
      }
    }
  }

  resetQuestion() {
    let question = this.filterQuestions().find((question: any) => {
      return question['QuestionId'] == this.currentQuestion['QuestionId'];
    });

    let json = JSON.parse(JSON.stringify(this.currentQuestion));

    question['SelectedChoices'] = json['SelectedChoices'];
    question['SwayamChoices'] = json['SwayamChoices'];
    question['ChoiceAnswerSummaries'] = json['ChoiceAnswerSummaries'];
    question['Choices'] = json['Choices'];
  }

  removeRejectReason(event: any, option: any) {
    event.preventDefault();
    event.stopPropagation();
    option['reject_reason'] = undefined;
  }

  submitReason(event: any, question: any, choice: any) {
    event.preventDefault();
    event.stopPropagation();
    let answered = question.SelectedChoices.findIndex((selected: any) => {
      return selected == choice['ChoiceId'];
    });
    question.SelectedChoices.splice(answered, 1);
    choice['reject_reason'] = undefined;
  }

  validateOption(event: any, choice: any, bool: boolean) {
    event.preventDefault();
    event.stopPropagation();
    choice['evidenceStatus'] = bool;
  }

  resetEvidence(event: any, evidence: any) {
    event.preventDefault();
    event.stopPropagation();
    evidence['evidenceStatus'] = undefined;
    evidence['decision'] = undefined;
    let question =
      this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ];
    let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
      return choice_['ChoiceId'] == evidence.choiceId;
    });
    if (summary) {
      summary['reason_dummy'] = summary['Reason'];
      let question =
        this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ];
      let answered = question['SelectedChoices'].findIndex((choice_: any) => {
        return choice_ == evidence['choiceId'];
      });
      if (answered != null) {
        summary['Status'] = 'SELECTED';
      } else {
        summary['Status'] = 'UNSELECTED';
      }
    }
  }

  fixOption(event: any, choice: any, fix: string, key: string = 'decision') {
    event.preventDefault();
    event.stopPropagation();
    choice[key] = fix;

    if (key == 'decision') {
      let question =
        this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ];
      let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
        return choice_['ChoiceId'] == choice.choiceId;
      });
      if (summary) {
        summary['reason_dummy'] = summary['Reason'];
        let question =
          this.filterQuestions()[
            this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ];
        let answered = question['SelectedChoices'].findIndex((choice_: any) => {
          return choice_ == choice['choiceId'];
        });
        if (fix == 'Not Applicable') {
          if (answered != undefined || answered != null) {
            question.SelectedChoices.splice(answered, 1);
          }
          summary['Status'] = 'NOT_APPLICABLE';
        } else {
          if (answered != null) {
            summary['Status'] = 'UNSELECTED';
          } else {
            summary['Status'] = 'SELECTED';
          }
        }
      }
    } else {
      choice['reason_dummy'] = choice['Reason'];
      choice['Status'] =
        fix == 'Not Applicable' ? 'NOT_APPLICABLE' : choice['Status'];
    }
  }

  resetFix(
    event: any,
    evidence: any,
    key: string = 'decision',
    value: string = undefined
  ) {
    event.preventDefault();
    event.stopPropagation();
    evidence[key] = value;

    if (key == 'decision') {
      let question =
        this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ];
      let summary = question.ChoiceAnswerSummaries.find((choice_: any) => {
        return choice_['ChoiceId'] == evidence.choiceId;
      });
      if (summary) {
        summary['reason_dummy'] = undefined;
        summary['Status'] = 'UNSELECTED';
      }
    } else {
      evidence['reason_dummy'] = undefined;
      evidence['Status'] = 'UNSELECTED';
    }
  }

  resetReason(event: any, choice: any, clear: boolean) {
    event.preventDefault();
    event.stopPropagation();
    if (clear) {
      choice['reason_dummy'] = choice['Reason'];
    } else {
      choice['Reason'] = choice['reason_dummy'];
      choice['reason_dummy'] = undefined;
    }
  }

  checkIfChoiceIsSelected(question: any, choice_id: string) {
    return question['SelectedChoices'].indexOf(choice_id) > -1;
  }

  checkIfAnswered(question: any) {
    return question.SelectedChoices.length > 0;
  }

  async next(force: boolean = false) {
    let current_question =
      this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ];
    if (
      JSON.stringify(this.currentQuestion) ==
        JSON.stringify(current_question) ||
      force
    ) {
      this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ] = this.currentQuestion;
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] += 1;
      this.currentQuestion = JSON.parse(
        JSON.stringify(
          this.filterQuestions()[
            this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        )
      );
    } else {
      this.unsavedChanges['status'] = true;
      this.unsavedChanges['callback'] = () => {
        this.next(true);
      };
    }
  }
  async prev(force: boolean = false) {
    let current_question =
      this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ];
    if (
      JSON.stringify(this.currentQuestion) ==
        JSON.stringify(current_question) ||
      force
    ) {
      this.filterQuestions()[
        this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
      ] = this.currentQuestion;
      this.currentQuestionIndexs[this.selectedLens][this.selectedPiller] -= 1;
      this.currentQuestion = JSON.parse(
        JSON.stringify(
          this.filterQuestions()[
            this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
          ]
        )
      );
    } else {
      this.unsavedChanges['status'] = true;
      this.unsavedChanges['callback'] = () => {
        this.prev(true);
      };
    }
  }

  async goBack() {
    if (this.selectedLens && this.selectedPiller && this.selectedWorkload) {
      let current_question =
        this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ];
      if (
        JSON.stringify(this.currentQuestion) !=
          JSON.stringify(current_question) &&
        this.currentQuestion
      ) {
        this.unsavedChanges['status'] = true;
        this.unsavedChanges['callback'] = () => {
          this.removePiller.emit(true);
        };
        return;
      }
    }
    this.removePiller.emit(true);
  }

  showComment(event: any, choice: any) {
    event.preventDefault();
    event.stopPropagation();
    choice['show_comment'] = true;
  }

  alterPratice(event: any, practice: any) {
    if (this.isMilestone) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    practice['selected'] = !practice['selected'];
  }

  addComment(event: any, option: any) {
    event.preventDefault();
    event.stopPropagation();
    option['comments'] = option['dummy_comment'];
    option['dummy_comment'] = undefined;
  }

  canDeactivate(): any {
    if (this.selectedPiller && this.selectedLens && this.currentQuestion) {
      let current_question =
        this.filterQuestions()[
          this.currentQuestionIndexs[this.selectedLens][this.selectedPiller]
        ];
      if (
        JSON.stringify(this.currentQuestion) ==
          JSON.stringify(current_question) ||
        this.currentQuestion == null
      ) {
        return true;
      } else {
        this.unsavedChanges['status'] = true;
        this.unsavedChanges['callback'] = () => {
          return this.save(current_question);
        };
      }
    } else {
      return true;
    }
    return false;
  }
}
