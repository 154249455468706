<h5 style="text-align: center; padding-bottom: 4px;">Disks</h5>
<div class="storage_main" *ngFor="let storage of this.storage['volumes']; let i = index">
  <span *ngIf="storage['existing']" style="font-weight: bold;">{{storage.id}}</span>
  <ng-container *ngIf="storage['existing']">
    <select name="" class="form-select" style="width: auto;" [(ngModel)]="storage['required']['type']"
      id="StorageClassID">
      <option value="gp2">GP2</option>
      <option value="gp3">GP3</option>
    </select>
  </ng-container>
  <ng-container *ngIf="!storage['existing']">
    <select name="" class="form-select" style="width: auto;" [(ngModel)]="storage['type']" id="StorageClassID">
      <option value="gp2">GP2</option>
      <option value="gp3">GP3</option>
    </select>
  </ng-container>
  <ng-container *ngIf="storage['existing']">
    <input type="number" class="form-control" placeholder="Size" style="flex: 1;"
      [(ngModel)]="storage['required']['size']">
  </ng-container>
  <ng-container *ngIf="!storage['existing']">
    <input type="number" class="form-control" placeholder="Size" style="flex: 1;" [(ngModel)]="storage['size']">
  </ng-container>
  <ng-container *ngIf="storage['existing']">
    <button class="btn btn-light" style="pointer-events: none; opacity: 0;">
      <i class="fa fa-minus-circle" aria-hidden="true"></i>
    </button>
  </ng-container>
  <ng-container *ngIf="!storage['existing']">
    <button class="btn btn-light" (click)="removeStorage(i)">
      <i class="fa fa-minus-circle" aria-hidden="true"></i>
    </button>
  </ng-container>
</div>
<div style="text-align: right;">
  <button class="btn btn-light" (click)="addStorage()">
    <i class="fa fa-plus-circle" aria-hidden="true"></i> Add New
  </button>
</div>