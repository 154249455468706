<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">Scan</div>
    <div class="KBBody">
      <div class="col-mb-12" style="text-align: center;">
        <span [innerHTML]="description">
        </span>
      </div>
      <div class="col-mb-6">
        <label>Account ID: </label>
        <span>{{data.account_id}}</span>
      </div>
      <div class="col-mb-6">
        <label>Region: </label>
        <span>{{data.region_name}}</span>
      </div>
      <div class="col-mb-6 mb-2">
        <label>Scan Name: </label>
        <span>
          <input style='width: 300px;' class="form-control" type="text" [(ngModel)]="scan_name">
        </span>
      </div>
      <div class="KBActions">
        <button class="btn btn-success" (click)="yes()">Scan</button>
        <button class="btn btn-light" (click)="no()">Cancel</button>
      </div>
    </div>
  </div>
</div>