<table class="table table-docs">
  <thead>
    <tr>
      <th>Sl. No.</th>
      <th *ngFor="let h of headers">{{ h['name'] }}</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="milestones.length > 0">
      <tr *ngFor="let milestone of milestones; let i = index" (click)="selectMilestone(milestone)">
        <td>
          {{ i + 1 }}
        </td>
        <td *ngFor="let h of headers">
          <span [ngClass]="{'nav-link': h.click}">
            {{ milestone[h.id] }}
          </span>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="milestones.length == 0 && !loading['milestones']">
      <tr>
        <td colspan="5" style="text-align: center;">No Milestones found</td>
      </tr>
    </ng-container>
    <ng-container *ngIf="loading['milestones']">
      <tr>
        <td colspan="5" style="text-align: center;">
          <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>