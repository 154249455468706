<h5 style="text-align: center; padding-bottom: 4px;" *ngIf="!showFilter">Compute</h5>
<div class="instance_comparison step_1_container" *ngIf="!showFilter">
  <div class="instance_ui">
    <div><img src="./../../../../../../assets/img/sawyam test icons.png" alt="" (load)="setMainContainerHeight()"></div>
    <div class="instnace_details">
      <div class="font-bold-size">{{instance['instanceType']}}</div>
      <div class="font-small-light">RAM: {{ instance['ram'] }} GiB</div>
      <div class="font-small-light">vCPU: {{ instance['cpu'] }}</div>
      <div class="font-small-light">OD Cost: {{ instance['onDemandCost'] }}/hr</div>
    </div>
  </div>
  <div><i class="fa fa-angle-right" aria-hidden="true" style="font-size: 2em;"></i></div>
  <div class="instance_ui clickable" *ngIf="changeToInstance['required']" (click)="enableFilter()">
    <div><img src="./../../../../../../assets/img/sawyam test icons.png" alt="" (load)="setMainContainerHeight()"></div>
    <div class="instnace_details">
      <div class="font-bold-size">{{ changeToInstance['required']['instanceType'] }}</div>
      <div class="font-small-light">RAM: {{ changeToInstance['required']['ram'] }} GiB</div>
      <div class="font-small-light">vCPU: {{ changeToInstance['required']['cpu'] }}</div>
      <div class="font-small-light">OD Cost: {{ changeToInstance['required']['onDemandCost'] }}/hr</div>
    </div>
  </div>
  <div *ngIf="!changeToInstance['required']" class="instance_ui clickable select_instance" (click)="enableFilter()">
    <div>
      <i class="fa fa-check" aria-hidden="true"></i>
      <div class="font-small-light">Select Instance</div>
    </div>
  </div>
</div>
<div *ngIf="showFilter" class="instance_selector">
  <div class="search_bar">
    <div class="search_icon_container">
      <i class="fa fa-search" aria-hidden="true"></i>
    </div>
    <div class="search_input_container">
      <input type="text" name="" id="" placeholder="Filter..." [(ngModel)]="filterText" (keyup)="checkForMore()">
    </div>
    <div>
      <i class="fa fa-times clickable" aria-hidden="true" (click)="reset()"></i>
    </div>
  </div>
  <!-- <div class=" instance_family_container">
        <div class="icon_container">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
        </div>
        <div class="instance_family_main" style="width: 100%;">
          <div class="instance_family_floating">
            <div class="instance_family font-bold-border-bottom"
              [ngClass]="{'font-bold-border-bottom': instanceType.name == currentInstanceFamily}"
              (click)="currentInstanceFamily = instanceType.name"
              *ngFor="let instanceType of fetchSelectedInstanceFamilies()">{{instanceType.name}}</div>
          </div>
        </div>
        <div class="icon_container">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <div class="icon_container" style="position: relative;">
          <i class="fa fa-cog" aria-hidden="true" style="cursor: pointer;" (click)="displaySelected()"></i>
          <div class="settings_dropdown" [ngStyle]="{'max-height': showSettings['finish'] ? '200px' : '0px'}"
            *ngIf="showSettings['show']">
            <div style="display: flex; justify-content: flex-start; align-items: stretch; gap: 8px;">
              <div style="flex: 1; position: relative;">
                <i class="fa fa-search"
                  style="position: absolute; top: 50%; left: 8px; transform: translateY(-50%); opacity: 0.5;"
                  aria-hidden="true"></i>
                <input style="width: 100%; padding-left: 30px;" type="text" class="form-control"
                  [(ngModel)]="filterTextSettings" placeholder="Filter...">
              </div>
            </div>
            <div style="flex: 1; overflow-y: auto;">
              <div class="instance_option_settings" *ngFor="let instanceType of filteredResults()">
                <div style="font-weight: bold;">{{ instanceType.name }}</div>
                <div>
                  <label class="switch">
                    <input [(ngModel)]="instanceType.selected" type="checkbox">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div> -->
  <div class="search_results">
    <div>
      <div class="instance_option" (click)="selectInstance(instanceType)"
        [ngStyle]="{opacity: instanceType['display'] ? 1 : 0}" *ngFor="let instanceType of filteredResult()">
        <div>
          <img src="./../../../../../../assets/img/sawyam test icons.png" alt="">
        </div>
        <div style="flex: 1;">
          <div class="font-bold-size">{{instanceType['instanceType']}}</div>
          <div class="font-small-light">RAM: {{instanceType['ram']}} vCPU: {{instanceType['cpu']}}</div>
        </div>
        <div style="display: flex; align-items: center; gap: 10px;">
          <div *ngIf="loadingPricing[instanceType['instanceType']]">
            <ng-container *ngIf="loadingPricing[instanceType['instanceType']] == 'LOADING'">
              <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
            </ng-container>
            <ng-container *ngIf="loadingPricing[instanceType['instanceType']] != 'LOADING'">
              <span>{{loadingPricing[instanceType['instanceType']]}}/hr</span>
            </ng-container>
          </div>
          <div
            *ngIf="changeToInstance['required'] && instanceType['instanceType'] == changeToInstance['required']['instanceType']">
            <i class="fa fa-check-circle" aria-hidden="true" style="font-size: 1.2em;"></i>
          </div>
        </div>
      </div>
    </div>
    <div [ngStyle]="{display: loadingInstanceTypes ? 'block': 'none'}" class="loading_instance_types"
      style="pointer-events: none;">
      <div class="instance_option opacity_animation">
        <div class="round_icon_loader">
        </div>
        <div style="flex: 1;">
          <div class="font-bold-size big_text_loader">
          </div>
          <div class="font-small-light small_text_loader">
          </div>
        </div>
      </div>
      <div class="instance_option opacity_animation">
        <div class="round_icon_loader">
        </div>
        <div style="flex: 1;">
          <div class="font-bold-size big_text_loader">
          </div>
          <div class="font-small-light small_text_loader">
          </div>
        </div>
      </div>
      <div class="instance_option opacity_animation">
        <div class="round_icon_loader">
        </div>
        <div style="flex: 1;">
          <div class="font-bold-size big_text_loader">
          </div>
          <div class="font-small-light small_text_loader">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>