<div class="card-header" style="margin-top: 19px; height: 60px">
  <span class="title" style="font-size: 22px; line-height: 45px; vertical-align: middle">IAM Access Key Age
    Report</span>
  <button *ngIf="!fetching" class="btn btn-primary-soft" style="float: right; margin: 0 10px" (click)="refresh()">
    <i class="fa fa-refresh" aria-hidden="true"></i>
    Refresh
  </button>
  <button class="btn btn-success-soft" style="float: right; margin: 0 10px" *ngIf="!fetching && users.length > 0"
    (click)="downloadReport()"><i class="fa fa-download" aria-hidden="true"></i>
    Download</button>
</div>
<div class="card">
  <div class="card-body">
    <div style="max-width: 100%; overflow-x: auto;">
      <table class="table table-docs table-hover cost-table" id="ebs_cost_optimization">
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of userHeader">
              <div *ngIf="h.center" style="text-align: center;">
                {{ h.name }}
              </div>
              <div *ngIf="!h.center">
                {{ h.name }}
              </div>
              <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h, users)">
                <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input type="text" [(ngModel)]="h.filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
          </tr>
        </thead>

        <tbody [ngStyle]="{'display': users.length ? 'table-row-group': 'none'}" *ngFor="let i of users; let j = index">
          <tr *ngIf="notifier.filterTableColums(userHeader, i)">
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of userHeader">
              <div *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" [innerHTML]="i[h.id]">
                </div>
              </div>
              <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" *ngIf="!h.click" [innerHTML]="i[h.id]">
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="users.length == 0 && !fetching"
          [ngStyle]="{'display': users.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="userHeader.length + 1">
              <div style="text-align: center;">No Users Found</div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="fetching">
          <tr>
            <td [attr.colspan]="userHeader.length + 1" style="text-align: center;">
              <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" style="width: 20px; margin: 10px;">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>