<div class="card" *ngIf="view_type == 'counts'">
  <h5 class="card-header">Config</h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">{{h.name}}</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': configList.length > 0 ? 'table-row-group': 'none'}">
        <tr *ngFor="let i of configList; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': configList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="card" *ngIf="view_type == 'resources'">
  <h5 class="card-header">
    <span><button class="btn btn-light" (click)="goBackToCounts()">
        < Back</button></span>
    <span>Config - {{ resourceType }}</span>
  </h5>
  <div class="card-body">
    <div>
      <input id="deletedResourcesCheckbox" name="deletedResourcesCheckbox" type="checkbox" [ngModel]="deletedResources"
        (change)="getDeletedResources($event)" style="padding-right: 10px;">
      <label for="deletedResourcesCheckbox">Include Deleted Resources</label>
    </div>

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of resources_headers">{{h.name}}</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display' : resourcesList.length > 0 ? 'table-row-group': 'none'}">
        <tr *ngFor="let i of resourcesList; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of resources_headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': resourcesList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="resources_headers.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="resourcesNextToken" style="display: flex; justify-content: flex-end; margin: 10px 0px;">
      <button class="btn btn-light" style="background-color: #5864ff; color: white;" (click)="loadMoreResources()">
        Next
      </button>
    </div>
  </div>
</div>
<div class="card" [ngStyle]="{'display': view_type == 'resource_timeline' ? 'block' : 'none'}">
  <h5 class="card-header">
    <span><button class="btn btn-light" (click)="goBackToResources()">
        < Back</button></span>
    <span>Config - {{ resourceType }} - {{ resourcecIdentifier }}</span>
  </h5>
  <div class="card-body">
    <div class="card">
      <h5 class="card-header">
        <span>Details</span>
      </h5>
      <div class="card-body">
        <div class="panel panel-default">
          <div class="panel-heading" style="font-size: medium"></div>

          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <b>Resource ID</b>
                <p id="eventname">
                  {{ resourcecIdentifier }}
                </p>
              </div>
              <div class="col-md-6">
                <b>Resource Type</b>
                <p id="eventid">{{ resourceType }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p>&nbsp;</p>
    <div class="card">
      <h5 class="card-header" style="display: flex; justify-content: space-between;">
        <span>Events</span>
        <span>
          <input class="datetimepicker" type="text" [(ngModel)]="dateRange" />
        </span>
      </h5>
      <div class="card-body" style="padding-right: 20px;">
        <div class="panel panel-default">
          <div class="panel-heading" style="font-size: medium"></div>

          <div class="panel-body">
            <div class="row">
              <h4 *ngIf="resourceHistory && resourceHistory.length > 0">{{ showDateFormat(
                resourceHistory[0].configurationItemCaptureTime,"MMMM DD, YYYY") }}</h4>

              <ul class="timeline">

                <li class="timeline-inverted" *ngFor="let i of resourceHistory">
                  <!--<div class="timeline-badge primary"><a><i class="glyphicon glyphicon-record" rel="tooltip" title="11 hours ago via Twitter" id=""></i></a></div>-->
                  <div class="timeline-panel" style="margin-right:40px">
                    <div class="timeline-heading">
                      <h5 class="titleT">Configuration Change</h5>
                    </div>
                    <div class="timeline-body">

                      <div class="card">
                        <h5 class="card-header">
                          <span>JSON</span>
                        </h5>
                        <div class="card-body">
                          <div class="panel panel-default">

                            <pre class='code language-json"' style="min-height:500px"
                              [innerHTML]="parseJson(i.configuration)"></pre>
                          </div>
                        </div>

                      </div>

                      <!--<div class="timeline-footer">
                        <a><i class="glyphicon glyphicon-thumbs-up"></i></a>
                        <a><i class="glyphicon glyphicon-share"></i></a>
                        <a class="pull-right">Continuar Lendo</a>
                      </div>-->
                    </div>
                  </div>
                </li>





                <li class="clearfix" style="float: none;"></li>
              </ul>


              <!--<div class="col-md-6" *ngFor="let i of resourceHistory">
                <b>Resource ID</b>
                <p id="eventname">
                  {{ showDateFormat( i.configurationItemCaptureTime,"MMMM DD, YYYY") }}
                </p>
              </div>
              <div class="col-md-6">
                <b>Resource Type</b>
                <p id="eventid">{{ resourceType }}</p>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<style>
  .titleT {
    padding: 10px;
  }

  div.code {
    white-space: pre;
  }
</style>