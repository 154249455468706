<div class="screen">
  <div class="popup-container">
    <div class="icon-container-popup">
      <i class="fa-solid fa-triangle-exclamation"></i>
    </div>
    <div class="header-text">
      Unsaved changes!
    </div>
    <div class="popup-description">
      There are unsaved changes. <br> Do you want to Save and Proceed?
    </div>
    <div class="flex-center">
      <button class="btn btn-primary-soft" (click)="this.action('SAVE')">Save & Proceed</button>
      <button class="btn btn-light" (click)="this.action('SKIP')">Skip</button>
      <button class="btn btn-light" (click)="this.action('CANCEL')">Cancel</button>
    </div>
  </div>
</div>