import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as moment from 'moment';

declare let window: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './iam.component.html',
})
export class iamComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  iamCheckList: any = [];
  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  headers: any = [
    {
      id: 'Description',
      name: 'Description',
      sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
    {
      id: 'Result',
      name: 'Status',
      color: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Category',
      name: 'Category',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Severity',
      name: 'Severity',
      sort: { sort: true, direction: 'Asc' },
      alter: true,
    },
    {
      id: 'failReason',
      name: 'Fail Reason',
      sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
  ];
  showOffendesModal = false;
  offendeList: any;
  offendeListHeader: any = [
    {
      id: 'offenders',
      name: 'Offenders',
    },
  ];
  offentpopTitle = 'Offender List';

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      this.iamCheckList = [];
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  scanDetails: any = null;
  fetching: Boolean = true;
  async load() {
    this.iamCheckList = [];
    this.scanDetails = null;
    this.notifier.loading(true);
    let data: any = {
      accountId: this.accountId,
      audit_type: '/audit/iam',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/audit/iam`;

    let ids = await this.apiServer.postDataPromis(apiURL, data, header);

    if (ids.scanIds || ids.scanId) {
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/audit/status`;
      data = {
        scanId: (ids.scanIds && ids.scanIds[0]) || ids.scanId,
        audit_type: '/audit/iam',
      };
      let status = await this.apiServer.postDataPromis(apiURL, data, header);
      this.scanDetails = status;
      if (this.scanDetails.startedAt) {
        this.scanDetails.startedAt = moment(this.scanDetails.startedAt).format(
          'DD/MM/YYYY hh:mm A'
        );
      }
      this.fetching = false;

      if (status.hasOwnProperty('signedURL') && status.signedURL != '') {
        apiURL = status['signedURL'];
        let offenders = await this.apiServer.getDataPromis(apiURL, {}, {});
        this.iamCheckList = offenders;
        this.iamCheckList = this.iamCheckList.map((iam: any) => {
          if (
            iam.Severity == 'High' &&
            (iam.Result == 'false' || iam.Result.toLowerCase() == 'manual')
          ) {
            iam['index'] = 1;
          }
          if (
            iam.Severity == 'Medium' &&
            (iam.Result == 'false' || iam.Result.toLowerCase() == 'manual')
          ) {
            iam['index'] = 2;
          }
          if (
            iam.Severity == 'Low' &&
            (iam.Result == 'false' || iam.Result.toLowerCase() == 'manual')
          ) {
            iam['index'] = 3;
          }
          if (iam.Severity == 'High' && iam.Result == 'true') {
            iam['index'] = 4;
          }
          if (iam.Severity == 'Medium' && iam.Result == 'true') {
            iam['index'] = 5;
          }
          if (iam.Severity == 'Low' && iam.Result == 'true') {
            iam['index'] = 6;
          }
          return iam;
        });
        this.iamCheckList = this.iamCheckList.sort((a: any, b: any) => {
          if (a.index < b.index) {
            return -1;
          }
          return 0;
        });
        this.notifier.loading(false);
      } else if (status.scanStatus != 'COMPLETE' && status.signedURL == '') {
        this.notifier.loading(false);
      } else if (status.scanStatus == 'Submitted') {
        this.notifier.loading(false);
      }
    } else if (ids.statusCode == 202) {
      this.notifier.loading(false);
    } else {
      this.notifier.loading(false);
    }
  }

  downloadReport() {
    let element = document.createElement('table');
    element.innerHTML += `
        <thead>
          <tr>
            <th>Sl. No.</th>
            ${this.headers.map((h: any) => {
              return `<th>${h.name}</th>`;
            })}
            <td>Offenders</td>
          </tr>
        </thead>
        <tbody>
            ${this.iamCheckList.map((ec2: any, index: any) => {
              return `
                <tr>
                  <td>${index + 1}</td>
                  ${this.headers.map((h: any) => {
                    return `<td>
                      ${this.checkforBool(ec2[h.id], h.id)}
                    </td>`;
                  })}
                  <td>
                    ${ec2['Offenders'].join(', ')}
                  </td>
                </tr>
              `;
            })}
        </tbody>
      `;
    window.exportToExcelElement(
      element,
      `IAM Compliance - ${this.accountId}`,
      'csv'
    );
  }

  callFunction(name: any, param: any) {
    return;
  }

  checkforBool(value: any, key: any): String {
    if (key == 'Result') {
      return value == 'true' || value == true
        ? 'Pass'
        : value == 'false' || value == false
        ? 'Fail'
        : value;
    } else {
      return value;
    }
  }

  showOffendes(offendeList: any) {
    this.showOffendesModal = true;

    var dt = [];
    for (var i = 0; i < offendeList.length; i++) {
      dt.push({ offenders: offendeList[i] });
    }

    this.offendeList = dt;
  }

  hidePop(e: any) {
    this.showOffendesModal = false;
    this.offendeList = [];
  }
}
