import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

import * as moment from 'moment';
declare let Jhxlsx: any;
@Component({
  // selector: 'app-sp-inventory',
  templateUrl: './sp-inventory.component.html',
  styleUrls: ['./sp-inventory.component.css']
})
export class SpInventoryComponent implements OnInit {

  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
    spInventory: any=[]

  accountId: any;
  regionId: any;
  currentMessage: any;
  dateRange: any;
  headers: any = [
    {
      id: 'masterId',
      name: 'Master Account ID',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'accountId',
      name: 'Child Account ID',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'savingsPlanId',
      name: 'Savings Plan ID',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'type',
      name: 'Type',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'instanceFamily',
      name: 'Instance Family',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'status',
      name: 'Status',
      filter: true,
      type: 'status',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'region',
      name: 'Region',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'commitment',
      name: 'Commitment',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'enddate',
      name: 'End Date',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    }, {
      id: 'startdate',
      name: 'Start Date',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'term',
      name: 'Term',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    }
  ];


  constructor(
    public notifier: NotifierService,
    private apiService: APIService,
    private zone: NgZone
  ) {}


  ngOnInit(): void {
    this.load();
  }
  async load() {
    this.notifier.loading(true);
    let data = {
      "a":"fetchsp"
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/inventory`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {

      this.spInventory = result.sp.map((sp:any) => {
        
        sp.enddate=moment(sp.enddate).format(
          'DD/MM/YYYY hh:mm A'
        );
        sp.startdate=moment(sp.startdate).format( 'DD/MM/YYYY hh:mm A')

        // sp.total=Number(sp.total).toFixed(4);
        return sp;
     
      });
      // this.c
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }
  export() {
    let data: any = [
      {
        sheetName: `SP Inventory Report`,
        data: [
          (this.headers).map(
            (h: any) => {
              return { text: h.name };
            }
          ),
        ],
      },
    ];

    let sheetData: any =  this.spInventory;
    // let headers: this.headers;

    sheetData.map((item: any) => {
      let data_: any = [];
      this.headers.forEach((h: any) => {
        data_.push({ text: h.alter ? h.alter(item, h.id) : item[h.id] });
      });

      data[0]['data'].push(data_);
    });

    var options = {
      fileName: "SP_Inventory_Report"
    };

    Jhxlsx.export(data, options);
  }


}
