<div class="card mt-20" style="border: none; position: relative; height: 100%;">
    <h5 class="card-header">RI Inventory Report</h5>
    <div class="card-body">
        <div style="display: flex; justify-content: flex-end; align-items: center; margin: 10px 0px;">
            <button *ngIf="riInventory && riInventory.length > 0" class="btn" style="background-color: #5864ff; color: white"
                (click)="export()">
                Export
              </button>
          </div>
         
          
      <table class="table table-docs table-hover" id="ri-inventory-table">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <ng-container *ngFor="let h of headers">

                <th *ngIf="!h.hide">
                    {{ h.name }}
                    <span
                      *ngIf="h.sort && h.sort.sort"
                      (click)="notifier.sortTable(h, riInventory)"
                    >
                      <i
                        class="fa fa-sort-amount-desc"
                        aria-hidden="true"
                        *ngIf="h.sort.direction == 'Des'"
                      ></i>
                      <i
                        class="fa fa-sort-amount-asc"
                        aria-hidden="true"
                        *ngIf="h.sort.direction == 'Asc'"
                      ></i>
                    </span>
                    <span *ngIf="h.filter" class="inputseach">
                      <input type="text" [(ngModel)]="h.filterText" />
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </span>
                  </th>


            <!-- <th *ngIf="!h.hide">{{ h.name }}</th> -->
            </ng-container>
          </tr>
        </thead>
  
        <tbody [ngStyle]="{'display': riInventory.length > 0 ? 'table-row-group' : 'none'}">
            <ng-container *ngFor="let i of riInventory; let j = index" >

          
          <tr *ngIf="notifier.filterTableColums(headers, i)" >
            <!-- <td>{{j + 1}}</td> -->
            <td>{{ j + 1 }}</td>
            <ng-container *ngFor="let h of headers">
            <td *ngIf="!h.hide">
              <ng-container *ngIf="h.type == 'string'">
                <span *ngIf="!h.click">{{
                  h.alter ? h.alter(i, h.id) : i[h.id]
                }}</span>
              </ng-container>
              <ng-container *ngIf="h.type == 'status'">
                <div
                  class="flex-start status-container {{ i[h.id].toLowerCase() }}"
                >
                  <div class="round-indicator {{ i[h.id].toLowerCase() }}"></div>
                  <div>{{ i[h.id] }}</div>
                </div>
              </ng-container>
              </td>
            </ng-container>
            <!-- <ng-container *ngFor="let h of headers">
                <td *ngIf="!h.hide" >
                    <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
                  </td>
            </ng-container>
          -->
          </tr>
        </ng-container>
        </tbody>
        <tbody [ngStyle]="{'display': riInventory.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="headers.length + 1">
              <div style="text-align: center;">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  <!-- </div> -->