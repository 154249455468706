import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-changerequesttype',
  templateUrl: './changerequesttype.component.html',
  styleUrls: [
    './changerequesttype.component.css',
    '../step1-instance-selector/step1-instance-selector.component.css',
  ],
})
export class ChangerequesttypeComponent implements OnInit {
  @Input('typesselected') typesselected: any;
  @Output('setMainHeight')
  setMainHeight = new EventEmitter<number>();
  constructor() {}

  ngOnInit(): void {}

  toggleType(type: string) {
    this.typesselected[type]['selected'] =
      !this.typesselected[type]['selected'];
  }

  setMainContainerHeight(height: number = undefined) {
    this.setMainHeight.emit(height);
  }
}
