import { Directive, ElementRef, Input  } from '@angular/core';

@Directive({
  selector: '[numbersonly]'
})

@Input()
export class NumbersOnly {
  constructor(public el: ElementRef) {

    this.el.nativeElement.onkeypress = (evt: any) => {
      console.log(evt.which);
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      }
    };

  }
}
