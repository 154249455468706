<div [ngStyle]="{display: !moreDetails || !moreDetails.action ? 'block': 'none'}" class="card">
  <div class="card-header" style="margin-top: 19px; height: 60px">
    <span class="title" style="font-size: 22px; line-height: 45px; vertical-align: middle">IAM Groups</span>
    <button *ngIf="!groupLoading" class="btn btn-primary-soft" style="float: right; margin: 0 10px" (click)="refresh()">
      <i class="fa fa-refresh" aria-hidden="true"></i>
      Refresh
    </button>
  </div>
  <div class="card-body">

    <div>
      <div style="display: flex; justify-content: flex-start; align-items: center; gap: 15px; padding: 10px;">
        <div (click)="loadDetails('All Groups')"
          style="cursor: pointer; background-color: white; border: 1px solid rgb(224, 224, 224); border-radius: 8px; padding: 8px; min-width: 200px;">
          <div>
            <div>
              <Strong>Groups</Strong>
            </div>
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              <span style="font-size: 2em;">{{groupDetail.groups}}</span>
              <img src="../assets/img/loading_2.svg" *ngIf="groupLoading" alt="loading scans" width="10"
                style="width: 16px; margin: 10px;">
            </div>
          </div>
        </div>
        <div (click)="loadDetails('No users')"
          style="cursor: pointer; background-color: rgb(214 41 62 / 60%); color: #d6293e; border-radius: 8px; padding: 8px; min-width: 200px; ">
          <div style="display: flex; justify-content: flex-start; gap: 10px;">
            <div style="padding: 6px 0px 0px 6px;">
              <i class="fa fa-exclamation-circle" aria-hidden="true"
                style="font-size: 30px; color: white; opacity: 0.6;"></i>
            </div>
            <div>
              <div>
                <Strong style="color: white;">Without Users</Strong>
              </div>
              <div style="display: flex; justify-content: flex-start; align-items: center;">
                <span style="color: white; font-size: 2em;">{{groupDetail.without_Users}}</span>
              </div>
            </div>
          </div>
        </div>
        <div (click)="loadDetails('With inline policies')"
          style="cursor: pointer; background-color: rgb(214 41 62 / 60%); color: #d6293e; border-radius: 8px; padding: 8px; min-width: 200px; ">
          <div style="display: flex; justify-content: flex-start; gap: 10px;">
            <div style="padding: 6px 0px 0px 6px;">
              <i class="fa fa-exclamation-circle" aria-hidden="true"
                style="font-size: 30px; color: white; opacity: 0.6;"></i>
            </div>
            <div>
              <div>
                <Strong style="color: white;">With Inline Policies</Strong>
              </div>
              <div style="display: flex; justify-content: flex-start; align-items: center;">
                <span style="color: white; font-size: 2em;">{{groupDetail.with_Inline_Policies}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div
        style="margin-top: 10px; padding: 10px; margin-bottom: 70px; display: flex; justify-content: flex-start; align-items: center; gap: 15px; height: 500px;"
        [ngStyle]="{display: groupDetail.groups == '-' ? 'none': 'flex'}">
        <div
          style="background-color: white; padding-top: 10px; margin-top: 70px; border-radius: 4px; border-bottom: 1px solid rgba(0, 0, 0, 0.125); ">
          <span style="font-size: 1.4em; padding: 0px 15px; display: block;">Without Users</span>
          <hr style="margin: 10px 0;">
          <div>
            <div id="graph_without_user" style="border-radius: 10px; padding: 10px; height: 500px; width: 500px;"></div>
          </div>
        </div>

        <div
          style="background-color: white; padding-top: 10px; margin-top: 70px; border-radius: 4px; border-bottom: 1px solid rgba(0, 0, 0, 0.125); ">
          <span style="font-size: 1.4em; padding: 0px 15px; display: block;">Inline Policies</span>
          <hr style="margin: 10px 0;">
          <div>
            <div id="graph_with_inline" style=" border-radius: 10px; padding: 10px; height: 500px; width: 500px;"></div>
          </div>
        </div>
      </div>
    </div>

    <style>
      #graph_without_user canvas,
      #graph_with_inline canvas {
        width: 100%;
        height: 100%;
      }
    </style>
  </div>
</div>

<div *ngIf="moreDetails && moreDetails.action" class="card">
  <div class="card-header" style="height: 60px">
    <span class="title" style="font-size: 22px; line-height: 45px; vertical-align: middle">
      <button class="btn btn-light" (click)="hideDetails()">
        < Back</button>
          <span>{{moreDetails.title}}</span>
    </span>
  </div>
  <div class="card-body">
    <iam-groups-details [action]="moreDetails.action" [title]="moreDetails.title"></iam-groups-details>
  </div>
</div>