<div class="card" *ngIf="!updateRule && !deleteRule">
  <div class="card-header">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <span class="btn" (click)="close()" style="padding: 0px; margin: 0px;">Rules</span> <span
          class="seperator">></span>
        <span>{{rule.rule_name}}</span>
      </div>
      <div>
        <button style="background-color: #5864FF; color: white;" class="btn btn-light"
          (click)="triggerUpdateRule()">Update</button>
        <button class="btn btn-danger" (click)="deleteRule = rule">Delete</button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="rule_grid">
      <div class="rule_grid_child">
        <div class="rule_key">Rule ID</div>
        <div class="rule_value">{{ rule.rule_id }}</div>
      </div>
      <div class="rule_grid_child">
        <div class="rule_key">Rule Name</div>
        <div class="rule_value">{{ rule.rule_name }}</div>
      </div>
      <div class="rule_grid_child">
        <div class="rule_key">Created On</div>
        <div class="rule_value">{{ parseDate(rule.rule_creation_time) }}</div>
      </div>
      <div class="rule_grid_child">
        <div class="rule_key">Created By</div>
        <div class="rule_value">{{ rule.created_by }}</div>
      </div>
      <div *ngIf="rule.updated_by" class="rule_grid_child">
        <div class="rule_key">Last Updated By</div>
        <div class="rule_value">{{ rule.updated_by }}</div>
      </div>
      <div class="rule_grid_child">
        <div class="rule_key">Backup Type</div>
        <div class="rule_value">{{ rule.backup_type || 'backup_by_tag' }}</div>
      </div>
      <div class="rule_grid_child">
        <div class="rule_key">Status</div>
        <div class="rule_value" [ngStyle]="{color: rule.status ? 'green' : 'grey'}">{{ rule.status ? 'Active' :
          'Disabled' }}</div>
      </div>
      <div class="rule_grid_child">
        <div class="rule_key">Backup Frequency</div>
        <div class="rule_value">{{ minsToHumanReadable(rule.backup_frequency) }}</div>
      </div>
      <div class="rule_grid_child">
        <div class="rule_key">Backup Retention</div>
        <div class="rule_value">{{ minsToHumanReadable(rule.backup_retention) }}</div>
      </div>
      <div class="rule_grid_child">
        <div class="rule_key">Log Retention</div>
        <div class="rule_value">{{ minsToHumanReadable(rule.log_retention) }}</div>
      </div>
      <div class="rule_grid_child">
        <div class="rule_key">Last Triggered</div>
        <div class="rule_value">{{ parseDate(rule.last_triggered) }}</div>
      </div>
      <div class="rule_grid_child">
        <div class="rule_key">Next Trigger</div>
        <div class="rule_value">{{ getDiff(rule.last_triggered, rule.backup_frequency, rule.status) }}</div>
      </div>
    </div>
    <hr>
    <div style="margin: 10px 0px;">
      <div style="margin: 5px 0px;" class="card-header">
        <h5>Resources</h5>
      </div>
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of rulesHeaders">{{h.name}}</th>
          </tr>
        </thead>

        <tbody [ngStyle]="{'display': parseResources().length > 0 ? 'table-row-group': ''}">
          <tr *ngFor="let i of parseResources(); let j = index">
            <td>{{j+1}}</td>
            <td *ngFor="let h of rulesHeaders">
              <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                <span (click)="callFunction(h.click,i)">
                  {{i[h.id]}}
                </span>
              </span>
              <span *ngIf="!h.click">
                <span>{{h.alter ? h.alter(i[h.id]) : i[h.id]}}</span>
              </span>
            </td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': parseResources().length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="rulesHeaders.length + 1">
              <div style="text-align: center;">No Resources Found.

              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="card" *ngIf="updateRule">
  <div class="card-header">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <span class="btn" (click)="close()" style="padding: 0px; margin: 0px;">Rules</span> <span
          class="seperator">></span>
        <span class="btn" style="padding: 0; margin: 0;" (click)="updateRule = null">{{rule.rule_name}}</span>
        <span class="seperator">></span>
        <span>Update</span>
      </div>
    </div>
  </div>
  <div class="card-body">
    <createrule-backup *ngIf="updateRule" (close)="updateCheck($event)" [action]="'update_backup_rule'"
      [rule]="updateRule"></createrule-backup>

  </div>
</div>

<div class="card" *ngIf="deleteRule">
  <div class="card-header">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <span class="btn" (click)="close()" style="padding: 0px; margin: 0px;">Rules</span> <span
          class="seperator">></span>
        <span class="btn" style="padding: 0; margin: 0;" (click)="deleteRule = null">{{rule.rule_name}}</span>
        <span class="seperator">></span>
        <span>Delete</span>
      </div>
    </div>
  </div>
  <div class="card-body">
    <deleterule-backup *ngIf="deleteRule" (close)="deleteCheck($event)" [rule]="deleteRule"></deleterule-backup>

  </div>
</div>