import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  styleUrls: [
    './createscan.amis.component.css',
    './../../warquiz.component.css',
  ],
  selector: 'create-workload',
  templateUrl: './createscan.amis.component.html',
})
export class CreateWorkloadComponent implements OnInit, OnDestroy {
  @Output() hideModal = new EventEmitter<any>();
  @Input() accountId: string;
  @Input() regionId: string;
  uid = localStorage.getItem('t');
  t = localStorage.getItem('t');
  ut = localStorage.getItem('ut');
  eId = localStorage.getItem('eId');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  scan_name: string = '';
  review_owner: string = '';
  description: string = '';
  environment: string = 'PRODUCTION';
  loading: boolean = false;
  lensesData: any = {
    wellarchitected: {
      name: 'AWS Well-Architected Framework',
      description:
        'The AWS Well-Architected Framework Lens provides a set of foundational questions for you to consider for all of your cloud architectures.',
      selected: true,
      editable: false,
    },
    softwareasaservice: {
      name: 'SaaS Lens',
      description:
        'The AWS SaaS Lens provides a set of additional questions for you to consider for your Software-as-a-Service (SaaS) applications',
      selected: false,
    },
    serverless: {
      name: 'Serverless Lens',
      description:
        'The AWS Serverless Application Lens provides a set of additional questions for you to consider for your serverless applications.',
      selected: false,
    },
  };

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  async yes() {
    await this.createJob();
    return;
  }

  no(check: boolean = false) {
    this.hideModal.emit(check);
  }

  getObjectKeys(obj: any): any {
    return Object.keys(obj);
  }

  async createJob() {
    var format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

    if (format.test(this.scan_name)) {
      this.notifier.alert(
        'Error',
        '',
        'Special Characters are not allowed.',
        'error',
        5000
      );
      return;
    }
    if (this.scan_name == '') {
      this.notifier.alert('Info', '', 'Scan Name is required.', 'info', 5000);
      return;
    }
    this.loading = true;
    let data: any = {
      action: 'create_workload_v2',
      account_id: this.accountId,
      region_id: this.regionId,
      workload_name: this.scan_name,
      description: this.description,
      environment: this.environment,
      review_owner: this.review_owner,
      lenses: this.getObjectKeys(this.lensesData).filter((lens: any) => {
        return this.lensesData[lens].selected;
      }),
      id_token: localStorage.getItem('idT'),
    };

    let headers: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('success', '', result.message, 'success', 5000);
      this.no(true);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.loading = false;
  }
}
