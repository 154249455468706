<div class="card">
  <div class="card-header">
    <h5><button class="btn btn-light" (click)="hide()">
        < Back</button> Change Request - {{cr_detail['cr_id']}}</h5>
    <span>
      <changerequest-status [status]="cr_detail['status']"></changerequest-status>
    </span>
  </div>
  <div class="card-body">
    <div class="cr_detail_container">
      <div class="cr_detail_components" *ngIf="cr_detail['type'] != 'New Instance'">
        <app-crdetails [crdetail]="cr_detail" [accountId]="account_id" [regionId]="region_id"
          [cr_id]="cr_detail['cr_id']" [notReviewable]="true"></app-crdetails>
      </div>
      <div class="cr_detail_components" *ngIf="cr_detail['type'] == 'New Instance'">
        <app-new-instance [instanceObj]="cr_detail" [readOnly]="true" (hideModel)="false"></app-new-instance>
      </div>
      <div class="cr_detail_actions">
        <div class="form-group mb-2">
          <p style="margin: 0;"><strong>Created On</strong></p>
          <p>{{parseDate(cr_detail['creation_time'])}}</p>
        </div>
        <div class="form-group mb-2">
          <p style="margin: 0;"><strong>Created By</strong></p>
          <p>{{cr_detail['user_name']}}</p>
        </div>
        <div class="form-group mb-2">
          <p style="margin: 0;"><strong>Review Status</strong></p>
          <p>{{cr_detail['review_status'] || 'Not Reviewed'}}</p>
        </div>
        <ng-container *ngIf="cr_detail['review_status']">
          <div class="form-group mb-2">
            <p style="margin: 0;"><strong>{{cr_detail['review_status']}} By</strong></p>
            <p>{{cr_detail['reviewed_by']}}</p>
          </div>
        </ng-container>
        <ng-container *ngIf="cr_detail['status'] == 'Rejected'">
          <div class="form-group mb-2">
            <p style="margin: 0;"><strong>Reject Reason</strong></p>
            <p>{{cr_detail['reject_reason']}}</p>
          </div>
        </ng-container>
        <ng-container
          *ngIf="cr_detail['status'] != 'Approved' && cr_detail['status'] != 'Rejected' && checkForReviewer() && sr">
          <div class="form-group mb-2">
            <a routerLink="/dash/bom/changerequest"
              [queryParams]="{action: 'review', cr_id: cr_detail['cr_id'], region_id: cr_detail['region_id'], account_id: cr_detail['account_id'], resource_ids: cr_detail['metadata']['resource_ids'].join(','), sr: sr}"
              target="_blank" class="btn btn-primary-soft">Review</a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>