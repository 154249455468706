<div class="alarm_screen" style="box-shadow: 1px 3px 1px #80808024">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform: capitalize">
        {{ action == "update" ? "Update" : "Add" }} RI
      </h4>
    </div>
    <div class="alarm_body" style="padding: 1px 25px 5px 25px">
      <div
        class="row"
        [ngStyle]="{ display: ri.riType == 'OnDemand' ? 'none' : 'block' }"
      >
        <label>Instance Types</label>
        <select
          class="form-control"
          id="instanceTypes"
          (change)="fetchRICost()"
          [(ngModel)]="ri.instanceType"
          data-live-search="true"
        >
          <option *ngFor="let type of instanceTypes" value="{{ type }}">
            {{ type }}
          </option>
        </select>
      </div>
      <div class="row">
        <label>OS</label>
        <select
          class="form-control"
          id="platformsList"
          (change)="fetchRICost()"
          [(ngModel)]="ri.os"
          data-live-search="true"
        >
          <option *ngFor="let platform of platformTypes" value="{{ platform }}">
            {{ platform }}
          </option>
        </select>
      </div>
      <div
        class="row"
        [ngStyle]="{ display: ri.riType == 'OnDemand' ? 'none' : 'block' }"
      >
        <label>Region</label>
        <select
          class="form-control"
          id="regionsList"
          (change)="fetchRICost()"
          [(ngModel)]="ri.region"
          data-live-search="true"
        >
          <option *ngFor="let region of regions" value="{{ region.id }}">
            {{ region.name }}
          </option>
        </select>
      </div>
      <div class="row">
        <label>Quantity</label>
        <input type="number" class="form-control" [(ngModel)]="ri.quantity" />
      </div>
      <div class="row">
        <label>RI Type</label>
        <select
          class="form-control"
          id="billingTypes"
          [(ngModel)]="ri.riType"
          (change)="fetchRICost()"
          data-live-search="true"
        >
          <option *ngFor="let type of RITypes" value="{{ type }}">
            {{ type }}
          </option>
        </select>
      </div>
      <div
        class="row"
        [ngStyle]="{ display: ri.riType == 'OnDemand' ? 'none' : 'block' }"
      >
        <label>RI hourly cost</label>
        <input type="text" class="form-control" [(ngModel)]="ri.riCost" />
      </div>
      <div class="row">
        <label>Discount percentage:</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="ri.discountPercentage"
        />
      </div>
      <div class="row">
        <label>Period:</label>
        <input class="datetimepicker" type="text" [(ngModel)]="dateRange" />
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button
            class="btn"
            (click)="updateEntry()"
            style="
              background-color: #5864ff;
              color: white;
              text-transform: capitalize;
              margin-right: 20px;
            "
          >
            {{ action == "add" ? "Add" : action }}
          </button>
          <button class="btn btn-light" (click)="close(false)">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>
</div>
