<h4 style="text-align: center;">Forgot Password</h4>
<form [formGroup]="userNameForm" *ngIf="!otpSent" (ngSubmit)="sendOTP()">
  <div class="mb-3">
    <label class="form-label" for="card-email">Email</label>
    <input class="form-control" id="card-email" formControlName="username" type="email" required minlength="4"
      maxlength="50" [(ngModel)]="username" />
    <div *ngIf="username_form.username.invalid && (username_form.username.dirty || username_form.username.touched)"
      class="alert alert-danger">
      <div *ngIf="username_form.username.errors && username_form.username.errors.required">
        Email is Required.
      </div>
    </div>
  </div>

  <div class="mb-3">
    <button class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit" (ngSubmit)="sendOTP()">
      Send OTP
    </button>
  </div>
</form>
<form [formGroup]="newPasswordForm" *ngIf="otpSent" (ngSubmit)="resetPassword()">
  <div class="mb-3">
    <label class="form-label" for="card-email">OTP</label>
    <input class="form-control" id="card-email" formControlName="otp" type="text" autocomplete="off" required
      minlength="4" maxlength="50" [(ngModel)]="otp" />
    <div *ngIf="new_password_form.otp.invalid && (new_password_form.otp.dirty || new_password_form.otp.touched)"
      class="alert alert-danger">
      <div *ngIf="new_password_form.otp.errors && new_password_form.otp.errors.required">
        OTP is Required
      </div>
    </div>
  </div>
  <div class="mb-3">
    <label class="form-label" for="card-email">New Password</label>
    <input class="form-control" id="card-email" formControlName="new_password" type="password" required minlength="7"
      maxlength="50" [(ngModel)]="newPassword" />
    <div
      *ngIf="new_password_form.new_password.invalid && (new_password_form.new_password.dirty || new_password_form.username.touched)"
      class="alert alert-danger">
      <div *ngIf="new_password_form.new_password.errors && new_password_form.new_password.errors.required">
        Password is Required.
      </div>
    </div>
  </div>
  <div class="mb-3">
    <label class="form-label" for="card-email">Confirm Password</label>
    <input class="form-control" id="card-email" formControlName="confirm_new_password" type="password" required
      minlength="7" maxlength="50" [(ngModel)]="newConfirmPassword" />
    <div
      *ngIf="new_password_form.confirm_new_password.invalid && (new_password_form.confirm_new_password.dirty || new_password_form.confirm_new_password.touched)"
      class="alert alert-danger">
      <div
        *ngIf="new_password_form.confirm_new_password.errors && new_password_form.confirm_new_password.errors.required">
        Confirm Password is Required.
      </div>
    </div>
  </div>

  <div class="mb-3">
    <button class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit" (ngSubmit)="resetPassword()">
      Reset Password
    </button>
  </div>
</form>