<div class="rulesets" *ngIf="!fetching">
  <div class="add-rule add-rule2" *ngIf="addedRulesetsOptions.length == 0">
    No Rulesets added
  </div>
  <div class="ruleset" *ngFor="let rule of addedRulesetsOptions; let i = index">
    <div class="ruleset_header">
      <span>
        {{rule}}
      </span>
      <span (click)="removeRuleSet(i)">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="ruleset_body">
      <div *ngFor="let key of getKeys(rule)" class="ruleset_input">
        <label for="">{{key}}</label>
        <input type="number" class="form-control" [(ngModel)]="this.rulesetsInterface[rule][key]">
      </div>
    </div>
  </div>
  <div class="add-rule" *ngIf="selectableRuleSet().length > 0">
    <select id="" class="form-select" name="rulesets" [(ngModel)]="selectedRuleSet">
      <option *ngFor=" let rule of selectableRuleSet()" value="{{rule}}">
        {{rule}}
      </option>
    </select>
    <span class="minfy_button" (click)="addRuleSet()">Add Rule</span>
  </div>
</div>

<div *ngIf="fetching" class="fetching_31">
  <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_scan_32" />
</div>