import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'nTable',
  templateUrl: 'nTable.html',
  //styleUrls: ['./hero-detail.component.css']
})
export class nTableComponent implements OnInit {
  @Input() headers?: any;
  @Input() data?: any;
  @Input() modalTitle?: any;

  @Input() showModal?: any;

  @Output() hideModal = new EventEmitter<boolean>();

  close(value: boolean) {
    this.hideModal.emit(value);
  }

  constructor() {}

  ngOnInit() {
    if (!this.data) {
      this.data = [];
    }
    // console.log("headers", this.headers);
    // console.log("data", this.data);
    // console.log("modaltitle", this.modalTitle);
    // console.log("showmodal", this.showModal);
  }

  callFunction(name: any, param: any) {
    return;
  }
}
