<main class="main login-main" id="top">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    class="svgbackground"
    id="svgbackground"
  >
    <radialGradient id="background-gradient">
      <stop offset="0" stop-color="rgba(0,0,0,0)" />
      <stop offset="1" stop-color="rgba(0,0,0,0)" />
    </radialGradient>
    <rect width="1" height="1" fill="url(#background-gradient)" />
    <g stroke-width="0.00125"></g>
  </svg>

  <div class="container-fluid">
    <div class="row min-vh-100 flex-center g-0">
      <div
        class="col-lg-4 col-xxl-3 py-3 position-relative login-box"
        [ngClass]="{ register: register }"
      >
        <div class="card overflow-hidden z-index-1">
          <div class="card-body p-0">
            <div class="row h-100">
              <div
                class="col-md-12 d-flex flex-center"
                style="flex-direction: column"
              >
                <div class="flex-grow-1" style="width: 100%">
                  <div class="row flex-between-center">
                    <div class="col-12 text-center">
                      <!--<h3>Account Login</h3>-->
                      <img
                        style="
                          display: block;
                          margin: auto;
                          margin-bottom: 20px;
                        "
                        src="../assets/img/swayam_logo_v3.0.svg"
                      />
                    </div>
                  </div>

                  <form
                    *ngIf="!otpRequired && !register && !forgotPassword"
                    [formGroup]="loginForm"
                    (ngSubmit)="login()"
                  >
                    <div class="mb-3">
                      <label class="form-label" for="card-email"
                        >User Name</label
                      >
                      <input
                        class="form-control"
                        id="card-email"
                        formControlName="userName"
                        type="text"
                        required
                        minlength="4"
                        maxlength="50"
                        [(ngModel)]="username"
                      />
                      <div
                        *ngIf="
                          f.userName.invalid &&
                          (f.userName.dirty || f.userName.touched)
                        "
                        class="alert alert-danger"
                      >
                        <div
                          *ngIf="
                            f.userName.errors && f.userName.errors.required
                          "
                        >
                          User Name is required.
                        </div>
                        <div
                          *ngIf="
                            f.userName.errors && f.userName.errors.minlength
                          "
                        >
                          Name must be at least 4 characters long.
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="d-flex justify-content-between">
                        <label class="form-label" for="card-password"
                          >Password</label
                        ><a
                          class="fs--1 btn-link"
                          (click)="forgotPassword = true"
                          >Forgot Password?</a
                        >
                      </div>
                      <input
                        class="form-control"
                        id="card-password"
                        type="password"
                        formControlName="password"
                        [(ngModel)]="password"
                        required
                      />

                      <div
                        *ngIf="
                          f.password.invalid &&
                          (f.password.dirty || f.password.touched)
                        "
                        class="alert alert-danger"
                      >
                        <div
                          *ngIf="
                            f.password.errors && f.password.errors.required
                          "
                        >
                          Password is required.
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <a routerLink="/register">
                        <div class="fs--1 btn-link" style="text-align: center">
                          Register
                        </div>
                      </a>
                    </div>

                    <div class="mb-3">
                      <button
                        class="btn btn-primary d-block w-100 mt-3"
                        type="submit"
                        name="submit"
                        (ngSubmit)="login()"
                      >
                        Log in
                      </button>
                    </div>
                  </form>
                  <div
                    *ngIf="otpRequired && !forgotPassword"
                    style="width: 100%; margin: 0 auto"
                  >
                    <div class="otp_container">
                      <div class="header-icon-container">
                        <i class="fa fa-lock" aria-hidden="true"></i>
                      </div>
                      <div class="small-title">Security check</div>
                      <div class="otp-message">
                        We've sent a 6 characters code to your registered email
                      </div>
                      <div class="otp-description">
                        Please enter the code below, if you cancel you will be
                        logged out.
                      </div>
                      <div class="otp-form-container">
                        <form
                          [formGroup]="otpForm"
                          (ngSubmit)="validateOTP($event)"
                        >
                          <input
                            class="form-control"
                            id="card-email"
                            formControlName="otpText"
                            type="text"
                            required
                            minlength="4"
                            maxlength="50"
                            [(ngModel)]="otpText"
                            (keyup)="validateOTP()"
                            autocomplete="off"
                          />
                          <div
                            *ngIf="
                              o.otpText.invalid &&
                              (o.otpText.dirty || o.otpText.touched)
                            "
                            class="alert alert-danger"
                          >
                            <div
                              *ngIf="
                                o.otpText.errors && o.otpText.errors.required
                              "
                            >
                              OTP is required.
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="otp-description" style="padding-bottom: 0px">
                        Didn't receive your code?
                      </div>
                      <div
                        class="small-title"
                        style="cursor: pointer; padding: 0px"
                        (click)="login()"
                      >
                        Resend Code
                      </div>
                    </div>
                    <!-- <div (click)="otpRequired = false" class="btn btn-light">
                      < Back </div>
                        <form [formGroup]="otpForm" (ngSubmit)="validateOTP()">
                          <div class="mb-3">
                            <label class="form-label" for="card-email">OTP</label>
                            <input class="form-control" id="card-email" formControlName="otpText" type="text" required
                              minlength="4" maxlength="50" [(ngModel)]="otpText" autocomplete="off" />
                            <div *ngIf="
                            o.otpText.invalid &&
                            (o.otpText.dirty || o.otpText.touched)
                          " class="alert alert-danger">
                              <div *ngIf="
                              o.otpText.errors && o.otpText.errors.required
                            ">
                                OTP is required.
                              </div>
                            </div>
                          </div>

                          <div class="mb-3">
                            <button class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit"
                              (ngSubmit)="validateOTP()">
                              Validate
                            </button>
                          </div>
                        </form>
                    </div> -->
                  </div>
                </div>
                <div *ngIf="!otpRequired && forgotPassword" style="width: 100%">
                  <div (click)="forgotPassword = false" class="btn btn-light">
                    < Back
                  </div>
                  <forgot-password
                    (hideModel)="forgotPassword = false"
                  ></forgot-password>
                </div>
              </div>
              <div *ngIf="!otpRequired && register" style="width: 100%">
                <div (click)="register = false" class="btn btn-light">
                  < Back
                </div>
                <register-page (hideModel)="register = false"></register-page>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<table
  style="display: none; margin-left: auto; margin-right: auto"
  class="noborder"
>
  <tbody>
    <tr>
      <td><label for="number-nodes">Number of nodes:</label></td>
      <td>
        <input
          type="number"
          min="1"
          max="300"
          step="1"
          value="50"
          id="number-nodes"
          style="width: 5em"
        />
      </td>
    </tr>
    <tr>
      <td><label for="extra-edges">Extra edges:</label></td>
      <td>
        <input
          type="number"
          min="0"
          max="1000"
          step="any"
          value="20"
          id="extra-edges"
          style="width: 5em"
        />%
      </td>
    </tr>
    <tr>
      <td><label for="network-style">Network style:</label></td>
      <td>
        <select id="network-style">
          <option value="0.0">Mesh</option>
          <option value="0.5" selected="selected">Balanced</option>
          <option value="1.0">Hub-and-spoke</option>
        </select>
      </td>
    </tr>
    <tr>
      <td><label for="drift-speed">Drift speed:</label></td>
      <td>
        <input
          type="number"
          min="0"
          max="100"
          step="any"
          value="1"
          id="drift-speed"
          style="width: 5em"
        />
      </td>
    </tr>
    <tr>
      <td><label for="repulsion-force">Repulsion force:</label></td>
      <td>
        <input
          type="number"
          min="0"
          max="100"
          step="any"
          value="1"
          id="repulsion-force"
          style="width: 5em"
        />
      </td>
    </tr>
  </tbody>
</table>
<!-- authentication Popup
<div class="modal fade" id="authentication-modal" tabindex="-1" role="dialog" aria-labelledby="authentication-modal-label" aria-hidden="true">
  <div class="modal-dialog mt-6" role="document">
    <div class="modal-content border-0">
      <div class="modal-header px-5 position-relative modal-shape-header bg-shape">
        <div class="position-relative z-index-1 light">
          <h4 class="mb-0 text-white" id="authentication-modal-label">Register</h4>
          <p class="fs--1 mb-0 text-white">Please create your free Falcon account</p>
        </div><button class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body py-4 px-5">
        <form>
          <div class="mb-3"><label class="form-label" for="modal-auth-name">Name</label><input class="form-control" type="text" autocomplete="on" id="modal-auth-name" /></div>
          <div class="mb-3"><label class="form-label" for="modal-auth-email">Email address</label><input class="form-control" type="email" autocomplete="on" id="modal-auth-email" /></div>
          <div class="row gx-2">
            <div class="mb-3 col-sm-6"><label class="form-label" for="modal-auth-password">Password</label><input class="form-control" type="password" autocomplete="on" id="modal-auth-password" /></div>
            <div class="mb-3 col-sm-6"><label class="form-label" for="modal-auth-confirm-password">Confirm Password</label><input class="form-control" type="password" autocomplete="on" id="modal-auth-confirm-password" /></div>
          </div>
          <div class="form-check"><input class="form-check-input" type="checkbox" id="modal-auth-register-checkbox" /><label class="form-label" for="modal-auth-register-checkbox">I accept the <a href="#!">terms </a>and <a href="#!">privacy policy</a></label></div>
          <div class="mb-3"><button class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Register</button></div>
        </form>
        <div class="position-relative mt-5">
          <hr class="bg-300" />
          <div class="divider-content-center">or register with</div>
        </div>
        <div class="row g-2 mt-2">
          <div class="col-sm-6"><a class="btn btn-outline-google-plus btn-sm d-block w-100" href="#"><span class="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span> google</a></div>
          <div class="col-sm-6"><a class="btn btn-outline-facebook btn-sm d-block w-100" href="#"><span class="fab fa-facebook-square me-2" data-fa-transform="grow-8"></span> facebook</a></div>
        </div>
      </div>
    </div>
  </div>
</div>
  -->

<div
  class="modal show"
  tabindex="-1"
  *ngIf="showpopOTP"
  style="display: block; top: 20%"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Verify Your Login!</h5>
      </div>
      <div class="modal-body">
        <h2>Please enter the OTP sent to your registered email id.</h2>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">OTP</label>
          <input
            type="email"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="80%"
            [(ngModel)]="otp"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="loginOPT()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<style>
  .login-main {
    background-image: url("../../assets/img/networkb.jpg");
    background-size: cover;
    color: #fff;
  }

  .login-box .card {
    /* background:transparent;*/
    background: rgba(255, 255, 255, 0.03);
  }

  .btn-link {
    color: #fff;
  }

  .svgbackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
</style>
