<div class="card" *ngIf="!innerData">
  <h5 class="card-header">Security Group</h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->
    <div style="display: flex; align-items: center; justify-content: flex-end;" *ngIf="sgList.length > 0">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="export()">Export</button>
    </div>
    <table class="table table-docs table-hover" id="sgList">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of sgheader">{{ h.name }}</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': sgList.length > 0 ? 'table-row-group' : 'none'}"
        *ngFor="let i of sgList; let j = index">
        <tr>
          <td>{{ j + 1 }}</td>
          <td *ngFor="let h of sgheader">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span>
                {{ i[h.id] }}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{ i[h.id] }}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': sgList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="sgheader.length + 1">
            <div style="text-align: center;">No Security Groups Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="card" *ngIf="innerData">
  <div class="card">
    <h5 class="card-header">
      <button class="btn btn-light" (click)="goBack()">
        < Back</button>
          <span>Security Group - {{ innerData.sg_name }}</span>
    </h5>
    <div class="card-body">
      <div class="card">
        <h5 class="card-header">
          <span>Details</span>
        </h5>
        <div class="card-body">
          <div class="panel panel-default">
            <div class="panel-heading" style="font-size: medium"></div>

            <div class="panel-body">
              <div class="row">
                <div class="col-md-6">
                  <b>Security Group Name</b>
                  <p id="eventname">
                    {{ innerData.sg_name }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Security Group ID</b>
                  <p id="eventid">{{ innerData.sg_id }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>VPC ID</b>
                  <p id="readonly">{{ innerData.vpc_id }}</p>
                </div>
                <div class="col-md-6">
                  <b>Inbound Rules Count</b>
                  <p id="eventsource">{{ innerData.inbound_count }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Outbound Rules Count</b>
                  <p id="aws_access_key">{{ innerData.outbound_count }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <button class="nav-link active" id="inbound-rules-tab" data-bs-toggle="tab"
                data-bs-target="#inbound-rules" type="button" role="tab" aria-controls="inbound-rules"
                aria-selected="true">
                Inbound Rules ({{innerData.inbound_count}})
              </button>
              <button class="nav-link" id="outbound-rules-tab" data-bs-toggle="tab" data-bs-target="#outbound-rules"
                type="button" role="tab" aria-controls="outbound-rules" aria-selected="false">
                Outbound Rules ({{innerData.outbound_count}})
              </button>
            </div>
          </nav>

          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="inbound-rules" role="tabpanel"
              aria-labelledby="inbound-rules-tab">
              <div class="card" style="border-top: none !important">
                <!--<h5 class="card-header"></h5>-->

                <div class="card-body">
                  <!--<h5 class="card-title">Case Details</h5>-->

                  <table class="table table-docs table-hover">
                    <thead>
                      <tr>
                        <th>S.N.</th>
                        <th *ngFor="let h of sgInnerHeader">{{ h.name }}</th>
                      </tr>
                    </thead>

                    <tbody *ngFor="let i of innerData.inbound_rules; let j = index">
                      <tr>
                        <td>{{ j + 1 }}</td>
                        <td *ngFor="let h of sgInnerHeader">
                          <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                            <span>
                              {{ i[h.id] }}
                            </span>
                          </span>
                          <span *ngIf="!h.click">
                            {{ i[h.id] }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                    <tbody [ngStyle]="{'display': innerData.inbound_rules.length == 0 ? 'table-row-group' : 'none'}">
                      <tr>
                        <td [attr.colspan]="sgInnerHeader.length + 1">
                          <div style="text-align: center;">No Inbound Rules Found</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="outbound-rules" role="tabpanel" aria-labelledby="outbound-rules-tab">
              <div class="card" style="border-top: none !important">
                <!--<h5 class="card-header"></h5>-->
                <div class="card-body">
                  <!--<h5 class="card-title">Case Details</h5>-->

                  <table class="table table-docs table-hover">
                    <thead>
                      <tr>
                        <th>S.N.</th>
                        <th *ngFor="let h of sgInnerHeader">{{ h.name }}</th>
                      </tr>
                    </thead>

                    <tbody [ngStyle]="{'display': innerData.outbound_rules.length > 0 ? 'table-row-group' : 'none'}"
                      *ngFor="let i of innerData.outbound_rules; let j = index">
                      <tr>
                        <td>{{ j + 1 }}</td>
                        <td *ngFor="let h of sgInnerHeader">
                          <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                            <span>
                              {{ i[h.id] }}
                            </span>
                          </span>
                          <span *ngIf="!h.click">
                            {{ i[h.id] }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                    <tbody [ngStyle]="{'display': innerData.outbound_rules.length == 0 ? 'table-row-group' : 'none'}">
                      <tr>
                        <td [attr.colspan]="sgInnerHeader.length + 1">
                          <div style="text-align: center;">No Outbound Rules Found</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>