import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
import { regions } from './../../../../dash/dash.component';

declare let $: any;

@Component({
  selector: 'update-bom',
  templateUrl: './update.bom.component.html',
  styleUrls: ['./update.bom.component.css'],
})
export class UpdateBOMComponent implements OnInit, OnDestroy {
  @Input('bom') bom: any;
  @Input('action') action: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  instanceTypes: any = [];
  billingTypes: any = [];
  regionId: any;
  regions: any = [];
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.bom = { ...this.bom };
    this.regions = regions;
    $('#instanceTypes').selectpicker();
    $('#billingTypes').selectpicker();
    $('#platformsList').selectpicker();
    setTimeout(() => {
      $('#regions_list').selectpicker();
    }, 100);
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.load();
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.load();
        this.regionId = d.value;
      }
    });
  }

  async load() {
    await this.fetchInstanceTypes();
    await this.fetchBillingTypes();
    await this.fetchPlatformsTypes();
    await this.getEC2Pricing(this.bom);
  }

  async changeInstanceType(bom: any) {
    await this.getEC2Pricing(bom);
    await this.fetchCPURAM(bom);
  }

  async updateEntry() {
    if (this.action == 'local') {
      this.close(true);
      return;
    }
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      action: this.action,
      instanceType: this.bom.instanceType,
      region: this.regionId,
      totalCost: this.getTotal(),
      hourlyCost: this.bom.hourlyCost,
      os: this.bom.os,
      cpu: this.bom.cpu,
      disk: this.bom.disk,
      quantity: this.bom.quantity,
      ram: this.bom.ram,
      billingType: this.bom.billingType,
      usageHours: this.bom.usageHours,
      id: this.action == 'update' ? this.bom.uuid : undefined,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      if (this.action == 'add') {
        this.close();
      } else {
        this.close(true);
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
      this.notifier.loading(false);
    }
  }

  getTotal() {
    let total =
      (isNaN(this.bom['hourlyCost']) ? 0 : Number(this.bom['hourlyCost'])) *
      (isNaN(this.bom['quantity']) ? 0 : Number(this.bom['quantity'])) *
      (isNaN(this.bom['usageHours']) ? 0 : Number(this.bom['usageHours']));
    return total.toFixed(2);
  }

  async fetchCPURAM(value: any) {
    if (value['instanceType'] == '') {
      return;
    }
    this.notifier.loading(true);
    let data = {
      action: 'fetchCpuRam',
      instanceType: value['instanceType'],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      value['cpu'] = result.cpu;
      value['ram'] = result.ram;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async getEC2Pricing(value: any) {
    if (
      value['instanceType'] == '' ||
      value['os'] == '' ||
      value['billingType'] == ''
    ) {
      return;
    }
    this.notifier.loading(true);
    let data = {
      action: 'fetchRiPrice',
      region: value['region'],
      accountId: this.accountId,
      instanceType: value['instanceType'],
      os: value['os'],
      riType: value['billingType'],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2pricing`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      value['hourlyCost'] = result.onDemandCost.toString();
      value['totalCost'] =
        value['hourlyCost'] *
        (isNaN(value['quantity']) ? 0 : Number(value['quantity'])) *
        (isNaN(value['usageHours']) ? 0 : Number(value['usageHours']));
      value['totalCost'] = Number(value['totalCost']).toFixed(2);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  platformTypes: any = [];
  async fetchPlatformsTypes() {
    this.notifier.loading(true);
    let data = { action: 'fetchPlatformDetails', accountId: this.accountId };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.platformTypes = result.billingTypeList;
      setTimeout(() => {
        $('#platformsList').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  close(sendData: boolean = false) {
    if (sendData) {
      this.hideModel.emit(this.bom);
    } else {
      this.hideModel.emit(false);
    }
  }

  async fetchInstanceTypes() {
    this.notifier.loading(true);
    let data = { action: 'fetchInstanceTypes' };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.instanceTypes = result.instanceTypeList;
      setTimeout(() => {
        $('#instanceTypes').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  async fetchBillingTypes() {
    this.notifier.loading(true);
    let data = { accountId: this.accountId, action: 'fetchBillingType' };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.billingTypes = result.billingTypeList;
      setTimeout(() => {
        $('#billingTypes').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }
}
