import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

import * as moment from 'moment';

declare let $: any;
declare let window: any;

@Component({
  templateUrl: 'billingdashboard.component.html',
  styleUrls: ['./billingdashboard.component.css'],
})
export class BillingDashboardComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  accountId: any;
  regionId: any;
  currentMessage: any;
  selectedLinkedAccount: any;

  masterAccounts: any = [];

  loading: any = {
    master: true,
  };
  dateRange: any = '';

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    setTimeout(() => {
      $('#masterAccountSelect').selectpicker();
    }, 100);
    this.dateRange = moment().startOf('month').format('MM-YYYY');
    $('#datetimepicker').val(moment().startOf('month').format('MM-YYYY'));
    setTimeout(() => {
      $('#datetimepicker')
        .datepicker({
          autoclose: true,
          minViewMode: 1,
          format: 'mm-yyyy',
          orientation: 'bottom auto',
          endDate: new Date(),
        })
        .on('changeDate', function (selected: any) {
          window.angularComponentReference.zone.run(() => {
            window.angularComponentReference.componentFn();
          });
        });
    }, 100);

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      this.masterAccounts = [];
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) => {
        this.load(false);
      },
      component: this,
    };
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  services: any = [];
  headers: any = [];
  selectedMasterAccount: any;

  async load(fetch: boolean = true) {
    this.appended = [];
    this.responseData = {};
    this.masterAccounts = [];
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        this.headers = [];
        if (fetch) {
          this.loading['master'] = true;
        }

        selectedAccountData.data.forEach(
          async (account: any, index: number) => {
            this.loading[account.accountId] = true;
            this.headers.push({
              id: account.accountId,
              name: `${account.accountName} (${account.accountId})`,
            });
            if (fetch) {
              this.loading['maintotal'] = true;
              await this.fetchMasterAccount(account.accountId);
              if (index == 0) {
                this.selectedMasterAccount = this.masterAccounts[0];
              }
            } else {
              this.loading['master'] = false;
            }
            this.fetchMonthlyData(
              this.selectedMasterAccount,
              account.accountId
            );
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
    this.loading['maintotal'] = false;
    this.loading['master'] = false;
  }

  toFixed(value: number | number, decimal: number) {
    return value.toFixed(decimal);
  }

  getSelectedMonthYear() {
    let monthYear = moment().startOf('month').format('YYYY-MM');

    let dRange = $('#datetimepicker').val();
    if (dRange) {
      monthYear = moment(dRange, 'MM-YYYY').format('YYYY-MM');
    }

    return monthYear;
  }

  fetchTotalBillService(service: string) {
    let total: number = 0;
    Object.keys(this.responseData).forEach((h: any) => {
      if (this.responseData[h] && this.responseData[h][service]) {
        total += this.responseData[h][service]['finalTotalBill'];
      }
    });
    return total;
  }

  fetchTotalAccountService(h: string) {
    let total: number = 0;
    if (!this.responseData[h]) {
      return 0.0;
    }
    Object.keys(this.responseData[h]).forEach((service: any) => {
      if (this.responseData[h] && this.responseData[h][service]) {
        total += this.responseData[h][service]['finalTotalBill'];
      }
    });
    return total;
  }

  fetchFinalCost() {
    let total: number = 0;
    Object.keys(this.responseData).forEach((account: any) => {
      Object.keys(this.responseData[account]).forEach((h: any) => {
        total += this.responseData[account][h]['finalTotalBill'];
      });
    });
    return total.toFixed(2);
  }

  async fetchMasterAccount(account: any = this.accountId) {
    let data = {
      lid: [account],
      userType: 'C',
      action: 'fetchMasterAccList',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      if (account) {
        result.masterAccList = result.masterAccList.filter((account: any) => {
          return this.masterAccounts.indexOf(account) < 0;
        });
        this.masterAccounts = [...this.masterAccounts, ...result.masterAccList];
        setTimeout(async () => {
          $('#masterAccountSelect').selectpicker('refresh');
        }, 100);
      } else {
        this.masterAccounts = result.masterAccList;
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.loading['master'] = false;
  }

  async fetchMonthlyData(masterAccId: any, lid: any) {
    let monthYear = moment().startOf('month').format('YYYY-MM');

    let dRange = $('#datetimepicker').val();
    if (dRange) {
      monthYear = moment(dRange, 'MM-YYYY').format('YYYY-MM');
    }
    let data = {
      a: 'fetchMonthlyTotalCost',
      mId: masterAccId,
      lId: lid,
      ml: monthYear,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchdailybillingdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' && result.documentsData.length > 0) {
      this.parsedData(result.documentsData[0], lid);
    }
    this.loading[lid] = false;
  }

  appended: any = [];
  responseData: any = {};
  parsedData(responseData: any, lid: any) {
    this.responseData[lid] = {};
    responseData.components.forEach((service: any) => {
      this.responseData[lid][service.name] = service;
      service['index'] = true;
      if (this.appended.indexOf(service.name) < 0) {
        this.appended.push(service.name);
      }
    });
  }

  // helpers

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel(
      'billing-dashboard',
      `Billing Dashboard - ${this.selectedMasterAccount}`,
      'csv'
    );
  }

  close(event: any) {
    this.selectedLinkedAccount = null;
    $('#masterAccountSelect').selectpicker('refresh');
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        resolve({ error: false, data: selectedAccounts.list });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }
}
