import { Component, OnInit } from '@angular/core';

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './view.reports.component.html',
})
export class viewReportsComponent implements OnInit {
  reportType: String = 'sg';
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  customHeader: any = [
    {
      id: 'instance_name',
      name: 'Host Name',
    },
    {
      id: 'instance_id',
      name: 'Instance ID',
    },
    {
      id: 'latest_ami_name',
      name: 'Latest AMI Name',
    },
    {
      id: 'latest_ami_id',
      name: 'Latest AMI ID',
    },
    {
      id: 'first_ami_friendly',
      name: 'AMI Date',
    },
    {
      id: 'latest_ami_friendly',
      name: 'Last AMI Date',
    },
    {
      id: 'ami_avg_retention',
      name: 'Retention',
    },
    {
      id: 'total_amis',
      name: 'Total AMIs',
    },
  ];
  constructor() {}
  ngOnInit(): void {
    this.reportType = 'sg';
  }
}
