<div class="card h-100 " *ngIf="!showCreateShedule && !showLog">
  <!-- Card header START -->
  <div class="card-header d-sm-flex align-items-center text-center justify-content-sm-between border-0 pb-0">
    <h1 class="h4 card-title">Scheduler Dashboard

    </h1>
    <!-- Button modal -->
    <div>

      <button class="btn btn-primary-soft" (click)="CreateShedule()"> <i class="fa fa-solid fa-plus pe-1"></i> Create
        Schedule</button>
      <span style="color: white;
          padding: 3px;
          margin-right: 10px;
          font-weight: bold;
          cursor: default;
          background: #0a58ca;
          vertical-align: middle;
          font-size: 2em;">BETA</span>
    </div>
  </div>
  <!-- Card header START -->
  <!-- Card body START -->
  <div class="card-body">







    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of scheduleHeaders">
            {{h.name}}
            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,scheduleRules)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
          <th>Log</th>
          <th></th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': scheduleRules.length > 0 ? 'table-row-group': 'none'}"
        *ngFor="let i of scheduleRules; let j = index">
        <tr *ngIf="notifier.filterTableColums(scheduleHeaders,i)">
          <td>{{j+1}}</td>
          <td *ngFor="let h of scheduleHeaders">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span>
                {{h.transform ? h.transform(i, h.id) : i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{h.transform ? h.transform(i, h.id) : i[h.id]}}
            </span>
          </td>
          <td>
            <button class="btn btn-sm btn-transparent" (click)="showLogData(i)"><i class="fa fa-list-ol"></i>
              Log</button>
          </td>
          <td>
            <div class="dropdown">
              <i class="fa fa-ellipsis-vertical fa-fw dropdown-toggle" style="color: #ccc;" id="dropdownMenu2"
                data-bs-toggle="dropdown"></i>

              <!--<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                :
              </button>-->
              <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <li><button class="dropdown-item" type="button" (click)="clone(i)"><i class="fa fa-clone fa-fw"
                      style="color: #0094ff;"></i> Clone</button></li>
                <li><button class="dropdown-item" type="button" (click)="delete(i)"><i class="fa fa-xmark-square fa-fw"
                      style="color: red;"></i> Delete</button></li>
                <li *ngIf="i.status == 'A'"><button class="dropdown-item" type="button" (click)="pause(i)"><i
                      class="fa fa-circle-pause fa-fw" style="color: gray;"></i> Pause</button></li>
                <li *ngIf="i.status == 'P'"><button class="dropdown-item" type="button"
                    (click)="callFunction('showData', i)"><i class="fa fa-circle-pause fa-fw" style="color: gray;"></i>
                    Activate</button></li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': scheduleRules.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="scheduleHeaders.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>




<!--https://social.webestica.com/events.html
<div class="modal fade show" id="modalCreateEvents" tabindex="-1" aria-labelledby="modalLabelCreateEvents" style="display: block;" aria-modal="true" role="dialog" >-->

<div class="modal-dialog modal-dialog-centered" *ngIf="showCreateShedule" style="position:static; top: 3%">
  <div class="modal-content" *ngIf="subView == 'Basic Details'">

    <div class="modal-header">
      <h5 class="modal-title" id="modalLabelCreateEvents">Create Schedule</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="CloseCreateShedule()"></button>
    </div>

    <div class="modal-body">

      <form class="row g-4">
        <!-- Title -->
        <div class="col-12">
          <label class="form-label">Title</label>
          <input type="text" class="form-control" placeholder="Schedule name here" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="cShedule.name">
        </div>
        <!-- Description -->
        <div class="col-12">
          <label class="form-label">Description</label>
          <textarea class="form-control" rows="2" [ngModelOptions]="{standalone: true}" [(ngModel)]="cShedule.desc"
            placeholder="Ex: topics, schedule, etc."></textarea>
        </div>


        <div class="col-12">
          <label class="form-label">Action</label>
          <select class="form-select" [ngModelOptions]="{standalone: true}" [(ngModel)]="cShedule.action">
            <option value="Start">Start</option>
            <option value="Stop">Stop</option>
            <!-- <option value="Backup">Backup</option> -->
          </select>
        </div>
        <!-- Date -->


        <div class="col-sm-12">
          <fieldset class="">
            <legend>Start Date Time</legend>
            <div class="row">
              <div class="col-sm-6">
                <label class="form-label">Date</label>
                <input type="text" class="form-control datetimepicker" id="startDate"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="cShedule.startDate" placeholder="Select Start date"
                  readonly="readonly">
              </div>
              <!-- Time -->
              <div class="col-sm-6">
                <label class="form-label">Time</label>
                <input type="text" class="form-control datetimepickerTime" id="startDateTime" data-enabletime="true"
                  data-nocalendar="true" placeholder="Select Start time" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="cShedule.startDateTime" readonly="readonly">
              </div>
            </div>
          </fieldset>
        </div>



        <div class="col-sm-12">
          <fieldset class="">
            <legend>Trigger</legend>
            <div class="row">

              <div class="col-12">
                <div class="col-12">
                  <label class="form-label">Scheduler Frequency</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="SchedulerFrequency" id="FrequencyEveryDay"
                    (change)="cShedule.rule.frequency = 'EveryDay'" [checked]="cShedule.rule.frequency == 'EveryDay'">
                  <label class="form-check-label" for="FrequencyEveryDay">
                    Daily
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="SchedulerFrequency" id="FrequencyWeekDays"
                    (change)="cShedule.rule.frequency = 'WeekDays'" [checked]="cShedule.rule.frequency == 'WeekDays'">
                  <label class="form-check-label" for="FrequencyWeekDays">
                    Weekly
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="SchedulerFrequency" id="FrequencyDayOfMonth"
                    (change)="cShedule.rule.frequency = 'DayOfMonth'"
                    [checked]="cShedule.rule.frequency == 'DayOfMonth'">
                  <label class="form-check-label" for="FrequencyDayOfMonth">
                    Monthly
                  </label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="SchedulerFrequency" id="FrequencyOnce"
                    (change)="cShedule.rule.frequency = 'Once'" [checked]="cShedule.rule.frequency == 'Once'">
                  <label class="form-check-label" for="FrequencyOnce">
                    One Time
                  </label>
                </div>


              </div>

              <div class="col-12" *ngIf="cShedule.rule.frequency == 'EveryDay'">
                <div class="col-sm-12">

                  <fieldset class="">
                    <legend>Repeat Every</legend>
                    <label class="form-label">Days</label>
                    <input type="number" class="form-control" placeholder="1" [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="cShedule.rule.repeatEvery">
                  </fieldset>
                </div>
              </div>

              <div class="col-12" *ngIf="cShedule.rule.frequency == 'WeekDays'">
                <div class="col-sm-12">
                  <fieldset class="">
                    <legend>Repeat Every</legend>
                    <label class="form-label">Weeks</label>
                    <input type="number" class="form-control" placeholder="1" [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="cShedule.rule.repeatEvery">
                    <div class="col-12">
                      <label class="form-label mt-2">On</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" name="frequencyWeekDay" id="frequencyWeekDay1"
                        (change)="changeWeekDay('Sunday')" [checked]="cShedule.rule.frequencyWeekDay.Sunday">
                      <label class="form-check-label" for="frequencyWeekDay1">
                        Sunday
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" name="frequencyWeekDay" id="frequencyWeekDay2"
                        (change)="changeWeekDay('Monday')" [checked]="cShedule.rule.frequencyWeekDay.Monday">
                      <label class="form-check-label" for="frequencyWeekDay2">
                        Monday
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" name="frequencyWeekDay" id="frequencyWeekDay3"
                        (change)="changeWeekDay('Tuesday')" [checked]="cShedule.rule.frequencyWeekDay.Tuesday">
                      <label class="form-check-label" for="frequencyWeekDay3">
                        Tuesday
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" name="frequencyWeekDay" id="frequencyWeekDay4"
                        (change)="changeWeekDay('Wednesday')" [checked]="cShedule.rule.frequencyWeekDay.Wednesday">
                      <label class="form-check-label" for="frequencyWeekDay4">
                        Wednesday
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" name="frequencyWeekDay" id="frequencyWeekDay5"
                        (change)="changeWeekDay('Thursday')" [checked]="cShedule.rule.frequencyWeekDay.Thursday">
                      <label class="form-check-label" for="frequencyWeekDay5">
                        Thursday
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" name="frequencyWeekDay" id="frequencyWeekDay6"
                        (change)="changeWeekDay('Friday')" [checked]="cShedule.rule.frequencyWeekDay.Friday">
                      <label class="form-check-label" for="frequencyWeekDay6">
                        Friday
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" name="frequencyWeekDay" id="frequencyWeekDay7"
                        (change)="changeWeekDay('Saturday')" [checked]="cShedule.rule.frequencyWeekDay.Saturday">
                      <label class="form-check-label" for="frequencyWeekDay7">
                        Saturday
                      </label>
                    </div>

                  </fieldset>
                </div>
              </div>

              <div class="col-12" *ngIf="cShedule.rule.frequency == 'DayOfMonth'">
                <div class="col-sm-12">

                  <fieldset class="">
                    <legend>Repeat Every</legend>

                    <label class="form-label">Month</label>

                    <input type="number" class="form-control" placeholder="1" [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="cShedule.name">

                    <div class="col-12">
                      <label class="form-label mt-2">On</label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="monthyRepeatDays" id="monthyRepeatDays" checked
                        (change)="setMonthRepeat('Days')" [checked]="cShedule.rule.monthyRepeat == 'Days'">
                      <label class="form-check-label" for="monthyRepeatDays">
                        Days
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="monthyRepeatDayOfMonth"
                        id="monthyRepeatDayOfMonth" (change)="setMonthRepeat('DayOfMonth')"
                        [checked]="cShedule.rule.monthyRepeat == 'DayOfMonth'">
                      <label class="form-check-label" for="monthyRepeatDayOfMonth">
                        Day Of Month
                      </label>
                    </div>

                    <fieldset class="" *ngIf="cShedule.rule.monthyRepeat == 'Days'">
                      <legend>Days</legend>
                      <div class="col-12">
                        <div class="form-check form-check-inline" *ngFor="let item of dayOfMonth; let i = index">
                          <input class="form-check-input" type="checkbox" name="monthyRepeatDaysT"
                            id="monthyRepeatDaysT{{i}}" (change)="onChangePush(cShedule.rule.dayOfMonth, item)"
                            [checked]="cShedule.rule.dayOfMonth.indexOf(item) != -1">
                          <label class="form-check-label" for="monthyRepeatDaysT{{i}}">
                            {{item}}
                          </label>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="" *ngIf="cShedule.rule.monthyRepeat == 'DayOfMonth'">
                      <legend>On</legend>

                      <!--<div class="col-12">
                <label class="form-label">On</label>  <br />-->
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="onDayCFirst" id="onDayCFirst"
                          (change)="changeMonthDay('First')" [checked]="cShedule.rule.frequencyMonthDay.First">
                        <label class="form-check-label" for="onDayCFirst">
                          First
                        </label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="onDayCSecond" id="onDayCSecond"
                          (change)="changeMonthDay('Second')" [checked]="cShedule.rule.frequencyMonthDay.Second">
                        <label class="form-check-label" for="onDayCSecond">
                          Second
                        </label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="onDayCThird" id="onDayCThird"
                          (change)="changeMonthDay('Third')" [checked]="cShedule.rule.frequencyMonthDay.Third">
                        <label class="form-check-label" for="onDayCThird">
                          Third
                        </label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="onDayCFourth" id="onDayCFourth"
                          (change)="changeMonthDay('Fourth')" [checked]="cShedule.rule.frequencyMonthDay.Fourth">
                        <label class="form-check-label" for="onDayCFourth">
                          Fourth
                        </label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="onDayCLast" id="onDayCLast"
                          (change)="changeMonthDay('Last')" [checked]="cShedule.rule.frequencyMonthDay.Last">
                        <label class="form-check-label" for="onDayCLast">
                          Last
                        </label>
                      </div>

                    </fieldset>
                    <fieldset class="" *ngIf="cShedule.rule.monthyRepeat == 'DayOfMonth'">
                      <legend>Week Days</legend>



                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="onDayCWeekDays" id="onDayCSunday"
                          (change)="changeWeekDay('Sunday')" [checked]="cShedule.rule.frequencyWeekDay.Sunday">
                        <label class="form-check-label" for="onDayCSunday">
                          Sunday
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="onDayCWeekDays" id="onDayCMonday"
                          (change)="changeWeekDay('Monday')" [checked]="cShedule.rule.frequencyWeekDay.Monday">
                        <label class="form-check-label" for="onDayCMonday">
                          Monday
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="onDayCWeekDays" id="onDayCTuesday"
                          (change)="changeWeekDay('Tuesday')" [checked]="cShedule.rule.frequencyWeekDay.Tuesday">
                        <label class="form-check-label" for="onDayCTuesday">
                          Tuesday
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="onDayCWeekDays" id="onDayCWednesday"
                          (change)="changeWeekDay('Wednesday')" [checked]="cShedule.rule.frequencyWeekDay.Wednesday">
                        <label class="form-check-label" for="onDayCWednesday">
                          Wednesday
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="onDayCWeekDays" id="onDayCThursday"
                          (change)="changeWeekDay('Thursday')" [checked]="cShedule.rule.frequencyWeekDay.Thursday">
                        <label class="form-check-label" for="onDayCThursday">
                          Thursday
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="onDayCWeekDays" id="onDayCFriday"
                          (change)="changeWeekDay('Friday')" [checked]="cShedule.rule.frequencyWeekDay.Friday">
                        <label class="form-check-label" for="onDayCFriday">
                          Friday
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="onDayCWeekDays" id="onDayCSaturday"
                          (change)="changeWeekDay('Saturday')" [checked]="cShedule.rule.frequencyWeekDay.Saturday">
                        <label class="form-check-label" for="onDayCSaturday">
                          Saturday
                        </label>
                      </div>

                    </fieldset>
                  </fieldset>
                </div>
              </div>
              <!--<div class="col-12 mt-2" *ngIf="cShedule.rule.frequency == 'Once'">
        <div class="col-sm-12">
          <fieldset class="">
            <legend>Start Date Time</legend>
            <div class="row">
              <div class="col-sm-6">
                <label class="form-label">Date</label>
                <input type="text" class="form-control datetimepicker"
                       [ngModelOptions]="{standalone: true}" [(ngModel)]="cShedule.startDate" placeholder="Select Start date" readonly="readonly">
              </div>-->
              <!-- Time -->
              <!--<div class="col-sm-6">
                  <label class="form-label">Time</label>
                  <input type="text" class="form-control datetimepickerTime"
                         data-enabletime="true" data-nocalendar="true" placeholder="Select Start time"
                         [ngModelOptions]="{standalone: true}" [(ngModel)]="cShedule.startDateTime"
                         readonly="readonly">
                </div>
              </div>
            </fieldset>
          </div>
        </div>-->
            </div>
          </fieldset>
        </div>
        <div class="col-sm-12" *ngIf="cShedule.rule.frequency != 'Once'">
          <fieldset class="">
            <legend>End Date Time</legend>
            <div class="row">
              <div class="col-sm-6">
                <label class="form-label">Date</label>
                <input type="text" class="form-control datetimepicker" id="endDate"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="cShedule.endDate" placeholder="Select End Date"
                  readonly="readonly">
              </div>
              <!-- Time -->
              <div class="col-sm-6">
                <label class="form-label">Time</label>
                <input type="text" class="form-control datetimepickerTime" id="endDateTime" data-enabletime="true"
                  data-nocalendar="true" placeholder="Select End time" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="cShedule.endDateTime" readonly="readonly">
              </div>
            </div>
          </fieldset>
        </div>

        <div class="col-sm-12" *ngIf="cShedule.action == 'Backup'">
          <fieldset class="">
            <legend>Backup Retention period</legend>
            <div class="row">
              <div class="col-sm-6">

                <select class="form-select" [ngModelOptions]="{standalone: true}" [(ngModel)]="cShedule.retention.unit">
                  <option value="Always">Always</option>
                  <option value="Days">Days</option>
                  <option value="Weeks">Weeks</option>
                  <option value="Months">Months</option>
                  <option value="Years">Years</option>
                </select>
              </div>
              <!-- Time -->
              <div class="col-sm-6">
                <input type="number" class="form-control" min="1" step="1" *ngIf="cShedule.retention.unit != 'Always'"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="cShedule.retention.value">
              </div>
            </div>
          </fieldset>
        </div>

        <div class="col-12">
          <button type="button" class="btn btn-primary-soft pull-right" (click)="showCalculatedSchedule()"
            *ngIf="cShedule._id != '0'"><i class="fa fa-calculator"></i> View Calculated Schedule</button>
          <!--<input type="text" class="form-control" placeholder="1hr 23m">-->
          <table class="table table-docs table-hover" *ngIf="scheduleMaps && scheduleMaps.length > 0">
            <tr>
              <th></th>
              <th width="175">Action Time</th>
              <th width="75">Action</th>
              <th width="75">Status</th>
              <th>Target</th>
            </tr>
            <tr *ngFor="let item of scheduleMaps;let i = index">
              <td>{{i + 1}}</td>
              <td>{{convertToIST(item.actionTime)}}</td>
              <td>{{item.action}}</td>
              <td>{{item.status}}</td>
              <td>
                <!--<pre>{{convetToString(item.target)}}</pre>-->

                <div class="col-12" *ngFor="let region of item.target">
                  <fieldset class="mb-3">
                    <legend style="font-weight:bold;">{{region.regionName}}</legend>


                    <fieldset class="" *ngIf="region.Tag && region.Tag.length > 0">
                      <legend>Tags</legend>

                      <div class="col-12" *ngFor="let i of region.Tags">
                        <button class="btn btn-outline-info m-1">{{i}}</button>
                      </div>
                    </fieldset>

                    <fieldset class="mb-3" *ngIf="region.EC2 && region.EC2.length > 0">
                      <legend>EC2</legend>
                      <table class="table table-docs table-hover ">
                        <tr>
                          <!--<th></th>-->
                          <th>Instance Id</th>
                          <th>Name</th>
                          <th>Instance Type</th>
                          <th>Private IP / Public IP</th>
                          <th>Instance Status</th>
                        </tr>
                        <tr *ngFor="let item of region.EC2D;let i = index">
                          <!--<td> <button class="btn btn-danger-soft" (click)="mapInstanceToTarget(item)"><i class="fa fa-remove"></i> Remove</button></td>-->
                          <td>{{item.instanceId}}</td>
                          <td>{{item.tagName}}</td>
                          <td>{{item.instanceType}}</td>
                          <td>{{item.privateIp}}{{item.publicIp ? " / " + item.publicIp : ""}}</td>
                          <td>
                            <div class='status_div'
                              [ngClass]="{'btn-danger-soft': item.instanceStatus == 'stopped', 'btn-success-soft': item.instanceStatus == 'running'}"
                              style="width:98px;">
                              <div class='status_indicator'></div>
                              <div class='status_text'>{{item.instanceStatus}}</div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </fieldset>

                    <fieldset class="" *ngIf="region.RDS && region.RDS.length > 0">
                      <legend>RDS</legend>
                      <table class="table table-docs table-hover ">
                        <tr>
                          <th></th>
                          <th>DB Instance Id</th>
                          <th>DB Engine</th>
                          <th>Instance Type</th>
                          <th>Instance Status</th>
                        </tr>
                        <tbody *ngFor="let item of region.RDSD;let i = index" id="{{item.dbInstanceIdentifier}}">
                          <tr>
                            <td>
                              <button class="btn btn-danger-soft" (click)="mapInstanceToTarget(item)"><i
                                  class="fa fa-remove"></i> Remove</button>
                            </td>
                            <td>{{item.dbInstanceIdentifier}}</td>
                            <td>{{item.dbEngine}}</td>
                            <td>{{item.dbInstanceType}}</td>
                            <td>
                              <div class='status_div'
                                [ngClass]="{'btn-danger-soft': item.dbStatus == 'stopped', 'btn-success-soft': item.dbStatus == 'running'}"
                                style="width:98px;">
                                <div class='status_indicator'></div>
                                <div class='status_text'>{{item.dbStatus}}</div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                  </fieldset>
                </div>

              </td>
            </tr>
          </table>
        </div>

        <div class="col-12">
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
              <div class="me-2">
                <label class="mb-0 form-label" style="color: #797c80 ">Enabled</label>
              </div>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked8" checked="">
              </div>
            </li>
          </ul>


        </div>

        <!-- Duration -->
        <!--<div class="col-sm-4">
    <label class="form-label">Duration</label>
    <input type="text" class="form-control" placeholder="1hr 23m">
  </div>-->
        <!-- Location -->
        <!--<div class="col-12">
    <label class="form-label">Location</label>
    <input type="text" class="form-control" placeholder="Logansport, IN 46947">
  </div>-->
        <!-- Add guests -->
        <!--<div class="col-12">
    <label class="form-label">Add guests</label>
    <input type="email" class="form-control" placeholder="Guest email">
  </div>-->
        <!-- Dropzone photo START -->
        <!--<div class="mb-3">
    <label class="form-label">Upload attachment</label>
    <div class="dropzone dropzone-default card shadow-none dz-clickable" data-dropzone="{&quot;maxFiles&quot;:2}">
      <div class="dz-message">
        <i class="bi bi-file-earmark-text display-3"></i>
        <p>Drop presentation and document here or click to upload.</p>
      </div>
    </div>
  </div>-->
        <!-- Dropzone photo END -->
      </form>
      <!-- Form END -->
    </div>
    <!-- Modal feed body END -->
    <!-- Modal footer -->
    <!-- Button -->
    <div class="modal-footer">
      <button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal" (click)="CloseCreateShedule()">
        Cancel</button>
      <button type="button" class="btn btn-primary-soft" (click)="createShedule(true)" *ngIf="cShedule._id == '0'">Save
        Draft</button>
      <!--<button type="button" class="btn btn-primary-soft" (click)="createShedule()" *ngIf="cShedule._id != '0'">Save</button>-->

      <button type="button" class="btn btn-primary-soft" (click)="createShedule()" *ngIf="cShedule._id != '0'">
        <span *ngIf="cShedule.status == 'P'">
          Activate
        </span>
        <span *ngIf="cShedule.status != 'P'">
          Save
        </span>
      </button>

      <button type="button" class="btn btn-success-soft" (click)="showViewTargets()">Next : Select Targets</button>

    </div>

    <div class="alert alert-warning d-flex align-items-center" role="alert">
      <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:">
        <use xlink:href="#exclamation-triangle-fill" />
      </svg>
      <div>
        New/Edit events can take upto 10 minutes for the changes to come into force
      </div>
    </div>

  </div>


  <div class="modal-content" *ngIf="subView == 'Select Targets'">

    <div class="modal-header">
      <h5 class="modal-title" id="modalLabelCreateEvents">Select Targets</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="CloseCreateShedule()"></button>
    </div>

    <div class="modal-body">


      <fieldset class="">
        <legend>Selected Targets</legend>

        <div class="col-12" *ngFor="let region of cShedule.target">
          <fieldset class="mb-3">
            <legend style="font-weight:bold;">{{region.regionName}}</legend>


            <fieldset class="" *ngIf="region.Tag && region.Tag.length > 0">
              <legend>Tags</legend>

              <div class="col-12" *ngFor="let i of region.Tags">
                <button class="btn btn-outline-info m-1">{{i}}</button>
              </div>
            </fieldset>

            <fieldset class="mb-3" *ngIf="region.EC2 && region.EC2.length > 0">
              <legend>EC2</legend>

              <!--<div class="col-12" *ngFor="let i of region.EC2">
                <button class="btn btn-outline-info m-1">{{i}}</button>
              </div>-->

              <table class="table table-docs table-hover ">
                <tr>
                  <th></th>
                  <th>Instance Id</th>
                  <th>Name</th>
                  <th>Instance Type</th>
                  <th>Private IP / Public IP</th>
                  <!--<th>Instance Status</th>-->
                </tr>
                <tr *ngFor="let item of region.EC2D;let i = index">
                  <td> <button class="btn btn-danger-soft" (click)="mapInstanceToTarget(item)"><i
                        class="fa fa-remove"></i> Remove</button></td>
                  <td>{{item.instanceId}}</td>
                  <td>{{item.tagName}}</td>
                  <td>{{item.instanceType}}</td>
                  <td>{{item.privateIp}}{{item.publicIp ? " / " + item.publicIp : ""}}</td>
                  <!--<td>
                    <div class='status_div' [ngClass]="{'btn-danger-soft': item.instanceStatus == 'stopped', 'btn-success-soft': item.instanceStatus == 'running'}"
                         style="width:98px;">
                      <div class='status_indicator'></div>
                      <div class='status_text'>{{item.instanceStatus}}</div>
                    </div>
                  </td>-->
                </tr>
              </table>
            </fieldset>

            <fieldset class="" *ngIf="region.RDS && region.RDS.length > 0">
              <legend>RDS</legend>
              <!--<div class="col-12" *ngFor="let i of region.RDS">
                <button class="btn btn-outline-info m-1">{{i}}</button>
              </div>-->
              <table class="table table-docs table-hover ">
                <tr>
                  <th></th>
                  <th>DB Instance Id</th>
                  <th>DB Engine</th>
                  <th>Instance Type</th>
                  <!--<th>Availability Zone</th>-->
                  <!--<th>Status Check</th>-->
                  <!--<th>Private IP / Public IP</th>-->
                  <th>Instance Status</th>
                </tr>
                <tbody *ngFor="let item of region.RDSD;let i = index" id="{{item.dbInstanceIdentifier}}">
                  <tr>
                    <td>
                      <button class="btn btn-danger-soft" (click)="mapInstanceToTarget(item)"><i
                          class="fa fa-remove"></i> Remove</button>
                    </td>
                    <td>{{item.dbInstanceIdentifier}}</td>
                    <td>{{item.dbEngine}}</td>
                    <td>{{item.dbInstanceType}}</td>
                    <!--<td>{{item.privateIp}}{{item.publicIp ? " / " + item.publicIp : ""}}</td>-->
                    <td>
                      <div class='status_div'
                        [ngClass]="{'btn-danger-soft': item.dbStatus == 'stopped', 'btn-success-soft': item.dbStatus == 'running'}"
                        style="width:98px;">
                        <div class='status_indicator'></div>
                        <div class='status_text'>{{item.dbStatus}}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </fieldset>
        </div>
      </fieldset>


      <div class="col-sm-12">
        <fieldset class="">
          <legend>Target</legend>
          <div class="row">
            <div class="col-12">

              <div class="col-12">
                <label>Select Region : </label>
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{selectedRegion}}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li *ngFor="let i of regions">
                    <span class="dropdown-item" (click)="setRegion(i)">{{i.name}}</span>
                  </li>
                </ul>
              </div>



              <div class="col-12">
                <label class="form-label">Target Type</label>
              </div>

              <!--<div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"
                       name="targetTypeRadio" id="targetTypeRadio1"
                       (change)="changeTragetType('Tags')" [checked]="cShedule.targetType == 'Tags'">
                <label class="form-check-label" for="targetTypeRadio1">
                  Tags
                </label>
              </div>-->

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="targetTypeRadio" id="targetTypeRadi2"
                  (change)="changeTragetType('EC2')" [checked]="cShedule.targetType == 'EC2'">
                <label class="form-check-label" for="targetTypeRadi2">
                  EC2
                </label>
              </div>

              <!--<div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"
                       name="targetTypeRadio" id="targetTypeRadi3"
                       (change)="changeTragetType('RDS')" [checked]="cShedule.targetType == 'RDS'">
                <label class="form-check-label" for="targetTypeRadi3">
                  RDS
                </label>
              </div>-->

            </div>
          </div>


          <div class="col-12" *ngIf="cShedule.targetType == 'EC2'">
            <div class="col-sm-12">

              <fieldset class="">
                <legend>EC2</legend>

                <!--<div class="form-check form-check-inline" *ngFor="let item of instancesList;let i = index">
                  <input class="form-check-input" type="checkbox"
                         name="ec2" id="EC2{{i}}"
                         (change)="mapInstanceToTarget(item.instanceId)" [checked]="checkIf(item.instanceId)">
                  <label class="form-check-label" for="EC2{{i}}">
                    {{item.tagName}}
                  </label>
                </div>-->

                <div class="alert alert-primary" role="alert" *ngIf="!instancesList || instancesList.length == 0">
                  No EC2 Instance Found!
                </div>

                <table class="table table-docs table-hover " *ngIf="instancesList && instancesList.length > 0">
                  <tr>
                    <th></th>
                    <!--<th>
    Instance Id
    <span class="inputseach">
      <input type="text" [(ngModel)]="h.filterText" />
      <i class="fa fa-search" aria-hidden="true"></i>
    </span>
  </th>
  <th>Name</th>
  <th>Instance Type</th>
  <th>Private IP / Public IP</th>
  <th>Instance Status</th>-->
                    <th *ngFor="let h of targetEC2Header">
                      {{h.name}}
                      <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,scheduleRules)">

                        <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                        <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                      </span>

                      <span *ngIf="h.filter " class="inputseach">
                        <input type="text" [(ngModel)]="h.filterText" />
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </span>
                    </th>
                  </tr>
                  <tbody *ngFor="let item of instancesList;let i = index" id="{{item.instanceId}}">
                    <tr *ngIf="!checkIf(item.instanceId, 'EC2') && notifier.filterTableColums(targetEC2Header,item)">
                      <td>
                        <!--<input class="form-check-input" type="checkbox"
                        name="ec2" id="EC2{{i}}"
                        (change)="mapInstanceToTarget(item)" [checked]="checkIf(item.instanceId, 'EC2')">-->
                        <button class="btn btn-primary-soft" (click)="mapInstanceToTarget(item)"><i
                            class="fa fa-check-square"></i> Select</button>
                      </td>
                      <td>{{item.instanceId}}</td>
                      <td>{{item.tagName}}</td>
                      <td>{{item.instanceType}}</td>
                      <td>{{item.privateIp}}{{item.publicIp ? " / " + item.publicIp : ""}}</td>
                      <td>
                        <div class='status_div'
                          [ngClass]="{'btn-danger-soft': item.instanceStatus == 'stopped', 'btn-success-soft': item.instanceStatus == 'running'}"
                          style="width:98px;">
                          <div class='status_indicator'></div>
                          <div class='status_text'>{{item.instanceStatus}}</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!--<div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox"
                         name="frequencyWeekDay" id="frequencyWeekDay1"
                         (change)="cShedule.rule.frequencyWeekDay.Sunday != cShedule.rule.frequencyWeekDay.Sunday">
                  <label class="form-check-label" for="NotiRadio1">
                    Ec2-1
                  </label>
                </div>-->

              </fieldset>
            </div>
          </div>

          <div class="col-12" *ngIf="cShedule.targetType == 'RDS'">
            <div class="col-sm-12">



              <fieldset class="">
                <legend>RDS</legend>
                <!--<div class="form-check form-check-inline" *ngFor="let item of rdsList;let i = index">
                  <input class="form-check-input" type="checkbox"
                         name="RDS" id="RDS{{i}}"
                         (change)="mapInstanceToTarget(item.dbInstanceIdentifier)" [checked]="checkIf(item.dbInstanceIdentifier, 'RDS')">
                  <label class="form-check-label" for="RDS{{i}}">
                    {{item.dbInstanceIdentifier}}

                  </label>
                </div>-->
                <div class="alert alert-primary" role="alert" *ngIf="!rdsList || rdsList.length == 0">
                  No RDS Instance Found!
                </div>
                <table class="table table-docs table-hover" *ngIf="rdsList && rdsList.length > 0">
                  <tr>
                    <th></th>
                    <th>DB Instance Id</th>
                    <th>DB Engine</th>
                    <th>Instance Type</th>
                    <!--<th>Availability Zone</th>-->
                    <!--<th>Status Check</th>-->
                    <!--<th>Private IP / Public IP</th>-->
                    <th>Instance Status</th>
                  </tr>
                  <tbody *ngFor="let item of rdsList;let i = index" id="{{item.dbInstanceIdentifier}}">
                    <tr *ngIf="!checkIf(item.dbInstanceIdentifier, 'RDS')">
                      <td>
                        <!--<input class="form-check-input" type="checkbox"
                        name="ec2" id="EC2{{i}}"
                        (change)="mapInstanceToTarget(item)" [checked]="checkIf(item.instanceId, 'EC2')">-->
                        <button class="btn btn-primary-soft" (click)="mapInstanceToTarget(item)"><i
                            class="fa fa-check-square"></i> Select</button>
                      </td>
                      <td>{{item.dbInstanceIdentifier}}</td>
                      <td>{{item.dbEngine}}</td>
                      <td>{{item.dbInstanceType}}</td>
                      <!--<td>{{item.privateIp}}{{item.publicIp ? " / " + item.publicIp : ""}}</td>-->
                      <td>
                        <div class='status_div'
                          [ngClass]="{'btn-danger-soft': item.dbStatus == 'stopped', 'btn-success-soft': item.dbStatus == 'running'}"
                          style="width:98px;">
                          <div class='status_indicator'></div>
                          <div class='status_text'>{{item.dbStatus}}</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!--<fieldset class="">
                  <legend>RDS Cluster</legend>
                  <div class="form-check form-check-inline" *ngFor="let item of rdsClusterList;let i = index">
                    <input class="form-check-input" type="checkbox"
                           name="RDS" id="RDS{{i}}"
                           (change)="mapInstanceToTarget(item.dbInstanceIdentifier)" [checked]="checkIf(item.dbInstanceIdentifier, 'RDS')">
                    <label class="form-check-label" for="RDS{{i}}">

                      {{item.clusterIdentifier}}
                    </label>
                  </div>
                </fieldset>-->
                <!--<div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox"
                         name="frequencyWeekDay" id="frequencyWeekDay1"
                         (change)="cShedule.rule.frequencyWeekDay.Sunday != cShedule.rule.frequencyWeekDay.Sunday">
                  <label class="form-check-label" for="NotiRadio1">
                    RDS-2
                  </label>
                </div>-->

              </fieldset>
            </div>
          </div>

          <!--<div class="col-12" *ngIf="cShedule.targetType == 'Tags'">
            <div class="col-sm-12">

              <fieldset class="">
                <legend>Tags</legend>
                <div class="form-check form-check-inline" *ngFor="let item of tagList;let i = index">
                  <input class="form-check-input" type="checkbox"
                         name="Tags" id="Tags{{i}}"
                         (change)="mapInstanceToTarget(item)" [checked]="checkIf(item, 'Tags')">
                  <label class="form-check-label" for="Tags{{i}}">
                    {{item}}
                  </label>
                </div>


              </fieldset>
            </div>
          </div>-->
        </fieldset>
      </div>



      <fieldset class="mt-3">
        <legend>Alert Notifications</legend>

        <div class="row mb-3">
          <label for="colFormLabelSm" class="col-sm-2 col-form-label text-success">On Success</label>
          <div class="col-sm-10">
            <input type="email" class="form-control form-control-sm" placeholder="abc@abc.com"
              [(ngModel)]="cShedule.alerts.onSuccess">
          </div>
        </div>

        <div class="row mb-3">
          <label for="colFormLabelSm" class="col-sm-2 col-form-label text-danger">On Error</label>
          <div class="col-sm-10">
            <input type="email" class="form-control form-control-sm" placeholder="abc@abc.com"
              [(ngModel)]="cShedule.alerts.onError">
          </div>
        </div>


        <div class="row mb-3">
          <label for="colFormLabelSm" class="col-sm-2 col-form-label text-info">Other Notifications</label>
          <div class="col-sm-10">
            <input type="email" class="form-control form-control-sm" placeholder="abc@abc.com"
              [(ngModel)]="cShedule.alerts.others">
          </div>
        </div>

      </fieldset>


    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal"
        (click)="CloseCreateShedule()">Cancel</button>
      <button type="button" class="btn btn-primary-soft" (click)="backToBasic()">Back</button>
      <button type="button" class="btn btn-success-soft" (click)="createShedule()">Save</button>
    </div>

  </div>
</div>










<div class="modal-dialog modal-dialog-centered" *ngIf="showLog" style="position:static; top: 3%">
  <div class="modal-content">

    <div class="modal-header">
      <h5 class="modal-title" id="modalLabelCreateEvents">View Logs</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="showLog = false"></button>
    </div>

    <div class="modal-body">
      <div class="row g-4">
        <table class="table table-docs table-hover">
          <tr>
            <th></th>
            <th width="175">Action Time</th>
            <th width="75">Action</th>
            <th width="75">Status</th>
            <th>Target</th>
            <th>Log</th>
          </tr>
          <tr *ngFor="let item of logData;let i = index">
            <td>{{i + 1}}</td>
            <td>{{convertToIST(item.actionTime)}}</td>
            <td>{{item.action}}</td>
            <td>{{item.status}}</td>
            <td>
              <!--<pre>{{convetToString(item.target)}}</pre>-->
              <div class="col-12" *ngFor="let region of item.target">
                <fieldset class="mb-3">
                  <legend style="font-weight:bold;">{{region.regionName}}</legend>


                  <fieldset class="" *ngIf="region.Tag && region.Tag.length > 0">
                    <legend>Tags</legend>

                    <div class="col-12" *ngFor="let i of region.Tags">
                      <button class="btn btn-outline-info m-1">{{i}}</button>
                    </div>
                  </fieldset>

                  <fieldset class="mb-3" *ngIf="region.EC2 && region.EC2.length > 0">
                    <legend>EC2</legend>
                    <table class="table table-docs table-hover ">
                      <tr>
                        <!--<th></th>-->
                        <th>Instance Id</th>
                        <th>Name</th>
                        <th>Instance Type</th>
                        <!--<th>Private IP / Public IP</th>-->
                        <th>Previous State</th>
                        <th>Current State</th>
                        <!--<th>Instance Status</th>-->
                      </tr>
                      <tr *ngFor="let item of region.EC2D;let i = index">
                        <!--<td> <button class="btn btn-danger-soft" (click)="mapInstanceToTarget(item)"><i class="fa fa-remove"></i> Remove</button></td>-->
                        <td>{{item.instanceId}}</td>
                        <td>{{item.tagName}}</td>
                        <td>{{item.instanceType}}</td>
                        <!--<td>{{item.privateIp}}{{item.publicIp ? " / " + item.publicIp : ""}}</td>-->
                        <td>{{item.previousState}}</td>
                        <td>{{item.currentState}}</td>

                        <!--<td>
                          <div class='status_div' [ngClass]="{'btn-danger-soft': item.instanceStatus == 'stopped', 'btn-success-soft': item.instanceStatus == 'running'}"
                               style="width:98px;">
                            <div class='status_indicator'></div>
                            <div class='status_text'>{{item.instanceStatus}}</div>
                          </div>
                        </td>-->
                      </tr>
                    </table>
                  </fieldset>

                  <!--<fieldset class="" *ngIf="region.RDS && region.RDS.length > 0">
                    <legend>RDS</legend>
                    <table class="table table-docs table-hover ">
                      <tr>
                        <th></th>
                        <th>DB Instance Id</th>
                        <th>DB Engine</th>
                        <th>Instance Type</th>
                        <th>Instance Status</th>
                      </tr>
                      <tbody *ngFor="let item of region.RDSD;let i = index" id="{{item.dbInstanceIdentifier}}">
                        <tr>
                          <td>
                            <button class="btn btn-danger-soft" (click)="mapInstanceToTarget(item)"><i class="fa fa-remove"></i> Remove</button>
                          </td>
                          <td>{{item.dbInstanceIdentifier}}</td>
                          <td>{{item.dbEngine}}</td>
                          <td>{{item.dbInstanceType}}</td>
                          <td>
                            <div class='status_div' [ngClass]="{'btn-danger-soft': item.dbStatus == 'stopped', 'btn-success-soft': item.dbStatus == 'running'}"
                                 style="width:98px;">
                              <div class='status_indicator'></div>
                              <div class='status_text'>{{item.dbStatus}}</div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>-->
                </fieldset>
              </div>

            </td>
            <!--<td *ngIf="item.action == 'Stop'">
              <pre *ngIf="item.lastRun && item.lastRun.m">{{convetToString(item.lastRun.m.StoppingInstances)}}</pre>
            </td>
            <td *ngIf="item.action == 'Start'">
              <pre *ngIf="item.lastRun && item.lastRun.m">{{convetToString(item.lastRun.m.StartingInstances)}}</pre>
            </td>-->
          </tr>
        </table>

      </div>
    </div>

    <div class="modal-footer">

      <button type="button" class="btn btn-primary-soft" (click)="showLog = false">Back</button>

    </div>
  </div>
</div>



<div class="modal-dialog" id="otpModal" *ngIf="otpModal">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">OTP Confirmation</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelOTPClick()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Enter OTP: <input type="text" name="otpvalue" id="otp" [(ngModel)]="otpValue">
      <label class="text-danger">{{optErrorMSG}}</label>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancelOTPClick()">Cancel</button>

      <button type="button" class="btn btn-primary" (click)="confirmOTPClick()">Confirm</button>
    </div>
  </div>
</div>