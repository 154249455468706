<div class="minfyui-change-request-ui">
  <div class="main_contaniner">
    <div class="minfy-change-request-container">
      <div class="change_request_header">
        <div style="cursor: pointer; float: right; font-size: 1.2em">
          <i class="fa fa-times clickable" aria-hidden="true" (click)="hide(false)"></i>
        </div>
        <div class="header_bold">Change Request Approval</div>
        <div class="header_description">
          <div>Account ID: {{ accountId }}</div>
          <div>Region: {{ regionId }}</div>
          <div>
            <div style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
              ">
              Instance ID:
              <span class="nav-link">{{ cr_detail["metadata"]["resource_ids"][0] }}
                <!-- <span *ngIf="instances.length > 1">(+{{ instances.length - 1 }} more)</span>
                <span><i class="fa fa-pencil" aria-hidden="true"></i></span></span> -->
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="change_request_body">
        <div>Are you sure you want to {{ action }} this CR?</div>
        <div *ngIf="action == 'reject'" class="row" style="margin-top: 10px">
          <label>Reason</label>
          <textarea style="min-height: 100px; resize: none" class="form-control" [(ngModel)]="rejectReason"></textarea>
        </div>
      </div>
      <div class="change_request_footer">
        <button *ngIf="(rejectReason != '' && this.action == 'reject') || action == 'approve'" class="btn btn-light"
          style="
            text-transform: capitalize;
            color: #5864ff;
            background-color: transparent;
          " (click)="confirmCall()">
          <span *ngIf="loading">
            <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 25px; padding-right: 10px" />
          </span>
          <span style="color: inherit; text-transform: capitalize;" *ngIf="!loading">
            {{action}}
          </span>
        </button>
        <button class="btn btn-light" (click)="hide(false)">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>