<div class="card mt-20" style="border: none; position: relative; height: 100%">
  <h5 class="card-header">MRR Discount</h5>
  <div class="card-body">
    <div style="display: flex; justify-content: space-between; align-items: center">
      <div>
        <span>
          <b>MRR Period: </b> {{ period }}
        </span>
      </div>
      <button class="btn btn-light" style="background-color: #5864ff; color: white" (click)="addMrr()">
        Add MRR Details
      </button>
    </div>

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
          <th>Update</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody *ngFor="let i of mrrData; let j = index"
        [ngStyle]="{ display: mrrData.length > 0 ? 'table-row-group' : 'none' }">
        <tr>
          <td>{{ j + 1 }}</td>
          <td *ngFor="let h of headers">
            <ng-container *ngIf="h.id === 'serviceName'; else otherContent">
              <ng-container>
                <span *ngIf="i[h.id] === 'Amazon Elastic Compute Cloud'" [ngClass]="{ 'btn btn-link': true }"
                  [style.cursor]="'pointer'" [style.padding]="0" (click)="toggleExpandRow(j)">
                  {{ i[h.id] || '-' }} <!-- Display Elastic Compute Cloud -->
                </span>
              </ng-container>
              <ng-container>
                <span *ngIf="i[h.id] === 'Amazon Relational Database Service'" [ngClass]="{ 'btn btn-link': true }"
                  [style.cursor]="'pointer'" [style.padding]="0" (click)="toggleExpandRow(j)">
                  {{ i[h.id] || '-' }} <!-- Display Elastic Compute Cloud -->
                </span>
              </ng-container>
              <ng-container>
                <span *ngIf="i[h.id] === 'Amazon Simple Storage Service'" [ngClass]="{ 'btn btn-link': true }"
                  [style.cursor]="'pointer'" [style.padding]="0" (click)="toggleExpandRow(j)">
                  {{ i[h.id] || '-' }} <!-- Display Elastic Compute Cloud -->
                </span>
              </ng-container>
              <ng-container>
                <span *ngIf="i[h.id] === 'Amazon CloudFront'" [ngClass]="{ 'btn btn-link': true }"
                  [style.cursor]="'pointer'" [style.padding]="0" (click)="toggleExpandRow(j)">
                  {{ i[h.id] || '-' }} <!-- Display Elastic Compute Cloud -->
                </span>
              </ng-container>

              <ng-container
                *ngIf="!['Amazon Relational Database Service','Amazon Elastic Compute Cloud','Amazon Simple Storage Service', 'Amazon CloudFront'].includes(i[h.id])">
                {{ i[h.id] || '-' }}
                <!-- Display other service names normally -->
              </ng-container>
            </ng-container>
            <!-- <ng-container *ngIf="h.id ==='mrrDiscount' && selectedClientName !== 'NHA'">
              <span *ngIf="['Amazon Simple Storage Service', 'Amazon CloudFront'].includes(i[h.id])"
                [ngClass]="{ 'btn btn-link': true }" [style.cursor]="'pointer'" [style.padding]="0"
                data-bs-toggle="modal" data-bs-target="#expandmodel" (click)="openPopup(i[h.id])">
                {{ 'More' || '-' }} <!-- Display service names for non-NHA clients
              </span>

            </ng-container> -->

            <ng-template #otherContent>
              <ng-container *ngIf="h.id === 'mrrDiscount'">
                <!-- Display "More" link for specific services and non-NHA clients -->
                <!-- <span *ngIf="selectedClientName !== 'NHA' && ['Amazon Simple Storage Service', 'Amazon CloudFront'].includes(i.serviceName)">
                  {{ i[h.id] || 0 }}%
                </span> -->
                <!-- Display "-" if service is 'Amazon Elastic Compute Cloud' and client is 'NHA' -->
                <!-- <span *ngIf="i.serviceName === 'Amazon Elastic Compute Cloud' && selectedClientName === 'NHA'">-</span> -->
                <!-- Display discount with "%" for other cases -->
                <!-- <span *ngIf="!(['Amazon Simple Storage Service', 'Amazon CloudFront'].includes(i.serviceName) && selectedClientName !== 'NHA')"> -->
                {{ i[h.id] || 0 }}%
                <!-- </span> -->
              </ng-container>

              <!-- Display other column content if it's not 'mrrDiscount' -->
              <span *ngIf="h.id !== 'mrrDiscount'" [innerHTML]="i[h.id] || '-'"></span>
            </ng-template>


          </td>

          <td>
            <span (click)="updateTrigger(i)" class="btn btn-light">
              <i class="fa fa-pencil"></i>
            </span>
          </td>
          <td>
            <span (click)="confirmdeletion(i)" class="btn btn-light">
              <i class="fa fa-trash"></i>
            </span>
          </td>
        </tr>

        <!-- Expanded content table - only when the row is expanded and selectedClientName is NHA and serviceName is Amazon Elastic Compute Cloud -->
        <tr *ngIf="expandedRow === j ">
          <td [attr.colspan]="headers.length + 3">

            <!-- Subtable for displaying 'sub' details from mrrDatasub -->
            <!-- (selectedClientName !== 'NHA' && (i.serviceName === 'Amazon Elastic Compute Cloud' || i.serviceName === 'Amazon Relational Database Service')) -->
            <table *ngIf="selectedClientName === 'NHA' && exp_row === 'Amazon Elastic Compute Cloud'"
              class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>Billing Type</th>
                  <th>Discount</th>
                </tr>
              </thead>
              <tbody>
                <!-- Display data from 'mrrDatasub' -->
                <tr>
                  <td>Instance</td>
                  <td>{{ mrrDatasub.instance + '%' || '-' }}</td>
                </tr>
                <tr>
                  <td>EBS</td>
                  <td>{{ mrrDatasub.ebs + '%' || '-' }}</td>
                </tr>
                <tr>
                  <td>Others</td>
                  <td>{{ mrrDatasub.others + '%' || '-' }}</td>
                </tr>
              </tbody>
            </table>
            <table *ngIf="selectedClientName !== 'NHA' && exp_row === 'Amazon Relational Database Service'"
              class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Billing Type</th>
                  <th>Applied</th>
                  <th>Discount</th>
                </tr>
              </thead>
              <tbody>
                <!-- Instance Header -->
                <tr>
                  <td rowspan="2"><strong>Instance</strong></td>
                  <td>RI/SP</td>
                  <td>{{ mrrDatasub.instance.ri_sp.isMrr }}</td>
                  <td>{{ mrrDatasub.instance.ri_sp.perc + '%' || '-' }}</td>
                </tr>
                <tr>
                  <td>OD</td>
                  <td>{{ mrrDatasub.instance.od.isMrr }}</td>
                  <td>{{ mrrDatasub.instance.od.perc + '%' || '-' }}</td>
                </tr>
                <!-- <tr>
                  <td>Spot</td>
                  <td>{{ mrrDatasub.instance.spot.isMrr }}</td>
                  <td>{{ mrrDatasub.instance.spot.perc + '%' || '-' }}</td>
                </tr> -->

                <!-- EBS Data -->
                <!-- <tr>
                  <td><strong>EBS</strong></td>
                  <td>-</td>
                  <td>{{ mrrDatasub.ebs.isMrr }}</td>
                  <td>{{ mrrDatasub.ebs.perc + '%' || '-' }}</td>
                </tr> -->

                <!-- Others Data -->
                <tr>
                  <td rowspan="3"><strong>Others</strong></td>
                  <td>-</td>
                  <td>{{ mrrDatasub.others.isMrr }}</td>
                  <td>{{ mrrDatasub.others.perc + '%' || '-' }}</td>
                </tr>
              </tbody>
            </table>
            <table *ngIf="selectedClientName !== 'NHA' && exp_row === 'Amazon Elastic Compute Cloud'"
              class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Billing Type</th>
                  <th>Applied</th>
                  <th>Discount</th>
                </tr>
              </thead>
              <tbody>
                <!-- Instance Header -->
                <tr>
                  <td rowspan="3"><strong>Instance</strong></td>
                  <td>RI/SP</td>
                  <td>{{ mrrDatasub.instance.ri_sp.isMrr }}</td>
                  <td>{{ mrrDatasub.instance.ri_sp.perc + '%' || '-' }}</td>
                </tr>
                <tr>
                  <td>OD</td>
                  <td>{{ mrrDatasub.instance.od.isMrr }}</td>
                  <td>{{ mrrDatasub.instance.od.perc + '%' || '-' }}</td>
                </tr>
                <tr>
                  <td>Spot</td>
                  <td>{{ mrrDatasub.instance.spot.isMrr }}</td>
                  <td>{{ mrrDatasub.instance.spot.perc + '%' || '-' }}</td>
                </tr>

                <!-- EBS Data -->
                <tr>
                  <td><strong>EBS</strong></td>
                  <td>-</td>
                  <td>{{ mrrDatasub.ebs.isMrr }}</td>
                  <td>{{ mrrDatasub.ebs.perc + '%' || '-' }}</td>
                </tr>

                <!-- Others Data -->
                <tr>
                  <td><strong>Others</strong></td>
                  <td>-</td>
                  <td>{{ mrrDatasub.others.isMrr }}</td>
                  <td>{{ mrrDatasub.others.perc + '%' || '-' }}</td>
                </tr>
              </tbody>
            </table>
            <table *ngIf="selectedClientName !== 'NHA' && exp_row === 'Amazon Simple Storage Service'"
              class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>Billing Type</th>
                  <th>Applied</th>
                  <th>Discount</th>
                </tr>
              </thead>
              <tbody>


                <tr>
                  <td>S3</td>
                  <td>{{ mrrDatasub.s3Mrr.isMrr }}</td>
                  <td>{{ mrrDatasub.s3Mrr.perc + '%' || '-' }}</td>
                </tr>

              </tbody>
            </table>
            <table *ngIf="selectedClientName !== 'NHA' && exp_row === 'Amazon CloudFront'"
              class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>Billing Type</th>
                  <th>Applied</th>
                  <th>Discount</th>
                </tr>
              </thead>
              <tbody>


                <tr>
                  <td>CFRC</td>
                  <td>{{ mrrDatasub.cfrcMrr.isMrr }}</td>
                  <td>{{ mrrDatasub.cfrcMrr.perc + '%' || '-' }}</td>
                </tr>

              </tbody>
            </table>
          </td>
        </tr>
      </tbody>

      <tbody [ngStyle]="{
          display: mrrData.length == 0 ? 'table-row-group' : 'none'
        }">
        <tr>
          <td [attr.colspan]="6">
            <div style="text-align: center">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>




  </div>
</div>

<!-- this one is table popup -->

<!-- <div class="modal fade" id="expandmodel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-docs table-hover fixed-header">
            <thead>
              <th>S.I</th>
              <th *ngFor="let h of headers_pop">{{ h.name }}</th>

            </thead>
            <tbody *ngFor="let i of popupData; let j = index"
              [ngStyle]="{ display: popupData.length > 0 ? 'table-row-group' : 'none' }">
              <tr>
                <td>{{ j + 1 }}</td>
                <td *ngFor="let h of headers_pop">
                  {{ i[h.id] || '-' }}
                </td>

            </tbody>
            <tbody [ngStyle]="{
          display: popupData.length == 0 ? 'table-row-group' : 'none'
        }">
              <tr>
                <td [attr.colspan]="6">
                  <div style="text-align: center">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="lek !== null && lek !== undefined && lek !== ''" class="btn btn-primary-soft"
          (click)="fetchDetails(service_Name)">
          Next
        </button>
        <button type="button" class="btn btn-success-soft" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div> -->


<div *ngIf="delete"
  style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); backdrop-filter: blur(5px); display: flex; align-items: center; justify-content: center;">
  <div class="card"
    style="height: 200px; width: 60%; padding: 18px; background: white; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);justify-content: space-between;">
    <h4>Are you sure you want to delete?</h4>
    <div style="text-align: end;">
      <button class="btn btn-danger" style="margin: 0px 4px;" (click)="deleteTrigger(indexdelete)">Delete</button>
      <button class="btn" style="margin: 0px 4px; background-color: rgb(228, 230, 232);"
        (click)="delete = false">Cancel</button>
    </div>
  </div>
</div>



<mrr-update *ngIf="updateMRR" [action]="updateAction" [mrr]="updateMRR" [unsaved]="unsavedMRR"
  (hideModel)="closeUpdateTrigger($event)" [mrrData]="mrrData" [mrrDatasub]='mrrDatasub' [mid]='mid' [subEc2]='subEc2'
  [subRds]='subRds' [s3Mrr]='s3Mrr' [cfrcMrr]='cfrcMrr' [s3Keys]='s3Keys' [cfrcKeys]='cfrcKeys'>
</mrr-update>