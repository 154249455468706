<div class="card mt-20" style="border: none; position: relative; height: 100%;">

<div class="card-header">
    <span class="title" style="font-size: 22px; line-height: 45px; vertical-align: middle; position: relative;">WR Pnl</span>
    <button class="btn btn-primary-soft" style="float: right; margin: 0 10px" (click)="fetch()">
      <i class="fa fa-refresh" aria-hidden="true"></i>
      Refresh
    </button>
    <button class="btn btn-success-soft" style="float: right; margin: 0 10px"
      
      (click)="export()"><i class="fa fa-download" aria-hidden="true"></i>
      Download</button>
  </div>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; align-items: center; margin: 10px 0px;">
      <input type="text" id="monthDate" [(ngModel)]="dateRange">
    </div>
    <div style="margin: 10px 0px; padding: 10px; border-radius: 10px; box-shadow: 1px 1px 3px 1px grey;">
        <table class="table table-docs table-hover" id="WR-Pnl-Report">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <ng-container *ngFor="let h of headers">
    
                    <th>
                        {{ h.name }}
                        <span
                          *ngIf="h.sort && h.sort.sort"
                          (click)="notifier.sortTable(h, data_fetch)"
                        >
                          <i
                            class="fa fa-sort-amount-desc"
                            aria-hidden="true"
                            *ngIf="h.sort.direction == 'Des'"
                          ></i>
                          <i
                            class="fa fa-sort-amount-asc"
                            aria-hidden="true"
                            *ngIf="h.sort.direction == 'Asc'"
                          ></i>
                        </span>
                        <span *ngIf="h.filter" class="inputseach">
                          <input type="text" [(ngModel)]="h.filterText" />
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </th>
    
    
                <!-- <th *ngIf="!h.hide">{{ h.name }}</th> -->
                </ng-container>
              </tr>
            </thead>
            <!-- <ng-container  *ngIf="data_fetch.length >= 1"> -->           
                <tbody *ngIf="data_fetch" [ngStyle]="{'display': data_fetch.length !== 0 ? 'table-row-group' : 'none'}">
                    <ng-container *ngFor="let i of data_fetch; let j = index" >
    
              
              <tr *ngIf="notifier.filterTableColums(headers, i)" >
                <!-- <td>{{j + 1}}</td> -->
                <td>{{ j + 1 }}</td>
                <ng-container *ngFor="let h of headers">
                <td *ngIf="!h.hide">
                  
                    <span *ngIf="!h.click">{{
                      h.alter ? h.alter(i, h.id) : i[h.id]
                    }}</span>
                  
                  
                  </td>
                </ng-container>
                <!-- <ng-container *ngFor="let h of headers">
                    <td *ngIf="!h.hide" >
                        <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
                      </td>
                </ng-container>
              -->
              </tr>
            </ng-container>
            </tbody>
        <!-- </ng-container> -->
            <ng-container  *ngIf="data_fetch.length === 0">
                <tbody >
                    <tr>
                      <td [attr.colspan]="headers.length + 1">
                        <div style="text-align: center;">No Data Found</div>
                      </td>
                    </tr>
                  </tbody>
            </ng-container>
            
          </table>
        </div>
  </div>