<div class="card" *ngIf="!selectedLinkedAccount">
  <h5 class="card-header">Cost Variance</h5>
  <div class="card-body">
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">{{h.name}}</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': variance.length > 0 ? 'table-row-group': ''}">
        <tr *ngFor="let i of variance; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{display: fetching ? 'table-row-group': 'none'}">
        <tr>
          <td style="text-align: center;" [attr.colspan]="headers.length + 1">
            <img src="../assets/img/loading_2.svg" *ngIf="fetching" alt="loading scans"
              style="width: 18px; margin: 10px" />
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': variance.length == 0 && !fetching ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<cost-variance-report *ngIf="selectedLinkedAccount" [mid]="selectedMasterAccount" [lid]="selectedLinkedAccount"
  (close)="close($event)"></cost-variance-report>