import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BOMv2RoutingModule } from './bomv2-routing.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ValidateChangeRequestComponent } from './components/validate-change-request/validate-change-request.component';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    ValidateChangeRequestComponent
  ],
  imports: [CommonModule, BOMv2RoutingModule],
})
export class Bomv2Module {}
