import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from './../../../_services/notifier.service';
import * as moment from 'moment';

declare let window: any;

@Component({
  templateUrl: './tagscostoptimization.component.html',
  styleUrls: ['./tagscostoptimization.component.css'],
})
export class BetaTagsCostOptimizationComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  jobDetails: any = null;
  recomondations: any = [];
  funRef: any;
  tagsList: any = null;
  tagsModalTitle: any = 'Tags';
  modalHeader: any;
  createJobTrigger: boolean = null;
  nextTokens: any = {
    recomondations: undefined,
  };
  currentPage: String = 'job_details';
  fetching: boolean = false;

  destroyed: boolean = false;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;

  ec2CostHeaders: any = [
    { id: 'insname', name: 'Tag Name', filter: true },
    { id: 'type', name: 'Instance Type', filter: true },
    {
      id: 'current24hoursPrice',
      name: 'Current Cost (24 hrs a day)',
      filter: true,
      center: true,
    },
    {
      id: 'rectype',
      name: 'Recommended',
      filter: true,
    },
    {
      id: 'rec8hoursPrice',
      name: 'Recommended Cost (8 hrs a day)',
      filter: true,
      center: true,
    },
    {
      id: 'savings_html',
      name: 'Savings (8 hrs a day)',
      filter: true,
      center: true,
    },
  ];
  moreDetailsHeaders: any = [
    { id: 'type_ins_id', name: 'Instance ID' },
    { id: 'avaiduration', name: 'Availability Duration' },
    {
      id: 'avaipercentage',
      name: 'Availability Percentage',
    },
    {
      id: 'recduration',
      name: 'Recommended Duration',
    },
  ];

  scanAccess: boolean = false;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.refresh();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.refresh();
      }
    });
    this.funRef = {};
  }

  position: any = { x: '0px', y: '0px' };
  setPosition(event: any) {
    this.position.x = window.innerWidth - event.clientX - 60 + 'px';
    this.position.y = event.clientY + 10 + 'px';
  }

  loading_recom: boolean = false;

  async checkScanAccess() {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (selectedAccountData.error) {
        console.log(selectedAccountData.error);
      } else if (selectedAccountData.data.site_247_project_id) {
        this.scanAccess = true;
        return this.scanAccess;
      }
    } catch (err) {}
    this.jobDetails = null;
    this.recomondations = [];
    this.scanAccess = false;
    return this.scanAccess;
  }

  refresh_: boolean = false;
  async refresh() {
    let check = await this.checkScanAccess();
    if (!check) {
      this.notifier.alert(
        'Info',
        '',
        'Managed Services support via SWAYAM is not enabled for your account. Please contact the admin.',
        'info',
        5000
      );
      return;
    }
    this.refresh_ = true;
    if (this.currentPage == 'job_details') {
      this.jobDetails = null;
      this.loadJobDetails();
    } else if (this.currentPage == 'recomondations') {
      this.recomondations = [];
      this.loadrecomondations();
    }
    this.checkScanAccess();
  }

  async loadJobDetails() {
    this.currentPage = 'job_details';
    let check = await this.checkScanAccess();
    if (!check) {
      this.notifier.alert(
        'Info',
        '',
        'Managed Services support via SWAYAM is not enabled for your account. Please contact the admin.',
        'info',
        5000
      );
      return true;
    }
    this.fetching = true;
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (selectedAccountData.error) {
        console.log(selectedAccountData.error);
      } else {
        const account = selectedAccountData.data;
        let data = {
          action: 'JobStatus',
          accountId: this.accountId,
          region: this.regionId,
        };

        let header = {
          Authorization: localStorage.getItem('t'),
        };
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/tagsscan`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (result.status_code == 1 || result.item) {
          if (result.item.length == 0) {
            this.jobDetails = null;
          } else {
            this.jobDetails = result.item[0];
            this.jobDetails.scandate = moment(this.jobDetails.scandate).format(
              'DD/MM/YYYY hh:mm A'
            );
          }
        } else {
          this.jobDetails = null;
        }
        if (!this.destroyed) {
          this.fetching = false;
        }
        this.refresh_ = false;
      }
    } catch (err) {}
    return true;
  }

  getTotalSavings(): String {
    let total = 0;
    this.recomondations.forEach((recom: any) => {
      total += isNaN(recom.savingsOn8HoursPr)
        ? 0
        : Number(recom.savingsOn8HoursPr);
    });
    return total.toFixed(2);
  }

  downloadReport() {
    let element = document.createElement('table');
    element.innerHTML += `
        <thead>
          <tr>
            <th>Sl. No.</th>
            ${[...this.ec2CostHeaders, ...this.moreDetailsHeaders].map(
              (h: any) => {
                return `<th>${h.name}</th>`;
              }
            )}
          </tr>
        </thead>
        <tbody>
            ${this.recomondations.map((ec2: any, index: any) => {
              return `
                <tr>
                  <td>${index + 1}</td>
                  ${[...this.ec2CostHeaders, ...this.moreDetailsHeaders].map(
                    (h: any) => {
                      return `<td>
                      ${ec2[h.id]}
                    </td>`;
                    }
                  )}
                </tr>
              `;
            })}
        </tbody>
      `;
    window.exportToExcelElement(
      element,
      `Tags Cost Controls - ${this.accountId}`,
      'csv'
    );
  }

  nextToken: any;
  async loadrecomondations(nextToken: string = '') {
    this.nextToken = nextToken == '' ? null : nextToken;

    this.currentPage = 'recomondations';
    this.loading_recom = true;
    let currentAccountId = this.accountId;
    let currentRegionId = this.regionId;
    let check = await this.checkScanAccess();
    if (!check) {
      this.notifier.alert(
        'Info',
        '',
        'Managed Services support via SWAYAM is not enabled for your account. Please contact the admin.',
        'info',
        5000
      );
      this.loading_recom = false;
      this.fetching = false;
      return true;
    }
    if (this.destroyed) {
      return true;
    }
    if (nextToken == '') {
      this.refresh_ = true;
      this.recomondations = [];
      this.notifier.loading(true);
    }
    let data = {
      action: 'Ec2Tags',
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/tagsscan`;

    if (nextToken != '' && this.refresh_) {
      nextToken = '';
      this.recomondations = [];
      return true;
    }
    this.refresh_ = false;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.status_code == 1 || result.item) {
      result.item = result.item
        .filter((recom: any) => {
          return recom.type != recom.rectype && recom.rectype != '';
        })
        .map((recom: any) => {
          recom['createdTime'] = moment(recom['createdTime']).format(
            'DD/MM/YYYY hh:mm A'
          );
          if (recom.rectype != '-') {
            recom['savings_html'] = `<span class='${
              Number(recom.savingsOn8HoursPr) > 0
                ? 'green_text'
                : Number(recom.savingsOn8HoursPr) < 0
                ? 'red_text'
                : ''
            }'>$${recom.savingsOn8HoursPr}</span>`;
            recom['accountID'] = currentAccountId;
            recom['regionID'] = currentRegionId;
          } else {
            recom['currentprice'] = '<span>-</span>';
            recom['reccprice'] = '<span>-</span>';
            recom['savings_html'] = '<span>-</span>';
            recom['accountID'] = currentAccountId;
            recom['regionID'] = currentRegionId;
          }
          return recom;
        });
      this.recomondations = [...this.recomondations, ...result.item];

      this.recomondations = this.recomondations.map((recomm: any) => {
        recomm['type_ins_id'] =
          recomm['type_ins_id'].split('_')[
            recomm['type_ins_id'].split('_').length - 1
          ];
        return recomm;
      });
    } else {
      this.loading_recom = false;
      if (result.message != 'NO RECORD FOUND') {
        this.notifier.alert('Info', '', result.message, 'info', 5000);
      }
    }

    if (result.next_token) {
      await this.loadrecomondations(result.next_token);
    } else {
      this.loading_recom = false;
      this.nextToken = null;
    }

    if (!this.destroyed) {
      this.notifier.loading(false);
    }

    return true;
  }

  currentAccountId: string = this.accountId;

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  loadHTML(value: any) {
    if (value == 'LOADING_PRICE_THROUGH_API') {
      return `<span class='center'>
        <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" style="width: 25px; margin: 10px;">
      </span>`;
    } else {
      return `<span>${value}</span>`;
    }
  }

  callFunction(name: any, param: any) {}

  createJob(event: any) {
    this.createJobTrigger = true;
  }

  hideCreateJob(event: any) {
    this.createJobTrigger = null;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
