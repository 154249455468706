import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

import * as moment from 'moment';

declare let $: any;
declare let window: any;
declare let html2pdf: any;
declare let Jhxlsx: any;

@Component({
  templateUrl: 'billing.component.html',
  styleUrls: ['./billing.component.css'],
})
export class BillingComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  summary: any = [];
  analytics: any;
  activeAccount: any = null;
  diffDays: number;
  dateRange: string;
  billingOf: string = 'ec2';
  servicesData: any = {
    ec2: {
      'service-cost': 0,
      'service-name': 'AWS Elastic Compute Cloud',
      'service-credits': 0,
    },
    rds: {
      'service-cost': 0,
      'service-name': 'AWS Relational Database Service',
      'service-credits': 0,
    },
    cloudfront: {
      'service-cost': 0,
      'service-name': 'AWS Cloudfront',
      'service-credits': 0,
    },
    others: {
      'service-cost': 0,
      'service-name': 'Others',
      'service-credits': 0,
      services: [],
    },
  };

  ec2Headers: any = [
    {
      id: 'masterAccountId',
      name: 'Master account ID',
      export: true,
    },
    {
      id: 'lid_instanceId',
      name: 'Instance ID',
      export: true,
    },
    {
      id: 'instance_name',
      name: 'Tag Name',
      export: true,
    },
    {
      id: 'projectTagName',
      name: 'Project Tag Name',
      export: true,
      displayConditional: true,
    },
    {
      id: 'mapMigrated',
      name: 'Map Migrated Tag Name',
      export: true,
      displayConditional: true,
    },
    {
      id: 'admin_instance_type',
      name: 'Instance Type',
      export: true,
    },
    {
      id: 'cpu',
      name: 'CPU',
      export: true,
    },
    {
      id: 'ram',
      name: 'Ram',
      export: true,
    },
    {
      id: 'os',
      name: 'OS',
      export: true,
    },
    {
      id: 'region',
      name: 'Region',
      export: true,
    },
    {
      id: 'RI',
      name: 'RI Applied',
      export: true,
    },
    {
      id: 'billingType',
      name: 'Billing Type',
      export: true,
      alter: (value: any, param: any) => {
        let billingType: string = '';
        if (value[param] == 'Dis-RI') {
          billingType = 'OD-Discount';
        } else if (value[param] == 'M-RI') {
          billingType = 'M-Reserved';
        } else if (value[param] == 'Customer RI') {
          billingType = 'Cust-Reserved';
        } else {
          billingType = value[param];
        }
        return billingType;
      },
    },
    {
      id: 'hourly_cost',
      name: 'AWS Cost/Hr',
      export: false,
      alter: (value: any, param: string) => {
        if (value[param] == 'null' || value['billingType'] == 'Dis-RI') {
          return '-';
        }
        if (value['RI'] && value['RI'].toLowerCase() == 'yes') {
          if (value['billingType'] != 'unUsedCustomerRI') {
            if (value['billingType'] == 'Customer RI(Unused RI)') {
              return `<span>${Number(value['riHourlyCost']).toFixed(4)}</span>`;
            } else {
              return `<span class='line_through'>${Number(value[param]).toFixed(
                4
              )}</span> <span>${Number(value['riHourlyCost']).toFixed(
                4
              )}</span>`;
            }
          } else {
            return Number(value['riHourlyCost'].toFixed(4));
          }
        } else {
          return Number(value[param]).toFixed(4);
        }
      },
    },
    {
      id: 'hourly_cost',
      name: 'AWS Cost/Hr',
      export: true,
      hide: true,
      alter: (value: any, param: string) => {
        if (value[param] == 'null' || value['billingType'] == 'Dis-RI') {
          return '-';
        }
        if (value['RI'] && value['RI'].toLowerCase() == 'yes') {
          if (value['billingType'] != 'unUsedCustomerRI') {
            return `<span>${Number(value[param]).toFixed(4)}</span>`;
          } else {
            return Number(value['riHourlyCost'].toFixed(4));
          }
        } else {
          return Number(value[param]).toFixed(4);
        }
      },
    },
    {
      id: 'total_hours',
      name: 'AWS Total Hours',
      export: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? '-' : Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'total_cost',
      name: 'AWS Total Cost',
      export: false,
      alter: (value: any, param: string) => {
        if (isNaN(value[param])) {
          return '-';
        } else if (value['RI'] && value['RI'].toLowerCase() == 'yes') {
          if (value['billingType'] != 'unUsedCustomerRI') {
            if (value['billingType'] == 'Customer RI(Unused RI)') {
              return `<span>${Number(value['riTotalCost']).toFixed(4)}</span>`;
            } else {
              return `<span class='line_through'>${Number(value[param]).toFixed(
                4
              )}</span> <span>${Number(value['riTotalCost']).toFixed(
                4
              )}</span>`;
            }
          } else {
            return Number(value['riTotalCost'].toFixed(4));
          }
        } else {
          return Number(value[param]).toFixed(4);
        }
      },
    },
    {
      id: 'total_cost',
      name: 'AWS Total Cost',
      hide: true,
      export: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? '-' : Number(value[param]).toFixed(4);
      },
    },
    {
      id: 'riHourlyCost',
      name: 'RI Rate',
      export: true,
      hide: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param]) ? '-' : Number(value[param]).toFixed(4);
      },
    },
    {
      id: 'riTotalCost',
      name: 'Final Total Cost',
      hide: true,
      export: true,
      alter: (value: any, param: string) => {
        return value['RI'] && value['RI'].toLowerCase() == 'yes'
          ? value['billingType'] == 'unUsedCustomerRI'
            ? `${Number(value['total_cost']).toFixed(4)}`
            : Number(value[param]).toFixed(4)
          : Number(value['total_cost']).toFixed(4);
      },
    },
  ];
  ebsHeaders: any = [
    {
      id: 'lid_volumeId',
      name: 'Volume ID',
      export: true,
    },
    {
      id: 'volume_name',
      name: 'Tag Name',
      export: true,
    },
    {
      id: 'projectTagNameEBS',
      name: 'Project Tag Name',
      export: true,
      displayConditional: true,
    },
    {
      id: 'mapMigratedEBS',
      name: 'Map Migrated Tag Name',
      export: true,
      displayConditional: true,
    },
    {
      id: 'volume_type',
      name: 'Volume Type',
      export: true,
    },
    {
      id: 'gb_cost',
      name: 'GB Cost',
      export: true,
    },
    {
      id: 'total_storage',
      name: 'Total Storage',
      export: true,
    },
    {
      id: 'total_cost',
      name: 'Total Cost',
      export: true,
    },
  ];
  othersHeaders: any = [
    {
      id: 'lid_resourceId',
      name: 'Resource ID',
      export: true,
    },
    {
      id: 'resource_name',
      name: 'Resource Name',
      export: true,
    },
    {
      id: 'total_Gb_or_Cost',
      name: 'Total GB',
      export: true,
      alter: (value: any) => {
        return Number(value).toFixed(2);
      },
    },
    {
      id: 'total_cost',
      name: 'Total GB Cost',
      export: true,
      alter: (value: any) => {
        return Number(value).toFixed(2);
      },
    },
  ];

  projectTagNameEC2: boolean = false;
  mapMigratedEC2: boolean = false;
  projectTagNameEBS: boolean = false;
  mapMigratedEBS: boolean = false;

  funRef: any;

  summaryHeader: any = [
    {
      id: 'lid',
      name: 'Linked Account Number',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
    {
      id: 'accname',
      name: 'Account Name',
    },
    {
      id: 'scount',
      name: 'Service Count',
    },
    {
      id: 'credits',
      name: 'Credits',
    },
    {
      id: 'totalcost',
      name: 'Cost(appr.)',
    },
  ];

  latestDataSummaryheader: any = [
    {
      id: 'lid',
      name: 'Linked Account Number',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
  ];

  billsHeader: any = [
    {
      id: 'service-name',
      name: 'Service Name',
      clickCheck: (id: any) => {
        if (
          id == 'Amazon CloudFront' ||
          id == 'Amazon Relational Database Service' ||
          id == 'Amazon Elastic Compute Cloud'
        ) {
          return true;
        }
        return false;
      },
      click: 'loadInnerData',
    },
    { id: 'html', name: 'Service Cost' },
  ];

  accountId: any;
  regionId: any;
  currentMessage: any;

  masterAccounts: any = [];
  downloadAll: any = null;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}
  ngOnInit(): void {
    this.funRef = {
      showData: this.showData,
      loadInnerData: this.loadInnerData,
    };

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    $('#masterAccountSelect').selectpicker();
    $('.datetimepicker').val(moment().startOf('month').format('MM-YYYY'));
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm-yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      this.masterAccounts = [];
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) => {
        this.load();
      },
      component: this,
    };
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    this.analytics = null;
    await this.fetchDiffDays();
  }

  startOf: any;
  endOf: any;

  async fetchDiffDays() {
    let currentDate: any = $('.datetimepicker').val();
    let liveDate: any = moment(`02-2022`, 'MM-YYYY');
    this.startOf = moment(currentDate, 'MM-YYYY');
    this.endOf = moment(currentDate, 'MM-YYYY');
    let currentMonth = false;
    if (
      (new Date().getTime() -
        new Date(
          moment(currentDate, 'MM-YYYY')
            .subtract(1, 'months')
            .endOf('month')
            .format('MM-DD-YYYY')
        ).getTime()) /
        (1000 * 3600 * 25) <
      moment(currentDate, 'MM-YYYY')
        .subtract(1, 'months')
        .endOf('month')
        .daysInMonth()
    ) {
      currentMonth = true;
    }
    this.startOf = this.startOf.startOf('day');
    this.startOf = this.startOf.format('DD-MM-YYYY');
    if (!currentMonth) {
      this.endOf = this.endOf.endOf('month');
      this.endOf = this.endOf.format('DD-MM-YYYY');
    } else {
      this.endOf = moment(
        `${new Date().getDate()}-${
          new Date().getMonth() + 1
        }-${new Date().getFullYear()}`,
        'D-M-YYYY'
      ).format('DD-MM-YYYY');
    }
    currentDate = moment(currentDate, 'MM-YYYY');
    currentDate = currentDate.endOf('month').valueOf();
    currentDate = new Date(currentDate);
    liveDate = liveDate.endOf('month').valueOf();
    liveDate = new Date(liveDate);
    let diff =
      (currentDate.getTime() - liveDate.getTime()) / (1000 * 3600 * 24);
    this.diffDays = diff;
    setTimeout(async () => {
      if (this.diffDays > 0) {
        await this.fetchMasterAccounts();
      } else {
        await this.loadSummaryData();
      }
    }, 100);
  }

  downloadAl() {
    this.downloadAll = {};
    this.downloadAll['status'] = '';
    this.downloadAll['masters'] = this.masterAccounts;
    this.downloadAll['lids'] = this.response.billDetails.items.map(
      (item: any) => {
        return { lid: item.lid, checked: true };
      }
    );
  }

  cancelDownloadAll(event: any) {
    this.downloadAll = null;
  }

  async loadInnerData(ref: any, id: any) {
    await ref.loadRegions();
  }

  exportPDF(event?: any) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let element = document.createElement('div');
    let ec2Index = 0;
    let total = this.getPDFTotal();
    var ondemandCost = parseFloat(this.response.onDemandFinalTotalCost);
    var spotCost = parseFloat(this.response.spotFinalTotalCost);
    var mRiCost = parseFloat(this.response.mRiTotalCost);
    var finalimriCost = parseFloat(this.response.mRiFinalTotalCost);
    var custRiCost = parseFloat(this.response.customerRITotalCost);
    var finalCustRiCost = parseFloat(this.response.customerRIFinalTotalCost);
    var ondemadisCost = parseFloat(this.response.disRiTotalCost);
    var finalDisCost = parseFloat(this.response.disRiFinalTotalCost);
    var minfyRiCost = parseFloat(this.response.mRiTotalCost);
    var finalMinfyRiCost = parseFloat(this.response.minfyRiFinalTotalCost);
    var ebsCost = parseFloat(this.response.ebsTotalCost);
    var othersCost = parseFloat(this.response.othersTotalCost);
    var totalec2Cost =
      ondemandCost +
      spotCost +
      finalMinfyRiCost +
      finalimriCost +
      finalCustRiCost +
      finalDisCost;

    element.innerHTML = `
      <div
        id="completebillDetailsTable"
        class="container"
        style="
          margin-bottom: 100px;
          font-weight: 400;
          padding: 0px 25px;
          color: #000000;
        "
      >
        <div>
          <div class="row">
            <div class="col-sm-5">
              <div style="margin: auto">
                <img
                  style="margin-top: 80px"
                  src="../assets/img/minfy_logo.png"
                  alt="company logo"
                  width="75%"
                />
              </div>
            </div>
            <div class="col-sm-7" style="display: flex; flex-direction: column">
              <div style="padding-bottom: 5px; border-bottom: 3px solid gainsboro">
                <p style="font-weight: 780; font-family: Arial; font-size: 15px">
                  AWS Usage Report
                </p>
                <p style="font-family: Arial; font-size: 12px">
                  For any billing related queries. email us at
                  <span style="color: blue"> billing-support@minfytech.com </span>
                </p>
              </div>
              <div style="padding-bottom: 5px; border-bottom: 3px solid gainsboro">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                  "
                >
                  <p style="font-family: Arial; font-size: 12px">
                    AWS Service Charges
                  </p>
                  <p style="font-family: Arial; font-size: 12px">
                    $<span id="awsodCost" class='pdf_black' style='color: black;'>${this.getTotalRAW()}</span>
                  </p>
                </div>
              </div>
              <div style="padding-bottom: 5px; border-bottom: 3px solid gainsboro">
                <div
                  class="highlights"
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    font-family: Arial;
                    font-weight: bold;
                    font-size: 15.5px;
                  "
                >
                  <p>TOTAL AMOUNT</p>
                  <p>$<span id="awsTotalCost" style='color: black;'>${this.getDiscountedTotalRAW()}</span></p>
                </div>
              </div>
            </div>

            <div class="col-sm-12" style="font-family: Arial; font-size: 12px">
              <!-- <p ><span class="highlights">AWS Usage Report Date: </span><span id="reportDate"></span></p> -->

              <p>
                <span class="highlights pdf_black" style='font-weight: bold;'>Customer: </span
                ><span id="customerName" class='pdf_black' style='color: black;'>${
                  this.activeAccount.lid
                }</span>
              </p>

              <p class="highlights" style='font-weight: bold;'>
                This AWS Usage Report is for the billing period
                <span id="billingPeriod" class='pdf_black' style='color: black;'>${
                  this.startOf
                } - ${this.endOf}</span>
              </p>
              <p style="line-height: 1.5; margin-bottom: 5px; font-size: 11px">
                Greetings from MINFY, we're writing to provide you with an electronic
                report for your use of AWS services. Additional information about your
                bill,individual service charge details, and your account history are
                available on Swayam Portal.
              </p>
            </div>

            <table
              class="table"
              id="completepdfTable"
              style="
                border: 1.5px solid #d1d1a1;
                width: 720px;
                float: left;
                font-family: Arial;
                font-size: 12px;
                margin: 10px;
                color: #000000;
              "
            >
              <thead>
                <tr style="border-bottom: 1px solid #d1d1a1">
                  <th
                    style="text-align: left; background-color: #c8e1f5"
                    class="highlights"
                  >
                    Service Breakup Summary
                  </th>

                  <th style="background-color: #c8e1f5"></th>
                </tr>
              </thead>
              <tbody
                id="completepdfTableBody"
                style="font-family: Arial; font-size: 12px"
              >
                <tr style="border-bottom: 1px solid #d1d1a1" class="highlights">
                  <td style="color: #fcb65c">AWS Service Charges</td>
                  <td style="text-align: right">
                    $ <span id="totalOdCost" style='color: #000;'><b style='color: black;'>${this.getDiscountedTotalRAW()}</b></span>
                  </td>
                </tr>

                ${this.activeAccount.services
                  .map((service: any, index: any) => {
                    let service_name =
                      service.service_name || service['service-name'];
                    let service_cost = Number(service['service-cost']).toFixed(
                      2
                    );
                    let servicename = null;
                    if (
                      service_name === 'Amazon CloudFront' &&
                      this.servicesData['cloudfront']['discountedCost']
                    ) {
                      servicename = 'cloudfront';
                    }
                    if (
                      service_name === 'Amazon Elastic Compute Cloud' &&
                      this.servicesData['ec2']['discountedCost']
                    ) {
                      servicename = 'ec2';
                    }
                    if (
                      service_name === 'Amazon Relational Database Service' &&
                      this.servicesData['rds']['discountedCost']
                    ) {
                      servicename = 'rds';
                    }
                    if (
                      service.service_name == 'Amazon Elastic Compute Cloud'
                    ) {
                      ec2Index = index;
                      return '';
                    }
                    let service_html = service['html'];
                    let element = document.createElement('span');
                    element.innerHTML = service_html;
                    element.querySelectorAll('span').forEach((span: any) => {
                      span.style.color = 'black';
                      if (span.querySelectorAll('span').length == 0) {
                        span.innerHTML = isNaN(span.innerText)
                          ? span.innerText
                          : Number(span.innerText).toFixed(2);
                      }
                    });
                    return `
                <tr class="service">
                  <td style="padding-left: 50px">${service_name}</td>
                    ${
                      servicename
                        ? `
                      <td style="text-align: right">
                      ${
                        this.servicesData[servicename]['discountedCost']
                          ? this.servicesData[servicename]['discountedCost'] <
                            this.servicesData[servicename]['service-cost']
                            ? ` 
                        $<span><span class="line_through">
                            ${
                              this.servicesData[servicename]['service-cost'] ||
                              0
                            }</span> $<span class="bold_text">
                            ${
                              this.servicesData[servicename][
                                'discountedCost'
                              ] || 0
                            }</span></span>
                      `
                            : `
                            <span class="line_through">
                            ${
                              this.servicesData[servicename][
                                'discountedCost'
                              ] || 0
                            }</span> $<span class="bold_text">
                            ${
                              this.servicesData[servicename]['service-cost'] ||
                              0
                            }</span></span>
                      `
                          : `$${service_cost || 0}`
                      }
                    </td>
                    `
                        : `<td style='text-align: right;'><span style='color: black; text-align: right;'>$${element.innerHTML}</span></td>`
                    }
                </tr>
                `;
                  })
                  .join('')}

                  ${
                    this.servicesData['ec2']['service-cost'] > 0
                      ? `
                    <tr class="service">
                      <td style="padding-left: 50px">
                        ${
                          this.activeAccount.services[ec2Index][
                            'service-name'
                          ] ||
                          this.activeAccount.services[ec2Index]['service_name']
                        }
                      </td>
                      <td style="text-align: right">
                      ${
                        this.servicesData['ec2']['discountedCost']
                          ? this.servicesData['ec2']['discountedCost'] <
                            this.servicesData['ec2']['service-cost']
                            ? ` 
                        $<span class="line_through">
                            ${
                              this.servicesData['ec2']['service-cost'] || 0
                            }</span> $<span class="bold_text">
                            ${
                              this.servicesData['ec2']['discountedCost'] || 0
                            }</span>
                      `
                            : `
                            $<span class="line_through">
                            ${
                              this.servicesData['ec2']['discountedCost'] || 0
                            }</span> $<span class="bold_text">
                            ${
                              this.servicesData['ec2']['service-cost'] || 0
                            }</span></span>
                      `
                          : `$${this.servicesData['ec2']['service-cost'] || 0}`
                      }
                    
                  `
                      : ''
                  } 
                  </td>
                    </tr>
                  ${
                    ondemandCost > 0
                      ? `
                    <tr class="service">
                      <td style="padding-left:100px">
                      Ondemand 
                      </td>
                      <td style="text-align:right" >$
                      ${ondemandCost.toFixed(2)}
                      </td>	
                      </tr>
                  `
                      : ''
                  }
                  ${
                    spotCost > 0
                      ? `
                    <tr class="service">
                    <td style="padding-left:100px">
                    Spot 
                    </td>
                    <td style="text-align:right" >$
                    ${spotCost.toFixed(2)}
                    </td>	
                    </tr>
                  `
                      : ''
                  }

                  ${
                    mRiCost > 0 && finalimriCost > 0
                      ? mRiCost == finalimriCost
                        ? `
                    <tr class="service">
                      <td style="padding-left:100px">
                      M-Reserved 
                      </td>
                      <td style="text-align:right" >$
                      ${mRiCost.toFixed(2)}
                      </td>	
                      </tr>
                  `
                        : `
                    <tr class="service">
                    <td style="padding-left:100px">
                    M-Reserved 
                    </td>
                    <td style="text-align:right" >$
                    <span style="text-decoration: line-through;">${mRiCost.toFixed(
                      2
                    )}</span>  $${finalimriCost.toFixed(2)}
                    </td>	
                    </tr>
                  `
                      : ''
                  }

                  ${
                    custRiCost > 0 && finalCustRiCost > 0
                      ? custRiCost == finalCustRiCost
                        ? `
                    <tr class="service">
                      <td style="padding-left:100px">
                      Cust-Reserved
                      </td>
                      <td style="text-align:right" >$
                      ${custRiCost.toFixed(2)}
                      </td>	
                      </tr>
                  `
                        : `
                    <tr class="service">
                    <td style="padding-left:100px">
                    Cust-Reserved
                    </td>
                    <td style="text-align:right" >$
                    <span style="text-decoration: line-through;">${custRiCost.toFixed(
                      2
                    )}</span>  $${finalCustRiCost.toFixed(2)}
                    </td>	
                    </tr>
                  `
                      : ''
                  }

                  ${
                    ondemadisCost > 0 && finalDisCost > 0
                      ? ondemadisCost == finalDisCost
                        ? `
                        <tr class="service">
                        <td style="padding-left:100px">
                        Ondemand Cost 
                        </td>
                        <td style="text-align:right" >$
                        ${ondemadisCost.toFixed(2)}
                        </td>	
                        </tr>
                  `
                        : `
                    <tr class="service">
                    <td style="padding-left:100px">
                    Ondemand Cost
                    </td>
                    <td style="text-align:right" >$
                    <span style="text-decoration: line-through;">${ondemadisCost.toFixed(
                      2
                    )}</span>  $${finalDisCost.toFixed(2)}
                    </td>	
                    </tr>
                  `
                      : ''
                  }

                  ${
                    minfyRiCost > 0 && finalMinfyRiCost > 0
                      ? minfyRiCost == finalMinfyRiCost
                        ? `
                        <tr class="service">
                        <td style="padding-left:100px">
                        Minfy RI 
                        </td>
                        <td style="text-align:right" >$
                        ${minfyRiCost.toFixed(2)}
                        </td>	
                        </tr>
                  `
                        : `
                        <tr class="service">
                        <td style="padding-left:100px">
                        Minfy RI
                        </td>
                        <td style="text-align:right" >$
                        <span style="text-decoration: line-through;">${minfyRiCost.toFixed(
                          2
                        )}</span>  $${finalMinfyRiCost.toFixed(2)}
                        </td>	
                        </tr>
                  `
                      : ''
                  }

                  ${
                    ebsCost > 0
                      ? `
                    <tr class="service">
                    <td style="padding-left:75px">
                    EBS 
                    </td>
                    <td style="text-align:right" >$
                    ${ebsCost.toFixed(2)}
                    </td>	
                    </tr>
                  `
                      : ''
                  } 
                  ${
                    othersCost > 0
                      ? `
                    <tr class="service">
                    <td style="padding-left:75px">
                    Others 
                    </td>
                    <td style="text-align:right" >$
                    ${othersCost.toFixed(2)}
                    </td>	
                    </tr>
                  `
                      : ''
                  }
              </tbody>
              <tfoot
                style="background-color: #cccc98; color: #000000"
                id="completepdfTableFooter"
              >
                <tr>
                  <td style="text-align: left"><b>Total</b></td>

                  <td style="text-align: right; color: #000000;"><b style='color: black;'>${total}</b></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    `;

    var opt = {
      margin: 5,

      filename: `${this.activeAccount.lid}_${this.startOf}-${this.endOf}.pdf`,

      image: { type: 'jpeg', quality: 1 },

      html2canvas: { dpi: 192, scale: 4, letterRendering: true, useCORS: true },

      pagebreak: {
        before: '.newPage',
        avoid: ['h2', 'h3', 'h4', 'td', 'tr', '.field'],
      },

      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    html2pdf().set(opt).from(element).save();
  }

  getValue(value: string) {
    if (value == 'projectTagName') {
      return this.projectTagNameEC2;
    }
    if (value == 'mapMigrated') {
      return this.mapMigratedEC2;
    }
    if (value == 'projectTagNameEBS') {
      return this.projectTagNameEBS;
    }
    if (value == 'mapMigratedEBS') {
      return this.mapMigratedEBS;
    }
    return true;
  }

  exportCSV(event?: any) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let ec2Index = 0;
    let total_element: any = document.createElement('span');
    let total = this.getDiscountedTotalRAW();
    total_element.innerHTML = total;
    var ondemandCost = parseFloat(this.response.onDemandFinalTotalCost);
    var spotCost = parseFloat(this.response.spotFinalTotalCost);
    var mRiCost = parseFloat(this.response.mRiTotalCost);
    var finalimriCost = parseFloat(this.response.mRiFinalTotalCost);
    var custRiCost = parseFloat(this.response.customerRITotalCost);
    var finalCustRiCost = parseFloat(this.response.customerRIFinalTotalCost);
    var ondemadisCost = parseFloat(this.response.disRiTotalCost);
    var finalDisCost = parseFloat(this.response.disRiFinalTotalCost);
    var minfyRiCost = parseFloat(this.response.mRiTotalCost);
    var finalMinfyRiCost = parseFloat(this.response.minfyRiFinalTotalCost);
    var ebsCost = parseFloat(this.response.ebsTotalCost);
    var othersCost = parseFloat(this.response.othersTotalCost);
    var totalec2Cost =
      ondemandCost +
      spotCost +
      finalMinfyRiCost +
      finalimriCost +
      finalCustRiCost +
      finalDisCost;

    let dict: any = {
      sheetName: `${this.activeAccount.lid}`,
      data: [
        [
          { text: 'Account Name:' },
          { text: localStorage.getItem('selectedaccountIds') },
        ],
        [
          {
            text: 'Sl. No.',
          },
          {
            text: 'Service Name',
          },
          {
            text: 'Service Cost',
          },
        ],
      ],
    };

    this.activeAccount.services
      .sort((a: any, b: any) => {
        return a['service_name'] == 'Amazon Elastic Compute Cloud' ||
          a['service-name'] == 'Amazon Elastic Compute Cloud'
          ? 1
          : b['service_name'] == 'Amazon Elastic Compute Cloud' ||
            b['service-name'] == 'Amazon Elastic Compute Cloud'
          ? -1
          : 0;
      })
      .forEach((service: any, index: any) => {
        let data: any = [];
        let service_name = service.service_name || service['service-name'];
        let service_cost = Number(service['service-cost']).toFixed(2);
        let servicename = null;
        if (
          service_name === 'Amazon CloudFront' &&
          this.servicesData['cloudfront']['discountedCost']
        ) {
          servicename = 'cloudfront';
        }
        if (
          service_name === 'Amazon Elastic Compute Cloud' &&
          this.servicesData['ec2']['discountedCost']
        ) {
          servicename = 'ec2';
        }
        if (
          service_name === 'Amazon Relational Database Service' &&
          this.servicesData['rds']['discountedCost']
        ) {
          servicename = 'rds';
        }
        if (service.service_name == 'Amazon Elastic Compute Cloud') {
          ec2Index = index;
          servicename = 'ec2';
        } else {
          ec2Index = null;
        }
        data.push({
          text: index + 1,
        });
        data.push({
          text: service_name,
        });
        if (servicename) {
          if (this.servicesData[servicename]['discountedCost']) {
            data.push({
              text:
                Number(this.servicesData[servicename]['discountedCost']) >
                Number(service_cost)
                  ? Number(service_cost)
                  : Number(this.servicesData[servicename]['discountedCost']),
            });
          } else {
            data.push({
              text: this.servicesData[servicename]['service-cost'],
            });
          }
          if (servicename == 'ec2') {
            dict.data.push(data);

            if (totalec2Cost > 0) {
              let data_: any = [];

              data_.push({
                text: '',
              });
              data_.push({
                text: 'EC2',
              });
              data_.push({
                text: Number(totalec2Cost.toFixed(2)),
              });
              dict.data.push(data_);
            }
            if (this.exportData['ec2'].length > 0) {
              dict.data.push([
                { text: '' },
                { text: 'Sl. No.' },
                ...this.ec2Headers
                  .filter((h: any) => {
                    return h.export;
                  })
                  .map((h: any) => {
                    return { text: h['name'] };
                  }),
              ]);

              this.exportData['ec2'].map((ec2: any, index: any) => {
                dict.data.push([
                  { text: '' },
                  { text: index + 1 },
                  ...this.ec2Headers
                    .filter((h: any) => {
                      return h.export;
                    })
                    .map((h: any) => {
                      if (h.alter) {
                        return { text: h.alter(ec2, h.id) || '-' };
                      }
                      return { text: ec2[h['id']] || '-' };
                    }),
                ]);
              });
            }
            if (ebsCost > 0) {
              let data_: any = [];

              data_.push({
                text: '',
              });
              data_.push({
                text: 'EBS',
              });
              data_.push({
                text: Number(ebsCost.toFixed(2)),
              });
              dict.data.push(data_);
            }
            if (this.exportData['ebs'].length > 0) {
              dict.data.push([
                { text: '' },
                { text: 'Sl. No.' },
                ...this.ebsHeaders
                  .filter((h: any) => {
                    return h.export;
                  })
                  .map((h: any) => {
                    return { text: h['name'] };
                  }),
              ]);
              this.exportData['ebs'].map((ec2: any, index: any) => {
                dict.data.push([
                  { text: '' },
                  { text: index + 1 },
                  ...this.ebsHeaders
                    .filter((h: any) => {
                      return h.export;
                    })
                    .map((h: any) => {
                      if (h.alter) {
                        return { text: h.alter(ec2[h.id]) || '-' };
                      }
                      return { text: ec2[h['id']] || '-' };
                    }),
                ]);
              });
            }
            if (othersCost > 0) {
              let data_: any = [];

              data_.push({
                text: '',
              });
              data_.push({
                text: 'Others',
              });
              data_.push({
                text: Number(othersCost.toFixed(2)),
              });
              dict.data.push(data_);
            }
            if (this.exportData['others'].length > 0) {
              dict.data.push([
                { text: '' },
                { text: 'Sl. No.' },
                ...this.othersHeaders
                  .filter((h: any) => {
                    return h.export;
                  })
                  .map((h: any) => {
                    return { text: h['name'] };
                  }),
              ]);
              this.exportData['others'].map((ec2: any, index: any) => {
                dict.data.push([
                  { text: '' },
                  { text: index + 1 },
                  ...this.othersHeaders
                    .filter((h: any) => {
                      return h.export;
                    })
                    .map((h: any) => {
                      if (h.alter) {
                        return { text: h.alter(ec2[h.id]) || '-' };
                      }
                      return { text: ec2[h['id']] || '-' };
                    }),
                ]);
              });
            }
          } else {
            dict.data.push(data);
          }
        } else {
          data.push({
            text: Number(service_cost),
          });
          dict.data.push(data);
        }
      });

    dict.data.push([
      {
        text: '',
      },
    ]);

    dict.data.push([
      {
        text: '',
      },
      {
        text: 'Total',
      },
      {
        text: total_element.innerText,
      },
    ]);

    var options = {
      fileName: `${this.activeAccount.lid}_${this.startOf}-${this.endOf}`,
    };
    Jhxlsx.export([dict], options);
  }

  async fetchMasterAccounts() {
    if (this.masterAccounts.length > 0) {
      this.loadSummaryData($('#masterAccountSelect').val());
      return;
    }
    this.notifier.loading(true);
    let data = {
      lid: [this.accountId],
      userType: 'C',
      action: 'fetchMasterAccList',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = result.masterAccList;
      setTimeout(async () => {
        $('#masterAccountSelect').selectpicker('refresh');
        $('#masterAccountSelect').val(this.masterAccounts[0]);
        $('#masterAccountSelect').selectpicker('refresh');
        await this.loadSummaryData(this.masterAccounts[0]);
      }, 100);
    } else {
      this.summary = [];
      this.activeAccount = null;
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.notifier.loading(false);
    }
  }

  response: any;
  async loadSummaryData(account: any = '') {
    this.notifier.loading(true);
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const accountIdList = selectedAccountData.data;
        let monthYear = moment().startOf('month').format('MM-YYYY');

        let dRange = $('.datetimepicker').val();
        if (dRange) {
          dRange = dRange.toString().split('-');
          monthYear = dRange[0] + '-' + dRange[1];
        }
        this.dateRange = monthYear;
        let data = {
          masterAccId: account && account != '' ? account : undefined,
          month_year: monthYear,
          lid: account && account != '' ? [this.accountId] : accountIdList,
          userType: 'C',
          action: 'supportPlanDetails',
        };
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/${
          account && account != '' ? 'getservicelevelbill' : 'getbills'
        }`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (result.status == '1' || result.s == '1') {
          this.response = result;
          this.summary = result.billDetails.items;
          this.summary = this.summary.map((account: any) => {
            account.services = account.services.map((service: any) => {
              if (service['service_cost']) {
                service['service-cost'] = service['service_cost'];
                service['service-name'] = service['service_name'];
              }
              service['html'] = `
              <span>${Number(service['service-cost']).toFixed(4)}</span>`;
              return service;
            });
            return account;
          });
          if (
            result.appliedSupportPlanInfoList &&
            result.appliedSupportPlanInfoList.length > 0
          ) {
            result.appliedSupportPlanInfoList.forEach((support: any) => {
              let service: any = this.summary[0]['services'].find(
                (service: any) => {
                  return (
                    service['service-name']
                      .toLowerCase()
                      .indexOf(support['supportPlanType'].toLowerCase()) > -1
                  );
                }
              );
              service['service-cost'] = support['updatedRate'].toString();
              if (support['discountedSupportPlanRate'] == 0) {
                service['html'] = `<span>${Number(
                  service['service-cost']
                ).toFixed(4)}</span>`;
              } else {
                service['discountedCost'] =
                  support['discountedSupportPlanRate'].toString();
                if (service['service-cost'] != service['discountedCost']) {
                  service['html'] = `<span class='line_through'>${Number(
                    service['service-cost']
                  ).toFixed(4)}</span><span>${Number(
                    service['discountedCost']
                  ).toFixed(4)}</span>`;
                } else {
                  service['html'] = `<span>${Number(
                    service['service-cost']
                  ).toFixed(4)}</span>`;
                }
              }
            });
          }
          this.response.billDetails.items = this.summary;
          if (this.activeAccount && this.summary.length > 0) {
            this.showData(this, this.summary[0]);
          }
        } else {
          this.summary = [];
          this.activeAccount = null;
          this.notifier.alert('Info', '', result.msg, 'info', 5000);
        }
      } else {
        this.notifier.alert(
          'Error',
          '',
          selectedAccountData.msg,
          'error',
          5000
        );
      }
    } catch (err: any) {
      // console.error(err);

      this.notifier.alert('Info', '', err.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: String[];
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.map((account: any) => {
          return account.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  goBack() {
    this.activeAccount = null;
  }

  callFunction(name: any, id: any) {
    this.funRef[name](this, id);
  }

  getTotalRAW() {
    let raw_html = '';
    if (this.diffDays > 0) {
      let totalCost =
        this.response &&
        !isNaN(this.response.finalODTotalCost) &&
        Number(this.response.finalODTotalCost).toFixed(2);
      raw_html = totalCost;
    } else {
      let totalCost =
        Number(this.servicesData['ec2']['service-cost']) +
        Number(this.servicesData['rds']['service-cost']) +
        Number(this.servicesData['cloudfront']['service-cost']) +
        Number(this.servicesData['others']['service-cost']);
      raw_html = `${totalCost}`;
    }

    return raw_html;
  }

  getDiscountedTotalRAW() {
    let raw_html = '';
    if (this.diffDays > 0) {
      let discountedCost = isNaN(this.response['finalTotalCost'])
        ? '0'
        : this.response['finalTotalCost'].toFixed(2);
      raw_html = discountedCost;
    } else {
      let totalCost =
        Number(this.servicesData['ec2']['service-cost']) +
        Number(this.servicesData['rds']['service-cost']) +
        Number(this.servicesData['cloudfront']['service-cost']) +
        Number(this.servicesData['others']['service-cost']);
      raw_html = `${totalCost}`;
    }

    if (Number(raw_html) < 0) {
      raw_html = `${this.activeAccount.totalCost.toFixed(2) || 0}`;
    }
    return raw_html;
  }

  getPDFTotal() {
    let mainTotal = (
      Number(this.servicesData['ec2']['service-cost']) +
      Number(this.servicesData['rds']['service-cost']) +
      Number(this.servicesData['cloudfront']['service-cost']) +
      Number(this.servicesData['others']['service-cost'])
    ).toFixed(2);
    if (this.diffDays > 0) {
      let totalCost =
        this.response &&
        !isNaN(this.response.finalODTotalCost) &&
        Number(this.response.finalODTotalCost);
      let discountedCost = isNaN(this.response['finalTotalCost'])
        ? '-'
        : this.response['finalTotalCost'].toFixed(2);
      if (totalCost.toFixed(2) == discountedCost) {
        return `<span><strong style='color: black;'>$${
          totalCost > 0 ? totalCost.toFixed(2) : this.getDiscountedTotalRAW()
        }</strong></span>`;
      } else {
        return `<span style='text-decoration: line-through; color: black;'>$${totalCost.toFixed(
          2
        )}</span> <span><strong style='color: black;'>$${discountedCost}</strong></span>`;
      }
    } else {
      return `<span><strong style='color: black;'>$${mainTotal}</strong></span>`;
    }
  }

  getTotal() {
    let mainTotal = (
      Number(this.servicesData['ec2']['service-cost']) +
      Number(this.servicesData['rds']['service-cost']) +
      Number(this.servicesData['cloudfront']['service-cost']) +
      Number(this.servicesData['others']['service-cost'])
    ).toFixed(2);
    if (this.diffDays > 0) {
      let totalCost =
        this.response &&
        !isNaN(this.response.finalODTotalCost) &&
        Number(this.response.finalODTotalCost).toFixed(2);
      let discountedCost = isNaN(this.response['finalTotalCost'])
        ? '-'
        : this.response['finalTotalCost'].toFixed(2);
      if (totalCost == discountedCost) {
        return `<span class='bold_text'>$${totalCost}</span>`;
      } else {
        return `<span class='line_through'>$${
          this.response &&
          !isNaN(this.response.finalODTotalCost) &&
          Number(this.response.finalODTotalCost).toFixed(2)
        }</span><span class='bold_text'>$${
          isNaN(this.response['finalTotalCost'])
            ? '-'
            : this.response['finalTotalCost'].toFixed(2)
        }</span>`;
      }
    } else {
      return `<span class='bold_text'>$${mainTotal}</span>`;
    }
  }

  showData(ref: any, value: any) {
    ref.filterData(ref, JSON.stringify(value));
  }

  filterData(ref: any, value: any) {
    let dt = JSON.parse(value);
    ref.servicesData['ec2']['service-cost'] = 0;
    ref.servicesData['ec2']['discountedCost'] = null;
    ref.servicesData['rds']['service-cost'] = 0;
    ref.servicesData['rds']['discountedCost'] = null;
    ref.servicesData['others']['service-cost'] = 0;
    ref.servicesData['others']['services'] = [];
    ref.servicesData['others']['discountedCost'] = 0;
    ref.servicesData['cloudfront']['service-cost'] = 0;
    ref.servicesData['cloudfront']['discountedCost'] = null;
    dt.services.forEach((service: any, i: number) => {
      service['service-cost'] = Number(service['service-cost']);
      if (service['service-name'] === 'Amazon Relational Database Service') {
        if (this.diffDays > 0) {
          service['discountedCost'] = !isNaN(
            ref.response['finalRDSUpdatedCost']
          )
            ? ref.response['finalRDSUpdatedCost']
              ? Number(Number(ref.response['finalRDSUpdatedCost']).toFixed(2))
              : null
            : null;
          if (
            Number(service['discountedCost']).toFixed(2) ==
            service['service-cost'].toFixed(2)
          ) {
            service['discountedCost'] = null;
          }
        }
        ref.servicesData['rds'] = service;
        return;
      }
      if (service['service-name'] === 'Amazon CloudFront') {
        if (this.diffDays > 0) {
          service['discountedCost'] = !isNaN(ref.response['finalCFRCTotalCost'])
            ? ref.response['finalCFRCTotalCost']
              ? Number(Number(ref.response['finalCFRCTotalCost']).toFixed(2))
              : null
            : null;
          if (
            Number(service['discountedCost']).toFixed(2) ==
            service['service-cost'].toFixed(2)
          ) {
            service['discountedCost'] = null;
          }
        }
        ref.servicesData['cloudfront'] = service;
        return;
      }
      if (service['service-name'] === 'Amazon Elastic Compute Cloud') {
        if (this.diffDays > 0) {
          service['discountedCost'] = !isNaN(
            ref.response['finalEc2OdTotalCost']
          )
            ? ref.response['finalEc2OdTotalCost']
              ? Number(Number(ref.response['finalEc2OdTotalCost']).toFixed(2))
              : null
            : null;
          if (
            Number(service['discountedCost']).toFixed(2) ==
            service['service-cost'].toFixed(2)
          ) {
            service['discountedCost'] = null;
          }
        }
        ref.servicesData['ec2'] = service;
        return;
      }
      ref.servicesData['others']['service-cost'] += Number(
        service['service-cost']
      );

      if (service['discountedCost'] && Number(service['discountedCost']) > 0) {
        ref.servicesData['others']['discountedCost'] += Number(
          service['discountedCost']
        );
      } else {
        ref.servicesData['others']['discountedCost'] += Number(
          service['service-cost']
        );
      }
      ref.servicesData['others'].services.push(service);
    });
    ref.servicesData['rds']['service-cost'] =
      typeof ref.servicesData['rds']['service-cost'] == 'number'
        ? Number(Number(ref.servicesData['rds']['service-cost']).toFixed(2))
        : Number(ref.servicesData['rds']['service-cost']);
    ref.servicesData['ec2']['service-cost'] =
      typeof ref.servicesData['ec2']['service-cost'] == 'number'
        ? Number(Number(ref.servicesData['ec2']['service-cost']).toFixed(2))
        : Number(ref.servicesData['ec2']['service-cost']);
    ref.servicesData['cloudfront']['service-cost'] =
      typeof ref.servicesData['cloudfront']['service-cost'] == 'number'
        ? Number(
            Number(ref.servicesData['cloudfront']['service-cost']).toFixed(2)
          )
        : Number(ref.servicesData['cloudfront']['service-cost']);
    ref.servicesData['others']['service-cost'] =
      typeof ref.servicesData['others']['service-cost'] == 'number'
        ? Number(Number(ref.servicesData['others']['service-cost']).toFixed(2))
        : Number(ref.servicesData['others']['service-cost']);

    if (
      ref.servicesData['others']['discountedCost'] &&
      Number(ref.servicesData['others']['discountedCost']) > 0
    ) {
      ref.servicesData['others']['discountedCost'] = Number(
        Number(ref.servicesData['others']['discountedCost'])
      ).toFixed(2);
    } else {
      delete ref.servicesData['others']['discountedCost'];
    }
    ref.activeAccount = dt;
  }

  getOthers(i: any) {
    if (
      i['service-name'] === 'Amazon Relational Database Service' ||
      i['service-name'] === 'Amazon CloudFront' ||
      i['service-name'] === 'Amazon Elastic Compute Cloud'
    ) {
      return false;
    }
    return true;
  }

  showAnalytics(dt: any) {
    let accountData = { ...dt };
    delete accountData.services;
    this.analytics = {
      currentMonth: this.dateRange,
      account: accountData,
    };
  }

  hideAnalytics(event: any) {
    this.analytics = null;
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    let element = document.createElement('table');
    let total: number = 0;
    element.innerHTML = `
      <thead>
        <tr>
          <th>
            Sl. No.
          </th>
          <th>
            Service Name
          </th>
          <th>
            Service Cost
          </th>
        </tr>
      <thead>
      <tbody>
        ${this.servicesData.others.services.map(
          (service: any, index: number) => {
            let value: number = Number(
              service['discountedCost'] || service['service-cost'] || 0
            );
            total += value;
            return `
            <tr>
              <td>${index + 1}</td>
              <td>${service['service-name']}</td>
              <td>${value.toFixed(4)}</td>
            </tr>
          `;
          }
        )}
        <tr>
          <td></td>
          <td>Total</td>
          <td>${total.toFixed(4)}</td>
        </tr>
      </tbody>
    `;
    window.exportToExcelElement(
      element,
      `services-bills-${this.accountId}`,
      'csv'
    );
  }

  async loadEC2Ddata(dict: any) {
    this.notifier.loading(true);
    let promises: any = [];
    promises.push(this.exportEC2());
    promises.push(this.exportEBS());
    promises.push(this.exportOthers());
    Promise.all(promises).then((values: any) => {
      this.exportCSV();
      this.notifier.loading(false);
    });
  }

  // CSV Export
  exportData: any = { ec2: [], ebs: [], others: [] };
  async exportEC2(lastEvalutedKey: any = null) {
    return new Promise(async (resolve: any, reject: any) => {
      if (!lastEvalutedKey) {
        this.exportData['ec2'] = [];
      }
      let data: any = {
        month_year: $('.datetimepicker').val(),
        lid: this.activeAccount.lid,
        userType: 'C',
        masterAccId: $('#masterAccountSelect')
          ? $('#masterAccountSelect').val()
          : undefined,
        more_instances: lastEvalutedKey ? lastEvalutedKey : undefined,
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        this.exportData['ec2'] = [...this.exportData['ec2'], ...result.items];
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      }

      if (result.more_instances) {
        await this.exportEC2(result.more_instances);
      }
      resolve(false);
    });
  }

  async exportEBS(nextToken: any = null) {
    return new Promise(async (resolve: any, reject: any) => {
      if (!nextToken) {
        this.exportData['ebs'] = [];
      }
      let data: any = {
        month_year: $('.datetimepicker').val(),
        lid: this.activeAccount.lid,
        masterAccId: $('#masterAccountSelect')
          ? $('#masterAccountSelect').val()
          : undefined,
        userType: 'C',
        more_instances: nextToken ? nextToken : undefined,
      };

      if (this.diffDays > 0) {
        data = {
          month_year: $('.datetimepicker').val(),
          lid: this.activeAccount.lid,
          userType: 'C',
          masterAccId: $('#masterAccountSelect').val(),
          more_instances: nextToken ? nextToken : undefined,
          action: 'fetchVolumes',
        };
      }

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/${
        this.diffDays > 0 ? 'ec2breakup' : 'volumedetails'
      }`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1' || result.Items.length > 0) {
        let dt: any = result.Items || result.volumesList;
        dt = dt.map((ebs: any) => {
          ebs.projectTagNameEBS = ebs.projectTagName;
          delete ebs.projectTagName;
          ebs.mapMigratedEBS = ebs.mapMigrated;
          delete ebs.mapMigrated;
          return ebs;
        });
        this.exportData['ebs'] = [...this.exportData['ebs'], ...dt];
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      }
      if (result.more_instances) {
        await this.exportEBS(result.more_instances);
      }
      resolve(true);
    });
  }

  nextTokens: any = {};
  async exportOthers() {
    return new Promise(async (resolve: any, reject: any) => {
      if (!this.nextTokens['others']) {
        this.exportData['others'] = [];
      }
      let data: any = {
        month_year: $('.datetimepicker').val(),
        lid: this.activeAccount.lid,
        masterAccId: $('#masterAccountSelect')
          ? $('#masterAccountSelect').val()
          : undefined,
        more_instances: this.nextTokens['others']
          ? this.nextTokens['others']
          : undefined,
        action: 'fetchTotalOtherDetails',
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/${
        this.diffDays > 0 ? 'ec2breakup' : 'updatedusagedetails'
      }`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1' || result.Items.length > 0) {
        this.exportData['others'] = [
          ...this.exportData['others'],
          ...(result.Items || result.othersDataList),
        ];
      }
      resolve(true);
    });
  }
}
