<div class="card">
  <h5
    class="card-header"
    style="
      display: flex;
      justify-content: flex-center;
      align-items: center;
      gap: 8px;
    "
  >
    <button class="btn btn-light" *ngIf="selectedMID" (click)="back()">
      Back
    </button>
    {{
      selectedMID
        ? selectedMID + " Report (" + selectedDate + ")"
        : "PnL Report"
    }}
  </h5>
  <div class="card-body">
    <div
      style="
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 8px;
      "
    >
      <div
        style="
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          gap: 8px;
        "
        [ngStyle]="{ display: !selectedMID ? 'flex' : 'none' }"
      >
        <div>
          <span>Month Year:</span>
          <span>
            <input type="text" id="monthDate" class="form-control" />
          </span>
        </div>
        <div>
          <button
            class="btn btn-light"
            (click)="loadMasterAccounts()"
            style="background-color: #5864ff; color: white"
          >
            Refresh
          </button>
          <button
            class="btn btn-light"
            style="background-color: #5864ff; color: white"
            (click)="triggerFIle()"
          >
            Upload
          </button>
          <input
            type="file"
            name=""
            id="fileInput"
            style="display: none"
            (change)="checkFile($event)"
            #fileInput
          />
        </div>
        <div>
          <button
            class="btn btn-light"
            style="background-color: #5864ff; color: white"
            (click)="completeExcelApi()"
          >
            Download All
          </button>
        </div>
      </div>
      <div class="flex-start" *ngIf="selectedMID && !editMode">
        <button
          class="btn btn-light"
          style="background-color: #5864ff; color: white"
          (click)="loadErp(this, selectedMID)"
        >
          ERP
        </button>
      </div>
      <div class="flex-start" *ngIf="selectedMID && !editMode">
        <button
          class="btn btn-light"
          (click)="loadChildData(this, null, selectedMID)"
          style="background-color: #5864ff; color: white"
        >
          Refresh
        </button>
      </div>
      <div
        class="flex-start"
        *ngIf="(selectedMID ? childAccountData : cfrcData).length > 0"
      >
        <button
          class="btn btn-light"
          style="background-color: #5864ff; color: white"
          (click)="export()"
          *ngIf="!editMode"
        >
          Download Excel
        </button>
        <button
          class="btn btn-light"
          style="background-color: #5864ff; color: white"
          *ngIf="selectedMID && editMode"
          (click)="saveDetails()"
        >
          Save
        </button>
        <button
          class="btn btn-light"
          style="background-color: #5864ff; color: white"
          *ngIf="selectedMID"
          (click)="edit()"
        >
          {{ editMode ? "Exit" : "Edit" }}
        </button>
      </div>
    </div>
    <div style="max-width: 100%; overflow: auto">
      <div class="table-container">
        <table class="table table-docs table-hover" id="cloudFrontData">
          <thead class="table-header">
            <tr>
              <th>S.N.</th>
              <ng-container
                *ngFor="let h of selectedMID ? child_headers : headers"
              >
                <th *ngIf="!h.conditionalShow || showConditionalElement">
                  {{ h.name }}
                  <span
                    *ngIf="h.sort && h.sort.sort"
                    (click)="notifier.sortTable(h, cfrcData)"
                  >
                    <i
                      class="fa fa-sort-amount-desc"
                      aria-hidden="true"
                      *ngIf="h.sort.direction == 'Des'"
                    ></i>
                    <i
                      class="fa fa-sort-amount-asc"
                      aria-hidden="true"
                      *ngIf="h.sort.direction == 'Asc'"
                    ></i>
                  </span>

                  <span *ngIf="h.filter" class="inputseach">
                    <input type="text" [(ngModel)]="h.filterText" />
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </th>
              </ng-container>
            </tr>
          </thead>

          <tbody
            [ngStyle]="{
              display:
                (selectedMID ? child_headers : headers).length > 0
                  ? 'table-row-group'
                  : 'none'
            }"
          >
            <ng-container
              *ngFor="
                let i of selectedMID ? childAccountData : cfrcData;
                let j = index
              "
            >
              <tr
                [ngClass]="{ hoverable: !selectedMID }"
                *ngIf="
                  notifier.filterTableColums(
                    selectedMID ? child_headers : headers,
                    i
                  )
                "
              >
                <td>{{ j + 1 }}</td>
                <ng-container
                  *ngFor="let h of selectedMID ? child_headers : headers"
                >
                  <td *ngIf="!h.conditionalShow || showConditionalElement">
                    <span
                      *ngIf="h.click"
                      class="btn"
                      (click)="callFunction(h.click, i)"
                    >
                      {{ i[h.id] }}
                    </span>
                    <div
                      *ngIf="!h.click"
                      [ngStyle]="{
                        color: h.color ? h.color(i[h.id]) : 'inherit'
                      }"
                    >
                      <span *ngIf="!h.editable || !editMode">
                        {{ h.alter ? h.alter(i, h.id) : i[h.id] }}
                      </span>
                      <span *ngIf="h.editable && editMode">
                        <input
                          type="text"
                          [(ngModel)]="i[h.id]"
                          [disabled]="h.id == 'erp' && !i['erpEdit']"
                        />
                      </span>

                      <span
                        *ngIf="
                          selectedMID && h.icon && (!h.editable || !editMode)
                        "
                      >
                        <i
                          (click)="loadErp(this, selectedMID, i['lId'])"
                          class="fa-solid fa-arrows-rotate load_icon_style"
                        ></i>
                      </span>
                    </div>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </tbody>
          <tbody
            *ngIf="(selectedMID ? childAccountData : cfrcData).length == 0"
          >
            <tr>
              <td
                [attr.colspan]="
                  (selectedMID ? child_headers : headers).length + 1
                "
              >
                <div style="text-align: center">No Data Found</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div
  style="
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.1);
  "
  [ngStyle]="{ display: upload ? 'block' : 'none' }"
>
  <div
    style="
      position: relative;
      margin: 10px;
      gap: 10px;
      background-color: white;
      border-radius: 10px;
      padding: 75px 20px 20px 20px;
      box-shadow: 1px 1px 3px 1px grey;
      display: flex;
      flex-direction: column;
      max-height: calc(100% - 20px);
    "
  >
    <div
      style="
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 10px;
        cursor: pointer;
      "
      (click)="closeUpload()"
      class="btn btn-light"
    >
      X
    </div>
    <div style="overflow: auto; flex: 1">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th *ngFor="let h of editableHeader">
              {{ h.name }}
            </th>
          </tr>
        </thead>

        <tbody
          [ngStyle]="{
            display: editable.length > 0 ? 'table-row-group' : 'none'
          }"
        >
          <tr *ngFor="let i of editable; let j = index">
            <td *ngFor="let h of editableHeader">
              <span
                *ngIf="h.click"
                class="btn"
                (click)="callFunction(h.click, i)"
              >
                <span [innerHTML]="i[h.id]"> </span>
              </span>
              <span *ngIf="!h.click && !h.editable">
                {{ h.alter ? h.alter(i[h.id]) : i[h.id] }}
              </span>
              <span *ngIf="!h.click && h.editable">
                <input class="form-control" type="text" [(ngModel)]="i[h.id]" />
              </span>
            </td>
          </tr>
        </tbody>
        <tbody
          [ngStyle]="{
            display: editable.length == 0 ? 'table-row-group' : 'none'
          }"
        >
          <tr>
            <td [attr.colspan]="editableHeader.length">
              <div style="text-align: center">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      style="
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 15px;
      "
    >
      <button
        class="btn btn-light"
        (click)="saveDetails()"
        style="background-color: #5864ff; color: white"
      >
        Save Details
      </button>
    </div>
  </div>
</div>
