<h5 class="card-title">Reports</h5>
<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button class="nav-link active" id="daily-reports-tab" data-bs-toggle="tab" data-bs-target="#daily-reports"
      type="button" role="tab" aria-controls="daily-reports" aria-selected="true" (click)="changeReportType('daily')">
      Daily
    </button>
    <button class="nav-link" id="weekly-reports-tab" data-bs-toggle="tab" data-bs-target="#weekly-reports" type="button"
      role="tab" aria-controls="weekly-reports" aria-selected="false" (click)="changeReportType('weekly')">
      Weekly
    </button>
  </div>
</nav>

<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active" id="daily-reports" role="tabpanel" aria-labelledby="daily-reports-tab">
    <div class="card" style="border-top: none !important">
      <!--<h5 class="card-header"></h5>-->

      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->
        <div style="display: flex; justify-content: center; align-items: flex-start; gap: 15px;">
          <div class="report-types-flex mb-10"
            style="flex: 1; padding-right: 20px; border-right: 1px solid rgb(204, 200, 200);">
            <table class="table table-docs">
              <thead>
                <tr>
                  <th>Sl. No.</th>
                  <th>Report Type</th>
                  <!-- <th>Description</th> -->
                  <th>Enable/Disable</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let report of reports; let i = index">
                  <td>{{i + 1}}</td>
                  <td>{{reportsMapper[report.report_type]['name']}}</td>
                  <!-- <td>{{reportsMapper[report.report_type]['name']}}</td> -->

                  <td>
                    <span>
                      <label class="switch">
                        <input type="checkbox" [(ngModel)]="report.selected" (change)="toggleReports()">
                        <span class="slider round"></span>
                      </label>
                    </span>
                  </td>
                </tr>
                <tr *ngIf="loading">
                  <td colspan="4" style="text-align: center;">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 20px;" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="width: 350px;">
            <div class="label" style="font-weight: bold; padding: 8px 8px 20px 8px; color: #aaaaaa;">Email IDs</div>

            <div class="additional_contacts">
              <div class="additional_contact" style="width: auto;" *ngFor="
                                                                                        let contact of additionalContacts;
                                                                                        let i = index;
                                                                                        trackBy: trackByFn
                                                                                      ">
                <input type="text" name="contact_value" class="form-control" *ngIf="additionalContacts.length - 3 < i"
                  (keyup)="checkForLast(contact, i)" [(ngModel)]="additionalContacts[i]" />
                <input type="text" name="contact_value" class="form-control" *ngIf="additionalContacts.length - 3 >= i"
                  [(ngModel)]="additionalContacts[i]" />
                <div>
                  <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add"
                    *ngIf="additionalContacts.length == i + 1" (click)="addAdditionalContact()"></i>
                </div>
                <div>
                  <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove"
                    *ngIf="additionalContacts.length > 1" (click)="deleteSelectedAdditionalContact(i)"></i>
                </div>
              </div>
            </div>
            <div *ngIf="!arrayEquals(prevContacts, additionalContacts) && selectedLength() > 0" style="padding: 5px;">
              <button type="button" class="btn btn-light"
                [ngClass]="{'click-disabled': !writeAccess, dim_button: enabling}"
                [attr.disabled]="!writeAccess ? true : null"
                style="background-color: #5864ff; color: white; display: flex; align-items: center; justify-content: flex-start; gap: 6px;"
                (click)="!enabling && toggleReports()">
                <span [ngStyle]="{display: enabling ? 'block' : 'none'}">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 16px;" />
                </span>
                <span style="color: inherit;">
                  Update
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="weekly-reports" role="tabpanel" aria-labelledby="weekly-reports-tab">
    <div class="card" style="border-top: none !important">
      <!--<h5 class="card-header"></h5>-->
      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->
        <div style="display: flex; justify-content: center; align-items: flex-start; gap: 15px;">
          <div class="report-types-flex mb-10"
            style="flex: 1; padding-right: 20px; border-right: 1px solid rgb(204, 200, 200);">
            <table class="table table-docs">
              <thead>
                <tr>
                  <th>Sl. No.</th>
                  <th>Report Type</th>
                  <!-- <th>Description</th> -->
                  <th>Enable/Disable</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let report of reports; let i = index">
                  <td>{{i + 1}}</td>
                  <td>{{reportsMapper[report.report_type]['name']}}</td>
                  <!-- <td>{{reportsMapper[report.report_type]['name']}}</td> -->

                  <td>
                    <span>
                      <label class="switch">
                        <input type="checkbox" [(ngModel)]="report.selected" (change)="toggleReports()">
                        <span class="slider round"></span>
                      </label>
                    </span>
                  </td>
                </tr>
                <tr *ngIf="loading">
                  <td colspan="4" style="text-align: center;">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 20px;" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="width: 350px;">
            <div class="label" style="font-weight: bold; padding: 8px 8px 20px 8px; color: #aaaaaa;">Email IDs</div>

            <div class="additional_contacts">
              <div class="additional_contact" style="width: auto;" *ngFor="
                                                                                              let contact of additionalContacts;
                                                                                              let i = index;
                                                                                              trackBy: trackByFn
                                                                                            ">
                <input type="text" name="contact_value" class="form-control" *ngIf="additionalContacts.length - 3 < i"
                  (keyup)="checkForLast(contact, i)" [(ngModel)]="additionalContacts[i]" />
                <input type="text" name="contact_value" class="form-control" *ngIf="additionalContacts.length - 3 >= i"
                  [(ngModel)]="additionalContacts[i]" />
                <div>
                  <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add"
                    *ngIf="additionalContacts.length == i + 1" (click)="addAdditionalContact()"></i>
                </div>
                <div>
                  <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove"
                    *ngIf="additionalContacts.length > 1" (click)="deleteSelectedAdditionalContact(i)"></i>
                </div>
              </div>
            </div>
            <div *ngIf="!arrayEquals(prevContacts, additionalContacts) && selectedLength() > 0" style="padding: 5px;">
              <button type="button" class="btn btn-light"
                [ngClass]="{'click-disabled': !writeAccess, dim_button: enabling}"
                [attr.disabled]="!writeAccess ? true : null"
                style="background-color: #5864ff; color: white; display: flex; align-items: center; justify-content: flex-start; gap: 6px;"
                (click)="!enabling && toggleReports()">
                <span [ngStyle]="{display: enabling ? 'block' : 'none'}">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 16px;" />
                </span>
                <span style="color: inherit;">
                  Update
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>