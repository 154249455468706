import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotifierService } from '../_services/notifier.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  constructor(
    private http: HttpClient,
    public notifier: NotifierService,
    public navigate: Router
  ) {}

  public static get API_ENDPOINT(): string {
    return 'https://api.swayam.cloud/prod';
  }
  public static get API_ENDPOINTClient(): string {
    return 'https://api.swayam.cloud/prod/client';
  }
  public static get API_ENDPOINTAdmin(): string {
    return 'https://api.swayam.cloud/prod/msopsusers';
  }
  public static get API_ENDPOINTFiles(): string {
    return 'https://api.swayam.cloud';
  }

  public static get API_ENDPOINTScheduler(): string {
    return 'https://api.swayam.cloud/v3/admin/scheduler';
  }

  public static get API_ENDPOINTV3(): string {
    return 'https://api.swayam.cloud/v3';
  }

  public static get API_ENDPOINTMongo(): string {
    return 'http://43.204.90.89:14189';
  }

  public static get API_ENDPOINTSchedulerOtp(): string {
    return 'https://api.swayam.cloud/v3/admin/operations/schedulerotp';
  }

  public static get OTP_Enabled(): boolean {
    return true;
  }

  query(url: string, data: any, header: any = {}): Observable<any> {
    data.newConfigver = 2.5;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    for (var i in header) {
      headers = headers.set(i, header[i]);
    }

    return this.http.post(url, JSON.stringify(data), { headers: headers });
  }

  postDataPromis(url: string, data: any, header: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      //if (!data.ver) data.newConfigver = 2.5;
      //if (!data.role) data.newConfigrole = localStorage.getItem("role");

      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');

      for (var i in header) {
        headers = headers.set(i, header[i]);
      }

      this.http.post(url, JSON.stringify(data), { headers: headers }).subscribe(
        (data: any) => {
          if (Object.keys(data).length == 0) {
            data['status'] = '0';
            data['error'] = 'Data not Found';
          }
          return resolve(data);
        },
        (error) => {
          let errMsg: string;

          if (error.status == 401 || error.status == '401') {
            this.notifier.alert(
              'Error',
              '',
              error.error.message == 'The incoming token has expired'
                ? 'Session Expired. Please login'
                : error.error.message,
              'error',
              5000
            );
            this.notifier.loading(false);
            let redirect: string =
              window.location.pathname + window.location.search;
            window.location.href = `/login?redirectURL=${redirect}`;

            return;
          } else if (error.status == 0 || error.status == '0') {
            this.notifier.alert(
              'Error',
              '',
              'Session Expired. Please Login',
              'error',
              5000
            );
            this.notifier.loading(false);
            let redirect: string =
              window.location.pathname + window.location.search;
            window.location.href = `/login?redirectURL=${redirect}`;

            return;
          }

          if (error instanceof Response) {
            let dt: any = {};
            const body = error.json() || dt;
            if (body.error_message) {
              body.error = body.error_message;
            }

            return resolve(body);

            //const err = JSON.stringify(body); // body.error ||
            //errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
          } else {
            if (error.error.error_message) {
              error.error.error = error.error.error_message;
            }
          }
          return resolve(error.error);
        }
      );
    });
  }

  getDataPromisXML(url: string, data: any, header: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      //if (!data.ver) data.newConfigver = 2.5;
      //if (!data.role) data.newConfigrole = localStorage.getItem("role");

      let headers = new HttpHeaders();
      headers = headers.set(
        'Content-Type',
        'application/atom+xml;type=feed;charset=utf-8'
      );

      for (var i in header) {
        headers = headers.set(i, header[i]);
      }

      this.http.get(url, { headers: headers }).subscribe(
        (data: any) => {
          if (Object.keys(data).length == 0) {
            data['status'] = '0';
            data['error'] = 'Data not Found';
          }
          return resolve(data);
        },
        (error) => {
          let errMsg: string;

          if (error.status == 401 || error.status == '401') {
            this.notifier.alert(
              'Error',
              '',
              error.error.message == 'The incoming token has expired'
                ? 'Session Expired. Please login'
                : error.error.message,
              'error',
              5000
            );
            this.notifier.loading(false);
            let redirect: string =
              window.location.pathname + window.location.search;
            window.location.href = `/login?redirectURL=${redirect}`;

            return;
          } else if (error.status == 0 || error.status == '0') {
            this.notifier.alert(
              'Error',
              '',
              'Session Expired. Please Login',
              'error',
              5000
            );
            this.notifier.loading(false);
            let redirect: string =
              window.location.pathname + window.location.search;
            window.location.href = `/login?redirectURL=${redirect}`;

            return;
          }

          if (error instanceof Response) {
            let dt: any = {};
            const body = error.json() || dt;
            if (body.error_message) {
              body.error = body.error_message;
            }

            return resolve(body);

            //const err = JSON.stringify(body); // body.error ||
            //errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
          } else {
            if (error.error.error_message) {
              error.error.error = error.error.error_message;
            }
          }
          return resolve(error.error);
        }
      );
    });
  }

  getDataPromis(url: string, data: any, header: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      //if (!data.ver) data.newConfigver = 2.5;
      //if (!data.role) data.newConfigrole = localStorage.getItem("role");

      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');

      for (var i in header) {
        headers = headers.set(i, header[i]);
      }

      this.http.get(url, { headers: headers }).subscribe(
        (data) => {
          //this.getDataPromisLog(url, data, header);
          return resolve(data);
        },
        (error) => {
          let errMsg: string;
          if (error instanceof Response) {
            const body = error.json() || '';
            const err = JSON.stringify(body); // body.error ||
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
          } else {
            errMsg = error.message ? error.message : error.toString();
          }
          return resolve(error);
        }
      );
    });
  }

  getDataPromisLog(url: string, data: any, header: any = {}) {
    return new Promise((resolve, reject) => {
      //if (!data.ver) data.newConfigver = 2.5;
      //if (!data.role) data.newConfigrole = localStorage.getItem("role");

      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');

      for (var i in header) {
        headers = headers.set(i, header[i]);
      }

      this.http.get('', { headers: headers }).subscribe(
        (data) => {
          return resolve(true);
        },
        (error) => {
          let errMsg: string;
          if (error instanceof Response) {
            const body = error.json() || '';
            const err = JSON.stringify(body); // body.error ||
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
          } else {
            errMsg = error.message ? error.message : error.toString();
          }
          return resolve(errMsg);
        }
      );
    });
  }

  postDataPromisNew(url: string, data: any, header: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      //if (!data.ver) data.newConfigver = 2.5;
      //if (!data.role) data.newConfigrole = localStorage.getItem("role");

      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');

      for (var i in header) {
        headers = headers.set(i, header[i]);
      }

      this.http.post(url, JSON.stringify(data), { headers: headers }).subscribe(
        (data) => {
          return resolve(data);
        },
        (error) => {
          let errMsg: string;

          //if (error.status == 401 || error.status == '401') {
          //  this.notifier.alert(
          //    'Error',
          //    '',
          //    error.error.message == 'The incoming token has expired'
          //      ? 'Session Expired. Please login'
          //      : error.error.message,
          //    'error',
          //    5000
          //  );
          //  this.notifier.loading(false);
          //      this.router.navigate(['/login'], { queryParams: { returnUrl: this.navigate.url } });

          //  return;
          //} else if (error.status == 0 || error.status == '0') {
          //  this.notifier.alert(
          //    'Error',
          //    '',
          //    'Session Expired. Please Login',
          //    'error',
          //    5000
          //  );
          //  this.notifier.loading(false);
          //      this.router.navigate(['/login'], { queryParams: { returnUrl: this.navigate.url } });

          //  return;
          //}

          if (error instanceof Response) {
            let dt: any = {};
            const body = error.json() || dt;
            if (body.error_message) {
              body.error = body.error_message;
            }
            body.errorStatus = error.status;
            return resolve(body);

            //const err = JSON.stringify(body); // body.error ||
            //errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
          } else {
            if (error.error) {
              if (error.error.error_message) {
                error.error.error = error.error.error_message;
              }
            }
          }
          return resolve({ errorStatus: error.status, error: error.error });
        }
      );
    });
  }
}
