import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../../../api/api.service';
import { NotifierService } from '../../../../../_services/notifier.service';

declare let $: any;
declare let window: any;
@Component({
  selector: 'step3-uploadbom-v2',
  styleUrls: [
    './../../upload.bom.component.css',
    './step3.uploadbomv2.component.css',
  ],
  templateUrl: './step3.uploadbomv2.component.html',
})
export class Step3UploadBOMv2Component implements OnInit, OnDestroy {
  @Input('headers') headers: any;
  selectedHeaders: any = [];
  @Input('pricingStrategy') pricingStrategy: any;
  @Input('bom') bom: any;

  plans: any = [
    {
      plan: 'OD',
      duration_: 2,
    },
    {
      plan: '1YNU',
      duration: '1 Year',
      duration_: 12,
    },
    {
      plan: '1YCNU',
      duration: '1 Year',
      duration_: 12,
    },
    { plan: 'S1YNU', duration: '1 Year', duration_: 12 },
    { plan: 'S1YAU', duration: '1 Year', duration_: 12 },
    { plan: 'S1YPU', duration: '1 Year', duration_: 12 },
    {
      plan: '3YNU',
      duration: '3 Years',
      duration_: 36,
    },
    {
      plan: '3YCNU',
      duration: '3 Years',
      duration_: 36,
    },
    { plan: 'S3YNU', duration: '3 Years', duration_: 36 },
    { plan: 'S3YAU', duration: '3 Years', duration_: 36 },
    { plan: 'S3YPU', duration: '3 Years', duration_: 36 },
    {
      plan: '1 YNU Spot Min',
      duration: '1 Year',
      duration_: 12,
    },
    {
      plan: '1 YCNU Spot Min',
      duration: '1 Year',
      duration_: 12,
    },
    {
      plan: '1 YNU Spot Max',
      duration: '1 Year',
      duration_: 12,
    },
    {
      plan: '1 YCNU Spot Max',
      duration: '1 Year',
      duration_: 12,
    },
    {
      plan: '3 YNU Spot Min',
      duration: '3 Years',
      duration_: 36,
    },
    {
      plan: '3 YCNU Spot Min',
      duration: '3 Years',
      duration_: 36,
    },
    {
      plan: '3 YNU Spot Max',
      duration: '3 Years',
      duration_: 36,
    },
    {
      plan: '3 YCNU Spot Max',
      duration: '3 Years',
      duration_: 36,
    },
  ];

  loading: boolean = true;
  regionId: any;
  accountId: any;
  currentMessage: any;
  constructor(private notifier: NotifierService) {}
  ngOnInit(): void {
    this.selectedHeaders = this.headers.filter((header: any) => {
      if (header.id != 'exclude') {
        return true;
      }
      return false;
    });
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
    });
    this.loading = false;
  }

  checkForOD(value: any) {
    return this.pricingStrategy[value.key]['plans'].find((plan: any) => {
      return plan.plan == 'OD';
    });
  }

  addStrategy(value: any) {
    let strategy = {
      plan: this.checkForOD(value) ? '1YNU' : 'OD',
      hours_per_month: '',
      duration: '',
      period: 'Days',
    };

    if (this.pricingStrategy[value.key]) {
      this.pricingStrategy[value.key]['plans'].push(strategy);
    } else {
      this.pricingStrategy[value.key] = {
        env_type: value.id,
        plans: [],
        start_date: '',
        end_date: '',
      };
    }

    this.setEndDate(this.pricingStrategy[value.key]['start_date'], value.key);
  }

  reomveStrategy(value: string, index: number) {
    if (this.pricingStrategy[value]) {
      this.pricingStrategy[value]['plans'].splice(index, 1);
      this.setEndDate(this.pricingStrategy[value]['start_date'], value);
    }
  }

  getArray(plan: string) {
    return Array(this.getDuration(plan).duration_)
      .fill(1)
      .map((x, i) => i); // [0,1,2,3,4]
  }

  setEndDate(date: string, value: string) {
    this.pricingStrategy[value]['start_date'] = moment(
      date,
      'DD-MMM-YYYY'
    ).format('DD-MM-YYYY');
    let duration: any = 0;
    if (date && date == '') {
      this.pricingStrategy[value]['end_date'] = '-';
      return;
    }
    if (this.pricingStrategy[value]) {
      let od_days = 0;
      let temp = JSON.parse(JSON.stringify(this.pricingStrategy))[value];
      if (temp && temp['plans'].length > 0) {
        temp['plans'] = temp['plans'].map((strat: any) => {
          if (strat.plan == 'OD' && strat.period == 'Months') {
            let moment_obj = moment(date, 'DD-MMM-YYYY').add(
              strat.duration,
              'months'
            );
            od_days = moment
              .duration(moment_obj.diff(moment(date, 'DD-MMM-YYYY')))
              .asDays();
            od_days = Math.ceil(od_days);
          } else if (strat.plan == 'OD' && strat.period == 'Days') {
            let moment_obj = moment(date, 'DD-MMM-YYYY').add(
              strat.duration,
              'days'
            );
            od_days = moment
              .duration(moment_obj.diff(moment(date, 'DD-MMM-YYYY')))
              .asDays();
          }
          return strat;
        });
      }
      this.pricingStrategy[value]['plans'].forEach((ps: any) => {
        if (ps.plan == 'OD') {
          return;
        }
        duration += this.getArray(ps.plan).length;
      });

      let end_date = moment(date, 'DD-MMM-YYYY').add(duration, 'months');

      if (od_days) {
        end_date.add(od_days, 'days');
      }
      this.pricingStrategy[value]['end_date'] = end_date.format('DD-MM-YYYY');
      this.pricingStrategy[value]['end_date'] =
        this.pricingStrategy[value]['end_date'].toLowerCase() == 'Invalid date'
          ? '-'
          : this.pricingStrategy[value]['end_date'];
    }
  }

  getDuration(plan_str: string) {
    return this.plans.find((plan: any) => {
      return plan.plan == plan_str;
    });
  }

  formatDate(
    date: string,
    required_format: string,
    current_format: string = null
  ) {
    if (current_format) {
      return moment(date, current_format).format(required_format);
    } else {
      return moment(date).format(required_format);
    }
  }

  ngOnDestroy(): void {}
}
