<div *ngIf="viewer" class="image_viewer" [ngClass]="{image_viewer_show: viewer.show}">
  <div class="image_viewer_controller">
    <div class="controllers_parent">
      <div class="image_control" title="Zoom Out" (click)="viewer.width = viewer.width - 10"><i
          class="fa fa-search-minus" aria-hidden="true"></i>
      </div>
      <div class="image_control" title="Zoom In" (click)="viewer.width = viewer.width + 10"><i class="fa fa-search-plus"
          aria-hidden="true"></i>
      </div>
      <div class="image_control" title="Download" (click)="downloadAttachment(viewer)"><i class="fa fa-download"
          aria-hidden="true"></i>
      </div>
      <div class="image_control" title="Close" (click)="hideImage()"><i class="fa fa-times" aria-hidden="true"></i>
      </div>
    </div>
  </div>
  <div class="image_viewer_name">
    {{viewer.file_name}}
  </div>
  <div style="position: absolute; top: 10px; right: 10px; left: 10px; bottom: 10px;">
    <div class="image_viewer_container">
      <div style="text-align: center;">
        <img class='image_link' style="transition: 0.2s; min-width: 500px;" [ngStyle]="{width: viewer.width + '%'}"
          [src]="viewer.src" alt="Loading...">
      </div>
    </div>
  </div>
</div>
<div class="card mt-20" style="border: none;" [ngStyle]="{'display': !activeTicket && !createTicket ? 'block': 'none'}">
  <h5 class="card-header">View Tickets</h5>
  <div class="card-body">
    <div class="support_filter">
      <div class="support_select">
        <div class="support_header">Support Type</div>
        <div class="support_types_container" style="margin: 10px">
          <div class="support_type_container pointer" [ngClass]="{
              support_type_selected: supportType == 'minfy' && !minfyDisabled,
              selected_type_disabled: minfyDisabled
            }">
            <div class="support_type_box" (click)="changeSupportType('minfy')">
              <div class="support_type_logo">
                <div class="outer">
                  <div class="inner"></div>
                </div>
              </div>
              <div>
                <div class="support_type_text">Minfy Support</div>
                <div class="support_type_desc">Managed Services from Minfy</div>
              </div>
            </div>
          </div>
          <div class="support_type_container pointer" [ngClass]="{
              support_type_selected: supportType == 'aws' && !awsDisabled,
              selected_type_disabled: awsDisabled
            }">
            <div class="support_type_box" (click)="changeSupportType('aws')">
              <div class="support_type_logo">
                <img src="../../../assets/img/aws_logo_1.png" alt="" />
              </div>
              <div>
                <div class="support_type_text">AWS Support</div>
                <div class="support_type_desc">
                  Support from Amazon Web Services
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="p-1">
        <input type="radio" name="supportType" id="minfySupport" checked/>
        <label for="minfySupport">Minfy Support</label>
      </div>
      <div class="p-1">
        <input type="radio" name="supportType" id="awsSupport" />
        <label for="awsSupport">AWS Support</label>
      </div> -->
        </div>
      </div>
      <div class="support_select" [ngStyle]="{'display': supportType == 'minfy' ? 'flex' : 'none'}">
        <div class="support_header">Tickets Between</div>
        <div class="support_types_container">
          <div>
            <input class="datetimepicker" type="text" [(ngModel)]="dateRange" />
          </div>
        </div>
      </div>
      <div class="support_select">
        <div class="support_header">Action</div>
        <div class="support_types_container">
          <div>
            <button class="btn" (click)="load()" style="background-color: #5864ff; color: white">
              Refresh
            </button>
            <button class="btn" *ngIf="tickets.length > 0" style="background-color: #5864ff; color: white"
              (click)="export()">
              Export CSV
            </button>
          </div>
        </div>
      </div>
      <div class="support_select">
        <div class="support_types_container">
          <div>
            <span>Tickets: {{tickets.length}} / {{totalTickets}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="support_select" style="padding: 5px 15px;">
      <div class="support_header">Filter</div>
      <div class="support_types_container">
        <div style="position: relative;">
          <input type="checkbox" id="include_resolved_checkbox" name="support_type" value="minfy"
            [(ngModel)]="includeResolved" (change)="load()" />
          <label for="include_resolved_checkbox">Include Resolved</label>
          <div
            style="position: absolute; top: 50%; transform: translateY(-50%); height: 30px; bottom: -5px; right: -12px; border-right: 1px solid #aaaaaa; width: 5px;">
          </div>
        </div>
        <div style="position: relative;">
          <div class="filters_container" [ngStyle]="{opacity: supportType == 'minfy' && !minfyDisabled ? '1': '0' }">
            <div class="search_container">
              <div style="padding: 0; margin: 0; position: relative;" class="key_dropdown">
                <select class="search_dropdown" [(ngModel)]="filterKey" (change)="checkForValues()">
                  <option *ngFor="let key of filterKeys" [value]="key.id" [innerText]="key.name"></option>
                </select>
                <i class="fa fa-caret-down" aria-hidden="true"
                  style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none; font-size: 22px; padding: 5px; background-color: white; "></i>
              </div>
              <div *ngIf="filterKey != 'priority' && filterKey != 'reporter'">
                <div class="input_field_container">
                  <div style="flex: 1;">
                    <input type="text" [(ngModel)]="filterValue" class="search_input">
                  </div>
                  <div>
                    <i class="fa fa-times" aria-hidden="true" style="padding: 5px; cursor: pointer;"
                      (click)="filterValue = ''"></i>
                  </div>
                </div>
              </div>
              <div [ngStyle]="{display: filterKey == 'priority' || filterKey == 'reporter' ? 'block': 'none'}">
                <div style="padding: 0; margin: 0; position: relative;">
                  <select id="search_dropdown" class="search_dropdown" [(ngModel)]="filterValue"
                    data-live-search="true">
                    <option *ngFor="let key of dropDownKeyValues" [value]="key.id" [innerText]="key.name">
                    </option>
                  </select>
                  <i class="fa fa-caret-down" aria-hidden="true"
                    style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none; font-size: 22px; padding: 5px;"></i>
                </div>
              </div>
              <div *ngIf="loadingSearchValues">
                <img src="../assets/img/loading_2.svg" alt="loading scans" width="10"
                  style="width: 20px; margin: 10px;">
              </div>
              <div class="search_icon" (click)="clearFilters()" title="Clear Filter">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
              <div class="search_icon" (click)="load()" title="Apply Filter">
                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div style="max-width: 100%; overflow: auto;">
      <table class="table table-docs table-hover mt-20" id="view-tickets-table">
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of supportType == 'minfy' ? minfyHeaders : awsHeaders">
              {{ h.name }}
              <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,tickets)">

                <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
              </span>

              <span *ngIf="h.filter " class="inputseach">
                <input type="text" [(ngModel)]="h.filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
            <th *ngIf="writeAccess">
              {{ supportType == "minfy" ? "AWS Support" : "Minfy Support" }}
            </th>
          </tr>
        </thead>
        <tbody [ngStyle]="{'display': tickets.length > 0 ? 'table-row-group' : 'none'}"
          *ngFor="let i of tickets; let j = index">
          <tr *ngIf="notifier.filterTableColums(supportType == 'minfy' ? minfyHeaders : awsHeaders ,i)">
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of supportType == 'minfy' ? minfyHeaders : awsHeaders">
              <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                <span>
                  {{ i[h.id] }}
                </span>
              </span>
              <span *ngIf="!h.click">
                <span *ngIf="h.pipe">
                  {{ h.pipe(i[h.id]) }}
                </span>
                <span *ngIf="!h.pipe">
                  {{ i[h.id] }}
                </span>
              </span>
            </td>
            <td *ngIf="writeAccess">
              <span (click)="createTicketTrigger(i)" class="btn">{{
                supportType == "minfy" ? "AWS Support" : "Minfy Support"
                }}</span>
            </td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': tickets.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="(supportType == 'minfy' ? minfyHeaders : awsHeaders).length + 2">
              <div style="text-align: center;">No Tickets Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="display: flex; justify-content: right;" *ngIf="nextToken">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;"
        (click)="supportType == 'minfy' ? loadMinfySupportTickets() : loadAWSSupportTickets() ">Next</button>
    </div>
  </div>
</div>
<div *ngIf="activeTicket">
  <div class="card mt-20" style="border: none;">
    <h5 class="card-header">
      <button class="btn btn-light" (click)="goBack()">
        < Back</button>
          <span>Ticket - {{ activeTicket.issue_key || activeTicket.caseId }}</span>
    </h5>
    <div class="card-body">
      <div class="support_filter" *ngIf="writeAccess" style="justify-content: flex-end">
        <div class="support_select">
          <div class="support_header">Action</div>
          <div class="support_types_container">
            <div>
              <button class="btn" style="background-color: #5864ff; color: white" (click)="refreshCorrespondence()">
                Refresh
              </button>
              <button class="btn" style="background-color: #5864ff; color: white"
                *ngIf="supportType == 'aws' && (activeTicket.status == 'resolved' || activeTicket.status == 'unassigned')"
                (click)="reOpenTicket()" [ngClass]="{'click-disabled': !writeAccess}"
                [attr.disabled]="!writeAccess ? true : null">
                Reopen
              </button>
              <button class="btn" style="background-color: #5864ff; color: white"
                [ngClass]="{'click-disabled': !writeAccess}" [attr.disabled]="!writeAccess ? true : null"
                (click)="resolveTicketTrigger()" *ngIf="supportType == 'aws' && activeTicket.status != 'resolved'">
                Resolve
              </button>
              <button class="btn" style="background-color: #5864ff; color: white"
                [ngClass]="{'click-disabled': !writeAccess}" [attr.disabled]="!writeAccess ? true : null"
                (click)="replyTicket()">
                Reply
              </button>
              <button class="btn" style="background-color: #5864ff; color: white"
                [ngClass]="{'click-disabled': !writeAccess}" [attr.disabled]="!writeAccess ? true : null"
                (click)="addToKB()" *ngIf="urlPrefix == 'admin'">
                Add To KB
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-20" style="border: none;">
        <h5 class="card-header">
          <span>Case Details</span>
        </h5>
        <div class="card-body">
          <div class="panel panel-default">
            <div class="panel-heading" style="font-size: medium"></div>

            <div class="panel-body">
              <div class="row">
                <div class="col-md-6">
                  <b>Subject</b>
                  <p id="eventname">
                    {{ activeTicket.summary || activeTicket.subject }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Status</b>
                  <p id="eventid">{{ activeTicket.status }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Ticket ID</b>
                  <p id="readonly">
                    {{ activeTicket.issue_key || activeTicket.caseId }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Priority</b>
                  <p id="eventsource">
                    {{ activeTicket.priority || activeTicket.severityCode }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Creator</b>
                  <p id="aws_access_key">
                    {{ activeTicket.creator || activeTicket.submittedBy }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Created On</b>
                  <p id="aws_region">
                    {{ activeTicket.created || activeTicket.timeCreated }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Reporter</b>
                  <p id="eventtime">
                    {{ activeTicket.reporter || activeTicket.submittedBy }}
                  </p>
                </div>
                <div class="col-md-6">
                  <b>Assignee</b>
                  <p id="username">{{ activeTicket.assignee || "NA" }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b>Participants</b>
                  <p id="eventtime">
                    {{ activeTicket.participants || "NA" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h5 class="card-header">
          <span>Correspondence</span>
        </h5>
        <div class="card-body">
          <table class="table table-docs table-hover">
            <thead>
              <tr>
                <th *ngFor="let h of correspondenceHeader">{{ h.name }}</th>
              </tr>
            </thead>

            <tbody [ngStyle]="{'display': correspondence.length > 0 ? 'table-row-group' : 'none'}">
              <tr *ngFor="let i of correspondence">
                <td *ngFor="let h of correspondenceHeader">
                  <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                    <span [innerHTML]="i[h.id]">
                    </span>
                  </span>
                  <span *ngIf="!h.click" [innerHTML]="i[h.id]"> </span>
                  <div class="comment_attachments" *ngIf="h.id == 'body' && i.attachments">
                    <div class="comment_attachment" [title]="attachment.file_name"
                      (click)="!attachment.src ? downloadAttachment(attachment) : viewImage(attachment)"
                      *ngFor="let attachment of i.attachments">
                      <div style="text-align: center; min-width: 150px; padding-bottom: 20px;">
                        <span *ngIf="!attachment.src || attachment.src == 'LOADING'" style="padding: 10px;">
                          <i class="fa fa-file-text" style="font-size: 70px;" aria-hidden="true"></i>
                        </span>
                        <span *ngIf="attachment.src && attachment.src != 'LOADING'"
                          style="position: relative; min-height: 120px;">
                          <img [src]="attachment.src">
                        </span>
                      </div>
                      <div class="attachment_meta">
                        <div class="attachment_text">{{attachment.file_name}}</div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody [ngStyle]="{'display': correspondence.length == 0 ? 'table-row-group' : 'none'}">
              <tr>
                <td [attr.colspan]="correspondenceHeader.length">
                  <div style="text-align: center;">No Correspondence Found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<reply-ticket *ngIf="replyTicket_" [modalTitle]="modalTitle" [ticket]="replyTicket_" [supportType]="supportType"
  (hideModal)="hideModal($event)"></reply-ticket>
<confirm-element *ngIf="resolveTicket" (hideModal)="hideResolveTicket($event)"></confirm-element>
<add-kb-element *ngIf="addKB" [supportType]="supportType" [ticket]="addKB" (hideModal)="hideAddKB($event)">
</add-kb-element>
<create-ticket *ngIf="createTicket" [createTicketStrictTicketType]="true"
  [createTicketSupportType]="supportType == 'minfy' ? 'aws' : 'minfy'" [createTicketSubject]="createTicket.subject"
  [createTicketDescription]="createTicket.description" (hideCreateTicket)="hideCreateTicket($event)"></create-ticket>