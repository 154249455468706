import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';

import { APIService } from './../../api/api.service';
import { NotifierService } from './../../_services/notifier.service';

@Component({
  selector: 'register-page',
  styleUrls: ['./../login.component.css'],
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
  @Output() hideModel = new EventEmitter<boolean>();
  otpRequired: boolean = false;
  public registerForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', []),
    lastName: new UntypedFormControl('', []),
    emailId: new UntypedFormControl('', []),
    cmpName: new UntypedFormControl('', []),
    designation: new UntypedFormControl('', []),
    password: new UntypedFormControl('', []),
    confPassword: new UntypedFormControl('', []),
    phoneNumber: new UntypedFormControl('', []),
  });

  role: string = 'admin';

  public otpForm = new UntypedFormGroup({
    otp: new UntypedFormControl('', []),
  });

  public hideRoleSelection = false;

  // get username_form() {
  //   return this.userNameForm.controls;
  // }

  get register_form() {
    return this.registerForm.controls;
  }

  get otp_form() {
    return this.otpForm.controls;
  }

  otpSent: boolean = false;

  firstName: string = '';
  lastName: string = '';
  emailId: string = '';
  cmpName: string = '';
  designation: string = '';
  password: string = '';
  confPassword: string = '';
  phoneNumber: string = '';

  otp: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    if (window.location.href.indexOf('app.swayam.cloud') != -1) {
      this.role = 'admin';
      this.hideRoleSelection = true;
    } else if (window.location.href.indexOf('client.swayam.cloud') != -1) {
      this.role = 'client';
      this.hideRoleSelection = true;
    } else if (window.location.href.indexOf('cbs.swayam.cloud') != -1) {
      this.role = 'admin';
      this.hideRoleSelection = true;
    } else if (window.location.href.indexOf('cbsadmin.swayam.cloud') != -1) {
      this.role = 'admin';
      this.hideRoleSelection = true;
    } else if (
      window.location.href.indexOf('swayam-pipeline-portal-internal') != -1
    ) {
      this.role = 'Admin';
      this.hideRoleSelection = true;
    }
  }

  async otpValidation() {
    this.notifier.loading(true);
    let data = {
      eId: this.emailId.trim(),
      otp: this.otp.trim(),
      ut: this.role == 'admin' ? 'msops' : 'client',
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.role}/users/validatesignupotp`;

    let result = await this.apiServer.postDataPromis(apiURL, data, {});

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.router.navigateByUrl('/login');
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  getKeys(obj: any): any {
    return Object.keys(obj);
  }

  passwordChecker(password: string) {
    let passwordObj: any = {
      strength: 0,
      policies: {
        'Must have atleast 8 characters': password.length > 7,
        'Must have atleast 1 uppercase letter, 1 lowercase letter, and 1 number':
          false,
        'Must have atleast 1 Special Character': false,
        'Longer Password': false,
      },
    };
    if (passwordObj.policies['Must have atleast 8 characters']) {
      passwordObj.strength += 1;
    }
    if (
      password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{0,}$/gm)
    ) {
      passwordObj.strength += 1;
      passwordObj.policies[
        'Must have atleast 1 uppercase letter, 1 lowercase letter, and 1 number'
      ] = true;
    }
    if (
      password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{10,}$/gm)
    ) {
      passwordObj.strength += 1;
      passwordObj.policies['Longer Password'] = true;
    }
    if (password.match(/[^A-z\s\d][\\\^]?/gm)) {
      passwordObj.strength += 1;
      passwordObj.policies['Must have atleast 1 Special Character'] = true;
    }
    return passwordObj;
  }

  async register() {
    if (this.passwordChecker(this.password)['strength'] < 3) {
      this.notifier.alert(
        'Error',
        '',
        "Entered Password doesn't meet the minimum password policy",
        'error',
        5000
      );
      return;
    }

    if (!this.emailId.trim()) {
      this.notifier.alert(
        'Error',
        '',
        'Email ID is a mandatory field',
        'error',
        5000
      );
      return;
    }
    if (this.password.trim() != this.confPassword.trim()) {
      this.notifier.alert('Info', '', 'Passwords do not match', 'info', 5000);
      return;
    }

    this.notifier.loading(true);

    let data: any = {
      eId: this.emailId.trim(),
      ut: 'msops',
      un: this.firstName.trim() + ' ' + this.lastName.trim(),
      pswd: this.password.trim(),
      mn: this.phoneNumber,
      cn: this.cmpName.trim(),
      des: this.designation.trim(),
    };

    if (this.role == 'client') {
      data = {
        eId: this.emailId.trim(),
        ut: 'client',
        pswd: this.password.trim(),
      };
    }

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.role}/users/signup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, {});

    console.log(result);
    if (result.status == '1' || result.s == '1') {
      this.otpSent = true;
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }
}
