<div class="alarm_screen">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4>Launch Instances</h4>
    </div>
    <div class="alarm_body">
      <div class="row">
        There are {{ os.length }} similar instances. Do you want to launch all instances at once?
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="uploadFile()" style="background-color: #5864ff; color: white">
            Launch All
          </button>
          <button class="btn btn-light" (click)="close(true)">Launch One</button>
          <button class="btn btn-light" (click)="close(false)">Cancel</button>
        </div>
      </div>
    </div>
  </div>