<div class="instance_comparison step_1_container" style="align-items: stretch;">
  <div class="instance_ui clickable" (click)="toggleType('compute')"
    [ngClass]="{selected: typesselected['compute']['selected']}">
    <i class="fa fa-check-circle check" aria-hidden="true"></i>
    <div><i class="fa fa-server" aria-hidden="true" (load)="setMainContainerHeight()"></i></div>
    <div class="instnace_details">
      <div class="font-bold-size">Compute</div>
    </div>
  </div>
  <div class="instance_ui clickable" (click)="toggleType('storage')"
    [ngClass]="{selected: typesselected['storage']['selected']}">
    <i class="fa fa-check-circle check" aria-hidden="true"></i>
    <div><i class="fa fa-archive" aria-hidden="true" (load)="setMainContainerHeight()"></i></div>
    <div class="instnace_details">
      <div class="font-bold-size">Disks</div>
    </div>
  </div>
</div>