import { Component, OnInit } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

declare let Jhxlsx: any;

@Component({
  //moduleId: module.id.toString(),
  selector: 'inventory-reports',
  templateUrl: './inventory.view.reports.component.html',
})
export class InventoryReportsComponent implements OnInit {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  ec2List: any;
  rdsList: any;
  volumeList: any;
  elasticIPsList: any;
  loadList: any;

  accountId: any;
  regionId: any;
  currentMessage: any;

  headers: any = [];

  ec2Headers: any = [
    {
      id: 'instanceId',
      name: 'Instance ID',
    },
    {
      id: 'hostName',
      name: 'Instance Name',
    },
    {
      id: 'instanceType',
      name: 'Instance Type',
    },
    {
      id: 'status',
      name: 'Instance Status',
    },
    {
      id: 'region',
      name: 'Region',
    },
    {
      id: 'publicIp',
      name: 'Public IP',
    },
    {
      id: 'privateIp',
      name: 'Private IP',
    },
  ];

  rdsHeaders: any = [
    {
      id: 'dbInstanceIdentifier',
      name: 'DB Identifier',
    },
    {
      id: 'dbEngine',
      name: 'DB Engine',
    },
    {
      id: 'dbInstanceType',
      name: 'DB Type',
    },
    {
      id: 'dbStatus',
      name: 'DB Status',
    },
    {
      id: 'vpc',
      name: 'VPC',
    },
    {
      id: 'multi_AZ',
      name: 'Multi AZ',
    },
    {
      id: 'storageType',
      name: 'Storage Type',
    },
    {
      id: 'region',
      name: 'Region',
    },
  ];

  volumeHeaders: any = [
    {
      id: 'volumeId',
      name: 'Volume Id',
    },
    {
      id: 'volumeType',
      name: 'Volume Type',
    },
    {
      id: 'hostName',
      name: 'Host Name',
    },
    {
      id: 'volumeSize',
      name: 'Volume Size',
    },
    {
      id: 'IOPS',
      name: 'IOPS',
    },
    {
      id: 'snapshot',
      name: 'Snapshot',
    },
    {
      id: 'state',
      name: 'State',
    },
    {
      id: 'attachmentInformation',
      name: 'Attachment Information',
    },
    {
      id: 'encryption',
      name: 'Encryption',
    },
  ];

  elasticIPsHeader: any = [
    {
      id: 'instance',
      name: 'Instance ID',
    },
    {
      id: 'associationId',
      name: 'Association ID',
    },
    {
      id: 'allocationId',
      name: 'Allocation ID',
    },
    {
      id: 'publicIP',
      name: 'Public IP',
    },
    {
      id: 'privateIp',
      name: 'Private IP',
    },
    {
      id: 'networkInterfaceId',
      name: 'Network Interface ID',
    },
  ];

  loadHeader: any = [
    {
      id: 'DNS',
      name: 'DNS',
    },
    {
      id: 'VPCID',
      name: 'VPC ID',
    },
    {
      id: 'name',
      name: 'Name',
    },
    {
      id: 'TYPE',
      name: 'Type',
    },
    {
      id: 'state',
      name: 'State',
    },
    {
      id: 'CREATIONDATE',
      name: 'Creation Date',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }
  async load() {
    this.notifier.loading(true);
    await this.loadEC2();
    await this.loadRDS();
    await this.loadVolume();
    await this.loadElasticIPs();
    await this.loadLoadBalancers();
    this.notifier.loading(false);
  }

  getmonthlybackupreport() {
    var EC2_table: any = undefined;
    var RDS_table: any = undefined;
    var Volume_table: any = undefined;
    var Elastic_IP_table: any = undefined;
    var Load_Balancer_table: any = undefined;
    var main_data: any = [];
    if (
      document
        .getElementById('billDetailsTabledetaildatareports_ec2')
        .querySelectorAll('table').length != 0
    ) {
      EC2_table = document.getElementById('monthly_table_ec2');
      var dict: any = {
        sheetName: 'EC2',
        data: [],
      };
      EC2_table.querySelectorAll('tr').forEach((instance: any) => {
        var array2: any = [];
        instance.querySelectorAll('td, th').forEach((td: any) => {
          array2.push({ text: td.innerText ? td.innerText : '' });
        });
        dict['data'].push(array2);
      });
      main_data.push(dict);
    }
    if (
      document
        .getElementById('billDetailsTabledetaildatareports_rds')
        .querySelectorAll('table').length != 0
    ) {
      RDS_table = document.getElementById('monthly_table_rds');
      var dict: any = {
        sheetName: 'RDS',
        data: [],
      };
      RDS_table.querySelectorAll('tr').forEach((instance: any) => {
        var array2: any = [];
        instance.querySelectorAll('td, th').forEach((td: any) => {
          array2.push({ text: td.innerText ? td.innerText : '' });
        });
        dict['data'].push(array2);
      });
      main_data.push(dict);
    }
    if (
      document
        .getElementById('billDetailsTabledetaildatareports_volume')
        .querySelectorAll('table').length != 0
    ) {
      Volume_table = document.getElementById('monthly_table_volume');
      var dict: any = {
        sheetName: 'Volume',
        data: [],
      };
      Volume_table.querySelectorAll('tr').forEach((instance: any) => {
        var array2: any = [];
        instance.querySelectorAll('td, th').forEach((td: any) => {
          array2.push({ text: td.innerText ? td.innerText : '' });
        });
        dict['data'].push(array2);
      });
      main_data.push(dict);
    }
    if (
      document
        .getElementById('billDetailsTabledetaildatareport_elasticip')
        .querySelectorAll('table').length != 0
    ) {
      Elastic_IP_table = document.getElementById('monthly_table_elasticip');
      var dict: any = {
        sheetName: 'Elastic IP',
        data: [],
      };
      Elastic_IP_table.querySelectorAll('tr').forEach((instance: any) => {
        var array2: any = [];
        instance.querySelectorAll('td, th').forEach((td: any) => {
          array2.push({ text: td.innerText ? td.innerText : '' });
        });
        dict['data'].push(array2);
      });
      main_data.push(dict);
    }
    if (
      document
        .getElementById('billDetailsTabledetaildatareport_loadbalancer')
        .querySelectorAll('table').length != 0
    ) {
      Load_Balancer_table = document.getElementById(
        'monthly_table_loadbalancer'
      );
      var dict: any = {
        sheetName: 'Load Balancer',
        data: [],
      };
      Load_Balancer_table.querySelectorAll('tr').forEach((instance: any) => {
        var array2: any = [];
        instance.querySelectorAll('td, th').forEach((td: any) => {
          array2.push({ text: td.innerText ? td.innerText : '' });
        });
        dict['data'].push(array2);
      });
      main_data.push(dict);
    }
    var today = new Date();
    var options = {
      fileName: `Inventory_Report_${this.accountId}_${today.getDate()}-${
        today.getMonth() + 1
      }-${today.getFullYear()}`,
    };
    Jhxlsx.export(main_data, options);
    EC2_table = undefined;
    RDS_table = undefined;
    Volume_table = undefined;
    Elastic_IP_table = undefined;
    Load_Balancer_table = undefined;
  }

  async loadEC2(nextToken: String = '') {
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    if (nextToken == '') {
      this.ec2List = [];
    }

    let data = {
      accountId: this.accountId,
      emailid: this.userId,
      action: 'EC2',
      region: this.regionId,
      nextToken: nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/reports/inventory`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.ec2List = [...this.ec2List, ...result.ec2list];
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.nextToken) {
      await this.loadEC2(result.nextToken);
    }
  }

  async loadRDS(nextToken: String = '') {
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    if (nextToken == '') {
      this.rdsList = [];
    }

    let data = {
      accountId: this.accountId,
      emailid: this.userId,
      action: 'RDS',
      region: this.regionId,
      nextToken: nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/reports/inventory`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.rdsList = [...this.rdsList, ...result.rdslist];
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.nextToken) {
      await this.loadRDS(result.nextToken);
    }
  }

  async loadVolume(nextToken: String = '') {
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    if (nextToken == '') {
      this.volumeList = [];
    }

    let data = {
      accountId: this.accountId,
      emailid: this.userId,
      action: 'Volume',
      region: this.regionId,
      nextToken: nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/reports/inventory`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.volumeList = [...this.volumeList, ...result.volumeList];
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.nextToken) {
      await this.loadVolume(result.nextToken);
    }
  }

  async loadElasticIPs(nextToken: String = '') {
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    if (nextToken == '') {
      this.elasticIPsList = [];
    }

    let data = {
      accountId: this.accountId,
      emailid: this.userId,
      action: 'ElasticIP',
      region: this.regionId,
      nextToken: nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/reports/inventory`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.elasticIPsList = [...this.elasticIPsList, ...result.elasticIP];
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.nextToken) {
      await this.loadElasticIPs(result.nextToken);
    }
  }

  async loadLoadBalancers(nextToken: String = '') {
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    if (nextToken == '') {
      this.loadList = [];
    }

    let data = {
      accountId: this.accountId,
      emailid: this.userId,
      action: 'LoadBalancer',
      region: this.regionId,
      nextToken: nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/reports/inventory`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.loadList = [...this.loadList, ...result.loadlist];
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.nextToken) {
      await this.loadLoadBalancers(result.nextToken);
    }
  }

  callFunction(id: any, id1: any) {}
}
