import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
declare let window: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './s3.component.html',
})
export class s3Component implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  s3List: any = [];
  filterType: string = 'ALL';

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  index: number = 1;

  headers: any = [
    {
      id: 'bucketName',
      name: 'Bucket Name',
    },
    { id: 'createdDate', name: 'Created Date' },
    { id: 'region', name: 'Region' },
    {
      id: 'isPublic',
      name: 'Is Public',
      color: (value: string) => (value ? 'red' : 'green'),
    },
    {
      id: 'isEncrypted',
      name: 'Is Encrypted',
      color: (value: string) => (!value ? 'red' : 'green'),
    },
    {
      id: 'bucketPublicPolicyStatus',
      name: 'Bucket Public Policy Status',
      color: (value: string) => (value ? 'red' : 'green'),
    },
    {
      id: 'restrictPublicBuckets',
      name: 'Restrict Public Buckets',
      color: (value: string) => (!value ? 'red' : 'green'),
    },
    {
      id: 'isBlockPublicAccess',
      name: 'Block Public Access',
      color: (value: string) => (!value ? 'red' : 'green'),
    },
    {
      id: 'isBlockPublicAcl',
      name: 'Block Public Acl',
      color: (value: string) => (!value ? 'red' : 'green'),
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null) {
        return;
      }

      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load(true);
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load(true);
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load(refresh: boolean = false) {
    if (refresh) {
      this.s3List = [];
      this.index = 1;
    }
    this.notifier.loading(true);
    let data = {
      action: 'fetchS3DetailsWithPagination',
      fromIndex: this.index,
      toIndex: '10',
      clientemailid: this.accountId,
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Compliance.S3', 'S3 Buckets Fetched');
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ebs`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      let dt = result.bucketList.encryptedBucketList;
      dt = dt.map((bucket: any) => {
        bucket['focused'] = true;
        return bucket;
      });
      this.s3List = [...this.s3List, ...dt];
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.bucketList.encryptedBucketList.length == 10) {
      this.index++;
    } else {
      this.index = 1;
    }
    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    return;
  }

  getLength(value: string, toBe: boolean): number {
    return this.s3List.filter((bucket: any) => {
      return bucket && bucket[value] == toBe;
    }).length;
  }

  filterBuckets(value: string, toBe: boolean) {
    this.filterType = value;
    this.s3List = this.s3List.map((bucket: any) => {
      if (value == 'ALL') {
        bucket['focused'] = true;
        return bucket;
      }
      if (bucket[value] == toBe) {
        bucket.focused = true;
      } else {
        bucket.focused = false;
      }
      return bucket;
    });
  }

  checkforBool(value: String): String {
    if (typeof value == 'boolean') {
      return value ? 'Yes' : 'No';
    } else {
      return value;
    }
  }
}
