<div class="card">
  <div class="card-body">
    <div class="sections">
      <div class="section">
        <div class="section_header" (click)="loadEC2Data()">
          <div class="section_title">EC2</div>
          <div><i class="fa fa-caret-down"></i></div>
        </div>
        <div *ngIf="ec2Show">
          <div style="display: flex; margin: 10px; justify-content: right;" *ngIf="ec2Data.length > 0">
            <button class="btn btn-light" style="background-color: #5864FF; color: white;"
              (click)="exportEC2()">Export</button>
          </div>
          <table class="table table-docs table-hover" id="ec2-bills-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <ng-container *ngFor="let h of ec2Headers">
                  <th *ngIf="!h.hide && (h.displayConditional && getValue(h.id))">{{ h.name }}</th>
                  <th *ngIf="!h.hide && !h.displayConditional">{{ h.name }}</th>
                </ng-container>
              </tr>
            </thead>

            <tbody [ngStyle]="{'display': ec2Data.length > 0 ? 'table-row-group' : 'none'}">

              <tr *ngFor="let i of ec2Data; let j = index">
                <td>{{j + 1}}</td>
                <ng-container *ngFor="let h of ec2Headers">
                  <td *ngIf="!h.hide && (h.displayConditional && getValue(h.id))">
                    <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"> </span>
                  </td>
                  <td *ngIf="!h.hide && !h.displayConditional">
                    <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"> </span>
                  </td>
                </ng-container>
              </tr>
              <tr *ngIf="!this.nextTokens['ec2']">
                <td
                  [attr.colSpan]="filterHeaders(ec2Headers) - 1 - (projectTagNameEC2 ? 0 : 1) - (mapMigratedEC2 ? 0 : 1)">

                </td>
                <td>SUB Total</td>
                <td>
                  {{getTotalEC2(ec2Data)}}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="ec2Data.length == 0">
              <tr>
                <td [attr.colspan]="ec2Headers.length + 1">
                  <div style="text-align: center;">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="display: flex; justify-content: right;" *ngIf="nextTokens['ec2']">
            <button class="btn btn-light" style="background-color: #5864FF; color: white;"
              (click)="loadEC2Data(true)">Next</button>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section_header" (click)="loadEBSData()">
          <div class="section_title">EBS</div>
          <div><i class="fa fa-caret-down"></i></div>
        </div>
        <div *ngIf="ebsShow">
          <div style="display: flex; margin: 10px; justify-content: right;" *ngIf="ebsData.length > 0">
            <button class="btn btn-light" style="background-color: #5864FF; color: white;"
              (click)="exportEBS()">Export</button>
          </div>
          <table class="table table-docs table-hover" id="ebs-bills-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <ng-container *ngFor="let h of ebsHeaders">
                  <th *ngIf="!h.hide && (h.displayConditional && getValue(h.id))">{{ h.name }}</th>
                  <th *ngIf="!h.hide && !h.displayConditional">{{ h.name }}</th>
                </ng-container>
              </tr>
            </thead>

            <tbody [ngStyle]="{'display': ebsData.length > 0 ? 'table-row-group' : 'none'}">
              <tr *ngFor="let i of ebsData; let j = index">
                <td>{{j + 1}}</td>
                <ng-container *ngFor="let h of ebsHeaders">
                  <td *ngIf="!h.hide && (h.displayConditional && getValue(h.id))">
                    <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i[h.id]) : i[h.id]"> </span>
                  </td>
                  <td *ngIf="!h.hide && !h.displayConditional">
                    <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i[h.id]) : i[h.id]"> </span>
                  </td>
                </ng-container>
              </tr>
              <tr *ngIf="!this.nextTokens['ebs']">
                <td [attr.colSpan]="ebsHeaders.length - 3 - (projectTagNameEBS ? 0 : 1) - (mapMigratedEBS ? 0 : 1)">

                </td>
                <td>SUB Total</td>
                <td>
                  {{getTotalEBS(ebsData)['gb_cost']}}
                </td>
                <td>
                  {{getTotalEBS(ebsData)['storage']}}
                </td>
                <td>
                  {{getTotalEBS(ebsData)['total']}}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="ebsData.length == 0">
              <tr>
                <td [attr.colspan]="ebsHeaders.length + 1">
                  <div style="text-align: center;">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="display: flex; justify-content: right;" *ngIf="nextTokens['ebs']">
            <button class="btn btn-light" style="background-color: #5864FF; color: white;"
              (click)="loadEBSData(true)">Next</button>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section_header" (click)="loadOthersData()">
          <div class="section_title">Others</div>
          <div><i class="fa fa-caret-down"></i></div>
        </div>
        <div *ngIf="othersShow">
          <div style="display: flex; margin: 10px; justify-content: right;" *ngIf="othersData.length > 0">
            <button class="btn btn-light" style="background-color: #5864FF; color: white;"
              (click)="exportOthers()">Export</button>
          </div>
          <table class="table table-docs table-hover" id="others-bills-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th *ngFor="let h of othersHeaders">{{ h.name }}</th>
              </tr>
            </thead>

            <tbody [ngStyle]="{'display': othersData.length > 0 ? 'table-row-group' : 'none'}">
              <tr *ngFor="let i of othersData; let j = index">
                <td>{{j + 1}}</td>
                <td *ngFor="let h of othersHeaders">
                  <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i[h.id]) : i[h.id]"> </span>
                </td>
              </tr>
              <tr *ngIf="!this.nextTokens['others']">
                <td [attr.colSpan]="othersHeaders.length - 1">

                </td>
                <td>SUB Total</td>
                <td>
                  {{getTotalOthers(othersData)}}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="othersData.length == 0">
              <tr>
                <td [attr.colspan]="othersHeaders.length + 1">
                  <div style="text-align: center;">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="display: flex; justify-content: right;" *ngIf="nextTokens['others']">
            <button class="btn btn-light" style="background-color: #5864FF; color: white;"
              (click)="loadOthersData(true)">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>