<div class="card">
  <h5 class="card-header">Margin Dashboard</h5>
  <div class="card-body">
    <div class="main-page">
      <button class="btn btn-light" (click)="exportToCSV()">
        Download CSV
      </button>
      <button class="btn btn-light" (click)="refresh()">Refresh</button>
    </div>
    <div class="data-table">
      <div class="table-container">
        <table
          class="table table-docs table-hover table-bordered"
          id="marginData"
        >
          <thead class="table-header">
            <tr>
              <th
                *ngFor="let h of main_header"
                [attr.rowspan]="h.rowspan ? h.num : null"
                (click)="process_data_fun(h.sort.col, '')"
              >
                {{ h.name }}
                <span *ngIf="h.sort && h.sort.sort">
                  <i
                    class="fa fa-sort-amount-desc"
                    aria-hidden="true"
                    *ngIf="sorting_method == 'desc'"
                  ></i>
                  <i
                    class="fa fa-sort-amount-asc"
                    aria-hidden="true"
                    *ngIf="sorting_method == 'asc'"
                  ></i>
                </span>
              </th>
              <th *ngFor="let j of dates" colspan="2">
                {{ j | customDate }}
              </th>
            </tr>
            <tr>
              <ng-container *ngFor="let j of dates">
                <th
                  *ngFor="let sh of sub_header"
                  (click)="process_data_fun(sh.sort.col, j)"
                >
                  {{ sh.name }}
                  <span *ngIf="sh.sort && sh.sort.sort">
                    <i
                      class="fa fa-sort-amount-desc"
                      aria-hidden="true"
                      *ngIf="sorting_method == 'desc'"
                    ></i>
                    <i
                      class="fa fa-sort-amount-asc"
                      aria-hidden="true"
                      *ngIf="sorting_method == 'asc'"
                    ></i>
                  </span>
                </th>
              </ng-container>
              <!-- </ng-container> -->
            </tr>
          </thead>
          <tbody class="table-body">
            <tr *ngFor="let data of process_data">
              <td>{{ data.key }}</td>

              <ng-container *ngFor="let date of dates">
                <ng-container *ngFor="let header of sub_header">
                  <td>
                    {{ data.result[date] ? data.result[date][header.id] : 0 }}
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
