import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

declare let window: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './findings.component.html',
  styleUrls: ['./findings.component.css'],
})
export class FindingsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  iamCheckList: any = [];
  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  headers: any = [
    {
      id: 'Description',
      name: 'Description',
      sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
    {
      id: 'Result',
      name: 'Status',
      color: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Category',
      name: 'Category',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Severity',
      name: 'Severity',
      sort: { sort: true, direction: 'Asc' },
      alter: true,
    },
    {
      id: 'failReason',
      name: 'Fail Reason',
      sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
  ];
  showOffendesModal = false;
  offendeList: any;
  offendeListHeader: any = [
    {
      id: 'offenders',
      name: 'Offenders',
    },
  ];
  offentpopTitle = 'Offender List';
  audit_type: string = null;

  findings: any = [
    { audit_type: 'iam', title: 'IAM' },
    { audit_type: 'ec2', title: 'EC2' },
    { audit_type: 'rds', title: 'RDS' },
    { audit_type: 'lambda', title: 'Lambda' },
    { audit_type: 'kms', title: 'KMS' },
    { audit_type: 'cft', title: 'Cloud formation' },
    { audit_type: 'dynamo', title: 'DynamoDB' },
    { audit_type: 'cloudtrail', title: 'Cloudtrail' },
    { audit_type: 'cloudwatch', title: 'Cloudwatch' },
    { audit_type: 'cloudfront', title: 'Cloudfront' },
    { audit_type: 'apigateway', title: 'API Gateway' },
    { audit_type: 'ses', title: 'SES' },
    { audit_type: 's3', title: 'S3' },
    { audit_type: 'sg', title: 'Security Gropus' },
    { audit_type: 'kube', title: 'Kubernetes' },
    { audit_type: 'es', title: 'Elastic Search' },
    { audit_type: 'sns', title: 'SNS' },
    { audit_type: 'redshift', title: 'Redshift' },
    { audit_type: 'elb', title: 'ELB' },
    { audit_type: 'route53', title: 'Route 53' },
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    public route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.route.queryParams.subscribe((params: any) => {
      if (params['audit_type'] && params['audit_type'] != '') {
        let check: boolean = false;
        if (this.audit_type != null) {
          check = true;
        }
        this.audit_type = params['audit_type'];
        if (check) {
          this.load();
        }
      } else {
        this.audit_type = null;
      }
    });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      this.iamCheckList = [];
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  getFinding() {
    let temp = this.findings.find((finding: any) => {
      return finding['audit_type'] == this.audit_type;
    });
    if (temp) {
      return temp;
    } else {
      return { audit_type: this.audit_type, title: this.audit_type };
    }
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  scanDetails: any = null;
  fetching: Boolean = true;
  async load() {
    this.notifier.loading(true);
    this.iamCheckList = [];
    this.offendeList = [];
    this.fetching = true;
    this.scanDetails = null;
    let data: any = {
      accountId: this.accountId,
      audit_type: `/audit/${this.getFinding()['audit_type']}`,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/audit/ec2`;

    let ids = await this.apiServer.postDataPromis(apiURL, data, header);

    if (ids.scanIds || ids.scanId) {
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/audit/status`;
      data = {
        scanId: (ids.scanIds && ids.scanIds[0]) || ids.scanId,
        audit_type: `/audit/${this.getFinding()['audit_type']}`,
        accountId: this.accountId,
      };
      let status = await this.apiServer.postDataPromis(apiURL, data, header);
      this.scanDetails = status;
      if (this.scanDetails.startedAt) {
        this.scanDetails.startedAt = moment(this.scanDetails.startedAt).format(
          'DD/MM/YYYY hh:mm A'
        );
      }
      if (status.hasOwnProperty('signedURL') && status.signedURL != '') {
        apiURL = status['signedURL'];
        let offenders = await this.apiServer.getDataPromis(apiURL, {}, {});

        if (!Array.isArray(offenders)) {
          this.iamCheckList = offenders['data'];
          this.scanDetails['resource_found'] = offenders['resource_found'];
        } else {
          this.iamCheckList = offenders;
          this.scanDetails['resource_found'] = true;
        }
        this.iamCheckList = this.iamCheckList.map((iam: any) => {
          if (
            iam.Severity == 'High' &&
            (iam.Result == 'false' || iam.Result.toLowerCase() == 'manual')
          ) {
            iam['index'] = 1;
          }
          if (
            iam.Severity == 'Medium' &&
            (iam.Result == 'false' || iam.Result.toLowerCase() == 'manual')
          ) {
            iam['index'] = 2;
          }
          if (
            iam.Severity == 'Low' &&
            (iam.Result == 'false' || iam.Result.toLowerCase() == 'manual')
          ) {
            iam['index'] = 3;
          }
          if (iam.Severity == 'High' && iam.Result == 'true') {
            iam['index'] = 4;
          }
          if (iam.Severity == 'Medium' && iam.Result == 'true') {
            iam['index'] = 5;
          }
          if (iam.Severity == 'Low' && iam.Result == 'true') {
            iam['index'] = 6;
          }
          return iam;
        });
        this.iamCheckList = this.iamCheckList.sort((a: any, b: any) => {
          if (a.index < b.index) {
            return -1;
          }
          return 0;
        });
        this.notifier.loading(false);
      } else if (status.scanStatus != 'COMPLETE' && status.signedURL == '') {
        this.notifier.loading(false);
      } else if (status.scanStatus == 'Submitted') {
        this.notifier.loading(false);
      }
    } else if (ids.statusCode == 202) {
      this.notifier.loading(false);
    } else {
      this.notifier.loading(false);
    }
    this.fetching = false;
  }

  downloadReport() {
    let element = document.createElement('table');
    element.innerHTML += `
        <thead>
          <tr>
            <th>Sl. No.</th>
            ${this.headers.map((h: any) => {
              return `<th>${h.name}</th>`;
            })}
            <td>Offenders</td>
          </tr>
        </thead>
        <tbody>
            ${this.iamCheckList.map((ec2: any, index: any) => {
              return `
                <tr>
                  <td>${index + 1}</td>
                  ${this.headers.map((h: any) => {
                    return `<td>
                      ${this.checkforBool(ec2[h.id], h.id)}
                    </td>`;
                  })}
                  <td>
                    ${ec2['Offenders'].join(', ')}
                  </td>
                </tr>
              `;
            })}
        </tbody>
      `;
    window.exportToExcelElement(
      element,
      `${this.getFinding()['title']} Compliance - ${this.accountId}`,
      'csv'
    );
  }

  callFunction(name: any, param: any) {
    return;
  }

  checkforBool(value: any, key: any): String {
    if (key == 'Result') {
      return value == 'true' || value == true
        ? 'Pass'
        : value == 'false' || value == false
        ? 'Fail'
        : value;
    } else {
      return value;
    }
  }

  showOffendes(offendeList: any) {
    this.showOffendesModal = true;

    var dt = [];
    for (var i = 0; i < offendeList.length; i++) {
      dt.push({ offenders: offendeList[i] });
    }

    this.offendeList = dt;
  }

  hidePop(e: any) {
    this.showOffendesModal = false;
    this.offendeList = [];
  }
}
