<div class="card mt-20" style="border: none; position: relative; height: 100%;">
  <div class="card-header" style="display: flex; justify-content: space-between; align-items: center;">
    <h5>Master Account Details</h5>
    <button class="btn btn-success-soft" (click)="downloadAll()" *ngIf="!interval">
      Download All
    </button>
    <span *ngIf="interval">
      <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px;">
    </span>
  </div>
  <div class="card-body">
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
          <th>Export</th>
        </tr>
      </thead>

      <tbody *ngFor="let i of accounts; let j = index"
        [ngStyle]="{'display': accounts.length > 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td>{{j + 1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
            <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || 0" (click)="callFunction(h.click, i)">
            </span>

          </td>
          <td><span class="btn btn-link" (click)="exportMainData(i)">Download CSV</span></td>

        </tr>
        <tr *ngIf="i.expanded">
          <td [attr.colspan]="headers.length + 2">
            <table class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>Sl. No.</th>
                  <th *ngFor="let h of childAccountHeaders">{{ h.name }}</th>
                </tr>
              </thead>

              <tbody [ngStyle]="{'display': i.data.length > 0 ? 'table-row-group' : 'none'}">
                <tr *ngFor="let i of i.data; let j = index">
                  <td>{{j + 1}}</td>
                  <td *ngFor="let h of childAccountHeaders">
                    <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
                    <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || 0"
                      (click)="callFunction(h.click, i)">
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody [ngStyle]="{'display': i.data.length == 0 ? 'table-row-group' : 'none'}">
                <tr>
                  <td [attr.colspan]="childAccountHeaders.length + 1">
                    <div style="text-align: center;">No Data Found</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': accounts.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 2">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>