import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../api/api.service';
import { NotifierService } from '../../_services/notifier.service';

declare let window: any;
@Component({
  selector: 'snapshots-backupreport',
  templateUrl: './backupreport.component.html',
})
export class backupReportComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  instances: any = [];
  createScan: boolean = false;

  @Input('customHeader') customHeader: any;

  funRef: any;

  accountId: any;
  regionId: any;
  currentMessage: any;

  instancesHeader: any = [
    {
      id: 'instance_name',
      name: 'Instance Name',
      click: 'loadSnapshots',
    },
    {
      id: 'instance_id',
      name: 'Instance ID',
    },
    { id: 'total_volumes', name: 'Total Volumes' },
    { id: 'status', name: 'Snapshot Status' },
    { id: 'job_status', name: 'Job Status' },
  ];

  snapshotsHeader: any = [
    {
      id: 'volume_id',
      name: 'Volume ID',
    },
    {
      id: 'volume_size',
      name: 'Volume Size',
    },
    {
      id: 'latest_snapshot_id',
      name: 'Latest Snapshot ID',
    },
    {
      id: 'latest_friendly',
      name: 'Latest Snapshot Created On',
    },
    {
      id: 'first_snapshot_id',
      name: 'First Snapshot ID',
    },
    {
      id: 'first_snapshot_friendly',
      name: 'First Snaopshot Created On',
    },
    {
      id: 'total_snapshots',
      name: 'Total Snapshots',
    },
    {
      id: 'snapshot_since',
      name: 'Snapshot Since(Days)',
    },
    {
      id: 'snapshot_backup_freq',
      name: 'Snapshot Frequency',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    if (this.customHeader) {
      this.instancesHeader = this.customHeader;
    }

    this.funRef = {
      loadSnapshots: this.loadSnapshots,
    };

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  getURl() {
    return `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;
  }

  getData() {
    return {
      action: 'create_jobs',
      region_name: this.regionId,
      account_id: this.accountId,
    };
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  hideModal(event: any) {
    this.createScan = false;
  }

  createScanTrigger() {
    this.createScan = true;
  }

  async load() {
    this.notifier.loading(true);
    await this.loadInstances();
    this.notifier.loading(false);
  }

  async loadInstances(nextToken: any = '') {
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      action: 'snapshots',
      region_name: this.regionId,
      account_id: this.accountId,
      next_token: nextToken,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/snapshots`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.instances = result.items;
      this.instances.forEach((instance: any) => {
        instance.status =
          (instance.snapshot_status ? instance.snapshot_status : 0) +
          '/' +
          instance.total_volumes;
      });
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  async loadSnapshots(ref: any, id: any) {
    if (id.showNested) {
      id.showNested = false;
      id.shapshots = [];
      return;
    }
    ref.notifier.loading(true);
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      action: 'snapshots',
      region_name: ref.regionId,
      account_id: ref.accountId,
      instance_id: id.instance_id,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/operations/snapshots`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      id.showNested = true;
      id.snapshots = result.items[0].volumes;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    ref.notifier.loading(false);
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel(
      'backup-report-table',
      `backupreport-${this.accountId}-${this.regionId}`,
      'csv'
    );
  }

  hideNested(event: any) {
    event.showNested = false;
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
