<div class="alarm_screen">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4>Delete Entry</h4>
    </div>
    <div class="alarm_body">
      <div class="row">
        Are you sure, you want to delete this entry?
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="deleteEntry()" style="background-color: #5864ff; color: white">
            Delete
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>