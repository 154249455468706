<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">Create Workload</div>
    <div class="KBBody">
      <div class="col-mb-6 mb-10">
        <label>Account ID: </label>
        <span>{{accountId}}</span>
      </div>
      <div class="col-mb-6 mb-10">
        <label>Workload Name: </label>
        <span>
          <input class="form-control custom-input" type="text" [(ngModel)]="scan_name">
        </span>
      </div>
      <div class="col-mb-6 mb-10">
        <label>Description: </label>
        <span>
          <textarea class="form-control custom-input" rows="4" [(ngModel)]="description"></textarea>
        </span>
      </div>
      <div class="col-mb-6 mb-10">
        <label>Review Owner: </label>
        <span>
          <input class="form-control custom-input" type="text" [(ngModel)]="review_owner">
        </span>
      </div>
      <div class="col-mb-6 mb-10">
        <label>Environment: </label>
        <div>
          <div class="form-group">
            <input type="radio" id="production" name="environment" value="PRODUCTION" [(ngModel)]="environment">
            <label for="production">Production</label>
          </div>
          <div class="form-group">
            <input type="radio" id="preproduction" name="environment" value="PREPRODUCTION" [(ngModel)]="environment">
            <label for="preproduction">Pre Production</label>
          </div>
        </div>
      </div>
      <div class="col-mb-6 mb-10">
        <label>Lenses: </label>
        <div class="options_container">
          <div class="option" [ngClass]="{selected_option_contaniner: lensesData[lens].selected}"
            *ngFor="let lens of getObjectKeys(lensesData); let j = index">
            <div>
              <input type="checkbox" id="option_{{j}}" [(ngModel)]="lensesData[lens].selected"
                [attr.disabled]="lensesData[lens].editable == false ? true: null">
            </div>
            <div style="flex: 1;" [ngClass]="{'pointer-none': lensesData[lens].editable == false}">
              <div class="option_text">
                <label for="option_{{j}}">{{ lensesData[lens].name }}</label>
              </div>
              <div class="option_description">
                {{ lensesData[lens].description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="KBActions">
      <button class="btn btn-success" *ngIf="!loading" (click)="yes()">Create</button>
      <button class="btn btn-success" *ngIf="loading" [attr.disabled]="true">
        <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin-right: 10px;" />
        Create
      </button>
      <button class="btn btn-light" (click)="no(false)">Cancel</button>
    </div>
  </div>
</div>