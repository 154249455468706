<div [ngClass]="{card: !notReviewable}">
  <div class="card-header" *ngIf="!notReviewable">
    <h5>Change Request Review</h5>
  </div>
  <div class="card-body" [ngStyle]="{'padding-top': notReviewable ? '0px' : undefined}">
    <div *ngIf="crdetail['type'] != 'New Instance'">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link" [ngClass]="{ active: index == 0 }"
            *ngFor="let header of getSelectedComponents(); let index = index" [attr.id]="header + '_' + index + '-tab'"
            data-bs-toggle="tab" [attr.data-bs-target]="'#' + header + '_' + index" type="button" role="tab"
            [attr.aria-controls]="header + '_' + index" aria-selected="true"
            style="display: flex; align-items: center; gap: 8px">
            <span style="color: inherit; text-transform: capitalize">{{
              header
              }}</span>
          </button>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show" [ngClass]="{ active: index == 0 }"
          *ngFor="let header of getSelectedComponents(); let index = index" [attr.id]="header + '_' + index"
          [attr.aria-labelledby]="header + '_' + index + '-tab'">
          <ng-container *ngIf="header == 'compute'">
            <div class="instance_comparison">
              <div class="instance_ui">
                <div class="instnace_details">
                  <div><img src="../assets/img/sawyam test icons.png" alt=""></div>
                  <div class="font-bold-size">{{crdetail['components'][header]['current']['instanceType']}}</div>
                  <div class="font-small-light">RAM: {{crdetail['components'][header]['current']['ram']}} GiB</div>
                  <div class="font-small-light">vCPU: {{crdetail['components'][header]['current']['cpu']}}</div>
                  <div class="font-small-light">OD Cost:
                    {{crdetail['components'][header]['current']['onDemandCost']}}/hr</div>
                </div>
              </div>
              <div><i class="fa fa-angle-right" aria-hidden="true" style="font-size: 2em;"></i></div>
              <div class="instance_ui">
                <div class="instnace_details">
                  <div><img src="../assets/img/sawyam test icons.png" alt=""></div>
                  <div class="font-bold-size">{{crdetail['components'][header]['required']['instanceType']}}</div>
                  <div class="font-small-light">RAM: {{crdetail['components'][header]['required']['ram']}} GiB</div>
                  <div class="font-small-light">vCPU: {{crdetail['components'][header]['required']['cpu']}}</div>
                  <div class="font-small-light">OD Cost:
                    {{crdetail['components'][header]['required']['onDemandCost']}}/hr</div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="header == 'storage'">
            <div>
              <div class="font-bold-size" style="padding: 10px 0px;">Existing Disks</div>
              <div>
                <table class="table table-docs">
                  <thead>
                    <tr>
                      <th>
                        Sl. No.
                      </th>
                      <th>
                        Volume ID
                      </th>
                      <th>
                        Volume Type
                      </th>
                      <th>
                        Current Size
                      </th>
                      <th>
                        Required Size
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let volume of getExistingVolumes(); let i = index">
                      <tr>
                        <td>
                          {{i + 1}}
                        </td>
                        <td>
                          {{volume['id']}}
                        </td>
                        <td>
                          {{volume['current']['type']}}
                        </td>
                        <td>
                          {{volume['current']['size']}}
                        </td>
                        <td>
                          {{volume['required']['size']}}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div class="font-bold-size" style="padding: 10px 0px;">New Disks</div>
              <div>
                <table class="table table-docs">
                  <thead>
                    <tr>
                      <th>
                        Sl. No.
                      </th>
                      <th>
                        Volume Type
                      </th>
                      <th>
                        Required Size
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let volume of getNewVolumes(); let i = index">
                      <tr>
                        <td>
                          {{i + 1}}
                        </td>
                        <td>
                          {{volume['type']}}
                        </td>
                        <td>
                          {{volume['size']}}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="crdetail['type'] == 'New Instance'">
      <app-new-instance [instanceObj]="crdetail" [readOnly]="true" (hideModel)="false">
      </app-new-instance>
    </div>
    <div *ngIf="!notReviewable">
      <div class="row" style="margin-top: 10px;">
        <span class="font-bold-size">Approver </span> <span>{{eId}}</span>
      </div>
      <div class="row" style="margin-top: 10px;">
        <span class="font-bold-size">Name </span> <span>{{un}}</span>
      </div>
      <div
        style="text-align: left; margin-top: 10px; display: flex; justify-content: flex-start; align-items: center; gap: 10px;">
        <input type="checkbox" name="" id="accept" [(ngModel)]="checked"> <label for="accept">I agree to the Change
          request being
          proposed</label>
      </div>
      <div style="margin-top: 10px; display: flex; justify-content: flex-start; align-items: center; gap: 10px;">
        <button class="btn btn-success-soft" (click)="showConfirm = 'approve'"
          [attr.disabled]="checked ? null: true">Approve</button>
        <button class="btn btn-danger-soft" (click)="showConfirm = 'reject'">Reject</button>
      </div>
    </div>
  </div>
</div>

<changerequest-confirmaction *ngIf="showConfirm && !notReviewable" [accountId]="accountId" [regionId]="regionId"
  [jira_organization_id]="jira_organization_id" [jira_project_id]="jira_project_id" [cr_detail]="crdetail"
  [cr_id]="cr_id" [action]="showConfirm" [setMessage]="setMessage" (hideModel)="hide($event)">
</changerequest-confirmaction>