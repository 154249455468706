<div class="card">
  <div class="card-header">
    <h5>AMIs</h5>
  </div>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; align-items: center; gap: 8px;">
      <button *ngIf="scans.length > 0" class="btn btn-light" style="background-color: #5864FF; color: white;"
        (click)="createRuleTrigger()">Scan</button>
      <button *ngIf="!fetch_scans" class="btn btn-light" style="background-color: #5864FF; color: white;"
        (click)="load()">Refresh</button>
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of scansHeaders">{{h.name}}</th>
          <th>Report</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': scans.length > 0 && !fetch_scans ? 'table-row-group': ''}">
        <tr *ngFor="let i of scans; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of scansHeaders">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              <span *ngIf="h.id != 'status'">{{h.alter ? h.alter(i, h.id) : i[h.id]}}</span>
              <span *ngIf="h.id == 'status'">
                <amis-status [data]="i"></amis-status>
              </span>
            </span>
          </td>
          <td>
            <span class="btn btn-light" *ngIf="i.status == 'Completed'" (click)="downloadReport(i)">
              Download
            </span>
            <span class="btn btn-light muted" *ngIf="i.status != 'Completed'" (click)="downloadReport(i)">
              Download
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': scans.length == 0 && !fetch_scans ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="scansHeaders.length + 1">
            <div style="text-align: center;">No Scans Found.
              <span class="btn_" style="padding: 0px; margin: 0px;" (click)="createRuleTrigger()">Click here</span> to
              create
              one
            </div>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': fetch_scans ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="scansHeaders.length + 1">
            <div style="text-align: center;">
              <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 25px; margin: 10px;">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="card" *ngIf="createScan">
  <createscan-amis
    [description]="'Depending on the number of AMIs in your account, it may take some time to complete. <br> Are you sure you want to continue?'"
    [message]="'[message]'" [data]="{account_id: accountId, region_name: regionId}" (hideModal)="close($event)">
  </createscan-amis>
</div>


<style>
  .muted {
    pointer-events: none;
    opacity: 0.4;
  }
</style>