<div class="card">
  <div class="card-header flex-between">
    <h5>Sales Report</h5>
    <div class="flex-start">
      <button class="btn btn-primary-soft" (click)="fileupload.click()">
        Upload
      </button>
      <button class="btn btn-primary-soft" (click)="addRow()">
        Add Row
      </button>
      <button
        class="btn btn-primary-soft"
        *ngIf="rows.length > 0"
        (click)="export()"
      >
        Export
      </button>
      <button
        class="btn btn-primary-soft"
        *ngIf="rows.length > 0"
        (click)="rows = []"
      >
        Reset
      </button>
      <button
        class="btn btn-primary-soft"
        *ngIf="rows.length > 0"
        (click)="fetch()"
      >
        Fetch
      </button>
      <input type="file" (change)="checkFile($event)" style="display: none" #fileupload />
    </div>
  </div>
  <div class="card-body">
    <div class="overflow">
      <table class="table table-docs">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>Region</th>
            <th>OS</th>
            <th>Instance Type</th>
            <th>Org Hours</th>
            <th>SP Hours</th>
            <th>Hours</th>
            <th>OD Rate</th>
            <th>OD Cost</th>
            <th>One Year Standard Rate</th>
            <th>One Year Standard Cost</th>
            <th>Three Year Standard Rate</th>
            <th>Three Year Standard Cost</th>
            <th>One Year Convertible Rate</th>
            <th>One Year Convertible Cost</th>
            <th>Three Year Convertible Rate</th>
            <th>Three Year Convertible Cost</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of rows; let i = index">
            <td>{{ i + 1 }}</td>
            <td>
              <select class="form-select" [(ngModel)]="row['region']">
                <option value="{{ region.id }}" placeholder="Select Region" *ngFor="let region of regions">{{ region.name }}</option>
              </select>
            </td>
            <td><input type="text" placeholder="OS" [(ngModel)]="row['os']" class="form-control"></td>
            <td><input type="text" placeholder="Instance Type" [(ngModel)]="row['instanceType']" class="form-control"></td>
            <td><input type="text" placeholder="Org Hours" [(ngModel)]="row['orgHours']" class="form-control"></td>
            <td><input type="text" placeholder="SP Hours" [(ngModel)]="row['spHours']" class="form-control"></td>
            <td class="center">{{ row?.hours || '-' }}</td>
            <td class="center">{{ row?.odRate?.toFixed(4) || '-' }}</td>
            <td class="center">{{ row?.odCost?.toFixed(4) || '-' }}</td>
            <td class="center">{{ row?.oneYrStdRate?.toFixed(4) || '-' }}</td>
            <td class="center">{{ row?.oneYrStdCost?.toFixed(4) || '-' }}</td>
            <td class="center">{{ row?.threeYrStdRate?.toFixed(4) || '-' }}</td>
            <td class="center">{{ row?.threeYrStdCost?.toFixed(4) || '-' }}</td>
            <td class="center">{{ row?.oneYrConvRate?.toFixed(4) || '-' }}</td>
            <td class="center">{{ row?.oneYrConvCost?.toFixed(4) || '-' }}</td>
            <td class="center">{{ row?.threeYrConvRate?.toFixed(4) || '-' }}</td>
            <td class="center">{{ row?.threeYrConvCost?.toFixed(4) || '-' }}</td>
            <td><button class="btn btn-light" (click)="removeRow(i)">Remove Row</button></td>
          </tr>
          <tr *ngIf="rows.length == 0">
            <td [attr.colspan]="18" class="center">No Data Found</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
