import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';

import { APIService } from './../../api/api.service';
import { NotifierService } from './../../_services/notifier.service';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  @Output() hideModel = new EventEmitter<boolean>();
  otpRequired: boolean = false;
  public userNameForm = new UntypedFormGroup({
    username: new UntypedFormControl('addet0260@gmail.com', []),
  });

  public newPasswordForm = new UntypedFormGroup({
    otp: new UntypedFormControl('1234', []),
    new_password: new UntypedFormControl('1234', []),
    confirm_new_password: new UntypedFormControl('1234', []),
  });

  get username_form() {
    return this.userNameForm.controls;
  }

  get new_password_form() {
    return this.newPasswordForm.controls;
  }

  otpSent: boolean = false;

  username: string = '';
  otp: string = '';
  newPassword: string = '';
  newConfirmPassword: string = '';
  role: string = 'Admin';
  hideRoleSelection: any = false;
  CBSPortal: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    if (window.location.href.indexOf('app.swayam.cloud') != -1) {
      this.role = 'Admin';
      this.hideRoleSelection = true;
    } else if (window.location.href.indexOf('client.swayam.cloud') != -1) {
      this.role = 'Client';
      this.hideRoleSelection = true;
    } else if (window.location.href.indexOf('cbs.swayam.cloud') != -1) {
      this.role = 'Admin';
      this.hideRoleSelection = true;
    } else if (window.location.href.indexOf('cbsadmin.swayam.cloud') != -1) {
      this.role = 'Admin';
      this.hideRoleSelection = true;
    }
  }

  async sendOTP() {
    this.notifier.loading(true);
    let data = {
      eId: this.username.trim(),
      ut: this.role == 'Admin' ? 'msops' : 'client',
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${
      this.role == 'Admin' ? 'admin' : 'client'
    }/users/forgotpassword`;

    let result = await this.apiServer.postDataPromis(apiURL, data, {});

    if (result.status == '1' || result.s == '1') {
      if (result.statusCode == 200) {
        this.otpSent = true;
        this.notifier.alert('Success', '', result.msg, 'success', 5000);
      } else {
        this.notifier.alert(
          'Error',
          '',
          result.error || result.msg,
          'error',
          5000
        );
      }
    } else {
      this.notifier.alert(
        'Error',
        '',
        result.error || result.msg,
        'error',
        5000
      );
    }
    this.notifier.loading(false);
  }

  async resetPassword() {
    if (this.newPassword.trim() != this.newConfirmPassword.trim()) {
      this.notifier.alert('Info', '', 'Passwords do not match', 'info', 5000);
      return;
    }
    if (!this.otp) {
      this.notifier.alert('Info', '', 'OTP Field is Mandatory', 'info', 5000);
      return;
    }
    this.notifier.loading(true);
    let data = {
      eId: this.username.trim(),
      pswd: this.newPassword.trim(),
      otp: this.otp.trim(),
      ut: this.role == 'Admin' ? 'msops' : 'client',
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${
      this.role == 'Admin' ? 'admin' : 'client'
    }/users/confirmforgotpassword`;

    let result = await this.apiServer.postDataPromis(apiURL, data, {});

    if (result.status == '1' || result.s == '1') {
      this.hideModel.emit(true);
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }
}
