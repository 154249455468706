import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
// import * as echarts from 'echarts';

import * as moment from 'moment';

declare let $: any;
declare let window: any;

// type EchartsOption = echarts.EChartsOption;

@Component({
  selector: 'cost-variance-report',
  templateUrl: 'billing.component.html',
  styleUrls: ['./billing.component.css'],
})
export class BetaDailyBillingComponent implements OnInit, OnDestroy {
  @Input('mid') mid: any;
  @Input('lid') lid: any;
  @Output('close') close = new EventEmitter<boolean>();
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  summary: any = [];
  analytics: any;
  activeAccount: any = null;
  diffDays: number;
  dateRange: string;
  billingOf: string = 'ec2';
  clickables: any = [
    'Amazon Elastic Compute Cloud',
    'Amazon CloudFront',
    'Amazon Relational Database Service',
  ];
  servicesData: any = {
    ec2: {
      'service-cost': 0,
      'service-name': 'AWS Elastic Compute Cloud',
      'service-credits': 0,
    },
    rds: {
      'service-cost': 0,
      'service-name': 'AWS Relational Database Service',
      'service-credits': 0,
    },
    cloudfront: {
      'service-cost': 0,
      'service-name': 'AWS Cloudfront',
      'service-credits': 0,
    },
    others: {
      'service-cost': 0,
      'service-name': 'Others',
      'service-credits': 0,
      services: [],
    },
  };

  funRef: any;

  summaryHeader: any = [
    {
      id: 'lid',
      name: 'Linked Account Number',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
    {
      id: 'accname',
      name: 'Account Name',
    },
    {
      id: 'scount',
      name: 'Service Count',
    },
    {
      id: 'credits',
      name: 'Credits',
    },
    {
      id: 'totalcost',
      name: 'Cost(appr.)',
    },
  ];

  latestDataSummaryheader: any = [
    {
      id: 'lid',
      name: 'Linked Account Number',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
  ];

  billsHeader: any = [
    {
      id: 'service-name',
      name: 'Service Name',
      clickCheck: (id: any) => {
        if (
          id == 'Amazon CloudFront' ||
          id == 'Amazon Relational Database Service' ||
          id == 'Amazon Elastic Compute Cloud'
        ) {
          return true;
        }
        return false;
      },
      click: 'loadInnerData',
    },
    { id: 'html', name: 'Service Cost' },
  ];

  accountId: any;
  regionId: any;
  currentMessage: any;

  masterAccounts: any = [];
  downloadAll: any = null;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}
  ngOnInit(): void {
    this.funRef = {
      showData: this.showData,
      loadInnerData: this.loadInnerData,
    };

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    $('#masterAccountSelect').selectpicker();

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      this.masterAccounts = [];
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.appended = [];
        this.summary = [];
        this.response = null;
        this.masterAccounts = [];
        this.responseData = [];
        setTimeout(() => {
          $('#masterAccountSelect').selectpicker('refresh');
        }, 100);
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.appended = [];
        this.summary = [];
        this.response = null;
        this.masterAccounts = [];
        this.responseData = [];
        setTimeout(() => {
          $('#masterAccountSelect').selectpicker('refresh');
        }, 100);
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    this.analytics = null;
    if (this.lid) {
      this.accountId = this.lid;
    }
    await this.fetchMasterAccounts();
  }

  startOf: any;
  endOf: any;
  range: string = 'last_7_days';

  getRangeDates() {
    let response = { sd: '', ed: '' };
    if (this.range == 'last_7_days') {
      response['sd'] = moment().subtract(7, 'days').format('YYYY-MM-DD');
      response['ed'] = moment().subtract(1, 'days').format('YYYY-MM-DD');
    }
    if (this.range == 'last_week') {
      response['sd'] = moment()
        .subtract(1, 'weeks')
        .startOf('week')
        .format('YYYY-MM-DD');
      response['ed'] = moment()
        .subtract(1, 'weeks')
        .endOf('week')
        .format('YYYY-MM-DD');
    }
    if (this.range == 'this_week') {
      response['sd'] = moment().startOf('week').format('YYYY-MM-DD');
      response['ed'] = moment().format('YYYY-MM-DD');
    }
    if (this.range == 'this_month') {
      response['sd'] = moment().startOf('month').format('YYYY-MM-DD');
      response['ed'] = moment().format('YYYY-MM-DD');
    }
    if (this.range == 'last_month') {
      response['sd'] = moment()
        .subtract(1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
      response['ed'] = moment()
        .subtract(1, 'months')
        .endOf('month')
        .format('YYYY-MM-DD');
    }
    if (this.range == 'last_3_month') {
      response['sd'] = moment()
        .subtract(3, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
      response['ed'] = moment()
        .subtract(1, 'months')
        .endOf('month')
        .format('YYYY-MM-DD');
    }
    if (this.range == 'last_6_month') {
      response['sd'] = moment()
        .subtract(6, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
      response['ed'] = moment()
        .subtract(1, 'months')
        .endOf('month')
        .format('YYYY-MM-DD');
    }
    return response;
  }

  checkIfClickable(value: string) {
    return this.clickables.includes(value);
  }

  customRange: string;
  customDate: string;
  parsedHeader(value: string): string {
    if (
      (value.indexOf(' - ') > -1 &&
        this.range != 'last_3_month' &&
        this.range != 'last_6_month') ||
      this.customRange == 'month'
    ) {
      value = value
        .split(' - ')
        .map((i: any) => {
          return moment(i, 'YYYY-MM-DD').format('MMM DD');
        })
        .join(' - ');
    } else if (
      (this.range == 'last_3_month' || this.range == 'last_6_month') &&
      !this.customRange
    ) {
      value = value.split(' - ').map((i: any) => {
        return moment(i, 'YYYY-MM-DD').format('MMM');
      })[0];
    } else {
      value = moment(value, 'YYYY-MM-DD').format('MMM DD');
    }
    return value;
  }

  parsedHeader_days(value: string): any {
    if (
      this.customRange == 'month' ||
      this.range == 'last_month' ||
      this.range == 'this_month'
    ) {
      let dt = value.split(' - ');
      let diffDays = this.fetchDiffDays(dt[0], dt[1]);
      if (diffDays >= 7) {
        return null;
      } else {
        return diffDays;
      }
    }
    return null;
  }

  fetchMonthYear() {
    let month_year: string;
    if (this.customRange == 'month') {
      month_year = this.customDate;
    } else if (this.range == 'last_month') {
      month_year = moment().subtract(1, 'months').format('YYYY-MM-DD') + ' - ';
    } else {
      month_year = moment().format('YYYY-MM-DD') + ' - ';
    }
    month_year = moment(month_year.split(' - ')[0], 'YYYY-MM-DD').format(
      'YYYY-MM'
    );
    return month_year;
  }

  fetchServiceTotal(service_: string, plain: boolean = false) {
    let total: any = { totalBill: 0, finalTotalBill: 0 };
    this.responseData.forEach((date: any) => {
      date.data.forEach((service: any) => {
        if (service.name == service_) {
          total['totalBill'] += service['totalBill'];
          total['finalTotalBill'] += service['finalTotalBill'];
        }
      });
    });

    if (plain) {
      return total;
    }

    if (total['finalTotalBill'] != total['totalBill']) {
      return `
        <span class='line_through'>
          $${total['totalBill'].toFixed(2)}
        </span>
        <span>
          $${total['finalTotalBill'].toFixed(2)}
        </span>
      `;
    } else {
      return `
        <span>
          $${total['finalTotalBill'].toFixed(2)}
        </span>
      `;
    }
  }
  fetchSubServiceTotal(
    service_: string,
    sub_service_: string,
    plain: boolean = false
  ) {
    let total: any = { totalBill: 0, finalTotalBill: 0 };
    this.responseData.forEach((date: any) => {
      let service_obj = date.data.find((service: any) => {
        return service.name == service_;
      });
      if (service_obj) {
        service_obj.subComponent.forEach((sub_service: any) => {
          if (sub_service.name == sub_service_) {
            total['totalBill'] += sub_service['totalBill'];
            total['finalTotalBill'] += sub_service['finalTotalBill'];
          }
        });
      }
    });

    if (plain) {
      return total;
    }

    if (total['finalTotalBill'] != total['totalBill']) {
      return `
        <span class='line_through'>
          $${total['totalBill'].toFixed(2)}
        </span>
        <span>
          $${total['finalTotalBill'].toFixed(2)}
        </span>
      `;
    } else {
      return `
        <span>
          $${total['finalTotalBill'].toFixed(2)}
        </span>
      `;
    }
  }

  loadHeaders() {
    let headers: any = [];
    let rangeDates = this.getRangeDates();
    let diff: number = this.fetchDiffDays(rangeDates['sd'], rangeDates['ed']);
    if (this.range == 'last_7_days' && !this.customRange) {
      for (let i = 7; i > 0; i--) {
        headers.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
      }
    }
    if (this.range == 'last_week' && !this.customRange) {
      for (let i = 0; i < 7; i++) {
        headers.push(
          moment()
            .subtract(1, 'weeks')
            .startOf('week')
            .add(i, 'days')
            .format('YYYY-MM-DD')
        );
      }
    }
    if (this.range == 'this_week' && !this.customRange) {
      for (let i = 0; i < diff; i++) {
        headers.push(
          moment().startOf('week').add(i, 'days').format('YYYY-MM-DD')
        );
      }
    }
    if (this.range == 'this_month' && !this.customRange) {
      if (diff > 7) {
        let index = 7;
        headers.push(
          moment().startOf('month').format('YYYY-MM-DD') +
            ' - ' +
            moment().startOf('month').add(6, 'days').format('YYYY-MM-DD')
        );
        while (diff - index > 0) {
          if (diff - index > 6) {
            headers.push(
              moment()
                .subtract(diff - index - 1, 'days')
                .format('YYYY-MM-DD') +
                ' - ' +
                moment()
                  .subtract(diff - index - 7, 'days')
                  .format('YYYY-MM-DD')
            );
            index += 7;
          } else {
            headers.push(
              moment()
                .subtract(diff - index - 1, 'days')
                .format('YYYY-MM-DD') +
                ' - ' +
                moment().format('YYYY-MM-DD')
            );
            index = diff;
          }
        }
      } else {
        let diff_ = this.fetchDiffDays(
          moment().startOf('month').format('YYYY-MM-DD'),
          rangeDates['ed']
        );

        for (let i = diff_ - 1; i >= 0; i--) {
          headers.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
        }
      }
    }
    if (this.range == 'last_month' && !this.customRange) {
      let index = 0;
      while (diff - index > 0) {
        if (diff - index > 6) {
          headers.push(
            moment()
              .subtract(1, 'month')
              .endOf('month')
              .subtract(diff - index - 1, 'days')
              .format('YYYY-MM-DD') +
              ' - ' +
              moment()
                .subtract(1, 'month')
                .endOf('month')
                .subtract(diff - index - 1 - 6, 'days')
                .format('YYYY-MM-DD')
          );
          index += 7;
        } else {
          headers.push(
            moment()
              .subtract(1, 'month')
              .endOf('month')
              .subtract(diff - index - 1, 'days')
              .format('YYYY-MM-DD') +
              ' - ' +
              moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
          );
          index = diff;
        }
      }
    }
    if (this.range == 'last_3_month' && !this.customRange) {
      for (let i = 3; i > 0; i--) {
        headers.push(
          moment().subtract(i, 'month').startOf('month').format('YYYY-MM-DD') +
            ' - ' +
            moment().subtract(i, 'month').endOf('month').format('YYYY-MM-DD')
        );
      }
    }
    if (this.range == 'last_6_month' && !this.customRange) {
      for (let i = 6; i > 0; i--) {
        let limit_data = moment
          .duration(
            moment()
              .subtract(i, 'month')
              .startOf('month')
              .diff(moment('03-2022', 'MM-YYYY').startOf('month'))
          )
          .asMonths();

        if (limit_data <= 0) {
          continue;
        }

        headers.push(
          moment().subtract(i, 'month').startOf('month').format('YYYY-MM-DD') +
            ' - ' +
            moment().subtract(i, 'month').endOf('month').format('YYYY-MM-DD')
        );
      }
    }

    if (this.customRange) {
      let values = this.customDate.split(' - ');
      if (this.customRange == 'month') {
        let index = 0;
        let diff_ =
          Number(moment(values[1], 'YYYY-MM-DD').format('DD')) -
          Number(moment(values[0], 'YYYY-MM-DD').format('DD')) +
          1;
        while (diff_ - index > 0) {
          if (diff_ - index > 6) {
            headers.push(
              moment(values[0], 'YYYY-MM-DD')
                .endOf('month')
                .subtract(diff_ - index - 1, 'days')
                .format('YYYY-MM-DD') +
                ' - ' +
                moment(values[0], 'YYYY-MM-DD')
                  .endOf('month')
                  .subtract(diff_ - index - 1 - 6, 'days')
                  .format('YYYY-MM-DD')
            );
            index += 7;
          } else {
            headers.push(
              moment(values[0], 'YYYY-MM-DD')
                .endOf('month')
                .subtract(diff_ - index - 1, 'days')
                .format('YYYY-MM-DD') +
                ' - ' +
                moment(values[0], 'YYYY-MM-DD')
                  .endOf('month')
                  .format('YYYY-MM-DD')
            );
            index = diff_;
          }
        }
      }
      if (this.customRange == 'week') {
        for (let i = 0; i < this.fetchDiffDays(values[0], values[1]); i++) {
          headers.push(
            moment(values[0], 'YYYY-MM-DD').add(i, 'days').format('YYYY-MM-DD')
          );
        }
      }
    }
    return headers;
  }

  compareValue(current: any, previous: any, last: boolean = false) {
    let dt = { type: '', change: '0', indicator: 0, color: 'white' };
    if (current == '-' || previous == '-') {
      dt['type'] = 'transparent';
      dt['indicator'] = 0;
      return dt;
    }

    if (!isNaN(current)) {
      current = current.toString();
    }
    if (!isNaN(previous)) {
      previous = previous.toString();
    }
    if (current.indexOf('$') > -1) {
      current = Number(current.replace('$', ''));
    } else {
      current = Number(current);
    }
    if (previous.indexOf('$') > -1) {
      previous = Number(previous.replace('$', ''));
    } else {
      previous = Number(previous);
    }
    current = Number(current.toFixed(2));
    previous = Number(previous.toFixed(2));
    if (
      last &&
      (this.customRange == 'month' ||
        this.range == 'last_month' ||
        this.range == 'this_month') &&
      this.customRange != 'week'
    ) {
      let remain = this.parsedHeader_days(
        this.loadHeaders()[this.loadHeaders().length - 1]
      );
      if (remain) {
        current = (current / remain) * 7;
      }
    }
    if (current == '0.00') {
      dt['type'] = 'transparent';
      dt['indicator'] = 0;
      return dt;
    }
    if (Number(current) > Number(previous)) {
      dt['change'] = ((Number(current) / Number(previous)) * 100 - 100).toFixed(
        1
      );
      dt['type'] = '#fff0f6';
      dt['color'] = '#c94d6d';
      dt['indicator'] = -1;
    } else if (Number(current) < Number(previous)) {
      dt['change'] = (100 - (Number(current) / Number(previous)) * 100).toFixed(
        1
      );
      dt['type'] = '#eefbf4';
      dt['color'] = '#58c388';
      dt['indicator'] = 1;
    } else {
      dt['indicator'] = 0;
      dt['type'] = 'transparent';
    }
    if (dt['change'] == 'Infinity') {
      dt['change'] = '0';
      dt['indicator'] = 0;
    }
    return dt;
  }

  responseData: any = [];
  appended: any = [];
  async fetchRangeBillData(masterAccId: any, custom?: string) {
    this.notifier.loading(true);
    this.activeService = null;
    let range_ = this.getRangeDates();
    let data: any = {
      a: 'fetchDateWiseTotalCost',
      sd: moment(range_['sd'], 'YYYY-MM-DD')
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      ed: moment(range_['ed'], 'YYYY-MM-DD')
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      mId: masterAccId,
      lId: this.accountId,
    };

    if (custom) {
      let values = custom.split(' - ');
      if (values.length > 1) {
        data['sd'] = moment(values[0], 'YYYY-MM-DD')
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
        data['ed'] = moment(values[1], 'YYYY-MM-DD')
          .add(1, 'days')
          .format('YYYY-MM-DD');
      } else {
        return;
      }
    }

    let months;
    if (this.range == 'last_3_month' && !custom) {
      months = [0, 1, 2].map((value: any) => {
        let month: string = moment()
          .subtract(3 - value, 'months')
          .format('YYYY-MM');

        return month;
      });
      data = {
        a: 'fetchMonthlyTotalCost',
        mId: masterAccId,
        lId: this.accountId,
        ml: months,
      };
    }

    if (this.range == 'last_6_month' && !custom) {
      months = [0, 1, 2, 3, 4, 5].map((value: any) => {
        let month: string = moment()
          .subtract(6 - value, 'months')
          .format('YYYY-MM');
        return month;
      });
      data = {
        a: 'fetchMonthlyTotalCost',
        mId: masterAccId,
        lId: this.accountId,
        ml: months,
      };
    }
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchdailybillingdetails`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' && result.documentsData.length > 0) {
      this.responseData = this.parsedData(result.documentsData);
      if (
        (this.range == 'last_3_month' || this.range == 'last_6_month') &&
        this.customRange == null &&
        !this.activeService &&
        this.urlPrefix == 'admin'
      ) {
        this.response = {};
        months.forEach((month: any) => {
          this.loadSummaryData(masterAccId, month);
        });
      } else if (
        (this.customRange == 'month' ||
          this.range == 'last_month' ||
          this.range == 'this_month') &&
        this.urlPrefix == 'admin'
      ) {
        this.loadSummaryData(
          masterAccId,
          moment(data['sd'], 'YYYY-MM-DD').add(1, 'months').format('YYYY-MM')
        );
      }
      this.setSubServices();
    } else {
      this.responseData = [];
      this.appended = [];
    }
    // this.loadGraph();
    this.notifier.loading(false);
  }

  activeService: string;
  expandService(service: any) {
    if (this.expanded[service] != undefined) {
      this.expanded[service] = !this.expanded[service];
    } else {
      this.loadMore(service);
    }
  }

  loadPrasedValue(obj: any) {
    if (obj == '-') {
      return obj;
    }
    if (!obj['finalTotalBill'] || !obj['totalBill']) {
      if (obj['finalTotalBill'] == 0 || obj['totalBill'] == 0) {
        return '<span>0.00</span>';
      }
      return `<span>$${obj.toFixed(2)}</span>`;
    }
    if (obj['finalTotalBill'] != obj['totalBill']) {
      return `
        <span>
          <span class='line_through'>$${obj['totalBill'].toFixed(2)}</span>
          <span>$${obj['finalTotalBill'].toFixed(2)}</span>
        </span>`;
    } else {
      return `<span>$${obj['finalTotalBill'].toFixed(2)}</span>`;
    }
  }

  loadMore(service: string) {
    this.loadInnerDataDaily(
      $('#masterAccountSelect').val(),
      service,
      this.customRange
    );
    this.activeService = service;
  }

  isExportable: boolean = true;
  loadingObj: any = {};
  async loadInnerDataDaily(masterAccId: any, service: string, custom?: string) {
    let payload: any = {
      mId: masterAccId,
      lId: this.accountId,
    };
    let rangetype: string = 'monthly';
    let headers: any = this.loadHeaders();
    if (custom == 'month') {
      rangetype = 'weekly';
    } else if (custom == 'week') {
      rangetype = 'daily';
    } else if (this.range == 'last_3_month' || this.range == 'last_6_month') {
      rangetype = 'monthly';
    } else if (this.range == 'last_month' || this.range == 'this_month') {
      rangetype = 'weekly';
    } else {
      rangetype = 'daily';
    }
    if (service == 'EC2') {
      payload['key'] = 'instanceID (instanceType)';
      payload['checks'] = ['instanceID', 'instanceType'];

      if (rangetype == 'monthly') {
        payload['a'] = 'fetchEc2Monthly';
      }
      if (rangetype == 'weekly') {
        payload['a'] = 'fetchEc2Weekly';
      }
      if (rangetype == 'daily') {
        payload['a'] = 'fetchEc2Daily';
      }
    } else if (service == 'EBS') {
      payload['key'] = 'volumeId';

      if (rangetype == 'monthly') {
        payload['a'] = 'fetchEbsMonthly';
      }
      if (rangetype == 'weekly') {
        payload['a'] = 'fetchEbsWeekly';
      }
      if (rangetype == 'daily') {
        payload['a'] = 'fetchEbsDaily';
      }
    } else if (service == 'Others') {
      payload['key'] = 'usageId';

      if (rangetype == 'monthly') {
        payload['a'] = 'fetchOthersMonthly';
      }
      if (rangetype == 'weekly') {
        payload['a'] = 'fetchOthersWeekly';
      }
      if (rangetype == 'daily') {
        payload['a'] = 'fetchOthersDaily';
      }
    } else if (service == 'Amazon Relational Database Service') {
      payload['key'] = 'resourceID';

      if (rangetype == 'monthly') {
        payload['a'] = 'fetchRDMonthly';
      }
      if (rangetype == 'weekly') {
        payload['a'] = 'fetchRDSWeekly';
      }
      if (rangetype == 'daily') {
        payload['a'] = 'fetchRDSDaily';
      }
    } else if (service == 'Amazon CloudFront') {
      payload['key'] = 'description';

      if (rangetype == 'monthly') {
        payload['a'] = 'fetchCfrcMonthly';
      }
      if (rangetype == 'weekly') {
        payload['a'] = 'fetchCfrcWeekly';
      }
      if (rangetype == 'daily') {
        payload['a'] = 'fetchCfrcDaily';
      }
    } else {
      return;
    }

    this.appended = [];
    this.responseData = [];

    let promises: any = [];
    this.isExportable = false;
    this.notifier.loading(true);

    for (let i = 0; i < headers.length; i++) {
      if (rangetype == 'monthly') {
        const element = headers[i].split(' - ');
        payload['d'] = element[0];
      } else if (rangetype == 'weekly') {
        const element = headers[i].split(' - ');
        payload['sd'] = moment(element[0], 'YYYY-MM-DD')
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
        payload['ed'] = moment(element[1], 'YYYY-MM-DD')
          .add(1, 'days')
          .format('YYYY-MM-DD');
      } else if (rangetype == 'daily') {
        const element = headers[i];
        payload['d'] = element;
      }
      promises.push(this.fetchRangeBillDataNested(payload));
    }
    Promise.all(promises).then((values: any) => {
      this.isExportable = true;
      this.notifier.loading(false);
    });
    this.breadCrum.push({
      id: 'fetch_master',
      value: service,
    });
  }

  async fetchRangeBillDataNested(payload: any) {
    return new Promise(async (resolve: any, reject: any) => {
      let header = {
        Authorization: localStorage.getItem('t'),
      };

      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchdailybillingdetails`;
      let data = { ...payload };
      let key = data['key'];
      let checks = data['checks'];
      data['key'] = undefined;
      data['checks'] = undefined;
      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == '1' && result.documentsData.length > 0) {
        let dt: any = this.parsedDataNested(result.documentsData, key, checks);
        this.responseData.push(...dt);
      }
      if (result.id && result.id != '') {
        data['id'] = result.id;
        data['key'] = payload['key'];
        data['checks'] = payload['checks'];
        await this.fetchRangeBillDataNested(data);
      } else {
        if (payload['id']) {
          delete payload['id'];
        }
      }
      resolve(true);
    });
  }

  response: any = {};
  async loadSummaryData(master_account_id: any, month_year: string) {
    if (this.urlPrefix != 'admin') {
      return;
    }
    this.loadingObj[month_year] = true;
    let data = {
      masterAccId: master_account_id,
      month_year: moment(month_year, 'YYYY-MM').format('MM-YYYY'),
      lid: [this.accountId],
      userType: 'C',
      action: 'supportPlanDetails',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/getservicelevelbill`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.response[month_year] = result.billDetails['items'][0]['services'];
      if (
        result.appliedSupportPlanInfoList &&
        result.appliedSupportPlanInfoList.length > 0
      ) {
        result.appliedSupportPlanInfoList.forEach((support: any) => {
          let service: any = this.response[month_year].find((service: any) => {
            return (
              service['service_name']
                .toLowerCase()
                .indexOf(support['supportPlanType'].toLowerCase()) > -1
            );
          });
          service['service_cost'] = (
            support['discountedSupportPlanRate'] ||
            support['updatedRate'] ||
            0
          ).toString();
        });
      }
      if (result.hasOwnProperty('finalCFRCTotalCost')) {
        let cfrc = this.response[month_year].find((service: any) => {
          return service['service_name'] == 'Amazon CloudFront';
        });
        if (cfrc) {
          cfrc['service_cost'] = result['finalCFRCTotalCost'];
        }
        let rds = this.response[month_year].find((service: any) => {
          return (
            service['service_name'] == 'Amazon Relational Database Service'
          );
        });
        if (rds) {
          rds['service_cost'] = result['finalRDSUpdatedCost'];
        }
        let ec2 = this.response[month_year].find((service: any) => {
          return service['service_name'] == 'Amazon Elastic Compute Cloud';
        });
        if (ec2) {
          ec2['components'] = {
            EC2:
              ec2['service_cost'] -
              result.ebsTotalCost -
              result.othersTotalCost,
            EBS: result.ebsTotalCost,
            Others: result.othersTotalCost,
          };
        }
      }
    }
    this.loadingObj[month_year] = false;
  }

  getValidMonth(month: any) {
    return moment(month.split(' - ')[0], 'YYYY-MM-DD').format('YYYY-MM');
  }

  monthTotal(month: any) {
    month = moment(month.split(' - ')[0], 'YYYY-MM-DD').format('YYYY-MM');

    let total: number = 0;
    if (this.response[month]) {
      this.response[month].forEach((service: any) => {
        total += Number(service['service_cost']);
      });
    }
    return total.toFixed(2);
  }

  checkForVariation(monthly_value: any, current_value: any, check?: boolean) {
    if (current_value == undefined || monthly_value == undefined) {
      return { varition: 0, check: false };
    }
    try {
      if (typeof current_value == 'string' && current_value.indexOf('$') > -1) {
        current_value = current_value.replace('$', '');
      }
      if (typeof monthly_value == 'string') {
        monthly_value = Number(monthly_value);
      }
      monthly_value = monthly_value.toFixed(2);
      if (typeof current_value == 'string') {
        current_value = Number(current_value);
      }
      current_value = current_value.toFixed(2);
      if (Number(monthly_value) != Number(current_value)) {
        return {
          variation: Number(
            Number(monthly_value) - Number(current_value)
          ).toFixed(2),
          check: false,
        };
      } else {
        return { variation: 0, check: true };
      }
    } catch (error) {
      return { variation: 0, check: true };
    }
  }

  getMonthlyValue(month_year: any, service: any, sub_service?: string) {
    if (month_year == 'MONTHLY') {
      if (this.customRange == 'month') {
        month_year = this.customDate;
      } else if (this.range == 'last_month') {
        month_year =
          moment().subtract(1, 'months').format('YYYY-MM-DD') + ' - ';
      } else {
        month_year = moment().format('YYYY-MM-DD') + ' - ';
      }
    }
    month_year = moment(month_year.split(' - ')[0], 'YYYY-MM-DD').format(
      'YYYY-MM'
    );
    if (this.response[month_year]) {
      let obj = this.response[month_year].find((service_: any) => {
        return service_.service_name == service;
      });
      if (obj) {
        if (sub_service) {
          return Number(obj.components[sub_service]);
        }
        return Number(obj['service_cost']);
      }
    }
    return 0.0;
  }

  breadCrum: any = [];
  async fetchRangeData(value: any) {
    if (
      (this.range == 'last_7_days' ||
        this.range == 'last_week' ||
        this.range == 'this_week') &&
      value.split(' - ').length == 1
    ) {
      this.customRange = null;
      this.breadCrum = [];
      return false;
    }

    if (this.customRange == 'week') {
      return false;
    }

    if (!this.customRange) {
      if (this.activeService) {
        this.breadCrum.splice(this.breadCrum.length - 1, 1);
      }
      if (this.range == 'last_month' || this.range == 'this_month') {
        this.breadCrum.push({ id: 'remove', value: this.parsedHeader(value) });
        this.customRange = 'week';
      } else {
        let temp = moment(value.split(' - ')[0], 'YYYY-MM-DD').format('MMM');
        this.breadCrum.push({
          id: 'month',
          value: temp,
          data: value,
        });
        this.customRange = 'month';
      }
    } else if (this.customRange == 'month') {
      if (this.activeService) {
        this.breadCrum.splice(this.breadCrum.length - 1, 1);
      }
      let temp = value
        .split(' - ')
        .map((value: any) => {
          return moment(value, 'YYYY-MM-DD').format('MMM DD');
        })
        .join(' - ');
      this.breadCrum.push({
        id: 'month',
        value: temp,
        data: value,
      });
      this.customRange = 'week';
    } else {
      if (this.activeService) {
        this.breadCrum.splice(this.breadCrum.length - 1, 1);
      }
      this.breadCrum = [];
      this.customRange = null;
      this.fetchMasterAccounts();
      return false;
    }

    this.customDate = value;

    if (this.activeService) {
      this.loadMore(this.activeService);
    } else {
      this.fetchRangeBillData($('#masterAccountSelect').val(), value);
    }

    return true;
  }

  fetchBreadCrum() {
    if (this.range == 'last_7_days') {
      this.breadCrum = [{ id: 'fetch_master', value: 'Last 7 Days' }];
    }
    if (this.range == 'last_week') {
      this.breadCrum = [{ id: 'fetch_master', value: 'Last Week' }];
    }
    if (this.range == 'this_week') {
      this.breadCrum = [{ id: 'fetch_master', value: 'This Week' }];
    }
    if (this.range == 'this_month') {
      this.breadCrum = [{ id: 'fetch_master', value: 'This Month' }];
    }
    if (this.range == 'last_month') {
      this.breadCrum = [{ id: 'fetch_master', value: 'Last Month' }];
    }
    if (this.range == 'last_3_month') {
      this.breadCrum = [{ id: 'fetch_master', value: 'Last 3 Months' }];
    }
    if (this.range == 'last_6_month') {
      this.breadCrum = [{ id: 'fetch_master', value: 'Last 6 Months' }];
    }
  }

  navigate(value: any) {
    this.activeService = null;

    if (value.id == 'remove') {
      this.customRange = null;
      this.breadCrum = [];
      this.fetchMasterAccounts();
    } else if (value.id == 'fetch_master') {
      this.fetchMasterAccounts();
    } else if (value.id == 'month') {
      this.customRange = null;
      this.activeService = null;
      this.breadCrum.splice(1);
      this.fetchRangeData(value.data);
    }
  }

  exportData() {
    let element = document.createElement('table');
    element.innerHTML = `
      <thead>
        <tr>
          <td style="font-weight: bold;">S. N.</td>
          <td style="font-weight: bold;">Service Name</td>
          ${this.loadHeaders()
            .map((h: any) => {
              return `
                <td>
                  <span>
                    ${this.parsedHeader(h)}
                  </span>
                  ${
                    this.parsedHeader_days(h)
                      ? `
                    <span>(${this.parsedHeader_days(h)})</span>
                  `
                      : ``
                  }
                </td>
              `;
            })
            .join(' ')}
        </tr>
        <tr>
          <td></td>
          <td></td>
          ${this.loadHeaders()
            .map((h: any) => {
              return `
                <td>
                  <span>
                    ${this.loadColumnTotal(h)}
                  </span>
                </td>
              `;
            })
            .join(' ')}
        </tr>
      </thead>
      <tbody>
        ${this.appended
          .map((service: any, index: number) => {
            return `
            <tr>
              <td>${index + 1}</td>
              <td>
                ${service}
              </td>
              ${this.loadHeaders()
                .map((h: any, h_index: any) => {
                  return `
                  <td>
                    ${
                      (this.loadValue(service, h) &&
                        this.loadValue(service, h)['finalTotalBill']) ||
                      0
                    }
                  </td>
                `;
                })
                .join('')}
            </tr>
            ${
              this.checkIfClickable(service) &&
              this.expanded.hasOwnProperty(service)
                ? `
              ${this.subServices(service)
                .map((sub_service: any) => {
                  return `
                  <tr>
                    <td></td>
                    <td>${sub_service}</td>
                    ${this.loadHeaders()
                      .map((h: any) => {
                        return `
                        <td>
                          ${
                            (this.loadNestedValue(service, h, sub_service) &&
                              this.loadNestedValue(service, h, sub_service)[
                                'finalTotalBill'
                              ]) ||
                            0
                          }
                        </td>
                      `;
                      })
                      .join(' ')}
                  </tr>
                `;
                })
                .join(' ')}
            `
                : ``
            }
          `;
          })
          .join(' ')}
      </tbody>
    `;
    window.exportToExcelElement(element, `${this.accountId}`, 'csv');
  }

  changeColor(index: number) {
    // index = index + 3;
    // document
    //   .getElementById('daily_bill_table')
    //   .querySelectorAll('tr')
    //   .forEach((tr: any) => {
    //     if (
    //       tr.querySelectorAll('td').length > 0 &&
    //       tr.querySelectorAll('td').length > index
    //     ) {
    //       tr.querySelectorAll('td')[index].style.backgroundColor =
    //         'rgba(0, 0, 0, 0.075)';
    //     }
    //     if (
    //       tr.querySelectorAll('th').length > 0 &&
    //       tr.querySelectorAll('th').length > index
    //     ) {
    //       tr.querySelectorAll('th')[index].style.backgroundColor =
    //         'rgba(0, 0, 0, 0.075)';
    //     }
    //   });
  }

  resetColor(index: number) {
    //   index = index + 3;
    //   document
    //     .getElementById('daily_bill_table')
    //     .querySelectorAll('tr')
    //     .forEach((tr: any) => {
    //       if (
    //         tr.querySelectorAll('td').length > 0 &&
    //         tr.querySelectorAll('td').length > index
    //       ) {
    //         tr.querySelectorAll('td')[index].style.backgroundColor =
    //           'transparent';
    //       }
    //       if (
    //         tr.querySelectorAll('th').length > 0 &&
    //         tr.querySelectorAll('th').length > index
    //       ) {
    //         tr.querySelectorAll('th')[index].style.backgroundColor =
    //           'transparent';
    //       }
    //     });
  }

  loadValue(service: string, date: string) {
    try {
      return this.responseData
        .find((i: any) => {
          return i.timestamp == date;
        })
        .data.find((i: any) => {
          return i.name == service;
        });
    } catch (err) {
      return '-';
    }
  }

  subservices: any = {};
  setSubServices() {
    this.subservices = {};
    this.responseData.forEach((date: any) => {
      date.data.forEach((service: any) => {
        if (service.subComponent) {
          if (!this.subservices[service.name]) {
            this.subservices[service.name] = {};
            this.expanded[service.name] = false;
          }
          service.subComponent.forEach((sub_service: any) => {
            if (!this.subservices[service.name][sub_service.name]) {
              this.subservices[service.name][sub_service.name] = [];
            }
            let sub_service_object = {
              timestamp: date.timestamp,
              ...sub_service,
            };
            this.subservices[service.name][sub_service.name].push(
              sub_service_object
            );
          });
        }
      });
    });
  }

  subServices(service: string) {
    return Object.keys(this.subservices[service]);
  }

  expanded: any = {};

  loadNestedValue(service: string, date: string, sub_service: string) {
    try {
      return this.subservices[service][sub_service].find((i: any) => {
        return i.timestamp == date;
      });
    } catch (err) {
      return '-';
    }
  }

  loadRangeString() {
    let range_ = this.getRangeDates();

    return `${moment(range_['sd'], 'YYYY-MM-DD').format(
      'MMM DD, YYYY'
    )} - ${moment(range_['ed'], 'YYYY-MM-DD').format('MMM DD, YYYY')}`;
  }

  loadAllColumnTotal() {
    let headers = this.loadHeaders();
    let total: number = 0;
    headers.forEach((h: any) => {
      let value: any = this.loadColumnTotal(h);
      if (value == '-') {
        total += 0;
      } else {
        if (value.indexOf('$') > -1) {
          value = value.replace('$', '');
        }
        total += Number(value);
      }
    });
    return total.toFixed(2);
  }

  loadAllColumnTotalParsed(plain: boolean = false) {
    let headers = this.loadHeaders();
    let totalFinal: number = 0;
    let total: number = 0;
    headers.forEach((h: any) => {
      let valueFinal: any = this.loadColumnTotal(h);
      let value: any = this.loadColumnTotalBefore(h);
      if (valueFinal == '-') {
        valueFinal = '0';
      }
      if (value == '-') {
        value = '0';
      }
      if (valueFinal.indexOf('$') > -1) {
        valueFinal = valueFinal.replace('$', '');
      }
      if (value.indexOf('$') > -1) {
        value = value.replace('$', '');
      }
      totalFinal += Number(valueFinal);
      total += Number(value);
    });

    if (plain) {
      return isNaN(totalFinal) ? 0 : totalFinal;
    }

    if (isNaN(totalFinal) || isNaN(total)) {
      return '<span>$0.0</span>';
    }

    if (totalFinal != total) {
      return `
            <span>
              <span class='line_through'>$${total.toFixed(2)}</span>
              <span>$${totalFinal.toFixed(2)}</span>
            </span>
          `;
    } else {
      return `
            <span>$${totalFinal.toFixed(2)}</span>
          `;
    }
  }

  loadColumnTotalParsed(date: string) {
    try {
      let dt = this.responseData.find((i: any) => {
        return i.timestamp == date;
      });
      if (dt) {
        let totalFinal: number = 0;
        let total: number = 0;
        dt.data.forEach((element: any) => {
          if (element['finalTotalBill']) {
            totalFinal += element['finalTotalBill'];
          }
          if (element['totalBill']) {
            total += element['totalBill'];
          }
        });
        if (totalFinal != total) {
          return `
            <span>
              <span class='line_through'>$${total.toFixed(2)}</span>
              <span>$${totalFinal.toFixed(2)}</span>
            </span>
          `;
        } else {
          return `
            <span>$${totalFinal.toFixed(2)}</span>
          `;
        }
      } else {
        return '-';
      }
    } catch (err) {
      return '-';
    }
  }
  loadColumnTotal(date: string) {
    try {
      let dt = this.responseData.find((i: any) => {
        return i.timestamp == date;
      });
      if (dt) {
        let total: number = 0;
        dt.data.forEach((element: any) => {
          total += element['finalTotalBill'];
        });
        return '$' + total.toFixed(2);
      } else {
        return '-';
      }
    } catch (err) {
      return '-';
    }
  }
  loadColumnTotalBefore(date: string) {
    try {
      let dt = this.responseData.find((i: any) => {
        return i.timestamp == date;
      });
      if (dt) {
        let total: number = 0;
        dt.data.forEach((element: any) => {
          if (element['totalBill']) {
            total += element['totalBill'];
          }
        });
        return '$' + total.toFixed(2);
      } else {
        return '0';
      }
    } catch (err) {
      return '0';
    }
  }

  // loadGraph() {
  //   let option: EchartsOption;
  //   var chartDom = document.getElementById('graph_main')!;
  //   let myChart = echarts.init(chartDom);

  //   option = {
  //     padding: 5,
  //     xAxis: {
  //       type: 'category',
  //       show: false,
  //       data: this.loadHeaders().map((h: any) => {
  //         return this.parsedHeader(h);
  //       }),
  //       boundaryGap: false,
  //     },
  //     grid: {
  //       left: '0%',
  //       right: '0%',
  //       bottom: '0%',
  //       containLabel: true,
  //     },
  //     yAxis: {
  //       type: 'value',
  //       show: false,
  //     },
  //     tooltip: {
  //       trigger: 'axis',
  //       axisPointer: {
  //         type: 'cross',
  //       },
  //     },
  //     series: [
  //       {
  //         data: this.loadHeaders().map((h: any) => {
  //           let value: any = this.loadColumnTotal(h);
  //           if (value.indexOf('$') > -1) {
  //             value = value.replace('$', '');
  //           }
  //           value = Number(value);
  //           return value;
  //         }),
  //         type: 'line',
  //       },
  //     ],
  //   };

  //   console.log(option);

  //   option && myChart.setOption(option);
  // }

  parsedData(responseData: any) {
    let headers = this.loadHeaders();
    let dt = [];
    this.appended = [];
    this.responseData = [];
    for (let j = 0; j < headers.length; j++) {
      let daterange_milli = headers[j].split(' - ').map((date: any) => {
        let date_: any = new Date(date);
        return Date.parse(date_);
      });
      daterange_milli = daterange_milli.map((date: any, index: number) => {
        if (index == 0) {
          date = moment(new Date(date)).startOf('day').valueOf();
        } else {
          date = moment(new Date(date)).endOf('day').valueOf();
        }
        return date;
      });
      let obj: any = { timestamp: headers[j], data: [] };
      let appended: string[] = [];
      for (let i = 0; i < responseData.length; i++) {
        const element = responseData[i];
        element['billDate'] = element['billDate'] || element['billingMonth'];
        if (
          (element['billDate'] < daterange_milli[1] &&
            element['billDate'] > daterange_milli[0]) ||
          moment(element['billDate']).format('YYYY-MM-DD') ==
            moment(daterange_milli[0]).format('YYYY-MM-DD')
        ) {
          for (let k = 0; k < element['components'].length; k++) {
            const component = element['components'][k];
            if (appended.indexOf(component.name) > -1) {
              if (
                this.range != 'last_7_days' &&
                this.range != 'last_week' &&
                this.range != 'this_week'
              ) {
                let comp = obj['data'].find((l: any) => {
                  return l.name == component.name;
                });
                comp['finalTotalBill'] += component['finalTotalBill'];
                comp['totalBill'] += component['totalBill'];
                if (component.subComponent) {
                  component.subComponent.forEach((sub_service: any) => {
                    let comp_sub_service = comp.subComponent.find(
                      (sub: any) => {
                        return sub.name == sub_service.name;
                      }
                    );
                    if (comp_sub_service) {
                      comp_sub_service['finalTotalBill'] +=
                        sub_service['finalTotalBill'];
                      comp_sub_service['totalBill'] += sub_service['totalBill'];
                    }
                  });
                }
              }
            } else {
              appended.push(component.name);
              obj.data.push(component);
            }
            if (this.appended.indexOf(component.name) < 0) {
              this.appended.push(component.name);
            }
          }
        }
      }
      dt.push(obj);
    }
    return dt;
  }

  parsedDataNested(responseData: any, key: string, checks: any) {
    let headers = this.loadHeaders();
    let dt = [];
    for (let j = 0; j < headers.length; j++) {
      let daterange_milli = headers[j].split(' - ').map((date: any) => {
        let date_: any = new Date(date);
        return Date.parse(date_);
      });
      daterange_milli = daterange_milli.map((date: any, index: number) => {
        if (index == 0) {
          date = moment(new Date(date)).startOf('day').valueOf();
        } else {
          date = moment(new Date(date)).endOf('day').valueOf();
        }
        return date;
      });
      let obj: any;
      obj = this.responseData.find((date: any) => {
        return date.timestamp == headers[j];
      });
      let exists: boolean = false;
      if (!obj) {
        obj = { timestamp: headers[j], data: [] };
      } else {
        exists = true;
      }
      for (let i = 0; i < responseData.length; i++) {
        const element = responseData[i];
        element['billDate'] = element['billDate'] || element['billingMonth'];
        if (
          (element['billDate'] < daterange_milli[1] &&
            element['billDate'] > daterange_milli[0]) ||
          moment(element['billDate']).format('YYYY-MM-DD') ==
            moment(daterange_milli[0]).format('YYYY-MM-DD')
        ) {
          if (checks && checks.length > 0) {
            element['name'] = key;
            checks.forEach((check: any) => {
              if (key.indexOf(check) > -1 && element[check] != undefined) {
                element['name'] = element['name'].replaceAll(
                  check,
                  element[check]
                );
              }
            });
          } else {
            element['name'] = element[key];
          }
          if (!key) {
            element['name'] = 'No Identifier Found';
          }
          if (!element['finalTotalBill']) {
            element['finalTotalBill'] =
              element['finalTotalCost'] || element['totalCost'] || 0;
          }
          if (!element['totalBill']) {
            element['totalBill'] =
              element['totalCost'] || element['finalTotalCost'] || 0;
          }
          if (obj.data.length > 0) {
            let comp = obj.data.find((service: any) => {
              let temp: any = key;
              if (checks && checks.length > 0) {
                checks.forEach((check: any) => {
                  if (key.indexOf(check) > -1 && service[check] != undefined) {
                    temp = temp.replaceAll(check, service[check]);
                  }
                });
              }
              return temp == element['name'];
            });
            if (comp) {
              comp['finalTotalBill'] +=
                element['finalTotalBill'] || element['finalTotalCost'];
              comp['totalBill'] += element['totalBill'] || element['totalCost'];
            } else {
              obj['data'].push(element);
            }
          } else {
            obj['data'].push(element);
          }
          if (this.appended.indexOf(element['name']) < 0) {
            this.appended.push(element['name']);
          }
        }
      }
      if (obj['data'].length > 0 && !exists) {
        dt.push(obj);
      }
    }
    return dt;
  }

  fetchDiffDays(start: any, end: any): number {
    let currentDate: any = end;
    let liveDate: any = start;
    currentDate = new Date(currentDate);
    liveDate = new Date(liveDate);
    let diff =
      (currentDate.getTime() - liveDate.getTime()) / (1000 * 3600 * 24) + 1;
    this.diffDays = diff;
    return diff;
  }

  cancelDownloadAll(event: any) {
    this.downloadAll = null;
  }

  async loadInnerData(ref: any, id: any) {
    await ref.loadRegions();
  }

  apiCaller(value: any) {
    if (this.activeService) {
      this.loadMore(this.activeService);
    } else {
      this.fetchRangeBillData(value);
    }
  }

  async fetchMasterAccounts(caller: boolean = true) {
    if (caller) {
      this.customRange = null;
      this.fetchBreadCrum();
    }

    if (this.masterAccounts.length > 0) {
      this.apiCaller($('#masterAccountSelect').val());
      return;
    }
    this.notifier.loading(true);
    let data = {
      lid: [this.accountId],
      userType: 'C',
      action: 'fetchMasterAccList',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = result.masterAccList;
      setTimeout(async () => {
        $('#masterAccountSelect').selectpicker('refresh');
        $('#masterAccountSelect').val(this.masterAccounts[0]);
        $('#masterAccountSelect').selectpicker('refresh');
        if (caller) {
          await this.apiCaller(this.masterAccounts[0]);
        }
      }, 100);
    } else {
      this.summary = [];
      this.activeAccount = null;
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.notifier.loading(false);
    }
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: String[];
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.map((account: any) => {
          return account.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  goBack() {
    this.activeAccount = null;
  }

  callFunction(name: any, id: any) {
    this.funRef[name](this, id);
  }

  showData(ref: any, value: any) {
    ref.filterData(ref, JSON.stringify(value));
  }

  filterData(ref: any, value: any) {
    let dt = JSON.parse(value);
    ref.servicesData['ec2']['service-cost'] = 0;
    ref.servicesData['ec2']['discountedCost'] = null;
    ref.servicesData['rds']['service-cost'] = 0;
    ref.servicesData['rds']['discountedCost'] = null;
    ref.servicesData['others']['service-cost'] = 0;
    ref.servicesData['others']['services'] = [];
    ref.servicesData['cloudfront']['service-cost'] = 0;
    ref.servicesData['cloudfront']['discountedCost'] = null;
    dt.services.forEach((service: any, i: number) => {
      service['service-cost'] = Number(service['service-cost']);
      if (service['service-name'] === 'Amazon Relational Database Service') {
        if (this.diffDays > 0) {
          service['discountedCost'] = !isNaN(
            ref.response['finalRDSUpdatedCost']
          )
            ? ref.response['finalRDSUpdatedCost']
              ? Number(Number(ref.response['finalRDSUpdatedCost']).toFixed(2))
              : null
            : null;
          if (
            Number(service['discountedCost']).toFixed(2) ==
            service['service-cost'].toFixed(2)
          ) {
            service['discountedCost'] = null;
          }
        }
        ref.servicesData['rds'] = service;
        return;
      }
      if (service['service-name'] === 'Amazon CloudFront') {
        if (this.diffDays > 0) {
          service['discountedCost'] = !isNaN(ref.response['finalCFRCTotalCost'])
            ? ref.response['finalCFRCTotalCost']
              ? Number(Number(ref.response['finalCFRCTotalCost']).toFixed(2))
              : null
            : null;
          if (
            Number(service['discountedCost']).toFixed(2) ==
            service['service-cost'].toFixed(2)
          ) {
            service['discountedCost'] = null;
          }
        }
        ref.servicesData['cloudfront'] = service;
        return;
      }
      if (service['service-name'] === 'Amazon Elastic Compute Cloud') {
        if (this.diffDays > 0) {
          service['discountedCost'] = !isNaN(
            ref.response['finalEc2OdTotalCost']
          )
            ? ref.response['finalEc2OdTotalCost']
              ? Number(Number(ref.response['finalEc2OdTotalCost']).toFixed(2))
              : null
            : null;
          if (
            Number(service['discountedCost']).toFixed(2) ==
            service['service-cost'].toFixed(2)
          ) {
            service['discountedCost'] = null;
          }
        }
        ref.servicesData['ec2'] = service;
        return;
      }
      ref.servicesData['others']['service-cost'] += Number(
        service['service-cost']
      );
      ref.servicesData['others'].services.push(service);
    });
    ref.servicesData['rds']['service-cost'] =
      typeof ref.servicesData['rds']['service-cost'] == 'number'
        ? Number(Number(ref.servicesData['rds']['service-cost']).toFixed(2))
        : Number(ref.servicesData['rds']['service-cost']);
    ref.servicesData['ec2']['service-cost'] =
      typeof ref.servicesData['ec2']['service-cost'] == 'number'
        ? Number(Number(ref.servicesData['ec2']['service-cost']).toFixed(2))
        : Number(ref.servicesData['ec2']['service-cost']);
    ref.servicesData['cloudfront']['service-cost'] =
      typeof ref.servicesData['cloudfront']['service-cost'] == 'number'
        ? Number(
            Number(ref.servicesData['cloudfront']['service-cost']).toFixed(2)
          )
        : Number(ref.servicesData['cloudfront']['service-cost']);
    ref.servicesData['others']['service-cost'] =
      typeof ref.servicesData['others']['service-cost'] == 'number'
        ? Number(Number(ref.servicesData['others']['service-cost']).toFixed(2))
        : Number(ref.servicesData['others']['service-cost']);
    if (
      ref.servicesData['others']['discountedCost'] &&
      Number(ref.servicesData['others']['discountedCost']) > 0
    ) {
      ref.servicesData['others']['discountedCost'] = Number(
        Number(ref.servicesData['others']['service-cost']) -
          Number(ref.servicesData['others']['discountedCost'])
      ).toFixed(2);
    } else {
      delete ref.servicesData['others']['discountedCost'];
    }
    ref.activeAccount = dt;
  }

  getOthers(i: any) {
    if (
      i['service-name'] === 'Amazon Relational Database Service' ||
      i['service-name'] === 'Amazon CloudFront' ||
      i['service-name'] === 'Amazon Elastic Compute Cloud'
    ) {
      return false;
    }
    return true;
  }

  showAnalytics(dt: any) {
    let accountData = { ...dt };
    delete accountData.services;
    this.analytics = {
      currentMonth: this.dateRange,
      account: accountData,
    };
  }

  hideAnalytics(event: any) {
    this.analytics = null;
  }

  hide() {
    this.close.emit(true);
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel('others-table', 'services-bills', 'csv');
  }
}
