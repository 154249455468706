import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from './../../../_services/notifier.service';

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './sg.operations.component.html',
})
export class sgOperationsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  sgTabs: any = [];
  sgList: any = [];

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;

  funRef: any;

  headers: any = [
    {
      id: 'sg_name',
      name: 'Security Group Name',
      click: 'loadAttachedInstances',
      filter: true,
    },
    {
      id: 'sg_id',
      name: 'Security Group ID',
      filter: true,
    },
    {
      id: 'instance_attched',
      name: 'Instances(EC2/RDS)',
    },
  ];

  attachedInstances: any;
  attachedInstancesHeader: any = [
    {
      id: 'instance_name',
      name: 'Instance Name',
    },
    {
      id: 'instance_id',
      name: 'Instance ID',
    },
    {
      id: 'service',
      name: 'Service',
    },
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    //this.load();
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }

      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });

    this.funRef = {
      loadAttachedInstances: this.loadAttachedInstances,
    };
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    let data = {
      action: 'sg_summary',
      region_name: this.regionId,
      account_id: this.accountId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/sg`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    this.sgTabs = [];
    if (result.status == '1' && !result.error_message) {
      result['total_sg']['metadata'] = {
        str: `<strong>Total SG</strong> ${result['total_sg']['count']}`,
        id_str: '#' + result['total_sg']['onclick'],
      };
      result['attached_sg']['metadata'] = {
        str: `<strong>Attached SG</strong> ${
          result['attached_sg']['count']
        } (${(
          (result['attached_sg']['count'] / result['total_sg']['count']) *
          100
        ).toFixed(2)}%)`,
        id_str: '#' + result['attached_sg']['onclick'],
      };
      result['unattached_sg']['metadata'] = {
        str: `<strong>Unattached SG</strong> ${
          result['unattached_sg']['count']
        } (${(
          (result['unattached_sg']['count'] / result['total_sg']['count']) *
          100
        ).toFixed(2)}%)`,
        id_str: '#' + result['unattached_sg']['onclick'],
      };
      this.sgTabs.push(result['total_sg']);
      this.sgTabs.push(result['attached_sg']);
      this.sgTabs.push(result['unattached_sg']);
      if (this.sgTabs.length > 0) {
        this.getSecurityGroups(this.sgTabs[0].onclick);
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async getSecurityGroups(clickID: String) {
    this.notifier.loading(true);
    let data = {
      action: clickID,
      region_name: this.regionId,
      account_id: this.accountId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/sg`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.sgList = result[`${clickID}`];
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async loadAttachedInstances(ref: any, param: any) {
    if (param.showNested) {
      param['showNested'] = false;
      return;
    }
    if (Number(param.instance_attched) == 0) {
      ref.notifier.alert(
        'Info',
        '',
        'No Attached Instances Found',
        'info',
        5000
      );
      return;
    }
    ref.notifier.loading(true);
    let data = {
      action:
        param.onclick == 'attached_instances'
          ? 'attched_instances'
          : param.onclick,
      region_name: ref.regionId,
      account_id: ref.accountId,
      sg_id: param.sg_id,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/compliance/sg`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      //this.attachedInstances = result.instance_details;
      param.attachedInstances = result.instance_details;
      param.showNested = true;
    } else {
      ref.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    ref.notifier.loading(false);
  }

  hideNested(e: any) {
    console.log('hideNested', e);
    //this.showOffendesModal = false;
    //this.offendeList = [];
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
