import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'rds-cost-create-job-element',
  templateUrl: './createjob.component.html',
  styleUrls: ['./createjob.component.css'],
})
export class RDSCostOptimizationCreateJobComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  uid = localStorage.getItem('t');
  t = localStorage.getItem('t');
  ut = localStorage.getItem('ut');
  eId = localStorage.getItem('eId');
  accountId: any;
  regionId: any;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  currentMessage: any;
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
    });
    console.log(this.accountId);
  }

  async yes() {
    await this.createJob();
    return;
  }

  no() {
    this.hideModal.emit({ status: 0 });
  }

  async createJob() {
    this.notifier.loading(true);

    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (selectedAccountData.error) {
        console.log(selectedAccountData.error);
      } else {
        const account = selectedAccountData.data;
        let data = {
          action: 'Create Job',
          accountId: this.accountId,
          region: this.regionId,
          zaid: account.site_247_project_id,
        };
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/rdsoptimisation `;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (
          result.status == '1' ||
          result.s == '1' ||
          result.status_code == 1
        ) {
          this.notifier.alert('success', '', result.message, 'success', 5000);
          this.no();
        } else {
          this.notifier.alert('Info', '', result.error_message, 'info', 5000);
        }
      }
    } catch (err) {}
    this.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }
}
