<div class="card">
  <h5 class="card-header">Inspector</h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->
    <div style="display: flex; justify-content: flex-end">
      <input class="datetimepicker" type="text" [(ngModel)]="dateRange" />
    </div>

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th></th>
          <th>S.N.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
        </tr>
      </thead>
      <tbody [ngStyle]="{'display': inspectorList.length > 0 ? 'table-row-group': 'none'}"
        *ngFor="let i of inspectorList; let j = index">
        <tr>
          <i (click)="expandCollapse(i)" class="fa fa-caret-right"></i>
          <td>{{ j + 1 }}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span>
                {{ i[h.id] }}
              </span>
            </span>
            <span *ngIf="!h.click && !h.nested">
              {{ i[h.id] }}
            </span>
            <span *ngIf="!h.click && h.nested">
              {{
              h.nested.index
              ? i[h.id][h.nested.index - 1][h.nested.inner]
              : i[h.id][h.nested.inner]
              }}
            </span>
          </td>
        </tr>
        <tr *ngIf="i.expanded">
          <td [attr.colspan]="headers.length + 2">
            <div class="card">
              <div class="card-header">
                <h5>Finding for {{ i.id }}</h5>
              </div>
              <div class="card-body">
                <div class="panel panel-default">
                  <div class="panel-heading" style="font-size: medium"></div>

                  <div class="panel-body">
                    <div class="row" style="
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                      ">
                      <div>
                        <b>ARN</b>
                        <p>
                          {{ i.arn }}
                        </p>
                      </div>
                      <div>
                        <b>Start</b>
                        <p>{{ i.createdAt }}</p>
                      </div>
                      <div>
                        <b>End</b>
                        <p>{{ i.updatedAt }}</p>
                      </div>
                      <div>
                        <b>Rules Package</b>
                        <p>{{ i.serviceAttributes.rulesPackageArn }}</p>
                      </div>
                      <div>
                        <b>AWS Agent ID</b>
                        <p>{{ i.assetAttributes.agentId }}</p>
                      </div>
                      <div>
                        <b>Finding</b>
                        <p>{{ i.title }}</p>
                      </div>
                      <div>
                        <b>Severity</b>
                        <p>{{ i.severity }}</p>
                      </div>
                      <div>
                        <b>Description</b>
                        <p>{{ i.description }}</p>
                      </div>
                      <div>
                        <b>Recommendation</b>
                        <p>{{ i.recommendation }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="panel panel-default">
                        <pre class="code language-json&quot;" style="min-height: 500px"
                          [innerHTML]="parseJson(i)"></pre>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': inspectorList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 2">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="nextToken" style="display: flex; justify-content: flex-end; align-items: center; padding: 10px;"
      (click)="this.load()">
      <button class="btn btn-light" style="background-color: #5864ff; color: white;">Next</button>
    </div>
  </div>
</div>