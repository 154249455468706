import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ValidateChangeRequestComponent } from './components/validate-change-request/validate-change-request.component';

const routes: Routes = [
  { path: '404', component: PageNotFoundComponent },
  {
    path: 'bomv2',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/404',
      },
      {
        path: ':account_id',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/404',
          },
          {
            path: ':region_id',
            children: [
              {
                path: '',
                pathMatch: 'full',

                redirectTo: '/404',
              },
              {
                path: ':guid',
                component: ValidateChangeRequestComponent,
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BOMv2RoutingModule {}
