<div *ngIf="showPopup" class="table-wrapper table_pos">
    <div class="sticky-heading table_heading">
        {{popupTitle}}
    </div>

    <div class="table-container table_res">

        <div class="table-responsive">
            <!-- Ensure headers_pop and serviceData[selectedService] are available before rendering the table -->
            <table class="table table-docs table-hover fixed-header tab_table">
                <!-- Table Header -->
                <thead>
                    <tr>
                        <th>S.I</th>
                        <th *ngFor="let h of headers_pop">{{ h.name }}</th>
                    </tr>
                </thead>

                <!-- Table Body - Display Data -->
                <tbody *ngIf="serviceData[selectedService] && serviceData[selectedService].length > 0; else noData">
                    <tr *ngFor="let i of serviceData[selectedService]; let j = index">
                        <td>{{ j + 1 }}</td>
                        <td *ngFor="let h of headers_pop">
                            {{ i[h.id] || '-' }}
                        </td>
                    </tr>
                </tbody>

                <!-- No Data Found -->
                <ng-template #noData>
                    <tbody>
                        <tr>
                            <td [attr.colspan]="headers_pop.length + 1">
                                <div style="text-align: center">No Data Found</div>
                            </td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>

    </div>
    <div class="modal-footer sticky-footer">
        <button *ngIf="lek !== null && lek !== undefined && lek !== ''" class="btn btn-primary-soft"
            (click)="fetchDetails(service_Name)">
            Next
        </button>
        <button type="button" class="btn btn-success-soft" (click)="closePopup()">Close</button>
    </div>
</div>