
<div class="card" style="min-height:80vh">
  <div class="card-body overflow-hidden ">
    <h1 style="margin-bottom:16px">Dashboard</h1>

    <div class="row g-0">

      <div class="g-0">
        <div class="card h-lg-100 overflow-hidden">
          <div class="card-body p-0">
            <div class="table-responsive scrollbar">
              <table class="table table-dashboard mb-0 table-borderless fs--1 border-200">
                <thead class="bg-light">
                  <tr class="text-900">
                    <th>Job Flow</th>
                    <th class="text-end">Status</th>
                    <th class="pe-card text-end">Progress</th> <!--style="width: 8rem"-->
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-bottom border-200" *ngFor="let job of jobList; let i = index;" >
                    <td>
                      <div class="d-flex align-items-center position-relative">
                        <img class="rounded-1 border border-200" src="/assets/img/icons/docs.png" alt="" width="60">
                        <div class="flex-1 ms-3">
                          <!--<h6 class="mb-1 fw-semi-bold"><a class="text-dark stretched-link" href="#!">Raven Pro</a></h6>-->
                          <p class="fw-semi-bold mb-0 text-500">{{job.jid}}</p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-end fw-semi-bold">$1311</td>

                    <td class="align-middle pe-card">
                      <div class=" align-items-middle rigth">
                        <div class="progress me-3 rounded-3 bg-200" style="height: 5px;width:80px; float:right">
                          <div class="progress-bar rounded-pill" role="progressbar" style="width: 39%;" aria-valuenow="39" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <!--<div class="fw-semi-bold ms-2">39%</div>-->
                      </div>
                    </td>
                    <td><a class="btn btn-sm btn-info"  href="dashc/jobflow?jid={{job.jid}}">View</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer bg-light py-2">
            <div class="row flex-between-center">
              <div class="col-auto">
                <!--<select class="form-select form-select-sm">
                <option>Last 7 days</option>
                <option>Last Month</option>
                <option>Last Year</option>
              </select>-->
              </div>
              <div class="col-auto"><a class="btn btn-sm btn-falcon-default" href="dashc/jobflow">Add New</a></div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  </div>
</div>
