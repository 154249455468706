import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as moment from 'moment';

declare let window: any;

@Component({
  templateUrl: './iamusers.component.html',
  styleUrls: ['./iamusers.component.css'],
})
export class BetaIAMUsersComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  users: any = [];
  fetching: boolean = false;

  destroyed: boolean = false;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  funRef: any;

  userHeader: any = [
    { id: 'userName', name: 'User Name', filter: true },
    { id: 'arn', name: 'ARN', filter: true },
    {
      id: 'createDate',
      name: 'Create Date',
      filter: true,
    },
    {
      id: 'passwordLastUsed',
      name: 'Password Last Used',
    },

    {
      id: 'accessKeykeyEnabled',
      name: 'Access Key Enabled',
      filter: true,
    },
  ];

  moreDetailsHeaders: any = [];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.refresh();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.refresh();
      }
    });
    this.funRef = {};
  }

  position: any = { x: '0px', y: '0px' };
  setPosition(event: any) {
    this.position.x = window.innerWidth - event.clientX - 60 + 'px';
    this.position.y = event.clientY + 10 + 'px';
  }

  refresh_: boolean = false;
  refresh() {
    this.refresh_ = true;
    this.loadiamusers();
  }

  nextToken: any = null;
  async loadiamusers(nextToken: string = '') {
    this.nextToken = nextToken == '' ? null : nextToken;
    this.fetching = true;
    if (this.destroyed) {
      return true;
    }
    if (nextToken == '') {
      this.refresh_ = true;
      this.users = [];
    }
    let data = {
      aId: this.accountId,
      eId: this.userId,
      nT: nextToken == '' ? undefined : nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/listiamusers`;

    if (nextToken != '' && this.refresh_) {
      nextToken = '';
      this.users = [];
      return true;
    }

    this.refresh_ = false;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '200' || result.status_code == 200 || result.IAM) {
      result.IAM = result.IAM.map((iam: any) => {
        iam['createDate'] = moment(
          iam['createDate'].trim(),
          'DD-MM-YYYY hh:mm:ss'
        ).format('DD/MM/YYYY hh:mm A');
        if (iam['passwordLastUsed'] && iam['passwordLastUsed'] != '-') {
          iam['passwordLastUsed'] = moment(
            iam['passwordLastUsed'].trim(),
            'DD-MM-YYYY hh:mm:ss'
          ).format('DD/MM/YYYY hh:mm A');
        }
        iam['accessKeykeyEnabled'] =
          iam['accessKeykeyEnabled'] == 'true' ||
          iam['accessKeykeyEnabled'] == true
            ? `<span class='red_color'>Yes</span>`
            : `<span class='green_color'>No</span>`;
        return iam;
      });
      this.users = [...this.users, ...result.IAM];
    } else {
      if (result.message != 'NO RECORD FOUND') {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    }

    if (result.nextToken) {
      await this.loadiamusers(result.nextToken);
    } else {
      this.nextToken = null;
    }
    if (!this.destroyed) {
      this.fetching = false;
    }

    return true;
  }

  downloadReport() {
    let element = document.createElement('table');
    element.innerHTML += `
        <thead>
          <tr>
            <th>Sl. No.</th>
            ${[...this.userHeader, ...this.moreDetailsHeaders].map((h: any) => {
              return `<th>${h.name}</th>`;
            })}
          </tr>
        </thead>
        <tbody>
            ${this.users.map((ec2: any, index: any) => {
              return `
                <tr>
                  <td>${index + 1}</td>
                  ${[...this.userHeader, ...this.moreDetailsHeaders].map(
                    (h: any) => {
                      return `<td>
                      ${ec2[h.id]}
                    </td>`;
                    }
                  )}
                </tr>
              `;
            })}
        </tbody>
      `;
    window.exportToExcelElement(
      element,
      `IAM Users - ${this.accountId}`,
      'csv'
    );
  }

  callFunction(name: any, param: any) {}

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
