import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

declare let $: any;

@Component({
  templateUrl: './ec2threshhold.component.html',
  styleUrls: ['./ec2threshhold.component.css'],
})
export class EC2ThreshholdComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  masterAccounts: any = [];

  accountId: any;
  regionId: any;
  currentMessage: any;

  headers: any = [
    {
      id: 'os',
      name: 'OS',
      click: 'loadUpdateForm',
    },
    {
      id: 'instanceType',
      name: 'Instance Type',
    },
    {
      id: 'threshHold',
      name: 'ThreshHold (%)',
    },
    {
      id: 'hours',
      name: 'Alarm Hours',
    },
  ];

  funRef: any = {};

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.funRef = {
      loadUpdateForm: this.loadUpdateForm,
    };
    $('#masterAccountSelect').selectpicker();

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    this.fetchingMasterAccounts = true;
    await this.fetchMasterAccounts();
    this.fetchingMasterAccounts = false;
  }

  async refresh() {
    this.fetchingLInkedAccountIDs = true;
    this.linkedAccountIDs = [];
    await this.fetchLinkedAccountIDs(this.selectedMasterAccount.accountId);
    this.fetchingLInkedAccountIDs = false;
  }

  fetchingLInkedAccountIDs: boolean = false;
  linkedAccountIDs: any = [];
  fetchingMasterAccounts: boolean = false;
  destroyed: boolean = false;
  selectedMasterAccount: any;
  async fetchMasterAccounts(nextToken: any = undefined) {
    if (!nextToken) {
      this.masterAccounts = [];
    }

    let data = {
      lid: [this.accountId],
      userType: 'C',
      action: 'fetchMasterAccList',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = result.masterAccList.map((account: any) => {
        return { accountId: account };
      });
      this.selectedMasterAccount = this.masterAccounts[0];
      this.triggerFetchLinkedAccounts(this.selectedMasterAccount);
      setTimeout(() => {
        $('#masterAccountSelect').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
  }

  async triggerFetchLinkedAccounts(masterAccID?: any) {
    if (!masterAccID) {
      masterAccID = this.selectedMasterAccount;
    } else {
      this.selectedMasterAccount = masterAccID;
    }
    this.fetchingLInkedAccountIDs = true;
    await this.fetchLinkedAccountIDs(masterAccID.accountId);
    this.fetchingLInkedAccountIDs = false;
  }

  async fetchLinkedAccountIDs(masterAccId: any) {
    this.linkedAccountIDs = [];
    let data = {
      mid: masterAccId,
      lid: this.accountId,
      a: 'fetch',
      region: this.regionId,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2usagealarm`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (
      (result.status == '1' || result.s == '1') &&
      result.data &&
      result.data.length > 0
    ) {
      this.linkedAccountIDs = result['data'].map((lid: any) => {
        lid['eid'] = lid['eid'].split(',');
        return lid;
      });
    } else {
      this.linkedAccountIDs = [];
    }
  }

  updateAlarm: any;
  updateBudgetIndex: number;
  deleteAlarm: any;
  deleteAlarmIndex: number;
  loadUpdateForm(ref: any, value: any, index: number) {
    ref.updateAlarm = value;
    ref.updateAlarm['mid'] = ref.selectedMasterAccount;
    ref.updateAlarm['lid'] = ref.accountId;
    ref.updateAlarm['action'] = 'update';
    ref.updateAlarmIndex = index;
  }

  updateUI(event: any) {
    if (event) {
      delete event['action'];
      this.refresh();
    }
    this.updateBudgetIndex = null;
    this.updateAlarm = null;
  }

  deleteAlarmTrigger(value: any, index: number) {
    this.deleteAlarm = value;
    this.deleteAlarmIndex = index;
  }

  updateDeleteAlarmUI(event: any) {
    if (event) {
      this.refresh();
    }
    this.deleteAlarm = null;
  }

  addAlarm() {
    this.updateAlarm = {
      eid: [''],
      hours: '720',
      threshHold: '80',
      os: '',
      instanceType: '',
      region: this.regionId,
      action: 'save',
      mid: this.selectedMasterAccount,
      lid: this.accountId,
    };
  }

  callFunction(param: any, id: any, index: number) {
    this.funRef[param](this, id, index);
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
