<div class="card" style="border:none;">
  <div class="card-header" style="display: flex; justify-content: space-between; align-items: center;">
    <h5>EC2 Instances</h5>
    <button class="btn btn-light" style="background-color: #5864FF; color: white;"
      (click)="downloadConsolidatedReport()">Consolidated Infra
      Report</button>
  </div>
  <div class="card-body" style="border:none">

    <div style="display: flex; justify-content: flex-end;">
      <div class="dropdown" style="opacity: 0;"
        [ngStyle]="{'opacity': getStartTriggerInstances().length > 0 ? '1': '0'}" *ngIf="writeAccess">
        <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
          id="instanceAction">
          Action
        </button>
        <div class="dropdown-menu" aria-labelledby="instanceAction">
          <a class="dropdown-item" href="#" (click)="triggerInstances($event, 'start_ec2')"
            title="Start Instances">Start</a>
          <a class="dropdown-item" href="#" (click)="triggerInstances($event, 'stop_ec2')"
            title="Stop Instances">Stop</a>
          <a class="dropdown-item" [ngClass]="{disabled_link: getSelectedRunningInstances() == 0}" href="#"
            (click)="getSelectedRunningInstances() != 0 && triggerInstances($event, 'reboot_ec2')"
            [title]="getSelectedRunningInstances() > 0 ? 'Reboot Instance' : 'No Running Instances selected'">Reboot</a>
        </div>
      </div>
    </div>

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th></th>
          <th>S.N.</th>
          <th *ngFor="let h of headers">
            {{h.name}}



            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,instancesList)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
          <th *ngIf="writeAccess">Start / Stop</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': instancesList.length > 0 ? 'table-row-group' : 'none'}"
        *ngFor="let i of instancesList; let j = index">
        <tr *ngIf="notifier.filterTableColums(headers,i)">
          <td><input type="checkbox" name="" id="" [(ngModel)]="i.actionStart"></td>
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <div *ngIf="i[h.id] == 'LOADING'" style="text-align: center;">
              <span class="center">
                <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 16px; margin: 10px" />
              </span>
              <span *ngIf="h.id == 'State' && i.rawState" style="text-transform: capitalize;">
                {{i['rawState']}}
              </span>
            </div>
            <div *ngIf="i[h.id] != 'LOADING'">
              <span *ngIf="h.click" class="btn light" (click)="callFunction(h.click, i)">
                <span>
                  {{i[h.id]}}
                </span>
              </span>
              <div *ngIf="!h.click">
                <span *ngIf="h.color">
                  <span [ngStyle]="{'color': h.color(i[h.id])}">{{i[h.id]}}</span>
                </span>
                <span *ngIf="!h.color">
                  <div *ngIf="h.statusCheck">
                    <div class='status_div'
                      [ngClass]="{'danger': i[h.id] == 'stopped', 'success': i[h.id] == 'running'}">
                      <div class='status_indicator'></div>
                      <div class='status_text'>{{i[h.id]}}</div>
                    </div>
                  </div>
                  <span *ngIf="!h.statusCheck">
                    {{i[h.id]}}
                  </span>
                </span>
              </div>
            </div>
          </td>
          <td *ngIf="i.State == 'running' && writeAccess && i.State != 'LOADING'">
            <span class=" btn" (click)="singleStartStop($event, i, 'stop_ec2')">Stop</span>
          </td>
          <td *ngIf="i.State != 'running' && writeAccess && i.State != 'LOADING'">
            <span class="btn" (click)="singleStartStop($event, i, 'start_ec2')">Start</span>
          </td>
          <td *ngIf="i.State != 'running' && writeAccess && i.State == 'LOADING'">
            <span class="center">
              <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 16px; margin: 10px" />
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': instancesList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 4">
            <div style="text-align: center;">No Instances Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<style>
  .disabled_link {
    opacity: 0.4 !important;
    cursor: not-allowed !important;
  }
</style>

<tags-modal *ngIf="showTagsModal" modalTitle="Tags" instanceID={{showTagsModal.ResourceId}} accountID={{accountId}}
  regionID={{regionId}} serviceName="ec2" (hideModal)="hideMdoal($event)"></tags-modal>
<ec2-details-view *ngIf="instance" [instance]="instance" (hideModal)="hideDetails($event)"
  (instanceStartStop)="instanceStartStop($event)"></ec2-details-view>
<instacne-start-stop-v2 *ngIf="command && writeAccess" [modalTitle]="modalTitle" [action]="action" [command]="command"
  [instances]="getSelectedInstances()" (hideModal)="hideStartStop($event)"></instacne-start-stop-v2>