<div class="card">
  <h5 class="card-header">EC2 Cost Controls</h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">
            {{h.name}}
            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,ec2CostList)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
          <th>Memory Utilization</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': ec2CostList.length > 0 ? 'table-row-group': 'none'}"
        *ngFor="let i of ec2CostList; let j = index">
        <tr *ngIf="notifier.filterTableColums(headers,i)">
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              <span *ngIf="h.alter">
                {{h.alter(i[h.id])}}
              </span>
              <span *ngIf="!h.alter">
                {{i[h.id]}}
              </span>
            </span>
          </td>
          <td><span (click)="fetchMemoryDetails(i)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="orange" class="bi bi-cpu-fill"
                viewBox="0 0 16 16">
                <path
                  d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0zm-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3h-7zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z">
                </path>
              </svg>
            </span></td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': ec2CostList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 2">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="nextToken" style="display: flex; justify-content: flex-end;">
      <button class="btn btn-light" style="background-color: #5864ff; color: white;" (click)="load()">Next</button>
    </div>
  </div>
</div>

<modal-popup *ngIf="utilization" [headers]="modalHeader" [data]="utilization" [modalTitle]="'Utilization'"
  [showModal]="utilization" (hideModal)="hidePop($event)"></modal-popup>