let searchOptions = [
  {
    url: '/dash/ticket/create',
    title: 'Create Ticket',
    slugs: [
      'create ticket',
      'cloud support',
      'createticket',
      'cloudsupport',
      'awssupport',
      'minfysupport',
    ],
    description: 'Create Minfy and AWS Tickets',
    icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Create+Ticket.svg',
  },
  {
    url: '/dash/ticket/list',
    title: 'View Tickets',
    slugs: [
      'view ticket',
      'cloud support',
      'viewticket',
      'cloudsupport',
      'awssupport',
      'minfysupport',
    ],
    description: 'Manage Minfy and AWS Tickets',
    icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/View+Ticket.svg',
  },
];

export default searchOptions;
