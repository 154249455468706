<div class="alarm_screen" style="box-shadow: 1px 3px 1px #80808024">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform: capitalize">
        {{ action == "update" ? "update" : "add" }} MRR
      </h4>
    </div>
    <div class="alarm_body" style="padding: 1px 25px 5px 25px;max-height: 600px; overflow-y: auto;">
      <div *ngIf="action !== 'update'">
        <div class="row">
          <div class="flex-between p-0">
            <label>Linked Account List</label>
            <div class="flex-start filters">
              <div class="flex-start">
                <input type="checkbox" id="select_all_accounts" (change)="selectAllchilds($event)" />
                <label for="select_all_accounts">Select All</label>
              </div>
            </div>
          </div>

          <select class="form-control" id="linkedAccounts" [(ngModel)]="childs" (change)="onAccountChange($event)"
            data-live-search="true" multiple required>
            <option *ngFor="let master of linkedAccounts['list']" [value]="master.accountId">
              {{ master.accountId }} ({{master.accountName}})
            </option>
          </select>

        </div>
        <div class="row">
          <div class="flex-between p-0">
            <label>Service Name</label>
            <div class="p-0 flex-start">
              <input type="checkbox" name="" (click)="selectAllOpt()" id="service_name" />
              <label for="service_name">Select All</label>
            </div>
          </div>
          <div class="p-0">
            <select id="serviceNameId" class="form-control selectpicker" data-live-search="true"
              [disabled]="action == 'update'" multiple (change)="onServiceNameChange($event)">
              <option value="" disabled>--Select the service name--</option>
              <option *ngFor="let name of serviceNamelist" [value]="name"
                [attr.disabled]="isServiceDisabled(name) ? true : null">
                {{ name }}
              </option>
            </select>
          </div>

        </div>
        <div class="row">

          <label>MRR Discount</label>
          <input type="number" class="form-control" [(ngModel)]="mrr.mrrDiscount"
            style="padding: 5px;margin-left: 0px" />
        </div>
      </div>

      <div class="row" *ngIf="action == 'update'">
        <div class="p-0">
          <label>Service Name</label>
        </div>
        <div class="p-0">
          <select id="serviceNameId" class="form-control" *ngIf="action == 'update'" [disabled]="action == 'update'">
            <option value="{{ mrr.serviceName }}"> {{ mrr.serviceName }}</option>
          </select>
          <!-- </div> -->
        </div>
        <div *ngIf="mrr.serviceName == 'Amazon Elastic Compute Cloud' && selectedClientName === 'NHA'">
          <div class="font-weight-bold mt-3 d-flex justify-content-between">
            <div>
              EC2 Discount
            </div>
            <!-- <div>
              <span class="view-details" style="cursor: pointer; color: blue;"
                (click)="openPopup('Amazon Elastic Compute Cloud')">View Details</span>
            </div> -->
          </div>
          <div class="border p-3 mb-3">

            <div class="form-group row">
              <label>Others </label>
              <input type="number" class="form-control" [(ngModel)]="mrrDatasub.others" />
            </div>
            <div class="form-group row">
              <label>Instance</label>
              <input type="number" class="form-control" [(ngModel)]="mrrDatasub.instance" />
            </div>
            <div class="form-group row">
              <label>EBS</label>
              <input type="number" class="form-control" [(ngModel)]="mrrDatasub.ebs" />
            </div>
          </div>



        </div>

        <div *ngIf="selectedClientName !== 'NHA'">
          <non_nha [mrrDatasub]="mrrDatasub" [mrr]="mrr" [selectedClientName]="selectedClientName" [action]="action"
            [mid]="mid" [childs]='childs' (mrrDatasubChange)="onMrrDatasubUpdate($event)">></non_nha>
        </div>

        <div style="padding-left: 0px;"
          *ngIf="!['Amazon Elastic Compute Cloud', 'Amazon Relational Database Service'].includes(mrr.serviceName)">
          <!-- Content for non-matching service names -->
          <label>MRR Discount</label>
          <div *ngIf="">
            <span class="view-details" style="cursor: pointer; color: blue;"
              (click)="openPopup('Amazon Simple Storage Service')">View Details</span>
          </div>
          <input style="margin-left: 0px;" type="number" class="form-control" [(ngModel)]="mrr.mrrDiscount" />
        </div>
      </div>

      <div *ngIf="selectedClientName !== 'NHA'">
        <non_nha [subEc2]="subEc2" [subRds]="subRds" [s3Mrr]="s3Mrr" [cfrcMrr]="cfrcMrr" [mid]="mid"
          [isAllSelect]="isAllSelect" [showEC2DiscountFields]="showEC2DiscountFields"
          [showCloudFrontDiscountFields]="showCloudFrontDiscountFields" [showS3DiscountFields]="showS3DiscountFields"
          [showRDSDiscountFields]="showRDSDiscountFields" (dataChange)="onDataChange($event)" [childs]="childs">
        </non_nha>
      </div>


      <div class="row" *ngIf="action !== 'update'">
        <label for="comment">Comment</label>
        <textarea id="comment" [(ngModel)]="mrr.comment" class="form-control"></textarea>
      </div>

      <div class="row">
        <label>MRR Period: </label>
        <input type="text" id="dataRangePicker" [(ngModel)]="dateRange" class="form-control" style="width: 300px"
          placeholder="Select date" [disabled]="action == 'update'" />
      </div>

      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="updateEntry()" style="
              background-color: #5864ff;
              color: white;
              text-transform: capitalize;
              margin: right 20px;
            ">
            {{ action == "add" ? "add" : action }}
          </button>
          <button class="btn btn-light" (click)="close(false)">Cancel</button>
        </div>
      </div>
    </div>
  </div>


  <style>
    .margin_left_0 {
      margin-left: 0px;
    }

    legend {
      float: none;
      font-size: 1rem;
    }

    .legend-section {
      margin: 20px;
    }

    fieldset {
      border: 1px solid #ccc;
      margin-bottom: 20px;
      padding: 15px;
    }

    label {
      margin-right: 10px;
    }

    input[type="number"] {
      width: 150px;
      margin-left: 10px;
    }

    .alarm_screen label {
      padding: 0 !important;
    }

    .table-wrapper {
      display: flex;
      justify-content: center;
      /* Center horizontally */
      align-items: center;
      /* Center vertically */
      height: 100vh;
      /* Full viewport height */
      position: relative;
    }

    .table-container {
      max-height: 80%;
      /* Set max height for vertical scrolling */
      overflow-y: auto;
      /* Enable vertical scrolling */
      width: 80%;
      /* Adjust as needed for width */
      border: 1px solid #ddd;
      /* Optional border */
      background-color: #fff;
      /* Background for clarity */
      padding: 30px;
      position: relative;
      padding-bottom: 60px;
      display: flex;
      flex-direction: column;
    }



    .sticky-footer {
      position: sticky;
      bottom: 0;
      width: 100%;
      /* Ensure it takes the full width of the table container */
      background-color: #fff;
      /* Adjust background color if needed */
      padding: 10px;
      /* Add padding to match your design */
      /* box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); Optional: shadow for better visibility */
      z-index: 10;
      /* Ensure it stays on top */
      text-align: right;
      /* Align buttons to the right */
    }

    .table-responsive {
      max-height: 100%;
      /* Make it take full container height */
    }
  </style>
</div>