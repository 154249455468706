<div class="leftmenu">
  <div class="offcanvas-body px-0">
    <button
      class="menuCloseButton"
      (click)="hideLeftMenu()"
      id="menuClosebtn"
      style="
        background: none;
        border: none;
        margin-left: 15px;
        margin-bottom: 5px;
      "
    >
      <i class="fa fa-arrow-circle-right fa-rotate-180"></i>
    </button>

    <button
      class=""
      (click)="showLeftMenu()"
      id="menuShowbtn"
      style="
        display: none;
        background: none;
        border: none;
        margin-left: 15px;
        margin-bottom: 5px;
      "
    >
      <i class="fa fa-arrow-circle-right"></i>
    </button>

    <ul
      class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start"
      id="menu"
    >
      <li class="dropdown" *ngIf="checkIfAvaForUser('cbs')">
        <a
          href="#"
          class="nav-link dropdown-toggle text-truncate"
          id="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Anomalies.svg"
            style="width: 40px"
            alt=""
          />
          CBS
        </a>
        <ul
          class="dropdown-menu text-small shadow sub-menu"
          aria-labelledby="dropdown"
        >
          <li>
            <h3 class="sub-menu-title">CBS</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:ricoverage')">
            <a class="dropdown-item" routerLink="/cbs/ricoverage">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>RI Coverage Report</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:ripurchased')">
            <a class="dropdown-item" routerLink="/cbs/ripurchased">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>RI Purchased Report</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:minfyri')">
            <a class="dropdown-item" routerLink="/cbs/minfyri">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Minfy RI Account Details</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:minfyriwr')">
            <a class="dropdown-item" routerLink="/cbs/minfyriwr">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Enable WR</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:minfyridetails')">
            <a class="dropdown-item" routerLink="/cbs/minfyridetails">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Minfy RI Details</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:organizations')">
            <a class="dropdown-item" routerLink="/cbs/organizations">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Organizations</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:mastersummary')">
            <a class="dropdown-item" routerLink="/cbs/mastersummary">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Master Summary</span>
            </a>
          </li>
          <!-- <li *ngIf="checkIfAvaForUser('cbs:riorder')">
            <a class="dropdown-item" routerLink="/cbs/riorder">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>RI Order Report</span>
            </a>
          </li> -->
          <!-- <li *ngIf="checkIfAvaForUser('cbs:rds/riorder')">
            <a class="dropdown-item" routerLink="/cbs/rds/riorder">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>RDS RI Order Report</span>
            </a>
          </li> -->
          <li *ngIf="checkIfAvaForUser('cbs:cfrc-report')">
            <a class="dropdown-item" routerLink="/cbs/cfrc-report">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>CFRC Report</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:wrpnl')">
            <a class="dropdown-item" routerLink="/cbs/wrpnl">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>WR Pnl</span>
            </a>
          </li>
          <!-- <li *ngIf="checkIfAvaForUser('cbs:cfrc/enableorder')">
            <a class="dropdown-item" routerLink="/cbs/cfrc/enableorder">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>New CFRC Order Report</span>
            </a>
          </li> -->
          <li
            class="searchpointerr"
            *ngIf="checkIfAvaForUser('cbs:pnl-summary')"
            (click)="expandDropdown($event, 'pnl-report')"
          >
            <a class="dropdown-item">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>P&L</span>
              <i class="fa fa-caret-down"></i>
            </a>
          </li>

          <div class="ng-container-main" *ngIf="expanded['pnl-report']">
            <li>
              <a class="dropdown-item" routerLink="/cbs/pnl-summary">
                <span>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px"
                    alt=""
                  />
                  PnL Summary</span
                >
              </a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/cbs/pnl-dashboard">
                <span>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px"
                    alt=""
                  />
                  Dashboard</span
                >
              </a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/cbs/pnl-report">
                <span>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px"
                    alt=""
                  />
                  Report</span
                >
              </a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/cbs/pnl-convert">
                <span>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px"
                    alt=""
                  />
                  Conversion Rate</span
                >
              </a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/cbs/margin-dash">
                <span>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px"
                    alt=""
                  />
                  Margin Dashboard</span
                >
              </a>
            </li>
            
            <li>
              <a
                class="dropdown-item"
                routerLink="/cbs/margin-perc-consolidation"
              >
                <span>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px"
                    alt=""
                  />
                  Margin Consolidation</span
                >
              </a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/cbs/unbilled-pnl">
                <span>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px"
                    alt=""
                  />
                  Unbilled PnL</span
                >
              </a>
            </li>
          </div>
          <li
          class="searchpointerr"
          *ngIf="checkIfAvaForUser('cbs:order-summary')"
          (click)="expandDropdown($event, 'order-report')"
        >
          <a class="dropdown-item">
            <img
              src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
              style="width: 40px"
              alt=""
            /><span>Order reports</span>
            <i class="fa fa-caret-down"></i>
          </a>
        </li>
        <div class="ng-container-main" *ngIf="expanded['order-report']">
          <li *ngIf="checkIfAvaForUser('cbs:cfrc/view')">
            <a class="dropdown-item" routerLink="/cbs/cfrc/view">
              <span>
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              />CFRC Order Report</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:riorder')">
            <a class="dropdown-item" routerLink="/cbs/riorder">
              <span>
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              />RI Order Report</span>
            </a>
          </li>
          
          <li *ngIf="checkIfAvaForUser('cbs:rds/riorder')">
            <a class="dropdown-item" routerLink="/cbs/rds/riorder">
              <span>
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              />RDS RI Order Report</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:cfrc/enableorder')">
            <a class="dropdown-item" routerLink="/cbs/cfrc/enableorder">
              <span>
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              />New CFRC Order Report</span>
            </a>
          </li>

        </div>
          <li
            class="searchpointerr"
            *ngIf="checkIfAvaForUser('cbs:ri-inventory')"
            (click)="expandDropdown($event, 'inventory')"
          >
            <a class="dropdown-item">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Inventory</span>
              <i class="fa fa-caret-down"></i>
            </a>
          </li>

          <div class="ng-container-main" *ngIf="expanded['inventory']">
            <li>
              <a class="dropdown-item" routerLink="/cbs/ri-inventory">
                <span>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px"
                    alt=""
                  />
                  RI</span
                >
              </a>
            </li>
            <li>
              <a class="dropdown-item" routerLink="/cbs/sp-inventory">
                <span>
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                    style="width: 40px"
                    alt=""
                  />
                  SP</span
                >
              </a>
            </li>
          </div>
          <li *ngIf="checkIfAvaForUser('cbs:enablesupportplan')">
            <a class="dropdown-item" routerLink="/cbs/enablesupportplan">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Enable Support Plan</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:onboardedmaster')">
            <a class="dropdown-item" routerLink="/cbs/onboardedmaster">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Onboarded Master</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:cfrc/enable')">
            <a class="dropdown-item" routerLink="/cbs/cfrc/enable">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Enable CFRC Report</span>
            </a>
          </li>
          <!-- <li *ngIf="checkIfAvaForUser('cbs:cfrc/view')">
            <a class="dropdown-item" routerLink="/cbs/cfrc/view">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>CFRC Order Report</span>
            </a>
          </li> -->

          <li *ngIf="checkIfAvaForUser('cbs:budgets')">
            <a class="dropdown-item" routerLink="/cbs/budgets">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Budgets</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:costvariance')">
            <a class="dropdown-item" routerLink="/cbs/costvariance">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Cost Variance</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:top10bills')">
            <a class="dropdown-item" routerLink="/cbs/top10bills">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Top 10 Bills</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:budgets-all')">
            <a class="dropdown-item" routerLink="/cbs/budgets-all">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Budgets All</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:erp')">
            <a class="dropdown-item" routerLink="/cbs/erp">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>ERP Integrations</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:po-alert')">
            <a class="dropdown-item" routerLink="/cbs/po-alert">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>PO Alert</span>
            </a>
          </li>

          <li *ngIf="checkIfAvaForUser('cbs:masterenable')">
            <a class="dropdown-item" routerLink="/cbs/masterenable">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Enable Master</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:discount')">
            <a class="dropdown-item" routerLink="/cbs/discount">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>View Discount</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:sales-report')">
            <a class="dropdown-item" routerLink="/cbs/sales-report">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Sales Report</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:rdsri')">
            <a class="dropdown-item" routerLink="/cbs/rdsri">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>RDS RI Master</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs:mrr-discount')">
            <a class="dropdown-item" routerLink="/cbs/mrr-discount">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>MRR Discount</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('cbs/s3')">
            <a class="dropdown-item" routerLink="/cbs/s3">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>S3 Enable</span>
            </a>
          </li>
          <!-- <li *ngIf="checkIfAvaForUser('cbs:single-master-job')">
            <a class="dropdown-item" routerLink="/cbs/single-master-job">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px"
                alt=""
              /><span>Single Master Job</span>
            </a>
          </li> -->
        </ul>
      </li>
    </ul>
  </div>
</div>

<div class="" style="position: fixed; top: 0; width: 100%; z-index: 99">
  <div class="row">
    <div class="col" style="padding-right: 0">
      <nav class="navbar navbar-expand-lg top-menu">
        <div class="container-fluid">
          <div class="nav-item" style="width: 175px">
            <a
              routerLink="/dash/"
              class="nav-link text-truncate"
              style="padding: 0"
            >
              <img
                style="width: 115px"
                src="../assets/img/swayam_logo_v3.0.svg"
              />
            </a>
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
              <li
                class="nav-item dropdown searchbox"
                id="accountGroups"
                *ngIf="
                  accountGroups &&
                  accountGroups.list &&
                  accountGroups.list.length > 0
                "
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ accountGroups.selected }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div class="flex-grow-1">
                      <div class="input-group">
                        <i
                          _ngcontent-bob-c49=""
                          aria-hidden="true"
                          class="icon bi-search position-right"
                        ></i>
                        <input
                          type="text"
                          class="form-control radius-top"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="btnGroupAddon"
                          [(ngModel)]="accountGroupSearchTerm"
                        />
                      </div>
                    </div>
                    <content
                      *ngFor="let i of accountGroups.list"
                      [ngClass]="{
                        'active-account': accountGroups.selected == i.groupName
                      }"
                    >
                      <div
                        *ngIf="
                          searchOnList(i.groupName, accountGroupSearchTerm)
                        "
                        (click)="selectAccountGroup(i)"
                      >
                        <span class="dropdown-item">{{ i.groupName }}</span>
                      </div>
                    </content>
                  </li>
                </ul>
              </li>

              <li
                class="nav-item dropdown searchbox"
                id="clientNames"
                *ngIf="
                  clientNames && clientNames.list && clientNames.list.length > 0
                "
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ clientNames.selected }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div class="flex-grow-1">
                      <div class="input-group">
                        <i
                          _ngcontent-bob-c49=""
                          aria-hidden="true"
                          class="icon bi-search position-right"
                        ></i>
                        <input
                          type="text"
                          class="form-control radius-top"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="btnGroupAddon"
                          [(ngModel)]="clientNameSearchTerm"
                        />
                      </div>
                    </div>
                    <content
                      *ngFor="let i of clientNames.list"
                      [ngClass]="{
                        'active-account': clientNames.selected == i.cmpName
                      }"
                    >
                      <div
                        *ngIf="searchOnList(i.cmpName, clientNameSearchTerm)"
                        (click)="selectclientName(i)"
                      >
                        <span class="dropdown-item">{{ i.cmpName }}</span>
                      </div>
                    </content>
                  </li>
                </ul>
              </li>

              <li
                class="nav-item dropdown searchbox"
                id="accountIds"
                *ngIf="
                  !accountIds || !accountIds.list || accountIds.list.length > 0
                "
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ accountIds.selected }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <div class="flex-grow-1">
                      <div class="input-group">
                        <i
                          _ngcontent-bob-c49=""
                          aria-hidden="true"
                          class="icon bi-search position-right"
                        ></i>
                        <input
                          type="text"
                          class="form-control radius-top"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="btnGroupAddon"
                          [(ngModel)]="accountIdSearchTerm"
                        />
                      </div>
                    </div>
                    <content
                      *ngFor="let i of accountIds.list"
                      [ngClass]="{
                        'active-account': getSelectedAccount() == i.accountId
                      }"
                    >
                      <div
                        *ngIf="
                          searchOnList(i.accountName, accountIdSearchTerm) &&
                          i['cft_status']
                        "
                        (click)="selectAccountId(i)"
                      >
                        <span class="dropdown-item flex-between">
                          <span> {{ i.accountName }} ({{ i.accountId }}) </span>
                          <span
                            class="copy-clipboard"
                            (click)="copyToClipboard($event, i.accountId)"
                          >
                            <i class="fa fa-clone"></i>
                          </span>
                        </span>
                      </div>
                      <div
                        *ngIf="
                          searchOnList(i.accountName, accountIdSearchTerm) &&
                          i['cft_status'] == 'loading'
                        "
                        class="display-content"
                        (click)="selectAccountId(i)"
                      >
                        <span class="dropdown-item flex-between">
                          <span> {{ i.accountName }} ({{ i.accountId }}) </span>
                          <span
                            class="copy-clipboard"
                            (click)="copyToClipboard($event, i.accountId)"
                          >
                            <i class="fa fa-clone"></i>
                          </span>
                        </span>
                        <img
                          src="../assets/img/loading_2.svg"
                          alt="loading scans"
                          class="wid-mar"
                        />
                      </div>
                      <div
                        *ngIf="
                          searchOnList(i.accountName, accountIdSearchTerm) &&
                          !i['cft_status']
                        "
                        (click)="selectAccountId(i)"
                        class="display-content"
                      >
                        <span class="dropdown-item flex-between">
                          <span> {{ i.accountName }} ({{ i.accountId }}) </span>
                          <span
                            class="copy-clipboard"
                            (click)="copyToClipboard($event, i.accountId)"
                          >
                            <i class="fa fa-clone"></i>
                          </span>
                        </span>
                        <span class="padding-10">
                          <i
                            class="fa fa-exclamation-triangle color-red"
                            title="Cloud Formation Template is not Configured"
                          ></i>
                        </span>
                      </div>
                    </content>
                  </li>
                </ul>
              </li>

              <li
                class="nav-item dropdown searchbox"
                id="regions"
                *ngIf="started && accountIds.list.length > 0"
              >
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ getMiddleString(selectedRegion) }}
                </a>
                <div
                  class="dropdown-menu relative"
                  aria-labelledby="navbarDropdown"
                >
                  <ul>
                    <li class="center" *ngIf="customerType == 'mp'">
                      Selected
                    </li>
                    <li
                      class="disabled border-top"
                      *ngIf="customerType == 'mp'"
                    ></li>
                    <ng-container *ngFor="let continent of continents">
                      <ng-container *ngIf="getRegions(continent).length > 0">
                        <ng-container *ngFor="let i of getRegions(continent)">
                          <li
                            [ngClass]="{
                              'active-account': getSelectedRegion() == i.id
                            }"
                          >
                            <span
                              class="dropdown-item"
                              (click)="setRegion(i, true)"
                              >{{ i.name }}</span
                            >
                          </li>
                        </ng-container>
                        <li
                          class="disabled border-top"
                          *ngIf="getRegions(continent).length > 0"
                        ></li>
                      </ng-container>
                    </ng-container>
                    <li class="center" *ngIf="customerType == 'mp'">
                      AWS Default Regions
                    </li>
                    <li
                      class="disabled border-top"
                      *ngIf="customerType == 'mp'"
                    ></li>
                    <ng-container *ngIf="customerType == 'mp'">
                      <ng-container *ngFor="let continent of continents">
                        <ng-container
                          *ngIf="getRegions(continent, false).length > 0"
                        >
                          <ng-container
                            *ngFor="let i of getRegions(continent, false)"
                          >
                            <li
                              [ngClass]="{
                                'active-account': getSelectedRegion() == i.id
                              }"
                            >
                              <span
                                class="dropdown-item"
                                (click)="setRegion(i, true)"
                                >{{ i.name }}</span
                              >
                            </li>
                          </ng-container>
                          <li
                            class="disabled border-top"
                            *ngIf="getRegions(continent, false).length > 0"
                          ></li>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <li
                      class="disabled dropdown-item border-bottom center"
                      *ngIf="getDisabledRegions(false).length > 0"
                    >
                      Disabled Regions ({{ getDisabledRegions(false).length }})
                    </li>
                    <ng-container *ngIf="getDisabledRegions(false).length > 0">
                      <li *ngFor="let i of getDisabledRegions(false)">
                        <span class="dropdown-item disabled">{{ i.name }}</span>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </li>

              <!-- notification -->
              <li class="nav-item dropdown">
                <div class="notiBtn" (click)="viewNotification($event)">
                  <i class="fa-solid fa-bell"></i>
                  <span
                    *ngIf="noticount != 0"
                    class="badge badge-pill badge-danger bg-danger"
                    >{{ noticount }}</span
                  >
                  <!-- <span class="badge" >12</span> -->
                </div>
              </li>
              <!-- notification -->
              <li class="nav-item dropdown searchbox min-width-auto">
                <a
                  class="d-block text-decoration-none dropdown-toggle color-top"
                  id="dropdownUser1"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <!--<img src="../../assets/img/passport.png" class="rounded-circle" width="32" height="32" />-->
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <!-- <span class="color-family">{{ username }}</span> -->
                </a>
                <ul
                  class="dropdown-menu text-small"
                  aria-labelledby="dropdownUser1"
                  data-popper-placement="bottom-start"
                >
                  <!--<li><a class="dropdown-item" href="#">Settings</a></li>
                  <li><a class="dropdown-item" href="#">Profile</a></li>-->
                  <li>
                    <span class="color-family text-bold">{{ username }}</span>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <button class="dropdown-item" (click)="logout()">
                      Sign out
                    </button>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="dropdown text-end"></div>

            <div style="width: 75px"></div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>

<div class="content min-vh-100 rightcontent">
  <router-outlet></router-outlet>
</div>

<!--<router-outlet></router-outlet>-->
