import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

declare let $: any;

@Component({
  selector: 'error-invite-user',
  templateUrl: './error.invite.user.component.html',
  styleUrls: ['./error.invite.user.component.css'],
})
export class ErrorInviteUser implements OnInit, OnDestroy {
  loading = true;
  @Output() hideModel = new EventEmitter<any>();

  ngOnInit(): void {}

  async uploadFile() {
    this.hideModel.emit(true);
  }

  ngOnDestroy(): void {}

  close() {
    this.hideModel.emit(false);
  }
}
