import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

declare let $: any;

@Component({
  selector: 'update-policy',
  templateUrl: './update.policies.component.html',
  styleUrls: ['./../../ec2-group/components/ec2-group.component.css'],
})
export class UpdatePoliciesComponent implements OnInit, OnDestroy {
  @Input('group') policy: any;
  @Input('action') action: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  instances: any = [];
  services: any = [];
  regionId: any;
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.policy = { ...this.policy };
    $('#servicesList').selectpicker();
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.load();
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.load();
        this.regionId = d.value;
      }
    });
  }

  async load() {
    await this.loadServices();
  }

  async updateEntry() {
    if (this.action == 'local') {
      this.close(true);
      return;
    }
    this.notifier.loading(true);
    let data = {
      clientID: this.userId,
      policyName: this.policy.policyName,
      action: this.action,
      menuList: $('#servicesList').val(),
      accessType: this.policy.accessType,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/policydetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      if (this.action == 'add') {
        this.close();
      } else {
        this.close(true);
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  close(sendData: boolean = false) {
    if (sendData) {
      this.policy['menuList'] = $('#servicesList').val();
      this.hideModel.emit(this.policy);
    } else {
      this.hideModel.emit(false);
    }
  }

  checkOfServices() {
    const fixedValues = ['CloudSupport', 'Settings'];
    fixedValues.forEach((element: any) => {
      const selectedValues = $('#servicesList').val();
      if (selectedValues.indexOf(element) < 0) {
        this.notifier.alert(
          'Info',
          '',
          `${element} is a Mandatory Service`,
          'info',
          5000
        );
        $('#servicesList').val([...selectedValues, element]);
        $('#servicesList').selectpicker('refresh');
      }
    });
  }

  async loadServices(nextToken?: any) {
    this.notifier.loading(true);
    let data = {
      emailId: this.userId,
      clientName: localStorage.getItem('un'),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/listawsaccounts`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.services = result.menuList;
      setTimeout(() => {
        $('#servicesList').selectpicker('refresh');
        $('#servicesList').selectpicker('val', this.policy.menuList);
        this.checkOfServices();
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }
}
