import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

declare let window: any;
declare let $: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './mrr.component.html',
})
export class MRRDiscountComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  mrrData: any = [];
  accountId: any;
  currentMessage: any;
  updateMRR: any;
  updateAction: any = 'update';
  unsavedMRR: any = [];
  unsavedUpdateId: number = -1;
  deleteMRR: any;

  headers: any = [
    {
      id: 'serviceName',
      name: 'Service Name',
    },
    {
      id: 'mrrDiscount',
      name: 'MRR Discount',
    },
  ];

  funRef: any = {};

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.funRef = {};

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      }
    });
  }

  async load() {
    console.log('fetching data');
    this.notifier.loading(true);
    let data = { a: 'fetch', lid: this.accountId };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/monthlydev`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.s == '1') {
      this.mrrData = result.data;
    } else {
      this.mrrData = [];
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  updateTrigger(id: any) {
    this.updateAction = 'update';
    this.updateMRR = id;
  }

  deleteUnsavedMRR(id: number) {
    this.unsavedMRR.splice(id, 1);
  }

  updateUnsavedMRR(id: any, index: number) {
    this.updateAction = 'add';
    this.unsavedUpdateId = index;
    this.updateMRR = id;
  }

  closeUpdateTrigger(event: any) {
    console.log('closeUpdateTrigger', event.mrrList);
    if (event) {
      if (this.updateAction == 'update') {
        this.load();
      } else {
        if (this.unsavedUpdateId > -1) {
          this.unsavedMRR[this.unsavedUpdateId] = event.mrrList[0];
          this.unsavedUpdateId = -1;
        } else {
          // this.unsavedMRR.push(event.mrrList);
          this.unsavedMRR = [...this.unsavedMRR, ...event.mrrList];
        }
      }
    }
    this.updateMRR = null;
  }
  async deleteTrigger(id: any) {
    if (confirm('Are you sure you want to delete this record?')) {
      this.notifier.loading(true);
      let data: any = {
        lid: this.accountId,
        a: 'delete',
        serviceName: id.serviceName,
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `https://api.swayam.cloud/v3/admin/billing/monthlydev`;
      let result = await this.apiService.postDataPromis(apiURL, data, header);
      if (result.status == '1' || result.s == '1') {
        this.notifier.alert('Success', '', result.msg, 'success', 5000);
        this.load();
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }

      this.notifier.loading(false);
    } else {
      return;
    }
  }

  closeDeleteTrigger(event: any) {
    this.deleteMRR = null;
  }

  addMrr() {
    this.updateAction = 'add';
    this.unsavedUpdateId = -1;
    this.updateMRR = {
      serviceName: '',
      mrrDiscount: 0,
    };
  }

  async saveDetails() {
    this.notifier.loading(true);
    let data: any = {
      a: 'add',
      lid: this.accountId,
      mrrList: this.unsavedMRR,
    };
    // console.log('saveDetails');
    // console.log(data);
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/monthlydev`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.unsavedMRR = [];
      this.load();
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  callFunction(param: any, id: any) {
    this.funRef[param](this, id);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
