<div class="card">
  <div class="card-header">
    <div class="card-title">
      <h5>

        <span>
          Security Groups
        </span>
        <button class="btn btn-success-soft" style="float: right; margin: 0 10px" (click)="createJob($event)">
          <i class="fa fa-search" aria-hidden="true"></i>
          Scan
        </button>
        <button class="btn btn-primary-soft" style="float: right; margin: 0 10px" (click)="load()">
          <i class="fa fa-search" aria-hidden="true"></i>
          Refresh
        </button>
      </h5>
    </div>
  </div>
</div>
<nav style="margin-top: 10px;">
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button *ngFor="let tab of sgTabs; let i = index" class="nav-link" [ngClass]="{'active' : i == 0}" id="nav-home-tab"
      [attr.data-bs-toggle]="'tab'" [attr.data-bs-target]="tab.metadata.id_str" type="button" role="tab"
      [attr.aria-controls]="tab.onclick" (click)="getSecurityGroups(tab.onclick)" aria-selected="true">
      {{ tab.metadata.str }} ({{tab.count || 0}})
    </button>
  </div>
</nav>
<div class="tab-content" id="nav-tabContent">
  <div *ngFor="let tab of sgTabs; let i = index" class="tab-pane" [ngClass]="{'active' : i == 0}" id={{tab.onclick}}
    role="tabpanel" [attr.aria-labelledby]="tab.onclick+'-tab'">
    <div class="card">
      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->
        <div class="card">
          <div class="card-body">
            <table class="table table-docs table-hover">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of getHeader(tab.onclick)">
                    {{ h.name }}
                    <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,sgList)">

                      <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                      <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                    </span>

                    <span *ngIf="h.filter " class="inputseach">
                      <input type="text" [(ngModel)]="h.filterText" />
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody [ngStyle]="{'display': sgList.length > 0 ? 'table-row-group' : 'none'}"
                *ngFor="let i of sgList; let j = index">
                <tr *ngIf="notifier.filterTableColums(getHeader(tab.onclick),i)">
                  <td> <i *ngIf="!i.showNested" class="fa fa-caret-right"></i> <i *ngIf="i.showNested"
                      class="fa fa-caret-down"></i>
                    {{ j + 1 }}</td>
                  <td *ngFor="let h of getHeader(tab.onclick)">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span>
                        {{ i[h.id] }}
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      {{ i[h.id] }}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="i.showNested" style="height:100px">
                  <td [attr.colspan]="headers.length + 1">
                    <nTableNested [headers]="getHeader()" [nestedHeader]="getHeaderNested()"
                      [nestedLoadData]="nestedLoader()" [data]="i.attachedInstances" (hideModal)="hideNested($event)">
                    </nTableNested>
                  </td>
                </tr>
              </tbody>
              <tbody [ngStyle]="{'display': sgList.length == 0 ? 'table-row-group' : 'none'}">
                <tr>
                  <td [attr.colspan]="headers.length + 1">
                    <div style="text-align: center;">No Security Groups Found</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<sg-create-job-element *ngIf="createJobTrigger" (hideModal)="hideCreateJob($event)">
</sg-create-job-element>