<div class="card">
  <h5 class="card-header">
    <span class="title font-line-middle">{{ getFinding()['title'] }} Compliance</span>
    <div>
      <button class=" btn btn-success-soft float-margin-10"
        *ngIf="iamCheckList.length > 0 && scanDetails && scanDetails['resource_found']" (click)="downloadReport()"><i
          class="fa fa-download" aria-hidden="true"></i>
        Download</button>
      <button class="btn btn-primary-soft float-margin-10" (click)="load()">
        <i class="fa fa-refresh" aria-hidden="true"></i>
        Refresh
      </button>
    </div>
  </h5>
  <div class="card-body">
    <nav class="mar-top-19">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-link" [ngClass]="{active: scanDetails && !scanDetails['resource_found']}" id="nav-home-tab"
          data-bs-toggle="tab" data-bs-target="#job-details" type="button" role="tab" aria-controls="job-details"
          aria-selected="true">
          Scan
        </a>
        <a class="nav-link" [ngClass]="{active: scanDetails && scanDetails['resource_found']}" id="nav-home-tab"
          data-bs-toggle="tab" data-bs-target="#floating-amis" type="button" role="tab" aria-controls="floating-amis"
          aria-selected="true" [attr.disabled]="scanDetails['resource_found'] ? null : true"
          *ngIf="scanDetails && !fetching" [ngClass]="{disabled: !scanDetails['resource_found']}">
          Findings
        </a>
      </div>
    </nav>
    <div class="flow-background">
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane" [ngClass]="{active: scanDetails && !scanDetails['resource_found']}" id="job-details"
          role="tabpanel" aria-labelledby="job-details-tab">
          <div class="card mar-top-0">
            <div class="card-body display-item-direction" *ngIf="scanDetails && !fetching">
              <div class="display-item-gap">
                <div class="bold_text">Scanned On</div>
                <div>{{ scanDetails["startedAt"] }}</div>
              </div>
              <div class="display-item-gap">
                <div class="bold_text">Status</div>
                <div>{{ scanDetails['resource_found'] ? scanDetails["scanStatus"] : 'Resource not found/configured' }}
                </div>
              </div>
            </div>
            <div *ngIf="fetching" class="card-body display-item-direction">
              <span class="center">
                <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" class="width-margin-10" />
              </span>
            </div>
            <div *ngIf="!scanDetails && !fetching" class="card-body display-item-direction">
              <span class='center'>
                No Scans Found. </span>
            </div>
          </div>
        </div>
        <div class="tab-pane" [ngClass]="{active: scanDetails && scanDetails['resource_found']}" id="floating-amis"
          role="tabpanel" aria-labelledby="floating-amis-tab">
          <div class="card mar-top-0">
            <div class="card-body">
              <table class="table table-docs table-hover">
                <thead>
                  <tr>
                    <th>S.N.</th>
                    <th *ngFor="let h of headers">
                      {{h.name}}
                      <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,iamCheckList)">

                        <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                        <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                      </span>

                      <span *ngIf="h.filter " class="inputseach">
                        <input type="text" [(ngModel)]="h.filterText" />
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </span>
                    </th>
                    <th>Offenders</th>
                  </tr>
                </thead>

                <tbody [ngStyle]="{'display': iamCheckList.length > 0 ? 'table-row-group': 'nonw'}"
                  *ngFor="let i of iamCheckList; let j = index">
                  <tr *ngIf="notifier.filterTableColums(headers,i)">
                    <td>{{j+1}}</td>
                    <td *ngFor="let h of headers">
                      <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                        <span (click)="callFunction(h.click,i)">
                          {{i[h.id]}}
                        </span>
                      </span>
                      <span *ngIf="!h.click">
                        <span *ngIf="h.color && !h.alter">
                          <span *ngIf="i[h.id] == 'true'" class="flex-center-gap-8">
                            <span><i class="fa fa-check-circle color-green" aria-hidden="true"></i></span>
                            <span class="color-green">Pass</span>
                          </span>
                          <span *ngIf="i[h.id] == 'false'" class="color-item-gap-8">
                            <span><i class="fa fa-times-circle color-red" aria-hidden="true"></i></span>
                            <span class="color-red">Fail</span>
                          </span>
                          <span *ngIf="i[h.id] == 'Manual' || i[h.id] == 'manual'" class="flex-center-gap-8">
                            <span><i class="fa fa-times-circle color-red" aria-hidden="true"></i></span>
                            <span class="color-red">Manual</span>
                          </span>
                          <span *ngIf="i[h.id] == ''">
                            <span>-</span>
                          </span>
                        </span>
                        <span *ngIf="!h.color && !h.alter">
                          {{checkforBool(i[h.id], h.id) || '-'}}
                        </span>
                        <span *ngIf="h.alter">
                          <span *ngIf="i[h.id] == 'Low'" class="flex-center-gap-8">
                            <span class="width-height-radius"></span>
                            <span color-163>{{i[h.id]}}</span>
                          </span>
                          <span *ngIf="i[h.id] == 'Medium'" class="color-direction-item">
                            <span class="width-height-orange"></span>
                            <span class="color-orange">{{i[h.id]}}</span>
                          </span>
                          <span *ngIf="i[h.id] == 'High'" class="flex-center-gap-8">
                            <span class="width-height-red"></span>
                            <span class="color-red">{{i[h.id]}}</span>
                          </span>
                        </span>
                      </span>
                    </td>
                    <td> <button class="btn btn-link" *ngIf="i.Result != 'true' && i.Result != true"
                        (click)="showOffendes(i.Offenders)">Offenders</button>
                    </td>
                  </tr>
                </tbody>
                <tbody [ngStyle]="{'display': iamCheckList.length == 0 ? 'table-row-group' : 'none'}">
                  <tr>
                    <td [attr.colspan]="headers.length + 2">
                      <div class="align-center">No Data Found</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="modal show" tabindex="-1" *ngIf="showOffendesModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Offender List</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="showOffendesModal = false" ></button>
      </div>
      <div class="modal-body">
        <div class="card">
         
          <div class="card-body">-->
<!--<h5 class="card-title">Case Details</h5>-->

<!--<table class="table table-docs table-hover" *ngIf="offendeList.length > 0">
              <thead >
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of offendeListHeader">{{h.name}}</th>
                
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let i of offendeList; let j = index">
                  <td>{{j+1}}</td>
                  <td *ngFor="let h of offendeListHeader">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span (click)="callFunction(h.click,i)">
                        {{i[h.id]}}
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      {{checkforBool(i[h.id])}}
                    </span>
                  </td>                
                </tr>
              </tbody>
            </table>
            <h5 style="text-align:center; color:#0094ff" *ngIf="offendeList.length == 0" >No Data</h5>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="showOffendesModal = false" >Close</button>      
      </div>
    </div>
  </div>
</div>-->

<modal-popup [headers]="offendeListHeader" [data]="offendeList" [modalTitle]="offentpopTitle"
  [showModal]="showOffendesModal" (hideModal)="hidePop($event)"></modal-popup>