import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, TitleStrategy } from '@angular/router';

declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title: any = 'dashboard';
  constructor(public router: Router, titleService: Title) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //gtag('config', 'G-GKPQB7KZZ8', { 'page_path': event.urlAfterRedirects });
        window.analyticsSendPageView(
          titleService.getTitle(),
          event.urlAfterRedirects
        );
      }
    });
  }
}
