import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
//import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { APIService } from './../api/api.service';
import { NotifierService } from './../_services/notifier.service';

import SearchData from '../data/searchData';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

declare var $: any;
declare var window: any;

export let internetActive: boolean;

export let regions: any = {
  enabled: [],
  disabled: [],
};

export let cftrequired: any = {
  'tell-me': {
    cft: moment('09-05-2023', 'DD-MM-YYYY').valueOf(),
    link: '/dash/tell-me',
  },
};

export let emsIntegration: any = [
  '/dash/reports/view?reportType=inventory',
  '/dash/site24x7/monitors',
];

export interface CookieOptions {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
}

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css'],
})
export class CBSDashComponent implements OnInit {
  userId = localStorage.getItem('eId');
  username = localStorage.getItem('un');
  cbsAccess: boolean = false;
  started: boolean = false;
  showITAS: boolean = false;
  selectedOption: string;
  masterEmailId: string = localStorage.getItem('masterId');
  currentPageLink: string;
  hideAccountTab: boolean = true;
  subType: string = localStorage.getItem('subType') || 'freetrial';
  isUserAdmin: boolean = localStorage.getItem('isUserAdmin') == '1';

  plans: any = {
    standard: 'Standard',
    advanced: 'Advanced',
    freetrial: 'Free Trial',
  };

  whatsNew: any = [
    {
      name: 'Tell Me',
      url: '/dash/tell-me',
      permission: () => {
        return (
          this.checkIfAvaForUser('Monitoring') &&
          moment().valueOf() <
            moment('22-06-2023', 'DD-MM-YYYY').endOf('day').valueOf()
        );
      },
      validTill: '22-06-2023',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Tell+Me+Feature%404x.png',
    },
    {
      name: 'EC2 Cost Controls',
      url: '/dash/costopt/ec2',
      permission: () => {
        return (
          (this.checkIfAvaForUser('Cost Controls') ||
            this.checkIfAvaForUser('CostOptimization')) &&
          moment().valueOf() <
            moment('22-06-2023', 'DD-MM-YYYY').endOf('day').valueOf()
        );
      },
      validTill: '22-06-2023',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Optimization%404x.png',
    },
  ];

  totalPeriod: number = localStorage.getItem('totalPeriod')
    ? Number(localStorage.getItem('totalPeriod'))
    : 0;

  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  agl: any;
  expanded: any = {
    site24x7: false,
    infra: false,
    security: false,
    maintenance: false,
    expenditure: false,
    optimization: false,
  };

  BOMAccess: boolean = this.urlPrefix === 'admin';
  betaAccess: boolean = false;
  accessType: string = localStorage.getItem('acT');
  access: string = localStorage.getItem('accessType');
  customerType: string = localStorage.getItem('customerType');

  accountGroups: any = {
    list: [],
    selected: '',
  };
  clientNames: any = {
    list: [],
    selected: '',
  };
  accountIds: any = {
    list: [],
    selected: '',
  };
  searchValue: string = '';
  recentSearchs: any = [];
  regions: any = {
    enabled: [],
    disabled: [],
  };

  expiresIn: any = localStorage.getItem('expiresIn');
  //regions = [
  //  { id: 'ap-south-1', name: 'Asia Pacific (Mumbai)ap-south-1' },
  //  { id: 'us-east-1', name: 'US East (N. Virginia)us-east-1' },
  //  { id: 'us-east-2', name: 'US East (Ohio)us-east-2' },
  //  { id: 'us-west-1', name: 'US West (N. California)us-west-1' },
  //  { id: 'us-west-2', name: 'US West (Oregon)us-west-2' },
  //  { id: 'ap-northeast-3', name: 'Asia Pacific (Osaka-Local)ap-northeast-3' },
  //  { id: 'ap-northeast-2', name: 'Asia Pacific (Seoul)ap-northeast-2' },
  //  { id: 'ap-southeast-1', name: 'Asia Pacific (Singapore)ap-southeast-1' },
  //  { id: 'ap-southeast-2', name: 'Asia Pacific (Sydney)ap-southeast-2' },
  //  { id: 'ap-northeast-1', name: 'Asia Pacific (Tokyo)ap-northeast-1' },
  //  { id: 'ca-central-1', name: 'Canada (Central)ca-central-1' },
  //  { id: 'eu-central-1', name: 'Europe (Frankfurt)eu-central-1' },
  //  { id: 'eu-west-1', name: 'Europe (Ireland)eu-west-1' },
  //  { id: 'eu-west-2', name: 'Europe (London)eu-west-2' },
  //  { id: 'eu-west-3', name: 'Europe (Paris)eu-west-3' },
  //  { id: 'eu-north-1', name: 'Europe (Stockholm)eu-north-1' },
  //  { id: 'me-south-1', name: 'Middle East (Bahrain)me-south-1' },
  //  { id: 'sa-east-1', name: 'South America (São Paulo)sa-east-1' },
  //  { id: 'ap-east-1', name: 'Asia Pacific (Hong Kong)ap-east-1' },
  //  { id: 'af-south-1', name: 'Africa (Cape Town)af-south-1' },
  //  { id: 'ap-southeast-3', name: 'Asia Pacific (Jakarta)ap-southeast-3' },
  //  { id: 'eu-south-1', name: 'Europe (Milan)eu-south-1' },
  //];

  selectedRegion = 'Asia Pacific (Mumbai) ap-south-1';
  selectedRegionId = 'ap-south-1';

  accountIdSearchTerm: any;
  clientNameSearchTerm: any;
  accountGroupSearchTerm: any;
  accessList: any = localStorage.getItem('menuList');
  showSearchResult: boolean = false;
  searchData: any = SearchData;
  searchResults: any;
  currentRoute: string = null;

  notification: any[] = [];
  noticount: any = 0;
  viewNotificationTrigger = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentRoute = e.url;
        if (localStorage.getItem('accountStatus') == 'Inactive') {
          if (e.url.indexOf('/dash/renewal') < 0) {
            this.showExpired = true;
            this.notifier.loading(false);
          } else {
            this.showExpired = false;
          }
        } else {
          this.showExpired = false;
        }
        this.currentPageLink = e.url;

        this.checkForMandatory();
      }
    });
  }

  showExpired: boolean = false;

  dashServiveMessage: any;

  async ngOnInit() {
    this.notifier.loading(true);

    // window.addEventListener("online", () => {
    //   alert("Internet is active");
    //   internetActive = true;
    // });
    // window.addEventListener("offline", () => {
    //   alert("Internet is down");
    //   internetActive = false;
    // });
    this.regions = regions;

    if (localStorage.getItem('accountStatus') == 'Inactive') {
      return false;
    }
    if (this.accessList) {
      try {
        this.accessList = JSON.parse(this.accessList).map((menu: any) => {
          return menu.toLowerCase();
        });
      } catch (error) {
        this.accessList = [];
      }
    }
    // this.fetchRecentSearch();
    // if (this.ut == 'user') {
    //   this.urlPrefix = 'client';
    // }

    if (this.urlPrefix == 'admin') {
      this.checkForCBS();
    }

    if (this.customerType != 'mp') {
      this.started = true;
    }
    this.checkForBetaAccess();

    await this.loadHeader();
    //localStorage.setItem('agl', JSON.stringify(this.agl));
    // if (!localStorage.getItem('selectedRegion')) {
    //   localStorage.setItem('selectedRegion', this.regions[0].name);
    //   localStorage.setItem('selectedRegionId', this.regions[0].id);
    //   localStorage.setItem('regionId', this.regions[0].id);
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // } else {
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // }
    return true;
  }

  totalWhatsNew() {
    return this.whatsNew.filter((new_: any) => {
      return new_['permission']();
    });
  }

  mandatory: boolean = false;

  checkForMandatory() {
    let check: any = Object.keys(cftrequired).find((feature: string) => {
      return (
        window.location.pathname.indexOf(cftrequired[feature]['link']) > -1
      );
    });
    this.mandatory = check ? true : false;
  }

  checkForEMS() {
    return (
      emsIntegration.includes(this.currentPageLink) &&
      !localStorage.getItem('emsConfig') &&
      this.getCurrentAccount() &&
      !this.getCurrentAccount()['site_247_project_id']
    );
  }

  createBUCheck(event: any) {
    if (event) {
      localStorage.removeItem('accountListNew');
      if (this.urlPrefix == 'admin') {
        this.fetchAccountList(localStorage.getItem('selectedClientList'));
      } else {
        this.loadAccounts();
      }
    }

    this.enableBU = false;
  }

  link: string = null;

  async loadCFTLink() {
    let apiURL: string = `https://prod.api.swayam.cloud/v3/cft`;

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let result: any = await this.apiServer.postDataPromis(apiURL, {}, header);

    if (result.status == '1' || result.s == '1') {
      this.link = result['template_url'].replace(
        'RootAdminEmailIdValue',
        this.userId || localStorage.getItem('eId')
      );
    }
  }

  async checkForBetaAccess() {
    let data = {
      action: 'check_user',
      uid: localStorage.getItem('eId'),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/checkdevuser`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      if (result.msg == 'Y') {
        this.betaAccess = true;
      } else {
        this.betaAccess = false;
      }
    }
    //if (this.CBSPortal && !this.cbsAccess) {
    //  this.logout();
    //}
  }

  async checkForCBS() {
    let data = {
      emailID: localStorage.getItem('eId'),
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchcbsuserstatus`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      if (result.enableCBS) {
        this.cbsAccess = true;
      }
    }
  }

  async checkForCFT(account_id: any, select: boolean = true) {
    return new Promise(async (resolve: any, reject: any) => {
      if (account_id['cft_status'] == undefined) {
        account_id['cft_status'] = 'loading';
      }
      let data = {
        action: 'validate_access',
        iam_role_arn: `arn:aws:iam::${account_id['accountId']}:role/msopsstsrole`,
        session_name: 'Swayam@AssumeRoleValidate',
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/accounts/validate`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        account_id['cft_status'] = true;
        account_id['cft_latest_version'] = moment(
          result['LatestStackVersion'],
          'DD-MM-YYYY'
        ).valueOf();
        account_id['cft_latest_version_raw'] = result['LatestStackVersion'];
        // result["StackVersion"] = "1.0.1";
        try {
          if (moment(result['StackVersion'], 'DD-MM-YYYY', true).isValid()) {
            account_id['cft_current_version'] = moment(
              result['StackVersion'],
              'DD-MM-YYYY'
            ).valueOf();
          } else {
            account_id['cft_current_version'] = moment(
              '01-01-2023',
              'DD-MM-YYYY'
            ).valueOf();
          }
        } catch {
          account_id['cft_current_version'] = moment(
            '01-01-2023',
            'DD-MM-YYYY'
          ).valueOf();
        }
        if (result['CloudShell']) {
          result['CloudShell']['Command'] = result['CloudShell'][
            'Command'
          ].replace('RootAdminEmailIdValue', this.userId);
        }
        account_id['cft_latest_url'] = result['CloudShell'];
        account_id['cft_deployed_region'] = result['StackRegion'];
        let currentAccount: any = this.getCurrentAccount();
        if (currentAccount) {
          if (currentAccount['accountId'] == account_id['accountId']) {
            this.started = true;
          }
        }
      } else {
        account_id['cft_status'] = false;
        account_id['cft_latest_url'] = this.link;
        if (
          this.customerType == 'mp' &&
          localStorage.getItem('accountId') == account_id['accountId']
        ) {
          this.started = false;
        }
      }

      if (
        localStorage.getItem('accountId') == account_id['accountId'] &&
        localStorage.getItem('role') == 'Client' &&
        select
      ) {
        this.setAccountList(account_id['accountId']);
      }
      resolve(result);
    });
  }

  async fetchAccountGroups() {
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      let data = {
        action: 'fetchAllAccountGroups2',
        clientId: localStorage.getItem('eId'),
        userId: localStorage.getItem('eId'),
        ut: localStorage.getItem('ut'),
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == '1') {
        result.agl = result.agl.sort(function (a: any, b: any) {
          if (a.groupName < b.groupName) {
            return -1;
          }
          if (a.groupName > b.groupName) {
            return 1;
          }
          return 0;
        });
        if (result.agl) {
          localStorage.setItem('accountGroups', JSON.stringify(result.agl));
          await this.loadAccountGroups();
          resolve({ s: '1', message: 'Account Groups Fetched' });
        } else {
          reject({ s: '0', message: 'Account Groups Not Found' });
        }
      }
      resolve(result);
    });
  }

  async loadAccountGroups() {
    return new Promise(async (resolve: any, reject: any) => {
      const accountGroups = localStorage.getItem('accountGroups');
      try {
        const data = JSON.parse(accountGroups);
        if (data.length > 0) {
          this.accountGroups.list = data.sort(function (a: any, b: any) {
            if (a.groupName < b.groupName) {
              return -1;
            }
            if (a.groupName > b.groupName) {
              return 1;
            }
            return 0;
          });
          const prevAccountGroup = localStorage.getItem('selectedAccountGroup');
          if (prevAccountGroup) {
            await this.setAccountGroup(prevAccountGroup);
          } else {
            await this.setAccountGroup(data[0].groupName);
          }
          resolve({ s: '1', message: 'ACCOUNT GROUPS LOADED' });
        } else {
          await this.fetchAccountGroups();
        }
      } catch (err) {
        await this.fetchAccountGroups();
      }
    });
  }

  async setAccountGroup(groupName: any) {
    this.accountIdSearchTerm = null;
    this.clientNameSearchTerm = null;
    this.accountGroupSearchTerm = null;

    this.accountGroups.selected = groupName;
    const prevAccountGroup = localStorage.getItem('selectedAccountGroup');
    if (prevAccountGroup) {
      if (prevAccountGroup != groupName) {
        await this.fetchClientList(groupName);
      } else {
        this.loadClientList(groupName);
      }
    } else {
      await this.fetchClientList(groupName);
    }
    localStorage.setItem('selectedAccountGroup', groupName);
  }

  async fetchClientList(groupName: any) {
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      let data = {
        action: 'fetchSwayamClientList',
        clientId: localStorage.getItem('eId'),
        userId: localStorage.getItem('eId'),
        groupName: groupName,
        ut: 'admin',
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == '1') {
        if (result.agl) {
          result.agl[0]['accountGroup']['accountGroupList'][
            'accountGroupDetails'
          ] = result.agl[0]['accountGroup']['accountGroupList'][
            'accountGroupDetails'
          ].sort(function (a: any, b: any) {
            if (a.cmpName < b.cmpName) {
              return -1;
            }
            if (a.cmpName > b.cmpName) {
              return 1;
            }
            return 0;
          });
          localStorage.setItem(
            'clientList',
            JSON.stringify(
              result.agl[0]['accountGroup']['accountGroupList'][
                'accountGroupDetails'
              ]
            )
          );
          await this.loadClientList(groupName);
          resolve({ s: '1', message: 'Client List Fetched' });
        } else {
          reject({ s: '0', message: 'Client List Not Found' });
        }
      }
      resolve(result);
    });
  }

  async loadClientList(groupName: any) {
    return new Promise(async (resolve, reject) => {
      const clientList = localStorage.getItem('clientList');
      try {
        const data = JSON.parse(clientList);
        if (data.length > 0) {
          this.clientNames.list = data.map((client: any) => {
            client['cmpName'] = client['cmpName'].trim();
            return client;
          });
          this.clientNames.list = data.sort(function (a: any, b: any) {
            if (a.cmpName < b.cmpName) {
              return -1;
            }
            if (a.cmpName > b.cmpName) {
              return 1;
            }
            return 0;
          });
          const prevClientList = localStorage.getItem('selectedClientList');
          if (
            prevClientList &&
            localStorage.getItem('selectedAccountGroup') == groupName
          ) {
            await this.setClientList(prevClientList);
          } else {
            await this.setClientList(data[0]['emailId']);
          }
          resolve({ s: '1', message: 'CLIENT LIST LOADED' });
        } else {
          await this.fetchClientList(groupName);
        }
      } catch (err) {
        await this.fetchClientList(groupName);
      }
    });
  }

  async setClientList(clientName: any) {
    $('#wait').show();
    this.accountIdSearchTerm = null;
    this.clientNameSearchTerm = null;
    this.accountGroupSearchTerm = null;

    this.clientNames.selected = this.clientNames.list.find((client: any) => {
      return client.emailId == clientName;
    })['cmpName'];
    const prevClientList = localStorage.getItem('selectedClientList');
    if (prevClientList) {
      if (prevClientList != clientName) {
        await this.fetchAccountList(clientName);
      } else {
        this.loadAccountList(clientName);
      }
    } else {
      await this.fetchAccountList(clientName);
    }
    localStorage.setItem('selectedClientList', clientName);
    return true;
  }

  async fetchAccountList(clientName: any) {
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      let data = { a: 'fetchAccounts', eId: clientName };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/awsaccounts`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == '1') {
        if (result.config) {
          localStorage.setItem('emsConfig', JSON.stringify(result.config));
          this.loadMonitorGroups();
        } else {
          if (localStorage.getItem('emsConfig')) {
            localStorage.removeItem('emsConfig');
          }
        }
        if (result.accounts) {
          result.accounts = result.accounts.sort(function (a: any, b: any) {
            if (a.accountName < b.accountName) {
              return -1;
            }
            if (a.accountName > b.accountName) {
              return 1;
            }
            return 0;
          });
          localStorage.setItem('accountId', result.accounts[0]['accountId']);
          localStorage.setItem(
            'accountListNew',
            JSON.stringify(result.accounts)
          );
          await this.loadAccountList(clientName);
          resolve({ s: '1', message: 'Account Groups Fetched' });
        } else {
          reject({ s: '0', message: 'Account Groups Not Found' });
        }
      }
      if (this.accountIds.list.length == 0) {
        this.loadRegions();
      }
      resolve(result);
    });
  }

  async loadAccountList(clientList: any) {
    return new Promise(async (resolve, reject) => {
      const accountList = localStorage.getItem('accountListNew');
      try {
        const data = JSON.parse(accountList);
        if (data.length > 0) {
          this.accountIds.list = data.sort(function (a: any, b: any) {
            if (a.accountName < b.accountName) {
              return -1;
            }
            if (a.accountName > b.accountName) {
              return 1;
            }
            return 0;
          });
          const prevAccountList = localStorage.getItem('accountId');
          if (
            prevAccountList &&
            localStorage.getItem('selectedClientList') == clientList
          ) {
            localStorage.setItem('accountId', prevAccountList);
          } else {
            localStorage.setItem('accountId', data[0]['accountId']);
          }
          let promises: any = [];
          this.accountIds.list.forEach((account: any) => {
            promises.push(this.checkForCFT(account));
          });
          Promise.all(promises).then(async (values: any) => {
            localStorage.setItem(
              'accountIdData',
              JSON.stringify(this.accountIds)
            );
            if (
              prevAccountList &&
              localStorage.getItem('selectedClientList') == clientList
            ) {
              await this.setAccountList(prevAccountList);
            } else {
              await this.setAccountList(data[0]['accountId']);
            }
          });
          resolve({ s: '1', message: 'ACCOUNT LIST LOADED' });
        } else {
          await this.fetchClientList(this.accountGroups.selected);
        }
      } catch (err) {
        console.log('LOADING ACCOUNT LIST', err);
        await this.fetchClientList(this.accountGroups.selected);
      }
    });
  }

  async setAccountList(accountId: any) {
    this.accountIdSearchTerm = null;
    this.clientNameSearchTerm = null;
    this.accountGroupSearchTerm = null;

    const accountList = localStorage.getItem('accountListNew');
    try {
      const data = JSON.parse(accountList);
      let account = data.find((account: any) => {
        return account.accountId == accountId;
      });

      if (!account) {
        account = this.accountIds.list.find((account_: any) => {
          return account_.accountId == accountId;
        });
      }

      this.accountIds.selected = account.accountName;
      if (!account['cft_status'] && this.customerType == 'mp') {
        this.started = false;
        this.router.navigate(['/dash/accounts/aws']);
      } else {
        this.started = true;
      }
      localStorage.setItem('accountId', account.accountId);
      if (account) {
        localStorage.setItem('swayam_jira_org_id', account.jira_org_id);
        localStorage.setItem('swayam_jira_org_name', account.jira_org_name);
        localStorage.setItem('swayam_jira_project_id', account.jira_project_id);
        localStorage.setItem(
          'swayam_jira_project_key',
          account.jira_project_key
        );
        localStorage.setItem(
          'swayam_jira_project_name',
          account.jira_project_name
        );
        localStorage.setItem(
          'swayam_site_247_project_id',
          account.site_247_project_id
        );
        localStorage.setItem(
          'site_247_project_name',
          account.site_247_project_name
        );
        localStorage.setItem('monitoringUrl', account.monitoringUrl);
      }
    } catch (err) {}

    let account: any = this.accountIds.list.find((account_: any) => {
      return account_.accountId == accountId;
    });

    if (!account['cft_status'] && this.customerType == 'mp') {
      this.started = false;
      this.router.navigate(['/dash/accounts/aws']);
    } else {
      this.started = true;
    }

    this.selectAccountId(
      this.accountIds.list.find((account_: any) => {
        return account_.accountId == accountId;
      })
    );
    return true;
  }

  //TODO : save on Change

  checkIfAvaForUser(routeName: string): boolean {
    if (localStorage.getItem('role') === 'Client') {
      if (routeName.includes(':')) {
        return true;
      }
      routeName = routeName.toLowerCase().split(':')[0];
      if (
        this.accessList.length === 0 ||
        this.accessList.indexOf(routeName) > -1
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      const colonCount = (routeName.match(/:/g) || []).length;
      if (colonCount == 1 && localStorage.getItem('fullpnlpermision') === '0') {
        const ls = localStorage.getItem('submenupnl').split(',');
        if (localStorage.getItem('submenupnl').length > 0) {
          if (ls.includes(routeName.split(':')[1])) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  async navigateTo(param: any) {
    // await this.setRecentSearch(param);
    this.searchValue = '';
    this.router.navigateByUrl(param.url);
  }

  getPercentage(val?: number) {
    if (!val) {
      val = 0;
    }
    var r = 12;
    var c = Math.PI * (r * 2);

    if (val < 0) {
      val = 0;
    }
    if (val > 100) {
      val = 100;
    }

    let pct = ((100 - val) / 100) * c;

    return pct;
  }

  width: any;
  currentAccountCFTStatus: boolean = null;
  checkingCFT: boolean = false;

  async loadHeader() {
    //for notification
    // this.loadNotificationCount();
    // setInterval(() => {
    //   this.loadNotificationCount();
    // }, 3600000);

    this.loadCFTLink();
    this.notifier.loading(true);

    if (localStorage.getItem('role') == 'Client') {
      let data = localStorage.getItem('accountIdData');
      var max = -219.99078369140625;
      let percent = (this.expiresIn / 15) * 100;
      this.width = ((100 - percent) / 100) * max;
      this.accountIds.list = [];
      // if (data) {
      //   this.accountIds = {
      //     selected: JSON.parse(data).selected,
      //     list: JSON.parse(data).list,
      //   };
      //   if (
      //     this.accountIds &&
      //     this.accountIds.list &&
      //     this.accountIds.list.length == 0
      //   ) {
      //    true;true;
      //   } else {
      //     this.started = false;
      //   }
      //   localStorage.setItem("accountId", this.accountIds.list[0].accountId);
      //   localStorage.setItem("accountIdData", JSON.stringify(this.accountIds));
      //   this.selectAccountId(
      //     JSON.parse(data).list.find((account: any) => {
      //       //return account.accountName == this.accountIds.selected;
      //       return this.accountIds.selected.indexOf(account.accountId) > -1;
      //     })
      //   );
      //   this.accountIds.list.forEach((account: any) => {
      //     this.checkForCFT(account);
      //   });
      //   return true;
      // } else {
      //   let data = {
      //     a: "fetch",
      //     eid: localStorage.getItem("eId"),
      //   };
      //   let header = {
      //     Authorization: localStorage.getItem("t"),
      //   };
      //   let apiUrl = `${APIService.API_ENDPOINTV3}/market/user/accounts`;

      //   let result = await this.apiServer.postDataPromis(apiUrl, data, header);
      //   if (result.s == "1" || result.status == "1") {
      //     this.accountIds.list = result.accounts;
      //     if (
      //       this.accountIds &&
      //       this.accountIds.list &&
      //       this.accountIds.list.length == 0
      //     ) {
      //      true;true;
      //     } else {
      //       this.started = false;
      //     }
      //     this.accountIds.list.forEach((account: any) => {
      //       this.checkForCFT(account);
      //     });
      //     this.selectAccountId(this.accountIds.list[0]);
      //   } else {
      //    true;true;
      //   }
      //   // this.notifier.loading(false);
      //   return true;
      // }
      this.loadAccounts();
      return false;
    }

    await this.loadAccountGroups();
    return true;
    let agl = localStorage.getItem('agl');
    if (agl) {
      console.log('agl', agl);
      this.agl = JSON.parse(agl);

      this.loadAGL();
      return true;
    }

    this.notifier.loading(true);
    let data: any = {
      action: 'fetchAccountGroupsAndAccIDs',
      userId: this.userId,
      ut: this.ut,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;
    console.log('FETCHING HEADER DATA', this.urlPrefix);
    if (this.urlPrefix == 'admin') {
      // console.log('URL ADMIN:', this.urlPrefix);
      // apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;
      console.log('I AM ADMIN');
      await this.loadAccountGroups();
      return true;
    } else {
      console.log('URL ADMIN:', this.urlPrefix);
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/listawsaccounts`;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.s == '1' || result.status == '1') {
      this.notifier.loading(false);
      if (this.urlPrefix == 'client') {
        this.accountIds = {
          selected: result.list[0]['otherdetail'][0].accountName,
          list: result.list[0]['otherdetail'],
        };
        localStorage.setItem('accountId', this.accountIds.list[0].accountId);
        localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));
        this.selectAccountId(this.accountIds.list[0]);
        return true;
      }

      this.agl = result.agl;

      localStorage.setItem('agl', JSON.stringify(result.agl));

      //
      this.accountGroups = {
        selected: this.agl[0].groupName,
        list: [],
      };

      for (let i = 0; i < this.agl.length; i++) {
        this.accountGroups.list.push(this.agl[i]);
      }

      localStorage.setItem('selectedAccountGroup', this.accountGroups.selected);
      localStorage.setItem(
        'selectedAccountGroupData',
        JSON.stringify(this.accountGroups)
      );

      console.log(
        'this.accountGroups',
        JSON.stringify(
          this.accountGroups.list[0].accountGroup.accountGroupList
            .accountGroupDetails,
          null,
          2
        )
      );

      let value =
        this.accountGroups.list[0].accountGroup.accountGroupList
          .accountGroupDetails;
      this.clientNames = {
        selected: value[0].cmpName,
        list: value,
      };

      let clientName = this.clientNames.list[0];

      this.clientNames.selected = clientName.cmpName;

      localStorage.setItem('selectedClientName', this.clientNames.selected);
      localStorage.setItem(
        'selectedClientNameData',
        JSON.stringify(this.clientNames)
      );

      this.accountIds = {
        selected: clientName.accList[0].accountName,
        list: clientName.accList,
      };

      localStorage.setItem('selectedaccountIds', this.accountIds.selected);
      localStorage.setItem('accountId', clientName.accList[0].accountId);
      localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));

      //localStorage.removeItem('selectedAccountGroup');
      //localStorage.removeItem('selectedClientName');
      //localStorage.removeItem('selectedaccountIds');

      //localStorage.removeItem("selectedRegion");

      this.notifier.loading(false);

      //this.accountIds = {
      //  selected: "",
      //  list: []
      //};

      //this.regions = {
      //  selected: "",
      //  list: []
      //};
    } else {
      this.notifier.alert('Error', 'Error', 'Error Loading Account Groups');
      this.notifier.loading(false);
    }

    return true;
  }

  checkForSwayamEMS() {
    return localStorage.getItem('emsConfig');
  }

  enableBU: boolean = false;
  async loadMonitorGroups() {
    let account: any = localStorage.getItem('emsConfig');
    if (!account) {
      return;
    }
    let emsData: any = JSON.parse(account);
    let data: any = {
      action: 'list_monitor_groups',
      id_token: localStorage.getItem('idT'),
      zaaid: emsData['zaid'],
      login_user_name: this.username,
      login_user_email: this.userId,
      origin: 'swayam',
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/ems/central`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      localStorage.setItem(
        'site24x7MonitorGroups',
        JSON.stringify(result['monitor_groups'])
      );
    }

    return;
  }

  continents: any = [];

  getRegions(continent: string, enabled: boolean = true) {
    return this.regions['enabled'].filter((region: any) => {
      return (
        this.getCurrentAccount() &&
        region.id.startsWith(continent) &&
        (!this.getCurrentAccount()['customeRegions'] ||
          (enabled
            ? this.checkRegion(this.getCurrentAccount(), region.id)
            : true))
      );
    });
  }

  getDisabledRegions(enabled: boolean = true) {
    return this.regions['disabled'].filter((region: any) => {
      return (
        this.getCurrentAccount() &&
        (!this.getCurrentAccount()['customeRegions'] ||
          (enabled
            ? this.checkRegion(this.getCurrentAccount(), region.id)
            : true))
      );
    });
  }

  async loadRegions() {
    this.continents = [];
    let data: any = {
      a: 'regions',
      accountId: localStorage.getItem('accountId') || null,
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/globals`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.regions['enabled'] = result.data.filter((region: any) => {
        if (this.continents.indexOf(region['id'].split('-')[0]) < 0) {
          this.continents.push(region['id'].split('-')[0]);
        }
        return region['status'] == 'enabled';
      });
      this.regions['disabled'] = result.data.filter((region: any) => {
        return region['status'] != 'enabled';
      });
      let data = this.accountIds['list'].find((account: any) => {
        return account['accountId'] == localStorage.getItem('accountId');
      });

      if (data && data.regions && data.regions.length > 0) {
        let region: any = this.regions['enabled'].find((region: any) => {
          return region.id == data.regions[0];
        });

        if (region) {
          await this.setRegion(region, false);
        } else {
          await this.setRegion(this.regions['enabled'][0], false);
        }
      } else {
        if (
          !localStorage.getItem('regionId') &&
          this.regions['enabled'].length > 0
        ) {
          await this.setRegion(this.regions['enabled'][0], false);
          return true;
        } else {
          let check = this.regions['enabled'].find((region: any) => {
            return region.id == localStorage.getItem('regionId');
          });
          if (check) {
            await this.setRegion(check, false);
            return true;
          } else if (this.regions['enabled'].length > 0) {
            await this.setRegion(this.regions['enabled'][0], false);
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.message || result.error || result.error_message,
        'info',
        5000
      );
    }
    if (this.accountIds.list.length == 0) {
      this.notifier.loading(false);
    }

    return false;
  }

  getSelectedAccountId() {
    return this.checkForDemoAccount()
      ? '1234567890'
      : localStorage.getItem('accountId');
  }

  getMiddleString(text: string) {
    return text.substring(text.indexOf('(') + 1, text.lastIndexOf(')'));
  }

  async loadAccounts() {
    this.notifier.loading(true);

    let data: any = {
      a: 'fetchAccounts',
      eId:
        localStorage.getItem('masterId') &&
        localStorage.getItem('masterId') != 'undefined' &&
        this.isUserAdmin
          ? localStorage.getItem('masterId')
          : localStorage.getItem('eId'),
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/market/awsaccounts`;

    let result: any = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' && result.accounts) {
      if (result.config) {
        this.loadMonitorGroups();
        localStorage.setItem('emsConfig', JSON.stringify(result.config));
      } else {
        if (localStorage.getItem('emsConfig')) {
          localStorage.removeItem('emsConfig');
        }
      }
      result.accounts = result.accounts.map((account: any) => {
        if (account['regions'] == undefined) {
          account['regions'] = [];
        }
        if (account['customeRegions'] != undefined) {
          account['customRegions'] = account['customeRegions'];
        }
        if (account['customRegions'] == undefined) {
          account['customRegions'] = true;
        }
        if (account['importType'] == undefined) {
          account['importType'] = 'automatic';
        }
        if (result.config) {
          account['site_247_project_id'] = result['config']['emsUid'];
          account['site_247_project_name'] = result['config']['emsName'];
        }
        return account;
      });
      this.accountIds.list = result.accounts;

      let selectedAccount = localStorage.getItem('accountId');

      if (!selectedAccount) {
        localStorage.setItem('accountId', this.accountIds.list[0].accountId);
      }
      let account = this.accountIds.list.find((account: any) => {
        return account['accountId'] == selectedAccount;
      });
      if (!account) {
        localStorage.setItem('accountId', this.accountIds.list[0]['accountId']);
      }
      localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));
      let promises: any = [];

      this.accountIds.list.forEach((account: any) => {
        promises.push(this.checkForCFT(account));
      });
      Promise.all(promises).then((values: any) => {
        localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));
      });
    } else {
      this.accountIds.list = [];
      if (
        this.customerType == 'mp' &&
        this.ut == 'admin' &&
        !this.isUserAdmin
      ) {
        this.router.navigate(['/dash/accounts/aws']);
        this.started = false;
      } else {
        localStorage.clear();
        this.notifier.alert(
          'Info',
          '',
          'Accounts not found. Please contact admin',
          'info',
          -1
        );
        this.router.navigate(['/login']);
        return;
      }
    }
    if (this.accountIds.list.length == 0) {
      await this.loadRegions();
    }
  }

  loadAGL() {
    this.accountGroups = JSON.parse(
      localStorage.getItem('selectedAccountGroupData')
    );
    this.clientNames = JSON.parse(
      localStorage.getItem('selectedClientNameData')
    );
    this.accountIds = JSON.parse(localStorage.getItem('accountIdData'));

    this.selectedRegion = localStorage.getItem('selectedRegion');
    //localStorage.setItem("selectedRegion", regions.name);
    localStorage.getItem('selectedRegionId');

    //for (var i = 0; i < this.agl[0].length; i++) {
    //  if()
    //}
    //this.accountGroups = {
    //  selected: this.agl[0].groupName,
    //  list: []
    //};

    //for (let i = 0; i < this.agl.length; i++) {
    //  this.accountGroups.list.push(this.agl[i]);
    //}
  }

  async selectAccountGroup(accountGroup: any, refresh = true) {
    this.accountGroups.selected = accountGroup.groupName;

    this.setAccountGroup(this.accountGroups.selected);
    return;
    let value = accountGroup.accountGroup.accountGroupList.accountGroupDetails;
    console.log('accountGroup', value);

    localStorage.setItem('selectedAccountGroup', this.accountGroups.selected);
    localStorage.setItem(
      'selectedAccountGroupData',
      JSON.stringify(this.accountGroups)
    );

    this.clientNames = {
      selected: this.accountGroups.selected,
      list: value,
    };

    //for (let i = 0; i < this.clientNames.length; i++) {
    //  if (this.clientNames.list[i].cmpName == this.clientNames.selected) {
    this.selectclientName(this.clientNames.list[0], refresh);
    //      break;
    //    }

    //}
  }

  async selectclientName(clientName: any, refresh = true) {
    this.clientNames.selected = clientName.cmpName;

    // if (!clientName.accList || clientName.accList.length == 0) {
    //   this.notifier.alert('Error', 'Error', 'No Account Found!');

    //   this.accountIds = {
    //     selected: '',
    //     list: [],
    //   };
    //   return;
    // }

    // this.accountIds = {
    //   selected: clientName.accList[0].accountName,
    //   list: clientName.accList,
    // };

    localStorage.setItem('selectedClientName', this.clientNames.selected);
    localStorage.setItem(
      'selectedClientNameData',
      JSON.stringify(this.clientNames)
    );

    this.setClientList(clientName.emailId);
    return;
    // this.notifier.changeAccountIdNotification(
    //   this.accountIds.list[0].accountId
    // );

    // this.selectAccountId(this.accountIds.list[0]);
  }

  searchOnList(data: string, pattern: string) {
    if (!pattern) return true;

    if (data.toLowerCase().indexOf(pattern.toLowerCase()) != -1) return true;
    return false;
  }

  checkForDemoAccount() {
    if (this.clientNames['selected'] == 'DemoClient') {
      return true;
    }
    return false;
  }

  getSelectedAccount() {
    return localStorage.getItem('accountId');
  }

  getSelectedRegion() {
    return localStorage.getItem('regionId');
  }

  async selectAccountId(accountId: any) {
    if (accountId['accountId'] == '') {
      return false;
    }

    this.notifier.loading(false);
    if (this.currentRoute.indexOf('/billing') < 0) {
      this.checkingCFT = true;
      let result: any = await this.checkForCFT(accountId, false);
      if (result.status == '1' || result.s == '1') {
        accountId['cft_status'] = true;
      } else {
        accountId['cft_status'] = false;
      }
      this.checkingCFT = false;
    }
    this.notifier.loading(true);

    this.accountIds.selected =
      accountId.accountName +
      ` (${this.checkForDemoAccount() ? '1234567890' : accountId.accountId})`; // accountId.accountName;

    localStorage.setItem('selectedaccountIds', this.accountIds.selected);
    localStorage.setItem('accountId', accountId.accountId);
    localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));

    if (
      !accountId['cft_status'] &&
      accountId['cft_status'] != undefined &&
      this.currentRoute.indexOf('/billing') < 0
    ) {
      this.currentAccountCFTStatus = true;
    }
    if (this.customerType == 'mp') {
      if (accountId['cft_status']) {
        this.started = true;
      } else {
        if (window.location.href.indexOf('accounts/aws') < 0) {
          this.router.navigate(['/dash/accounts/aws']);
        }
        this.started = false;
      }
    }
    await this.loadRegions();
    if (
      !localStorage.getItem('regionId') ||
      localStorage.getItem('regionId') == 'null'
    ) {
      let data = this.accountIds['list'].find((account: any) => {
        return account['accountId'] == localStorage.getItem('accountId');
      });

      if (data && data.regions && data.regions.length > 0) {
        let region: any = this.regions['enabled'].find((region: any) => {
          return region.id == data.regions[0];
        });
        if (region) {
          this.setRegion(region);
        } else {
          await this.setRegion(this.regions['enabled'][0], false);
        }
      } else if (this.regions['enabled'].length > 0) {
        await this.setRegion(this.regions['enabled'][0], false);
      }
    } else {
      this.setRegion(
        this.regions['enabled'].find((region: any) => {
          return region.id == localStorage.getItem('regionId');
        }),
        false
      );
    }
    this.notifier.loading(false);

    if (
      !localStorage.getItem('regionId') ||
      localStorage.getItem('regionId') == 'null'
    ) {
      return false;
    }
    this.checkForMandatory();
    setTimeout(() => {
      this.notifier.changeAccountIdNotification(accountId.accountId);
    }, 100);
    return true;
  }

  getCurrentAccount() {
    let account = localStorage.getItem('accountId');
    if (account) {
      return this.accountIds.list.find((account_: any) => {
        return account_.accountId == account;
      });
    } else {
      return {};
    }
  }

  checkRegion(account: any, region_id: any) {
    return account.regions.indexOf(region_id) > -1;
  }

  async setRegion(regions: any, validate: boolean = true) {
    this.selectedRegion = regions.name;

    localStorage.setItem('selectedRegion', regions.name);
    localStorage.setItem('selectedRegionId', regions.id);
    localStorage.setItem('regionId', regions.id);

    if (validate) {
      this.notifier.changeRegionNotification(regions.id);
    }
  }

  async copyToClipboard(event: any, text: string) {
    event.preventDefault();
    event.stopPropagation();
    if (navigator.clipboard?.writeText) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (e) {
        console.error('Error while copying code', e);
      }
    }
  }

  async logout() {
    this.notifier.loading(true);
    let data = { eId: this.userId, ut: this.urlPrefix };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/logout`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    }

    localStorage.clear();
    setTimeout(() => {
      this.router.navigateByUrl('/login');
      this.notifier.loading(false);
    }, 100);
  }

  hideLeftMenu() {
    $('.leftmenu').addClass('leftmenuhidden');
    $('.rightcontent').addClass('leftmenuhidden');
    $('#menuClosebtn').hide();
    $('#menuShowbtn').show();
  }

  showLeftMenu() {
    $('.leftmenu').removeClass('leftmenuhidden');
    $('.rightcontent').removeClass('leftmenuhidden');
    $('#menuClosebtn').show();
    $('#menuShowbtn').hide();
  }

  // showSearch() {
  //   this.showSearchResult = true;
  // }

  // hideSearch() {
  //   this.showSearchResult = false;
  // }

  // onSearchChange() {
  //   let searchValue = this.searchValue;
  //   if (!searchValue) return;
  //   searchValue = searchValue.toLowerCase();
  //   this.searchResults = this.searchData.items.filter((a: any) => {
  //     if (a.keys.indexOf(searchValue) != -1) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  // }

  goTO(link: string) {
    this.showSearchResult = false;
    this.router.navigateByUrl(link);
  }

  expandDropdown(event: any, value: string) {
    event.stopPropagation();
    this.expanded[value] = !this.expanded[value];
  }

  //for notification
  // notirefe(event: any) {
  //   this.loadNotificationCount();
  // }
  // async loadNotificationCount() {
  //   let data = {};
  //   let header = {
  //     Authorization: 'Bearer ' + localStorage.getItem('t'),
  //   };

  //   let apiURL = `https://apis-eks.swayam.cloud/global_notification/count_notifications/?email_id=${this.userId}&user_type=internal`;
  //   try {
  //     await this.apiServer.postDataPromis(apiURL, data, header).then((data) => {
  //       if (data['total_notification_count'] <= 99) {
  //         this.noticount = data['total_notification_count'];
  //       } else if (data['total_notification_count'] >= 100) {
  //         this.noticount = '99+';
  //       } else {
  //         this.noticount = 0;
  //       }
  //     });
  //   } catch (error) {
  //     this.noticount = 0;
  //   }
  // }

  viewNotification(event: any) {
    this.viewNotificationTrigger = !this.viewNotificationTrigger;
  }
}
