import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-new-instance',
  templateUrl: './new-instance.component.html',
  styleUrls: ['./new-instance.component.css'],
})
export class NewInstanceComponent implements OnInit {
  @Input('instanceObj') instanceObj: any;
  @Input('readOnly') readOnly: boolean;
  userID: string = localStorage.getItem('eId');
  userName: string = localStorage.getItem('un');
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  platformTypes: any;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private router: Router
  ) {}

  serverTypes: any = [
    {
      id: 'appserver',
      name: 'App Server',
    },
    {
      id: 'dbserver',
      name: 'DB Server',
    },
  ];

  dbTypes: any = [
    {
      id: 'MySQL 5.6',
      name: 'MySQL 5.6',
    },
    {
      id: 'MySQL 5.7',
      name: 'MySQL 5.7',
    },
    {
      id: 'MySQL Enterprise',
      name: 'MySQL Enterprise',
    },
    {
      id: 'MS Express',
      name: 'MS Express',
    },
    {
      id: 'MS SQL Web',
      name: 'MS SQL Web',
    },
    {
      id: 'MS SQL Standard',
      name: 'MS SQL Standard',
    },
    {
      id: 'Postgres',
      name: 'Postgres',
    },
    {
      id: 'Oracle EE',
      name: 'Oracle EE',
    },
    {
      id: 'Oracle SE',
      name: 'Oracle SE',
    },
    {
      id: 'Oracle SE1',
      name: 'Oracle SE1',
    },
    {
      id: 'Oracle SE2',
      name: 'Oracle SE2',
    },
    {
      id: 'Aurora Postgres',
      name: 'Aurora Postgres',
    },
    {
      id: 'Aurora MySQL',
      name: 'Aurora MySQL',
    },
  ];

  components: any = [
    {
      id: 'ec2',
      name: 'EC2',
    },
    {
      id: 'rds',
      name: 'RDS',
    },
  ];

  storageTypes: any = [
    {
      id: 'gp3',
      name: 'GP3',
    },
    {
      id: 'gp2',
      name: 'GP2',
    },
  ];

  storageUnits: any = [
    { id: 'GB', name: 'GB' },
    { id: 'TB', name: 'TB' },
  ];

  ngOnInit(): void {
    this.fetchPlatforms();
  }

  hide(event: boolean = false) {
    this.hideModel.emit(event);
  }

  getValue(list: any, id: string) {
    let dt = list.find((element: any) => {
      return element.id == id;
    });
    if (dt) {
      return dt['name'];
    } else {
      return '';
    }
  }

  async fetchPlatforms() {
    let data = {
      action: 'fetchPlatformDetails',
      accountId: localStorage.getItem('accountId'),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.platformTypes = result.billingTypeList;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  addInstance() {
    this.instanceObj['components']['compute']['instances'].push({
      serverType: 'appserver',
      dbPlatform: '',
      component: 'ec2',
      appName: '',
      noOfInstances: 1,
      cpu: '',
      ram: '',
      os: 'Linux',
      storageType: 'gp3',
      storageQty: '100',
      storageUnit: 'GB',
      existing: false,
    });
  }

  addStorage() {
    this.instanceObj['components']['storage']['volumes'].push({
      existing: false,
      name: '',
      id: '',
      type: 'gp3',
      size: '200',
      unit: 'GB',
    });
  }

  removeStorage(index: number) {
    this.instanceObj['components']['storage']['volumes'].splice(index, 1);
  }

  removeInstance(index: number) {
    this.instanceObj['components']['compute']['instances'].splice(index, 1);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == localStorage.getItem('accountId');
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  request_id: string;
  loadingCR: boolean = false;
  async saveAndSendOTP() {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const account = selectedAccountData.data;
        if (!account.jira_org_id || !account.jira_project_id) {
          this.notifier.alert(
            'Info',
            '',
            'Managed Services support via SWAYAM is not enabled for your account. Please contact the admin.',
            'info',
            5000
          );
        } else {
          this.instanceObj['metadata']['emails'] = this.instanceObj['metadata'][
            'emails'
          ].filter((email: string) => {
            return email != '';
          });

          this.instanceObj['metadata']['resource_ids'] =
            this.instanceObj['components']['compute']['instances'].length > 0
              ? [
                  this.instanceObj['components']['compute']['instances'][0][
                    'appName'
                  ],
                ]
              : [];
          this.loadingCR = true;
          let data = {
            action: 'ec2_change_request',
            id_token: localStorage.getItem('idT'),
            cr_data: this.instanceObj,
            group_name: localStorage.getItem('selectedAccountGroup'),
            client_id: localStorage.getItem('selectedClientList'),
            account_id: localStorage.getItem('accountId'),
            region_id: localStorage.getItem('regionId'),
            jira_project_id: account.jira_project_id,
            jira_organization_id: account.jira_org_id,
            login_user_name: localStorage.getItem('un'),
            login_user_email: localStorage.getItem('eId'),
            resource_ids: this.instanceObj['metadata']['resource_ids'],
          };

          let header = {
            Authorization: localStorage.getItem('t'),
          };
          let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

          let result = await this.apiService.postDataPromis(
            apiURL,
            data,
            header
          );

          if (result.status == '1' || result.s == '1') {
            this.request_id = result['request_id'];
          } else {
            this.notifier.alert('Info', '', result.error_message, 'info', 5000);
          }
          this.loadingCR = false;
        }
      }
    } catch (err) {}
  }

  deleteSelectedAdditionalContact(index: number) {
    this.instanceObj['metadata']['emails'].splice(index, 1);
  }

  checkForLast(value: any, index: number) {
    if (
      value != '' &&
      value.length > 0 &&
      this.instanceObj['metadata']['emails'].length - 1 == index
    ) {
      this.instanceObj['metadata']['emails'].push('');
    } else if (
      value == '' &&
      value.length == 0 &&
      this.instanceObj['metadata']['emails'].length - 2 == index &&
      this.instanceObj['metadata']['emails'][
        this.instanceObj['metadata']['emails'].length - 1
      ] == ''
    ) {
      this.instanceObj['metadata']['emails'].splice(
        this.instanceObj['metadata']['emails'].length - 1,
        1
      );
    }
  }

  addAdditionalContact() {
    this.instanceObj['metadata']['emails'].push('');
  }

  trackByFn() {
    return true;
  }

  hideModalCaller(event: any) {
    if (event) {
      this.router.navigate(['/dash/bom/changerequests']);
    }
    this.request_id = null;
  }
}
