<div class="row">
  <div class="interval_container">
    <div>Interval</div>
    <div class="interval_text" [ngClass]="{selected_interval: interval == '6'}" (click)="load('6')">6h</div>
    <div class="interval_text" [ngClass]="{selected_interval: interval == '12'}" (click)="load('12')">12h</div>
    <div class="interval_text" [ngClass]="{selected_interval: interval == '24'}" (click)="load('24')">24h</div>
  </div>
  <div>
    <div id="graphdiv" class="visualGraph" style="width: calc(100%); height: 500px;"></div>
  </div>
</div>