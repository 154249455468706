let data: any = {
  category: [
    { name: 'Services', id: '1' },
    {
      name: 'Features',
      id: '2',
    },
    {
      name: 'Blogs',
      id: '3',
    },
    {
      name: 'Documentation',
      id: '4',
    },
    {
      name: 'Knowledge Articles',
      id: '5',
    },
    {
      name: 'Tutorials',
      id: '6',
    },
    {
      name: 'Events',
      id: '7',
    },
    {
      name: 'Marketplace',
      id: '8',
    },
  ],
  items: [
    {
      name: 'Create Ticket (Cloud Support)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Create+Ticket.svg',
      desc: 'Create Tickets for Minty and AWS Support Tickets',
      categories: [1],
      keys: [
        'ticket',
        'create',
        'create ticket',
        'support',
        'cloud',
        'helpdesk',
        'contact',
      ],
      fullText: '',
      link: '/dash/ticket/create',
    },
    {
      name: 'View Tickets (Cloud Support)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/View+Ticket.svg',
      desc: 'View and Manage Minty and AWS Support Tickets',
      categories: [1],
      keys: [
        'ticket',
        'view',
        'view ticket',
        'support',
        'cloud',
        'helpdesk',
        'contact',
        'list',
      ],
      fullText: '',
      link: '/dash/ticket/list',
    },
    {
      name: 'Knowledge Base (Cloud Support)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Knowledge+Base.svg',
      desc: 'View KB Tickets',
      categories: [1],
      keys: [
        'ticket',
        'view',
        'view ticket',
        'support',
        'cloud',
        'helpdesk',
        'contact',
        'kb',
        'kb base',
      ],
      fullText: '',
      link: '/dash/knowledgebase',
    },
    {
      name: 'EC2 (Operations)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+(Manage+EC2+Instances).svg',
      desc: 'Virtual Server in the Cloud',
      categories: [1],
      keys: ['ec', 'ec2', 'vm', 'virtual', 'server', 'operations'],
      fullText: '',
      link: '/dash/operations/ec2',
    },
    {
      name: 'Tags (Operations)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Tags.svg',
      desc: 'Manage Tags',
      categories: [1],
      keys: ['tags', 'tag'],
      fullText: '',
      link: '/dash/operations/tags',
    },
    {
      name: 'RDS (Operations)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+RDS.svg',
      desc: 'Manage RDS Instances',
      categories: [1],
      keys: ['rds', 'instance', 'database', 'sql', 'mysql', 'engine'],
      fullText: '',
      link: '/dash/operations/rds',
    },
    {
      name: 'EBS (Operations)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+EBS.svg',
      desc: 'Manage EBS Volumes',
      categories: [1],
      keys: ['volumes', 'volume', 'ebs', 'disks', 'disk', 'storage'],
      fullText: '',
      link: '/dash/operations/ebs',
    },
    {
      name: 'Security Groups (Operations)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Security+Group.svg',
      desc: 'Manage Security Groups',
      categories: [1],
      keys: [
        'sg',
        'security',
        'group',
        'security groups',
        'inbound',
        'outbound',
        'rules',
      ],
      fullText: '',
      link: '/dash/operations/sg',
    },
    {
      name: 'Service Intelligence (Operations)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Service+Intelligence.svg',
      desc: '',
      categories: [1],
      keys: ['service', 'service intelligence', 'intelligence'],
      fullText: '',
      link: '/dash/operations/itsi',
    },
    {
      name: 'EC2 (Cost Controls)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/EC2+Cost+Optimization.svg',
      desc: 'Virtual Server in the Cloud',
      categories: [1],
      keys: ['ec', 'ec2', 'virtual', 'server', 'cost', 'optimization'],
      fullText: '',
      link: '/dash/costopt/ec2',
    },
    {
      name: 'Other (Cost Controls)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Other+Cost+Optimization.svg',
      desc: '',
      categories: [1],
      keys: ['other', 'cost', 'optimization'],
      fullText: '',
      link: '/dash/costopt/other',
    },
    {
      name: 'Floating Report (Cost Controls)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Floating+Report.svg',
      desc: '',
      categories: [1],
      keys: ['floating', 'orphan', 'cost', 'optimization'],
      fullText: '',
      link: '/dash/costopt/floating',
    },
    {
      name: 'EBS (Compliance)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+EBS.svg',
      desc: '',
      categories: [1],
      keys: ['compliance', 'ebs', 'encrypted', 'volumes', 'disks', 'storage'],
      fullText: '',
      link: '/dash/compliance/ebs',
    },
    {
      name: 'S3 (Compliance)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+S3.svg',
      desc: '',
      categories: [1],
      keys: [
        'compliance',
        's3',
        'public',
        'object',
        'objects',
        'bucket',
        'buckets',
        'storage',
      ],
      fullText: '',
      link: '/dash/compliance/ebs',
    },
    {
      name: 'Cloud Trail (Compliance)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Cloud+Trail.svg',
      desc: '',
      categories: [1],
      keys: ['cloud trail', 'cloud', 'trail', 'audit', 'activity'],
      fullText: '',
      link: '/dash/compliance/cloudtrail',
    },
    {
      name: 'Config (Compliance)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Settings.svg',
      desc: '',
      categories: [1],
      keys: ['config', 'compliance'],
      fullText: '',
      link: '/dash/compliance/config',
    },
    {
      name: 'IAM (Compliance)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+IAM.svg',
      desc: '',
      categories: [1],
      keys: [
        'security',
        'compliance',
        'iam',
        'user',
        'users',
        'offender',
        'offenders',
      ],
      fullText: '',
      link: '/dash/compliance/iam',
    },
    {
      name: 'Tags (Compliance)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Tags.svg',
      desc: '',
      categories: [1],
      keys: ['tag', 'compliance', 'tags'],
      fullText: '',
      link: '/dash/compliance/tags',
    },
    {
      name: 'Security Groups (Compliance)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Security+Group.svg',
      desc: 'Manage Security Groups',
      categories: [1],
      keys: [
        'sg',
        'security',
        'group',
        'security groups',
        'inbound',
        'outbound',
        'rules',
        'port',
      ],
      fullText: '',
      link: '/dash/compliance/sg',
    },
    {
      name: 'Path Report (Compliance)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Patch+Report.svg',
      desc: '',
      categories: [1],
      keys: ['patch', 'report', 'compliance'],
      fullText: '',
      link: '/dash/compliance/patch',
    },
    {
      name: 'Trusted Advisor (Compliance)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Trusted+Advisor.svg',
      desc: '',
      categories: [1],
      keys: ['advisor', 'trusted', 'security', 'compliance', 'trusted advisor'],
      fullText: '',
      link: '/dash/compliance/advisor',
    },
    {
      name: 'Inspector (Compliance)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Inspector.svg',
      desc: '',
      categories: [1],
      keys: ['inspector', 'security', 'compliance'],
      fullText: '',
      link: '/dash/compliance/inspector',
    },
    {
      name: 'Security Hub (Compliance)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Compliance+Security+Hub.svg',
      desc: '',
      categories: [1],
      keys: ['hub', 'security hub', 'security', 'compliance'],
      fullText: '',
      link: '/dash/compliance/securityhub',
    },
    {
      name: 'Created vs Resolved (SLA Report)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(Created+vs+Resolved).svg',
      desc: '',
      categories: [1],
      keys: [
        'support',
        'cloud',
        'help desk',
        'created',
        'resolved',
        'created vs resolved',
        'sla',
        'report',
        'sla report',
      ],
      fullText: '',
      link: '/dash/sla/createdvsresolved',
    },
    {
      name: 'First Response SLA (SLA Report)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(First+Response+SLA).svg',
      desc: '',
      categories: [1],
      keys: [
        'support',
        'cloud',
        'help desk',
        'met',
        'breached',
        'met vs breached',
        'sla',
        'report',
        'first',
        'first response',
        'sla report',
      ],
      fullText: '',
      link: '/dash/sla/firstresponsesla',
    },
    {
      name: 'Resolution SLA (SLA Report)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/SLA+Report+(Resolution+SLA).svg',
      desc: '',
      categories: [1],
      keys: [
        'support',
        'cloud',
        'help desk',
        'met',
        'breached',
        'met vs breached',
        'sla',
        'report',
        'resolution',
        'resolution sla',
        'sla report',
      ],
      fullText: '',
      link: '/dash/sla/resolutionsla',
    },
    {
      name: 'Monitoring',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Monitoring.svg',
      desc: 'Monitor Metrics of your cloud resources',
      categories: [1],
      keys: ['monitoring', 'cloud', 'resources'],
      fullText: '',
      link: '/dash/monitoring',
    },
    {
      name: 'RI Coverage Report (CBS)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg',
      desc: 'Central Billing System',
      categories: [1],
      keys: [
        'central',
        'billing',
        'system',
        'bills',
        'report',
        'ri',
        'coverage',
      ],
      fullText: '',
      link: '/dash/cbs/ricoverage',
    },
    {
      name: 'RI Purchased Report (CBS)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg',
      desc: 'Central Billing System',
      categories: [1],
      keys: [
        'central',
        'billing',
        'system',
        'bills',
        'report',
        'ri',
        'purchased',
      ],
      fullText: '',
      link: '/dash/cbs/ripurchased',
    },
    {
      name: 'Minfy RI Account Details (CBS)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg',
      desc: 'Central Billing System',
      categories: [1],
      keys: [
        'central',
        'billing',
        'system',
        'bills',
        'report',
        'ri',
        'account',
        'minfy',
      ],
      fullText: '',
      link: '/dash/cbs/minfyri',
    },
    {
      name: 'Organizations (CBS)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg',
      desc: 'Central Billing System',
      categories: [1],
      keys: [
        'central',
        'billing',
        'system',
        'cbs',
        'bills',
        'organizations',
        'org',
      ],
      fullText: '',
      link: '/dash/cbs/organizations',
    },
    {
      name: 'Master Summary (CBS)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg',
      desc: 'Central Billing System',
      categories: [1],
      keys: [
        'central',
        'billing',
        'system',
        'cbs',
        'bills',
        'master',
        'summary',
      ],
      fullText: '',
      link: '/dash/cbs/mastersummary',
    },
    {
      name: 'RI Order Report (CBS)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg',
      desc: 'Central Billing System',
      categories: [1],
      keys: [
        'central',
        'billing',
        'system',
        'cbs',
        'ri',
        'bills',
        'order',
        'report',
      ],
      fullText: '',
      link: '/dash/cbs/riorder',
    },
    {
      name: 'Enable CFRC Report (CBS)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg',
      desc: 'Central Billing System',
      categories: [1],
      keys: ['central', 'billing', 'system', 'cbs', 'enable', 'cfrc', 'report'],
      fullText: '',
      link: '/dash/cbs/cfrc/enable',
    },
    {
      name: 'CFRC Order Report (CBS)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg',
      desc: 'Central Billing System',
      categories: [1],
      keys: [
        'central',
        'billing',
        'system',
        'cbs',
        'view',
        'cfrc',
        'order',
        'report',
      ],
      fullText: '',
      link: '/dash/cbs/cfrc/view',
    },
    {
      name: 'Enable Master (CBS)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg',
      desc: 'Central Billing System',
      categories: [1],
      keys: ['central', 'billing', 'system', 'cbs', 'master', 'enable'],
      fullText: '',
      link: '/dash/cbs/masterenable',
    },
    {
      name: 'View Discount (CBS)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg',
      desc: 'Central Billing System',
      categories: [1],
      keys: ['central', 'billing', 'system', 'cbs', 'view', 'discount'],
      fullText: '',
      link: '/dash/cbs/discount',
    },
    {
      name: 'Dashboard (Anomalies)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg',
      desc: 'Manage Monitors, Anomalies and Alarms',
      categories: [1],
      keys: [
        'anomalies',
        'dashboard',
        'alarm',
        'monitor',
        'anomaly',
        'anomalies',
      ],
      fullText: '',
      link: '/dash/anomalies/dashboard',
    },
    {
      name: 'Trigger History (Anomalies)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/History.svg',
      desc: 'Manage Monitors, Anomalies and Alarms',
      categories: [1],
      keys: [
        'anomalies',
        'history',
        'alarm',
        'monitor',
        'anomaly',
        'anomalies',
        'trigger',
      ],
      fullText: '',
      link: '/dash/anomalies/history',
    },
    {
      name: 'Exceptions (Anomalies)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Exceptions.svg',
      desc: 'Manage Monitors, Anomalies and Alarms',
      categories: [1],
      keys: [
        'anomalies',
        'exceptions',
        'alarm',
        'monitor',
        'anomaly',
        'anomalies',
        'exception',
      ],
      fullText: '',
      link: '/dash/anomalies/exceptions',
    },
    {
      name: 'Update BOM (BOM)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Upload+BOM.svg',
      desc: 'Upload BOM',
      categories: [1],
      keys: ['bom', 'upload'],
      fullText: '',
      link: '/dash/bom/upload',
    },
    {
      name: 'View BOM Details (BOM)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/View+BOM+Details.svg',
      desc: 'Manage BOM Detais',
      categories: [1],
      keys: ['bom', 'view', 'manage', 'update'],
      fullText: '',
      link: '/dash/bom/view',
    },
    {
      name: 'Delta Report (BOM)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Delta+Report.svg',
      desc: 'Manage Delta Report',
      categories: [1],
      keys: ['bom', 'delta', 'report'],
      fullText: '',
      link: '/dash/bom/deltareport',
    },
    {
      name: 'Backup Report',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Backup.svg',
      desc: 'Manage Backup Reports',
      categories: [1],
      keys: ['snapshot', 'backup', 'report'],
      fullText: '',
      link: '/dash/backupreport',
    },
    {
      name: 'Monthly Bill (Billing)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg',
      desc: 'View Billing Report Month Wise',
      categories: [1],
      keys: ['bills', 'billing', 'month', 'monthly'],
      fullText: '',
      link: '/dash/billing',
    },
    {
      name: 'RI Order Report (Billing)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg',
      desc: '',
      categories: [1],
      keys: ['bills', 'ri', 'order', 'report', 'billing'],
      fullText: '',
      link: '/dash/billing/ri',
    },
    {
      name: 'View Reports (Reports)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg',
      desc: '',
      categories: [1],
      keys: [
        'view',
        'report',
        'reports',
        'mis',
        'inventory',
        'uptime',
        'security',
        'ticket',
      ],
      fullText: '',
      link: '/dash/reports/view',
    },
    {
      name: 'Enable Reports (Reports)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Billing.svg',
      desc: '',
      categories: [1],
      keys: ['enable', 'report', 'reports'],
      fullText: '',
      link: '/dash/reports/enable',
    },
    {
      name: 'Users (Settings)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/User.svg',
      desc: '',
      categories: [1],
      keys: ['user', 'users', 'user', 'manage'],
      fullText: '',
      link: '/dash/settings/users/view',
    },
    {
      name: 'EC2 Groups (Settings)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/User.svg',
      desc: '',
      categories: [1],
      keys: ['ec2', 'group', 'groups', 'instance'],
      fullText: '',
      link: '/dash/settings/ec2group/view',
    },
    {
      name: 'Policies (Settings)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/User.svg',
      desc: '',
      categories: [1],
      keys: ['policy', 'policies', 'rule', 'rules', 'iam'],
      fullText: '',
      link: '/dash/settings/policies/view',
    },
    {
      name: 'Audit Trail (Settings)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/User.svg',
      desc: '',
      categories: [1],
      keys: ['audit', 'trail', 'audit trail'],
      fullText: '',
      link: '/dash/settings/audittrail',
    },
    {
      name: 'User Management (Settings)',
      icon: 'https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/User.svg',
      desc: '',
      categories: [1],
      keys: ['user', 'management', 'password', 'reset'],
      fullText: '',
      link: '/dash/settings/profile',
    },
  ],
};

export default data;
