import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

@Component({
  selector: 'updaterule-anomalies',
  templateUrl: './updaterule.anomalies.component.html',
  styleUrls: ['./updaterule.anomalies.component.css'],
})
export class CreateExceptionAnomaliesComponent implements OnInit, OnDestroy {
  additionalContacts: string[] = [''];
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  regionId: any;
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  @Input() rule: any;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.rule = { ...this.rule };
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
    });
  }

  ngOnDestroy(): void {}

  trackByFn() {
    return true;
  }

  close() {
    this.hideModel.emit(this.rule);
  }

  async createAlarm() {
    this.notifier.loading(true);
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { swayam_site_247_project_name, swayam_site_247_project_id } =
          selectedAccountData.data;
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        let data = {
          action: this.rule.exception_type,
          project_name: swayam_site_247_project_name,
          monitor_name: this.rule.monitor_name,
          time_range: this.rule.time_range,
          attribute_name: this.rule.attribute_name,
          monitor_id: this.rule.monitor_id,
          zaaid: swayam_site_247_project_id,
          monitor_type: this.rule.monitor_type,
          exception_name: this.rule.exception_name,
          threshold: this.rule.threshold,
        };
        //https://api.swayam.cloud/v3/admin/support
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/anomalies`;

        let result = await this.apiService.postDataPromis(apiURL, data, header);

        if (result.status == '1' || result.s == '1') {
          this.notifier.alert('Success', '', result.message, 'success', 5000);
          this.hideModel.emit(false);
        } else {
          this.notifier.alert('Info', '', result.error, 'info', 5000);
        }
      } else {
      }
    } catch (err) {}
    this.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  checkForLast(value: any, index: number) {
    if (
      value != '' &&
      value.length > 0 &&
      this.additionalContacts.length - 1 == index
    ) {
      this.additionalContacts.push('');
    } else if (
      value == '' &&
      value.length == 0 &&
      this.additionalContacts.length - 2 == index &&
      this.additionalContacts[this.additionalContacts.length - 1] == ''
    ) {
      this.additionalContacts.splice(this.additionalContacts.length - 1, 1);
    }
    console.log(this.additionalContacts);
  }

  addAdditionalContact() {
    this.additionalContacts.push('');
  }
}
