import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

@Component({
  styleUrls: ['./createscan.amis.component.css'],
  selector: 'createscan-amis',
  templateUrl: './createscan.amis.component.html',
})
export class CreateScanAMIsReportComponent implements OnInit, OnDestroy {
  @Output() hideModal = new EventEmitter<any>();
  @Input() description: string;
  @Input() message: any;
  @Input() data: any;
  uid = localStorage.getItem('t');
  t = localStorage.getItem('t');
  ut = localStorage.getItem('ut');
  eId = localStorage.getItem('eId');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  scan_name: string = '';

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  async yes() {
    await this.createJob();
    return;
  }

  no(check: boolean = false) {
    this.hideModal.emit(check);
  }

  async createJob() {
    var format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

    if (format.test(this.scan_name)) {
      this.notifier.alert(
        'Error',
        '',
        'Special Characters are not allowed.',
        'error',
        5000
      );
      return;
    }
    if (this.scan_name == '') {
      this.notifier.alert('Info', '', 'Scan Name is required.', 'info', 5000);
      return;
    }
    this.notifier.loading(true);
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      action: 'create_ami_scan_job',
      login_user_name: this.eId,
      account_id: this.data.account_id,
      region_name: this.data.region_name,
      scan_name: this.scan_name,
    };

    let apiUrl: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/amis`;

    let result = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (result.status == '1' || result.s == '1') {
      result.message = result.message || result.msg;
      this.message = this.message.replace('[message]', result.message);
      this.notifier.alert('success', '', this.message, 'success', 5000);
      this.no(true);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }
}
