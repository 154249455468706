import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as echarts from 'echarts';

import * as moment from 'moment';

declare let flatpickr: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './dashboard.anomalies.component.html',
})
export class dashboardAnomaliesComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  monitors: any = [];
  activeMonitor: any = null;
  anomalies: any = [];
  dateRange: string;
  activeAnomaly: any = null;
  anomalyDetail: any;
  barchartdata: any = {};
  createAlarm: any;

  funRef: any;

  accountId: any;
  regionId: any;
  currentMessage: any;
  createTicket: any;

  showInnerData: boolean = false;
  monitorsHeader: any = [
    {
      id: 'display_name',
      name: 'Monitor Name',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
    {
      id: 'monitor_type',
      name: 'Monitor Type',
    },
    { id: 'formatted_attribute', name: 'Anomaly Attribute' },
    { id: 'total_count', name: 'Total Count' },
  ];

  anomaliessHeader: any = [
    {
      id: 'display_name',
      name: 'Monitor Name',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showAnomaly',
    },
    {
      id: 'monitor_type',
      name: 'Monitor Type',
    },
    { id: 'severity', name: 'Severity' },
    { id: 'formatted_attribute', name: 'Anomaliy Attribute' },
    { id: 'present_value', name: 'Present Value' },
    { id: 'base_value', name: 'Base Value' },
    { id: 'time', name: 'Created Time' },
    { id: 'deviation', name: 'Devaition' },
    { id: 'decision', name: 'Decision' },
  ];
  showGraph: boolean;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.funRef = {
      showData: this.showData,
      showAnomaly: this.showAnomaly,
    };

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    setTimeout(() => {
      let todays_date = new Date();
      let n = 6;
      if (todays_date.getMonth() < n)
        todays_date.setFullYear(todays_date.getFullYear() - 1);
      todays_date.setMonth((todays_date.getMonth() + n) % 12);
      let start_date = moment(todays_date)
        .startOf('month')
        .format('DD-MMM-YYYY');
      let end_date = moment().startOf('day').format('DD-MMM-YYYY');
      this.dateRange = start_date + ' to ' + end_date;
      flatpickr('.datetimepicker', {
        dateFormat: 'd-M-Y',
        mode: 'range',
        maxDate: new Date(),
        defaultDate: [start_date, end_date],
        showMonths: 2,
        onChange: (selectedDates: any, dateStr: any, instance: any) => {
          if (!dateStr.includes('to')) return;
          this.dateRange = dateStr;
          this.load();
        },
      });
    }, 500);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  createAlarmTrigger(monitor: any) {
    this.createAlarm = monitor;
  }

  async load() {
    this.notifier.loading(true);
    setTimeout(async () => {
      await this.loadMonitors();
      this.notifier.loading(false);
    }, 1000);
  }

  async loadMonitors() {
    console.log(this.dateRange);
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { site_247_project_id } = selectedAccountData.data;
        let start_date;
        let end_date;
        if (!(this.dateRange && this.dateRange.includes('to'))) {
          start_date = moment().startOf('month').format('YYYY-MM-DD');
          end_date = moment().startOf('day').format('YYYY-MM-DD');
        } else {
          let dRange = this.dateRange.toString().split(' to ');
          start_date = moment(dRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD');
          end_date = moment(dRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD');
        }
        let data = {
          action: 'anomalies_summary',
          zaaid: site_247_project_id,
          start_time: start_date,
          end_time: end_date,
        };
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        // https://api.swayam.cloud/v3/admin/support
        // let apiURL = `https://api.swayam.cloud/prod/msopsusers/anomalies?action=anomalies_summary&zaaid=${site_247_project_id}&start_time=${start_date}&end_time=${end_date}`;
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/anomalies`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        console.log(result);

        if (result.status == '1' || result.s == '1') {
          if (result.summary.length == 0) {
            this.notifier.alert('Info', '', 'No Monitors Found', 'info', 5000);
            this.monitors = [];
            await this.loadBarChartData();
            return;
          }
          this.monitors = result.summary;
          if (this.monitors.length == 0) {
            return;
          }
          await this.loadBarChartData();
          this.monitors.forEach((monitor: any, index: number) => {
            this.loadAlarmData(
              site_247_project_id,
              monitor.monitor_id,
              monitor.monitor_type,
              monitor.attribute_name,
              index
            );
          });
        } else {
          this.notifier.alert('Info', '', result.error_message, 'info', 5000);
        }
      } else {
        this.notifier.alert(
          'Error',
          '',
          selectedAccountData.msg,
          'error',
          5000
        );
      }
    } catch (err: any) {
      this.notifier.alert('Info', '', err.msg, 'info', 5000);
    }
  }

  async raiseTicket(annomaly: any) {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    this.createTicket = await this.loadAnomaly(this, annomaly.sno);
    this.createTicket['description'] = this.createTicket.anomaly_comment;
    this.notifier.loading(false);
  }

  hideCreateTicket(event: any) {
    this.createTicket = null;
  }

  async loadBarChartData() {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { site_247_project_id } = selectedAccountData.data;
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        let start_date;
        let end_date;
        if (!(this.dateRange && this.dateRange.includes('to'))) {
          start_date = moment().startOf('month').format('YYYY-MM-DD');
          end_date = moment().startOf('day').format('YYYY-MM-DD');
        } else {
          let dRange = this.dateRange.toString().split(' to ');
          start_date = moment(dRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD');
          end_date = moment(dRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD');
        }
        let data = {
          action: 'bar_chart_data',
          zaaid: site_247_project_id,
          start_time: start_date,
          end_time: end_date,
        };
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/anomalies`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        console.log(result);

        if (result.status == '1' && result.indexes) {
          this.barchartdata = result.indexes;
          if (this.barchartdata && this.barchartdata.length > 0) {
            this.loadGraph();
          } else {
            this.showGraph = false;
          }
        } else {
          this.notifier.alert('Info', '', result.error_message, 'info', 5000);
        }
      } else {
        this.notifier.alert(
          'Error',
          '',
          selectedAccountData.msg,
          'error',
          5000
        );
      }
    } catch (err: any) {
      console.log(err);
      this.notifier.alert('Info', '', err.msg, 'info', 5000);
    }
  }

  async loadAlarmData(
    zaaid: String,
    monitor_id: String,
    monitor_type: String,
    attribute_name: String,
    index: number
  ) {
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let data = {
      action: 'get_alarm',
      project_id: zaaid,
      monitor_id: monitor_id,
      monitor_type: monitor_type,
      attribute_name: attribute_name,
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/anomalies`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.showGraph = true;
      if (result.message) {
        this.monitors[index] = { ...this.monitors[index], ...result.message };
        this.monitors[index].status = result.message.alarm_status == 'true';
      } else {
        this.monitors[index].status = false;
        this.monitors[index] = {
          ...this.monitors[index],
          ...{ alarm_status: 'true', status: false },
        };
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  async loadAnomalies(ref: any) {
    // let data = {
    //   action: 'jira_comm',
    //   issue_key: ref.activeTicket.issue_key,
    // };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let start_date;
    let end_date;
    if (!(this.dateRange && this.dateRange.includes('to'))) {
      start_date = moment().startOf('month').format('YYYY-MM-DD');
      end_date = moment().startOf('day').format('YYYY-MM-DD');
    } else {
      let dRange = this.dateRange.toString().split(' to ');
      start_date = moment(dRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD');
      end_date = moment(dRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD');
    }

    let data = {
      action: 'get_anomalies',
      zaaid: ref.activeMonitor.zaaid,
      start_time: start_date,
      end_time: end_date,
      monitor_type: ref.activeMonitor.monitor_type,
      attribute_name: ref.activeMonitor.attribute_name,
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/anomalies`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      ref.anomalies = result.anomalies;
    } else {
      ref.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  async loadAnomaly(ref: any, sno: any = false) {
    // let data = {
    //   action: 'jira_comm',
    //   issue_key: ref.activeTicket.issue_key,
    // };
    let sno_ = sno;
    if (!sno && ref.activeAnomaly && ref.activeAnomaly.sno) {
      sno_ = ref.activeAnomaly.sno;
    }
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      action: 'get_anomaly',
      sno: sno_,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/anomalies`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      if (sno) {
        return result.anomaly_details;
      } else {
        ref.anomalyDetail = result.anomaly_details;
      }
    } else {
      ref.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  async ignoreAnomaly(annomaly: any) {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    // let anomalies_list: number[] = [annomaly.sno];
    // console.log(anomalies_list);
    let anomalies_list = [annomaly.sno];
    let data = {
      action: 'ignore_anomalies',
      anomalies_list: anomalies_list,
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/anomalies`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.data.message, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  goBackToMonitor() {
    this.activeMonitor = null;
    this.anomalies = [];
    this.loadGraph();
  }

  goBackToAnomaly() {
    this.activeAnomaly = null;
    this.anomalyDetail = null;
  }

  async showData(ref: any, id: any) {
    ref.activeMonitor = id;
    ref.notifier.loading(true);
    await ref.loadAnomalies(ref);
    ref.notifier.loading(false);
  }

  hideModel(event: any) {
    this.createAlarm.alarm_status = event;
    this.createAlarm = null;
  }

  async showAnomaly(ref: any, id: any) {
    ref.activeAnomaly = id;
    ref.notifier.loading(true);
    await ref.loadAnomaly(ref);
    ref.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  loadGraph() {
    var chartDom = document.getElementById('graphdiv');
    var myChart = echarts.init(chartDom);

    //let option = {
    //  color: [
    //    "#1976d2",
    //    "#42a5f5"
    //  ],
    //  title: {
    //    //text: 'Referer of a Website',
    //    //subtext: 'Fake Data',
    //    left: 'center'
    //  },
    //  tooltip: {
    //    trigger: 'item'
    //  },
    //  legend: {
    //    orient: 'vertical',
    //    left: 'left'
    //  },
    //  series: [
    //    {
    //      // name: 'Access From',  #42a5f5 #1976d2
    //      type: 'pie',
    //      radius: '50%',
    //      data: [
    //        { value: 1048, name: 'Used', color: "#42a5f5" },
    //        { value: 735, name: 'Free', color: "#1976d2" }
    //      ],
    //      emphasis: {
    //        itemStyle: {
    //          shadowBlur: 10,
    //          shadowOffsetX: 0,
    //          shadowColor: 'rgba(0, 0, 0, 0.5)'
    //        }
    //      }
    //    }
    //  ]
    //};

    let labelData = this.barchartdata.map((a: any) => a.attribute_name);
    let finalGraphData: any = [];
    let colors: any = [
      'rgb(0, 143, 251)',
      'rgb(0, 227, 150)',
      'rgb(254, 176, 25)',
    ];
    for (let i = 0; i < this.barchartdata.length; i++) {
      finalGraphData.push({
        value: this.barchartdata[i].count,
        itemStyle: { color: colors[i % 3] },
      });
    }
    // this.barchartdata.forEach((monitor: any) => {
    //   finalGraphData.push({
    //     value: monitor.count,
    //     itemStyle: { color: 'blue' },
    //   });
    // });
    let option: any = {
      color: ['rgb(0, 143, 251)', 'rgb(0, 227, 150)', 'rgb(254, 176, 25)'],
      xAxis: {
        type: 'category',
        data: labelData,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: finalGraphData,
          type: 'bar',
        },
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        position: function (
          pos: any,
          params: any,
          el: any,
          elRect: any,
          size: any
        ) {
          var obj: any = { top: 10 };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        },
        extraCssText: 'width: 170px',
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: false,
          },
          brush: {
            type: ['lineX', 'clear'],
          },
        },
      },
    };

    option && myChart.setOption(option);
  }
}
