import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import { FileUploadService } from 'src/app/fileupload/fileupload.service';

declare let flatpickr: any;

@Component({
  selector: 'po-report-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
})
export class AddPOReportComponent implements OnInit {
  @Input('entry') entry: any;
  @Input('action') action: any;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();
  files: any = [];
  constructor(
    private apiService: APIService,
    private fileUploadService: FileUploadService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.entry = JSON.parse(JSON.stringify(this.entry));

    this.entry['receivers'] = this.entry['receivers'].map((email: any) => {
      return { email: email };
    });

    if (this.entry['docs'] && this.entry['docs'].length > 0) {
      this.entry['docs'].forEach((file: string) => {
        this.files.push({
          name: file,
          type: 'old',
        });
      });
    }

    flatpickr('.datetimepicker', {
      dateFormat: 'd-m-Y',
      mode: 'range',
      showMonths: 2,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.entry['startDate'] = dateStr.split(' to ')[0];
        this.entry['expiryDate'] = dateStr.split(' to ')[1];
      },
    });

    if (this.action == 'update') {
      setTimeout(() => {
        $('.datetimepicker').val(
          `${this.entry['startDate']} to ${this.entry['expiryDate']}`
        );
      }, 10);
    }
  }

  humanFileSize(bytes: number, si: boolean = false, dp: number = 1) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;
    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );
    return bytes.toFixed(dp) + ' ' + units[u];
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  addFile() {
    this.documents.nativeElement.click();
  }

  filesChange(event: any) {
    Array.from(event.target.files).forEach((file: any) => {
      this.files.push({
        name: file['name'],
        type: 'new',
        file: file,
      });
    });
    this.documents.nativeElement.value = '';
  }

  async updateEntry() {
    let docs: any = [];

    this.notifier.loading(true);
    if (this.files.length > 4) {
      this.notifier.alert(
        'Info',
        '',
        'Max of 4 documents allowed',
        'info',
        5000
      );
      this.notifier.loading(false);

      return;
    } else if (this.files.length > 0) {
      let newFiles: any = this.files.filter((file: any) => {
        return file['type'] == 'new';
      });
      let oldFiles: any = this.files.filter((file: any) => {
        return file['type'] == 'old';
      });
      if (newFiles.length > 0) {
        docs = await this.getPresignedURLs(newFiles);
        docs = [...oldFiles.map((file: any) => file.name), ...docs];
      } else {
        docs = this.files.map((file: any) => file.name);
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        'Atleast one document is required',
        'info',
        5000
      );
      this.notifier.loading(false);

      return;
    }
    let data: any = {
      a: this.action,
      alert: {
        uuid: this.action == 'add' ? undefined : this.entry.uuid,
        customerName: this.entry.customerName,
        docNumber: this.entry.docNumber || '-',
        startDate: this.entry.startDate,
        expiryDate: this.entry.expiryDate,
        receivers: this.entry.receivers
          .map((email: any) => {
            return email['email'].trim();
          })
          .filter((email: string) => {
            return email != '';
          }),
        docs: docs,
      },
    };

    let apiURL: string = `${APIService.API_ENDPOINTV3}/admin/billing/poalerts`;

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.s == '1' || result.status == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.hideModel.emit(true);
    }

    this.notifier.loading(false);
  }

  removeEmail(index: number) {
    this.entry['receivers'].splice(index, 1);
  }

  addEmail() {
    this.entry['receivers'].push({
      email: '',
    });
  }

  @ViewChild('documents') documents: any;

  reset() {
    this.documents.nativeElement.value = '';
    this.files = [];
  }

  async getPresignedURLs(files: any) {
    return new Promise(async (resolve: any, reject: any) => {
      let data: any = {
        a: 'getpreurls',
        files: files
          .filter((file: any) => file.type == 'new')
          .map((file: any) => file.name),
      };

      let apiURL: string = `${APIService.API_ENDPOINTV3}/admin/billing/poalerts`;

      let header: any = {
        Authorization: localStorage.getItem('t'),
      };

      let result: any = await this.apiService.postDataPromis(
        apiURL,
        data,
        header
      );

      if (result.status == '1' || result.s == '1') {
        let promises: any = [];
        result.preUrls.forEach((url: any, index: number) => {
          promises.push(this.uploadFile(url, files[index]));
        });
        Promise.all(promises).then((values: any) => {
          resolve(result.preUrls.map((url: any) => url.name));
        });
      }
    });
  }

  async uploadFile(url: any, file: any) {
    return new Promise(async (resolve: any, reject: any) => {
      this.fileUploadService
        .uploadFile(url['url'], file.file)
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              file['status'] = 'uploading';
              let progress = Math.round((event.loaded / event.total) * 100);
              file.loaded = this.humanFileSize(event.loaded);
              file.total = this.humanFileSize(event.total);
              file.percentage = progress;
              break;
            case HttpEventType.Response:
              file['status'] = 'uploaded';
              resolve(true);
          }
        });
    });
  }
}
