<div class="card">
  <h5 class="card-header">PnL Summary</h5>
  <div class="card-body">
    <div class="main-page">
      <button class="btn btn-light" (click)="exportToCSV(result_data)">
        Download CSV
      </button>
    </div>
    <div class="data-table">
      <div class="table-container">
        <table class="table table-docs table-hover">
          <thead class="table-header">
            <tr>
              <th>Details</th>
              <th *ngFor="let d of dates">
                {{ d | customDate }}
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr *ngFor="let h of colHeader">
              <td>{{ h.name }}</td>
              <td *ngFor="let d of dates">
                <!-- <ng-container *ngIf="h.link; else elseBlock"> -->
                <a
                  *ngIf="h.link; else elseBlock"
                  class="nav-link"
                  routerLink="/cbs/pnl-report"
                  [queryParams]="{ 'month-year': d }"
                >
                  <span [ngStyle]="{ color: 'blue', 'vertical-align': 'left' }">
                    {{
                      h.alter
                        ? h.alter(result_data[d], h.id)
                        : result_data[d][h.id]
                    }}
                  </span>
                </a>
                <!-- </ng-container> -->
                <ng-template #elseBlock>
                  <span>
                    {{
                      h.alter
                        ? h.alter(result_data[d], h.id)
                        : result_data[d][h.id]
                    }}
                  </span>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
