import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { APIService } from '../../../../../api/api.service';
import { NotifierService } from '../../../../../_services/notifier.service';

@Component({
  selector: 'consolidated-infra-report',
  templateUrl: './consolidatedreport.component.html',
})
export class ConsolicatedReportComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input('reportType') reportType: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = false;
  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  reportStatus: any = {};

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        setTimeout(() => {
          this.load();
        }, 100);
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        setTimeout(() => {
          this.load();
        }, 100);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.load();
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  downloading: Boolean = true;
  async load() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    let client_id;
    if (this.urlPrefix == 'admin') {
      client_id = localStorage.getItem('selectedClientList');
    } else {
      client_id = localStorage.getItem('clientId');
    }
    let data: any = {
      a: 'fetchConsolidatedReport',
      cId: client_id,
      uId: this.userId,
    };

    if (this.reportType == 'compliance_report') {
      data['a'] = 'fetchConsolidatedReport';
    }
    if (this.reportType == 'infra_report') {
      data['a'] = 'fetchComplianceReport';
    }
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/reports/fetchconsoliatedreports`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' && result.sUrl) {
      this.reportStatus = result;
      this.reportStatus['status'] = 'COMPLETED';
    } else {
      this.reportStatus = result;
    }
    this.loading = false;
  }

  downloadReport() {
    if (this.reportStatus && this.reportStatus.sUrl) {
      var link = document.createElement('a');
      let client_id;
      if (this.urlPrefix == 'admin') {
        client_id = localStorage.getItem('selectedClientList');
      } else {
        client_id = localStorage.getItem('clientId');
      }
      link.target = '_blank';
      link.download = `Infra Report - ${client_id}`;
      link.href = this.reportStatus.sUrl;
      link.click();
    }
  }
}
