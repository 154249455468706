<div style="display: flex; justify-content: flex-end; align-items: center; gap: 8px; margin: 10px 0px;">
  <div>
    Dollar Value: <span>{{dollarValue.value}} <span
        style="text-transform: capitalize">({{dollarValue.type}})</span></span>
  </div>
  <button *ngIf="getSelectedInstances().length >  0" type="button" class="btn btn-light"
    (click)="launchSelected()">Launch
    ({{getSelectedInstances().length}})</button>
  <button class="btn btn-primary-soft" (click)="load()">
    Refresh
  </button>
  <button class="btn btn-primary-soft" (click)="changeRequest({}, 'New Instance')">
    <i class="fa fa-plus" aria-hidden="true"></i>
    New CR
  </button>
</div>
<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button class="nav-link" [ngClass]="{active: index == 0}" *ngFor="let header of selectedHeaders; let index = index"
      [attr.id]="header.id + '_' + index+'-tab'" data-bs-toggle="tab"
      [attr.data-bs-target]="'#'+header.id + '_' + index" type="button" role="tab"
      [attr.aria-controls]="header.id + '_' + index" aria-selected="true"
      style="display: flex; align-items: center; gap: 8px;">
      <span style="color: inherit;">{{header.key}}</span>
      <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
      <span style="color: inherit;">{{header.value}}</span>
    </button>
  </div>
</nav>
<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show" [ngClass]="{active: index == 0}"
    *ngFor="let header of selectedHeaders; let index = index" [attr.id]="header.id + '_' + index" role="tabpanel"
    [attr.aria-labelledby]="header.id + '_' + index+'-tab'">
    <div class="pricing_strategy_container">
      <div class="pricing_sheet_body">
        <div *ngIf="header.value == 'Exclude'">
          <div class="no_strategy">
            No Pricing Plan
          </div>
        </div>
        <div *ngIf="header.value != 'Exclude'">
          <div class="no_strategy"
            *ngIf="pricingStrategy[header.key] && pricingStrategy[header.key]['plans'] && pricingStrategy[header.key]['plans'].length == 0 && !checkForStratLess(header.id)">
            No Pricing Plan
          </div>
          <div
            *ngIf="pricingStrategy[header.key] && pricingStrategy[header.key]['plans'] && pricingStrategy[header.key]['plans'].length > 0 && checkForStratLess(header.id)">
            <div style="padding: 5px; flex: 1; max-width: 100%; overflow-x: auto;" *ngIf="!loadingObj[header.key]">
              <table class="table table-hover table-docs" style="width: 100%;">
                <thead>
                  <tr>
                    <td rowspan="2" style="font-weight: bold; white-space: nowrap;">
                      Sl. No.
                    </td>
                    <td rowspan="2" style="font-weight: bold; white-space: nowrap;">
                      Components
                    </td>
                    <td rowspan="2" style="font-weight: bold; white-space: nowrap;">
                      Description
                    </td>
                    <td rowspan="2" style="font-weight: bold; white-space: nowrap;">
                      Unit Cost
                    </td>
                    <td rowspan="2" style="font-weight: bold; white-space: nowrap;">
                      Item Count
                    </td>
                    <td rowspan="2" style="font-weight: bold; white-space: nowrap;">
                      Total (USD) Per Month
                    </td>
                  </tr>
                </thead>
                <tbody *ngIf="getIntances(header.key).length > 0">
                  <ng-container *ngFor="let os of getIntances(header.key); let i = index">
                    <tr style="position: relative;" *ngFor="let description of os['description']; let j = index">
                      <td [attr.rowspan]="os['description'].length" *ngIf="j == 0">
                        {{i + 1}}
                      </td>
                      <td [attr.rowspan]="os['description'].length" *ngIf="j == 0">
                        {{os['components']}}
                      </td>
                      <td>
                        {{description}}
                      </td>
                      <td>
                        {{ os['unitCost'] && os['unitCost'].length > 0 ? getStringArrayValue(os['unitCost'][0], j) : '-'
                        }}
                      </td>
                      <td>
                        {{ os['itemCount'] && os['itemCount'].length > 0 ?getStringArrayValue(os['itemCount'][0], j) :
                        '-' }}
                      </td>
                      <td [attr.rowspan]="os['description'].length" *ngIf="j == 0">
                        {{ os['totalUSDPerMonth'] && os['totalUSDPerMonth'].length > 0 ?
                        toFixed(os['totalUSDPerMonth'][0]) : '-'
                        }}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
                <tbody *ngIf="getIntances(header.key).length == 0">
                  <tr>
                    <td [attr.colspan]="6" style="text-align: center;">
                      No Data Found
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="loadingObj[header.key]" style="display: flex; justify-content: center; align-items: center;">
              <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
            </div>
          </div>
          <div
            *ngIf="pricingStrategy[header.key]['plans'] && pricingStrategy[header.key]['plans'].length > 0 && !checkForStratLess(header.id)">
            <div>
              <div *ngIf="months[header.key] && getSortedKeys(months[header.key]).length > 0">
                <div style="padding: 5px; flex: 1; max-width: 100%; overflow-x: auto;">
                  <table class="table table-hover table-docs" style="width: 100%;">
                    <thead>
                      <tr>
                        <td rowspan="2"></td>
                        <td rowspan="2" style="text-align: center; font-weight: bold; white-space: nowrap;">
                          Instance Type
                        </td>
                        <td rowspan="2" style="text-align: center; font-weight: bold; white-space: nowrap;">
                          OS
                        </td>
                        <td rowspan="2" style="text-align: center; font-weight: bold; white-space: nowrap;">
                          Disk Size
                        </td>
                        <td rowspan="2" style="text-align: center; font-weight: bold; white-space: nowrap;">
                          Disk Price
                        </td>
                        <td rowspan="2" style="text-align: center; font-weight: bold; white-space: nowrap;">
                          CPU
                        </td>
                        <td rowspan="2" style="text-align: center; font-weight: bold; white-space: nowrap;">
                          RAM
                        </td>
                        <td rowspan="2" style="text-align: center; font-weight: bold; white-space: nowrap;">
                          Resource ID
                        </td>
                        <td></td>
                        <ng-container *ngFor="let strategy of getSortedKeys(months[header.key]); let i = index">
                          <td *ngIf="strategy != 'OD'" style="position: relative; text-align: center;"
                            [attr.colspan]="months[header.key][strategy]['total']">
                            <span style="text-align: center; position: sticky; left: 0; right: 0;">
                              {{ strategy }}
                            </span>
                          </td>
                          <ng-container *ngIf="strategy == 'OD'">
                            <td style="position: relative; text-align: center;"
                              *ngFor="let obj of loadCol(header.key); let i = index" [attr.colspan]="obj['colspan']">
                              <span style="text-align: center; position: sticky; left: 0; right: 0;">
                                OD ({{ obj['hours_per_month'] }} H/M)
                              </span>
                            </td>
                          </ng-container>

                        </ng-container>

                      </tr>
                      <tr style="position: relative;">
                        <td style="text-align: center; font-weight: bold;">Month</td>
                        <ng-container *ngFor="let strategy of getSortedKeys(months[header.key]); let i = index">
                          <td style="padding: 3px; text-align: center; white-space: nowrap; margin: 0px 6px;"
                            *ngFor="let date of getArrayByLength(months[header.key][strategy]['total'])">
                            <span style="text-align: center; position: sticky; left: 0; right: 0;">
                              {{ months[header.key][strategy]['indexs'][date] }}
                              ({{parsedDate(months[header.key][strategy]['dates'][date])}})
                            </span>
                            <span style="cursor: pointer; margin-left: 6px;"
                              [title]="getMoreDetails(months[header.key][strategy]['dates'][date], months[header.key][strategy]['totalDays'][date], months[header.key][strategy]['totalDays'].length, date)">
                              <i class="fa fa-info-circle" style="font-size: 0.8em;" aria-hidden="true"></i>
                            </span>
                          </td>
                        </ng-container>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let os of getIntances(header.key)" style="position: relative;">
                        <td>
                          <span style="width: 50px;">
                            <input type="checkbox" (change)="selectInstance(os)"
                              [attr.checked]="checkForChecked(os.guid) ? true : null"
                              style="width: auto; min-width: auto;" name="" id="">
                          </span>
                        </td>

                        <td style=" font-weight: bold; padding: 0px 8px; background-color: #fff; white-space: nowrap;">
                          {{os.instanceName}}</td>
                        <td style="font-weight: bold; padding: 0px 8px; background-color: #fff; white-space: nowrap;">
                          {{os.os}}</td>
                        <td
                          style="text-align: center; font-weight: bold; padding: 0px 8px; background-color: #fff; white-space: nowrap;">
                          {{os.volumeSize}}</td>
                        <td
                          style="text-align: center; font-weight: bold; padding: 0px 8px; background-color: #fff; white-space: nowrap;">
                          {{os.volumeCost}}
                        </td>
                        <td
                          style="text-align: center; font-weight: bold; padding: 0px 8px; background-color: #fff; white-space: nowrap;">
                          {{os.cpu}}</td>
                        <td
                          style="text-align: center; font-weight: bold; padding: 0px 8px; background-color: #fff; white-space: nowrap;">
                          {{os.ram}}</td>
                        <td
                          style="text-align: center; font-weight: bold; padding: 0px 8px; background-color: #fff; white-space: nowrap;">
                          {{os.launchStatus ? os.resourceId : '-'}}
                          <ng-container *ngIf="os.launchStatus && os.resourceId">
                            <ng-container *ngIf="os.State">
                              <span *ngIf="os.State == 'LOADING'">
                                <img src="../assets/img/loading_2.svg" alt="loading scans"
                                  style="width: 18px; margin: 10px" />
                              </span>
                              <span *ngIf="os.State != 'LOADING'"
                                style="text-transform: capitalize;">({{os.State}})</span>
                            </ng-container>
                          </ng-container>
                        <td>
                          <span
                            style="color: rgb(98, 138, 199); font-weight: bold; cursor: pointer; white-space: nowrap;"
                            (click)="launchInstance(os, header)" *ngIf="!os.launchStatus">
                            Launch
                          </span>
                          <span
                            style="color: rgb(98, 138, 199); font-weight: bold; cursor: pointer; white-space: nowrap;"
                            *ngIf="os.launchStatus">
                            <span style="color: rgb(98, 138, 199);" *ngIf="os.State == 'terminated'"
                              (click)="launchInstance(os, header)">
                              Re Launch
                            </span>
                            <span style="color: rgb(98, 138, 199);"
                              *ngIf="os.State != 'LOADING' && os.State != 'terminated' && os.launchStatus"
                              (click)="changeRequest(os, 'CRFlow')">
                              Change Request
                            </span>
                            <span *ngIf="os.State == 'LOADING'">
                              <img src="../assets/img/loading_2.svg" alt="loading scans"
                                style="width: 18px; margin: 10px" />
                            </span>
                          </span>
                        </td>
                        <ng-container *ngFor="let strategy of getSortedKeys(months[header.key]); let i = index">
                          <td style="padding: 3px; text-align: center;"
                            *ngFor="let date of getArrayByLength(months[header.key][strategy]['total'])">
                            <span style="text-align: center; position: sticky; left: 0; right: 0;">
                              <input type="number" class="form-control" style=" margin: 0px auto;" disabled
                                [attr.value]="getPrice(os, strategy, months[header.key][strategy]['start'] + date).value">
                            </span>
                          </td>
                        </ng-container>
                      </tr>
                      <tr style="position: relative;">
                        <td colspan="9" style="text-align: right; background-color: white;">
                          Total Spend
                        </td>
                        <ng-container *ngFor="let strategy of getSortedKeys(months[header.key]); let i = index">
                          <td style="padding: 3px; text-align: center;"
                            *ngFor="let date of getArrayByLength(months[header.key][strategy]['total'])">
                            <span style="text-align: center;">
                              {{ getColumnTotal(header.key, months[header.key][strategy]['start'] + date, strategy) }}
                            </span>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <div *ngFor="let strategy of pricingStrategy[header.key]['plans']; let i = index" style="margin-bottom: 10px;">
                  <div style="border-bottom: 1px solid rgb(221, 221, 221);">
                    {{strategy.plan}}
    
                  </div>
                  <div style="padding: 5px; flex: 1; max-width: 100; overflow-x: auto;">
                    <table class="table table-hover table-docs" style="width: 100%;">
                      <thead>
                        <tr style="position: relative;">
                          <td>
                            <span style="width: 50px;">
                              <input type="checkbox" style="width: auto; min-width: auto;" name="" id="">
                            </span>
                          </td>
                          <td
                            style=" font-weight: bold; position: sticky; left: -5px; padding: 0px 8px; background-color: #fff;">
                            Months</td>
                          <td style="padding: 3px; text-align: center;"
                            *ngFor="let _ of getArray(strategy.plan); let n = index">
                            {{n + 1}}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let os of getIntances(header.value)" style="position: relative;">
                          <td>
                            <span style="width: 50px;">
                              <input type="checkbox" style="width: auto; min-width: auto;" name="" id="">
                            </span>
                          </td>
    
                          <td
                            style=" font-weight: bold; position: sticky; left: -5px; padding: 0px 8px; background-color: #fff;">
                            {{os.OSII}}</td>
                          <td *ngFor="let _ of getArray(strategy.plan); let n = index" style="text-align: center;">
                            <input type="number" class="form-control" style=" margin: 0px auto;"
                              [attr.value]="getPrice(os, strategy.plan, n)">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> -->
              </div>
              <div *ngIf="!months[header.key]" style="display: flex; justify-content: center; align-items: center;">
                <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
              </div>
              <div *ngIf="months[header.key] && getKeys(months[header.key]).length == 0"
                style="display: flex; justify-content: center; align-items: center;">
                <span>No Pricing Details Found</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</div>

<oscheck-upload-bom [os]="confirmOs" (hideModel)="confirmCheck($event)" *ngIf="confirmOs.length > 1">
</oscheck-upload-bom>
<minfyui-change-request-ui *ngIf="changeRequestInstance" [type]="CRType" [region]="regionId" [accountId]="accountId"
  [instance]="changeRequestInstance" (hideModal)="changeRequestInstance = null"></minfyui-change-request-ui>
<style>
  input:disabled {
    background-color: transparent;
  }
</style>