import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

declare let Jhxlsx: any;
@Component({
  templateUrl: './budget.component.html',
})
export class BudgetBIllingComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;

  accountId: any;
  regionId: any;
  currentMessage: any;
  selectedLinkedAccountID: any;

  headers: any = [
    {
      id: 'lid',
      name: 'Linked Account ID',
    },
    {
      id: 'budget',
      name: 'Budget',
    },
    {
      id: 'dailyVariance',
      name: 'Variance Report',
    },
    {
      id: 'threshHold',
      name: 'ThreshHold (%)',
    },
  ];

  funRef: any = {};

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    this.fetchingLInkedAccountIDs = true;
    await this.fetchLinkedAccountIDs();
    this.fetchingLInkedAccountIDs = false;
  }

  fetchingLInkedAccountIDs: boolean = false;
  linkedAccountIDs: any = [];
  destroyed: boolean = false;

  async fetchLinkedAccountIDs(nextToken: any = undefined) {
    if (!nextToken) {
      this.linkedAccountIDs = [];
    }
    let data = {
      a: 'fetchlidwise',
      lid: this.accountId,
      nt: nextToken,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/budgetalarm`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (
      (result.status == '1' || result.s == '1') &&
      result.lids &&
      result.lids.length > 0
    ) {
      result['lids'] = result['lids'].map((lid: any) => {
        lid['eid'] = lid['eid']
          .split(',')
          .filter((email: any) => {
            return email != '';
          })
          .map((email: any) => {
            return { eid: email };
          });
        return lid;
      });
      this.linkedAccountIDs = [...this.linkedAccountIDs, ...result['lids']];
    }

    if (result.nt) {
      await this.fetchLinkedAccountIDs(result.nt);
    }
  }

  callFunction(param: any, id: any, index: number) {
    this.funRef[param](this, id, index);
  }

  exportBudgets() {
    var dict: any = {
      sheetName: 'Budgets',
      data: [],
    };
    let headers: any = [{ text: 'Sl. No.' }];
    this.headers.forEach((h: any) => {
      headers.push({ text: h['name'] });
    });
    headers.push({ text: 'Email IDs' });
    dict.data.push(headers);
    this.linkedAccountIDs.forEach((lid: any, index: number) => {
      let data = [];
      data.push({ text: index + 1 });
      this.headers.forEach((h: any) => {
        data.push({ text: lid[h.id] });
      });
      data.push({
        text: lid['eid'].map((mail: any) => mail['eid']).join(', '),
      });
      dict.data.push(data);
    });
    var options = {
      fileName: `Budgets`,
    };
    Jhxlsx.export([dict], options);
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
