import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-lens-overview',
  templateUrl: './lens-overview.component.html',
  styleUrls: ['./lens-overview.component.css', './../../warquiz.component.css'],
})
export class LensOverviewComponent implements OnInit {
  @Input('loading') loading: any;
  @Input('lenses') lenses: any;
  @Input('pillers') pillers: any;
  @Input('questions') questions: any;
  @Input('workload') workload: any;
  @Input('selectedLens') selectedLens: any;
  @Input('isMilestone') isMilestone: any;

  @Output('selectPiller') selectPiller = new EventEmitter<string>();
  @Output('showQuestions') showQue = new EventEmitter<string>();

  loaded: boolean = false;
  lensesData: any = {
    softwareasaservice: 'SaaS Lens',
    wellarchitected: 'AWS Well-Architected Framework',
    serverless: 'Serverless Lens',
    foundationaltechnicalreview: 'FTR Lens',
  };
  pillersData: any = {
    costOptimization: 'Cost Optimization',
    operationalExcellence: 'Operational Excellence',
    performance: 'Performance',
    reliability: 'Reliability',
    security: 'Security',
    sustainability: 'Sustainability',
  };
  colors: any = [
    '#556ec7',
    '#93cb77',
    '#edcb5e',
    '#73c0de',
    '#bc76c5',
    '#fe8353',
  ];

  constructor() {}

  filterQuestions(
    value: any = undefined,
    type: string = undefined,
    answered: boolean = false,
    piller?: any,
    lens?: any
  ) {
    return this.questions.filter((question: any) => {
      if (type) {
        if (answered) {
          if (type == 'PillarId') {
            return (
              question[type] == value &&
              question['SelectedChoices'].length > 0 &&
              question['lensId'] == this.selectedLens
            );
          }

          return (
            question[type] == value && question['SelectedChoices'].length > 0
          );
        } else {
          if (type == 'PillarId') {
            return (
              question[type] == value && question['lensId'] == this.selectedLens
            );
          }
          return question[type] == value;
        }
      } else {
        return question.PillarId == piller && question.lensId == lens;
      }
    });
  }

  fetchTotalAnsweredQuestions() {
    return this.questions.filter((question: any) => {
      return (
        question['SelectedChoices'].length > 0 &&
        question['lensId'] == this.selectedLens
      );
    });
  }

  fetchQuestionsRisks(risk: string, PillarId: string = null) {
    return this.filterQuestions(this.selectedLens, 'lensId', false).filter(
      (question: any) => {
        if (PillarId && question['PillarId'] != PillarId) {
          return false;
        }
        if (question['SwayamChoices'].length == 0) {
          return false;
        }
        let high = question['SwayamChoices'].filter((choice: any) => {
          return choice['severity'] == 'High' && choice['status'] == false;
        }).length;
        let medium = question['SwayamChoices'].filter((choice: any) => {
          return choice['severity'] == 'Medium' && choice['status'] == false;
        }).length;
        let low = question['SwayamChoices'].filter((choice: any) => {
          return choice['severity'] == 'Low' && choice['status'] == false;
        }).length;
        if (risk == 'High' && high > 0) {
          return true;
        }
        if (risk == 'Medium' && medium > 0 && high == 0) {
          return true;
        }
        if (risk == 'Low' && low > 0 && medium == 0 && high == 0) {
          return true;
        }
        return false;
      }
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loaded = true;
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lenses']) {
      this.lenses = this.lenses.map((lens: any, index: number) => {
        return { lens: lens, color: this.colors[index] };
      });
    }
    if (changes['pillers']) {
      this.pillers = this.pillers.map((piller: any, index: number) => {
        return { piller: piller, color: this.colors[index] };
      });
    }
    if (changes['questions']) {
      setTimeout(() => {
        this.loaded = true;
      }, 100);
    }
  }

  showQuestions(piller: string) {
    this.showQue.emit(piller);
  }
}
