import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ssb',
  templateUrl: './ssb.component.html',
  styleUrls: ['./ssb.component.css'],
})
export class SsbComponent implements OnInit, OnDestroy {
  jobs: any = [];
  createJob: boolean = false;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  accountId: string;
  regionId: string;
  loading: any = {
    jobs: false,
  };
  perPage: number = 10;
  pages: any = [10, 50, 100, 200];
  page: number = 1;
  selectedJob: any;
  selectedJobID: any;
  jobsHeaders: any = [
    {
      id: 'name',
      name: 'Name',
      link: true,
    },
    {
      id: 'cr_time',
      name: 'Creation Time',
    },
    {
      id: 'total_checks',
      name: 'Total Checks',
    },
    {
      id: 'perc',
      name: 'Score',
    },
    {
      id: 'passed',
      name: '#Passed',
    },
    {
      id: 'failed',
      name: '#Failed',
    },
    {
      id: 'status',
      name: 'Status',
    },
    {
      id: 'cm_time',
      name: 'Completed Time',
    },
  ];
  currentMessage: any;

  constructor(
    private apiService: APIService,
    private router: ActivatedRoute,
    private navigator: Router,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.router.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length == 0) {
        this.selectedJob = null;
        this.selectedJobID = null;
      }
      if (params['job_id'] && params['job_id'] != '') {
        this.selectedJobID = params['job_id'];
      }
      if (this.jobs.length > 0) {
        this.loadJobs();
      }
    });

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  load() {
    this.selectedJob = null;
    this.loadJobs();
  }

  async loadJobs(event: any = false) {
    this.loading['jobs'] = true;
    let data: any = {
      action: 'list_jobs',
      account_id: this.accountId,
      region_id: this.regionId,
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/security/ssb`;

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.status == '1' || result.s == '1') {
      result.jobs = result.jobs.sort((a: any, b: any) => {
        let date_a: any = new Date(a['cr_time']);
        let date_b: any = new Date(b['cr_time']);
        return Date.parse(date_b) - Date.parse(date_a);
      });
      this.jobs = result.jobs;
      let temp: any;
      this.jobs.forEach((job: any) => {
        job['cr_time'] = moment(job['cr_time']).format('MMM DD, YYYY hh:mm A');
        job['cm_time'] = moment(job['cm_time']).format('MMM DD, YYYY hh:mm A');
        if (this.selectedJobID && job.job_id == this.selectedJobID) {
          temp = job;
        }
        if (!job['name']) {
          job['name'] = job['job_id'];
        }
      });

      if (temp) {
        this.selectedJob = temp;
      } else {
        this.navigator.navigate(['/dash/ssb']);
      }
    } else {
      this.jobs = [];
    }

    this.loading['jobs'] = false;
  }

  selectJob(job: any) {
    this.selectedJob = job;
    this.selectedJobID = job['job_id'];
    this.navigator.navigate(['/dash/ssb'], {
      queryParams: { job_id: this.selectedJobID },
    });
    return true;
  }

  hideModel(event: string) {
    if (event) {
      this.navigator.navigate(['/dash/ssb'], {
        queryParams: { job_id: event },
      });
      this.selectedJobID = event;
      this.loadJobs();
    }
    this.createJob = false;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
