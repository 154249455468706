<div class="card mt-20" style="border: none; position: relative; height: 100%">
  <h5 class="card-header">MRR Discount</h5>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; align-items: center">
      <button
        class="btn btn-light"
        style="background-color: #5864ff; color: white"
        (click)="addMrr()"
      >
        Add MRR Details
      </button>
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
          <th>Update</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody
        *ngFor="let i of mrrData; let j = index"
        [ngStyle]="{ display: mrrData.length > 0 ? 'table-row-group' : 'none' }"
      >
        <tr>
          <td>{{ j + 1 }}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"></span>
            <span
              class="'btn btn-link"
              *ngIf="h.click"
              [innerHTML]="i[h.id] || 0"
              (click)="callFunction(h.click, i)"
            ></span>
          </td>
          <td>
            <span (click)="updateTrigger(i)" class="btn btn-light"
              ><i class="fa fa-pencil"></i
            ></span>
          </td>
          <td>
            <span (click)="deleteTrigger(i)" class="btn btn-light"
              ><i class="fa fa-trash"></i
            ></span>
          </td>
        </tr>
      </tbody>
      <tbody
        [ngStyle]="{
          display: mrrData.length == 0 ? 'table-row-group' : 'none'
        }"
      >
        <tr>
          <td [attr.colspan]="headers.length + 3">
            <div style="text-align: center">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div
  style="
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.1);
  "
  [ngStyle]="{ display: unsavedMRR.length > 0 ? 'block' : 'none' }"
>
  <div
    style="
      position: relative;
      margin: 10px;
      gap: 10px;
      background-color: white;
      border-radius: 10px;
      padding: 75px 20px 20px 20px;
      box-shadow: 1px 1px 3px 1px grey;
      display: flex;
      flex-direction: column;
      max-height: calc(100% - 20px);
    "
  >
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <h4>Unsaved MRR Details</h4>
      <div
        style="display: flex; justify-content: flex-end; align-items: center"
      >
        <button
          class="btn btn-light"
          style="background-color: #5864ff; color: white"
          (click)="addMrr()"
        >
          Add MRR Details
        </button>
      </div>
    </div>
    <div
      style="
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 10px;
        cursor: pointer;
      "
      (click)="unsavedMRR = []"
      class="btn btn-light"
    >
      X
    </div>
    <div style="overflow: auto; flex: 1">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th *ngFor="let h of headers">{{ h.name }}</th>
            <th>Update</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody
          *ngFor="let i of unsavedMRR; let j = index"
          [ngStyle]="{
            display: unsavedMRR.length > 0 ? 'table-row-group' : 'none'
          }"
        >
          <tr>
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of headers">
              <span *ngIf="!h.click" [innerHTML]="i[h.id] || '-'"> </span>
              <span
                class="btn btn-link"
                *ngIf="h.click"
                [innerHTML]="i[h.id] || 0"
                (click)="callFunction(h.click, i)"
              >
              </span>
            </td>
            <td>
              <span (click)="updateUnsavedMRR(i, j)" class="btn btn-light"
                ><i class="fa fa-pencil"></i
              ></span>
            </td>
            <td>
              <span (click)="deleteUnsavedMRR(j)" class="btn btn-light"
                ><i class="fa fa-trash"></i
              ></span>
            </td>
          </tr>
        </tbody>
        <tbody
          [ngStyle]="{
            display: unsavedMRR.length == 0 ? 'table-row-group' : 'none'
          }"
        >
          <tr>
            <td [attr.colspan]="headers.length + 3">
              <div style="text-align: center">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      style="
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 15px;
      "
    >
      <button
        class="btn btn-light"
        (click)="saveDetails()"
        style="background-color: #5864ff; color: white"
      >
        Save Details
      </button>
    </div>
  </div>
</div>

<mrr-update
  *ngIf="updateMRR"
  [action]="updateAction"
  [mrr]="updateMRR"
  [unsaved]="unsavedMRR"
  (hideModel)="closeUpdateTrigger($event)"
>
</mrr-update>
