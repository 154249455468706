<div class="card">
  <div class="card-body">
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
        </tr>
      </thead>
      <tbody [ngStyle]="{
          display: diskDetailsList.length > 0 ? 'table-row-group' : 'none'
        }" *ngFor="let i of diskDetailsList; let j = index">
        <tr>
          <td>
            {{ j + 1 }}
          </td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span>
                {{ i[h.id] }}
              </span>
            </span>
            <span *ngIf="!h.click"> {{ i[h.id] }} </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{
          display: diskDetailsList.length == 0 ? 'table-row-group' : 'none'
        }">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div style="text-align: center">No Disks Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>