import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-step2-user-details',
  templateUrl: './step2-user-details.component.html',
  styleUrls: ['./step2-user-details.component.css'],
})
export class Step2UserDetailsComponent implements OnInit {
  @Input('emails') emails: any;
  @Output('setMainHeight') setMainHeight: any = new EventEmitter<number>();
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';

  parentElement: any;
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.parentElement = document.querySelector('.step_2_container');
      this.setMainContainerHeight(this.parentElement.offsetHeight + 48);
    }, 1);
  }

  deleteSelectedAdditionalContact(index: number) {
    this.emails['emails'].splice(index, 1);
    this.setMainContainerHeight();
  }

  checkForLast(value: any, index: number) {
    if (
      value != '' &&
      value.length > 0 &&
      this.emails['emails'].length - 1 == index
    ) {
      this.emails['emails'].push('');
      this.setMainContainerHeight();
    } else if (
      value == '' &&
      value.length == 0 &&
      this.emails['emails'].length - 2 == index &&
      this.emails['emails'][this.emails['emails'].length - 1] == ''
    ) {
      this.emails['emails'].splice(this.emails['emails'].length - 1, 1);
      this.setMainContainerHeight();
    }
  }

  addAdditionalContact() {
    this.emails['emails'].push('');
    this.setMainContainerHeight();
  }

  trackByFn() {
    return true;
  }

  setMainContainerHeight(height: number = undefined) {
    this.setMainHeight.emit(height);
  }
}
