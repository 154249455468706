<div class="alarm_screen" style="box-shadow:  1px 3px 1px #80808024 ">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform:capitalize">{{action == 'local' ? 'Add' : action}} Entry</h4>
    </div>
    <div class="alarm_body" style=" padding: 1px 25px 5px 25px">
      <div class="row">
        <label>OS</label>
        <select class="form-control" (change)="getEC2Pricing(bom)" id="platformsList" [(ngModel)]="bom.os"
          data-live-search="true">
          <option *ngFor="let os of platformTypes" value="{{os}}">{{os}}</option>
        </select>
      </div>
      <div class="row">
        <label>Region</label>
        <select class="form-control" id="regions_list" [(ngModel)]="bom.region" data-live-search="true">
          <option *ngFor="let region of regions" value="{{region.id}}">{{region.name}}</option>
        </select>
      </div>
      <div class="row">
        <label>Instance Types</label>
        <select class="form-control" (change)="changeInstanceType(bom)" id="instanceTypes"
          [(ngModel)]="bom.instanceType" data-live-search="true">
          <option *ngFor="let type of instanceTypes" value="{{type}}">{{type}}</option>
        </select>
      </div>
      <div class="row">
        <label>CPU</label>
        <input type="text" [disabled]="true" class="form-control" [ngModel]="bom.cpu">
      </div>
      <div class="row">
        <label>Disk</label>
        <input type="text" class="form-control" [(ngModel)]="bom.disk">
      </div>
      <div class="row">
        <label>Quantity</label>
        <input type="text" class="form-control" [(ngModel)]="bom.quantity">
      </div>
      <div class="row">
        <label>Billing Plan</label>
        <select class="form-control" (change)="getEC2Pricing(bom)" id="billingTypes" [(ngModel)]="bom.billingType"
          data-live-search="true">
          <option *ngFor="let type of billingTypes" value="{{type}}">{{type}}</option>
        </select>
      </div>
      <div class="row">
        <label>RAM</label>
        <input type="text" [disabled]="true" class="form-control" [ngModel]="bom.ram">
      </div>
      <div class="row">
        <label>Usage in Hours</label>
        <input type="text" class="form-control" [(ngModel)]="bom.usageHours">
      </div>
      <div class="row">
        <label>Cost Per Hour</label>
        <input type="text" class="form-control" disabled="true" [ngModel]="bom.hourlyCost">
      </div>
      <div class="row">
        <label>Total Cost</label>
        <input type="text" class="form-control" disabled="true" [ngModel]="getTotal()">
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="updateEntry()"
            style="background-color: #5864ff; color: white; text-transform:capitalize; margin-right:20px">
            {{action == 'local' ? 'Add' : action}}
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>


  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>