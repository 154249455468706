import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
// import { CanDeactivateComponent } from 'src/app/_helpers/auth.guard';

@Component({
  templateUrl: './warquiz.component.html',
  styleUrls: ['./warquiz.component.css'],
})
export class WarQuizComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading: any = {
    workloads: true,
    lenses: false,
    questions: false,
    pillars: false,
  };
  milestone: any = null;
  questions: any = [];
  milestones: any = [];
  filterType: string = 'ALL';
  selectedPiller: string = null;
  selectedLenses: string = null;
  currentQuestionIndexs: any = {};
  selectedWorkload: any = null;
  selectedWorkloadId: string = null;

  workloads: any = [];

  comments: any = [];

  pillersData: any = {
    costOptimization: 'Cost Optimization',
    operationalExcellence: 'Operational Excellence',
    performance: 'Performance',
    reliability: 'Reliability',
    security: 'Security',
    sustainability: 'Sustainability',
  };

  lensesData: any = {
    softwareasaservice: 'SaaS Lens',
    wellarchitected: 'AWS Well-Architected Framework',
    serverless: 'Serverless Lens',
    foundationaltechnicalreview: 'FTR Lens',
  };

  pillers: any = [];
  lenses: any = [];
  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  index: number = 1;
  createWorkload: boolean = false;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null) {
        return;
      }

      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load(true);
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load(true);
      }
    });
  }

  addComment(event: any, option: any) {
    event.preventDefault();
    event.stopPropagation();
    option['comments'] = option['dummy_comment'];
    option['dummy_comment'] = undefined;
  }

  user_favicon(
    username: string,
    user_name_parsed_requrired: boolean = false
  ): any {
    var user_favicon_text = '';
    var user_names;
    var username_name_parsed = '';
    if (username == '') {
      return 'Anonymous';
    }
    username = username.trim();
    if (!username.includes('@')) {
      user_names = username.split(' ');
    } else {
      user_names = username.split('@')[0].split('.');
    }
    if (user_names.length > 0) {
      user_favicon_text += user_names[0][0].trim();
      username_name_parsed += user_names[0].trim();
      if (user_names.length > 1) {
        if (user_names[user_names.length - 1].length > 0) {
          user_favicon_text += user_names[user_names.length - 1][0].trim();
          username_name_parsed +=
            ' ' + user_names[user_names.length - 1].trim();
        }
      } else {
        user_favicon_text += ' ';
      }
    }
    if (user_name_parsed_requrired) {
      return {
        user_full_name: username_name_parsed.trim(),
        user_favicon_parsed: user_favicon_text.trim(),
      };
    }
    return user_favicon_text.toUpperCase();
  }

  async selectWorkload(workload: any) {
    this.selectedWorkload = workload;
    this.selectedLenses = null;
    this.selectedPiller = null;
    this.pillers = [];
    this.lenses = [];
    this.questions = [];
    this.loading['pillars'] = true;
    this.loading['lenses'] = true;
    this.loading['questions'] = true;
    this.loadMilestones();
    if (this.selectedWorkload['lenses']) {
      this.lenses = this.selectedWorkload['lenses'];
    } else {
      await this.loadLenses();
    }
    await this.loadPillers();
    this.loadQuestions();
  }

  hideCreateWorkload(event: any) {
    if (event) {
      this.load();
    }
    this.createWorkload = false;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load(
    refresh: boolean = false,
    workload_id: string = null,
    length: number = -1
  ) {
    if (!workload_id) {
      this.loading['workloads'] = true;
      this.workloads = [];
      this.selectedWorkload = null;
      this.pillers = [];
      this.lenses = [];
      this.questions = [];
    }
    let data: any = {
      action: 'list_workloads',
      account_id: this.accountId,
      region_id: this.regionId,
      id_token: localStorage.getItem('idT'),
    };

    if (workload_id) {
      data['action'] = 'describe_workload';
      data['workload_id'] = workload_id;
    }

    let headers: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (length > -1 && this.workloads.length != length) {
      return;
    }

    if (result.status == '1' || result.s == '1') {
      result.workloads = result.workloads.map((workload: any) => {
        workload.creation_time = moment(workload.creation_time).format(
          'MMM DD, YYYY hh:mm A'
        );
        return workload;
      });
      if (!workload_id) {
        result.workloads = result.workloads.sort((a: any, b: any) => {
          let dateTemp_a: any = new Date(a['creation_time']);
          let dateTemp_b: any = new Date(b['creation_time']);
          return Date.parse(dateTemp_b) - Date.parse(dateTemp_a);
        });
        this.workloads = result.workloads;
        let temp = result.workloads.filter((workload: any) => {
          return (
            workload['ScanStatus'] == undefined ||
            workload['ScanStatus'] == 'SCANNING'
          );
        });
        setTimeout(() => {
          temp.forEach((workload: any) => {
            this.load(false, workload['id'], this.workloads.length);
          });
        }, 5000);
      } else {
        let workloadIndex = this.workloads.findIndex((workload: any) => {
          return workload['id'] == workload_id;
        });

        if (
          workloadIndex > -1 &&
          this.workloads.length > 0 &&
          this.workloads[workloadIndex]
        ) {
          this.workloads[workloadIndex] = result.workloads[0];
          if (
            this.workloads[workloadIndex]['ScanStatus'] == 'SCANNING' ||
            this.workloads[workloadIndex]['ScanStatus'] == undefined
          ) {
            setTimeout(() => {
              this.load(
                false,
                this.workloads[workloadIndex]['id'],
                this.workloads.length
              );
            }, 5000);
          }
        }
      }
    }

    this.loading['workloads'] = false;
  }

  async loadPillers() {
    this.loading['pillars'] = true;
    let data: any = {
      action: 'list_workload_pillars',
      workload_id: this.selectedWorkload['id'],
      id_token: localStorage.getItem('idT'),
    };

    let headers: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == '1' || result.s == '1') {
      this.pillers = result.pillars;
      this.lenses.forEach((lens: string) => {
        if (!this.currentQuestionIndexs[lens]) {
          this.currentQuestionIndexs[lens] = {};
        }
        this.pillers.forEach((piller: string) => {
          this.currentQuestionIndexs[lens][piller] = 0;
        });
      });
    }
    this.loading['pillars'] = false;
  }

  async loadLenses() {
    this.loading['lenses'] = true;
    let data: any = {
      action: 'list_workload_lenses',
      workload_id: this.selectedWorkload['id'],
      id_token: localStorage.getItem('idT'),
    };

    let headers: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == '1' || result.s == '1') {
      this.lenses = result.lens;
    }

    this.loading['lenses'] = false;
  }

  async loadQuestions() {
    this.loading['questions'] = true;
    let data: any = {
      action: 'get_workload_questions',
      workload_id: this.selectedWorkload['id'],
    };

    let headers: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == '1' || result.s == '1') {
      result.questions = result.questions.map((question: any) => {
        if (!question['SwayamChoices']) {
          question['SwayamChoices'] = [];
        }

        // consider previous values also
        if (question['ChoiceAnswerSummaries'].length == 0) {
          question['ChoiceAnswerSummaries'] = question['Choices'].map(
            (choice: any) => {
              return {
                ChoiceId: choice['ChoiceId'],
                Status:
                  question['SelectedChoices'].indexOf(choice['ChoiceId']) > -1
                    ? 'SELECTED'
                    : 'UNSELECTED',
                Reason: '',
              };
            }
          );
        }
        let check = question['SwayamChoices'].find((choice: any) => {
          return choice['Title'] == 'None of these';
        });
        if (!check) {
          question['SwayamChoices'].push({
            choiceId: question['Choices'].find((choice: any) => {
              return choice['Title'] == 'None of these';
            })['ChoiceId'],
          });
        }

        question['SwayamChoices'] = question['SwayamChoices'].map(
          (choice: any) => {
            if (choice['comments'] == undefined) {
              choice['comments'] = '';
            }
            if (!choice['best_practices']) {
              choice['best_practices'] = [
                {
                  practice: 'Practice 1',
                  selected: false,
                },
                {
                  practice: 'Practice 2',
                  selected: false,
                },
                {
                  practice: 'Practice 3',
                  selected: false,
                },
              ];
            }
            return choice;
          }
        );
        return question;
      });
      this.questions = result.questions;
    }

    this.loading['questions'] = false;
  }

  selectLens(lens: string) {
    this.selectedLenses = lens;
  }

  showQuestions: boolean = false;
  selectPiller(piller?: string, lens?: string) {
    if (piller) {
      this.selectedPiller = piller;
    }
    if (lens) {
      this.selectedLenses = lens;
    }
    this.showQuestions = true;
  }

  hideQuestions(event: any) {
    this.showQuestions = false;
    this.selectedPiller = null;
  }

  callFunction(name: any, param: any) {
    return;
  }

  milestoneName: string;
  showMileStonePopup: boolean = false;
  async saveMilestone() {
    if (this.milestone) {
      return;
    }
    this.loading['milestone'] = true;
    let data: any = {
      action: 'create_milestone',
      milestone_name: '',
      account_id: this.accountId,
      region_id: this.regionId,
      id_token: localStorage.getItem('idT'),
    };

    let headers: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (length > -1 && this.workloads.length != length) {
      return;
    }
    this.loading['milestone'] = false;
  }

  hideMilestonePopup(event: boolean) {
    this.showMileStonePopup = false;
    if (event) {
      this.loadMilestones();
    }
  }

  async loadMilestones() {
    this.loading['milestones'] = true;
    let data: any = {
      action: 'list_milestones',
      workload_id: this.selectedWorkload['id'],
      id_token: localStorage.getItem('idT'),
    };

    let headers: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == '1' || result.s == '1') {
      result.milestones = result.milestones.map((milestone: any) => {
        milestone['creation_time'] = moment(milestone).format(
          'MMM DD, YYYY hh:mm A'
        );
        return milestone;
      });
      this.milestones = result.milestones;
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.error || result.error_message,
        'info',
        5000
      );
    }
    this.loading['milestones'] = false;
  }

  async loadMilestone(milestone: any) {
    milestone['questions'] = [];
    this.milestone = milestone;

    this.loading['milestone'] = true;
    this.loading['questions'] = true;
    let data: any = {
      action: 'get_milestone_questions',
      workload_id: this.selectedWorkload['id'],
      id_token: localStorage.getItem('idT'),
      milestone_id: milestone['id'],
    };

    let headers: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == '1' || result.s == '1') {
      result.questions = result.questions.map((question: any) => {
        if (!question['SwayamChoices']) {
          question['SwayamChoices'] = [];
        }

        // consider previous values also
        if (question['ChoiceAnswerSummaries'].length == 0) {
          question['ChoiceAnswerSummaries'] = question['Choices'].map(
            (choice: any) => {
              return {
                ChoiceId: choice['ChoiceId'],
                Status:
                  question['SelectedChoices'].indexOf(choice['ChoiceId']) > -1
                    ? 'SELECTED'
                    : 'UNSELECTED',
                Reason: '',
              };
            }
          );
        }
        let check = question['SwayamChoices'].find((choice: any) => {
          return choice['Title'] == 'None of these';
        });
        if (!check) {
          question['SwayamChoices'].push({
            choiceId: question['Choices'].find((choice: any) => {
              return choice['Title'] == 'None of these';
            })['ChoiceId'],
          });
        }

        question['SwayamChoices'] = question['SwayamChoices'].map(
          (choice: any) => {
            if (choice['comments'] == undefined) {
              choice['comments'] = '';
            }
            if (!choice['best_practices']) {
              choice['best_practices'] = [
                {
                  practice: 'Practice 1',
                  selected: false,
                },
                {
                  practice: 'Practice 2',
                  selected: false,
                },
                {
                  practice: 'Practice 3',
                  selected: false,
                },
              ];
            }
            return choice;
          }
        );
        return question;
      });
      milestone['questions'] = result['questions'];
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.error || result.error_message,
        'info',
        5000
      );
    }
    this.loading['milestone'] = false;
    this.loading['questions'] = false;
  }
}
