import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-workload-overview',
  templateUrl: './workload-overview.component.html',
  styleUrls: [
    './workload-overview.component.css',
    './../../warquiz.component.css',
  ],
})
export class WorkloadOverviewComponent implements OnInit, OnChanges {
  @Input('loading') loading: any;
  @Input('lenses') lenses: any;
  @Input('pillers') pillers: any;
  @Input('questions') questions: any;
  @Input('milestones') milestones: any;
  @Input('workload') workload: any;
  @Input('isMilestone') isMilestone: boolean;

  @Output('selectLens') selectLens = new EventEmitter<string>();
  @Output('showQuestions') showQue = new EventEmitter<string>();
  @Output('saveMilestone') saveMilestone = new EventEmitter<boolean>();
  @Output('milestone') milestone: any = new EventEmitter<any>();

  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';

  loaded: boolean = false;
  lensesData: any = {
    softwareasaservice: 'SaaS Lens',
    wellarchitected: 'AWS Well-Architected Framework',
    serverless: 'Serverless Lens',
    foundationaltechnicalreview: 'FTR Lens',
  };
  colors: any = [
    '#556ec7',
    '#93cb77',
    '#edcb5e',
    '#73c0de',
    '#bc76c5',
    '#fe8353',
  ];

  constructor(private apiServer: APIService) {}

  filterQuestions(
    value: any = undefined,
    type: string = undefined,
    answered: boolean = false,
    piller?: any,
    lens?: any
  ) {
    return this.questions.filter((question: any) => {
      if (type) {
        if (answered) {
          if (type == 'PillarId') {
            return (
              question[type] == value &&
              question['SelectedChoices'].length > 0 &&
              question['lensId'] == lens
            );
          }

          return (
            question[type] == value && question['SelectedChoices'].length > 0
          );
        } else {
          if (type == 'PillarId') {
            return question[type] == value && question['lensId'] == lens;
          }
          return question[type] == value;
        }
      } else {
        return question.PillarId == piller && question.lensId == lens;
      }
    });
  }

  fetchTotalAnsweredQuestions() {
    return this.questions.filter((question: any) => {
      return question['SelectedChoices'].length > 0;
    });
  }

  fetchQuestionsRisks(risk: string, lensId: string = null) {
    return this.questions.filter((question: any) => {
      if (lensId && question['lensId'] != lensId) {
        return false;
      }
      if (question['SwayamChoices'].length == 0) {
        return false;
      }
      let high = question['SwayamChoices'].filter((choice: any) => {
        return choice['severity'] == 'High' && choice['status'] == false;
      }).length;
      let medium = question['SwayamChoices'].filter((choice: any) => {
        return choice['severity'] == 'Medium' && choice['status'] == false;
      }).length;
      let low = question['SwayamChoices'].filter((choice: any) => {
        return choice['severity'] == 'Low' && choice['status'] == false;
      }).length;
      if (risk == 'High' && high > 0) {
        return true;
      }
      if (risk == 'Medium' && medium > 0 && high == 0) {
        return true;
      }
      if (risk == 'Low' && low > 0 && medium == 0 && high == 0) {
        return true;
      }
      return false;
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lenses']) {
      this.lenses = this.lenses.map((lens: any, index: number) => {
        return { lens: lens, color: this.colors[index] };
      });
    }
    if (changes['pillers']) {
      this.pillers = this.pillers.map((piller: any, index: number) => {
        return { piller: piller, color: this.colors[index] };
      });
    }

    if (changes['questions']) {
      setTimeout(() => {
        this.loaded = true;
      }, 100);
    }
  }

  showQuestions(lens: string) {
    this.showQue.emit(lens);
  }

  async downloadReport(lens: string) {
    let data: any = {
      action: 'generate_report',
      workload_id: this.workload['id'],
      id_token: localStorage.getItem('idT'),
    };

    let headers: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    console.log(result);
  }
}
