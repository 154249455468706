import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as moment from 'moment';

declare let window: any;

@Component({
  templateUrl: './iam_age_report.component.html',
})
export class BetaIAMAgeReportComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  users: any = [];
  fetching: boolean = false;

  destroyed: boolean = false;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  funRef: any;

  userHeader: any = [
    { id: 'user_Name', name: 'User', filter: true },
    { id: 'accessKey_ID', name: 'Access Key ID', filter: true },
    {
      id: 'id_Status',
      name: 'Status',
      filter: true,
    },
    {
      id: 'age_in_days_html',
      name: 'Age in Days',
      center: true,
    },

    {
      id: 'created_Date',
      name: 'Create Date',
      filter: true,
    },
  ];

  moreDetailsHeaders: any = [];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.refresh();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.refresh();
      }
    });
    this.funRef = {};
  }

  position: any = { x: '0px', y: '0px' };
  setPosition(event: any) {
    this.position.x = window.innerWidth - event.clientX - 60 + 'px';
    this.position.y = event.clientY + 10 + 'px';
  }

  refresh_: boolean = false;
  refresh() {
    this.refresh_ = true;
    this.loadiamusers();
  }

  nextToken: any = null;
  async loadiamusers(nextToken: string = '') {
    this.nextToken = nextToken == '' ? null : nextToken;
    this.fetching = true;
    if (this.destroyed) {
      return true;
    }
    if (nextToken == '') {
      this.refresh_ = true;
      this.users = [];
    }
    let data = {
      aId: this.accountId,
      eId: this.userId,
      a: 'IAMAccesskeyAgeReport',
      nT: nextToken == '' ? undefined : nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/iamageingreport`;

    if (nextToken != '' && this.refresh_) {
      nextToken = '';
      this.users = [];
      return true;
    }

    this.refresh_ = false;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      result.status == '200' ||
      result.status == '1' ||
      result.status_code == 200 ||
      result.accessKeyDetails
    ) {
      result.accessKeyDetails = result.accessKeyDetails.map((access: any) => {
        if (access.age_in_days > 90) {
          access[
            'age_in_days_html'
          ] = `<span class='red_color'>${access.age_in_days}</span>`;
        } else {
          access[
            'age_in_days_html'
          ] = `<span class='green_color'>${access.age_in_days}</span>`;
        }
        return access;
      });
      this.users = [...this.users, ...result.accessKeyDetails];
    } else {
      if (result.errorMsg != 'NO RECORD FOUND') {
        this.notifier.alert('Info', '', result.errorMsg, 'info', 5000);
      }
    }

    if (result.nextToken) {
      await this.loadiamusers(result.nextToken);
    } else {
      this.nextToken = null;
    }
    if (!this.destroyed) {
      this.fetching = false;
    }

    return true;
  }

  downloadReport() {
    let element = document.createElement('table');
    element.innerHTML += `
        <thead>
          <tr>
            <th>Sl. No.</th>
            ${[...this.userHeader, ...this.moreDetailsHeaders].map((h: any) => {
              return `<th>${h.name}</th>`;
            })}
          </tr>
        </thead>
        <tbody>
            ${this.users.map((ec2: any, index: any) => {
              return `
                <tr>
                  <td>${index + 1}</td>
                  ${[...this.userHeader, ...this.moreDetailsHeaders].map(
                    (h: any) => {
                      return `<td>
                      ${ec2[h.id]}
                    </td>`;
                    }
                  )}
                </tr>
              `;
            })}
        </tbody>
      `;
    window.exportToExcelElement(
      element,
      `IAM Access Key Age Report - ${this.accountId}`,
      'csv'
    );
  }

  callFunction(name: any, param: any) {}

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
