<div class="card mb-2">
  <h5 class="card-header">User Management</h5>
  <div class="card-body">
    <form>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">User Name</label>
        <input type="text" class="form-control" placeholder="Enter User Name" [(ngModel)]="userDetails.un"
          name="username" />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Phone Number</label>
        <input type="text" class="form-control" placeholder="Enter Phone Number" [(ngModel)]="userDetails.ph"
          name="phonenumber" />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Company</label>
        <input type="text" class="form-control" placeholder="Enter Company Name" [(ngModel)]="userDetails.com"
          name="company" />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Designation</label>
        <input type="text" class="form-control" placeholder="Enter Designation" [(ngModel)]="userDetails.des"
          name="designation" />
      </div>
      <button type="submit" class="btn btn-primary" (click)="updateUser()">Update</button>
    </form>
  </div>
</div>
<div class="card">
  <h5 class="card-header">Change Password</h5>
  <div class="card-body">
    <form>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Existing Password</label>
        <input type="password" name="oldPass" [(ngModel)]="changePassword.oldPass" class="form-control"
          placeholder="Enter Old Password" />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">New Password</label>
        <input type="password" name="newPass" [(ngModel)]="changePassword.newPass" class="form-control"
          placeholder="Enter New Password" />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Confirm Password</label>
        <input type="password" name="newConfPass" [(ngModel)]="changePassword.newConfPass" class="form-control"
          placeholder="Confirm New Password" />
      </div>
      <button type="submit" class="btn btn-primary" (click)="changePasswordAction()">Update</button>
    </form>
  </div>
</div>