<div class="card">
  <h5 class="card-header">S3</h5>
  <div class="card-body">
    <!-- <div class="grid_container buckets_summary">
      <div class="grid_child flex_horizontal" (click)="filterBuckets('ALL', true)"
        [ngClass]="{'active_filter': filterType == 'ALL'}">
        <div>All Buckets</div>
        <div>{{ s3List.length }}</div>
      </div>
      <div class="grid_child flex_horizontal" (click)="filterBuckets('public', true)"
        [ngClass]="{'active_filter': filterType == 'public'}">
        <div>Public Buckets</div>
        <div>{{ getLength("public", true) }}</div>
      </div>
      <div class="grid_child flex_horizontal" (click)="filterBuckets('isEncrypted', true)"
        [ngClass]="{'active_filter': filterType == 'isEncrypted'}">
        <div>Encrypted Buckets</div>
        <div>{{ getLength("isEncrypted", true) }}</div>
      </div>
    </div> -->
    <!--<h5 class="card-title">Case Details</h5>-->
    <!-- <!-- <div class="grid_container">
      <div *ngFor="let bucket of s3List">
        <div class="grid_child">
          <div
            class="child_default info-group"
            [ngStyle]="{ opacity: bucket.focused ? 1 : 0.4 }"
          >
            <div class="bucket_name info-title">
              <div class="success_icon icon_container bucket_icon_container">
                <img
                  class="bucket_icon"
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Compliance+S3.svg"
                  alt=""
                />
              </div>
              <div>
                {{ bucket.bucketName }}
              </div>
            </div>

            <div class="child_public_check info-side">
              <div class="success_icon icon_container" *ngIf="bucket.public">
                <i class="fa fa-lock" aria-hidden="true"></i>
              </div>
              <div class="failed_icon icon_container" *ngIf="!bucket.public">
                <i class="fa fa-unlock-alt" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="child_more">
            <div class="info-group">
              <div class="info-title">Public</div>
              <div class="info-side">
                <div class="failed_icon icon_container" *ngIf="bucket.public">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div class="success_icon icon_container" *ngIf="!bucket.public">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="info-group">
              <div class="info-title">Encrypted</div>
              <div class="info-side">
                <div
                  class="success_icon icon_container"
                  *ngIf="bucket.isEncrypted"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div
                  class="failed_icon icon_container"
                  *ngIf="!bucket.isEncrypted"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="info-group">
              <div class="info-title">Bucket Public Policy Status</div>
              <div class="info-side">
                <div
                  class="failed_icon icon_container"
                  *ngIf="bucket.bucketPublicPolicyStatus"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div
                  class="success_icon icon_container"
                  *ngIf="!bucket.bucketPublicPolicyStatus"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="info-group">
              <div class="info-title">Restrict Public Buckets</div>
              <div class="info-side">
                <div
                  class="success_icon icon_container"
                  *ngIf="bucket.restrictPublicBuckets"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div
                  class="failed_icon icon_container"
                  *ngIf="!bucket.restrictPublicBuckets"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="info-group">
              <div class="info-title">Block Public Access</div>
              <div class="info-side">
                <div
                  class="success_icon icon_container"
                  *ngIf="bucket.blockPublicAccess"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div
                  class="failed_icon icon_container"
                  *ngIf="!bucket.blockPublicAccess"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="info-group">
              <div class="info-title">Block Public Acl</div>
              <div class="info-side">
                <div
                  class="success_icon icon_container"
                  *ngIf="bucket.blockPublicAcl"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div
                  class="failed_icon icon_container"
                  *ngIf="!bucket.blockPublicAcl"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">{{h.name}}</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': s3List.length > 0 ? 'table-row-group': ''}">
        <tr *ngFor="let i of s3List; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              <span *ngIf="h.color">
                <span [ngStyle]="{'color': h.color(i[h.id])}">{{checkforBool(i[h.id])}}</span>
              </span>
              <span *ngIf="!h.color">
                <span>{{checkforBool(i[h.id])}}</span>
              </span>
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': s3List.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="text-align: right;" *ngIf="index > 1"><button class="btn btn-light"
        style="background: #5864FF; color: white;" (click)="load()">Next</button></div>
  </div>
</div>