import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
import { regions } from 'src/app/cbsDash/dash.component';

declare let $: any;
declare let flatpickr: any;
import * as moment from 'moment';

@Component({
  selector: 'cfrc-update',
  templateUrl: './cfrcupdate-v3.component.html',
  styleUrls: [
    '../../../../views/cbs/enableri/update/update.enableri.component.css',
  ],
})
export class CfrcEnableV3Update implements OnInit, OnDestroy, AfterViewInit {
  selectedServiceNames: any[] = [];
  enabledRegion: any = [];
  usageTypes: any = [];
  regionsList: any = [];
  isAllSelect: boolean = false;
  serviceNamelist: any;
  currentMessage: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  mid: any;
  date: any;
  @Output() hideModel = new EventEmitter<any>();
  @Input('action') action: any;
  @Input() cfrc: any;
  @Input() unsaved: any;
  masterAccounts: any[];
  dateRange: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.regionsList = regions;
    this.fetchUsageType();
    this.cfrc = { ...this.cfrc };
    this.accountId = localStorage.getItem('accountId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      }
    });
    this.fetchMasterAccounts();
  }

  load() {}
  ngAfterViewInit(): void {
    flatpickr('#dataRangePicker', {
      dateFormat: 'Y-M-d',
      mode: 'range',
      showMonths: 2,
      defaultDate: this.cfrc.dateRange
        ? this.cfrc.dateRange.split(' to ')
        : null,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.dateRange = dateStr;
      },
    });
    $('#regionId').selectpicker();
    if (this.action.trim() == 'add' || this.cfrc.region.length != 0) {
      $('#regionId').val(this.cfrc.region);
      $('#regionId').selectpicker('refresh');
    }
  }
  async updateEntry() {
    if (this.action.trim() == 'add') {
      this.close({
        action: 'add',
        data: [
          {
            accountId: this.accountId,
            region: $('#regionId').val(),
            description: $('#usageTypeId').val(),
            costPerHour: this.cfrc.costPerHour,
            startDate: '',
            endDate: '',
          },
        ],
      });
      return;
    }
    this.notifier.loading(true);
    let data: any = {
      action: 'update',
      data: [
        {
          accountId: this.accountId,
          region: this.cfrc.region,
          description: this.cfrc.description,
          costPerHour: this.cfrc.costPerHour,
          startDate: '',
          endDate: '',
        },
      ],
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-enable-v3`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.close(true);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async fetchMasterAccounts() {
    this.regionsList.enabled.forEach((r: any) => {
      if (r.status === 'enabled') {
        this.enabledRegion.push(r.id);
      }
    });
    this.masterAccounts = [];
    this.notifier.loading(true);
    let data = {
      lid: this.accountId,
      userType: 'C',
      action: 'fetchMasterAccList',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = result.masterAccList;
      this.mid = this.masterAccounts[0];
      this.notifier.loading(false);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.notifier.loading(false);
    }
  }
  async fetchUsageType() {
    this.notifier.loading(true);
    let data = {
      action: 'list_usageType',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-enable-v3`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.usageTypes = result.usageType;
      setTimeout(() => {
        $('#usageTypeId').selectpicker();
        if (
          this.action.trim() == 'update' ||
          this.cfrc.description.length != 0
        ) {
          $('#usageTypeId').val(this.cfrc.description);
          $('#usageTypeId').selectpicker('refresh');
        }
        // else {
        //   $('#usageTypeId').selectpicker('refresh');
        // }
      }, 100);
      this.notifier.loading(false);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.notifier.loading(false);
    }
  }

  ngOnDestroy(): void {}

  trackByFn() {
    return true;
  }

  close(dataAction: any) {
    if (dataAction) {
      this.hideModel.emit(dataAction);
    } else {
      this.hideModel.emit(dataAction);
    }
  }
}
