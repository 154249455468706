import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as moment from 'moment';

declare let flatpickr: any;

@Component({
  templateUrl: './audit.settings.component.html',
  styleUrls: ['./audit.settings.component.css'],
})
export class auditSettingsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  auditList: any = {};
  destroyed: boolean = false;
  dataFilter: string = 'date';

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  filterDate: any = moment();
  selectedAction: any = 'all';
  selectedUser: string = 'all';
  endDate: string;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    document.addEventListener('scroll', (event) => this.loadMoreTrails(event));

    flatpickr('#date-selector', {
      dateFormat: 'd/m/Y',
      defaultDate: [moment().format('DD/MM/YYYY')],
      maxDate: new Date(),
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        this.filterDate = moment(dateStr, 'DD/MM/YYYY');
        this.nextToken = {};
        this.auditList = {};
        this.load(this.filterDate);
      },
    });

    flatpickr('#date-selector-range', {
      dateFormat: 'd/m/Y',
      defaultDate: [
        moment().subtract(1, 'days').format('DD/MM/YYYY'),
        moment().format('DD/MM/YYYY'),
      ],
      maxDate: moment().format('DD/MM/YYYY'),
      minDate: moment().subtract(3, 'months').format('DD/MM/YYYY'),
      showMonths: 2,
      mode: 'range',
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.filterDate = moment(dateStr.split(' to ')[1], 'DD/MM/YYYY');
        this.endDate = dateStr.split(' to ')[0];
        this.nextToken = {};
        this.auditList = {};
        this.load(this.filterDate);
      },
    });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load(this.filterDate);
        this.fetchActions();
        // this.loadClients();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load(this.filterDate);
        this.fetchActions();
        // this.loadClients();
      }
    });
  }

  loadMoreTrails(event: any) {
    event.preventDefault();

    var scrollPosition = window.pageYOffset;
    var windowSize = window.innerHeight;
    var bodyHeight = document.body.offsetHeight;

    const bottomDistance = Math.max(
      bodyHeight - (scrollPosition + windowSize),
      0
    );

    if (this.fetching) {
      return;
    }

    if (
      bottomDistance < 300 &&
      (this.nextToken['eidlastEvalKey'] ||
        this.nextToken['uidlastEvalKey'] ||
        (this.dataFilter == 'range' &&
          this.endDate != this.filterDate.format('DD/MM/YYYY')))
    ) {
      this.load(this.filterDate);
    }
  }

  getDates() {
    return Object.keys(this.auditList);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
    document.removeEventListener('scroll', this.loadMoreTrails);
    this.destroyed = true;
  }

  colors: any = {};
  actions: any = [];
  users: any = [];
  usersAppended: any = [];

  getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }

  user_favicon(username: string, user_name_parsed_requrired: boolean = false) {
    var user_favicon_text = '';
    var user_names;
    var username_name_parsed = '';
    if (username == '') {
      if (user_name_parsed_requrired) {
        return { user_full_name: 'Not Assigned', user_favicon_parsed: 'NA' };
      }
      return 'Not Assigned';
    }
    username = username.trim();
    if (!username.includes('@')) {
      user_names = username.split(' ');
    } else {
      user_names = username.split('@')[0].split('.');
    }
    if (user_names.length > 0) {
      user_favicon_text += user_names[0][0].trim();
      username_name_parsed += user_names[0].trim();
      if (user_names.length > 1) {
        if (user_names[user_names.length - 1].length > 0) {
          user_favicon_text += user_names[user_names.length - 1][0].trim();
          username_name_parsed +=
            ' ' + user_names[user_names.length - 1].trim();
        }
      } else {
        user_favicon_text += ' ';
      }
    }
    if (user_name_parsed_requrired) {
      return {
        user_full_name: username_name_parsed,
        user_favicon_parsed: user_favicon_text,
      };
    }
    return user_favicon_text.toUpperCase();
  }

  loadMoreData(event: any) {
    event.preventDefault();
    if (event.target.classList.contains('more_info_container_expanded')) {
      event.target.querySelector('.more_info_container').style.maxHeight =
        0 + 'px';
    } else {
      event.target.querySelector('.more_info_container').style.maxHeight =
        event.target.querySelector('.more_info_container_main').offsetHeight +
        'px';
    }
    event.target.classList.toggle('more_info_container_expanded');
  }

  reset() {
    let date: string;
    this.nextToken = {};
    this.auditList = {};
    if (this.dataFilter == 'date') {
      let element: any = document.querySelector('#date-selector');
      date = element.value;
      this.filterDate = moment(date, 'DD/MM/YYYY');
    } else if (this.dataFilter == 'range') {
      let element: any = document.querySelector('#date-selector-range');
      date = element.value;
      this.filterDate = moment(date.split(' to ')[1], 'DD/MM/YYYY');
      this.endDate = date.split(' to ')[0];
    } else {
      this.filterDate = moment();
    }
    this.load(this.filterDate);
  }

  fetching: boolean = true;
  nextToken: any = {};
  async load(date: any) {
    if (this.destroyed) {
      return;
    }
    this.fetching = true;
    let data: any = {
      action: 'fectchLogs',
      eid: this.userId,
      acntId: this.accountId,
      eidlastEvalKey: this.nextToken['eidlastEvalKey'],
      uidlastEvalKey: this.nextToken['eidlastEvalKey'],
      actionType: this.selectedAction.replaceAll(' ', '_'),
    };

    data = {
      action: 'customlogs',
      eid: this.userId,
      acntId: this.accountId,
      date: date.format('MM-DD-YYYY'),
      eidlastEvalKey: this.nextToken['eidlastEvalKey'],
      uidlastEvalKey: this.nextToken['eidlastEvalKey'],
      actionType: this.selectedAction.replaceAll(' ', '_'),
    };

    data['actionType'] =
      data['actionType'] == 'all' ? undefined : data['actionType'];

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/getlogs`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      data['actionType'] &&
      data['actionType'] != this.selectedAction.replaceAll(' ', '_')
    ) {
      return;
    }

    if (
      result.status == '1' ||
      result.s == '1' ||
      (result.logsList && result.logsList.length > 0)
    ) {
      result.logsList.forEach((log: any) => {
        const date = moment(log.actionTime.split(' ')[0], 'MM-DD-YYYY')
          .format('MMM DD YYYY')
          .toUpperCase();
        if (!this.auditList[date]) {
          this.auditList[date] = [];
        }
        let obj: any = {};
        obj['action_time'] = moment(
          log.actionTime.split(' ')[1],
          'HH:mm:ss'
        ).format('hh:mm:ss A');
        obj['action_raw'] = log.action;
        obj['action'] = log.action.replaceAll('_', ' ');
        if (log.emailId == '' || log.emailId == '-') {
          log.emailId = this.userId;
        }
        let namePrased: any = this.user_favicon(log.emailId, true);
        obj['action_by'] = namePrased['user_full_name'];
        obj['action_by_email'] = log.emailId;
        obj['resource_id'] = log.resourceId;
        if (!this.colors[obj['action']]) {
          this.colors[obj['action']] = this.getRandomColor();
        }
        if (this.usersAppended.indexOf(obj['action_by_email']) <= -1) {
          this.usersAppended.push(obj['action_by_email']);
          this.users.push({
            user_name: obj['action_by'],
            user_email: obj['action_by_email'],
          });
        }
        this.auditList[date].push(obj);
      });
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result['eidlastEvalKey']) {
      this.nextToken['eidlastEvalKey'] = result['eidlastEvalKey'];
    } else {
      this.nextToken['eidlastEvalKey'] = undefined;
    }
    if (result['uidlastEvalKey']) {
      this.nextToken['uidlastEvalKey'] = result['uidlastEvalKey'];
    } else {
      this.nextToken['uidlastEvalKey'] = undefined;
    }

    let scrollPosition = window.pageYOffset;
    let windowSize = window.innerHeight;
    let bodyHeight = document.body.offsetHeight;

    const bottomDistance = Math.max(
      bodyHeight - (scrollPosition + windowSize),
      0
    );
    if (!(result['eidlastEvalKey'] || result['uidlastEvalKey'])) {
      if (this.dataFilter == 'range') {
        if (this.filterDate.format('DD/MM/YYYY') == this.endDate) {
          this.fetching = false;
          return;
        }
        this.filterDate = this.filterDate.subtract(1, 'days');
        if (result.logsList && result.logsList.length == 0) {
          this.load(this.filterDate);
          return;
        }
      } else {
        this.fetching = false;
        return;
      }
    }

    if (bottomDistance < 300) {
      this.load(this.filterDate);
      return;
    }
    this.fetching = false;
  }

  async fetchActions() {
    let data: any = {
      action: 'fetchTypes',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/getlogs`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      result.types = result.types.map((type: any) => {
        type = type.replaceAll('_', ' ');
        return type;
      });
      this.actions = result.types;
    } else {
      this.actions = [];
    }
  }

  async loadClients() {
    let data: any = {
      clientId: this.userId,
      action: 'fetchAllClientUsers',
      ut: this.urlPrefix,
    };

    if (this.urlPrefix == 'admin') {
      data = { userId: this.userId };
    }

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/${
      this.urlPrefix == 'client' ? 'invitev2' : 'list'
    }`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.users = result.cl || result.details;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  callFunction(name: any, param: any) {}
}
