<div class="card">
  <h5 class="card-header">Upload BOM</h5>

  <div class="card-body" [ngStyle]="{'display': stepCount == 1 ? 'block' : 'none'}">

    <div class="uploadbomtextalign" >


      <div class="uploadbomtextalign">
        <div class="bomfilestyle">

          <span onclick="$('#bomFile').click()"
            class="bomfilename"><i
              aria-hidden="true" class="fa fa-cloud-upload"></i></span>
          <span *ngIf="bomFile && bomFile.fileName">
            {{bomFile.fileName}}
          </span>
          <button class="btn btn-light' UpdateandProcess"
            *ngIf="bomFile" (click)="uploadFile()"> Update and Process </button>
        </div>
      </div>


      <!--<span onclick="$('#bomFile').click()" style="
    color: #5864ff;
    font-size: 180px;
    display: block;
    text-align: center;
"><i aria-hidden="true" class="fa fa-cloud-upload"></i></span>

      <button class="btn btn-light" style="background-color: #5864ff; color: white; padding:25px" (click)="uploadFile()">
        Update and
        Process
      </button>-->


    </div>


    <div class="inputdisplay">
      <div>
        <input type="file" id="bomFile" (change)="setFile($event)">
      </div>
    </div>

  </div>

  <div class="card-body" [ngStyle]="{'display': stepCount == 2 ? 'block' : 'none'}">
    <div>
      <div class="buttonstyles">
        <button  class="btn btn-light backbtnstyle"
          (click)="stepCount = 1">Back</button>
        <button class="btn btn-light nextbtnstyle"
          (click)="submitHeaders()">Next</button>

      </div>
      <table>
        <tbody>
          <tr *ngFor="let header of headerList; let i = index">
            <td>{{header.headerKey}}</td>
            <td>
              <select class="form-control" [(ngModel)]="header.headerValue">
                <option value="none">None</option>
                <option value='cpu'>CPU</option>
                <option value='disk'>DISK</option>
                <option value='instanceType'>Instance Type</option>
                <option value='os'>OS</option>
                <option value='ram'>RAM</option>
                <option value='unit'>Unit</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card-body" [ngStyle]="{'display': stepCount == 3 ? 'block' : 'none'}">
    <div>
      <div class="buttonstyles">
        <button class="btn btn-light' backbtnstyle"
          (click)="stepCount = stepCount - 1">Back</button>
        <button class="btn btn-light' backbtnstyle" (click)="addBOMEntry()">Add BOM
          Details</button>
        <button class="btn btn-light' backbtnstyle"
          (click)="submitBOMDetails()">Submit</button>
      </div>
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th *ngFor="let h of headers">
              {{h.name}}
            </th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody [ngStyle]="{'display' : mappedData.length > 0 ? 'table-row-group' : 'nonw'}">
          <tr *ngFor="let i of mappedData;let index = index">
            <td>{{index + 1}}</td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="i.os">
            </td>
            <td>
              <span type="text">{{i.instanceType}}</span>
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="i.cpu">
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="i.disk">
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="i.quantity">
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="i.ram">
            </td>
            <td>
              <select class="form-control" [(ngModel)]="i.billingType">
                <option *ngFor="let type of billingTypes" value="{{type}}">{{type}}</option>
              </select>
            </td>
            <td>
              <input class="form-control" type="text" [(ngModel)]="i.usageHours">
            </td>
            <td><span class="btn"><i class="fa fa-trash" (click)="deleteDetail(index)"></i></span></td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': mappedData.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="headers.length + 2">
              <div class="uploadbomtextalign">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<error-upload-bom *ngIf="fileExists" (hideModel)="uploadFileError($event)"></error-upload-bom>
<update-bom *ngIf="selectedBOM" [action]="'local'" [bom]="selectedBOM" (hideModel)="hideModel($event)"></update-bom>