import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import { regions } from 'src/app/dash/dash.component';

declare let window: any;
declare let Jhxlsx: any;

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.css'],
})
export class SalesReportComponent implements OnInit {
  regions: any = regions;
  rows: any = [];
  @ViewChild('fileupload') fileupload: ElementRef;

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {}

  addRow() {
    this.rows.push({
      uuid: window.uuidv4(),
      region: 'ap-south-1',
      os: '',
      instanceType: '',
      orgHours: '',
      spHours: '',
    });
  }

  headers: any = {
    Region: 'region',
    OS: 'os',
    'Instance Type': 'instanceType',
    'Org Hours': 'orgHours',
    'SP Hours': 'spHours',
    Hours: 'hours',
    'OD Rate': 'odRate',
    'OD Cost': 'odCost',
    'One Year Standard Rate': 'oneYrStdRate',
    'One Year Standard Cost': 'oneYrStdCost',
    'Three Year Standard Rate': 'threeYrStdRate',
    'Three Year Standard Cost': 'threeYrStdCost',
    'One Year Convertible Rate': 'oneYrConvRate',
    'One Year Convertible Cost': 'oneYrConvCost',
    'Three Year Convertible Rate': 'threeYrConvRate',
    'Three Year Convertible Cost': 'threeYrConvCost',
  };

  removeRow(index: number) {
    this.rows.splice(index, 1);
  }

  async fetch() {
    this.notifier.loading(true);
    let data: any = {
      a: 'fetchReport',
      data: this.rows,
    };

    let apiURL: string = `https://api.swayam.cloud/v3/admin/billing/salesreport`;

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let result: any = await this.apiService.postDataPromis(
      apiURL,
      data,
      header
    );

    if (result.s == '1' || result.status == '1') {
      this.rows = result.data;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  checkFile(event: any) {
    let file = event.target.files[0];

    this.fileupload.nativeElement.value = '';

    if (
      file.name.indexOf('.xlsx') > -1 ||
      file.name.indexOf('.xls') > -1 ||
      file.name.indexOf('.csv') > -1
    ) {
      var r = new FileReader();
      r.onload = (e) => {
        var contents = window.processExcel(e.target.result);
        let data = JSON.parse(contents);
        data = data[Object.keys(data)[0]];
        let headers: any = data[0];
        let valid = true;
        ['Region', 'OS', 'Instance Type', 'Org Hours', 'SP Hours'].forEach(
          (element: any) => {
            if (headers.indexOf(element) <= -1) {
              valid = false;
            }
          }
        );
        if (!valid) {
          this.notifier.alert('Info', '', 'Not a Valid File', 'info', 5000);
          return;
        }
        let dt: any = [];
        for (let i = 1; i < data.length; i++) {
          if (data[i].length == 0) continue;
          let obj: any = { uuid: window.uuidv4() };
          for (let j = 0; j < headers.length; j++) {
            obj[this.headers[headers[j]]] = isNaN(data[i][j])
              ? data[i][j]
              : Number(data[i][j]);
          }
          this.rows.push(obj);
        }
      };
      r.readAsBinaryString(file);
    } else {
      this.notifier.alert('Info', '', 'File is not supported', 'info', 5000);
    }
  }

  export() {
    let data: any = [
      {
        sheetName: 'Sales Report',
        data: [
          [
            { text: 'Sl. No.' },
            { text: 'Region' },
            { text: 'OS' },
            { text: 'Instance Type' },
            { text: 'Org Hours' },
            { text: 'SP Hours' },
            { text: 'Hours' },
            { text: 'OD Rate' },
            { text: 'OD Cost' },
            { text: 'One Year Standard Rate' },
            { text: 'One Year Standard Cost' },
            { text: 'Three Year Standard Rate' },
            { text: 'Three Year Standard Cost' },
            { text: 'One Year Convertible Rate' },
            { text: 'One Year Convertible Cost' },
            { text: 'Three Year Convertible Rate' },
            { text: 'Three Year Convertible Cost' },
          ],
        ],
      },
    ];

    this.rows.map((row: any, index: number) => {
      data[0]['data'].push([
        { text: index + 1 },
        { text: row?.region || '-' },
        { text: row?.os || '-' },
        { text: row?.instanceType || '-' },
        { text: row?.orgHours || '-' },
        { text: row?.spHours || '-' },
        { text: row?.hours || '-' },
        { text: row?.odRate?.toFixed(4) || '-' },
        { text: row?.odCost?.toFixed(4) || '-' },
        { text: row?.oneYrStdRate?.toFixed(4) || '-' },
        { text: row?.oneYrStdCost?.toFixed(4) || '-' },
        { text: row?.threeYrStdRate?.toFixed(4) || '-' },
        { text: row?.threeYrStdCost?.toFixed(4) || '-' },
        { text: row?.oneYrConvRate?.toFixed(4) || '-' },
        { text: row?.oneYrConvCost?.toFixed(4) || '-' },
        { text: row?.threeYrConvRate?.toFixed(4) || '-' },
        { text: row?.threeYrConvCost?.toFixed(4) || '-' },
      ]);
    });

    var options = {
      fileName: 'Sales Report',
    };

    Jhxlsx.export(data, options);
  }
}
