import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-pipeline-build-tools',
  templateUrl: './pipeline-build-tools.component.html',
  styleUrls: ['./pipeline-build-tools.component.css'],
})
export class PipelineBuildToolsComponent implements OnInit {
  @Input('runtime') runtime: string;
  @Output('hide') hide: any = new EventEmitter<boolean>();
  files: any = [
    {
      file_path: `https://swayam-role-templates.s3.ap-south-1.amazonaws.com/Swayam/CodeCommit/RUNTIME/.gitignore`,
      file_name: '.gitignore',
      save_path: '<repo_root>',
      extention: 'gitignore',
    },
    {
      file_path: `https://swayam-role-templates.s3.ap-south-1.amazonaws.com/Swayam/CodeCommit/RUNTIME/buildspec.yml`,
      file_name: 'buildspec.yml',
      save_path: '<repo_root>',
      extention: 'yml',
    },
    {
      file_path: `https://swayam-role-templates.s3.ap-south-1.amazonaws.com/Swayam/CodeCommit/RUNTIME/sam.template.yaml`,
      file_name: 'sam.template.yaml',
      save_path: '<repo_root>',
      extention: 'yaml',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.files = this.files.map((file: any) => {
      file['file_path'] = file['file_path'].replace('RUNTIME', this.runtime);
      return file;
    });
  }

  download(source: string) {
    const fileName = source.split('/').pop();
    var el = document.createElement('a');
    el.setAttribute('href', source);
    el.setAttribute('download', fileName);
    // el.setAttribute('target', '_blank');
    el.click();
  }
  hideModel() {
    this.hide.emit(true);
  }
}
