<div class="card">
  <h5 class="card-header">
    CFRC Order Report
    <div style="float: right;" [ngStyle]="{display: this.cfrcData.length > 0 ? 'block' : 'none'}">
      <button class="btn btn-primary-soft" (click)="extendCFRC()">
        Extend CFRC
      </button>
    </div>
  </h5>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; align-content: center; gap: 8px;"
      *ngIf="this.cfrcData.length > 0">
      <div style="font-weight: bold;">
        CFRC Period : <select class="form-select" [(ngModel)]="selectedRange">
          <option [ngValue]="range" *ngFor="let range of ranges;">{{ range }}</option>
        </select>
      </div>
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">
            {{h.name}}
          </th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display' : filteredCFRCs().length > 0 ? 'table-row-group': 'none'}">
        <tr *ngFor="let i of filteredCFRCs(); let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span>
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': filteredCFRCs().length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 1000; background-color: rgba(0, 0, 0, 0.1);"
  [ngStyle]="{'display': extend ? 'block': 'none'}">
  <div
    style="position: relative; margin: 10px; gap: 10px; background-color: white; border-radius: 10px; padding: 75px 20px 20px 20px; box-shadow: 1px 1px 3px 1px grey; display: flex; flex-direction: column; max-height: calc(100% - 20px);">
    <div style="position: absolute; top: 10px; right: 10px; padding: 10px; cursor: pointer;" (click)="closeUpload()"
      class="btn btn-light">
      X
    </div>
    <div style="display: flex; justify-content: flex-end; gap: 10px; align-items: center;">
      <label for="">CFRC Period: </label>
      <input type="text" id="dataRangePicker" [(ngModel)]="dateRange" class="form-control" style="width: 300px;">
    </div>
    <div style="overflow: auto; flex: 1;">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th *ngFor="let h of editableHeader">
              {{h.name}}
            </th>
          </tr>
        </thead>

        <tbody [ngStyle]="{'display' : editable.length > 0 ? 'table-row-group': 'none'}">
          <tr *ngFor="let i of editable; let j = index">
            <td *ngFor="let h of editableHeader">
              <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                <span [innerHTML]="i[h.id]">
                </span>
              </span>
              <span *ngIf="!h.click && !h.editable">
                {{h.alter ? h.alter(i[h.id]) : i[h.id]}}
              </span>
              <span *ngIf="!h.click && h.editable">
                <input class="form-control" type="text" [(ngModel)]="i[h.id]">
              </span>
            </td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': editable.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="editableHeader.length">
              <div style="text-align: center;">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="display: flex; justify-content: flex-end; align-items: center; padding: 15px;">
      <button class="btn btn-light" (click)="saveDetails()" style="background-color: #5864FF; color: white;">
        Save Details
      </button>
    </div>
  </div>
</div>