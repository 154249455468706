import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-changerequestapproval',
  templateUrl: './changerequestapproval.component.html',
  styleUrls: ['./changerequestapproval.component.css'],
})
export class ChangerequestapprovalComponent implements OnInit, OnDestroy {
  constructor(
    private router: ActivatedRoute,
    private navigate: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  crid: string;
  action: string;
  accountid: string;
  regionId: string;
  resourceIds: any = [];
  otp: string;
  request_id: string;
  otpValidated: boolean = false;
  loading: boolean = false;
  crs: any = [];
  jira_project_id: string;
  jira_organization_id: string;

  ngOnInit() {
    this.router.queryParams.subscribe((res) => {
      this.crid = res['cr_id'];
      this.action = res['action'];
      this.regionId = res['region_id'];
      this.accountid = res['account_id'];
      this.resourceIds = res['resource_ids']
        ? res['resource_ids'].split(',')
        : [];
      if (res['sr']) {
        let data: any = JSON.parse(window.atob(res['sr']));
        if (data) {
          this.jira_project_id = data['jira_project_id'];
          this.jira_organization_id = data['jira_organization_id'];
        }
      }
    });

    this.sendOTP();
  }

  message: string;
  setMessage(msg: string) {
    this.message = msg;
  }
  async sendOTP() {
    this.loading = true;
    this.request_id = null;
    this.otpValidated = false;
    let data = {
      action: 'ec2_change_request_review_otp',
      id_token: localStorage.getItem('idT'),
      cr_id: this.crid,
      region_id: this.regionId,
      account_id: this.accountid,
      resource_ids: this.resourceIds,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.request_id = result.request_id;
    } else {
      this.message = result.error_message || result.message;
      // this.notifier.alert(
      //   'Error',
      //   '',
      //   result.error_message || result.message,
      //   'error',
      //   5000
      // );
    }
    this.loading = false;
  }

  async validateOTP() {
    this.loading = true;
    let data = {
      action: 'ec2_change_request_review_otp_validate',
      id_token: localStorage.getItem('idT'),
      otp: this.otp,
      status: this.action,
      request_id: this.request_id,
      region_id: this.regionId,
      account_id: this.accountid,
      cr_id: this.crid,
      resource_ids: this.resourceIds,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.otpValidated = true;
      this.crs = result.crs;
    } else {
      this.notifier.alert(
        'Error',
        '',
        result.error_message || result.message,
        'error',
        5000
      );
    }

    this.loading = false;
  }

  hide(event: boolean) {
    if (event) {
      this.otpValidated = false;
      this.request_id = null;
      this.loading = false;
      this.setMessage('CR is Updated');
    }
  }

  ngOnDestroy(): void {}
}
