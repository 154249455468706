import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from './../../../_services/notifier.service';
declare let window: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './patch.component.html',
})
export class patchComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  nextToken: any = null;
  current_instance: any = null;

  patchReportList: any = [];
  innerData: any = [];
  missing_patches: boolean = false;

  funRef: any;

  headers: any = [
    {
      id: 'instanceId',
      name: 'Instance Id',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'loadMissingPatches',
    },
    {
      id: 'instanceName',
      name: 'Instance Name',
    },
    {
      id: 'instanceType',
      name: 'Instance Type',
    },
    {
      id: 'instanceState',
      name: 'Instance Status',
    },
    {
      id: 'platformType',
      name: 'Platform Type',
    },
    {
      id: 'platformVersion',
      name: 'Platform Version',
    },
    {
      id: 'ssmAgentPingStatus',
      name: 'SSM Agent Ping Status',
    },
    {
      id: 'ssmAgentVersion',
      name: 'SSM Agent Version',
    },
  ];

  innerdataHeaders: any = [
    {
      id: 'kbid',
      name: 'KBID',
    },
    {
      id: 'cveids',
      name: 'CVEIDS',
    },
    {
      id: 'title',
      name: 'Title',
    },
    {
      id: 'state',
      name: 'State',
    },
    {
      id: 'severity',
      name: 'Severity',
    },
    {
      id: 'classification',
      name: 'Classification',
    },
  ];
  nextTokenPatches: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      this.nextToken = null;
      this.nextTokenPatches = null;
      this.current_instance = null;

      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    //this.load();
    this.funRef = {
      loadMissingPatches: this.loadMissingPatches,
    };
  }

  ngOnDestroy(): void {
    this.nextToken = null;
    this.nextTokenPatches = null;
    this.current_instance = null;
    this.missing_patches = false;
    this.loading = false;
    this.currentMessage.unsubscribe();
  }

  loadMorePatches() {
    this.load();
  }

  async load() {
    this.notifier.loading(true);
    this.missing_patches = false;
    if (!this.nextToken) {
      this.patchReportList = [];
    }
    let data = {
      accountId: this.accountId,
      action: 'FleetManagerEC2',
      region: this.regionId,
      nextToken: this.nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'Compliance.PatchReport',
      'FleetManagerEC2 Fetched'
    );
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/patches`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.patchReportList = [
        ...this.patchReportList,
        ...result.fleetManagerEC2List,
      ];
    } else {
      if (result.msg != 'No pending patches found') {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      }
    }
    if (result.nextToken) {
      this.nextToken = result.nextToken;
    } else {
      this.nextToken = null;
    }

    this.notifier.loading(false);
  }

  async loadMissingPatches(ref: any, id: any) {
    ref.loading = true;
    ref.current_instance = id;
    ref.missing_patches = true;
    let data = {
      accountId: ref.accountId,
      action: 'MissingPatches',
      region: ref.regionId,
      instanceId: id.instanceId,
      nextToken: ref.nextTokenPatches,
    };

    if (!ref.nextTokenPatches) {
      ref.innerData = [];
    }

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'Compliance.PatchReport',
      'MissingPatches Fetched'
    );
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/compliance/patches`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    if (
      ref.current_instance == null ||
      data.instanceId != ref.current_instance.instanceId ||
      ref.missing_patches == false
    ) {
      return;
    }
    if (result.status == '1' || result.s == '1') {
      ref.innerData = [...ref.innerData, ...result.missingPatchesList];
    } else {
      let error = result.error;
      if (!error) {
        error = result.msg;
      }

      if (!error) {
        error = 'Data Not Found';
      }

      ref.notifier.alert('Info', '', error, 'info', 5000);
    }

    if (result.nextToken) {
      ref.nextTokenPatches = result.nextToken;
      await ref.loadMissingPatches(ref, ref.current_instance);
    } else {
      ref.nextTokenPatches = null;
    }
    ref.loading = false;
  }

  goBack() {
    this.missing_patches = false;
    this.current_instance = null;
    this.innerData = [];
    this.nextTokenPatches = null;
    this.loading = false;
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel(
      'patch-reports-id',
      `patchReport-${this.current_instance.instanceId}-${this.accountId}-${this.regionId}`,
      'csv'
    );
  }

  callFunction(name: any, param: any) {
    this.nextTokenPatches = null;
    this.funRef[name](this, param);
  }
}
