import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';

declare let window: any;

@Component({
  templateUrl: './ec2.operations.component.html',
})
export class Betaec2OperationsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  instancesList: any = [];
  showTagsModal: any = null;
  instance: any = null;
  command: string = null;
  modalTitle: any;
  headers: any = [
    {
      id: 'ResourceName',
      name: 'Tag Name',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
    {
      id: 'InstanceType',
      name: 'Instance Type',
      sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
    { id: 'AvailabilityZone', name: 'Availability Zone' },
    {
      id: 'statusCheck',
      name: 'Status Check',
      color: (value: string) => (value != '2/2' ? 'red' : 'green'),
    },
    {
      id: 'ipdata',
      name: 'Private IP / Public IP',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'State',
      name: 'Instance Status',
      statusCheck: true,
    },
  ];

  funRef: any;

  accountId: any;
  regionId: any;
  currentMessage: any;
  action: string;
  filterText = '';
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.funRef = {
      showData: this.showData,
    };
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      this.hideMdoal(true);
      this.hideDetails(true);
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async downloadConsolidatedReport() {
    this.notifier.loading(true);
    let data = {
      action: 'fetch',
      userId: this.userId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/infrareport`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '') {
      if (result.url) {
        window.open(result.url, '_blank');
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.msg || result.message || result.error_message || result.errorMsg,
        'info',
        5000
      );
    }

    this.notifier.loading(false);
  }

  async load(nextToken?: any) {
    if (!nextToken) {
      this.instancesList = [];
    }
    this.notifier.loading(true);
    let selectedClientData = JSON.parse(localStorage.getItem('clientList'));
    let client_id;
    try {
      client_id = selectedClientData.find((client: any) => {
        return client.emailId == localStorage.getItem('selectedClientList');
      })['clientIdList'];
    } catch (err) {
      client_id = localStorage.getItem('clientId');
    }
    let data = {
      action: 'list_ec2',
      client_id: client_id,
      account_id: this.accountId,
      region_name: this.regionId,
      module: 'operations/ec2-table',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2v2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.instancesList = result.instances;
      this.instancesList.forEach((instance: any, index: number) => {
        instance['actionStart'] = false;
        instance.State = 'LOADING';
        instance.statusCheck = 'LOADING';
        instance.ipdata =
          (instance.PrivateIpAddress != 'NA'
            ? instance.PrivateIpAddress.toString()
            : '') +
          (instance.PublicIpAddress != 'NA'
            ? '/' + instance.PublicIpAddress.toString()
            : '');
      });
      if (result.instances.length > 0) {
        this.loadState(result.instances);
      }
      if (result.nextToken && result.nextToken != '') {
        await this.load(result.nextToken);
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async loadState(instnaces: any) {
    let data = {
      action: 'ec2_status',
      account_id: this.accountId,
      region_name: this.regionId,
      resource_ids: instnaces.map((instance: any) => {
        return instance.ResourceId;
      }),
      login_user_name: this.userId,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1') {
      instnaces = instnaces.map((instance: any) => {
        if (result[instance['ResourceId']]) {
          instance['State'] = result[instance['ResourceId']].state;
          instance['statusCheck'] =
            result[instance['ResourceId']].status_checks;
        }
        return instance;
      });
      let check = instnaces.filter((instance: any) => {
        if (instance.State == 'running' || instance.State == 'stopped') {
          return false;
        }
        return true;
      });
      if (check.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      this.notifier.alert(
        'Error',
        '',
        result.error_message || result.message,
        'error',
        5000
      );
    }
    return false;
  }

  instanceConfirm: any = null;
  title: String;
  message: String;
  triggerInstances(event: any, trigger: string) {
    event.preventDefault();
    if (!this.writeAccess) {
      return;
    }
    if (trigger == 'start_ec2') {
      this.modalTitle = 'start';
      this.action = 'start';
    } else if (trigger == 'stop_ec2') {
      this.action = 'stop';
      this.modalTitle = 'stop';
    } else if (trigger == 'reboot_ec2') {
      this.action = 'reboot';
      this.modalTitle = 'reboot';
    }
    this.command = trigger;
  }

  getSelectedInstances() {
    return this.instancesList.filter((instance: any) => {
      return instance.actionStart;
    });
  }

  getSelectedRunningInstances() {
    return this.instancesList.filter((instance: any) => {
      return instance.actionStart && instance.State == 'running';
    }).length;
  }

  async hideStartStop(event: any) {
    this.command = null;
    let check = false;
    if (event) {
      let dt = this.instancesList.filter((instance: any) => {
        if (instance.actionStart) {
          instance['rawState'] = undefined;
          instance['State'] = 'LOADING';
        }
        return instance.actionStart;
      });
      do {
        dt = dt.filter((instance: any) => {
          if (instance.State == 'stopped' || instance.State == 'running') {
            return false;
          }
          instance['rawState'] = instance['State'];
          instance['State'] = 'LOADING';
          return true;
        });
        if (dt.length > 0) {
          check = await this.loadState(dt);
        }
        console.log(check);
      } while (!check);
    }
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  selected_ec2: any;
  showData(ref: any, value: any) {
    if (ref.instance) {
      ref.instance['actionStart'] = false;
    }

    value['actionStart'] = true;
    ref.selected_ec2 = value;
    ref.instance = value;
    ref.instance = {
      ...ref.instance,
      ...{
        instanceId: value['ResourceId'],
        imageID: '-',
        instanceType: value['InstanceType'],
        State: value['State'],
        tagName: value['ResourceName'],
        privateIp: value['PrivateIpAddress'],
        isHibernateEnabled: false,
        platform: value['PlatformDetails'],
        workloadType: '',
        actionStart: true,
        publicIp: value['PublicIpAddress'],
        availabilityZone: value['AvailabilityZone'],
      },
    };
  }

  showTags(data: any) {
    this.showTagsModal = data;
  }

  getStartTriggerInstances() {
    return this.instancesList
      ? this.instancesList.filter((instance: any) => {
          return instance.actionStart;
        })
      : [];
  }

  singleStartStop(event: any, instance: any, trigger: any) {
    this.instancesList.forEach((instance: any) => {
      instance['actionStart'] = false;
    });
    instance.actionStart = true;
    this.triggerInstances(event, trigger);
  }
  hideMdoal(event: any) {
    this.showTagsModal = null;
  }

  instanceStartStop(event: any) {
    this.selected_ec2['actionStart'] = true;
    if (event == 'start') {
      this.modalTitle = 'start';
      this.action = 'start';
    } else if (event == 'stop') {
      this.action = 'stop';
      this.modalTitle = 'stop';
    }
    this.command = event;
  }

  hideDetails(event: any) {
    if (this.instance) {
      this.instance['actionStart'] = false;
      this.instance = null;
    }
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
