import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-pipeline-create',
  templateUrl: './pipeline-create.component.html',
  styleUrls: ['./pipeline-create.component.css'],
})
export class PipelineCreateComponent implements OnInit {
  @Output('hide') hide = new EventEmitter<boolean>();
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  pipelinePayload: any = {
    action: 'setup_pipeline',
    module_name: '',
    description: '',
    runtime: 'Python',
    id_token: localStorage.getItem('idT'),
    build_environment_variables: {
      dev: [
        { name: 'API_GATEWAY_ID', value: 'lgf0ofd3jl', type: 'PLAINTEXT' },
        { name: 'API_GATEWAY_STAGE', value: 'v3', type: 'PLAINTEXT' },
        { name: 'ADMIN_AUTHORIZER_ID', value: '9dnvy8', type: 'PLAINTEXT' },
        { name: 'CLIENT_AUTHORIZER_ID', value: 'pngzz8', type: 'PLAINTEXT' },
        { name: 'ENV', value: 'Dev', type: 'PLAINTEXT' },
      ],
      beta: [
        { name: 'API_GATEWAY_ID', value: '35ut57v60m', type: 'PLAINTEXT' },
        { name: 'API_GATEWAY_STAGE', value: 'v3', type: 'PLAINTEXT' },
        { name: 'ADMIN_AUTHORIZER_ID', value: 't2uezm', type: 'PLAINTEXT' },
        { name: 'CLIENT_AUTHORIZER_ID', value: 'd92rid', type: 'PLAINTEXT' },
        { name: 'ENV', value: 'Beta', type: 'PLAINTEXT' },
      ],
      prod: [
        { name: 'API_GATEWAY_ID', value: 'hv120lydu2', type: 'PLAINTEXT' },
        { name: 'API_GATEWAY_STAGE', value: 'v3', type: 'PLAINTEXT' },
        { name: 'ADMIN_AUTHORIZER_ID', value: '73grsv', type: 'PLAINTEXT' },
        { name: 'CLIENT_AUTHORIZER_ID', value: '6ec95g', type: 'PLAINTEXT' },
        { name: 'ENV', value: 'Prod', type: 'PLAINTEXT' },
      ],
    },
  };

  ngOnInit(): void {}

  getObjectKeys(obj: any) {
    return Object.keys(obj);
  }

  async fetchPipeline() {
    this.notifier.loading(true);
    let payload: any = JSON.parse(JSON.stringify(this.pipelinePayload));
    Object.keys(payload['build_environment_variables']).forEach(
      (element: any) => {
        payload['build_environment_variables'][element].push({
          name: 'MODULE_NAME',
          value: payload['module_name'],
          type: 'PLAINTEXT',
        });
      }
    );
    let data = payload;

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/pipeline/automation`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.message, 'success', 5000);
      this.hideModel(true);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  hideModel(event?: any) {
    this.hide.emit(event);
  }
}
