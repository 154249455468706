<div class="minfyui_changerequestapproval_container" *ngIf="!otpValidated && request_id">
  <div class="otp_vertification" name="otp_verfication">
    <form>
      <div class="otp_form_container">
        <div class="otp_container_header">OTP Verification</div>
        <div class="otp_container_description">Enter the OTP sent to your Email ID</div>
      </div>
      <div>
        <input type="text" class="form-control" name="otp_text" [(ngModel)]="otp">
      </div>
      <div class="otp_actions">
        <button type="submit" (click)="validateOTP()" class="btn btn-light" [ngClass]="{disabled_btn: loading}">
          <span *ngIf="loading">
            <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 25px; padding-right: 10px;" />
          </span>
          <span style="color: inherit;" *ngIf="!loading">
            Verify & Proceed
          </span>
        </button>
      </div>
    </form>
  </div>
</div>
<div *ngIf="!otpValidated && !request_id && !loading" class="minfyui_changerequestapproval_container">
  <div style="text-align: center;">
    <h3>{{ message }}</h3>
  </div>
</div>
<div *ngIf="!otpValidated && !request_id && loading" class="minfyui_changerequestapproval_container">
  <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 30px; padding-right: 10px;" />
</div>
<div *ngIf="otpValidated">
  <app-crdetails [crdetail]="crs[0]" [accountId]="accountid" [regionId]="regionId" [cr_id]="crid"
    [jira_project_id]="jira_project_id" [jira_organization_id]="jira_organization_id" [setMessage]="setMessage"
    (validated)="hide($event)">
  </app-crdetails>
</div>