<div class="card mt-20" style="border: none; position: relative; height: 100%;">
  <h5 class="card-header">ERP Integrations</h5>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; gap: 4px; align-items: center;">
      <input type="text" class="form-control datetimepicker" style="width: 300px;">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="updateTrigger()">Add
      </button>
      <button class="btn btn-light" style="background-color: #5864FF; color: white;" *ngIf="erps.length > 0"
        (click)="export()">Export CSV
      </button>
    </div>
    <div class="overflow-auto">
      <table class="table table-docs table-hover" id="erp-table">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th *ngFor="let h of headers">{{ h.name }}</th>
          </tr>
        </thead>

        <tbody *ngFor="let i of erps; let j = index"
          [ngStyle]="{'display': erps.length > 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td>{{j + 1}}</td>
            <td *ngFor="let h of headers">
              <span *ngIf="!h.click" [innerHTML]="i[h.id] || '-'"> </span>
              <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || 0" (click)="callFunction(h.click, i)">
              </span>

            </td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': erps.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="headers.length + 3">
              <div style="text-align: center;">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-add *ngIf="addErp" (hideModal)="hideModel($event)"></app-add>