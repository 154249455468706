<style>
  .nav-link {
    cursor: pointer;
    text-decoration: underline;
  }
</style>
<div class="card">
  <h5
    class="card-header"
    style="
      display: flex;
      justify-content: flex-center;
      align-items: center;
      gap: 8px;
    "
  >
    Margin percentage consolidation
  </h5>
  <div class="card-body">
    <div
      style="
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 8px;
      "
    >
      <div>
        <button
          class="btn btn-light"
          style="background-color: #5864ff; color: white"
          (click)="refresh()"
        >
          Refresh
        </button>
      </div>
      <input
        class="datetimepicker"
        id="monthDate"
        type="text"
        placeholder="select a month"
        style="padding: 6px"
      />

      <select
        style="width: 300px"
        class="form-select"
        data-live-search="true"
        [(ngModel)]="discountType"
        (change)="onDiscountTypeChange($event)"
      >
        <option value="" disabled style="font-weight: bold">
          -- Select discount type --
        </option>
        <option value="All">All</option>
        <option value="SPP">SPP</option>
        <option value="EDP">EDP</option>
      </select>

      <div>
        <button
          class="btn btn-light"
          style="background-color: #5864ff; color: white"
          (click)="downloadAll()"
        >
          Download {{ discountType }}
        </button>
      </div>
    </div>
    <div style="max-width: 100%; overflow: auto">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th *ngFor="let h of mainHeader">
              {{ h.name }}
              <span
                *ngIf="h.sort && h.sort.sort"
                (click)="notifier.sortTable(h, marginConsolidationData)"
              >
                <i
                  class="fa fa-sort-amount-desc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Des'"
                ></i>
                <i
                  class="fa fa-sort-amount-asc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Asc'"
                ></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input type="text" [(ngModel)]="h.filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
            <th>Export</th>
          </tr>
        </thead>
        <tbody *ngFor="let data of marginConsolidationData; let j = index">
          <tr *ngIf="notifier.filterTableColums(mainHeader, data)">
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of mainHeader">
              <span
                *ngIf="h.click"
                class="nav-link"
                (click)="h.click(data[h.clickId])"
              >
                {{ data[h.id] }}
              </span>
              <span *ngIf="!h.click">
                {{ h.alter ? h.alter(data[h.id]) : data[h.id] }}
              </span>
            </td>
            <td>
              <span class="btn btn-link" (click)="exportEach(data['data'])"
                >Download CSV</span
              >
            </td>
          </tr>
          <tr *ngIf="expandedState[data.key]">
            <td colspan="4">
              <table class="table table-docs table-hover">
                <thead>
                  <tr>
                    <th>Sl. No.</th>
                    <th *ngFor="let h of subheader">
                      {{ h.name }}
                      <span
                        *ngIf="h.sort && h.sort.sort"
                        (click)="notifier.sortTable(h, data.data)"
                      >
                        <i
                          class="fa fa-sort-amount-desc"
                          aria-hidden="true"
                          *ngIf="h.sort.direction == 'Des'"
                        ></i>
                        <i
                          class="fa fa-sort-amount-asc"
                          aria-hidden="true"
                          *ngIf="h.sort.direction == 'Asc'"
                        ></i>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody *ngFor="let value of data.data; let i = index">
                  <tr>
                    <td>{{ i + 1 }}</td>
                    <td *ngFor="let h of subheader">{{ value[h.id] }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
