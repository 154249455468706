import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import * as moment from 'moment';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from '../../../api/api.service';

declare let $: any;
declare let window: any;
@Component({
  templateUrl: './minfyridetailswithsputilizedamount.component.html',
})
export class Minfyridetailswithsputilizedamount implements OnInit, OnDestroy {
  dummyData: any = [];
  accountId: any;
  funRef: any = {};
  list: any = [];
  currentMessage: any;
  dateRange: string;
  loading: boolean = true;

  constructor(
    public notifier: NotifierService,
    private apiService: APIService,
    private zone: NgZone
  ) {}
  customHeaders: any = {
    'Total OD': {
      sort: { sort: true, direction: 'Asc' },
    },
    'Last 7 Days OD': {
      sort: { sort: true, direction: 'Asc' },
    },
  };
  headers: any = [
    {
      id: 'accountId',
      name: 'Account ID',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
    },
    {
      id: 'accountName',
      name: 'Account Name',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
    },
    {
      id: 'sp_purchased',
      name: 'SP Purchased',
      total: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'sp_utilized',
      name: 'SP Utilized (%)',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'sp_covered',
      name: 'SP Covered (%)',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'sp_unutilized',
      name: 'SP Un-utilized (%)',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'sp_utilized_amount',
      name: 'SP Utilized Amount (%)',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'ri_purchased',
      name: 'RI Purchased',
      total: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'ri_utilized',
      name: 'RI Utilized (%)',
      sort: { sort: true, direction: 'Asc' },
    },
  ];
  onAccountSelectionChange(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue === 'all') {
      const allValue = this.dummyData;
      console.log('>>>data all' + allValue);
    } else {
      console.log('>>>data selectedValue' + selectedValue);
    }
  }

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.funRef = {};

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load(true);
      }
    });
    $('.datetimepicker').val(moment().startOf('month').format('YYYY-MM'));
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'yyyy-mm',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });
    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) => {
        this.load();
      },
    };
  }
  getColumnTotal(h: any) {
    let total: number = 0;
    this.list.forEach((account: any) => {
      if (account[h] && account[h] != '-') {
        total += Number(account[h]);
      }
    });
    return total.toFixed(2);
  }

  async load(refresh: boolean = false, nextToken: any = undefined) {
    if (refresh || !nextToken) {
      this.list = [];
    }
    this.loading = true;
    let monthYear = moment().startOf('month').format('YYYY-MM');
    let dRange = $('.datetimepicker').val();
    if (dRange) {
      dRange = dRange.toString().split('-');
      monthYear = dRange[0] + '-' + dRange[1];
    }
    this.dateRange = monthYear;

    console.log('refresh button');
    this.dummyData = ['2323121', '321212121'];
  }
  ngOnDestroy(): void {
    this.dateRange = 'destroyed';
    this.currentMessage.unsubscribe();
  }
}
