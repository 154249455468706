<div class="amis_body">
  <div class="amis_more">
    <div>
      <div class="mb-3">
        <label for="" style="font-size: 0.9em; font-weight: bold;">Disk Size</label>
        <input type="text" name="" id="" placeholder="Disk Size" disabled
          [attr.value]="instance['disks'][0]['Ebs']['VolumeSize']">
      </div>
      <div class="mb-3">
        <label for="" style="font-size: 0.9em; font-weight: bold;">Disk Type</label>
        <div>gp3</div>
      </div>
    </div>
  </div>
</div>