import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
})
export class AddComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  uid = localStorage.getItem('t');
  t = localStorage.getItem('t');
  ut = localStorage.getItem('ut');
  eId = localStorage.getItem('eId');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  customerId: any;
  erpOrderId: any;
  accountId: any;
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  scan_name: string = '';
  loading: boolean = false;

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  async yes() {
    await this.createJob();
    return;
  }

  no(check: boolean = false) {
    this.hideModal.emit(check);
  }

  async createJob() {
    this.loading = true;
    let data: any = {
      a: 'add',
      customerId: this.customerId,
      accountId: this.accountId,
      erpOrderId: this.erpOrderId,
      customerName: this.scan_name,
    };

    let headers: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `https://api.swayam.cloud/v3/admin/billing/asiscustomers`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('success', '', result.msg, 'success', 5000);
      this.no(true);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.loading = false;
  }
}
