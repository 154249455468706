import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

declare let navigator: any;

@Component({
  selector: 'app-pipelines-dev',
  templateUrl: './pipelines-dev.component.html',
  styleUrls: ['./pipelines-dev.component.css'],
})
export class PipelinesDevComponent implements OnInit, OnDestroy {
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  accountId: string;
  regionId: string;
  currentMessage: any;
  selectedPipeline: any;
  pipelines: any = [];
  createPipeline: boolean = false;
  showBuildFiles: boolean = false;
  selectedPipelineIndex: number;
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  pipelineID: string;
  searchKey: string = '';

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.activatedRoute.queryParams.subscribe((res: any) => {
      if (res['id']) {
        this.pipelineID = res['id'];
        // TODO: remove swayam-pipeline-portal-internal query from queryParameters
        // TODO: remove this code later and place this code response handler after fetching pipelines
      } else {
        this.pipelineID = null;
        this.selectedPipeline = null;
      }
    });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  filteredPipeLines() {
    if (this.searchKey.length == 0) {
      return this.pipelines;
    }
    return this.pipelines.filter((pipeline: any) => {
      if (
        pipeline['repo_name']
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) > -1
      ) {
        return true;
      }
      if (
        pipeline['prod_branch']
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) > -1
      ) {
        return true;
      }
      if (
        pipeline['created_by']
          .toLowerCase()
          .indexOf(this.searchKey.toLowerCase()) > -1
      ) {
        return true;
      }
      return false;
    });
  }

  loading: any = {
    pipelines: false,
  };
  load() {
    this.fetchPipelines();
  }

  showPipelines() {
    // TODO: remove swayam-pipeline-portal-internal query from queryParameters
    this.router.navigate(['/dev/pipelines']);
  }

  user_favicon(
    username: string,
    user_name_parsed_requrired: boolean = false
  ): any {
    var user_favicon_text = '';
    var user_names;
    var username_name_parsed = '';
    if (username == '') {
      return 'Anonymous';
    }
    username = username.trim();
    if (!username.includes('@')) {
      user_names = username.split(' ');
    } else {
      user_names = username.split('@')[0].split('.');
    }
    if (user_names.length > 0) {
      user_favicon_text += user_names[0][0].trim();
      username_name_parsed += user_names[0].trim();
      if (user_names.length > 1) {
        if (user_names[user_names.length - 1].length > 0) {
          user_favicon_text += user_names[user_names.length - 1][0].trim();
          username_name_parsed +=
            ' ' + user_names[user_names.length - 1].trim();
        }
      } else {
        user_favicon_text += ' ';
      }
    }
    if (user_name_parsed_requrired) {
      return {
        user_full_name: username_name_parsed.trim(),
        user_favicon_parsed: user_favicon_text.trim(),
      };
    }
    return user_favicon_text.toUpperCase();
  }

  copyURL(url: string) {
    if (url) {
      const textArea = document.createElement('textarea');
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
      this.notifier.alert('Success', '', 'Clone URL Copied', 'success', 5000);
    }
  }

  timeSince(date: any) {
    let dest: any = new Date(date);
    let today: any = new Date();

    var seconds = Math.floor((today - dest) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + ' years';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' months';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' days';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' hours';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' minutes';
    }
    return Math.floor(seconds) + ' seconds';
  }
  async fetchPipelines() {
    let data = {
      action: 'list_pipelines',
      id_token: localStorage.getItem('idT'),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/pipeline/automation`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      result.pipelines = result.pipelines.sort((a: any, b: any) => {
        let dateTemp_a: any = new Date(a['created_at']);
        let dateTemp_b: any = new Date(b['created_at']);
        return Date.parse(dateTemp_b) - Date.parse(dateTemp_a);
      });
      this.pipelines = result.pipelines;
      this.selectedPipeline = this.pipelines.find((pipeline: any) => {
        return pipeline.repo_name == this.pipelineID;
      });

      this.router.navigate(['/dev/pipelines'], {
        queryParams: {
          id: this.pipelineID,
        },
      });
    }

    // handle selectedPipeline if querystring have id
  }

  hide(event?: any) {
    this.createPipeline = false;
    if (event) {
      this.fetchPipelines();
    }
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  toggleIndex(index: number) {
    this.selectedPipelineIndex = index;
  }

  getRuntime() {
    if (this.selectedPipelineIndex > -1) {
      return this.pipelines[this.selectedPipelineIndex]['repo_name'].split(
        /(?=[A-Z])/
      )[
        this.pipelines[this.selectedPipelineIndex]['repo_name'].split(
          /(?=[A-Z])/
        ).length - 1
      ];
    }
    return this.selectedPipeline['repo_name'].split(/(?=[A-Z])/)[
      this.selectedPipeline['repo_name'].split(/(?=[A-Z])/).length - 1
    ];
  }
}
