import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customDate' })
export class CustomDatePipe implements PipeTransform {
  transform(value: string): string {
    const parts = value.split('-');
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[1], 10);
    const formattedDate = new Date(year, month - 1).toLocaleDateString(
      'en-US',
      { month: 'long', year: 'numeric' }
    );
    return formattedDate;
  }
}
