import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  NgZone,
} from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

declare let window: any;

@Component({
  selector: 'reply-ticket',
  templateUrl: './reply.ticket.component.html',
  styleUrls: ['../../../ticket.component.css'],
})
export class ReplyTicketPopup implements OnInit {
  @ViewChild('attachmenrs') attachmentElement: ElementRef;
  t = localStorage.getItem('t');
  ut = localStorage.getItem('ut');
  eId = localStorage.getItem('eId');
  @Input() ticket?: any;
  @Input() supportType?: any;
  @Input() modalTitle: any;
  attachment_ids: any = [];
  additionalContacts: any = [this.eId, ''];
  accountId = '';
  replyMessage: any;
  region = '';
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  attachedfileData: any = [];

  currentMessage: any;

  @Input() showModal?: any;

  @Output() hideModal = new EventEmitter<boolean>();

  close(value: boolean) {
    this.hideModal.emit(value);
  }

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}

  ngOnInit() {
    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.addToFileList(docName, fileSize, targetDocBase64),
      component: this,
    };

    this.accountId = localStorage.getItem('accountId');
    this.region = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.region = d.value;
      }
    });
  }

  deleteSelectedAdditionalContact(index: number) {
    this.additionalContacts.splice(index, 1);
  }

  checkForLast(value: any, index: number) {
    if (
      value != '' &&
      value.length > 0 &&
      this.additionalContacts.length - 1 == index
    ) {
      this.additionalContacts.push('');
    } else if (
      value == '' &&
      value.length == 0 &&
      this.additionalContacts.length - 2 == index &&
      this.additionalContacts[this.additionalContacts.length - 1] == ''
    ) {
      this.additionalContacts.splice(this.additionalContacts.length - 1, 1);
    }
  }

  addAdditionalContact() {
    this.additionalContacts.push('');
  }

  trackByFn() {
    return true;
  }

  triggerFIle() {
    this.attachmentElement.nativeElement.click();
  }

  niceBytes(x: any) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  addToFileList(docName: string, fileSize: any, base64Data: any) {
    let parsedData = base64Data.split('base64,');
    this.attachedfileData.push({
      fileName: docName,
      fileSize: fileSize,
      data: parsedData.length > 1 ? parsedData[1] : parsedData[0],
    });
  }

  deleteSeletedFile(indexx: number) {
    this.attachedfileData.splice(indexx, 1);
    if (this.attachedfileData.length == 0) {
      $('#attachments').val('');
    }
  }

  async convertFiles(fileList: any) {
    let promises = [];
    for (var i = 0; i < fileList.length; i++) {
      let filePromise = new Promise(async (resolve: any, reject: any) => {
        var fileToLoad = fileList[i];
        var fileReader = new FileReader();
        var base64: any;
        let fileName = fileList[i].name;
        let fileSize = fileList[i].size;
        fileReader.onload = (fileLoadedEvent: any) => {
          base64 = fileLoadedEvent.target.result;
          window.angularComponentReference.zone.run(() => {
            window.angularComponentReference.componentFn(
              fileName,
              fileSize,
              base64
            );
          });
          resolve({ fileName: fileName, fileSize: fileSize, data: base64 });
        };

        fileReader.readAsDataURL(fileToLoad);
        return;
      });
      promises.push(filePromise);
    }
    Promise.all(promises).then((fileContents) => {
      this.submitAttachments();
    });
  }

  async getFile(fileLoaded: any) {
    let fileList: any = fileLoaded.target.files;
    let total = 0;
    fileList.forEach((element: any) => {
      total += element.size;
    });
    if (total / (1024 * 1024) > 5) {
      this.notifier.alert(
        'Error',
        '',
        'File Size cant be more than 5 MB.',
        'error',
        5000
      );
      return;
    }
    await this.convertFiles(fileList);
    $('#attachments').val('');
  }

  async submitAttachments() {
    this.notifier.loading(true);

    if (this.supportType == 'minfy') {
      try {
        let selectedAccountData: any = await this.fetchSelectedAccountDetails();
        if (!selectedAccountData.error) {
          const { jira_org_id, jira_project_id } = selectedAccountData.data;
          let data = {
            action: 'jira_attachment',
            attachments: this.attachedfileData.map((file: any) => {
              return { fileName: file.fileName, data: file.data };
            }),
            jira_project_id: jira_project_id,
            jira_organization_id: jira_org_id,
          };
          let header = {
            Authorization: localStorage.getItem('t'),
          };
          //https://api.swayam.cloud/v3/admin/support
          let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

          let result = await this.apiServer.postDataPromis(
            apiURL,
            data,
            header
          );

          console.log(result);

          if (result.status == '1' || result.s == '1') {
            this.attachment_ids = result.attachment_ids;
          } else {
            this.notifier.alert('Info', '', result.error, 'info', 5000);
          }
        } else {
          this.notifier.alert(
            'Error',
            '',
            selectedAccountData.msg,
            'error',
            5000
          );
        }
      } catch (err) {}
    } else if (this.supportType == 'aws') {
      let data = {
        action: 'attachment',
        attachments: this.attachedfileData.map((file: any) => {
          return { fileName: file.fileName, data: file.data };
        }),
        account_id: this.accountId,
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      console.log(result);

      if (result.status == '1' || result.s == '1') {
        this.attachment_ids = result.message;
      } else {
        this.notifier.alert('Info', '', result.error_message, 'info', 5000);
      }
    }
    window.analyticsSendEventClick('Tickets', `Attached Files`);
    this.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  async replyTicket() {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    let data;
    if (this.supportType == 'aws') {
      data = {
        action: this.supportType == 'minfy' ? 'jira_reply' : 'reply',
        case_id: this.ticket.caseId,
        account_id: this.accountId,
        body: this.replyMessage,
        attachment_set_id: this.attachment_ids,
        cc_email_addresses: this.additionalContacts.filter((contact: any) => {
          return contact != '';
        }),
      };
    } else if (this.supportType == 'minfy') {
      data = {
        action: this.supportType == 'minfy' ? 'jira_reply' : 'reply',
        issue_key: this.ticket.issue_key,
        description: this.replyMessage,
        attachment_set: this.attachment_ids,
        cc_email_addresses: this.additionalContacts.filter((contact: any) => {
          return contact != '';
        }),
      };
    }
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.replyMessage = '';
      this.attachment_ids = [];
      this.attachedfileData = [];
      this.additionalContacts = [''];
      this.notifier.alert(
        'Success',
        '',
        'Replied Successfully',
        'success',
        5000
      );
      this.close(true);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    window.analyticsSendEventClick('Tickets', `Replied to Ticket`);
    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    return;
  }
}
