<div class="minfyui-change-request-ui">
  <div class="main_contaniner">
    <div class="minfy-change-request-container">
      <div class="change_request_header">
        <div style="cursor: pointer; float: right; font-size: 1.2em">
          <i class="fa fa-times clickable" aria-hidden="true" (click)="hideModalCaller()"></i>
        </div>
        <div class="header_bold">Change Request</div>
        <div class="header_description">
          <div>Account ID: {{ accountId }}</div>
          <div>Region: {{ region }}</div>
          <div>
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              Instance ID:
              <span class="nav-link">{{ instances[0] }}
                <span *ngIf="instances.length > 1">(+{{ instances.length - 1 }} more)</span>
                <span><i class="fa fa-pencil" aria-hidden="true"></i></span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="change_request_body" [ngStyle]="{'padding-top': crs.length > 0 ? '65px' : ''}" (scroll)="
          currentStepObj['stepCount'] == 1 &&
            !scrollloading &&
            checkForMore($event)
        " style="max-height: 600px; overflow-y: auto; position: relative">
        <a routerLink="/dash/bom/changerequests" [queryParams]="{cr_id: getCRIDS()}" target="_blank"
          class="existing_crs" *ngIf="crs.length > 0">
          <div style="color: inherit;">
            <i style="color: inherit;" class="fa fa-exclamation-circle" aria-hidden="true"></i>
          </div>
          <div>
            {{ this.crs.length }} CR(s) already exists with same Resource ID
          </div>
        </a>
        <div *ngIf="currentStepObj['stepCount'] == 0" [ngClass]="{
            prevStepAbsolute:
              currentStepObj['stepCount'] < stepCount ||
              currentStepObj['display'] == false,
            nextStepAbsolute: currentStepObj['stepCount'] > stepCount,
            nextStepFinal:
              currentStepObj['stepCount'] > stepCount &&
              currentStepObj['display'],
            prevStepFinal:
              currentStepObj['stepCount'] < stepCount &&
              currentStepObj['display']
          }">
          <app-changerequesttype [typesselected]="changeRequestObj['components']"></app-changerequesttype>
        </div>
        <div *ngIf="currentStepObj['stepCount'] == 1" [ngClass]="{
            prevStepAbsolute:
              currentStepObj['stepCount'] < stepCount ||
              currentStepObj['display'] == false,
            nextStepAbsolute: currentStepObj['stepCount'] > stepCount,
            nextStepFinal:
              currentStepObj['stepCount'] > stepCount &&
              currentStepObj['display'],
            prevStepFinal:
              currentStepObj['stepCount'] < stepCount &&
              currentStepObj['display']
          }">
          <app-step1-instance-selector [instance]="changeRequestObj['components']['compute']['current']"
            [region]="region" [accountId]="accountId" [uuid]="uuid" [os]="instance.os"
            [changeToInstance]="changeRequestObj['components']['compute']"
            (setMainHeight)="setMainContainerHeight($event)" (setScrollLoading)="setScrollLoading($event)">
          </app-step1-instance-selector>
        </div>
        <div *ngIf="currentStepObj['stepCount'] == 2" [ngClass]="{
            prevStepAbsolute:
              currentStepObj['stepCount'] < stepCount ||
              currentStepObj['display'] == false,
            nextStepAbsolute: currentStepObj['stepCount'] > stepCount,
            nextStepFinal:
              currentStepObj['stepCount'] > stepCount &&
              currentStepObj['display'],
            prevStepFinal:
              currentStepObj['stepCount'] < stepCount &&
              currentStepObj['display']
          }">
          <app-step1-storage-selector [storage]="changeRequestObj['components']['storage']"
            (setMainHeight)="setMainContainerHeight($event)"></app-step1-storage-selector>
        </div>
        <div *ngIf="currentStepObj['stepCount'] == 3" [ngClass]="{
            prevStepAbsolute:
              currentStepObj['stepCount'] < stepCount ||
              currentStepObj['display'] == false,
            nextStepAbsolute: currentStepObj['stepCount'] > stepCount,
            prevStepFinal:
              currentStepObj['stepCount'] < stepCount &&
              currentStepObj['display'],
            nextStepFinal:
              currentStepObj['stepCount'] > stepCount &&
              currentStepObj['display']
          }">
          <app-step2-user-details [emails]="changeRequestObj['metadata']"
            (setMainHeight)="setMainContainerHeight($event)">
          </app-step2-user-details>
        </div>
        <div *ngIf="currentStepObj['stepCount'] == 4" [ngClass]="{
            prevStepAbsolute:
              currentStepObj['stepCount'] < stepCount ||
              currentStepObj['display'] == false,
            nextStepAbsolute: currentStepObj['stepCount'] > stepCount,
            prevStepFinal:
              currentStepObj['stepCount'] < stepCount &&
              currentStepObj['display'],
            nextStepFinal:
              currentStepObj['stepCount'] > stepCount &&
              currentStepObj['display']
          }">
          <app-otp-validation-create-cr [cr_data]="changeRequestObj" (setMainHeight)="setMainContainerHeight($event)">
          </app-otp-validation-create-cr>
        </div>
      </div>
      <div class="change_request_footer">
        <button class="btn btn-light" *ngIf="stepCount > 0" style="color: #5864ff; background-color: transparent"
          (click)="changePage(stepCount - 1)">
          Prev
        </button>
        <button class="btn btn-light" *ngIf="stepCount < 3 && validate()"
          style="color: #5864ff; background-color: transparent" (click)="changePage(stepCount + 1)">
          Next
        </button>
        <button class="btn btn-light" *ngIf="stepCount == 3 && validate()"
          [ngStyle]="{ 'padding-left': loadingCR ? '30px' : undefined }" style="
            color: #5864ff;
            background-color: transparent;
            position: relative;
          " (click)="saveAndSendOTP()">
          <span *ngIf="loadingCR" style="
              position: absolute;
              top: 50%;
              left: 5px;
              transform: translateY(-50%);
            ">
            <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 16px" />
          </span>
          <span style="color: inherit"> Create CR </span>
        </button>
        <button class="btn btn-light" *ngIf="stepCount == 4"
          [ngStyle]="{ 'padding-left': loadingCR ? '30px' : undefined }" style="
            color: #5864ff;
            background-color: transparent;
            position: relative;
          " (click)="validateOTP()">
          <span *ngIf="loadingCR" style="
              position: absolute;
              top: 50%;
              left: 5px;
              transform: translateY(-50%);
            ">
            <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 16px" />
          </span>
          <span style="color: inherit"> Confirm </span>
        </button>
      </div>
    </div>
  </div>
</div>