import { Component, OnInit, NgZone } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

import * as moment from 'moment';

declare let flatpickr: any;
declare let $: any;
declare let window: any;
declare let monthSelectPlugin: any;

@Component({
  //moduleId: module.id.toString(),
  selector: 'mis-reports',
  templateUrl: './mis.view.reports.component.html',
})
export class MISReportsComponent implements OnInit {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  tickets: any = [];
  instances: any = [];
  frequency: string = 'daily';
  dateRange: string;

  accountId: any;
  regionId: any;
  currentMessage: any;

  ticketHeader: any = [
    {
      id: 'issue_key',
      name: 'Issue Key',
    },
    {
      id: 'priority',
      name: 'Priority',
    },
    {
      id: 'summary',
      name: 'Summary',
    },
    {
      id: 'created',
      name: 'Created On',
    },
    {
      id: 'reporter',
      name: 'Reporter',
    },
    {
      id: 'status',
      name: 'Status',
    },
  ];

  instancesHeader: any = [
    {
      id: 'hostname',
      name: 'Host Name',
    },
    {
      id: 'instanceId',
      name: 'Instance ID',
    },
    {
      id: 'lastAmiName',
      name: 'Last AMI Name',
    },
    {
      id: 'imageId',
      name: 'Last AMI ID',
    },
    {
      id: 'amidate',
      name: 'AMI Date',
    },
    {
      id: 'dateOfLastAMI',
      name: 'Last AMI Date',
    },
    {
      id: 'retention',
      name: 'Retention',
    },
    {
      id: 'instanceWiseAMISize',
      name: 'Total AMIs',
    },
    {
      id: 'region',
      name: 'Region',
    },
  ];
  nextExists: boolean;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.frequencyChange();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.frequencyChange();
      }
    });

    setTimeout(() => {
      let start_date = moment().startOf('month').format('YYYY-MM-DD');
      let end_date = moment().startOf('day').format('YYYY-MM-DD');

      $('#sDate').val(start_date + ' to ' + end_date);

      flatpickr('.datetimepicker', {
        dateFormat: 'Y-M-d',
        mode: 'range',
        maxDate: new Date(),
        showMonths: 2,
      });
    }, 500);
    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.frequencyChange(),
      component: this,
    };
  }

  nextToken: any;

  async load() {
    this.notifier.loading(true);
    this.nextToken = null;
    this.nextExists = false;
    await this.loadTickets();
    if (this.frequency == 'daily') {
      await this.loadDailyBackup();
    }
    this.notifier.loading(false);
  }

  async loadTickets() {
    this.notifier.loading(true);
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { jira_org_name, jira_project_key } = selectedAccountData.data;
        let start_date: any = moment().subtract(1, 'day').format('YYYY-MM-DD');
        let end_date: any = moment().startOf('day').format('YYYY-MM-DD');

        let dRange: any;
        if (this.frequency == 'monthly') {
          dRange = $('#monthDate').val();
        } else if (this.frequency == 'weekly') {
          dRange = $('#dayDate').val();
        }
        if (dRange) {
          if (this.frequency == 'weekly') {
            dRange = dRange.toString().split(' to ');
            start_date = moment(dRange[0], 'YYYY-MM-DD').format('YYYY-MM-DD');
            end_date = moment(dRange[1], 'YYYY-MM-DD').format('YYYY-MM-DD');
          } else if (this.frequency == 'monthly') {
            console.log(dRange);
            dRange = moment(dRange, 'MM/YYYY');
            start_date = dRange.startOf('month').format('YYYY-MM-DD');
            end_date = dRange.endOf('month').format('YYYY-MM-DD');
          }
        }
        if (!this.nextExists) {
          this.tickets = [];
        }
        let data = {
          action: 'jira_list',
          account_id: this.accountId,
          include_resolved: 'false',
          jira_project_key: jira_project_key,
          jira_organization_key: jira_org_name,
          start_date: start_date,
          end_date: end_date,
          next_token: this.nextToken,
        };
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        //https://api.swayam.cloud/v3/admin/support
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        console.log(result);

        if (result.status == '1' || result.s == '1') {
          this.tickets = [...this.tickets, ...result.issues];
        } else {
          this.notifier.alert('Info', '', result.error, 'info', 5000);
        }

        if (result.next_token) {
          this.nextToken = result.next_token;
          this.nextExists = true;
        } else {
          this.nextExists = false;
        }
      } else {
        this.tickets = [];
        this.notifier.alert(
          'Error',
          '',
          selectedAccountData.msg,
          'error',
          5000
        );
      }
    } catch (err: any) {
      console.log(err);
      this.notifier.alert('Info', '', err.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async frequencyChange() {
    let start_date = moment().subtract(7, 'day').format('YYYY-MM-DD');
    let end_date = moment().startOf('day').format('YYYY-MM-DD');

    flatpickr('#dayDate', {
      dateFormat: 'Y-m-d',
      mode: 'range',
      maxDate: new Date(),
      showMonths: 2,
      onChange: (selectedDates: any, dateStr: string, instance: any) => {
        if (dateStr.includes('to')) {
          this.load();
        }
      },
    });
    $('#dayDate').val(start_date + ' to ' + end_date);
    let temp = new Date();
    $('#monthDate').val(`${temp.getMonth() + 1}/${temp.getFullYear()}`);

    $('#monthDate')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm-yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        console.log(selected);

        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });

        //startDate = new Date(selected.date.valueOf());
        //startDate.setDate(startDate.getDate(new Date(selected.date.valueOf())));
        //$('.to').datepicker('setStartDate', startDate);
      });
    setTimeout(() => {
      this.load();
    }, 100);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  async loadDailyBackup(nextToken: String = '') {
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      accountId: this.accountId,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/reports/dailybackupreport`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.instances = result.listami;
    } else {
      this.instances = [];
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  exportTickets() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel('ticketsTable', 'Tickets', 'csv');
  }

  exportDailyBackup() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel('dailyBackupTable', 'Daily Backup', 'csv');
  }

  callFunction(id: any, id1: any) {}
}
