import { Component, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

import * as moment from 'moment';

declare let $: any;
declare let window: any;

@Component({
  selector: 'network-launch-instance',
  templateUrl: 'network.launch.component.html',
  styleUrls: [
    './../../launch.instance.component.css',
    './../../../../upload.bom.component.css',
  ],
})
export class NetworkLaunchInstanceComponent implements OnInit, OnDestroy {
  @Input('instance') instance: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;

  vpcs: any = [];
  subnets: any = [];
  sgs: any = [];

  accountId: any;
  regionId: any;
  currentMessage: any;

  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.reset();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.reset();
      }
    });
  }

  reset() {
    this.loadVPCs();
  }

  async loadSubnets(vpc_id: any) {
    let data = {
      action: 'list_subnets',
      vpc_id: vpc_id,
      login_user_email: this.userId,
      account_id: this.accountId,
      region_id: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'BOMv2.Components.Launch.Components.Network',
      'Subnets Fetched'
    );
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2/create`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.subnets = result.subnets.map((subnet: any) => {
        subnet['vpc_id'] = vpc_id;
        return subnet;
      });
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }
  }

  async loadSGs(vpc_id: any) {
    let data = {
      action: 'list_sgs',
      vpc_id: vpc_id,
      login_user_email: this.userId,
      account_id: this.accountId,
      region_id: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'BOMv2.Components.Launch.Components.Network',
      'SGs Fetched'
    );
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2/create`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.sgs = result.sgs;
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }
  }

  updateReports(event: any) {
    if (event.event == 'add-item') {
      if (event.key == 'VPC') {
        this.instance.vpc_id = event.data;
        this.notifier.loading(true);
        let promises: any = [];
        promises.push(this.loadSubnets(this.instance.vpc_id));
        promises.push(this.loadSGs(this.instance.vpc_id));
        Promise.all(promises).then((values: any) => {
          this.notifier.loading(false);
        });
      }
      if (event.key == 'Subnet') {
        this.instance.subnet_id = event.data;
      }
      if (event.key == 'Security Group') {
        this.instance.sg_ids[0] = event.data;
      }
    }
  }

  async loadVPCs() {
    this.notifier.loading(true);
    let data = {
      action: 'list_vpcs',
      login_user_email: this.userId,
      account_id: this.accountId,
      region_id: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2/create`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.vpcs = result.vpcs;
      if (this.instance.vpc_id) {
        this.notifier.loading(true);
        let promises: any = [];
        promises.push(this.loadSubnets(this.instance.vpc_id));
        promises.push(this.loadSGs(this.instance.vpc_id));
        Promise.all(promises).then((values: any) => {
          this.notifier.loading(false);
        });
      } else {
        this.notifier.loading(false);
      }
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
      this.notifier.loading(false);
    }
  }

  ngOnDestroy(): void {}
}
