import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

declare let $: any;

@Component({
  selector: 'update-user',
  templateUrl: './invite.user.component.html',
  styleUrls: ['./../../ec2-group/components/ec2-group.component.css'],
})
export class InviteUserComponent implements OnInit, OnDestroy {
  @Input('userDetails') userDetails: any;
  @Input('action') action: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  instances: any = [];
  regionId: any;
  accountGroups: any = [];
  services: any = [];
  polices: any = [];
  policyName: string = '';
  accounts: any = [];
  twowayfactor: boolean = true;
  policyType: string = 'predefined';
  accessType: string = 'readOnly';
  currentMessage: any;
  chcekFor2FA: boolean;
  currentTag: string = null;
  tagKeys: any = [];
  tagValues: any = [];
  tagKeyValuesObj: any = {};
  selectedAccountID: any;
  @Output() hideModel = new EventEmitter<any>();
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.userDetails = JSON.parse(JSON.stringify(this.userDetails));
    if (this.urlPrefix == 'admin') {
      this.userDetails.clientCmpName = this.userDetails.cmpName;
      this.userDetails.emaiId = this.userDetails.userId;
      this.userDetails.mobileNumber = this.userDetails.phNumber;
      this.userDetails.clientName = this.userDetails.userName;
      this.userDetails.userType = 'user';
    } else {
      this.twowayfactor = this.userDetails.otpReq;
      if (
        this.userDetails.tags &&
        this.userDetails.tagsList &&
        Object.keys(this.userDetails.tagsList).length > 0
      ) {
        this.tagKeyValuesObj = this.userDetails.tagsList;
        this.currentTag = Object.keys(this.tagKeyValuesObj)[0];
      }
    }
    $('#instancesList').selectpicker();
    $('#policyList').selectpicker();
    $('#serviceList').selectpicker();
    $('#instancesGroup').selectpicker();
    $('#accountGroupLists').selectpicker();
    $('#tagKeysFilter').selectpicker();
    $('#tagValuesFilter').selectpicker();
    $('#accountsTagsFilter').selectpicker('refresh');
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.load();
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.load();
        this.regionId = d.value;
      }
    });
  }

  // tags
  loadingTags: boolean = false;
  async loadTags(nextToken: any = undefined) {
    // this.notifier.loading(true);
    this.loadingTags = true;
    if (!nextToken) {
      this.tagKeys = [];
    }
    let data = {
      action: 'listEc2Tags',
      accountId: this.selectedAccountID,
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (this.destroyed || data.accountId != this.selectedAccountID) {
      return;
    }

    if (result.status == '1' || result.s == '1') {
      if (result.tagKeyList && result.tagKeyList.length > 0 && !nextToken) {
        this.tagKeys = [...this.tagKeys, ...result.tagKeyList];
      }
      setTimeout(() => {
        $('#tagKeysFilter').selectpicker('refresh');
      }, 100);
      if (result.tagKeyList && result.tagKeyList.length > 0 && !nextToken) {
        if (!this.currentTag) {
          this.currentTag = this.tagKeys[0];
        }
        await this.loadValues();
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.nextToken) {
      await this.loadTags(result.nextToken);
    }
    this.loadingTags = false;
    // this.notifier.loading(false);
  }

  async loadValues() {
    this.loadingTags = true;
    // this.notifier.loading(true);
    let data = {
      action: 'listEc2TagsValues',
      accountId: this.selectedAccountID,
      region: this.regionId,
      nextToken: '',
      tagKey: this.currentTag,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.status == '1' || result.s == '1') {
      this.tagValues = result.tagKeyList;
      setTimeout(() => {
        if (this.tagKeyValuesObj[this.currentTag]) {
          $('#tagValuesFilter').val(this.tagKeyValuesObj[this.currentTag]);
        }
        $('#tagValuesFilter').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.loadingTags = false;
    // this.notifier.loading(false);
  }

  setValues() {
    let values: string[] = $('#tagValuesFilter').val();
    if (
      (!values || values.length == 0) &&
      this.tagKeyValuesObj[this.currentTag]
    ) {
      delete this.tagKeyValuesObj[this.currentTag];
    }
    this.tagKeyValuesObj[this.currentTag] = values;
    setTimeout(() => {
      $('#tagKeysFilter').selectpicker('refresh');
    }, 100);
  }

  getValues(key: string) {
    return this.tagKeyValuesObj[key] || [];
  }

  resetValues() {
    if (this.tagKeyValuesObj[this.currentTag]) {
      delete this.tagKeyValuesObj[this.currentTag];
      $('#tagValuesFilter').val('');
      $('#tagValuesFilter').selectpicker('refresh');
      setTimeout(() => {
        $('#tagKeysFilter').selectpicker('refresh');
      }, 100);
    }
  }

  getHTML(key: string) {
    return `
      <div class='flex_option'>
        <span>${key}</span>
        ${
          this.getValues(key).length > 0
            ? `<span>${this.getValues(key).length}</span>`
            : ''
        }
      </div>
    `;
  }

  uploadFileError(event: any) {
    if (event) {
      this.twowayfactor = true;
    } else {
      this.twowayfactor = false;
    }
    this.chcekFor2FA = false;
  }

  async loadAdminAccountGroups() {
    this.notifier.loading(true);
    let data = {
      userId: this.userId,
      action: 'fetchAllAccountGroups',
      ut: this.urlPrefix,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroups`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.accountGroups = result.agl;
      setTimeout(() => {
        $('#accountGroupLists').selectpicker('refresh');
        $('#accountGroupLists').val(this.userDetails.accountGroup.split(', '));
        $('#accountGroupLists').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  checkTwoWayFactor() {
    if (!this.twowayfactor) {
      this.chcekFor2FA = true;
    }
  }

  async load() {
    if (this.urlPrefix == 'client') {
      this.loadTags();
      this.fetchPolicyDetails();
      if (this.action == 'update') {
        await this.getUserDetails();
      }
    } else {
      this.loadAdminAccountGroups();
      return;
    }
    this.notifier.loading(true);
    let data = {
      emailId: this.userId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/listawsaccounts`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.services = result.menuList;
      this.accounts = result.list[0]['otherdetail'];
      setTimeout(() => {
        $('#instancesList').selectpicker('refresh');
        $('#serviceList').selectpicker('refresh');
        $('#instancesList').val([
          ...this.userDetails.accountDetailsList.map((account: any) => {
            return account.accountId;
          }),
        ]);
        $('#serviceList').val(this.userDetails.menuList);
        $('#serviceList').selectpicker('refresh');
        $('#instancesList').selectpicker('refresh');
        this.checkOfServices();
        this.fetchAccountGroups();
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  checkOfServices() {
    const fixedValues = ['CloudSupport', 'Settings'];
    fixedValues.forEach((element: any) => {
      const selectedValues = $('#serviceList').val();
      if (selectedValues.indexOf(element) < 0) {
        if (this.policyType == 'manual') {
          this.notifier.alert(
            'Info',
            '',
            `${element} is a Mandatory Service`,
            'info',
            5000
          );
          $('#serviceList').val([...selectedValues, element]);
          $('#serviceList').selectpicker('refresh');
        }
      }
    });
  }

  async getUserDetails() {
    this.notifier.loading(true);
    let data = {
      clientId: this.userDetails.emaiId,
      action: 'fetchSingleClientUsersList',
      masterClientId: this.userId,
      ut: this.urlPrefix,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string;
    if (this.urlPrefix == 'admin') {
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/invite`;
    } else {
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/invitev2`;
    }

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.userDetails = result.cl[0];
      if (this.userDetails.policyStatus) {
        this.policyType = 'predefined';
        this.policyName = this.userDetails.policyName;
      } else {
        this.policyType = 'manual';
        this.accessType = this.userDetails.accessType;
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
  }

  async fetchAccountGroups() {
    this.notifier.loading(true);
    let accountIdList = $('#instancesList')
      .val()
      .map((instance: any) => {
        return { accountId: instance };
      });
    if (accountIdList.length == 0) {
      this.userDetails.accountDetailsList.map((account: any) => {
        return { accountId: account.accountId };
      });
    }
    setTimeout(() => {
      $('#accountsTagsFilter').selectpicker('refresh');
    }, 100);
    let data = {
      clientId: this.userId,
      action: 'fetchgroup',
      accountIdList: accountIdList,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2groups`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.accountGroups = result.list;
      setTimeout(() => {
        $('#instancesGroup').selectpicker('refresh');
      }, 100);
    } else {
      if (this.action == 'update') {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      }
    }

    this.notifier.loading(false);
  }

  async fetchPolicyDetails() {
    this.notifier.loading(true);
    let data = { clientID: this.userId, action: 'fetch' };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/policydetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.polices = result.policyList;
      setTimeout(() => {
        $('#policyList').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  async updateEntry() {
    if (this.action == 'local') {
      this.close(true);
      return;
    }
    this.notifier.loading(true);
    const selectedAccounts = $('#instancesList').val();
    let data;
    if (this.urlPrefix == 'client') {
      data = {
        clientId: this.userDetails.emaiId,
        action:
          this.action == 'add'
            ? 'invite'
            : 'updateClientDetailsGroupAccessType',
        ut: this.urlPrefix,
        phoneNumber:
          this.action == 'add' ? this.userDetails.mobileNumber : undefined,
        cmpName:
          this.action == 'add' ? this.userDetails.clientCmpName : undefined,
        clientName:
          this.action == 'add' ? this.userDetails.clientName : undefined,
        otpReq: this.twowayfactor,
        accountDetailsList: this.accounts
          .filter((account: any) => {
            return selectedAccounts.indexOf(account.accountId) > -1;
          })
          .map((account: any) => {
            return {
              accountId: account.accountId,
              accountName: account.accountName,
            };
          }),
        policyName:
          this.policyType == 'predefined' ? this.policyName : undefined,
        masterClientId: this.userId,
        updatePolicy:
          this.action == 'update' && this.policyType == 'manual' ? false : true,
        menuList:
          this.policyType == 'manual' ? $('#serviceList').val() : undefined,
        accessType: this.policyType == 'manual' ? this.accessType : undefined,
        tagsList:
          Object.keys(this.tagKeyValuesObj).length > 0
            ? this.tagKeyValuesObj
            : undefined,
        tags: Object.keys(this.tagKeyValuesObj).length > 0 ? true : false,
        instanceGroupList:
          this.policyType == 'manual'
            ? this.accountGroups
                .filter((account: any) => {
                  return (
                    $('#instancesGroup').val().indexOf(account.groupName) > -1
                  );
                })
                .map((account: any) => {
                  return {
                    accountId: account.accountId,
                    groupName: account.groupName,
                  };
                })
            : undefined,
      };
    } else {
      data = {
        clientId: this.userDetails.emaiId,
        action: this.action == 'add' ? 'invite' : 'update',
        ut: this.userDetails.userType == 'user' ? 'msopsuser' : 'msops',
        phoneNumber:
          this.action == 'add' ? this.userDetails.mobileNumber : undefined,
        cmpName:
          this.action == 'add' ? this.userDetails.clientCmpName : undefined,
        userName:
          this.action == 'add' ? this.userDetails.clientName : undefined,
        accountGroup: $('#accountGroupLists').val(),
        masterClientId: this.action == 'add' ? this.userId : undefined,
      };
    }

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL: string;
    if (this.urlPrefix == 'admin') {
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/invite`;
    } else {
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/invitev2`;
    }

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      if (this.action == 'add') {
        this.close();
      } else {
        this.close(true);
      }
    } else {
      this.notifier.alert(
        'Error',
        '',
        result.m || result.error || result.msg,
        'error',
        5000
      );
    }
    this.notifier.loading(false);
  }

  destroyed: boolean = false;
  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }

  close(sendData: boolean = false) {
    if (sendData) {
      this.hideModel.emit(this.userDetails);
    } else {
      this.hideModel.emit(false);
    }
  }
}
