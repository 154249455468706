<div class="card" *ngIf="!activeRule && !createRule">
  <div class="card-header">
    <h5>Backup</h5>
  </div>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; align-items: center;">
      <button *ngIf="rules.length > 0" class="btn btn-light" style="background-color: #5864FF; color: white;"
        (click)="createRuleTrigger()">Create
        Rule</button>
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of rulesHeaders">{{h.name}}</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': rules.length > 0 && !fetch_rules ? 'table-row-group': ''}">
        <tr *ngFor="let i of rules; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of rulesHeaders">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              <span>{{h.alter ? h.alter(i[h.id]) : i[h.id]}}</span>
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': rules.length == 0 && !fetch_rules ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="rulesHeaders.length + 1">
            <div style="text-align: center;">No Rules Found.
              <span class="btn_" style="padding: 0px; margin: 0px;" (click)="createRuleTrigger()">Click here</span> to
              create
              one
            </div>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': fetch_rules ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="rulesHeaders.length + 1">
            <div style="text-align: center;">
              <img src="../assets/img/loading_2.svg" alt="loading rules" style="width: 25px; margin: 10px;">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="activeRule && !createRule">
  <rule-backup [rule]="activeRule" (close)="checkClose($event)"></rule-backup>
</div>

<div class="card" *ngIf="createRule">
  <div class="card-header">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <div>
        <span class="btn" (click)="close(false)" style="padding: 0px; margin: 0px;">Rules</span> <span
          class="seperator">></span>
        <span>Create</span>
      </div>
    </div>
  </div>
  <div class="card-body">
    <createrule-backup *ngIf="createRule" (close)="close($event)" [action]="'create_backup_rule'" [rule]="createRule">
    </createrule-backup>

  </div>
</div>