<!--<img src="../../assets/map/world.svg"   />-->
<div class="container-fluid" *ngIf="!showDetails">

  <!--<h2>Selected Client : {{clientName}} </h2>-->

  <h3>Service Intelligence</h3><br>
  <h3>Instances currently under monitoring</h3>
  <div class="table-responsive">
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Instance ID</th>
          <th>Instance Name</th>

          <!--<th>Status</th>-->
          <th>Disable Monitoring</th>
          <th>Dashboard</th>

        </tr>
      </thead>
      <tbody *ngFor="let item of mappedInstanceList">
        <tr *ngIf="item.monitoring">
          <td>{{item.instanceId}}</td>
          <td>{{item.instanceName}}</td>
          <!--<td>{{item.status}}</td>-->
          <td>
            <!--{{item.monitoring ? "Yes" : "No"}}-->
            <button class="btn btn-sm btn-danger" (click)="disableMonitoring(item)">Disable</button>
          </td>
          <td> <button *ngIf="item.monitoring" class="btn btn-sm btn-link" (click)="showDetailsView(item)">View</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <p>&nbsp;</p>

  <h3>Instances available for monitoring</h3>
  <div class="table-responsive">
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Instance ID</th>
          <th>Instance Name</th>
          <th>Enable Monitoring</th>
        </tr>
      </thead>
      <tbody *ngFor="let item of mappedInstanceList">
        <tr *ngIf="!item.monitoring">
          <td>{{item.instanceId}}</td>
          <td>{{item.instanceName}}</td>
          <td>
            <button class="btn btn-sm btn-danger" (click)="addToMonitering(item)">Enable</button>
          </td>

        </tr>
      </tbody>
    </table>
  </div>

</div>

<div class="container-fluid" *ngIf="showDetails">
  <div class="row">
    <div class="col">
      <button class="btn btn-sm btn-secondary right" (click)="showDetails = false"> <i class="fa fa-arrow-left"></i>
        Back</button>
    </div>
    <p>&nbsp;</p>
  </div>
  <div class="row">
    <!--<div class="wordmap-container col-xxl-6 col-lg-6">
        <div id="world-map-markers" style="width: 100%; height: 600px"> </div>
      </div>-->
    <div class="col-xxl-10 col-lg-10">

      <div class="row">

        <div class="col-xxl-4 col-lg-4">
          <span>CPU</span>
          <div id="cpuV" class="visualGraph"></div>
        </div>

        <div class="col-xxl-4 col-lg-4">
          <span>Network In (Bytes)</span>
          <div id="networkVInBy" class="visualGraph"></div>
        </div>

        <div class="col-xxl-4 col-lg-4">
          <span>Network Out (Bytes)</span>
          <div id="networkVOutBy" class="visualGraph"></div>
        </div>

      </div>

      <div class="row">

        <div class="col-xxl-4 col-lg-4">
          <span>Memory</span>
          <div id="diskVR" class="visualGraph"></div>
        </div>

        <!--<div class="col-xxl-4 col-lg-4">
    <span>RAM</span>
    <div id="ramV" class="visualGraph"></div>
  </div>-->

        <div class="col-xxl-4 col-lg-4">
          <span>Network In (Packets)</span>
          <div id="networkVIn" class="visualGraph"></div>
        </div>

        <div class="col-xxl-4 col-lg-4">
          <span>Network Out(Packets)</span>
          <div id="networkVOut" class="visualGraph"></div>
        </div>

      </div>


      <!--<div class="row">
          <div class="col-xxl-4 col-lg-4">
            <span>Disk Capacity</span>
            <div id="diskC" class="visualGraph"></div>
          </div>
          <div class="col-xxl-4 col-lg-4">
            <span>Disk Read</span>
            <div id="diskVR" class="visualGraph"></div>
          </div>

          <div class="col-xxl-4 col-lg-4">
            <span>Disk Write</span>
            <div id="diskVW" class="visualGraph"></div>
          </div>

        </div>-->

    </div>

    <div class="col-xxl-2 col-lg-2">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="panel-title">
                Events
              </h3>
            </div>
            <div class="card-body">
              <!--<h5 class="card-title">Special title treatment</h5>-->
              <p class="card-text">
                <span *ngIf="!cpuEvents || cpuEvents.length == 0">
                  No Events Reported.
                </span>

                <span *ngIf="cpuEvents && cpuEvents.length > 0">
                  As per the historical average the CPU breaches
                  the threshold of {{selectedItem.monitoringThreshold.CPU}}% when
                  the Network Out increases to {{cpuEvents[0].networkVOutBy}} Bytes. This typically happens around
                  {{cpuEvents[0].timeStampDisp}}. Based on the current usage the chance of network out reaching the
                  breach threshold today is {{calcStatus()}}.
                </span>
                <!--As per the historical average the CPU breaches the threshold of 85% when the Network Out increases to <> Bytes. This typically happens around <9 AM>. Based on the current usage the chance of network out reaching the breach threshold today is <High, Medium, Low>.    CPU usage breach can happen between <> AM to <> PM today.-->
                <!-- If breach count > 5 High, 2 > Medium , 0 Low-->
              </p>
              <!--<a href="#" class="btn btn-primary">Go somewhere</a>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--<canvas id="canvas" style="width: 100%; height: 600px"></canvas>-->
  <div class="row">
    <div class="col-xxl-4 col-lg-4" *ngIf="cpuDataBreachAverage" style="display:none">
      <!-- Panel Projects -->
      <div class="panel" id="projects">
        <div class="panel-heading">
          <h3 class="panel-title">CPU Breach History - Average</h3>
        </div>
        <!--    <span data-plugin="peityPie" data-skin="red" style="display: none;">7/10</span><svg class="peity" height="22" width="22"><path d="M 11 0 A 11 11 0 1 1 0.5383783207533117 14.399186938124423 L 11 11" fill="#d32f2f"></path><path d="M 0.5383783207533117 14.399186938124423 A 11 11 0 0 1 10.999999999999998 0 L 11 11" fill="#ef5350"></path></svg>
      -->
        <div class="table-responsive">
          <table class="table table-docs">
            <thead>
              <tr>
                <td>Components</td> <!-- What is going to Happen  e.g. : Disk is about to full -->
                <td>Count</td> <!--  Type of Breaches : CPU, Memory, Disk, Network   -->
                <!-- When it is Predicted to Happen -->
                <!--<td>Date</td>
              <td>Actions</td>-->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Duration</td>
                <td>24 Weeks</td>
              </tr>
              <tr>
                <td>Network Out (Bytes)</td>
                <td>
                  {{cpuDataBreachAverage.networkVOutBy}}
                </td>
              </tr>
              <tr>
                <td>Network Out (Packets)</td>
                <td>
                  {{cpuDataBreachAverage.networkVOut}}
                </td>
              </tr>
              <tr>
                <td>Network In (Bytes)</td>
                <td>
                  {{cpuDataBreachAverage.networkVInBy}}
                </td>
              </tr>
              <tr>
                <td>Network In (Packets)</td>
                <td>
                  {{cpuDataBreachAverage.networkVIn}}
                </td>
              </tr>

              <!--<span data-plugin="peityPie" data-skin="blue" style="display: none;">3/10</span><svg class="peity" height="22" width="22"><path d="M 11 0 A 11 11 0 0 1 21.46162167924669 14.399186938124421 L 11 11" fill="#1976d2"></path><path d="M 21.46162167924669 14.399186938124421 A 11 11 0 1 1 10.999999999999998 0 L 11 11" fill="#42a5f5"></path></svg>-->
              <!--<tr>
                  <td>Ram Usages (%)</td>
                  <td>
                    80%

                  </td>
                </tr>
                <tr>
                  <td>Disk Read Ops (Count)</td>
                  <td>
                    123456
                  </td>
                </tr>
                <tr>
                  <td>Disk Read Ops (Bytes)</td>
                  <td>
                    123456
                  </td>
                </tr>
                <tr>
                  <td>Disk Write Ops (Count)</td>
                  <td>
                    123456
                  </td>
                </tr>
                <tr>
                  <td>Disk Write Ops (Bytes)</td>
                  <td>
                    123456
                  </td>
                </tr>-->
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--<span data-plugin="peityPie" data-skin="green" style="display: none;">9/10</span><svg class="peity" height="22" width="22"><path d="M 11 0 A 11 11 0 1 1 4.534362224782794 2.100813061875579 L 11 11" fill="#388e3c"></path><path d="M 4.534362224782794 2.100813061875579 A 11 11 0 0 1 10.999999999999998 0 L 11 11" fill="#66bb6a"></path></svg>-->
    <!--<td>Jan 1, 2017</td>
         <td>
           <button type="button" class="btn btn-sm btn-icon btn-pure btn-default waves-effect waves-light waves-round" data-toggle="tooltip" data-original-title="Edit">
             <i class="icon bi-wrench" aria-hidden="true"></i>
           </button>
           <button type="button" class="btn btn-sm btn-icon btn-pure btn-default waves-effect waves-light waves-round" data-toggle="tooltip" data-original-title="Delete">
             <i class="icon bi-x-lg" aria-hidden="true"></i>
           </button>
         </td>-->
    <div class="col-xxl-6 col-lg-6" *ngIf="cpuDataBreachLast">
      <!-- Panel Projects -->
      <div class="panel" id="projects">
        <div class="panel-heading">
          <h3 class="panel-title">Recent Breach Stats</h3>
        </div>
        <div class="table-responsive">
          <div class="table-responsive">
            <table class="table table-docs">
              <thead>
                <tr>
                  <td>Components</td> <!-- What is going to Happen  e.g. : Disk is about to full -->
                  <td>Count</td> <!--  Type of Breaches : CPU, Memory, Disk, Network   -->
                  <!-- When it is Predicted to Happen -->
                  <!--<td>Date</td>
      <td>Actions</td>-->
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Time</td>
                  <td>{{cpuDataBreachLast.dispTime}}</td>
                </tr>
                <tr>
                  <td>Network Out (Bytes)</td>
                  <td>
                    {{cpuDataBreachLast.networkVOutBy}}
                  </td>
                </tr>
                <tr>
                  <td>Network Out (Packets)</td>
                  <td>
                    {{cpuDataBreachLast.networkVOut}}
                  </td>
                </tr>
                <tr>
                  <td>Network In (Bytes)</td>
                  <td>
                    {{cpuDataBreachLast.networkVInBy}}
                  </td>
                </tr>
                <tr>
                  <td>Network In (Packets)</td>
                  <td>
                    {{cpuDataBreachLast.networkVIn}}
                  </td>
                </tr>

                <!--<span data-plugin="peityPie" data-skin="blue" style="display: none;">3/10</span><svg class="peity" height="22" width="22"><path d="M 11 0 A 11 11 0 0 1 21.46162167924669 14.399186938124421 L 11 11" fill="#1976d2"></path><path d="M 21.46162167924669 14.399186938124421 A 11 11 0 1 1 10.999999999999998 0 L 11 11" fill="#42a5f5"></path></svg>-->
                <!--<tr>
                    <td>Ram Usages (%)</td>
                    <td>
                      80%

                    </td>
                  </tr>
                  <tr>
                    <td>Disk Read Ops (Count)</td>
                    <td>
                      123456
                    </td>
                  </tr>
                  <tr>
                    <td>Disk Read Ops (Bytes)</td>
                    <td>
                      123456
                    </td>
                  </tr>
                  <tr>
                    <td>Disk Write Ops (Count)</td>
                    <td>
                      123456
                    </td>
                  </tr>
                  <tr>
                    <td>Disk Write Ops (Bytes)</td>
                    <td>
                      123456
                    </td>
                  </tr>-->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-6 col-lg-6">
      <!-- Panel Projects Status -->
      <div class="panel" id="projects-status">
        <div class="panel-heading">
          <h3 class="panel-title">
            Current Stats
            <!--<span class="badge badge-pill bg-info">5</span>-->
          </h3>
        </div>
        <div class="table-responsive">
          <div class="table-responsive" *ngIf="cpuDataCurrent">
            <table class="table table-docs">
              <thead>
                <tr>
                  <td>Components</td> <!-- What is going to Happen  e.g. : Disk is about to full -->
                  <td>Count</td> <!--  Type of Breaches : CPU, Memory, Disk, Network   -->
                  <!-- When it is Predicted to Happen -->
                  <!--<td>Date</td>
                <td>Actions</td>-->
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Duration</td>
                  <td>5 Minutes</td>
                </tr>
                <tr>
                  <td>Network Out (Bytes)</td>
                  <td>
                    {{cpuDataCurrent.networkVOutBy}}
                  </td>
                </tr>
                <tr>
                  <td>Network Out (Packets)</td>
                  <td>
                    {{cpuDataCurrent.networkVOut}}
                  </td>
                </tr>
                <tr>
                  <td>Network In (Bytes)</td>
                  <td>
                    {{cpuDataCurrent.networkVInBy}}
                  </td>
                </tr>
                <tr>
                  <td>Network In (Packets)</td>
                  <td>
                    {{cpuDataCurrent.networkVIn}}
                  </td>
                </tr>
                <!--<tr>
                    <td>Ram Usages (%)</td>
                    <td>
                      80%                     
                    </td>
                  </tr>
                  <tr>
                    <td>Disk Read Ops (Count)</td>
                    <td>
                      123456
                    </td>
                  </tr>
                  <tr>
                    <td>Disk Read Ops (Bytes)</td>
                    <td>
                      123456
                    </td>
                  </tr>
                  <tr>
                    <td>Disk Write Ops (Count)</td>
                    <td>
                      123456
                    </td>
                  </tr>
                  <tr>
                    <td>Disk Write Ops (Bytes)</td>
                    <td>
                      123456
                    </td>
                  </tr>-->
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- End Panel Projects Stats -->
    </div>
  </div>
</div>

<div class="modal show" tabindex="-1" *ngIf="showpopAdd" style="display:block; top: 20%">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Send Alert to (Enter comma separated mail ids)</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="showpopAdd = false"></button>
      </div>
      <div class="modal-body">
        <h2>Monitoring Threshold</h2>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">CPU(%)</label>
          <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="80%"
            [(ngModel)]="monitoringThreshold.CPU">
        </div>
        <div class="mb-3" style="display:none">
          <label for="exampleFormControlInput1" class="form-label">RAM(%)</label>
          <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="80%"
            [(ngModel)]="monitoringThreshold.RAM">
        </div>
        <div class="mb-3" style="display:none">
          <label for="exampleFormControlInput1" class="form-label">Disk(%)</label>
          <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="80%"
            [(ngModel)]="monitoringThreshold.Disk">
        </div>
        <h2>Emails</h2>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">On Alarm</label>
          <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com"
            [(ngModel)]="alertEmails.onAlert">
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">On Warning</label>
          <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com"
            [(ngModel)]="alertEmails.onWarning">
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Daily Report</label>
          <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com"
            [(ngModel)]="alertEmails.dailyReport">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
          (click)="showpopAdd = false">Close</button>
        <button type="button" class="btn btn-primary" (click)="setMonitoringEnabled()">Save changes</button>
      </div>
    </div>
  </div>
</div>
<style>
  table tr {
    line-height: 30px;
  }

  .wordmap-container {
    /*  max-width: 90%;*/
    /* width: 100%;*/
    min-height: 450px;
  }

  #world-map-markers {
    width: 100%;
    min-height: 450px;
  }

  #worldmap {
    transform: scale(.9);
  }

  .visualGraph {
    width: 100%;
    height: 300px;
  }

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-top: 25%;
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: rgba(50, 50, 50, .5);
  }
</style>