import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';

@Component({
  templateUrl: './ec2.details.view.component.html',
  selector: 'ec2-details-view',
  styleUrls: ['ec2.details.view.component.css'],
})
export class ec2DetailsViewComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('instance_more_details') instanceView: ElementRef;

  private last: MouseEvent;
  private el: HTMLElement;

  private mouseDown: boolean = false;

  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  p: any = null;
  startY: any;
  startHeight: any;
  @Input() instance?: any;
  @Input('tabs') inputActiveTabs: string[] = [];
  activeTabs: string[] = [];
  ec2InstanceDetails: any = {
    type: 'details',
    data: {},
    loading: true,
  };
  @Output() hideModal = new EventEmitter<boolean>();
  @Output() instanceStartStop = new EventEmitter<any>();

  funRef: any;
  sgDetailsList: any = null;
  accountId: any;
  regionId: any;
  currentMessage: any;
  filterText = '';
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  tagsHeader: any = [
    {
      id: 'Key',
      name: 'Key',
    },
    {
      id: 'Value',
      name: 'Value',
    },
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.instance) {
      this.ec2InstanceDetails = {
        type: 'details',
        data: {},
        loading: true,
      };
      this.sgDetailsList = null;
      if (this.pageLoaded) {
        this.loadDetails();
      }
    }
  }
  pageLoaded: boolean = false;
  ngOnInit(): void {
    if (this.inputActiveTabs) {
      this.activeTabs = this.inputActiveTabs;
      if (this.activeTabs.length > 0) {
        this.changeTab(this.activeTabs[0]);
      }
    }
    this.p = document.querySelector('.instance_more_details');

    this.funRef = {};
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    this.loadDetails();
    this.pageLoaded = true;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  loading: boolean = true;
  instanceData: any;
  async loadDetails() {
    this.loading = true;
    let selectedClientData = JSON.parse(localStorage.getItem('clientList'));
    let client_id;
    try {
      client_id = selectedClientData.find((client: any) => {
        return client.emailId == localStorage.getItem('selectedClientList');
      })['clientIdList'];
    } catch (err) {
      client_id = localStorage.getItem('clientId');
    }
    let data = {
      action: 'list_ec2',
      client_id: client_id,
      account_id: this.accountId,
      region_name: this.regionId,
      module: 'operations/ec2-bottom',
      resource_id: this.instance.instanceId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2v2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.instanceData = result.instances[0];
    }

    // if (result.status == '1' || result.s == '1') {
    //   let dt = result.securityGroupLists;
    //   this.sgDetailsList = dt.map((sg: any) => {
    //     return sg.groupId;
    //   });
    // } else {
    //   this.notifier.alert('Info', '', result.error, 'info', 5000);
    // }
    this.loading = false;
  }

  showTagsModal: any;
  hideMdoal(event: any) {
    this.showTagsModal = null;
  }

  manageTags() {
    this.showTagsModal = this.instance;
  }

  changeTab(event: any) {
    this.sgDetailsList = null;
    this.ec2InstanceDetails['type'] = event;
    if (this.ec2InstanceDetails['type'] == 'security') {
      this.fetchSGList();
    }
    if (this.ec2InstanceDetails['type'] == 'monitoring') {
      this.loadInstanceMetrics();
    }
  }

  removeDetailsView(event: any) {
    this.instanceView.nativeElement.classList.add('remove-details-view');
    setTimeout(() => {
      this.hideModal.emit(true);
    }, 200);
  }

  initDragEC2(event: any) {
    this.mouseDown = true;
    this.last = event;
    this.instanceView.nativeElement.style.userSelect = 'none';
    this.instanceView.nativeElement.style.transition = '0s';
    this.startY = event.clientY;
    this.startHeight = parseInt(
      document.defaultView.getComputedStyle(this.instanceView.nativeElement)
        .height,
      10
    );
  }

  checkForTabs(value: string) {
    if (this.activeTabs.indexOf(value) > -1 || this.activeTabs.length == 0) {
      return true;
    }
    return false;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.mouseDown) {
      this.instanceView.nativeElement.style.height =
        this.startHeight - event.clientY + this.startY + 'px';
    }
  }

  @HostListener('mouseup')
  onMouseUp() {
    this.instanceView.nativeElement.style.userSelect = 'auto';
    this.instanceView.nativeElement.style.transition = '0.2s';
    this.mouseDown = false;
  }

  async fetchSGList() {
    this.sgDetailsList = this.instanceData.SecurityGroups.map((sg: any) => {
      return sg.GroupId;
    });
    console.log(this.sgDetailsList);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  async loadInstanceMetrics() {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const account = selectedAccountData.data;
        if (!account.site_247_project_id || this.instance.ResourceName == '') {
          this.instance['hide_matrics'] = true;
          return;
        }
        this.instance['cpu'] = 'LOADING';
        this.instance['ram'] = 'LOADING';
        this.instance['disk'] = 'LOADING';
        let data = {
          action: 'ec2_metrics',
          account_id: this.accountId,
          region_name: this.regionId,
          zaaid: account.site_247_project_id,
          instance_name: this.instance.tagName,
          login_user_name: this.userId,
          instance_id: this.instance.instanceId,
        };
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (result.status == '1') {
          this.instance['cpu'] = result.cpu || '-';
          this.instance['ram'] = result.memory || '-';
          this.instance['disk'] = result.disk || '-';
          this.instance['from'] = result.from || undefined;
        } else {
          this.notifier.alert(
            'Info',
            '',
            result.error_message || result.message,
            'info',
            5000
          );
          this.instance['cpu'] = '-';
          this.instance['ram'] = '-';
          this.instance['disk'] = '-';
          this.instance['from'] = undefined;
        }
      } else {
        this.instance['hide_matrics'] = true;
      }
    } catch (err) {
      this.instance['hide_matrics'] = true;
    }
  }

  stopInstance() {
    this.instanceStartStop.emit('stop');
  }

  startInstance() {
    this.instanceStartStop.emit('start');
  }

  async load() {}
}
