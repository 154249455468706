import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { APIService } from '../../../../../api/api.service';
import { NotifierService } from '../../../../../_services/notifier.service';

@Component({
  selector: 'delete-budget',
  templateUrl: './delete.budget.component.html',
  styleUrls: ['./delete.budget.component.css'],
})
export class deleteBudgetComponent implements OnInit, OnDestroy {
  loading = true;
  userId = localStorage.getItem('eId');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  accountId: any;
  regionId: any;
  currentMessage: any;
  @Input('lid') lid: any;
  @Input('mid') mid: any;
  @Output() hideModel = new EventEmitter<any>();

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
    });
  }

  deleting: boolean = false;
  async uploadFile() {
    this.deleting = true;
    let data: any = {
      a: 'delete',
      mid: this.mid.accountId,
      lid: this.lid,
      email: this.userId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/budgetalarm`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.close(true);
    } else {
      this.notifier.alert(
        'Error',
        '',
        result.error || result.msg,
        'error',
        5000
      );
    }
    this.deleting = false;
    this.hideModel.emit(true);
  }

  ngOnDestroy(): void {}

  close(event: any = false) {
    this.hideModel.emit(event);
  }
}
