import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-crdetails',
  templateUrl: './crdetails.component.html',
  styleUrls: ['./crdetails.component.css'],
})
export class CrdetailsComponent implements OnInit {
  eId: string = localStorage.getItem('eId');
  un: string = localStorage.getItem('un');
  @Input('crdetail') crdetail: any;
  @Input('notReviewable') notReviewable: boolean;
  @Input('accountId') accountId: any;
  @Input('regionId') regionId: any;
  @Input('cr_id') cr_id: any;
  @Input('jira_project_id') jira_project_id: any;
  @Input('jira_organization_id') jira_organization_id: any;
  @Input('setMessage') setMessage: any;
  @Output('validated') validated: any = new EventEmitter<boolean>();
  loading = false;
  showConfirm: string;
  checked: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  getSelectedComponents() {
    return Object.keys(this.crdetail['components']).filter((component: any) => {
      return this.crdetail['components'][component].selected;
    });
  }

  getExistingVolumes() {
    return this.crdetail['components']['storage']['volumes'].filter(
      (volume: any) => {
        return volume.existing;
      }
    );
  }

  getNewVolumes() {
    return this.crdetail['components']['storage']['volumes'].filter(
      (volume: any) => {
        return !volume.existing;
      }
    );
  }

  hide(event: boolean) {
    this.showConfirm = null;
    this.validated.emit(event);
  }
}
