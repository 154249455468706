import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from './../../../_services/notifier.service';
import * as moment from 'moment';

declare let window: any;

@Component({
  templateUrl: './ec2costoptimization.component.html',
  styleUrls: ['./ec2costoptimization.component.css'],
})
export class BetaEc2CostOptimizationComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  jobDetails: any = null;
  recomondations: any = [];
  funRef: any;
  tagsList: any = null;
  tagsModalTitle: any = 'Tags';
  modalHeader: any;
  createJobTrigger: boolean = null;
  nextTokens: any = {
    recomondations: undefined,
  };
  currentPage: String = 'job_details';
  fetching: boolean = false;

  selectedInstance: any;
  destroyed: boolean = false;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;

  ec2CostHeaders: any = [
    {
      id: 'instname',
      name: 'Instance Name',
      filter: true,
      rowspan: true,
      click: 'loadMetrics',
      rowspanbasedon: 'recommendations',
    },
    { id: 'insttype', name: 'Instance Type', filter: true },
    {
      id: 'platform',
      name: 'Platform',
    },
    {
      id: 'currentprice',
      name: 'Current Spend (On Demand)',
      filter: true,
      center: true,
    },
    // {
    //   id: 'rec',
    //   name: 'Recommended',
    //   filter: true,
    // },
    // {
    //   id: 'reccPr',
    //   name: 'Recommended Cost',
    //   filter: true,
    //   center: true,
    // },
    // {
    //   id: 'savings_html',
    //   name: 'Savings',
    //   filter: true,
    //   center: true,
    // },
  ];

  ec2Recommendations: any = [
    {
      id: 'recommendedInsType',
      name: 'Recommended',
    },
    {
      id: 'price',
      name: 'New Spend (On Demand)',
      center: true,
    },
    {
      id: 'savings_html',
      name: 'Savings',
      center: true,
    },
  ];

  moreDetailsHeaders: any = [
    {
      id: 'current_cors',
      name: 'Current Cores',
    },
    {
      id: 'current_ram',
      name: 'Current RAM(GB)',
    },
    {
      id: 'mxcpu',
      name: '4 Weeks Max CPU',
    },
    {
      id: 'mncpu',
      name: '4 Weeks Min CPU',
    },
    {
      id: 'acpu',
      name: 'Avg 4 Weeks CPU',
    },
    {
      id: 'mvalue',
      name: 'Avg 4 Weeks RAM Metrics',
    },
  ];

  recomHeader: any = [
    {
      id: 'recom_cors',
      name: 'Recommended Cores',
    },
    {
      id: 'recom_ram',
      name: 'Recommended RAM(GB)',
    },
  ];

  scanAccess: boolean = true;
  EMSStatus: boolean = false;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.funRef = {
      loadMetrics: this.loadMetrics,
    };

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.refresh();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.refresh();
      }
    });
  }

  loading_recom: boolean = false;

  getTotalSavings(): String {
    let savings_html = `0 USD`;
    let minsaving = 0;
    let maxsaving = 0;
    this.recomondations.forEach((recom: any) => {
      if (recom.recommendations && recom.recommendations.length > 0) {
        recom.recommendations.sort((a: any, b: any) => {
          if (
            (isNaN(a.savings) ? 0 : Number(a.savings)) <
            (isNaN(b.savings) ? 0 : Number(b.savings))
          ) {
            return -1;
          }
          return 0;
        });
        minsaving += isNaN(recom.recommendations[0].savings)
          ? 0
          : Number(recom.recommendations[0].savings);
        maxsaving += isNaN(
          recom.recommendations[recom.recommendations.length - 1].savings
        )
          ? 0
          : Number(
              recom.recommendations[recom.recommendations.length - 1].savings
            );
      }
    });
    savings_html = `${minsaving.toFixed(2)} USD - ${maxsaving.toFixed(2)} USD`;
    return savings_html;
  }

  mainHeaders() {
    return [...this.ec2CostHeaders, ...this.ec2Recommendations];
  }

  mainDetailsHeader() {
    return [...this.moreDetailsHeaders, ...this.recomHeader];
  }

  getTotalCost(): String {
    let total = 0;
    this.recomondations.forEach((recom: any) => {
      total += isNaN(recom.present_price_plain)
        ? 0
        : Number(recom.present_price_plain);
    });
    return total.toFixed(2);
  }

  position: any = {
    x: '0px',
    y: '0px',
    overflow: false,
    overflowwidth: '0px',
    pointer: '0px',
    width: '',
    height: '',
  };
  setPosition(event: any) {
    if (
      window.innerWidth <
      event.target.querySelector('.message_back').offsetWidth
    ) {
      this.position.overflow = true;

      if (
        window.innerWidth >
        event.target.querySelector('.message_back').offsetWidth - 110
      ) {
        this.position.overflowwidth = window.innerWidth - 50 + 'px';
      } else {
        this.position.overflowwidth = window.innerWidth - 110 + 'px';
      }
    }
    if (
      window.innerWidth - event.clientX >
      event.target.querySelector('.message_back').offsetWidth / 2
    ) {
      this.position.x =
        window.innerWidth -
        event.clientX -
        event.target.querySelector('.message_back').offsetWidth / 2 +
        'px';
    } else {
      this.position.x = 60 + 'px';
    }
    this.position.pointer =
      window.innerWidth -
      event.clientX +
      event.offsetX -
      event.target.offsetWidth +
      'px';
    this.position.y =
      event.clientY + event.target.offsetHeight - event.offsetY + 'px';
    this.position.width =
      event.target.querySelector('.message_grid').offsetWidth + 30 + 'px';
    this.position.height =
      event.target.querySelector('.message_grid').offsetHeight + 30 + 'px';
    if (
      window.innerHeight - event.clientY <
      event.target.querySelector('.message_grid').offsetHeight
    ) {
      this.position.height = window.innerHeight - event.clientY;
    }
  }

  loadMetrics(ref: any, value: any) {
    ref.selectedInstance = value;
  }

  hideMetric(event: any) {
    this.selectedInstance = null;
  }

  refresh_: boolean = false;
  async refresh() {
    this.refresh_ = true;
    if (this.currentPage == 'job_details') {
      this.jobDetails = null;
      this.loadJobDetails();
    } else if (this.currentPage == 'recomondations') {
      this.recomondations = [];
      this.loadrecomondations();
    }
  }

  async loadJobDetails() {
    this.currentPage = 'job_details';
    this.fetching = true;
    let data = {
      action: 'JobStatus',
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2metricsupdated`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status_code == 1 || result.item) {
      if (result.item.length == 0) {
        this.jobDetails = null;
      } else {
        this.jobDetails = result.item[0];
        this.jobDetails.scandate = moment(this.jobDetails.scandate).format(
          'DD/MM/YYYY hh:mm A'
        );
      }
    } else {
      this.jobDetails = null;
      if (result.message != 'NO RECORD FOUND') {
        this.notifier.alert('Info', '', result.message, 'info', 5000);
      }
    }
    if (!this.destroyed) {
      this.fetching = false;
    }
    this.refresh_ = false;
    return true;
  }

  checkForEMS() {
    return (
      this.recomondations.filter((recom: any) => {
        return recom.EMS;
      }).length == 0
    );
  }

  nextToken: any;
  async loadrecomondations(nextToken: string = '') {
    this.nextToken = nextToken == '' ? null : nextToken;
    this.currentPage = 'recomondations';
    this.loading_recom = true;
    if (this.destroyed) {
      return true;
    }
    if (nextToken == '') {
      this.EMSStatus = false;
      this.refresh_ = true;
      this.recomondations = [];
      this.notifier.loading(true);
    }
    let data = {
      action: 'getRecommendedData',
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2metricsupatedv2`;

    let currentAccountId = this.accountId;
    let currentRegionId = this.regionId;
    if (nextToken != '' && this.refresh_) {
      nextToken = '';
      this.recomondations = [];
      return true;
    }
    this.refresh_ = false;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.status_code == 1 || result.instances) {
      result.instances = result.instances
        .filter((recom: any) => {
          if (recom.mvalue == '-') {
            recom.EMS = false;
          } else {
            recom.EMS = true;
          }
          return recom.insttype != recom.rec;
        })
        .map((recom: any) => {
          recom.recommendations = recom.recommendations.map((recom_: any) => {
            recom_['savings_html'] = `<span class='${
              Number(recom_.savings) > 0
                ? 'green_text'
                : Number(recom_.savings) < 0
                ? 'red_text'
                : ''
            }'>$${recom_.savings}</span>`;
            if (recom['initial_insType_coreram']) {
              let current_data = recom['initial_insType_coreram'].split(', ');
              recom['current_cors'] = current_data[0].split(':')[1];
              recom['current_ram'] = current_data[1].split(':')[1];
            }
            if (recom_['recommendedCoreRam']) {
              let recommended_cors_ram =
                recom_['recommendedCoreRam'].split(', ');
              recom_['recom_cors'] = recommended_cors_ram[0].split(':')[1];
              recom_['recom_ram'] = recommended_cors_ram[1].split(':')[1];
            }
            recom_['accountID'] = currentAccountId;
            recom_['regionID'] = currentRegionId;
            return recom_;
          });
          return recom;
        });
      this.recomondations = [...this.recomondations, ...result.instances];
    } else {
      this.loading_recom = false;
      if (result.message != 'NO RECORD FOUND') {
      }
    }

    if (result.next_token) {
      this.loadrecomondations(result.next_token);
    } else {
      this.nextToken = null;
    }

    if (!this.destroyed) {
      this.notifier.loading(false);
    }
    this.loading_recom = false;
    return true;
  }

  currentAccountId: string = this.accountId;

  downloadReport() {
    let element = document.createElement('table');
    element.innerHTML += `
        <thead>
          <tr>
            <th>Sl. No.</th>
            ${[
              ...this.ec2CostHeaders,
              ...this.recomHeader,
              ...this.ec2Recommendations,
              ...[
                {
                  name: 'Reason',
                },
              ],
              ...this.moreDetailsHeaders,
            ]
              .map((h: any) => {
                return `<th>${h.name}</th>`;
              })
              .join('')}
          </tr>
        </thead>
        <tbody>
            ${this.recomondations
              .map((ec2: any, index: any) => {
                return ec2.recommendations
                  .map((recom: any, recom_index: number) => {
                    return `
                  <tr>
                    ${
                      recom_index == 0
                        ? `<td rowspan='${ec2.recommendations.length}'>${
                            index + 1
                          }</td>`
                        : ''
                    }
                    ${this.ec2CostHeaders
                      .map((h: any) => {
                        return h.rowspan && recom_index == 0
                          ? `<td ${
                              h.rowspan
                                ? `rowspan=${ec2.recommendations.length}`
                                : ''
                            }>
                        ${ec2[h.id]}
                      </td>`
                          : h.rowspan && recom_index != 0
                          ? `
                          
                          `
                          : `<td>
                            ${ec2[h.id]}
                          </td>
                          `;
                      })
                      .join('')}
                    ${this.recomHeader
                      .map((h: any) => {
                        return `<td>
                        ${recom[h.id]}
                      </td>`;
                      })
                      .join('')}
                    ${this.ec2Recommendations
                      .map((h: any) => {
                        return `<td>
                        ${recom[h.id]}
                      </td>`;
                      })
                      .join('')}
                      <td>${recom['reason']}</td>
                      ${this.moreDetailsHeaders
                        .map((h: any) => {
                          return `<td>
                        ${ec2[h.id]}
                      </td>`;
                        })
                        .join('')}
                      
                  </tr>
                `;
                  })
                  .join('');
              })
              .join('')}
        </tbody>
      `;
    window.exportToExcelElement(
      element,
      `EC2 Cost Controls - ${this.accountId}`,
      'xlsx'
    );
  }

  loadHTML(value: any) {
    if (value == 'LOADING_PRICE_THROUGH_API') {
      return `<span class='center'>
        <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" style="width: 25px; margin: 10px;">
      </span>`;
    } else {
      return `<span>${value}</span>`;
    }
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  createJob(event: any) {
    this.createJobTrigger = true;
  }

  hideCreateJob(event: any) {
    this.createJobTrigger = null;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
