<div class="modal show" tabindex="-1" *ngIf="showModal">
  <div class="modal-dialog" [ngStyle]="{'width': maxWidth }">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{modalTitle}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="close(true)"></button>
      </div>
      <div class="modal-body">
        <div class="card">

          <div class="card-body">
            <!--<h5 class="card-title">Case Details</h5>-->
            <table class="table table-docs table-hover" *ngIf="data.length > 0">
              <thead>
                <tr>
                  <th>S.N.</th>
                  <th *ngFor="let h of headers">{{h.name
                    }}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let i of data; let j = index">
                  <td>{{j+1}}</td>
                  <td *ngFor="let h of headers">
                    <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                      <span (click)="callFunction(h.click,i)">
                        {{i[h.id]}}
                      </span>
                    </span>
                    <span *ngIf="!h.click">
                      {{i[h.id]}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5 style="text-align:center; color:#0094ff" *ngIf="data.length == 0">No Data</h5>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="close(true)">Close</button>
      </div>
    </div>
  </div>
</div>