import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  NgZone,
} from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
import { Router, ActivatedRoute } from '@angular/router';
import { support } from 'jquery';

declare let window: any;
declare let $: any;
declare let CKEDITOR: any;

@Component({
  selector: 'add-kb-element',
  templateUrl: './add.kb.component.html',
  styleUrls: ['./add.kb.component.css'],
})
export class AddKBComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  @Input('ticket') ticket: any;
  @Input('supportType') supportType: any;
  uid = localStorage.getItem('t');
  t = localStorage.getItem('t');
  ut = localStorage.getItem('ut');
  eId = localStorage.getItem('eId');
  accountId: any;
  regionId: any;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  currentMessage: any;
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}
  ngOnInit(): void {
    CKEDITOR.replace('ckeditor_description_kb');

    this.ticket['kbdb'] = {
      selected: false,
      text: '',
    };

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
    });
  }

  async yes() {
    await this.updateKB();
    return;
  }

  no() {
    this.hideModal.emit({ status: 0 });
  }

  async updateKB() {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    let textData = CKEDITOR.instances.ckeditor_description_kb.getData();
    var doc = new DOMParser().parseFromString(textData, 'text/html');
    var result = new XMLSerializer().serializeToString(doc);
    var kbBody = result.split('<body>')[1].split('</body>')[0];

    if (this.ticket.new) {
      let data;
      if (this.supportType == 'aws') {
        data = {
          action: 'aws_kb',
          ticket_id: this.ticket.caseId,
          title: this.ticket.subject,
          account_id: this.accountId,
          account_name: JSON.parse(localStorage.getItem('accountIdData'))[
            'selected'
          ],
          client_name: '',
          kbdb: this.ticket.kbdb.text,
          display_id: this.ticket.caseId,
          body: kbBody,
        };
      } else {
        data = {
          action: 'swayam_kb',
          ticket_id: this.ticket.issue_key,
          title: this.ticket.subject,
          account_id: this.accountId,
          account_name: JSON.parse(localStorage.getItem('accountIdData'))[
            'selected'
          ],
          client_name: 'Minfy Technologies',
          display_id: this.ticket.issue_key,
          body: kbBody,
          kedb: this.ticket.kbdb.text,
        };
      }

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        this.hideModal.emit({ status: 1 });
        this.notifier.alert(
          'New KB',
          '',
          'Ticket Added to KB Successfully',
          'success',
          5000
        );
      } else {
        this.notifier.alert('Info', '', result.error_message, 'info', 5000);
      }
    }
    this.notifier.loading(false);
  }
}
