import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

declare let $: any;

@Component({
  selector: 'delete-bom',
  templateUrl: './delete.bom.component.html',
  styleUrls: ['./delete.bom.component.css'],
})
export class DeleteBOMComponent implements OnInit, OnDestroy {
  @Input('bom') bom: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  regionId: any;
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.bom = { ...this.bom };
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
    });
  }

  async deleteEntry() {
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      action: 'delete',
      id: this.bom.uuid,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.close(true);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
      this.notifier.loading(false);
    }
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  close(sendData: boolean = false) {
    if (sendData) {
      this.hideModel.emit(sendData);
    } else {
      this.hideModel.emit(false);
    }
  }
}
