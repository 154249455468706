<div class="card">
  <h5 class="card-header">Finanace Year details for PnL</h5>
  <div class="card-body">
    <div class="main-page">
      <button class="btn btn-light" (click)="exportToCsv()">
        Download CSV
      </button>
      <button class="btn btn-light" (click)="fetchData()">Refresh</button>
    </div>
    <div class="data-table" style="max-width: 100%; overflow: auto">
      <div class="table-container">
        <table
          class="table table-docs table-hover table-bordered"
          id="finPnlData"
        >
          <thead class="table-header">
            <tr>
              <th rowspan="2">S.N</th>
              <th
                *ngFor="let h of main_header"
                [attr.rowspan]="h.rowspan ? h.num : null"
              >
                {{ h.name }}
                <span *ngIf="h.filter" class="inputseach">
                  <input type="text" [(ngModel)]="h.filterText" />
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </th>
              <th *ngFor="let j of dates" colspan="2">
                {{ j | customDate }}
              </th>
            </tr>
            <tr>
              <ng-container *ngFor="let j of dates">
                <th *ngFor="let sh of sub_header">
                  {{ sh.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody class="table-body">
            <ng-container *ngFor="let data of result_data; let j = index">
            <tr *ngIf="notifier.filterTableColums(main_header, data)">
              <td>{{ j + 1 }}</td>
              <td>{{ data.mid }}</td>
              <td>{{ data.customerName }}</td>
              <ng-container *ngFor="let d of dates">
                <ng-container *ngFor="let header of sub_header">
                  <td>
                    {{ data.pnlData[d] ? (header.alter
                        ? header.alter(data.pnlData[d], header.id)
                        : data.pnlData[d][header.id]) : "--" }}
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
