import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
import { regions } from '../../../../dash/dash.component';

declare let $: any;
declare let flatpickr: any;

@Component({
  selector: 'rdsri-enableri-update',
  templateUrl: './rdsupdate.enableri.component.html',
  styleUrls: ['./rdsupdate.enableri.component.css'],
})
export class RDSRIEnableRIUpdateComponent implements OnInit, OnDestroy {
  additionalContacts: string[] = [''];
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  regions: any = regions;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  regionId: any;
  dateRange: any;
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  @Input() ri: any;
  @Input('action') action: any;
  @Input() masteaccid: any;
  dbInstanceTypes: any = [];
  dbEngines: any = [];
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.ri = { ...this.ri };
    $('#regionsList').selectpicker();
    setTimeout(() => {
      $('#regionsList').selectpicker('refresh');
    }, 1000);
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    setTimeout(() => {
      this.dateRange = this.ri.startDate + ' to ' + this.ri.endDate;
      flatpickr('.datetimepicker', {
        dateFormat: 'Y-m-d',
        mode: 'range',
        defaultDate: [this.ri.startDate, this.ri.endDate],
        showMonths: 2,
        onChange: (selectedDates: any, dateStr: any, instance: any) => {
          if (!dateStr.includes('to')) return;
          this.dateRange = dateStr;
        },
      });
    }, 500);
   if( this.ri.riApplied == ''){
    this.ri.riApplied = 'Yes';
   }
  }

  load() {
    // this.fetchInstanceTypes();
  }

  // async fetchRICost() {
  //   let instanceType: string = $('#instanceTypes').val();
  //   let engines: string = $('#engines').val();
  //   let region: string = $('#regionsList').val();
  //   let bt: string = $('#billingTypes').val();
  //   let deploymentType: String = $('#deploymentType').val();
  //   if (
  //     this.ri.riType == 'OnDemand' ||
  //     !engines ||
  //     !instanceType ||
  //     !deploymentType ||
  //     !region ||
  //     !bt
  //   ) {
  //     return;
  //   }
  //   this.notifier.loading(true);
  //   let data = {
  //     accountId: this.accountId,
  //     action: 'fetchRdsRiPrice',
  //     dbInstanceType: instanceType,
  //     dbEngine: engines,
  //     deploymentType: deploymentType,
  //     region: region,
  //     riType: bt,
  //   };
  //   let header = {
  //     Authorization: localStorage.getItem('t'),
  //   };

  //   let apiURL =
  //     'https://api.swayam.cloud/prod/msopsusers/billing/fetchrdsripurchaseorder';
  //   let result = await this.apiService.postDataPromis(apiURL, data, header);
  //   if (result.status == '1' || result.s == '1') {
  //     this.ri.riCost = result.onDemandCost;
  //   } else {
  //     this.notifier.alert('Info', '', result.msg, 'info', 5000);
  //   }
  //   this.notifier.loading(false);
  // }

  // async fetchInstanceTypes() {
  //   this.notifier.loading(true);
  //   let data = { action: 'fetchdbtypes' };

  //   let header = {
  //     Authorization: localStorage.getItem('t'),
  //   };
  //   //https://api.swayam.cloud/v3/admin/support
  //   let apiURL =
  //     'https://api.swayam.cloud/prod/msopsusers/billing/fetchrdsripurchaseorder';

  //   let result = await this.apiService.postDataPromis(apiURL, data, header);

  //   if (result.status == '1' || result.s == '1') {
  //     this.dbInstanceTypes = result.dbInstancetypes;
  //     this.dbEngines = result.dbEngineTypes;
  //     if (this.action.trim() == 'save') {
  //       if (!this.ri.dbInstanceType) {
  //         this.ri.dbInstanceType = 'NA';
  //       }
  //       if (!this.ri.dbEngine) {
  //         this.dbEngines[0];
  //       }
  //     }
  //     setTimeout(() => {
  //       $('#instanceTypes').selectpicker('refresh');
  //       $('#engines').selectpicker('refresh');
  //     }, 100);
  //   } else {
  //     this.notifier.alert('Info', '', result.error, 'info', 5000);
  //   }
  //   this.notifier.loading(false);
  // }

  async updateEntry() {
    // let sDate = $('.datetimepicker').val().split(' to ');
    // let start_date = sDate[0];
    // let end_date = sDate[1];
    // if (this.action.trim() == 'save') {
    //   this.close({
    //     accountId: this.accountId,
    //     action: 'save',
    //     riDetailsList: [
    //       {
    //         id: this.ri.id,
    //         dbInstanceType: $('#instanceTypes').val(),
    //         dbEngine: $('#engines').val(),
    //         deploymentType: $('#deploymentType').val(),
    //         region: $('#regionsList').val(),
    //         quantity: this.ri.quantity.toString(),
    //         riType: $('#billingTypes').val(),
    //         discountPercentage: this.ri.discountPercentage,
    //         startDate: start_date,
    //         endDate: end_date,
    //         riCost: this.ri.riType != 'OnDemand' ? this.ri.riCost : '0',
    //       },
    //     ],
    //   });
    //   return;
    // console.log("trying to save new entry");
    // }
    this.notifier.loading(true);
    if(this.ri.riApplied == 'No'){
      this.ri.riType = 'NA'
    }
    let data: any = {
      accId: this.ri.accId,
      action: 'update',
      riApplied: this.ri.riApplied,
      riType: this.ri.riType,
    };
    console.log("data", data);

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL =
      'https://api.swayam.cloud/v3/admin/billing/master-ri-details';

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.close(true);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  ngOnDestroy(): void {}

  trackByFn() {
    return true;
  }

  close(dataAction: any = false) {
    // if (dataAction) {
      this.hideModel.emit(dataAction);
    // } else {
    //   this.hideModel.emit(false);
    // }
  }
}
