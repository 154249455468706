<div class="alarm_screen" style="box-shadow: 1px 3px 1px #80808024;">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform:capitalize">Update Onboarding</h4>
    </div>
    <div class="alarm_body" style="padding: 1px 25px 5px 25px">
      <div class="row">
        <label>Account ID</label>
        <input type="text" class="form-control" [(ngModel)]="ri.accountId" readonly>
      </div>
      <div class="row">
        <label>Account Name</label>
        <input type="text" class="form-control" [(ngModel)]="ri.accountName" readonly>
      </div>
      <div class="row">
        <label>Bucket Name</label>
        <input type="text" class="form-control" [(ngModel)]="res.bucketName" placeholder="Bucket Name">
      </div>
      <div class="row">
        <label>Folder Name</label>
        <input type="text" class="form-control" [(ngModel)]="res.folderName" placeholder="Folder Name">
      </div>
      <div class="row">
        <label>Enable Billing conductor</label>
        <div style="display: inline-flex; gap:12px;">
          <div>
            <input type="radio" id="policyYes" name="enablePolicy" [(ngModel)]="res.billingConductorEnabled" [value]="true" (change)="onPolicyChange(true)">
            <label for="policyYes">Yes</label>
          </div>
          <div>
            <input type="radio" id="policyNo" name="enablePolicy" [(ngModel)]="res.billingConductorEnabled" [value]="false" (change)="onPolicyChange(false)">
            <label for="policyNo">No</label>
          </div>
        </div>
      </div>

      <div *ngIf="res.billingConductorEnabled">
        <div class="row">
          <label>Billing conductor Bucket Name</label>
          <input type="text" class="form-control" [(ngModel)]="res.billingConductorBucketName" placeholder="Bucket Name">
        </div>
        <div class="row">
          <label> Billing conductor Folder Name</label>
          <input type="text" class="form-control" [(ngModel)]="res.billingConductorFolderName" placeholder="Folder Name">
        </div>
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="updateEntry()"
            
            style="background-color: #5864ff; color: white; text-transform:capitalize; margin-right:20px">
            Update
          </button>
          <!-- [disabled]="!res.billingConductorEnabled" -->
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>



  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>