<div class="card mt-20 bom_selected_01" *ngIf="!bomSelected">
  <h5 class="card-header">BOMs</h5>
  <div class="card-body">
    <div class="new_bom_04">
      <span>
        <button class="btn btn-primary-soft" (click)="newBOM()">
          <i class="fa fa-plus" aria-hidden="true"></i>
          New BOM
        </button>
      </span>
      <span>
        <button class="btn btn-primary-soft" (click)="fetchBOMs()">Refresh</button>
      </span>
    </div>
    <table class="table table-docs table-hover" id="budgets_table">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of bomHeaders">{{ h.name }}</th>
          <th>Download BOM</th>
        </tr>
      </thead>

      <tbody *ngFor="let i of boms; let j = index"
        [ngStyle]="{'display': boms.length > 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td>{{j + 1}}</td>
          <td *ngFor="let h of bomHeaders">
            <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
            <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || '-'" (click)="callFunction(h.click, i)">
            </span>
          </td>
          <td *ngIf="i['bom_id'] && this.downloadingBOM != i['bom_id']">
            <span class="btn btn-light" (click)="downloadBOM(i['bom_id'], i['s3_key'])">
              <i class="fa fa-download" aria-hidden="true"></i>
            </span>
          </td>
          <td *ngIf="i['bom_id'] && this.downloadingBOM == i['bom_id']">
            <span>
              <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_scan_40" />
            </span>
          </td>
          <td *ngIf="!i['bom_id']"></td>
        </tr>
      </tbody>
      <tbody *ngIf="fetchingBOMs">
        <tr>
          <td [attr.colspan]="bomHeaders.length + 3" class="bom_header_48">
            <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_scan_40" />
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': !fetchingBOMs && boms.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="bomHeaders.length + 3">
            <div class="no_bom_found_56">No BOMs Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="card" *ngIf="!launch && bomSelected">
  <h5 class="card-header">
    <button class="btn btn-light" (click)="resetBOMWizard()">
      < BOMs</button>
        BOM Wizard
  </h5>
  <div class="card-body">
    <div class="steps_container">
      <div>
        <div class="step_container">
          <div class="step_line step_line_left" [ngClass]="{passed_step: stepCount >= 1 || stepsCompleted >= 1}">
          </div>
          <div>
            <div class="step_icon" (click)="(stepCount > 1 || stepsCompleted >= 1) && stepCount = 1"
              [ngClass]="{passed_step: stepCount >= 1 || stepsCompleted >= 1}">
              <i class="fa fa-upload" aria-hidden="true"></i>
            </div>
          </div>
          <div class="step_line step_line_right" [ngClass]="{passed_step: stepCount > 1 || stepsCompleted >= 1}">

          </div>
        </div>
        <div class="step_text" [ngClass]="{passed_step: stepCount >= 1 || stepsCompleted >= 1}">
          Upload
        </div>
      </div>
      <div>
        <div class="step_container">
          <div class="step_line step_line_left" [ngClass]="{passed_step: stepCount >= 2 || stepsCompleted >= 2}">

          </div>
          <div>
            <div class="step_icon" (click)="(stepCount > 2 || stepsCompleted >= 2) && stepCount = 2"
              [ngClass]="{passed_step: stepCount >= 2 || stepsCompleted >= 2}">
              <i class="fa fa-connectdevelop" aria-hidden="true"></i>
            </div>
          </div>
          <div class="step_line step_line_right" [ngClass]="{passed_step: stepCount > 2 || stepsCompleted >= 2}">

          </div>
        </div>
        <div class="step_text" [ngClass]="{passed_step: stepCount >= 2 || stepsCompleted >= 2}">
          Mapping
        </div>
      </div>
      <div>
        <div class="step_container">
          <div class="step_line step_line_left" [ngClass]="{passed_step: stepCount >= 3 || stepsCompleted >= 3}">

          </div>
          <div>
            <div class="step_icon" (click)="(stepCount > 3 || stepsCompleted >= 3) && stepCount = 3"
              [ngClass]="{passed_step: stepCount >= 3 || stepsCompleted >= 3}">
              <i class="fa fa-usd" aria-hidden="true"></i>
            </div>
          </div>
          <div class="step_line step_line_right" [ngClass]="{passed_step: stepCount > 3 || stepsCompleted >= 3}">

          </div>
        </div>
        <div class="step_text" [ngClass]="{passed_step: stepCount >= 3 || stepsCompleted >= 3}">
          Pricing Strategy
        </div>
      </div>
      <div>
        <div class="step_container">
          <div class="step_line step_line_left" [ngClass]="{passed_step: stepCount >= 4 || stepsCompleted >= 4}">

          </div>
          <div>
            <div class="step_icon" (click)="(stepCount > 4 || stepsCompleted >= 4) && stepCount = 4"
              [ngClass]="{passed_step: stepCount >= 4 || stepsCompleted >= 4}">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
          </div>
          <div class="step_line step_line_right" [ngClass]="{passed_step: stepCount > 4 || stepsCompleted >= 4}">

          </div>
        </div>
        <div class="step_text" [ngClass]="{passed_step: stepCount >= 4 || stepsCompleted >= 4}">
          Review
        </div>
      </div>
      <div>
        <div class="step_container">
          <div class="step_line step_line_left"
            [ngClass]="{passed_step: stepCount >= 5 || (!processing && stepsCompleted == 4)}">

          </div>
          <div>
            <div class="step_icon" (click)="!processing && stepsCompleted == 4 && stepCount = 5"
              [ngClass]="{passed_step: stepCount >= 5 || (!processing && stepsCompleted == 4)}">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
          </div>
          <div class="step_line step_line_right"
            [ngClass]="{passed_step: stepCount > 5 || (!processing && stepsCompleted == 4)}">

          </div>
        </div>
        <div class="step_text" [ngClass]="{passed_step: stepCount >= 5 || (!processing && stepsCompleted == 4)}">
          Governance
        </div>
      </div>
    </div>
    <div [ngStyle]="{'display': stepCount == 1 ? 'block' : 'none'}">
      <div class="step_main_container">
        <div class="step_header">
          <div class="step_header_171">
            <i class="fa fa-upload" aria-hidden="true"></i>
            <span>Upload</span>
          </div>
          <div class="step_1_5_176">
            Step 1 of 5
          </div>
        </div>
        <div class="step_body">
          <div *ngIf="bomSelected['status'] != 'Process completed'">
            <input type="file" #file id="bomFile" name="" class="bom_select_181"
              accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
              (change)="manualUpload($event)">
            <div *ngIf="!dropedFile && uploadStatus.status != 'SCANNING'" class="upload_bom_container"
              (click)="manualUploadTrigger($event)" (dragover)="dragIn($event)">
              <div [ngStyle]="{opacity: draging ? 0 : 1, display: dropedFile ? 'none' : 'block'}">
                <div class="icon_container">
                  <i class="fa fa-file-text bom_true_188" aria-hidden="true"></i>
                  <div class="plus_icon_container">
                    <i class="fa fa-plus-circle plus_icon" aria-hidden="true"></i>
                  </div>
                </div>
                <div>
                  <div class="upload_main_text">
                    Select a XLSX file to upload
                  </div>
                  <div class="upload_meta_text">
                    or drag and drop it here
                  </div>
                </div>

              </div>
              <div class="drag_in_container" *ngIf="draging && !dropedFile" (dragleave)="draging = false"
                (drop)="showFile($event)">
                Drop here
              </div>
            </div>
            <div *ngIf="dropedFile && uploadStatus.status == 'FILE_SELECTED'" class="upload_bom_container no_hover">
              <div>
                <div>
                  <div class="icon_container">
                    <i class="fa fa-file-text bom_header_True_212" aria-hidden="true"></i>
                  </div>
                  <div>
                    <div class="upload_main_text">
                      {{dropedFile.name}}
                    </div>
                    <div class="upload_meta_text">
                      {{humanFileSize(dropedFile.size, true)}}
                    </div>
                  </div>
                  <div class="actions_container">
                    <button (click)="getURL()">Upload</button>
                    <button (click)="cancelUpload()">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="dropedFile && uploadStatus.status == 'UPLOADING'" class="upload_bom_container no_hover">
              <div>
                <div>
                  <div class="icon_container icon_container_232">
                    <div class="progress_audit progress_audit_233">
                      <div class="tranform_234">
                        <svg class="progress-circle" width="60px" height="60px">
                          <circle class="progress-circle-back" cx="30" cy="30" r="25"></circle>
                          <circle class="progress-circle-prog"
                            [ngStyle]="{'stroke-dashoffset': getPercentage(uploadStatus.percentage)}" cx="30" cy="30"
                            r="25">
                          </circle>
                        </svg>
                      </div>
                      <div class="upload_per_243">
                        {{uploadStatus.percentage}}%
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="upload_main_text">
                      Uploading File...
                    </div>

                    <div class="upload_meta_text">
                      {{humanFileSize(uploadStatus.uploadsize, true)}} / {{humanFileSize(uploadStatus.totalsize, true)}}
                    </div>
                    <div class="actions_container">
                      <button (click)="cancelUpload()">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div [ngStyle]="{display: uploadStatus.status == 'SCANNING' ? 'block' : 'none'}"
              class="upload_bom_container no_hover">
              <div>
                <div>
                  <div class="icon_container">
                    <i class="fa fa-file-text true_268" aria-hidden="true"></i>
                    <div class="magnifier_loading">
                      <img src="../assets/img/magnifier.png" alt="loading scans" class="loading_270" />
                    </div>
                  </div>
                  <div>
                    <div class="upload_main_text">
                      Scanning...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="bomSelected['status'] == 'Process completed'" class="center_align upload_bom_container no_hover">
            <span class="file_name_283">File Name: </span>
            <span class="file_name_284">
              {{bomSelected['file_name']}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div [ngStyle]="{'display': stepCount == 2 ? 'block' : 'none'}">
      <div class="step_main_container">
        <div class="step_header">
          <div class="map_sheet_295">
            <i class="fa fa-connectdevelop" aria-hidden="true"></i>
            <span>Map Sheets</span>
          </div>
          <div class="map_sheet_299">
            Step 2 of 5
          </div>
        </div>
        <div class="step_body step_body_303">
          <div class="map_headers_container">
            <div *ngFor="let header of headerList; let i = index" class="header_item">
              <strong>{{header.key}}</strong>
              <span>
                <select placeholder="Select Entity" class="form-select" (change)="setHeaderName(header)"
                  [(ngModel)]="header.id" *ngIf="bomSelected['status'] != 'Process completed'">
                  <ng-container *ngFor="let option of values">
                    <option value="{{option.id}}" *ngIf="header.id == option.id" selected>{{option.name}}</option>
                    <option value="{{option.id}}" *ngIf="header.id != option.id">{{option.name}}</option>
                  </ng-container>
                </select>
                <span *ngIf="bomSelected['status'] == 'Process completed'">
                  {{header.value}}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="step_footer">
          <div class="strp_folder_323">
            <span class="btn_span" (click)="stepCount = 1">Prev</span>
            <span *ngIf="bomSelected['status'] != 'Process completed'" class="btn_span loader_button upload_bom_326"
              [ngClass]="{dim_button: uplaodBOMLoading}" (click)="!uplaodBOMLoading && saveMappings()">
              <span [ngStyle]="{display: uplaodBOMLoading ? 'block' : 'none'}">
                <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_scan_329" />
              </span>
              <span class="button_text">
                Save and Next
              </span>
            </span>
            <span (click)="stepCount = 3" *ngIf="bomSelected['status'] == 'Process completed'"
              class="btn_span loader_button loader_button_336">
              Next
            </span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="stepCount == 3" [ngStyle]="{'display': stepCount == 3 ? 'block' : 'none'}">
      <div class="step_main_container">
        <div class="step_header">
          <div class="config_346">
            <i class="fa fa-usd" aria-hidden="true"></i>
            <span>Configure Pricing Strategy</span>
          </div>
          <div class="config_354">
            Step 3 of 5
          </div>
        </div>
        <div class="step_body step_body_354">
          <step3-uploadbom-v2 [bom]="bomSelected" [headers]="selectableHeaders(headerList)"
            [pricingStrategy]="pricingStrategy">
          </step3-uploadbom-v2>
        </div>
        <div class="step_footer">
          <div class="step_footer_360">
            <span class="btn_span" (click)="stepCount = 2">Prev</span>
            <span class="btn_span loader_button uploading_bom_361" [ngClass]="{dim_button: uplaodBOMLoading}"
              (click)="validateData()">
              <span [ngStyle]="{display: uplaodBOMLoading ? 'block' : 'none'}">
                <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_scan_364" />
              </span>
              <span class="button_text">
                Next
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="stepCount == 4" [ngStyle]="{'display': stepCount == 4 ? 'block' : 'none'}">
      <div class="step_main_container">
        <div class="step_header">
          <div class="step_4_5_377">
            <i class="fa fa-check" aria-hidden="true"></i>
            <span>Review</span>
          </div>
          <div class="review_379">
            Step 4 of 5
          </div>
        </div>
        <div class="step_body step_body_385">
          <step4-uploadbom-v4 [headers]="selectableHeaders(headerList)" [stepsCompleted]="stepsCompleted"
            [dollarValue]="dollarValue" (refresh)="refresh($event)" [processing]="processing"
            [processFailed]="processFailed" [pricingStrategy]="pricingStrategy">
          </step4-uploadbom-v4>
        </div>
        <div class="step_footer">
          <div class="step_footer_392">
            <span class="btn_span" (click)="stepCount = 3">Prev</span>
            <ng-container *ngIf="!processFailed">
              <span class="btn_span loader_button loadBom_396" *ngIf="!processing && stepsCompleted < 4"
                (click)="finalize()" [ngClass]="{dim_button: uplaodBOMLoading}">
                <span [ngStyle]="{display: uplaodBOMLoading ? 'block' : 'none'}">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" class="loading_scan_398" />
                </span>
                <span class="button_text">
                  Save and Review
                </span>
              </span>
              <span class="btn_span loader_button loader_button_404" *ngIf="!processing && stepsCompleted == 4"
                (click)="stepCount = 5" [ngClass]="{dim_button: uplaodBOMLoading}">
                <span [ngStyle]="{display: uplaodBOMLoading ? 'block' : 'none'}">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" />
                </span>
                <span class="button_text">
                  Next
                </span>
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="stepCount == 5" [ngStyle]="{'display': stepCount == 5 ? 'block' : 'none'}">
      <div class="step_main_container">
        <div class="step_header">
          <div class="step_header_421">
            <i class="fa fa-connectdevelop" aria-hidden="true"></i>
            <span>Governance</span>
          </div>
          <div class="step_5_5_425">
            Step 5 of 5
          </div>
        </div>
        <div class="step_body step_body_429">
          <step5-uploadbom-v2 [headers]="selectableHeaders(headerList)" [dateRange]="dateRange"
            [dollarValue]="dollarValue" [startdate]="dateRange.date" (launch)="launchInstance($event)"
            [pricingStrategy]="pricingStrategy" [bom_id]="bomSelected['bom_id']">
          </step5-uploadbom-v2>
        </div>
        <div class="step_footer">
          <div class="step_footer_436">
            <span class="btn_span" (click)="stepCount = 4">Prev</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<launch-instance *ngIf="launch" (exit)="launch = null" [instance]="launch" [bom_id]="bomSelected['bom_id']">
</launch-instance>
<error-upload-bom *ngIf="fileExists" (hideModel)="uploadFileError($event)"></error-upload-bom>
<update-bom *ngIf="selectedBOM" [action]="'local'" [bom]="selectedBOM" (hideModel)="hideModel($event)"></update-bom>
<!-- [ngStyle]="{display: uplaodBOMLoading ? 'block': 'none'}" -->