import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'changerequest-confirmaction',
  templateUrl: './confirmaction.component.html',
  styleUrls: ['./confirmaction.component.css'],
})
export class ConfirmactionComponent implements OnInit {
  @Input('accountId') accountId: string;
  @Input('regionId') regionId: string;
  @Input('cr_detail') cr_detail: any;
  @Input('cr_id') cr_id: string;
  @Input('jira_project_id') jira_project_id: any;
  @Input('jira_organization_id') jira_organization_id: any;
  @Output('hideModel') hideModel: any = new EventEmitter<boolean>();
  @Input('action') action: string;
  @Input('setMessage') setMessage: any;
  rejectReason: string;
  loading: boolean = false;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  async confirmCall() {
    this.loading = true;
    let data: any = {
      action: 'ec2_change_request_review',
      id_token: localStorage.getItem('idT'),
      status: this.action,
      account_id: this.accountId,
      region_id: this.regionId,
      cr_id: this.cr_id,
      jira_project_id: this.jira_project_id,
      jira_organization_id: this.jira_organization_id,
      client_name: this.cr_detail['client_id'],
      reject_reason: this.action == 'reject' ? this.rejectReason : undefined,
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.setMessage(result.message);
      this.hide(true);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
      this.hide(false);
    }
    this.loading = false;
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  hide(event: boolean) {
    this.hideModel.emit(event);
  }
}
