<div class="card">
  <div class="card-body">
    <div class="sections" *ngIf="regions.length > 0">
      <div style="display: flex; justify-content: right; margin: 10px 0px;">
        <button class="btn btn-light" style="background-color: #5864FF; color: white;"
          (click)="export('')">Export</button>
      </div>
      <div class="section" *ngFor="let region of regions">
        <div class="section_header" (click)="showData(region)">
          <div class="section_title">{{region.name}}</div>
          <div><i class="fa fa-caret-down"></i></div>
        </div>
        <div *ngIf="region.expanded">
          <table class="table table-docs table-hover" id="{{region.name}}_cloud_front_table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <ng-container *ngFor="let h of headers">
                  <th>
                    {{ h.name }}</th>
                </ng-container>
              </tr>
            </thead>

            <tbody [ngStyle]="{'display': region.data && region.data.length > 0 ? 'table-row-group' : 'none'}">
              <tr *ngFor="let i of region.data; let j = index">
                <td>{{j + 1}}</td>
                <ng-container *ngFor="let h of headers">
                  <td>
                    <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"> </span>
                  </td>
                </ng-container>
              </tr>
            </tbody>
            <tbody *ngIf="region.data.length == 0">
              <tr>
                <td [attr.colspan]="headers.length + 1">
                  <div style="text-align: center;">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="regions.length == 0">
      <h5 style="padding: 10px; text-align: center;">No Data Found</h5>
    </div>
  </div>
</div>

<div *ngIf="showConditionalElement" style="display: none;">
  <table id="regionExportData">
    <thead>
      <tr>
        <th>Sl. No.</th>
        <ng-container *ngFor="let h of exportHeaders">
          <th
            *ngIf="((!h.conditionalShow && h.display) || showConditionalElement) && !(showConditionalElement && !h.export)">
            {{ h.name }}</th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let i of exportData; let j = index">
        <td>{{j + 1}}</td>
        <ng-container *ngFor="let h of exportHeaders">
          <td
            *ngIf="((!h.conditionalShow && h.display) || showConditionalElement) && !(showConditionalElement && !h.export)">
            <span *ngIf="!h.click" [innerHTML]="h.alter ? h.alter(i, h.id) : i[h.id]"> </span>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>