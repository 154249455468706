import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-step1-instance-selector',
  templateUrl: './step1-instance-selector.component.html',
  styleUrls: ['./step1-instance-selector.component.css'],
})
export class Step1InstanceSelectorComponent implements OnInit, OnChanges {
  @Input('changeToInstance') changeToInstance: any;
  @Input('instance') instance: any;
  @Input('region') region: string;
  @Input('os') os: string;
  @Input('uuid') uuid: string;
  @Input('accountId') accountId: string;
  @Output('setMainHeight') setMainHeight = new EventEmitter<number>();
  @Output('setScrollLoading') setScrollLoading = new EventEmitter<boolean>();
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['uuid'] &&
      this.showFilter &&
      this.nextToken &&
      this.nextToken != ''
    ) {
      this.loadInstances();
    }
  }

  showSettings: any = {
    show: false,
    finish: false,
  };
  parentElement: any;

  instanceFamily: any = [
    {
      name: 't2',
      selected: true,
    },
    {
      name: 't3',
      selected: false,
    },
    {
      name: 't3a',
      selected: false,
    },
    {
      name: 'c4',
      selected: false,
    },
    {
      name: 'r5',
      selected: false,
    },
  ];
  currentInstanceFamily: string = 't2';

  filterText: string = '';
  filterTextSettings: string = '';
  showFilter: boolean = false;
  loadingInstanceTypes: boolean = true;
  instanceTypes: any = [];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  async showInstance(instance: any) {
    return new Promise((resolve: any, reject: any) => {
      setTimeout(() => {
        instance['display'] = true;
        resolve(true);
      }, 100);
    });
  }

  fetchSelectedInstanceFamilies() {
    return this.instanceFamily.filter((instance: any) => {
      return instance.selected;
    });
  }

  filteredResults() {
    return this.instanceFamily.filter((instance: any) => {
      return instance.name.indexOf(this.filterTextSettings) > -1;
    });
  }

  displaySelected() {
    if (!this.showSettings['show']) {
      this.showSettings['show'] = true;
      setTimeout(() => {
        this.showSettings['finish'] = true;
      }, 1);
    } else {
      this.showSettings['finish'] = false;
      setTimeout(() => {
        this.showSettings['show'] = false;
      }, 200);
    }
  }

  async enableFilter() {
    this.showFilter = true;
    if (this.instanceTypes.length == 0) {
      this.loadInstances();
    }
    this.setMainContainerHeight();
  }

  checkForExists(instanceType: any) {
    return this.instanceFamily.indexOf(instanceType) > -1;
  }

  selectInstance(instance: any) {
    this.changeToInstance['required'] = instance;
    if (!this.changeToInstance['required']['onDemandCost']) {
      this.changeToInstance['required']['onDemandCost'] =
        this.loadingPricing[instance['instanceType']];
    }
    this.showFilter = false;
    setTimeout(() => {
      this.setMainContainerHeight();
    }, 100);
  }

  setMainContainerHeight(height: number = undefined) {
    this.setMainHeight.emit(height);
  }

  filteredResult() {
    return this.instanceTypes.filter((instanceType: any) => {
      return instanceType['instanceType'].indexOf(this.filterText) > -1;
    });
  }

  async ngOnInit() {
    setTimeout(() => {
      this.parentElement = document.querySelector('.step_1_container');
      this.setMainContainerHeight(this.parentElement.offsetHeight + 48);
    }, 1);
    await this.fetchOnDemandCost(this.instance['instanceType']);
    this.instance['onDemandCost'] =
      this.loadingPricing[this.instance['instanceType']];
  }

  reset() {
    this.filterText = '';
    this.nextToken = null;
    this.loadInstances();
  }

  timer: number = 1000;
  timerObj: any;
  checkForMore() {
    this.loadingInstanceTypes = true;
    setTimeout(() => {
      this.setMainContainerHeight();
    }, 100);
    if (this.timerObj) {
      clearTimeout(this.timerObj);
    }
    // if (this.filteredResults().length > 0) {
    //   return;
    // }
    this.timerObj = setTimeout(() => {
      this.nextToken = null;
      this.loadInstances();
    }, this.timer);
  }

  nextToken: string;
  async loadInstances() {
    return new Promise(async (resolve: any, reject: any) => {
      this.setScrollLoading.emit(true);
      this.loadingInstanceTypes = true;
      this.setMainContainerHeight();
      if (!this.nextToken) {
        this.instanceTypes = [];
      }
      let search_key = this.filterText
        ? this.filterText
        : this.instance['instanceType'].split('.')[0] + '.';
      let data = {
        action: 'describe_instance_types',
        account_id: this.accountId,
        region_id: this.region,
        filter: search_key,
        next_token: this.nextToken,
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        result.instance_types = result.instance_types.map((type: any) => {
          type['instanceType'] = type['InstanceType'];
          type['cpu'] = type['VCpuInfo']['DefaultVCpus'];
          type['ram'] = type['MemoryInfo']['SizeInMiB'] / 1024;
          type['display'] = true;
          if (!this.loadingPricing[type['instanceType']]) {
            this.fetchOnDemandCost(type['InstanceType']);
          }
          return type;
        });

        this.instanceTypes = [...this.instanceTypes, ...result.instance_types];
      }
      this.setScrollLoading.emit(false);
      this.nextToken = result.next_token;
      setTimeout(() => {
        this.setMainContainerHeight();
      }, 100);
      let parent: any = document.querySelector('.change_request_body');

      if (
        Math.ceil(parent.scrollTop + parent.offsetHeight) >
          parent.scrollHeight - 300 &&
        this.nextToken
      ) {
        this.loadInstances();
      } else {
        this.loadingInstanceTypes = false;
      }

      resolve(true);
    });
  }

  loadingPricing: any = {};
  async fetchOnDemandCost(instanceType: string) {
    this.loadingPricing[instanceType] = 'LOADING';
    let data = {
      accountId: this.accountId,
      action: 'fetchRiPrice',
      instanceType: instanceType,
      os: this.os,
      region: this.region,
      riType: 'OnDemand',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2pricing`;
    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.loadingPricing[instanceType] = result.onDemandCost.toString();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
  }
}
