<h5>Tags</h5>
<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button class="nav-link active" id="baseline-tags-tab" data-bs-toggle="tab" data-bs-target="#baseline-tags"
      type="button" role="tab" aria-controls="baseline-tags" aria-selected="true">Baseline Tags</button>
    <button class="nav-link" id="compliance-tags-tab" data-bs-toggle="tab" data-bs-target="#compliance-tags"
      (click)="loadCompliance()" type="button" role="tab" aria-controls="compliance-tags"
      aria-selected="false">Compliance</button>
  </div>
</nav>

<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active" id="baseline-tags" role="tabpanel" aria-labelledby="baseline-tags-tab">
    <div class="card" style="border-top: none !important;">
      <!--<h5 class="card-header"></h5>-->

      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->

        <div style="display: flex;">
          <div style="padding-right: 15px;">
            <div style="font-weight: bold; margin: 10px 0px;">Tags List</div>
            <div>
              <select class="form-control" multiple (change)="changeSelectedTags($event)" id="tagListID"
                data-live-search="true">
                <option *ngFor="let tag of tagList" value="{{tag}}">{{tag}}</option>
              </select>
            </div>
          </div>
          <div
            style="flex: 1; border-left: 1px solid black; border-right: 1px solid black; min-height: 100px; margin: 0px 20px; padding: 0px 60px; display: flex; flex-direction: column; align-items: flex-start;">
            <div style="font-weight: bold; margin: 10px 0px;">Baselined Tags
            </div>
            <div style="display: flex; gap: 10px; flex-wrap: wrap;">
              <div *ngFor="let tag of selectedTags; let i = index"
                style="display: flex; justify-content: center; align-items: center; gap: 10px; border-radius: 10px; box-shadow: 1px 1px 3px 1px grey; padding: 5px;">
                <div>{{tag}}</div>
                <div (click)="removeSelectedTags(i)"
                  style="padding: 5px; padding-left: 10px; font-weight: bold; cursor: pointer;">x</div>
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="submitTags()"
              [ngClass]="{'click-disabled': !writeAccess && selectedTags.length == 0}"
              [attr.disabled]="!writeAccess && selectedTags.length == 0 ? true : null">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="compliance-tags" role="tabpanel" aria-labelledby="compliance-tags-tab">
    <div class="card" style="border-top: none !important;">
      <!--<h5 class="card-header"></h5>-->
      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->
        <div style="display: flex; justify-content: flex-end; gap: 10px;">
          <button class="btn btn-light" style="background-color: #5864FF; color: white;"
            (click)="loadCompliance()">Refresh</button>
          <button class="btn btn-light" style="background-color: #5864FF; color: white;"
            (click)="createScanTrigger()">Scan</button>
        </div>

        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of headers">
                {{h.name}}
                <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,instances)">

                  <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                  <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                </span>

                <span *ngIf="h.filter " class="inputseach">
                  <input type="text" [(ngModel)]="h.filterText" />
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </th>
            </tr>
          </thead>

          <tbody [ngStyle]="{'display': instances.length > 0 ? 'table-row-group': 'none'}"
            *ngFor="let i of instances; let j = index">
            <tr *ngIf="notifier.filterTableColums(headers,i)">
              <td>{{j+1}}</td>
              <td *ngFor="let h of headers">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span (click)="callFunction(h.click,i)">
                    {{i[h.id]}}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{i[h.id]}}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody [ngStyle]="{'display': instances.length == 0 ? 'table-row-group' : 'none'}">
            <tr>
              <td [attr.colspan]="headers.length + 1">
                <div style="text-align: center;">No Data Found</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>

<createscan-backupreport *ngIf="createScan" [apiUrl]="getURl()"
  [description]="'Depending on the number of instances in your account this may take some time to complete. <br> Are you sure you want to continue?'"
  [data]="getData()" [message]="'[message]'" (hideModal)="hideModal($event)"></createscan-backupreport>

<tags-modal *ngIf="showTagsModal" modalTitle="Tags" list={{showTagsModal}} instanceID={{showTagsModal.instanceId}}
  accountID={{accountId}} regionID={{regionId}} serviceName="ec2" (hideModal)="hideMdoal($event)"></tags-modal>