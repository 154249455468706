<div class="alarm_screen" style="box-shadow:  1px 3px 1px #80808024 ">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform:capitalize">{{action == 'update' ? 'Update' : 'Add'}} RDS RI</h4>
    </div>
    <div class="alarm_body" style=" padding: 1px 25px 5px 25px">
      <div class="row" *ngIf="ri.type == 'edit'">
        <label>Master Id</label>
        <input type="number" class="form-control" [(ngModel)]="ri.accId" readonly>
      </div>
      <div class="row" *ngIf="ri.type == 'add'">
        <select class="form-control" id="instanceTypes" [(ngModel)]="ri.accId"
        data-live-search="true" placeholder="Select Master ID">
        <option value="" selected="">--- Select Master ID ---</option>
        <option *ngFor="let id of masteaccid; let i = index"  value="{{masteaccid[i]}}">{{masteaccid[i]}}</option>
      </select>
      </div>

      <div class="row">
        <label>Client Name</label>
        <input type="text" class="form-control" [(ngModel)]="ri.accountName" readonly>
      </div>


      <div class="row">
        <label>Cross-Account RDS RI Apply</label>
        <div class="flex">
        <div class="riapp">
          <input type="radio" id="yes" value="Yes" name="riapp" [(ngModel)]="ri.riApplied" >
          <!-- <input *ngIf="ri.type == 'edit'" type="radio" id="yes" value="Yes" name="riapp" [(ngModel)]="ri.riApplied"> -->

          <label>Yes</label>
        </div>

        <div class="riapp">
          <input type="radio" id="no" value="No" name="riapp" [(ngModel)]="ri.riApplied">
          <label>No</label>
        </div>

      </div>
      </div>

      <div class="row" [ngStyle]="{display: ri.riApplied == 'No' ? 'none': 'block'}">
        <label>RI Type</label>
        <select class="form-control" id="instanceTypes" [(ngModel)]="ri.riType"
          data-live-search="true">
          <option  value="" selected="">---Select RI Type---</option>
          <option  value="MinfyRI">Minfy RI</option>
          <option  value="CustomerRI" >Custom RI</option>

        </select>
      </div>


      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="updateEntry()"
            style="background-color: #5864ff; color: white; text-transform:capitalize; margin-right:20px">
            {{action == 'add' ? 'Add' : action}}
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>


  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>