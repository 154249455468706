<div class="leftmenu offcanvas offcanvas-start  show" tabindex="-1" id="offcanvas" data-bs-keyboard="false"
  data-bs-backdrop="false" style="display:none">
  <div class="offcanvas-header">
    <!--<button class="btn float-end" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
    <i class="bi bi-arrow-left-square-fill fs-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"></i>
  </button>-->
    <!--<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>-->

    <h6 class="offcanvas-title d-none d-sm-block" id="offcanvas" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"
      role="button">
      <img style="width:50px" src="../assets/img/logo.png" />
    </h6>

  </div>

  <div class="offcanvas-body px-0">
    <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu">
      <li class="nav-item">
        <a href="/client/" class="nav-link text-truncate">
          <i class="fa fa-home"></i>
        </a>
      </li>

      <li class="dropdown" *ngIf="checkIfAvaForUser('cloudSupport')">
        <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown"
          aria-expanded="false">
          <i class="fa fa-handshake-o"></i>
        </a>
        <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdown">
          <li>
            <h3 class="sub-menu-title">Cloud Support</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('cloudSupport:ticket:create')">
            <a class="dropdown-item" href="/client/ticket/create">Create Ticket</a>
          </li>
          <li *ngIf="checkIfAvaForUser('cloudSupport:ticket:list')"><a class="dropdown-item"
              href="/client/ticket/list">View Tickets</a></li>
        </ul>
      </li>

      <li class="dropdown" *ngIf="checkIfAvaForUser('Operations')">
        <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown"
          aria-expanded="false">
          <i class="fa fa-list"></i>
        </a>

        <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
          <li>
            <h3 class="sub-menu-title">Operations</h3>
            <hr />
          </li>




          <li *ngIf="checkIfAvaForUser('Operations:ec2')">
            <a class="dropdown-item" href="/client/operations/ec2">
              <i class="fa fa-server"></i>
              <span>EC2</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Operations:tags')"><a class="dropdown-item" href="/client/operations/tags"><i
                class="fa fa-tags"></i><span>Tags</span></a></li>
          <li *ngIf="checkIfAvaForUser('Operations:rds')"><a class="dropdown-item" href="/client/operations/rds"><i
                class="fa fa-database"></i><span>RDS</span></a></li>
          <li *ngIf="checkIfAvaForUser('Operations:ebs')"><a class="dropdown-item" href="/client/operations/ebs"><i
                class="fa fa-microchip"></i><span>EBS</span></a></li>
          <li *ngIf="checkIfAvaForUser('Operations:securityGroups')"><a class="dropdown-item"
              href="/client/operations/securitygroups"><i class="fa fa-shield"></i><span>Security Groups</span></a></li>
          <li *ngIf="checkIfAvaForUser('Operations:config')"><a class="dropdown-item"
              href="/client/operations/config"><svg aria-hidden="true" height="16" width="16" focusable="false"
                data-prefix="fas" data-icon="sliders-h" class="svg-inline--fa fa-sliders-h fa-w-16" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M496 384H160v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h336c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160h-80v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h336v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h80c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16C7.2 64 0 71.2 0 80v32c0 8.8 7.2 16 16 16h208v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h208c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16z">
                </path>
              </svg><span>Config</span></a></li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('CostOptimization')">
        <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown"
          aria-expanded="false">
          <i class="fa fa-money"></i>
        </a>
        <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
          <li>
            <h3 class="sub-menu-title">Cost Controls</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('CostOptimization:ec2')"><a class="dropdown-item"
              href="/client/costoptimization/ec2"><i class="fa fa-dollar"></i><span>EC2 Cost Controls</span></a></li>
          <li *ngIf="checkIfAvaForUser('CostOptimization:floatingReport')"><a class="dropdown-item"
              href="/client/costoptimization/floatingReport"><i class="fa fa-file-text"></i><span>Floating
                Report</span></a></li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('Compliance')">
        <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown"
          aria-expanded="false">
          <i class="fa fa-shield"></i>
        </a>
        <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
          <li>
            <h3 class="sub-menu-title">Compliance</h3>
            <hr />
          </li>

          <li *ngIf="checkIfAvaForUser('Compliance:ebs')"><a class="dropdown-item" href="/client/compliance/ebs"><i
                class="fa fa-key"></i><span>EBS</span></a></li>
          <li *ngIf="checkIfAvaForUser('Compliance:s3')"><a class="dropdown-item" href="/client/compliance/s3"><i
                class="fa fa-shopping-basket"></i><span>S3</span></a></li>
          <li *ngIf="checkIfAvaForUser('Compliance:cloudTrail')"><a class="dropdown-item"
              href="/client/compliance/cloudtrail"><i class="fa fa-file-text"></i><span>Cloud Trail</span></a></li>
          <li *ngIf="checkIfAvaForUser('Compliance:iam')"><a class="dropdown-item" href="/client/compliance/iam"><i
                class="fa fa-shield"></i><span>IAM</span></a></li>
          <li *ngIf="checkIfAvaForUser('Compliance:tags')"><a class="dropdown-item" href="/client/compliance/tags"><i
                class="fa fa-tag"></i><span>Tags</span></a></li>
          <li *ngIf="checkIfAvaForUser('Compliance:securityGroups')"><a class="dropdown-item"
              href="/client/compliance/securitygroups"><i class="fa fa-shield"></i><span>Security Groups</span></a></li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('SLAReport')">
        <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown"
          aria-expanded="false">
          <i class="fa fa-ticket"></i>
        </a>
        <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
          <li>
            <h3 class="sub-menu-title">SLA Report</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('SLAReport:createdVsResolved')"><a class="dropdown-item"
              href="/client/slareport/createdvsresolved"><i class="fa fa-industry"></i><span>Created vs
                Resolved</span></a></li>
          <li *ngIf="checkIfAvaForUser('SLAReport:firstResponseSLA')"><a class="dropdown-item"
              href="/client/slareport/firstresponsesla"><i class="fa fa-first-order"></i><span>First Response
                SLA</span></a></li>
          <li *ngIf="checkIfAvaForUser('SLAReport:resolutionSLA')"><a class="dropdown-item"
              href="/client/slareport/resolutionsla"><i class="fa fa-check-square-o"></i><span>Resolution SLA</span></a>
          </li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('Anomalies')">
        <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown"
          aria-expanded="false">
          <i class="fa fa-server"></i>
        </a>
        <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
          <li>
            <h3 class="sub-menu-title">Anomalies</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('Anomalies:dashboard')"><a class="dropdown-item"
              href="/client/anomalies/dashboard"><i class="fa fa-dashboard"></i><span>Dashboard</span></a></li>
        </ul>
      </li>
      <li class="nav-item" *ngIf="checkIfAvaForUser('BackupReport')">
        <a href="/client/backupreport" class="nav-link text-truncate" title="Backup Report">
          <i class="fa fa-folder-open-o "></i>
        </a>
      </li>
      <li class="nav-item" *ngIf="checkIfAvaForUser('Billing')">
        <a href="/client/billing" class="nav-link text-truncate" title="Billing">
          <i class="fa fa-usd"></i>
        </a>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('Reports')">
        <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown"
          aria-expanded="false">
          <i class="fa fa-file"></i>
        </a>
        <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
          <li>
            <h3 class="sub-menu-title">Reports</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('Reports:view')"><a class="dropdown-item" href="/client/reports/view"><i
                class="fa fa-eye"></i><span>View Reports</span></a></li>
          <li *ngIf="checkIfAvaForUser('Reports:enable')"><a class="dropdown-item" href="/client/reports/enable"><i
                class="fa fa-cart-plus"></i><span>Enable Reports</span></a></li>
        </ul>
      </li>
      <li class="dropdown" *ngIf="checkIfAvaForUser('Settings')">
        <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown"
          aria-expanded="false">
          <i class="fa fa-wrench"></i>
        </a>
        <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
          <li>
            <h3 class="sub-menu-title">Settings</h3>
            <hr />
          </li>
          <li *ngIf="checkIfAvaForUser('Settings:createPolicy')">
            <a class="dropdown-item" href="/client/settings/createpolicy">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                class="bi bi-clipboard-check" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z">
                </path>
                <path
                  d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z">
                </path>
                <path
                  d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z">
                </path>
              </svg><span>Create Policy</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Settings:managePolicy')">
            <a class="dropdown-item" href="/client/settings/managepolicy">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-card-list"
                viewBox="0 0 16 16">
                <path
                  d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z">
                </path>
                <path
                  d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z">
                </path>
              </svg><span>Manage Policy</span>
            </a>
          </li>
          <li *ngIf="checkIfAvaForUser('Settings:inviteUser')"><a class="dropdown-item"
              href="/client/settings/inviteuser"><i class="fa fa-user"></i><span>Invite User</span></a></li>
          <li *ngIf="checkIfAvaForUser('Settings:viewInvitedUser')"><a class="dropdown-item"
              href="/client/settings/viewinviteduser"><i class="fa fa-server"></i><span>View Invited User</span></a>
          </li>
          <li *ngIf="checkIfAvaForUser('Settings:createEc2Group')"><a class="dropdown-item"
              href="/client/settings/createec2group"><i class="fa fa-user"></i><span>Create EC2 Group</span></a></li>
          <li *ngIf="checkIfAvaForUser('Settings:updateEc2Group')"><a class="dropdown-item"
              href="/client/settings/updateec2group"><i class="fa fa-server"></i><span>Update EC2 Group</span></a></li>
          <li *ngIf="checkIfAvaForUser('Settings:auditTrail')"><a class="dropdown-item"
              href="/client/settings/audittrail"><i class="fa fa-file-text"></i><span>Audit Trail</span></a></li>
          <li *ngIf="checkIfAvaForUser('Settings:userManagement')"><a class="dropdown-item"
              href="/client/settings/usermanagement"><i class="fa fa-user"></i><span>User Management</span></a></li>
        </ul>
      </li>


    </ul>
  </div>
</div>

<div class="" style="margin-left:40px;">
  <div class="row">
    <div class="col min-vh-100 " style="padding-right:0; margin-top:40px;">
      <!-- toggler -->

      <nav class="navbar navbar-expand-lg top-menu" style="display:none;">
        <div class="container-fluid">


          <!--<a class="navbar-brand" >

  </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>-->

          <form class="flex-grow-1" style="margin-right:15px">
            <!--<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
    <button class="btn btn-primary" type="submit">Search</button>-->

            <div class="input-group">

              <input type="text" class="form-control" placeholder="Search" aria-label="Search"
                aria-describedby="btnGroupAddon">
              <div class="input-group-text" id="btnGroupAddon"> <i class="icon bi-search" aria-hidden="true"></i></div>
            </div>

          </form>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto ">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Dropdown
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a href="#" class="d-block text-decoration-none dropdown-toggle" id="dropdownUser1"
                  data-bs-toggle="dropdown" aria-expanded="true">
                  <img src="../../assets/img/passport.png" class="rounded-circle" width="32" height="32" />
                </a>
                <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1"
                  style="position: absolute; inset: 0px auto auto 0px;  transform: translate(-25px, 34px);"
                  data-popper-placement="bottom-start">
                  <li><a class="dropdown-item" href="#">New project...</a></li>
                  <li><a class="dropdown-item" href="#">Settings</a></li>
                  <li><a class="dropdown-item" href="#">Profile</a></li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><a class="dropdown-item" href="#">Sign out</a></li>
                </ul>
              </li>
            </ul>


            <div class="dropdown text-end">

            </div>
            <div style="width:75px;">

            </div>
          </div>
        </div>
      </nav>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>