import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as moment from 'moment';
declare let window: any;
declare let $: any;


@Component({
  selector: 'app-wrpnl',
  templateUrl: './wrpnl.component.html',
  styleUrls: ['./wrpnl.component.css']
})
export class WrpnlComponent implements OnInit {
  accountId: string;
  regionId: string;
  data_fetch: any = []; // Initialize as an empty array
  dateRange: any;
  currentMessage: any;
  headers: any = [
    { id: 'master', name: 'Master Account ID', filter: true, type: 'string', sort: { sort: true, direction: 'Asc' } },
    { id: 'totalOd', name: 'Total OD', sort: { sort: true, direction: 'Asc' } },
    { id: 'salesValue', name: 'Sales Value',sort: { sort: true, direction: 'Asc' } },
    { id: 'spPurchase', name: 'SP Purchase', sort: { sort: true, direction: 'Asc' } },
    { id: 'notCovOd', name: 'Not Cov OD', sort: { sort: true, direction: 'Asc' } },
    { id: 'spAndNotCov', name: 'SP And Not Cov', sort: { sort: true, direction: 'Asc' } },
    { id: 'wrSavings', name: 'WR Savings', sort: { sort: true, direction: 'Asc' } },
    { id: 'wrSavingsPnl', name: 'WR Savings PNL',sort: { sort: true, direction: 'Asc' } },
  ];

  constructor(
    public notifier: NotifierService,
    private apiService: APIService,
    private zone: NgZone
  ) {}
  


  ngOnInit(): void {
    console.log('data len',this.data_fetch.length);
    console.log('data',this.data_fetch)
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    let temp = moment(new Date());
    // this.fetch();

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.fetch(),
      component: this,
    };

    this.dateRange = temp.format('MM/YYYY');
    $('#monthDate')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm/yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

      this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
        let d = JSON.parse(msg);
        if (d.value == null) {
          return;
        }
        if (d.key == 'accountId') {
          this.accountId = d.value;
          this.fetch();
        } else if (d.key == 'regionId') {
          this.regionId = d.value;
          this.fetch();
        }
      });
  }

 async fetch(){
    this.notifier.loading(true);
    let dRange: any = $('#monthDate').val() || this.dateRange;
    let sData = moment(dRange, 'MM/YYYY').format('MM-YYYY');
    console.log("type",typeof('sData'));
    let data = { month: sData, 
                  a: 'fetch' };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/wr-pnl`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      setTimeout(()=>{
        this.data_fetch = result.data;

      },1000)
    } else {
      setTimeout(()=>{
        this.data_fetch = [];

      },1000)
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }
  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
  export(){
    window.exportToExcel(
      'WR-Pnl-Report',
      `WR-Pnl-Report`,
      'csv'
    );
  }
}
