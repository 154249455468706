import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../api/api.service';
import { NotifierService } from '../../_services/notifier.service';

@Component({
  templateUrl: './backupv2.component.html',
  styleUrls: ['./backupv2.component.css'],
})
export class Backupv2Component implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';

  funRef: any;
  createRule: any = false;

  rules: any = [];

  rulesHeaders: any = [
    {
      id: 'rule_name',
      name: 'Rule Name',
      click: 'loadMoreData',
    },
    {
      id: 'tag_key_value_pairs',
      name: 'Resources',
      alter: (value: string[]) => {
        return value.length;
      },
    },
    {
      id: 'status',
      name: 'Status',
      alter: (value: boolean) => {
        return value ? 'Active' : 'Disabled';
      },
    },
  ];
  fetch_rules: boolean = false;

  accountId: any;
  regionId: any;
  currentMessage: any;
  notifications: any = [];
  activeRule: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.funRef = {
      loadMoreData: this.loadMoreData,
    };

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    this.activeRule = null;
    await this.loadRules();
  }

  createRuleTrigger() {
    this.createRule = {
      backup_frequency: 0,
      rn_rule_id: '',
      time_zone: '',
      updation_time: '',
      created_by: this.userId,
      status: true,
      region_name: this.regionId,
      account_id: this.accountId,
      backup_retention: 0,
      resources: [],
      rule_id: '',
      tag_key_value_pairs: [],
      log_retention: 0,
      rule_creation_time: '',
      updated_by: '',
      rule_name: '',
      backup_type: 'backup_by_tag',
      last_triggered: '',
      resources_except: '',
    };
  }

  async loadRules() {
    this.fetch_rules = true;
    this.rules = [];

    let data: any = {
      action: 'list_rules',
      account_id: this.accountId,
      region_name: this.regionId,
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiUrl: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/backup`;

    let result = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (
      (result.s == '1' || result.status == '1') &&
      result.rules &&
      result.rules.length > 0
    ) {
      this.rules = result.rules;
      this.rules = this.rules.map((rule: any) => {
        if (!rule.tag_key_value_pairs) {
          rule['tag_key_value_pairs'] = [];
        }
        return rule;
      });
    }

    this.fetch_rules = false;
  }

  checkClose(event: any) {
    if (event) {
      this.load();
    }

    this.activeRule = null;
  }

  close(evnet_: any) {
    if (evnet_) {
      this.load();
    }
    this.createRule = null;
  }

  async loadMoreData(ref: any, value: any) {
    ref.activeRule = value;
  }

  callFunction(value: any, param: any) {
    this.funRef[value](this, param);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
