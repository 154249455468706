<div class="alarm_screen" style="box-shadow:  1px 3px 1px #80808024 ">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform:capitalize">{{action == 'add' ? 'Invite' : 'Update'}} User</h4>
    </div>
    <div class="alarm_body" style=" padding: 1px 25px 5px 25px">
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">User Name</label>
        <input type="text" class="form-control" placeholder="Enter User Name" *ngIf="action == 'add'"
          [(ngModel)]="userDetails.clientName" name="username" />
        <input type="text" class="form-control" [attr.disabled]="true" *ngIf="action == 'update'"
          [ngModel]="userDetails.clientName" name="username" />
      </div>
      <div class="form-group mb-2" *ngIf="action == 'update'">
        <label for="exampleInputEmail1">Company Name</label>
        <input type="text" class="form-control" [attr.disabled]="true" [ngModel]="userDetails.clientCmpName"
          name="company" />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Email ID</label>
        <input type="text" class="form-control" placeholder="Enter Email ID" *ngIf="action == 'add'"
          [(ngModel)]="userDetails.emaiId" name="emailid" />
        <input type="text" class="form-control" [attr.disabled]="true" *ngIf="action == 'update'"
          [ngModel]="userDetails.emaiId" name="emailid" />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Phone Number</label>
        <input type="text" class="form-control" placeholder="Enter Phone Number" *ngIf="action == 'add'"
          [(ngModel)]="userDetails.mobileNumber" name="company" />
        <input type="text" class="form-control" *ngIf="action == 'update'" [attr.disabled]="true"
          [ngModel]="userDetails.mobileNumber" name="company" />
      </div>
      <div class="form-group mb-2" [ngStyle]="{display: urlPrefix == 'client'? 'block' : 'none'}">
        <label for="exampleInputEmail1">AWS Account List</label>
        <select id="instancesList" class="form-control" multiple data-live-search="true"
          (change)="fetchAccountGroups()">
          <option *ngFor="let account of accounts" value="{{account.accountId}}">{{account.accountId}}
            ({{account.accountName}})</option>
        </select>
      </div>
      <div class="form-group mb-2" [ngStyle]="{display: urlPrefix == 'admin'? 'block' : 'none'}">
        <label for="exampleInputEmail1">Account Group</label>
        <select id="accountGroupLists" class="form-control" multiple data-live-search="true">
          <option *ngFor="let account of accountGroups" value="{{account.groupName}}">{{account.groupName}}</option>
        </select>
      </div>
      <div class="form-group mb-2" [ngStyle]="{display: action == 'add' && urlPrefix == 'client' ? 'block' : 'none'}">
        <label for="exampleInputEmail1">Policy Type</label>
        <div class="pt-2">
          <input type="radio" [(ngModel)]="policyType" value="predefined" id="predefinedPolity" name="policyType">
          <label for="predefinedPolity">Pre-defined Policy</label>
        </div>
        <div class="pt-2">
          <input type="radio" (change)="checkOfServices()" [(ngModel)]="policyType" value="manual" id="manualPolicy"
            name="policyType">
          <label for="manualPolicy">Manual Policy</label>
        </div>
      </div>
      <div class="mb-2" [ngStyle]="{'display': policyType == 'predefined' && urlPrefix == 'client' ? 'block' : 'none'}">
        <label for="exampleInputEmail1">Select Policy List</label>
        <div class="pt-2">
          <select id="policyList" [(ngModel)]="policyName" class="form-control" [(ngModel)]="userDetails.policyName"
            data-live-search="true">
            <option *ngFor="let policy of polices" value="{{policy}}">{{policy}}</option>
          </select>
        </div>
      </div>
      <div class="mb-2" [ngStyle]="{'display': policyType == 'manual' && urlPrefix == 'client' ? 'block' : 'none'}">
        <label for="exampleInputEmail1">Select Service List</label>
        <div class="pt-2">
          <select multiple data-live-search="true" (change)="checkOfServices()" id="serviceList" class="form-control"
            data-live-search="true">
            <option *ngFor="let service of services" value="{{service}}">{{service}}</option>
          </select>
        </div>
      </div>
      <div class="mb-2" [ngStyle]="{'display': policyType == 'manual' && urlPrefix == 'client' ? 'block' : 'none'}">
        <label for="exampleInputEmail1">Select Instances Group</label>
        <div class="pt-2">
          <select id="instancesGroup" class="form-control" data-live-search="true">
            <option *ngFor="let account of accountGroups" value="{{account.groupName}}">{{account.groupName}}
              ({{account.accountId}})</option>
          </select>
        </div>
      </div>
      <div class="mb-2" [ngStyle]="{'display': policyType == 'manual' && urlPrefix == 'client' ? 'block' : 'none'}">
        <label for="exampleInputEmail1">Access Type</label>
        <div class="pt-2">
          <div class="pt-2">
            <input type="radio" [(ngModel)]="accessType" value="readOnly" id="policyAccessReadOnly"
              name="policyAccessType">
            <label for="policyAccessReadOnly">Read Only</label>
          </div>
          <div class="pt-2">
            <input type="radio" [(ngModel)]="accessType" value="readandwrite" id="policyAccessReadAndWrite"
              name="policyAccessType">
            <label for="policyAccessReadAndWrite">Read and Write</label>
          </div>
        </div>
      </div>
      <div class="form-group mb-2" [ngStyle]="{display: urlPrefix == 'client'? 'block' : 'none'}">
        <label for="exampleInputEmail1">Two Factor Authentication</label>
        <select name="" [(ngModel)]="twowayfactor" id="" class="form-select" (change)="checkTwoWayFactor()">
          <option [ngValue]="true">Enable</option>
          <option [ngValue]="false">Disable</option>
        </select>
      </div>
      <div class="form-group mb-2" [ngStyle]="{display: urlPrefix == 'client'? 'block' : 'none'}">
        <label for="exampleInputEmail1">Tags</label>
        <div class="tags_flex">
          <div class="tags_button_container">
            <select name="" placeholder="Account ID" data-size="5" [(ngModel)]="selectedAccountID"
              id="accountsTagsFilter" data-live-search="true" (change)="loadTags()">
              <option *ngFor="let account of userDetails.accountDetailsList" [ngValue]="account.accountId">
                {{account.accountId}}</option>
            </select>
            <select name="" placeholder="Select Key" data-size="5" [(ngModel)]="currentTag" id="tagKeysFilter"
              data-live-search="true" (change)="loadValues()">
              <option *ngFor="let key of tagKeys" [ngValue]="key" [attr.data-content]="getHTML(key)"></option>
            </select>
            <select name="" (change)="setValues()" data-size="5" multiple placeholder="Select Value"
              id="tagValuesFilter" data-live-search="true">
              <option *ngFor="let value of tagValues" [ngValue]="value">{{value}}</option>
            </select>
          </div>
          <button title="Reset Values" *ngIf="!loadingTags" class="btn btn-light" (click)="resetValues()"><i
              class="fa fa-refresh" aria-hidden="true"></i></button>
          <img src="../assets/img/loading_2.svg" alt="loading scans"
            [ngStyle]="{display: loadingTags ? 'block' : 'none'}" style="width: 18px; margin: 10px" />
        </div>
      </div>
    </div>
    <div class="alarm_actions alarm_footer">
      <div style="display: flex; justify-content: flex-end; gap: 8px;">
        <button class="btn" (click)="updateEntry()" [ngClass]="{'click-disabled': !writeAccess}"
          [attr.disabled]="!writeAccess ? true : null"
          style="background-color: #5864ff; color: white; text-transform:capitalize;">
          {{action == 'add' ? 'Create' : 'Update'}}
        </button>
        <button class="btn btn-light" (click)="close()">Cancel</button>
      </div>
    </div>
  </div>


  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>

  <error-invite-user *ngIf="chcekFor2FA" (hideModel)="uploadFileError($event)"></error-invite-user>