import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-step1-storage-selector',
  templateUrl: './step1-storage-selector.component.html',
  styleUrls: [
    './step1-storage-selector.component.css',
    '../step1-instance-selector/step1-instance-selector.component.css',
  ],
})
export class Step1StorageSelectorComponent implements OnInit {
  @Output('setMainHeight') setMainHeight = new EventEmitter<number>();
  @Input('storage') storage: any;
  constructor() {}

  ngOnInit(): void {
    if (this.storage['volumes'].length == 0) {
      this.addStorage();
    }
    this.setMainContainerHeight();
  }

  addStorage() {
    this.storage['volumes'].push({
      type: 'gp3',
      id: '',
      name: '',
      size: 150,
      existing: false,
    });
    this.setMainContainerHeight();
  }

  removeStorage(index: number) {
    this.storage['volumes'].splice(index, 1);
    this.setMainContainerHeight();
  }

  setMainContainerHeight(height: number = undefined) {
    this.setMainHeight.emit(height);
  }
}
