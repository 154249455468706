import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

import * as moment from 'moment';

declare let flatpickr: any;
declare let window: any;
declare let $: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './ricoverage.component.html',
  styleUrls: ['./ricoverage.component.css'],
})
export class RICoverageCBSComponenet implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  riData: any = [];
  type: any = 'customerRI';
  selectedAccount: any = { accountId: '', data: [] };

  accountId: any;
  regionId: any;
  currentMessage: any;
  dateRange: any;
  loadingReportData: boolean = true;

  headers: any = [
    {
      id: 'childAccountID',
      name: 'Account ID',
    },
    {
      id: 'riEffectiveCost',
      name: 'RI Effective Cost',
    },
    {
      id: 'spEffectiveCost',
      name: 'SP Effective Cost',
    },
    {
      id: 'odEffectiveCost',
      name: 'On Demand Effective Cost',
    },
    {
      id: 'totalCost',
      name: 'Total Cost',
    },
  ];

  constructor(
    private notifier: NotifierService,
    private apiService: APIService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.load(),
      component: this,
    };

    let temp = moment(new Date());
    this.dateRange = temp.format('MM/YYYY');

    $('#monthDate')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm/yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    this.notifier.loading(true);
    this.selectedAccount.data = [];
    let dRange: any = $('#monthDate').val() || this.dateRange;
    let sData = moment(dRange, 'MM/YYYY').format('MM-YYYY');
    let data = {
      monthYear: sData,
      action: 'fetchRIMasterDetails',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://hj6jo6jw09.execute-api.ap-south-1.amazonaws.com/dev/v2/fetchrireport`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    console.log(result);
    if (result.status == '1' || result.s == '1') {
      this.riData = result.masterRIDataList;
      if (this.selectedAccount.accountId) {
        this.getAccounts(true);
      }
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  getAccounts(check: any = false) {
    let accounts = this.riData.map((ri: any) => {
      return ri.masterAccountID;
    });
    if (!this.selectedAccount.accountId) {
      this.changeSelectedAccount(accounts[0]);
    }
    if (check) {
      this.fetchAccountBillingData();
      this.notifier.loading(false);
    }
    return accounts;
  }

  sumAccount() {
    let total: any = {
      riEffectiveCost: 0,
      spEffectiveCost: 0,
      odEffectiveCost: 0,
      totalCost: 0,
    };
    this.selectedAccount.data.forEach((account: any) => {
      for (let key of Object.keys(account)) {
        if (total.hasOwnProperty(key)) {
          total[key] += Number(account[key]);
        }
      }
    });
    for (let key of Object.keys(total)) {
      try {
        total[key] = total[key].toFixed(3);
      } catch (error) {}
    }
    return total;
  }

  changeType(type: string) {
    this.type = type;
    if (this.selectedAccount.accountId) {
      this.notifier.loading(false);
      this.fetchAccountBillingData();
    }
  }

  async fetchAccountBillingData() {
    if (!this.selectedAccount.accountId) return;
    this.notifier.loading(false);
    this.loadingReportData = true;
    this.selectedAccount.data = [];
    let dRange: any = $('#monthDate').val() || this.dateRange;
    let sData = moment(dRange, 'MM/YYYY').format('MM-YYYY');
    let data = {
      monthYear: sData,
      action: 'fetchRIChildAccDetails',
      masterAccountID: this.selectedAccount.accountId,
      billingType: this.type,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://hj6jo6jw09.execute-api.ap-south-1.amazonaws.com/dev/v2/fetchrireport`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      if (result.childRIDataList) {
        this.selectedAccount.data = result.childRIDataList;
      } else {
        this.selectedAccount.data = [];
      }
    } else {
      this.selectedAccount.data = [];
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.loadingReportData = false;
    this.sumAccount();
  }

  changeSelectedAccount(account: any) {
    this.selectedAccount['accountId'] = account;
    this.fetchAccountBillingData();
    this.notifier.loading(false);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
