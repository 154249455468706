<div class="card">
  <h5 class="card-header">Snapshots</h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->
    <div style="display: flex; justify-content: flex-end; gap: 8px;">
      <button class="btn" style="background-color: #5864ff; color: white" (click)="createScanTrigger()">
        Scan
      </button>
      <button class="btn" style="background-color: #5864ff; color: white" (click)="load()">
        Refresh
      </button>
      <button *ngIf="instances && instances.length > 0" class="btn" style="background-color: #5864ff; color: white"
        (click)="export()">
        Export CSV
      </button>
    </div>

    <table class="table table-docs table-hover" id="backup-report-table">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of instancesHeader">{{ h.name }}</th>
        </tr>
      </thead>

      <tbody *ngFor="let i of instances; let j = index">
        <tr>
          <td>
            <i *ngIf="!i.showNested" class="fa fa-caret-right"></i> <i *ngIf="i.showNested"
              class="fa fa-caret-down"></i>
            {{ j + 1 }}
          </td>
          <td *ngFor="let h of instancesHeader">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span>
                {{ i[h.id] }}
              </span>
            </span>
            <span *ngIf="!h.click" [innerHTML]="i[h.id]">
            </span>
          </td>
        </tr>
        <tr *ngIf="i.showNested" style="height:100px">
          <td [attr.colspan]="instancesHeader.length + 1">
            <nTable [headers]="snapshotsHeader" [data]="i.snapshots" (hideModal)="hideNested($event)"></nTable>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<createscan-backupreport *ngIf="createScan" [apiUrl]="getURl()" [data]="getData()"
  [description]="'Depending on the number of snapshots in your account this may take some time to complete. <br> Are you sure you want to continue?'"
  [message]="'Scan Requested Successfully, [message]'" (hideModal)="hideModal($event)">
</createscan-backupreport>