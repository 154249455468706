<div class="card" *ngIf="!add">
  <h5 class="card-header" style="
  display: flex;
  justify-content: flex-center;
  align-items: center;
  gap: 8px;
">Conversion Rate</h5>
  <!-- <button *ngIf="viewall"
 class="btn btn-light"
 style="
 width:90px;
  display: flex;
  justify-content: flex-center;
  align-items: center;
  gap: 8px;
"
 
 (click)="viewall=false"
> < Back
</button> -->

  <div class="card-body">
    <div style="
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 8px;
      ">
      <div style="
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          gap: 8px;
        ">
        <!-- <div>
          <span>Month Year:</span>
          <span>
            <input type="text" id="monthDate" class="form-control" />
          </span>
        </div> -->
        <div class="flex-start">
          <button  class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Add
            New</button>
          <!-- <button class="btn btn-light" style="background-color: #5864ff; color: white" (click)="enableEditing()">{{
            editMode ? 'Exit' : 'Edit'}}</button> -->
          <!-- <button *ngIf="editMode && viewall" class="btn btn-light" style="background-color: #5864ff; color: white"
            (click)="save()">Save</button> -->
          <button *ngIf="!editMode" class="btn btn-light" style="background-color: #5864ff; color: white"
            (click)="loadAllData()">Refresh</button>
          <!-- (click)="loadChildData(this, null, selectedMID)" -->
        </div>
      </div>
    </div>


    <div style="max-width: 100%; overflow: auto" class="container d-flex justify-content-center">
      <!-- <table *ngIf="!viewall" class="table table-docs table-hover" style="margin-left: auto;margin-right: auto; width:100%" id="cloudFrontData">
        <thead>
          <tr>
            <th>Month</th>

            <th>Currency Rate</th>
          </tr>
        </thead>

        <tbody *ngIf="(currentrate).length != 0">
          <tr>
            <td>{{monthyear}}</td>
            <td>{{currentrate.rate}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="(currentrate).length == 0">
          <tr>
            <td colspan="100%">

              <div style="text-align: center">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table> -->
      <table class="table table-docs table-hover" style="margin-left: auto;margin-right: auto; width:100%"
        id="cloudFrontData">
        <thead>
          <tr>
            <th>Sr</th>
            <th>Month</th>
            <!-- <ng-container
                *ngFor="let h of selectedMID ? child_headers : headers"
              >
                <th *ngIf="!h.conditionalShow || showConditionalElement">
                  {{ h.name }}
                </th>
              </ng-container> -->
            <th>Conversion Rate</th>
            <th>Updated By</th>
            <th>Updated On</th>
            <th>Action</th>
            <!-- <th>Action</th> -->
          </tr>
        </thead>

        <tbody *ngIf="(alldata).length != 0">
          <tr *ngFor="let data of alldata; let srNo = index">
            <td>{{srNo+1}}</td>
            <td>
              <!-- <div *ngIf="data.isEdit">
                <input type="text" id="monthDate" class="form-control" placeholder="MM-YYYY"  [(ngModel)]="data.monthYear"/>
              </div> -->
              <div>
                {{data.monthYear}}
              </div>
            </td>
            <td>
              <div *ngIf="data.isEdit">
                <input type="text" [(ngModel)]="data.rate" />
              </div>
              <div *ngIf="!data.isEdit">
                {{data.rate}}
              </div>
            </td>
            
            <td>{{data.updatedby}}</td>
            <td>{{data.updatedon}}</td>
            <td>
              <div *ngIf="!data.isEdit" style="justify-content: left;">
                <i class="fa-solid fa-pen-to-square" aria-hidden="true" (click)="onEdit(data)"></i>
                <!-- <i class="fa fa-trash-o" aria-hidden="true"></i> -->
              </div>
              <div *ngIf="data.isEdit" style="display: flex; justify-content: space-between;">
                <i class="fa-solid fa-check-to-slot" (click)="validateForm(data)"></i>
                
                <i class="fa-solid fa-xmark" (click)="onCancel(data)"></i>
              </div>
            </td>
          </tr>
        </tbody>
        <!-- <tbody *ngIf="(alldata).length != 0">
          <tr *ngFor="let data of alldata; let i = index">
            <td>{{data.monthYear}}</td>
            <td *ngIf="!editMode" ><span>{{data.rate}}</span></td>
              <td *ngIf="editMode"><input class="form-control" [(ngModel)]="data.rate"/></td>
            <td>{{data.updatedby}}</td>
            <td>{{data.updatedon}}</td>
          </tr>
        </tbody> -->
        <tbody *ngIf="(alldata).length == 0">
          <tr>
            <td colspan="100%">

              <div style="text-align: center">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
  <!-- <div style="max-width: 100%; overflow: auto">
      <table class="table table-docs table-hover" id="cloudFrontData">
        <thead>
          <tr>
            <th>S.N.</th>
            <ng-container
              *ngFor="let h of selectedMID ? child_headers : headers"
            >
              <th *ngIf="!h.conditionalShow || showConditionalElement">
                {{ h.name }}
              </th>
            </ng-container>
          </tr>
        </thead>

        <tbody
          [ngStyle]="{
            display:
              (selectedMID ? child_headers : headers).length > 0
                ? 'table-row-group'
                : 'none'
          }"
        >
          <tr
            *ngFor="
              let i of selectedMID ? childAccountData : cfrcData;
              let j = index
            "
            [ngClass]="{ hoverable: !selectedMID }"
          >
            <td>{{ j + 1 }}</td>
            <ng-container
              *ngFor="let h of selectedMID ? child_headers : headers"
            >
              <td *ngIf="!h.conditionalShow || showConditionalElement">
                <span
                  *ngIf="h.click"
                  class="btn"
                  (click)="callFunction(h.click, i)"
                  >
                  {{i[h.id]}}
                </span>
                <div
                  *ngIf="!h.click"
                  [ngStyle]="{ color: h.color ? h.color(i[h.id]) : 'inherit' }"
                >
                  <span *ngIf="!h.editable || !editMode">
                    {{ h.alter ? h.alter(i, h.id) : i[h.id] }}
                  </span>
                  <span *ngIf="h.editable && editMode">
                    <input type="text" [(ngModel)]="i[h.id]" />
                  </span>
                </div>
              </td>
            </ng-container>
          </tr>
        </tbody>
        <tbody *ngIf="(curnrate).length == 0">
          <tr>
            <td
              [attr.colspan]="
                (curnrate).length + 1
              "
            >
              <div style="text-align: center">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->
  <!-- </div> -->
</div>
<!-- <div *ngIf="add" class="card">
  <h2>New</h2>
    <div>
      <span>Month Year:</span>
        <span>
          <input type="text" id="monthDate" class="form-control"  [(ngModel)]="monthYear"/>
        </span>
    </div>
    <div>
      <label for="Rate">
        <input type="text" id="rate" [(ngModel)]="rate" required>
      </label>
    </div>
    <div>
      <button type="button" class="btn" style="background-color: #5864ff;" (click)="addnew()"> Add </button>

    </div>
        
</div> -->



<!-- <div style="max-width: 100%; overflow: auto">
  <table class="table table-docs table-hover" id="cloudFrontData">
    <thead>
      <tr>
        <th>S.N.</th>
        <ng-container
          *ngFor="let h of selectedMID ? child_headers : headers"
        >
          <th *ngIf="!h.conditionalShow || showConditionalElement">
            {{ h.name }}
          </th>
        </ng-container>
      </tr>
    </thead>

    <tbody
      [ngStyle]="{
        display:
          (selectedMID ? child_headers : headers).length > 0
            ? 'table-row-group'
            : 'none'
      }"
    >
      <tr
        *ngFor="
          let i of selectedMID ? childAccountData : cfrcData;
          let j = index
        "
        [ngClass]="{ hoverable: !selectedMID }"
      >
        <td>{{ j + 1 }}</td>
        <ng-container
          *ngFor="let h of selectedMID ? child_headers : headers"
        >
          <td *ngIf="!h.conditionalShow || showConditionalElement">
            <span
              *ngIf="h.click"
              class="btn"
              (click)="callFunction(h.click, i)"
              >
              {{i[h.id]}}
            </span>
            <div
              *ngIf="!h.click"
              [ngStyle]="{ color: h.color ? h.color(i[h.id]) : 'inherit' }"
            >
              <span *ngIf="!h.editable || !editMode">
                {{ h.alter ? h.alter(i, h.id) : i[h.id] }}
              </span>
              <span *ngIf="h.editable && editMode">
                <input type="text" [(ngModel)]="i[h.id]" />
              </span>
            </div>
          </td>
        </ng-container>
      </tr>
    </tbody>
    <tbody *ngIf="(selectedMID ? childAccountData : cfrcData).length == 0">
      <tr>
        <td
          [attr.colspan]="
            (selectedMID ? child_headers : headers).length + 1
          "
        >
          <div style="text-align: center">No Data Found</div>
        </td>
      </tr>
    </tbody>
  </table>
</div> -->























<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">


  <div class="modal-dialog modal-dialog-centered" style="align-items: center;justify-content: center;"> 
    <div class="modal-content" style="width:50%">
    <div class="modal-header">
      <h1 class="modal-title fs-5" id="exampleModalLabel">
        Add New
      </h1>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="monthYear">Month and Year</label>
        <div class="inputgroup">
          <input type="text" id="monthDate" class="form-control" [(ngModel)]="monthYear"/>
        </div>
      </div>
      <div class="mb-3">
        <label for="rate">Convesion Rate</label>
        <input type="text" class="form-control" id="rate" [(ngModel)]="rate"/>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="close()">Close</button>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="addnew()">Add</button>
    </div>
  </div>
</div> 
</div>