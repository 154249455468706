import { Component, OnInit, OnDestroy } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from './../../../_services/notifier.service';

declare let $: any;
declare let window: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './tags.component.html',
})
export class tagsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  tagList: any = [];
  accountId: any;
  regionId: any;
  instances: any = [];
  createScan: any;
  currentMessage: any;
  showTagsModal: any;

  selectedTags: any = [];

  funRef: any;

  headers: any = [
    {
      id: 'instanceId',
      name: 'Instance ID',
    },
    {
      id: 'missingTags',
      name: 'Missing Tags',
    },
    {
      id: 'scannedTime',
      name: 'Last Scanned Time',
    },
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    setTimeout(() => {
      $('#tagListID').selectpicker();
    }, 100);
    this.funRef = {};

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  hideModal(event: any) {
    this.createScan = false;
  }

  hideMdoal(event: any) {
    this.showTagsModal = null;
  }

  getURl() {
    return `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/savemanidatorytags `;
  }

  getData() {
    return {
      action: 'scanRequest',
      region: this.regionId,
      accountId: this.accountId,
    };
  }

  createScanTrigger() {
    this.createScan = true;
  }

  changeSelectedTags(event: any) {
    this.selectedTags = $(event.target).val();
  }

  removeSelectedTags(i: number) {
    this.selectedTags.splice(i, 1);
    $('#tagListID').selectpicker('val', this.selectedTags);
    setTimeout(() => {
      $('#tagListID').selectpicker('refresh');
    }, 100);
  }

  addMissingTags(list: any = ['_all_']) {
    if (list[0] == '_all_') {
      this.showTagsModal = this.instances;
    } else {
      this.showTagsModal = this.instances.filter((instance: any) => {
        return instance.selected;
      });
    }
  }

  async loadCompliance() {
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      action: 'fetchMissingTags',
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Compliance.Tags', 'MissingTags Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;
    apiURL = this.getURl();

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      if (result.tagList && result.tagList.length != 0) {
        let dt = result.tagDetailsList;
        dt = dt.map((instance: any) => {
          instance['missingTags'] = instance['tagsList'].join(', ');
          instance['selected'] = false;
          return instance;
        });
        this.instances = dt;
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  changeTags(event: any) {
    this.selectedTags = $(event.target).val();
  }

  async submitTags() {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      tagsList: this.selectedTags,
      action: 'saveManidatoryTags',
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Compliance.Tags', 'ManidatoryTags Saved');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = this.getURl();

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      // Compliance.Tags, Tags Updated
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async fetchSubmittedTags() {
    this.notifier.loading(true);
    let data = {
      accountId: this.accountId,
      action: 'fetchManidatoryTags',
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Compliance.Tags', 'ManidatoryTag Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = this.getURl();

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      // Compliance.Tags, Tags Fetched
      this.selectedTags = result.tagList;
      $('#tagListID').selectpicker('val', this.selectedTags);
      setTimeout(() => {
        $('#tagListID').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async load() {
    this.notifier.loading(true);
    let data = {
      action: 'listEc2Tags',
      accountId: this.accountId,
      region: this.regionId,
      nextToken: '',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Compliance.Tags', 'Ec2Tags Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.tagList = result.tagKeyList;
      setTimeout(() => {
        $('#tagListID').selectpicker('refresh');
      }, 100);
      await this.fetchSubmittedTags();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
