import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import { ASTWithSource } from '@angular/compiler';

@Component({
  selector: 'instacne-start-stop-v2',
  styleUrls: ['./startstop.ec2.component.css'],
  templateUrl: './startstop.ec2.component.html',
})
export class StartStopv2InstanceComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  regionId: any;
  otpEmailID: string = this.userId;
  otpText: string;
  currentMessage: any;
  validateOTP: boolean = null;
  @Input('instances') instancesList: any;
  @Input('modalTitle') modalTitle: any;
  @Input('command') command: any;
  @Input('action') action: any;
  @Output() hideModal = new EventEmitter<any>();
  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    if (!this.writeAccess) {
      this.close();
    }
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
    });
  }

  requestId: string;
  async sendOTP() {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    let data = {
      action: this.modalTitle + '_ec2_otp_generate',
      resource_ids: this.instancesList.map((instance: any) => {
        return instance.ResourceId;
      }),
      account_id: this.accountId,
      region_name: this.regionId,
      login_user_name: localStorage.getItem('un'),
      login_user_email: this.otpEmailID,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.validateOTP = true;
      this.requestId = result.request_id;
      this.notifier.alert('Success', '', result.message, 'success', 5000);
    } else {
      this.validateOTP = false;
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async instanceTriggerAction() {
    if (!this.writeAccess) {
      return;
    }
    this.notifier.loading(true);
    let data = {
      action: this.modalTitle + '_ec2_otp_validate',
      resource_ids: this.instancesList.map((instance: any) => {
        return instance.ResourceId;
      }),
      account_id: this.accountId,
      region_name: this.regionId,
      login_user_name: localStorage.getItem('un'),
      login_user_email: this.otpEmailID,
      request_id: this.requestId,
      otp: this.otpText,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.message, 'success', 5000);
      this.close(true);
    } else {
      this.notifier.alert('Info', '', result.message, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  close(value: boolean = false): void {
    this.hideModal.emit(value);
  }
}
