<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">Scan</div>
    <div class="KBBody">
      <div class="col-mb-6">
        <label>Account ID: </label>
        <span>{{accountId}}</span>
      </div>
      <div class="col-mb-6">
        <label>Region: </label>
        <span>{{regionId}}</span>
      </div>
      <div class="KBActions">
        <button class="btn btn-success-soft" style="float: right; margin: 0 10px" (click)="yes()">
          <i class="fa fa-search" aria-hidden="true"></i>
          Scan
        </button>
        <button class="btn btn-light" (click)="no()">Cancel</button>
      </div>
    </div>
  </div>