<h1 class="card-title">Other Cost Controls</h1>
<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button style="position: relative;" [ngStyle]="{'padding-left': unusedEBSLoading ? '60px' : ''}"
      class="active nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#unused-ebs" type="button"
      role="tab" aria-controls="unused-ebs" aria-selected="true">
      <img *ngIf="unusedEBSLoading" src="../assets/img/loading.svg"
        style="width: 70px; height: 70px; position: absolute; top: 50%; left: 0; transform: translateY(-50%);"
        alt="" />{{ unusedEBSList.length }} Unused EBS
    </button>
    <button style="position: relative;" [ngStyle]="{'padding-left': unusedElasticIPsLoading ? '60px' : ''}"
      class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#unused-elastic-ips" type="button"
      role="tab" aria-controls="unused-elastic-ips" aria-selected="true">
      <img *ngIf="unusedElasticIPsLoading" src="../assets/img/loading.svg"
        style="width: 70px; height: 70px; position: absolute; top: 50%; left: 0; transform: translateY(-50%);"
        alt="" />{{ unusedElasticIPsList.length }} Unused Elastic
      IPs
    </button>
    <button style="position: relative;" [ngStyle]="{'padding-left': stoppedEC2Loading ? '60px' : ''}" class="nav-link"
      id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#stopped-ec2" type="button" role="tab"
      aria-controls="stopped-ec2" aria-selected="true">
      <img *ngIf="stoppedEC2Loading" src="../assets/img/loading.svg"
        style="width: 70px; height: 70px; position: absolute; top: 50%; left: 0; transform: translateY(-50%);"
        alt="" />{{ stoppedEC2List.length }} Stopped EC2
    </button>
  </div>
</nav>
<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane active" id="unused-ebs" role="tabpanel" aria-labelledby="unused-ebs-tab">
    <div class="card">
      <div class="card-body">

        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of unusedEBSHeaders">
                {{ h.name }}
                <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h, unusedEBSList)">
                  <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                  <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                </span>

                <span *ngIf="h.filter" class="inputseach">
                  <input type="text" [(ngModel)]="h.filterText" />
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </th>
            </tr>
          </thead>

          <tbody [ngStyle]="{
              display: unusedEBSList.length > 0 ? 'table-row-group' : 'none'
            }" *ngFor="let i of unusedEBSList; let j = index">
            <tr *ngIf="notifier.filterTableColums(unusedEBSHeaders, i)">
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of unusedEBSHeaders">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{ i[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ i[h.id] }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody [ngStyle]="{
              display: unusedEBSList.length == 0 ? 'table-row-group' : 'none'
            }">
            <tr>
              <td [attr.colspan]="unusedEBSHeaders.length + 1">
                <div style="text-align: center" *ngIf="!unusedEBSLoading">
                  No Data Found
                </div>
                <div style="text-align: center" *ngIf="unusedEBSLoading">
                  <img *ngIf="unusedEBSLoading" src="../assets/img/loading.svg"
                    style="margin-right: 10px; width: 70px; height: 70px;" alt="" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="unused-elastic-ips" role="tabpanel" aria-labelledby="unused-elastic-ips-tab">
    <div class="card">
      <div class="card-body">

        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of unusedElasticIPs">
                {{ h.name }}
                <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h, unusedElasticIPsList)">
                  <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                  <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                </span>

                <span *ngIf="h.filter" class="inputseach">
                  <input type="text" [(ngModel)]="h.filterText" />
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </th>
            </tr>
          </thead>

          <tbody [ngStyle]="{
              display:
                unusedElasticIPsList.length > 0 ? 'table-row-group' : 'none'
            }" *ngFor="let i of unusedElasticIPsList; let j = index">
            <tr *ngIf="notifier.filterTableColums(unusedElasticIPs, i)">
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of unusedElasticIPs">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{ i[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ i[h.id] }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody [ngStyle]="{
              display:
                unusedElasticIPsList.length == 0 ? 'table-row-group' : 'none'
            }">
            <tr>
              <td [attr.colspan]="unusedElasticIPs.length + 1">
                <div style="text-align: center" *ngIf="!unusedElasticIPsLoading">
                  No Data Found
                </div>
                <div style="text-align: center" *ngIf="unusedElasticIPsLoading">
                  <img src="../assets/img/loading.svg" style="margin-right: 10px; width: 70px; height: 70px;" alt="" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="stopped-ec2" role="tabpanel" aria-labelledby="stopped-ec2-tab">
    <div class="card">
      <div class="card-body">
        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of stoppedEC2Headers">
                {{ h.name }}
                <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h, stoppedEC2List)">
                  <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                  <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                </span>

                <span *ngIf="h.filter" class="inputseach">
                  <input type="text" [(ngModel)]="h.filterText" />
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </th>
              <th>Volume
              </th>
            </tr>
          </thead>

          <tbody [ngStyle]="{
              display: stoppedEC2List.length > 0 ? 'table-row-group' : 'none'
            }" *ngFor="let i of stoppedEC2List; let j = index">
            <tr *ngIf="notifier.filterTableColums(stoppedEC2Headers, i)">
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of stoppedEC2Headers">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{ i[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ i[h.id] }}
                </span>
              </td>
              <td>
                <span class="btn" (click)="getVolume(i)">Get Volume</span>
              </td>
            </tr>
          </tbody>
          <tbody [ngStyle]="{
              display: stoppedEC2List.length == 0 ? 'table-row-group' : 'none'
            }">
            <tr>
              <td [attr.colspan]="stoppedEC2Headers.length + 2">
                <div style="text-align: center" *ngIf="!stoppedEC2Loading">
                  No Data Found
                </div>
                <div style="text-align: center" *ngIf="stoppedEC2Loading">
                  <img *ngIf="stoppedEC2Loading" src="../assets/img/loading.svg"
                    style="margin-right: 10px; width: 70px; height: 70px;" alt="" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ec2-details-view *ngIf="instance" [tabs]="['storage']" [instance]="instance" (hideModal)="hideDetails($event)">
</ec2-details-view>