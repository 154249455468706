<div class="confirmContainer">
  <div class="confirmBox">
    <div class="confirmContent">
      <div class="confirmBoxHeader">
        <div class="confirmLogo">
          <div class="excla alert">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <div class="confirmHeaderText">Resolve Ticket  ??</div>
          </div>
        </div>
      </div>
      <div class="confirmBoxBody">
        <div>Are you sure you want to resolve the ticket?</div>
      </div>
      <div class="confirmBoxActions">
        <button class="btn btn-success" (click)="yes()">Resolve</button>
        <button class="btn btn-light" (click)="no()">Cancel</button>
      </div>
    </div>
  </div>
</div>
