import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

import * as moment from 'moment';

declare let flatpickr: any;
declare let window: any;
declare let $: any;

declare let Jhxlsx: any;
@Component({
  // selector: 'app-cfrcreport',
  templateUrl: './cfrcreport.component.html',
  styleUrls: ['./cfrcreport.component.css']
})


export class CfrcreportComponent implements OnInit,OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
    cfrcData: any=[]

  accountId: any;
  regionId: any;
  currentMessage: any;
  dateRange: any;
  headers: any = [
    {
      id: 'masterId',
      name: 'Master Account ID',
    },
    {
      id: 'usageAmount',
      name: 'Total Usage',
    },
  ];


  constructor(
    private notifier: NotifierService,
    private apiService: APIService,
    private zone: NgZone
  ) {}

  
  ngOnInit(): void {
    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.load(),
      component: this,
    };
    let temp = moment(new Date());
    this.dateRange = temp.format('MM/YYYY');

    $('#monthDate')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm/yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });
      this.load();
      // this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      //   let d = JSON.parse(msg);
      //   if (d.value == null) {
      //     return;
      //   }
      //   if (d.key == 'accountId') {
      //     this.accountId = d.value;
      //     this.load();
      //   } else if (d.key == 'regionId') {
      //     this.regionId = d.value;
      //     this.load();
      //   }
      // });

  }
  async load() {
    this.notifier.loading(true);
    let dRange: any = $('#monthDate').val() || this.dateRange;
    let sData = moment(dRange, 'MM/YYYY').format('MM-YYYY');
    let data = {
      "a":"fetchcfrc",
      monthYear: sData
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/inventory`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {

      this.cfrcData = result.cfrc.map((cf:any) => {
        cf.usageAmount=cf.usageAmount.toFixed(4);
        return cf;
     
      });
      // this.c
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }
  export() {
    let dRange: any = $('#monthDate').val() || this.dateRange;
      let sData = moment(dRange, 'MM/YYYY').format('MM-YYYY');
    // this.showConditionalElement = true;
    let data: any = [
      {
        sheetName: `CFRC Report ${sData}`,
        data: [
          (this.headers).map(
            (h: any) => {
              return { text: h.name };
            }
          ),
        ],
      },
    ];

    let sheetData: any =  this.cfrcData;
    // let headers: this.headers;

    sheetData.map((item: any) => {
      let data_: any = [];
      this.headers.forEach((h: any) => {
        data_.push({ text: h.alter ? h.alter(item, h.id) : item[h.id] });
      });

      data[0]['data'].push(data_);
    });

    var options = {
      fileName: `CFRC_report_${sData}`
    };

    Jhxlsx.export(data, options);
  }

  // export() {
  //   let dRange: any = $('#monthDate').val() || this.dateRange;
  //   let sData = moment(dRange, 'MM/YYYY').format('MM-YYYY');
  //   // exportToExcel("viewTicketTable","ticketlist", "xlsx");
  //   window.exportToExcel(
  //     'cfrc-report-table',
  //     `cfrc-report-${sData}`,
  //     'xlsx'
  //   );
  // }
  ngOnDestroy(): void {
    // this.currentMessage.unsubscribe(); 
  }

}
