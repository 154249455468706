import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as moment from 'moment';

declare let window: any;
declare let $: any;

declare let flatpickr: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './mrr.component.html',
  styleUrls: ['./mrr.component.css'],
})
export class MRRDiscountComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  selectedClientName: any;
  // = localStorage.getItem('selectedClientName');
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  delete: boolean = false;
  mrrData: any = [];
  accountId: any;
  currentMessage: any;
  updateMRR: any;
  updatemrrsub: any;
  period: any;
  updateAction: any = 'update';
  unsavedMRR: any = [];
  unsavedUpdateId: number = -1;
  unselected: any = [];
  deleteMRR: any;
  unselectedServiceString: string;
  dateRange: any;
  headers: any = [
    {
      id: 'serviceName',
      name: 'Service Name',
    },
    {
      id: 'mrrDiscount',
      name: 'MRR Discount',
    },
    {
      id: 'comment',
      name: 'Comment',
    },
  ];

  funRef: any = {};
  list: any;
  showPopup: boolean;
  popupData: any = [];
  mid: any;
  masterAccounts: any;
  date: string;
  headers_pop: { id: string; name: string }[];
  s3Mrr: { isMrr: boolean; perc: number };
  subRds: { instance: {}; others: {} };
  subEc2: {
    instance: {
      ri_sp: {
        isMrr: boolean;
        perc: number;
      };
      od: {
        isMrr: boolean;
        perc: number;
      };
      spot: {
        isMrr: boolean;
        perc: number;
      };
    };
    ebs: {
      isMrr: boolean;
      perc: number;
    };
    others: {
      isMrr: boolean;
      perc: number;
    };
  };
  cfrcMrr: { isMrr: boolean; perc: number };
  s3Keys: any[];
  cfrcKeys: any[];

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.funRef = {};
    // this.fetchMasterAccounts();
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.expandedRow = null;
        this.fetchMasterAccounts();
        this.load();
      }
    });
    flatpickr('#dataRangePicker', {
      dateFormat: 'Y-m-d',
      mode: 'range',
      showMonths: 2,
      defaultDate: this.dateRange ? this.dateRange.split(' to ') : null,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.dateRange = dateStr;
      },
    });
    this.date = moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('MM-YYYY');
  }
  mrrDatasub: any = [];
  subloading: any = false;

  setHeadersBasedOnService(serviceName: string) {
    // Define header configurations based on the service name
    if (serviceName === 'Amazon Elastic Compute Cloud') {
      this.headers_pop = [
        { id: 'instanceId', name: 'Instance ID' },

        { id: 'instanceName', name: 'Instance Name' },

        { id: 'instanceType', name: 'Instance Type' },
        { id: 'os', name: 'OS' },
        { id: 'region', name: 'Region' },
      ];
    } else if (serviceName === 'Amazon Relational Database Service') {
      this.headers_pop = [
        // { id: 'key', name: 'Key' },
        { id: 'region', name: 'Region' },
        { id: 'usageType', name: 'Usage Type' },
        { id: 'azType', name: 'Availability Zone Type' },
        { id: 'dbEngine', name: 'DB Engine' },
      ];
    } else if (serviceName === 'Amazon Simple Storage Service') {
      this.headers_pop = [
        // { id: 'key', name: 'Key' },
        { id: 'region', name: 'Region' },
        { id: 'usageType', name: 'Usage Type' },
      ];
    } else if (serviceName === 'Amazon CloudFront') {
      this.headers_pop = [
        // { id: 'key', name: 'Key' },
        { id: 'region', name: 'Region' },
        { id: 'usageType', name: 'Usage Type' },
      ];
    } else {
      // Default headers if the service name does not match
      this.headers_pop = [
        { id: 'serviceName', name: 'Service Name' },
        { id: 'mrrDiscount', name: 'MRR Discount' },
        { id: 'comment', name: 'Comment' },
      ];
    }
  }

  async loadsub(serviceName: any) {
    let data;
    this.notifier.loading(true);
    this.subloading = true;
    if (this.selectedClientName === 'NHA') {
      data = {
        a: 'fetchEc2Mrr',
        lid: this.accountId,
      };
    } else {
      data = {
        a: 'fetchNonNhaMrr',
        lid: this.accountId,
        serviceName: serviceName,
      };
    }
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/mrr`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.s == '1') {
      if (this.selectedClientName == 'NHA') {
        this.mrrDatasub = result.sub;
      } else {
        this.mrrDatasub = result;
        console.log('result', result.data);
        console.log('mrrdatasub', this.mrrDatasub);
      }
    } else {
      this.mrrDatasub = [];
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    console.log('merrdatasub', this.mrrDatasub);
    this.subloading = false;
    this.notifier.loading(false);
  }

  async load() {
    // console.log('fetching data');
    this.notifier.loading(true);
    let data = { a: 'fetch', lid: this.accountId };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/mrr`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.s == '1') {
      this.mrrData = result.data;
      console.log('mrrdata', this.mrrData.length);

      if (this.mrrData.length > 0) {
        this.period =
          this.mrrData[0].startDate + ' to ' + this.mrrData[0].endDate;
      }
    } else {
      this.mrrData = [];
      this.period = '-';
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    if (this.mrrData.length == 0) {
      this.period = '-';
    }
    this.notifier.loading(false);
  }

  lek: any;
  service_Name: any;
  // async openPopup(serviceName:any) {
  //   this.setHeadersBasedOnService(serviceName);
  //   this.notifier.loading(true);
  //   this.popupData = [];
  //   this.service_Name = serviceName;
  //   this.fetchDetails(serviceName);
  //   this.showPopup = true;     // Set a flag to show the popup
  //   this.notifier.loading(false);
  // }

  // // Method to close the popup
  // closePopup() {
  //   this.notifier.loading(true);
  //   this.service_Name = undefined;
  //   this.showPopup = false;
  //   this.popupData = [];
  //   this.notifier.loading(false);
  //   this.lek = undefined;
  // }
  async fetchDetails(serviceName: any) {
    this.notifier.loading(true);
    const serviceMapping: { [key: string]: string } = {
      'Amazon Simple Storage Service': 's3',
      'Amazon Relational Database Service': 'rds',
      'Amazon CloudFront': 'cfrc',
      'Amazon Elastic Compute Cloud': 'ec2',
    };

    // Get the abbreviated service name from the mapping
    const abbreviatedServiceName = serviceMapping[serviceName] || serviceName;
    let data;
    if (this.lek === null || this.lek === undefined || this.lek === '') {
      data = {
        month: this.date,
        childs: [this.accountId],
        mid: this.mid,
        a: abbreviatedServiceName,
      };
    } else {
      data = {
        month: this.date,
        childs: [this.accountId],
        mid: this.mid,
        a: abbreviatedServiceName,
        lek: this.lek,
      };
    }

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/mrr`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1') {
      console.log('result lek', result.lek);
      if (
        result.lek === null ||
        result.lek === undefined ||
        result.lek === ''
      ) {
        this.popupData = [...this.popupData, ...result.riDetails];
        this.lek = result.lek;
      } else {
        this.lek = result.lek;
        this.popupData = [...this.popupData, ...result.riDetails];
        // this.fetchDetails(serviceName);
      }
      console.log('popupdata', this.popupData);
    }
    this.notifier.loading(false);
  }

  async fetchMasterAccounts() {
    this.masterAccounts = [];
    this.notifier.loading(true);
    let data = {
      lid: this.accountId,
      userType: 'C',
      action: 'fetchMasterAccList',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    // let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;
 let apiURL = `https://api.swayam.cloud/v3/admin/billing/ec2breakup`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = result.masterAccList;
      this.mid = this.masterAccounts[0];
      //  if(this.mid == 440591205624) {
      //  }

      this.notifier.loading(false);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.notifier.loading(false);
    }
    this.selectedClientName = localStorage.getItem('selectedClientName');

    console.log('selectedclient', this.selectedClientName);
  }

  async updateTrigger(id: any) {
    this.expandedRow = null;
    if (
      id.serviceName === 'Amazon Elastic Compute Cloud' &&
      this.selectedClientName === 'NHA'
    ) {
      await this.loadsub(id.serviceName);
      this.updatemrrsub = this.mrrDatasub;
    } else if (
      (id.serviceName === 'Amazon Elastic Compute Cloud' ||
        id.serviceName === 'Amazon Relational Database Service' ||
        id.serviceName == 'Amazon CloudFront' ||
        id.serviceName == 'Amazon Simple Storage Service') &&
      this.selectedClientName !== 'NHA'
    ) {
      await this.loadsub(id.serviceName);
      this.updatemrrsub = this.mrrDatasub;
    }
    this.updateAction = 'update';
    this.updateMRR = id;
  }
  indexdelete: any;
  confirmdeletion(i: any) {
    this.delete = true;
    this.indexdelete = i;
  }

  deleteUnsavedMRR(id: number) {
    let removed = this.unsavedMRR.splice(id, 1)[0].serviceName;
    this.unselected.push(removed);
    this.unselectedServiceString = this.unselected.join(', ');
    // console.log('data deleted', JSON.stringify(this.unselectedServiceString));
  }

  updateUnsavedMRR(id: any, index: number) {
    this.updateAction = 'add';
    this.unsavedUpdateId = index;
    this.updateMRR = id;
  }

  closeUpdateTrigger(event: any) {
    if (event) {
      // if (this.updateAction == 'update') {
      this.load();
      //   } else {
      //     this.unselected = event.unselected;
      //     this.unselectedServiceString = this.unselected.join(', ');
      //     if (this.unsavedUpdateId > -1) {
      //       this.unsavedMRR[this.unsavedUpdateId] = event.mrrList[0];
      //       this.unsavedUpdateId = -1;
      //     } else {
      //       // this.unsavedMRR.push(event.mrrList);
      //       this.unsavedMRR = [...this.unsavedMRR, ...event.mrrList];
      //     }
      //   }
    }
    this.updateMRR = null;
  }
  async deleteTrigger(id: any) {
    this.notifier.loading(true);
    let data: any = {
      lid: this.accountId,
      a: 'delete',
      serviceName: id.serviceName,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/mrr`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.load();
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.delete = false;
    this.notifier.loading(false);
  }

  expandedRow: number | null = null; // Track which row is expanded
  exp_row: any;
  async toggleExpandRow(index: number) {
    let heders_exp;

    // Check if the row is being expanded (not already expanded)
    if (this.expandedRow !== index) {
      // Only load the sub data when expanding the row
      const serviceName = this.mrrData[index].serviceName;
      this.exp_row = serviceName;
      await this.loadsub(serviceName);
      this.expandedRow = index; // Expand the row
    } else {
      // If the row is already expanded, collapse it
      this.expandedRow = null;
    }
  }

  closeDeleteTrigger(event: any) {
    this.deleteMRR = null;
  }

  addMrr() {
    this.updateAction = 'add';
    this.unsavedUpdateId = -1;

    // Initialize updateMRR
    this.updateMRR = {
      serviceName: '',
      mrrDiscount: 0,
      comment: '',
      serviceType: '',
    };

    // Initialize updatemrrsub
    this.updatemrrsub = {
      instance: 0,
      ebs: 0,
      others: 0,
    };

    // Initialize subEc2
    this.subEc2 = {
      instance: {
        ri_sp: {
          isMrr: false,
          perc: 0,
        },
        od: {
          isMrr: false,
          perc: 0,
        },
        spot: {
          isMrr: false,
          perc: 0,
        },
      },
      ebs: {
        isMrr: false,
        perc: 0,
      },
      others: {
        isMrr: false,
        perc: 0,
      },
    };

    // Initialize subRds
    this.subRds = {
      instance: {
        ri_sp: {
          isMrr: false,
          perc: 0,
        },
        od: {
          isMrr: false,
          perc: 0,
        },
      },
      others: {
        isMrr: false,
        perc: 0,
      },
    };

    // Initialize s3Mrr and cfrcMrr
    this.s3Mrr = {
      isMrr: false,
      perc: 0,
    };

    this.cfrcMrr = {
      isMrr: false,
      perc: 0,
    };
  }

  async saveDetails() {
    // console.log('date', this.dateRange);
    if (this.dateRange == '' || this.dateRange == undefined) {
      this.notifier.alert('Info', '', 'Please select date range', 'info', 500);
      return;
    }
    const start = this.dateRange.split('to')[0].trim();
    const end = this.dateRange.split('to')[1].trim();
    const dates = {
      startDate: start,
      endDate: end,
    };

    this.unsavedMRR = this.unsavedMRR.map((service: any) => ({
      ...service,
      ...dates,
    }));
    // console.log('this.unsavedMRR', this.unsavedMRR);
    this.notifier.loading(true);
    let data: any = {
      a: 'add',
      lid: this.accountId,
      mrrList: this.unsavedMRR,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/mrr`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.unsavedMRR = [];
      this.load();
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  callFunction(param: any, id: any) {
    this.funRef[param](this, id);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
