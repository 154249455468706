import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

declare let humanizeDuration: any;

@Component({
  selector: 'rule-backup',
  templateUrl: './rule.backup.component.html',
  styleUrls: ['./../../backupv2.component.css'],
})
export class RuleBackupv2Component implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  updateRule: any = null;
  deleteRule: any = null;
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';

  funRef: any;

  @Input('rule') rule: any;
  @Output('close') closeEmitter = new EventEmitter<boolean>();

  accountId: any;
  regionId: any;
  currentMessage: any;
  notifications: any = [];

  rulesHeaders: any = [
    {
      id: 'key',
      name: 'Key',
    },
    {
      id: 'value',
      name: 'Value',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.funRef = {};

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  triggerUpdateRule() {
    this.updateRule = { ...this.rule };
  }

  parseDate(value: string) {
    return moment(value).format('DD/MM/YYYY hh:mm A');
  }

  minsToHumanReadable(value: any) {
    let milli = Number(value) * 1000 * 60;
    let human = humanizeDuration(milli);
    value = moment.duration(value, 'minutes');
    if (human.indexOf('year') > -1 || human.indexOf('years') > -1) {
      return value.years() > 1
        ? `${value.years()} Years`
        : `${value.years()} Year`;
    }
    if (human.indexOf('month') > -1 || human.indexOf('months') > -1) {
      return value.months() > 1
        ? `${value.months()} Months`
        : `${value.months()} Month`;
    }
    if (human.indexOf('day') > -1 || human.indexOf('days') > -1) {
      return value.days() > 1 ? `${value.days()} Days` : `${value.days()} Day`;
    }
    if (human.indexOf('hour') > -1 || human.indexOf('hours') > -1) {
      return value.hours() > 1
        ? `${value.hours()} Hours`
        : `${value.hours()} Hour`;
    }

    return value > 1 ? `${value.minutes()} Minutes` : 'Minute';
  }

  // minsToHumanReadable(value: any) {
  //   let min: any = Number(value);
  //   return humanizeDuration(min * 60000);
  // }

  parseResources() {
    return this.rule.tag_key_value_pairs.map((resource: string) => {
      let resourceObj = resource.split(':');
      return { key: resourceObj[0], value: resourceObj[1] };
    });
  }

  getDiff(last: any, frequency: any, status: boolean) {
    return status
      ? moment(last).add(frequency, 'minutes').format('DD/MM/YYYY hh:mm A')
      : 'Disabled';
  }

  async load() {
    // await this.loadRules();
  }

  updateCheck(rule: any) {
    if (rule) {
      this.rule = rule;
    }
    this.updateRule = null;
  }

  deleteCheck(check: any) {
    if (check) {
      this.close(true);
    }
  }

  close(check: any = false) {
    this.closeEmitter.emit(check);
  }

  callFunction(value: any, param: any) {
    this.funRef[value](this, param);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
