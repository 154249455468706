<h5 class="card-title">EBS</h5>
<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button"
      role="tab" aria-controls="nav-home" aria-selected="true">Non - Encrypted Volumes</button>
    <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button"
      role="tab" aria-controls="nav-profile" aria-selected="false">Encrypted Volumes</button>
  </div>
</nav>

<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
    <div class="card" style="border-top: none !important;">
      <!--<h5 class="card-header"></h5>-->

      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->

        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of headers">
                {{h.name}}
                <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,ebsNonEncryptedList)">

                  <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                  <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                </span>

                <span *ngIf="h.filter " class="inputseach">
                  <input type="text" [(ngModel)]="h.filterText" />
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </th>
            </tr>
          </thead>

          <tbody [ngStyle]="{'display': ebsNonEncryptedList.length > 0 ? 'table-row-group': 'none'}"
            *ngFor="let i of ebsNonEncryptedList; let j = index">
            <tr *ngIf="notifier.filterTableColums(headers,i)">
              <td>{{j+1}}</td>
              <td *ngFor="let h of headers">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{i[h.id]}}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{i[h.id]}}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody [ngStyle]="{'display': ebsNonEncryptedList.length == 0 ? 'table-row-group' : 'none'}">
            <tr>
              <td [attr.colspan]="headers.length + 1">
                <div style="text-align: center;">No Data Found</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
    <div class="card" style="border-top: none !important;">
      <!--<h5 class="card-header"></h5>-->
      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->

        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of headers">
                {{h.name}}
                <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,ebsEncryptedList)">

                  <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                  <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                </span>

                <span *ngIf="h.filter " class="inputseach">
                  <input type="text" [(ngModel)]="h.filterText" />
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </th>
            </tr>
          </thead>

          <tbody [ngStyle]="{'display': ebsEncryptedList.length > 0 ? 'table-row-group': 'none'}"
            *ngFor="let i of ebsEncryptedList; let j = index">
            <tr *ngIf="notifier.filterTableColums(headers,i)">
              <td>{{j+1}}</td>
              <td *ngFor="let h of headers">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{i[h.id]}}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{i[h.id]}}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody [ngStyle]="{'display': ebsEncryptedList.length == 0 ? 'table-row-group' : 'none'}">
            <tr>
              <td [attr.colspan]="headers.length + 1">
                <div style="text-align: center;">No Data Found</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>