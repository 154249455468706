import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from './../../../_services/notifier.service';

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './ec2costoptimization.component.html',
})
export class ec2CostOptimizationComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  ec2CostList: any = [];
  utilization: any = null;
  nextToken: any;

  funRef: any;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;

  headers: any = [
    {
      id: 'tagName',
      name: 'Tag Name',
      filter: true,
    },
    {
      id: 'instanceId',
      name: 'Instance ID',
      filter: true,
    },
    {
      id: 'instanceType',
      name: 'Instance Type',
      filter: true,
    },
    {
      id: 'instanceStatus',
      name: 'Instance Status',
    },
    {
      id: 'cpuUtilization',
      name: 'Last 3 Weeks Average CPU Utilization',
    },
    {
      id: 'recomRequired',
      name: 'Recommendation',
      alter: (value: boolean) => {
        return value ? 'Recommend to reduce Instance type' : '';
      },
    },
  ];

  modalHeader: any = [
    {
      id: 'cpuUtilisation',
      name: 'CPU Utilization',
    },
    {
      id: 'diskRead',
      name: 'Disk Read',
    },
    {
      id: 'memory',
      name: 'Memory',
    },
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      this.nextToken = null;
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }

      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    this.funRef = {};
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    if (!this.nextToken) {
      this.ec2CostList = [];
    }
    let data = {
      accountId: this.accountId,
      action: 'test',
      region: this.regionId,
      nextToken: this.nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ec2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.ec2CostList = [...this.ec2CostList, ...result.instanceDetails];
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.nextToken) {
      this.nextToken = result.nextToken;
    } else {
      this.nextToken = null;
    }

    this.notifier.loading(false);
  }

  async fetchMemoryDetails(value: any) {
    this.notifier.loading(true);
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { site_247_project_id } = selectedAccountData.data;
        let data = {
          zaaid: site_247_project_id,
          action: 'fetchCpuUtilisationDetails',
          instanceName: value.tagName,
        };
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        //https://api.swayam.cloud/v3/admin/support
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2cpudetails`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        console.log(result);

        if (result.status == '1' || result.s == '1') {
          this.utilization = [result.ec2UtilisationDetails];
        } else {
          this.notifier.alert('Info', '', result.error, 'info', 5000);
        }
      } else {
        this.notifier.alert(
          'Error',
          '',
          selectedAccountData.error,
          'error',
          5000
        );
      }
    } catch (err: any) {
      this.notifier.alert('Info', '', err, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  hidePop(event: any) {
    this.utilization = null;
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
