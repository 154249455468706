import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css', './../../../warquiz.component.css'],
})
export class CreateComponent implements OnInit {
  @Output() hideModal = new EventEmitter<any>();
  @Input() accountId: string;
  @Input() regionId: string;
  @Input() workload: any;
  uid = localStorage.getItem('t');
  t = localStorage.getItem('t');
  ut = localStorage.getItem('ut');
  eId = localStorage.getItem('eId');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  constructor(
    private notifier: NotifierService,
    private apiServer: APIService
  ) {}

  scan_name: string = '';
  loading: boolean = false;

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  async yes() {
    await this.createJob();
    return;
  }

  no(check: boolean = false) {
    this.hideModal.emit(check);
  }

  async createJob() {
    var format = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;

    if (format.test(this.scan_name)) {
      this.notifier.alert(
        'Error',
        '',
        'Special Characters are not allowed.',
        'error',
        5000
      );
      return;
    }
    if (this.scan_name == '') {
      this.notifier.alert('Info', '', 'Scan Name is required.', 'info', 5000);
      return;
    }
    this.loading = true;
    let data: any = {
      action: 'create_milestone',
      workload_id: this.workload['id'],
      account_id: this.accountId,
      region_id: this.regionId,
      milestone_name: this.scan_name,
      id_token: localStorage.getItem('idT'),
    };

    let headers: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string = `${environment.apiURL}/${this.urlPrefix}/war/operations`;

    let result = await this.apiServer.postDataPromis(apiURL, data, headers);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('success', '', result.message, 'success', 5000);
      this.no(true);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.loading = false;
  }
}
