import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

declare let $: any;
declare let window: any;
declare let Jhxlsx: any;

@Component({
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css'],
})
export class BudgetComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  masterAccounts: any = [];

  accountId: any;
  regionId: any;
  currentMessage: any;

  headers: any = [
    {
      id: 'lid',
      name: 'Linked Account ID',
      click: 'loadUpdateForm',
    },
    {
      id: 'accountName',
      name: 'Account Name',
    },
    {
      id: 'dailyVariance',
      name: 'Variance Report',
    },
    {
      id: 'budget',
      name: 'Budget',
    },
    {
      id: 'threshHold',
      name: 'ThreshHold (%)',
    },
  ];

  funRef: any = {};

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.funRef = {
      loadUpdateForm: this.loadUpdateForm,
    };
    $('#masterAccountSelect').selectpicker();

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    this.fetchingMasterAccounts = true;
    await this.fetchMasterAccounts();
    this.fetchingMasterAccounts = false;
  }

  async refresh() {
    this.fetchingLInkedAccountIDs = true;
    await this.fetchLinkedAccountIDs(this.selectedMasterAccount.accountId);
    this.fetchingLInkedAccountIDs = false;
  }

  exportBudgets() {
    var dict: any = {
      sheetName: 'Budgets',
      data: [],
    };
    let headers: any = [{ text: 'Sl. No.' }];
    this.headers.forEach((h: any) => {
      headers.push({ text: h['name'] });
    });
    headers.push({ text: 'Current Month Bill' });
    headers.push({ text: 'Email IDs' });
    dict.data.push(headers);
    this.linkedAccountIDs.forEach((lid: any, index: number) => {
      let data = [];
      data.push({ text: index + 1 });
      this.headers.forEach((h: any) => {
        data.push({ text: lid[h.id] });
      });
      data.push({ text: lid['current_month'] });
      data.push({ text: lid['eid'].join(', ') });
      dict.data.push(data);
    });
    var options = {
      fileName: `Budgets`,
    };
    Jhxlsx.export([dict], options);
  }

  fetchingLInkedAccountIDs: boolean = false;
  linkedAccountIDs: any = [];
  fetchingMasterAccounts: boolean = false;
  destroyed: boolean = false;
  selectedMasterAccount: any;
  async fetchMasterAccounts(nextToken: any = undefined) {
    if (!nextToken) {
      this.masterAccounts = [];
    }

    let data = {
      action: 'fetchMasterAccounts',
      lastEvaluatedKey: nextToken,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `https://rddbcejif4.execute-api.ap-south-1.amazonaws.com/dev/msopsusers/billing/fetchenabledmasteraccounts`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (this.destroyed) {
      return;
    }
    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = [...this.masterAccounts, ...result.masteracnts];
      if (!nextToken) {
        this.triggerFetchLinkedAccounts(this.masterAccounts[0]);
      }
      setTimeout(() => {
        $('#masterAccountSelect').selectpicker('refresh');
      }, 1);
    } else {
      this.masterAccounts = [];
      this.notifier.alert(
        'Error',
        '',
        result.error || result.msg,
        'error',
        5000
      );
    }

    if (result.lastEvaluatedKey) {
      await this.fetchMasterAccounts(result.lastEvaluatedKey);
    }
  }

  async triggerFetchLinkedAccounts(masterAccID?: any) {
    if (!masterAccID) {
      masterAccID = this.selectedMasterAccount;
    } else {
      this.selectedMasterAccount = masterAccID;
    }
    this.fetchingLInkedAccountIDs = true;
    await this.fetchLinkedAccountIDs(masterAccID.accountId);
    this.fetchingLInkedAccountIDs = false;
  }

  fetchBudget(lid_: any) {
    let dt = this.linkedAccountIDs.find((lid: any) => {
      return lid.lid == lid_;
    });
    if (dt) {
      this.updateBudget = {
        threshHold: dt['threshHold'],
        lid: lid_,
        budget: dt['budget'],
        dailyVariance: dt['dailyVariance'],
        action: 'save',
        eid: dt['eid'],
        mid: this.selectedMasterAccount,
      };
    }
  }

  async fetchLinkedAccountIDs(masterAccId: any, nextToken: any = undefined) {
    if (!nextToken) {
      this.linkedAccountIDs = [];
    }
    let data = {
      a: 'fetch',
      mid: masterAccId,
      nt: nextToken,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/budgetalarm`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (
      (result.status == '1' || result.s == '1') &&
      result.lids &&
      result.lids.length > 0
    ) {
      result['lids'] = result['lids'].map((lid: any) => {
        this.fetchMonthlyData(
          masterAccId,
          lid.lid,
          moment().format('MM-YYYY'),
          lid
        );
        lid['eid'] = lid['eid'].split(',');
        return lid;
      });
      this.linkedAccountIDs = [...this.linkedAccountIDs, ...result['lids']];
    } else {
      this.linkedAccountIDs = [];
    }

    if (result.nt) {
      await this.fetchLinkedAccountIDs(masterAccId, result.nt);
    }
  }

  updateBudget: any;
  updateBudgetIndex: number;
  deleteBudget: any;
  deleteBudgetIndex: number;
  loadUpdateForm(ref: any, value: any, index: number) {
    ref.updateBudget = value;
    ref.updateBudget['mid'] = ref.selectedMasterAccount;
    ref.updateBudget['action'] = 'update';
    ref.updateBudgetIndex = index;
  }

  updateUI(event: any) {
    if (event) {
      delete event['action'];
      this.refresh();
    }
    this.updateBudgetIndex = null;
    this.updateBudget = null;
  }

  deleteBudgetTrigger(value: any, index: number) {
    this.deleteBudget = value['lid'];
    this.deleteBudgetIndex = index;
  }

  updateDeleteBudgetUI(event: any) {
    if (event) {
      this.refresh();
    }
    this.deleteBudget = null;
  }

  addBudget() {
    this.updateBudget = {
      threshHold: '80',
      dailyVariance: 'enabled',
      lid: this.accountId,
      budget: '0',
      action: 'save',
      eid: [''],
      mid: this.selectedMasterAccount,
    };
  }

  getExistingBudgets() {
    return this.linkedAccountIDs.map((lid: any) => {
      return lid.lid;
    });
  }

  loading: any = {};
  async fetchMonthlyData(masterAccId: any, lid: any, monthyear: any, obj: any) {
    this.loading[lid] = true;
    let data = {
      masterAccId: masterAccId,
      month_year: monthyear,
      lid: [lid],
      userType: 'C',
      action: 'supportPlanDetails',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/getservicelevelbill`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      obj['current_month'] = result['finalTotalCost'].toFixed(2);
    } else {
      obj['current_month'] = 'No Bill Found';
    }

    this.loading[lid] = false;
  }

  callFunction(param: any, id: any, index: number) {
    this.funRef[param](this, id, index);
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
