<div class="alarm_screen alarm_screen_box">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 class="alarm_header_text">
        {{ action == "add" ? "Add" : "Update" }} PO Alert
      </h4>
    </div>
    <div class="alarm_body alarm_padding">
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Customer Name</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          placeholder="Enter Customer Name"
          [(ngModel)]="entry.customerName"
        />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Doc Number</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          placeholder="Enter Doc Number"
          [(ngModel)]="entry.docNumber"
        />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Start Date - End Date</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control datetimepicker"
          placeholder="Start Date - End Date"
          [(ngModel)]="entry.dateRanger"
        />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Emails</label>
        <div
          *ngFor="let email of entry['receivers']; let i = index"
          class="flex-start"
        >
          <input
            type="email"
            class="form-control"
            [(ngModel)]="email['email']"
          />
          <button class="btn btn-light">
            <i class="fa fa-minus" (click)="removeEmail(i)"></i>
          </button>
        </div>
        <button class="btn btn-light flex-start" (click)="addEmail()">
          <i class="fa fa-plus"></i>
          <span>Add</span>
        </button>
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1"
          >Documents
          <span class="nav-link d-inline" (click)="reset()">Reset</span></label
        >
        <div
          class="files_uploading_div"
        >
          <div
            class="file_update file"
            style="cursor: auto; position: relative"
            *ngFor="let file of files; let i = index"
          >
            <div class="flex-center">
              <i
                class="fa fa-file"
                aria-hidden="true"
                style="color: rgb(118, 118, 221)"
              ></i>
              <div class="flex-start">
                <div class="attachment">
                  <span class="file_name">{{ file["name"] }}</span>
                  <ng-container *ngIf="file['type'] == 'new'">
                    <span class="file_size" *ngIf="file['status'] == 'uploading'">
                      {{ file["loaded"] }} / {{ file["total"] }}
                    </span>
                    <span class="file_size" *ngIf="file['status'] != 'uploading'">
                      {{ humanFileSize(file["file"]['size'], true) }}
                    </span>
                  </ng-container>
                </div>
                <div
                  class="progress_bar_document"
                  *ngIf="file['status'] == 'uploading'"
                  style="
                    background-color: rgb(194 217 254);
                    height: 4px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                  "
                >
                  <div
                    class="upload_progress"
                    [ngStyle]="{ width: file['percentage'] + '%'}"
                    style="
                      transition: 0.2s;
                      width: 0%;
                      height: 4px;
                      background-color: rgb(118, 118, 221);
                    "
                  ></div>
                </div>
              </div>
              <span *ngIf="file['status'] == 'uploaded'">
                <i
                  class="fa fa-check-circle"
                  style="color: rgb(118, 118, 221)"
                ></i>
              </span>

              <span
                class="remove_file_upload"
                (click)="removeFile(i)"
                [attr.disabled]="file['status'] != 'uploaded'"
                style="
                  cursor: pointer;
                  border-radius: 5px;
                  padding: 5px;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 5px;
                  color: grey;
                  font-size: 14px;
                  font-weight: bold;
                "
                >X</span
              >
            </div>
          </div>
        </div>
        <div *ngIf="files.length < 4" class="mt-2">
          <button class="btn btn-light flex-start" (click)="addFile()">
            <i class="fa fa-plus"></i>
            <span>Attach Docs</span>
          </button>
        </div>
        <input
          autocomplete="off"
          type="file"
          style="display: none"
          (change)="filesChange($event)"
          #documents
          multiple
          class="form-control"
        />
      </div>
    </div>
    <div class="alarm_actions alarm_footer">
      <div class="alarm_div">
        <button class="btn alarm_btn" (click)="updateEntry()">
          {{ action == "add" ? "Create" : "Update" }}
        </button>
        <button class="btn btn-light" (click)="hideModel.emit(false)">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
