import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

import * as moment from 'moment';

declare let flatpickr: any;
declare let window: any;
declare let $: any;

declare let Jhxlsx: any;
@Component({
//   selector: 'app-ri-inventory',
  templateUrl: './ri-inventory.component.html',
  styleUrls: ['./ri-inventory.component.css']
})
export class RiInventoryComponent implements OnInit{

  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
    riInventory: any=[]

    funRef: any = {};
  accountId: any;
  regionId: any;
  currentMessage: any;
  dateRange: any;
  headers: any = [
    {
      id: 'masterId',
      name: 'Master Account ID',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'accountId',
      name: 'Child Account ID',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'accountName',
      name: 'Account Name',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'offeringType',
      name: 'Offering Type',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'subscriptionId',
      name: 'Subscription ID',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'service',
      name: 'Service',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'instanceFamily',
      name: 'Instance Family',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'instanceType',
      name: 'Instance Type',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'availabilityZone',
      name: 'Availability Zone',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' },
      hide:true
    },
    {
      id: 'platform',
      name: 'Platform',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'region',
      name: 'Region',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'instancesCount',
      name: 'Instance Count',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'scope',
      name: 'Scope',
      filter: true,
      type: 'string',
      hide:true,
      sort: { sort: true, direction: 'Asc' }    
    },
    {
      id: 'status',
      name: 'Status',
      filter: true,
      type: 'status',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'sizeFlexibility',
      name: 'Size Flexibility',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' },
      hide:true
    },
    {
      id: 'recurringFee',
      name: 'Recurring Fee',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'endDate',
      name: 'End Date',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    }, {
      id: 'startDate',
      name: 'Start Date',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    },
    {
      id: 'total',
      name: 'Total',
      filter: true,
      type: 'string',
      sort: { sort: true, direction: 'Asc' }
    }
  ];


  constructor(
    public notifier: NotifierService,
    private apiService: APIService,
    
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.load();
  }
  async load() {
    this.notifier.loading(true);
    let data = {
      "a":"fetchri"
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/v3/admin/billing/inventory`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {

      this.riInventory = result.ri.map((ri:any) => {
        
        ri.endDate=moment(ri.endDate).format(
          'DD/MM/YYYY hh:mm A'
        );
        ri.startDate=moment(ri.startDate).format( 'DD/MM/YYYY hh:mm A')

        ri.total=Number(ri.total).toFixed(4);
        return ri;
     
      });
      // this.c
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }
  export() {
    let data: any = [
      {
        sheetName: `Ri Inventory Report`,
        data: [
          (this.headers).map(
            (h: any) => {
              return { text: h.name };
            }
          ),
        ],
      },
    ];

    let sheetData: any =  this.riInventory;
    // let headers: this.headers;

    sheetData.map((item: any) => {
      let data_: any = [];
      this.headers.forEach((h: any) => {
        data_.push({ text: h.alter ? h.alter(item, h.id) : item[h.id] });
      });

      data[0]['data'].push(data_);
    });

    var options = {
      fileName: "RI_Inventory_Report"
    };

    Jhxlsx.export(data, options);
  }
  
  callFunction(param: any, value: any) {
    this.funRef[param](this, value);
  }



}
