<div class="alarm_screen">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4>{{ budgetRaw['action'] == 'save' ? 'Add' : 'Update' }} Alarm</h4>
    </div>
    <div class="alarm_body" *ngIf="!instanceSelectionModal">
      <div class="row" *ngIf="budgetRaw['action'] != 'update'">
        <label>OS</label>
        <span
          style="display: flex; justify-content: space-between; align-items: center; gap: 10px; padding-right: 0px;">
          {{ getSelectedInstances().length }} Instances Selected
          <span class="nav-link" style="cursor: pointer;" (click)="instanceSelectionModal = true">
            <i class="fa fa-pencil" aria-hidden="true"></i>
            Update
          </span>
          <img *ngIf="fetchingLinkedAccountIDs" src="../assets/img/loading_2.svg" alt="loading scans"
            style="width: 18px; margin: 10px" />
        </span>
      </div>
      <div class="row" *ngIf="budgetRaw['action'] == 'update'">
        <label>OS</label>
        <span style="font-weight: bold;">{{budgetRaw['os']}}</span>
      </div>
      <div class="row" *ngIf="budgetRaw['action'] == 'update'">
        <label>Instance Type</label>
        <span style="font-weight: bold;">{{budgetRaw['instanceType']}}</span>
      </div>
      <div class="row" *ngIf="budgetRaw['action'] == 'update'">
        <label>Instance Details</label>
        <span
          style="display: flex; font-weight: bold; justify-content: space-between; align-items: center; gap: 10px; padding-right: 0px;">
          <span>
            AWS Total Hours: {{ instances.length > 0 ? instances[0]['awsTotalHours'] : '-' }}
          </span>
          <span>
            Instance Count: {{ instances.length > 0 ? instances[0]['instancesCount'] : '-' }}
          </span>
          <img [ngStyle]="{display: fetchingLinkedAccountIDs ? 'block' : 'none'}" src="../assets/img/loading_2.svg"
            alt="loading scans" style="width: 18px; margin: 10px" />
        </span>
      </div>
      <div class="row">
        <label>Hours</label>
        <input type="number" [(ngModel)]="budgetRaw.hours" name="" id="" class="form-control"
          pattern="[\+\-]?\d+(\.\d+)?" title="Only Numbers and dot(.)  is allowed" />
      </div>
      <div class="row">
        <label>Threshold (%)</label>
        <input type="number" min="0" max="100" maxlength="3" [(ngModel)]="budgetRaw.threshHold" name="" id=""
          class="form-control" />
      </div>
      <div class="row">
        <label>Email IDs</label>
        <div class="additional_contacts">
          <div class="additional_contact" style="width: auto" *ngFor="
                    let contact of budgetRaw.eid;
                    let i = index;
                    trackBy: trackByFn
                  ">
            <input type="text" name="contact_value" class="form-control" *ngIf="budgetRaw.eid.length - 3 < i; i"
              (keyup)="checkForLast(contact, i)" [(ngModel)]="budgetRaw.eid[i]" />
            <input type="text" name="contact_value" class="form-control" *ngIf="budgetRaw.eid.length - 3 >= i"
              [(ngModel)]="budgetRaw.eid[i]" />
            <div>
              <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add" *ngIf="budgetRaw.eid.length == i + 1"
                (click)="addAdditionalContact()"></i>
            </div>
            <div>
              <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove" *ngIf="budgetRaw.eid.length > 1"
                (click)="deleteSelectedAdditionalContact(i)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alarm_body" *ngIf="instanceSelectionModal">
      <instances-selection [instances]="instances" [selection]="budgetRaw['action'] == 'save' ? 'multiple' : 'single'">
      </instances-selection>
    </div>
    <div class="alarm_actions" [ngStyle]="{display: !instanceSelectionModal ? 'block' : 'none'}">
      <div style="display: flex; justify-content: flex-end">
        <button class="btn" [attr.disabled]="updating ? true : null" [ngStyle]="{opacity: updating ? '0.6' : '1'}"
          (click)="updateBudget()" style="background-color: #5864ff; color: white">
          <img *ngIf="updating" src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px;" />
          {{ budgetRaw['action'] == 'update' ? 'Update' : 'Add' }}
        </button>
        <button class="btn btn-light" (click)="close(false)">Cancel</button>
      </div>
    </div>
    <div class="alarm_actions" *ngIf="instanceSelectionModal">
      <div style="display: flex; justify-content: flex-end">
        <button class="btn" (click)="instanceSelectionModal = false" style="background-color: #5864ff; color: white">
          Confirm
        </button>
        <button class="btn btn-light" (click)="close(false)">Cancel</button>
      </div>
    </div>
  </div>
</div>