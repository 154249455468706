<div class="card">
  <div class="card-header">
    <h5>
      Audit Trail
    </h5>
    <p class="header-desc">Audit trail enables you to track the actions that the end user performs. (Audit trail logs
      are maintained for 3 months before deleting permanently)</p>
  </div>
  <div class="card-body">
    <div class="audit_container">
      <div>
        <div>
          <div *ngFor="let date of getDates()" class="audit_date_container">
            <div class="date_text bold_text">
              {{date}}
            </div>
            <div class="audit_for_date_container">
              <div class="audit_for_date" *ngFor="let audit of auditList[date]">
                <div class="bold_text floating_sticky">{{audit['action_time']}}</div>
                <div class="audit_dt" (click)="loadMoreData($event)"
                  [ngStyle]="{'border-left': '5px solid ' + colors[audit['action']]}">
                  <div class="no_click">
                    <div class="audit_row">
                      <p class="bold_text">Action</p>
                      <p class="capitalize_text">{{audit['action']}}</p>
                    </div>
                    <div class="audit_row">
                      <p class="bold_text">Action By</p>
                      <p class="capitalize_text">{{audit['action_by']}}</p>
                    </div>
                    <div class="more_info_container">
                      <div class="more_info_container_main">
                        <div class="audit_row">
                          <p class="bold_text">Resource ID</p>
                          <p class="capitalize_text">{{audit['resource_id']}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="more_data_info">
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="fetching">
          <div style="padding: 15px; display: flex; justify-content: center; align-items: center;">
            <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" style="width: 20px; margin: 10px;">
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 100%;">
        <div class="filters_section">
          <div class="filters_header">
            Filters
          </div>
          <div class="filters_body">
            <div id="filters_form" class="filters_form" name="filters_form">
              <div class="form-group">
                <label>Action</label>
                <select class="form-select" [(ngModel)]="selectedAction" (change)="reset()">
                  <option value="all" selected>All</option>
                  <option [value]="action" *ngFor="let action of actions">{{action}}</option>
                </select>
              </div>
              <!-- <div class="form-group">
                <label>Action By</label>
                <select class="form-select" [(ngModel)]="selectedUser">
                  <option value="all" selected>All</option>
                  <option [value]="user.userId" class="capitalize_text" *ngFor="let user of users">
                    {{user.userName}}</option>
                </select>
              </div> -->
              <div class="form-group">
                <label>Date</label>
                <div style="display: grid; grid-template-columns: repeat(2, 1fr); margin-top: 4px;">
                  <div>
                    <div style="display: flex; align-items: center; gap: 10px;">
                      <input type="radio" name="date_range" value="date" id="date_select"
                        (change)="dataFilter == 'date' && reset()" [(ngModel)]="dataFilter" style="width: auto;">
                      <label for="date_select" style="width: auto; font-size: 1em;">Specific Date</label>
                    </div>
                    <div [ngStyle]="{display: dataFilter == 'date' ? 'block' : 'none'}">
                      <input type="text" id="date-selector" class="form-control">
                    </div>
                  </div>
                  <div>
                    <div style="display: flex; align-items: center; gap: 10px;">
                      <input type="radio" name="date_range" value="range" id="range_select"
                        (change)="dataFilter == 'range' && reset()" [(ngModel)]="dataFilter" style="width: auto;">
                      <label for="range_select" style="width: auto; font-size: 1em;">Specific Range</label>
                    </div>
                    <div [ngStyle]="{display: dataFilter == 'range' ? 'block' : 'none'}">
                      <input type="text" id="date-selector-range" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="display: flex; justify-content: flex-end; gap: 8px;">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="load()">Next</button>
    </div> -->
  </div>
</div>