import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import * as echarts from 'echarts';

declare let window: any;

@Component({
  templateUrl: './ec2.operations.component.html',
  styleUrls: ['./ec2.operations.component.css'],
})
export class ec2OperationsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  instancesList: any = [];
  showTagsModal: any = null;
  instance: any = null;
  command: string = null;
  modalTitle: any;
  headers: any = [
    {
      id: 'tagName',
      name: 'Tag Name',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
    {
      id: 'instanceType',
      name: 'Instance Type',
      sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
    { id: 'availabilityZone', name: 'Availability Zone' },
    {
      id: 'statusCheck',
      name: 'Status Check',
      color: (value: string) => (value != '2/2' ? 'red' : 'green'),
    },
    {
      id: 'ipdata',
      name: 'Private IP / Public IP',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'instanceStatus',
      name: 'Instance Status',
      statusCheck: true,
    },
  ];

  funRef: any;

  accountId: any;
  regionId: any;
  currentMessage: any;
  action: string;
  filterText = '';
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.funRef = {
      showData: this.showData,
    };
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      this.hideMdoal(true);
      this.hideDetails(true);
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async downloadConsolidatedReport() {
    this.notifier.loading(true);
    let data = {
      action: 'fetch',
      userId: this.userId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Operations.Ec2', 'Report Fetched');
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/infrareport`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '') {
      if (result.url) {
        window.open(result.url, '_blank');
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.msg || result.message || result.error_message || result.errorMsg,
        'info',
        5000
      );
    }

    this.notifier.loading(false);
  }

  instanceTypes: any = [];
  OSs: any = [];
  async load(nextToken?: any) {
    if (!nextToken) {
      this.instancesList = [];
      this.instanceTypes = [];
      this.OSs = [];
    }
    this.notifier.loading(true);
    let data = {
      action: 'testListInstancesWithPagination',
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.instancesList = [...this.instancesList, ...result.instanceDetails];
      let dt: any = [];
      this.instancesList.forEach((instance: any, index: number) => {
        let temp = this.instanceTypes.find((instance_: any) => {
          return instance_['name'] == instance['instanceType'];
        });
        if (!temp) {
          this.instanceTypes.push({ name: instance.instanceType, value: 1 });
        } else {
          temp['value'] += 1;
        }
        let temp1 = this.OSs.find((instance_: any) => {
          if (instance['platform']) {
            return instance_['name'] == instance['platform'];
          } else {
            return instance_['name'] == 'Others';
          }
        });
        if (!temp1) {
          this.OSs.push({ name: instance.platform || 'Others', value: 1 });
        } else {
          temp1['value'] += 1;
        }

        instance['actionStart'] = false;
        if (instance.instanceStatus == 'running') {
          dt.push(instance);
        }
        instance.ipdata =
          (instance.privateIp ? instance.privateIp.toString() : '') +
          (instance.publicIp ? '/' + instance.publicIp.toString() : '');
      });
      if (result.nextToken && result.nextToken != '') {
        await this.load(result.nextToken);
      }
      if (dt.length > 0) {
        await this.fetchInstanceAZ(dt);
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.loadStatusGraph();
    this.loadInstanceTypesGraph();
    this.loadPlatformGraph();

    this.notifier.loading(false);
  }

  async fetchInstanceAZ(instances: any, nextToken?: any) {
    let data = {
      action: 'fetchStatusCheckResult',
      instanceIdList: instances.map((instance: any) => {
        return instance.instanceId;
      }),
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1') {
      result.statusCheckDataList.forEach((instance: any) => {
        let instance_ = this.instancesList.find(
          (element: any) => element.instanceId == instance.instanceId
        );
        instance_ = { ...instance_, ...instance };
        this.instancesList[
          this.instancesList.findIndex(
            (element: any) => element.instanceId == instance.instanceId
          )
        ] = instance_;
      });
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.loadStatusChecksGraph();
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  showData(ref: any, value: any) {
    if (ref.instance) {
      ref.instance['actionStart'] = false;
    }

    value['actionStart'] = true;
    ref.instance = value;
  }

  showTags(data: any) {
    this.showTagsModal = data;
  }

  getStartTriggerInstances() {
    return this.instancesList
      ? this.instancesList.filter((instance: any) => {
          return instance.actionStart;
        })
      : [];
  }

  hideStartStop() {
    this.command = null;
  }

  singleStartStop(event: any, instance: any, trigger: any) {
    this.instancesList.forEach((instance: any) => {
      instance['actionStart'] = false;
    });
    instance.actionStart = true;
    this.triggerInstances(event, trigger);
  }

  triggerInstances(event: any, trigger: string) {
    event.preventDefault();
    if (!this.writeAccess) {
      return;
    }
    if (trigger == 'run') {
      this.modalTitle = 'start';
      this.action = 'start';
    } else if (trigger == 'stop') {
      this.action = 'stop';
      this.modalTitle = 'stop';
    }
    this.command = trigger;
  }

  hideMdoal(event: any) {
    this.showTagsModal = null;
  }

  instanceStartStop(event: any) {
    this.instancesList.forEach((instance: any) => {
      instance['actionStart'] = false;
    });
    this.instance['actionStart'] = true;
    if (event == 'start') {
      this.modalTitle = 'start';
      this.action = 'start';
    } else if (event == 'stop') {
      this.action = 'stop';
      this.modalTitle = 'stop';
    }
    this.command = event;
  }

  hideDetails(event: any) {
    if (this.instance) {
      this.instance['actionStart'] = false;
      this.instance = null;
    }
  }

  loadStatusGraph() {
    var chartDom = document.getElementById('statusGraph');
    var myChart = echarts.init(chartDom);

    let option = {
      tooltip: {
        trigger: 'item',
      },
      legend: {
        show: true,
        orient: 'vertical',
        left: 'left',
      },
      series: [
        {
          name: 'Status',
          type: 'pie',
          radius: '50%',
          data: [
            {
              name: 'Running',
              value: this.instancesList.filter((instance: any) => {
                return instance.instanceStatus == 'running';
              }).length,
            },
            {
              name: 'Stopped',
              value: this.instancesList.filter((instance: any) => {
                return instance.instanceStatus == 'stopped';
              }).length,
            },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  loadInstanceTypesGraph() {
    var chartDom = document.getElementById('instanceTypesGraph');
    var myChart = echarts.init(chartDom);

    let option = {
      tooltip: {
        trigger: 'item',
      },
      // legend: {
      //   show: true,
      //   orient: 'vertical',
      //   left: 'left',
      // },
      series: [
        {
          name: 'Instance Types',
          type: 'pie',
          radius: '50%',
          data: this.instanceTypes,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  loadPlatformGraph() {
    var chartDom = document.getElementById('OSGraph');
    var myChart = echarts.init(chartDom);

    let option = {
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          name: 'Platform',
          type: 'pie',
          radius: '50%',
          data: this.OSs,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  loadStatusChecksGraph() {
    var chartDom = document.getElementById('statusChecksGraph');
    var myChart = echarts.init(chartDom);

    let option = {
      tooltip: {
        trigger: 'item',
      },
      legend: {
        show: true,
        orient: 'vertical',
        left: 'left',
      },
      series: [
        {
          name: 'Status',
          type: 'pie',
          radius: '50%',
          data: [
            this.instancesList.filter((instance: any) => {
              return instance.statusCheck == '0/0';
            }).length > 0
              ? {
                  name: '0/0',
                  value: this.instancesList.filter((instance: any) => {
                    return instance.statusCheck == '0/0';
                  }).length,
                }
              : undefined,
            this.instancesList.filter((instance: any) => {
              return instance.statusCheck == '1/2';
            }).length > 0
              ? {
                  name: '1/2',
                  value: this.instancesList.filter((instance: any) => {
                    return instance.statusCheck == '1/2';
                  }).length,
                }
              : undefined,
            this.instancesList.filter((instance: any) => {
              return instance.statusCheck == '2/2';
            }).length > 0
              ? {
                  name: '2/2',
                  value: this.instancesList.filter((instance: any) => {
                    return instance.statusCheck == '2/2';
                  }).length,
                }
              : undefined,
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
