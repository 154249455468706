<div>
  <div class="form-group row">
    <label>Rule Name</label>
    <div class="form-edit">
      <span>{{rule.rule_name}}</span>
    </div>
  </div>
  <div class="form-group row">
    <div class="form-edit">
      <div>
        <span>To confirm deletion, type <i>delete</i> in the text input field.</span>
      </div>
      <div>
        <input type="text" [(ngModel)]="confirm_text" class="form-control">
      </div>
    </div>
  </div>
  <div class="form-group row">
    <div class="form-edit" style="display: flex; justify-content: flex-start;">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;"
        [ngClass]="{'disabled_bottom' : confirm_text != 'delete'}"
        (click)="confirm_text == 'delete' && updateRule()">Delete</button>
    </div>
  </div>
</div>