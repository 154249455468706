import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from './../../../_services/notifier.service';
declare let window: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './ebs.component.html',
})
export class ebsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  ebsNonEncryptedList: any = [];
  ebsEncryptedList: any = [];

  headers: any = [
    {
      id: 'volumeId',
      name: 'Volume ID',
      filter: true,
    },
    {
      id: 'volumeStatus',
      name: 'Volume Status',
      sort: { sort: true, direction: 'Asc' },
    },
    { id: 'snapshotId', name: 'Snapshot Id', filter: true },
    {
      id: 'instanceId',
      name: 'Instance Id',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
  ];

  funRef: any;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.funRef = {
      showData: this.showData,
    };

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      console.log('ebsComponent', msg);

      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy() {
    this.currentMessage.unsubscribe();
  }

  async load(nextToken: string = '') {
    this.notifier.loading(true);
    if (nextToken == '') {
      this.ebsEncryptedList = [];
      this.ebsNonEncryptedList = [];
    }
    let data = {
      accountId: this.accountId,
      region: this.regionId,
      action: 'fetchVolumeDetailsWithToken',
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Compliance.Ebs', 'Ebs Volume Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ebs`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      if (result.nonEncryptedVolumeList) {
        this.ebsNonEncryptedList =
          [...this.ebsNonEncryptedList, ...result.nonEncryptedVolumeList] || [];
      }
      if (result.encryptedVolumeList) {
        this.ebsEncryptedList =
          [...this.ebsEncryptedList, ...result.encryptedVolumeList] || [];
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.nextToken) {
      await this.load(result.nextToken);
    }

    this.notifier.loading(false);
  }

  async showData(ref: any, id: any) {
    console.log('showData', id);

    ref.notifier.loading(true);

    let data = {
      accountId: ref.accountId,
      region: ref.regionId,
      action: 'fetchInstanceName',
      instanceId: id.instanceId,
      nextToken: '',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Compliance.Ebs', 'InstanceName Fetched');
    //https://api.swayam.cloud/v3/admin/support
    //let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/support`;
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/operations/ebs`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      //ref.kbDetails = result.page;
      //ref.showDetailKB = true;

      ref.notifier.messageBox(
        'More Instance Info!',
        `<div style="display: flex; gap: 9px; align-items: center;"><strong>Instance Tag:</strong>  ` +
          result.instanceName +
          '</div>'
      );
    } else {
      //ref.notifier.messageBox("More Instance Info!", "sdasdasdasda");

      ref.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    ref.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  showPOPup() {
    this.notifier.messageBox(
      'More Instance Info!',
      '<span style="color:red"> sdasdasdasda <span>'
    );
  }
}
