<div class="card">
  <h5 class="card-header">RI Details</h5>
  <div style="
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      margin: 10px;
    ">
    <input class="datetimepicker" type="text" [(ngModel)]="dateRange" style="padding: 10px" />
    <button class="btn btn-primary-soft" (click)="load()">Refresh</button>
  </div>
  <div class="card-body">
    <table class="table table-docs table-hover" style="position: relative">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">
            {{ h.name }}
            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h, list)">
              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter" class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
            <div *ngIf="h.total">
              ${{getColumnTotal(h.id)}}
            </div>
          </th>
          <th>
            <span>
              Total OD
              <span *ngIf="customHeaders['Total OD'].sort && customHeaders['Total OD'].sort.sort"
                (click)="notifier.sortTable(customHeaders['Total OD'], list)">
                <i class="fa fa-sort-amount-desc" aria-hidden="true"
                  *ngIf="customHeaders['Total OD'].sort.direction == 'Des'"></i>
                <i class="fa fa-sort-amount-asc" aria-hidden="true"
                  *ngIf="customHeaders['Total OD'].sort.direction == 'Asc'"></i>
              </span>

              <span *ngIf="customHeaders['Total OD'].filter" class="inputseach">
                <input type="text" [(ngModel)]="customHeaders['Total OD'].filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </span>
            <div>${{getColumnTotal('total OD')}}</div>
          </th>
          <th>
            <span>
              Last 7 Days OD
              <span *ngIf="customHeaders['Last 7 Days OD'].sort && customHeaders['Last 7 Days OD'].sort.sort"
                (click)="notifier.sortTable(customHeaders['Last 7 Days OD'], list)">
                <i class="fa fa-sort-amount-desc" aria-hidden="true"
                  *ngIf="customHeaders['Last 7 Days OD'].sort.direction == 'Des'"></i>
                <i class="fa fa-sort-amount-asc" aria-hidden="true"
                  *ngIf="customHeaders['Last 7 Days OD'].sort.direction == 'Asc'"></i>
              </span>

              <span *ngIf="customHeaders['Last 7 Days OD'].filter" class="inputseach">
                <input type="text" [(ngModel)]="customHeaders['Last 7 Days OD'].filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </span>
            <div>${{getColumnTotal('Last 7 Days OD')}}</div>
          </th>
        </tr>
      </thead>
      <tbody [ngStyle]="{ display: list.length > 0 ? 'table-row-group' : '' }">
        <ng-container *ngFor="let i of list; let j = index">
          <tr *ngIf="notifier.filterTableColums(headers, i)">
            <td>{{ j + 1 }}</td>
            <td *ngFor="let h of headers">
              <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                <span>
                  {{ i[h.id] }}
                </span>
              </span>
              <span *ngIf="!h.click">
                <span>{{ i[h.id] }}</span>
              </span>
            </td>
            <td *ngIf="i.day_wise && i.day_wise.length > 0">
              <div class="failed_ami" (mouseenter)="setPosition($event)"
                *ngIf="i['total OD'] != '' && i['total OD'] != '-'">
                <div class="status_container dotter_underline">
                  {{i['total OD']}}
                </div>
                <div class="failed_ami_message_container"
                  [ngStyle]="{right: position.x ? position.x : 0, top: position.y ? position.y : 0, height: position.height, width: position.width}">
                  <div class="message_back">
                    <div>
                      <div class="message_grid"
                        [ngStyle]="{'max-width': position.overflow ? position.overflowwidth : '', 'overflow-x': position.overflow ? 'auto' : 'visible'}">
                        <div style="width: 600px; white-space: pre-wrap; max-width: 600px;">
                          <table>
                            <thead>
                              <tr>
                                <th *ngFor="let date of i.day_wise" style="white-space: nowrap; padding-right: 20px;">
                                  {{ dateParser(date.date)}}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td *ngFor="let date of i.day_wise" style="white-space: nowrap; padding-right: 20px;">
                                  ${{toFixed(date.total_od, 2)}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; height: 20px;">
                    <div class="showdown" [ngStyle]="{right: position.pointer}"></div>
                  </div>
                  <div class="message_container">
                    <div>
                      <div class="message_grid"
                        [ngStyle]="{'max-width': position.overflow ? position.overflowwidth : '', 'overflow-x': position.overflow ? 'auto' : 'visible'}">
                        <div style="width: 600px; white-space: pre-wrap;">
                          <table>
                            <thead>
                              <tr>
                                <th *ngFor="let date of i.day_wise" style="white-space: nowrap; padding-right: 20px;">
                                  {{ dateParser(date.date)}}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td *ngFor="let date of i.day_wise" style="white-space: nowrap; padding-right: 20px;">
                                  ${{toFixed(date.total_od, 2)}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span *ngIf="i['total OD'] == '' && i['total OD'] == '-'">
                -
              </span>
            </td>
            <td *ngIf="!i.day_wise || i.day_wise.length == 0">
              {{i['total OD']}}
            </td>
            <td>
              {{i['Last 7 Days OD'] ? i['Last 7 Days OD'].toFixed(4) : '-'}}
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tbody [ngStyle]="{
          display: list.length == 0 && !loading ? 'table-row-group' : 'none'
        }">
        <tr>
          <td [attr.colspan]="headers.length + 3">
            <div style="text-align: center">No Data Found</div>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{ display: loading ? 'table-row-group' : 'none' }">
        <tr>
          <td [attr.colspan]="headers.length + 3" style="position: sticky; bottom: 0; background-color: white">
            <div style="text-align: center">
              <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 20px" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>