import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'modal-popup',
  templateUrl: 'modal.html',
  //styleUrls: ['./hero-detail.component.css']
})
export class modalPopupComponent implements OnInit {
  @Input() headers?: any;
  @Input() data?: any;
  @Input() modalTitle?: any;
  @Input() maxWidth?: any;

  @Input() showModal?: any;

  @Output() hideModal = new EventEmitter<boolean>();

  close(value: boolean) {
    this.hideModal.emit(value);
  }

  constructor() {}

  ngOnInit() {}

  callFunction(name: any, param: any) {
    return;
  }
}
