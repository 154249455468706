<div
  style="position: relative; height: 100vh; background-image: url(../../../assets/img/networkb.jpg); color: white; background-size: cover;">
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" class="svgbackground" id="svgbackground">
    <radialGradient id="background-gradient">
      <stop offset="0" stop-color="rgba(0,0,0,0)" />
      <stop offset="1" stop-color="rgba(0,0,0,0)" />
    </radialGradient>
    <rect width="1" height="1" fill="url(#background-gradient)" />
    <g stroke-width="0.00125"></g>
  </svg>
  <div class="resp_register_parent"
    style="height: 100vh; overflow-y: auto; position: absolute; top: 50%; left: 50%; width: 100vw; transform: translate(-50%, -50%);">
    <div class="flex-grow-1 resp_register">
      <div class="row flex-between-center">
        <div class="col-12 text-center">
          <img style="
            display: block;
            margin: auto;
            margin-bottom: 20px;
          " src="../assets/img/swayam_logo_v3.0.svg" />
        </div>
      </div>
      <h3 style="text-align: center;">Register</h3>
      <form [formGroup]="registerForm" name="registerForm" *ngIf="!otpSent" (ngSubmit)="register()">
        <div class="mb-3">
          <div *ngIf="role != 'client'">
            <label class="form-label" for="card-email">First Name</label>
            <input class="form-control" id="card-email" formControlName="firstName" name="firstName" type="text"
              required minlength="4" maxlength="50" [(ngModel)]="firstName" />
          </div>
          <div class="mb-3" *ngIf="role != 'client'">
            <label class="form-label" for="card-email">Last Name</label>
            <input class="form-control" id="card-email" formControlName="lastName" name="lastName" type="text" required
              minlength="4" maxlength="50" [(ngModel)]="lastName" />
          </div>
          <div class="mb-3">
            <label class="form-label" for="card-email">Email Address</label>
            <input class="form-control" id="card-email" formControlName="emailId" name="emailId" type="email" required
              minlength="4" maxlength="50" [(ngModel)]="emailId" />
          </div>
          <div class="mb-3" *ngIf="role != 'client'">
            <label class="form-label" for="card-email">Company Name</label>
            <input class="form-control" id="card-email" formControlName="cmpName" name="cmpName" type="text" required
              minlength="4" maxlength="50" [(ngModel)]="cmpName" />
          </div>
          <div class="mb-3" *ngIf="role != 'client'">
            <label class="form-label" for="card-email">Designation</label>
            <input class="form-control" id="card-email" formControlName="designation" name="designation" type="text"
              required minlength="4" maxlength="50" [(ngModel)]="designation" />
          </div>
          <div class="mb-3">
            <label class="form-label" for="card-email">Password</label>
            <div class="password_container">
              <input class="form-control password_input" id="card-email" formControlName="password" name="password"
                type="password" required minlength="4" maxlength="50" [(ngModel)]="password" />
              <div class="password_checker_container password_checker_backbone password_checker_main"
                style="z-index: 1;">
                <div *ngIf="!passwordChecker(password)['policies']['Must have atleast 8 characters']"
                  style="font-size: 1.2em;" class="whitespacenone">
                  Must have atleast 8 characters
                </div>
                <div *ngIf="passwordChecker(password)['policies']['Must have atleast 8 characters']">
                  <div style="font-size: 1.2em;" class="whitespacenone"
                    *ngIf="passwordChecker(password)['strength'] == 1">
                    Weak Password
                  </div>
                  <div style="font-size: 1.2em;" class="whitespacenone"
                    *ngIf="passwordChecker(password)['strength'] == 2">
                    Average Password
                  </div>
                  <div style="font-size: 1.2em;" class="whitespacenone"
                    *ngIf="passwordChecker(password)['strength'] == 3">
                    Good Password
                  </div>
                  <div style="font-size: 1.2em;" class="whitespacenone"
                    *ngIf="passwordChecker(password)['strength'] == 4">
                    Strong Password
                  </div>
                </div>
                <div class="password_strength_container">
                  <div
                    [ngClass]="{very_low_strength: passwordChecker(password)['strength'] == 1, low_strength: passwordChecker(password)['strength'] == 2, medium_strength: passwordChecker(password)['strength'] == 3, high_strength: passwordChecker(password)['strength'] == 4}">
                  </div>
                  <div
                    [ngClass]="{low_strength: passwordChecker(password)['strength'] == 2, medium_strength: passwordChecker(password)['strength'] == 3, high_strength: passwordChecker(password)['strength'] == 4}">
                  </div>
                  <div
                    [ngClass]="{medium_strength: passwordChecker(password)['strength'] == 3, high_strength: passwordChecker(password)['strength'] == 4}">
                  </div>
                  <div [ngClass]="{high_strength: passwordChecker(password)['strength'] == 4}"></div>
                </div>
                <div class="password_policies">
                  <div *ngFor="let policy_key of getKeys(passwordChecker(password)['policies']); let i = index">
                    <ng-container *ngIf="i > 0">
                      <div class="password_policy passed" *ngIf="passwordChecker(password)['policies'][policy_key]">
                        <div style="margin-top: 5px;">
                          <div
                            style=" background-color: #afbaea; padding: 2px 5px; aspect-ratio: 1 / 1; border-radius: 50%; font-size: 0.6em;">
                            <i class="fa fa-check" style="color: #0321a6;" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div style="text-decoration: line-through; opacity: 0.6;">{{ policy_key }}</div>
                      </div>
                      <div class="password_policy" *ngIf="!passwordChecker(password)['policies'][policy_key]">
                        <div>
                          <i class="fa fa-circle" aria-hidden="true"></i>
                        </div>
                        <div>{{ policy_key }}</div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="password_checker_container password_checker_indicator" style="z-index: 2;"></div>
              <div class="password_checker_container password_checker_main" style="z-index: 3;">
                <div *ngIf="!passwordChecker(password)['policies']['Must have atleast 8 characters']"
                  style="font-size: 1.2em;" class="whitespacenone">
                  Must have atleast 8 characters
                </div>
                <div *ngIf="passwordChecker(password)['policies']['Must have atleast 8 characters']">
                  <div style="font-size: 1.2em;" class="whitespacenone"
                    *ngIf="passwordChecker(password)['strength'] == 1">
                    Weak Password
                  </div>
                  <div style="font-size: 1.2em;" class="whitespacenone"
                    *ngIf="passwordChecker(password)['strength'] == 2">
                    Average Password
                  </div>
                  <div style="font-size: 1.2em;" class="whitespacenone"
                    *ngIf="passwordChecker(password)['strength'] == 3">
                    Good Password
                  </div>
                  <div style="font-size: 1.2em;" class="whitespacenone"
                    *ngIf="passwordChecker(password)['strength'] == 4">
                    Strong Password
                  </div>
                </div>
                <div class="password_strength_container">
                  <div
                    [ngClass]="{very_low_strength: passwordChecker(password)['strength'] == 1, low_strength: passwordChecker(password)['strength'] == 2, medium_strength: passwordChecker(password)['strength'] == 3, high_strength: passwordChecker(password)['strength'] == 4}">
                  </div>
                  <div
                    [ngClass]="{low_strength: passwordChecker(password)['strength'] == 2, medium_strength: passwordChecker(password)['strength'] == 3, high_strength: passwordChecker(password)['strength'] == 4}">
                  </div>
                  <div
                    [ngClass]="{medium_strength: passwordChecker(password)['strength'] == 3, high_strength: passwordChecker(password)['strength'] == 4}">
                  </div>
                  <div [ngClass]="{high_strength: passwordChecker(password)['strength'] == 4}"></div>
                </div>
                <div class="password_policies">
                  <div *ngFor="let policy_key of getKeys(passwordChecker(password)['policies']); let i = index">
                    <ng-container *ngIf="i > 0">
                      <div class="password_policy passed" *ngIf="passwordChecker(password)['policies'][policy_key]">
                        <div style="margin-top: 5px;">
                          <div
                            style=" background-color: #afbaea; padding: 2px 5px; aspect-ratio: 1 / 1; border-radius: 50%; font-size: 0.6em;">
                            <i class="fa fa-check" style="color: #0321a6;" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div style="text-decoration: line-through; opacity: 0.6;">{{ policy_key }}</div>
                      </div>
                      <div class="password_policy" *ngIf="!passwordChecker(password)['policies'][policy_key]">
                        <div>
                          <i class="fa fa-circle" aria-hidden="true"></i>
                        </div>
                        <div>{{ policy_key }}</div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label" for="card-email">Confirm Password</label>
            <input class="form-control" id="card-email" formControlName="confPassword" name="confPassword"
              type="password" required minlength="4" maxlength="50" [(ngModel)]="confPassword" />
          </div>
          <div class="mb-3" *ngIf="role != 'client'">
            <label class="form-label" for="card-email">Phone Number</label>
            <input class="form-control" id="card-email" formControlName="phoneNumber" name="phoneNumber" type="number"
              required minlength="4" maxlength="50" [(ngModel)]="phoneNumber" />
          </div>
          <div class="mb-3 tab-pane preview-tab-pane active">

            <div style="text-align: right;" class="mb-3 tab-pane preview-tab-pane active">
              <a routerLink="/login" style="color: white;" class="btn btn-link">Already have account?</a>
            </div>

            <div class="mb-3">
              <button class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit" (ngSubmit)="register()">
                Register
              </button>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="otpForm" *ngIf="otpSent" (ngSubmit)="otpValidation()">
        <div class="mb-3">
          <label class="form-label" for="card-email">OTP</label>
          <input class="form-control" id="card-email" formControlName="otp" type="text" required minlength="4"
            maxlength="50" autocomplete="off" [(ngModel)]="otp" />

        </div>

        <div class="mb-3">
          <button class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit" (ngSubmit)="otpValidation()">
            Validate
          </button>
        </div>
      </form>
    </div>
  </div>
</div>