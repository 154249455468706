import { Component, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

import * as moment from 'moment';

declare let $: any;
declare let window: any;

@Component({
  selector: 'storage-launch-instance',
  templateUrl: 'storage.launch.component.html',
  styleUrls: [
    './../../launch.instance.component.css',
    './../../../../upload.bom.component.css',
  ],
})
export class StorageLaunchInstanceComponent implements OnInit, OnDestroy {
  @Input('instance') instance: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;

  constructor() {}
  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
