<div class="card">
  <h5 class="card-header" *ngIf="!createTicketStrictTicketType">Create Ticket</h5>
  <h5 class="card-header" *ngIf="createTicketStrictTicketType">
    <button class="btn btn-light" (click)="close()">
      < Back</button>
        <span>Create Ticket</span>
  </h5>
  <div class="card-body">
    <h5 class="card-title">Case Details</h5>

    <div class="form-row">
      <div class="form-group row">
        <label>Select Support Type</label>
        <div class="support_types_container" style="margin: 10px">
          <div class="support_type_container pointer" [ngClass]="{
              support_type_selected: supportType == 'minfy' && !minfyDisabled,
              selected_type_disabled: minfyDisabled
            }">
            <div class="support_type_box" (click)="changeSupportType('minfy')">
              <div class="support_type_logo">
                <div class="outer">
                  <div class="inner"></div>
                </div>
              </div>
              <div>
                <div class="support_type_text">Minfy Support</div>
                <div class="support_type_desc">Managed Services from Minfy</div>
              </div>
            </div>
          </div>
          <div class="support_type_container pointer" [ngClass]="{
              support_type_selected: supportType == 'aws' && !awsDisabled,
              selected_type_disabled: awsDisabled
            }">
            <div class="support_type_box" (click)="changeSupportType('aws')">
              <div class="support_type_logo">
                <img src="../../../assets/img/aws_logo_1.png" alt="" />
              </div>
              <div>
                <div class="support_type_text">AWS Support</div>
                <div class="support_type_desc">
                  Support from Amazon Web Services
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="p-1">
        <input type="radio" name="supportType" id="minfySupport" checked/>
        <label for="minfySupport">Minfy Support</label>
      </div>
      <div class="p-1">
        <input type="radio" name="supportType" id="awsSupport" />
        <label for="awsSupport">AWS Support</label>
      </div> -->
        </div>
      </div>

      <div class="mb-3" *ngIf="supportType == 'minfy'">
        <label for="" class="form-label">Service</label>
        <div>
          <div style="width: 500px;">
            <select class="form-control" id="servicesSelect" (change)="changeMinfyService()"
              [(ngModel)]="minfyTicketServiceSelected" data-live-search="true">
              <option value={{service}} *ngFor="let service of minfyTicketServices">{{service}}</option>
              <option value="others">Others</option>
            </select>
          </div>

        </div>
      </div>
      <div class="mb-3" *ngIf="supportType == 'minfy' && minfyTicketServiceSelected == 'others'">
        <label for="" class="form-label">Service Name</label>
        <div>
          <div style="width: 500px;">
            <input type="text" [ngModel]="minfyTicketServiceSelectedText" class="form-control">
          </div>

        </div>
      </div>
      <div class="mb-3" [ngStyle]="{display: supportType == 'minfy' && minfyTicketServiceSelected ? 'block': 'none'}">
        <label for="" class="form-label">Category</label>
        <div>
          <div style="width: 500px;">
            <div [ngStyle]="{display: minfyTicketServiceSelected != 'others'? 'block': 'none'}">
              <select class="form-control" id="categoriesSelect" (change)="minfyCategoryChange()"
                [(ngModel)]="minfyTicketCategorySelected" data-live-search="true">
                <option value="{{category}}" *ngFor="let category of getKeys(mainService[minfyTicketServiceSelected])">
                  {{category}}
                </option>
              </select>
            </div>
            <div [ngStyle]="{display: minfyTicketServiceSelected == 'others'? 'block': 'none'}">
              <input type="text" [ngModel]="minfyTicketCategorySelected" class="form-control">
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" *ngIf="supportType == 'minfy' && minfyTicketCategorySelected == 'Others'">
        <label for="" class="form-label">Category Name</label>
        <div>
          <div style="width: 500px;">
            <input type="text" [ngModel]="minfyTicketCategorySelectedText" class="form-control">
          </div>

        </div>
      </div>
      <div class="mb-3" *ngIf="supportType == 'minfy' && checkEC2Operation()">
        <label for="" class="form-label">EC2 Instances</label>
        <div style="display: flex; justify-content: flex-start; align-items: center; gap: 10px;">
          <div style="width: 500px;" [ngStyle]="{display: loading_ec2 || ec2_list.length > 0 ? 'block': 'none'}">
            <select class="form-control" id="ec2list" (change)="loadInstanceData()" [(ngModel)]="selected_ec2"
              data-live-search="true">
              <option [ngValue]="ec2" *ngFor="let ec2 of ec2_list">
                {{ec2.ResourceName}} ({{ec2.ResourceId}})
              </option>
            </select>
          </div>
          <div *ngIf="!loading_ec2 && ec2_list.length == 0">
            No Instances Found
          </div>
          <span *ngIf="loading_ec2">
            <img src="../assets/img/loading_2.svg" alt="loading scans" width="10" style="width: 15px; margin: 10px;">
          </span>
        </div>
      </div>
      <div class="mb-3" *ngIf="supportType == 'minfy' && checkEC2Operation() && selected_ec2">
        <label for="" class="form-label">Selected Instance</label>
        <div style="display: flex; justify-content: flex-start; flex-direction: row;">
          <div style="display: flex; flex-direction: column; gap: 10px;" class="ec2-item">
            <div style="display: flex; justify-content: space-between; gap: 20px; flex-direction: row;">
              <div
                style="display: flex; justify-content: flex-start; align-items: flex-start; gap: 6px; flex-direction: column;">
                <strong>{{selected_ec2.ResourceName}} ({{selected_ec2.ResourceId}})</strong>
                <span style="text-transform: capitalize; display: flex; align-items: center; gap: 8px;"
                  *ngIf="selected_ec2.State != 'LOADING'">
                  <div *ngIf="selected_ec2.State == 'running'"
                    style="width: 12px; height: 12px; background-color: green; border-radius: 50%;">

                  </div>
                  <div *ngIf="selected_ec2.State == 'stopped'"
                    style="width: 12px; height: 12px; background-color: red; border-radius: 50%;">

                  </div>
                  <div *ngIf="selected_ec2.State == 'pending' || selected_ec2.State == 'stopping'">
                    <img src="../assets/img/loading_2.svg" alt="loading scans" width="10"
                      style="width: 15px; margin: 10px;">
                  </div>
                  {{selected_ec2.State}}
                </span>
                <span style="text-transform: capitalize; display: flex; align-items: center; gap: 8px;"
                  *ngIf="selected_ec2.State == 'LOADING'">
                  <img src="../assets/img/loading_2.svg" alt="loading scans" width="10"
                    style="width: 15px; margin: 10px;">
                </span>
              </div>
              <div
                style="display: flex; justify-content: flex-start; align-items: flex-start; gap: 6px; flex-direction: row;">
                <span class="btn btn_blue" *ngIf="selected_ec2.State == 'running'"
                  (click)="triggerInstances($event, 'reboot_ec2')">Reboot</span>
                <span class="btn btn_blue" *ngIf="selected_ec2.State == 'running'"
                  (click)="triggerInstances($event, 'stop_ec2')">Stop</span>
                <span class="btn btn_blue" *ngIf="selected_ec2.State == 'stopped'"
                  (click)="triggerInstances($event, 'start_ec2')">Start</span>
              </div>
            </div>
            <div *ngIf="!selected_ec2.hide_matrics">
              <div><strong>Instance Metrics <span
                    *ngIf="selected_ec2.from && selected_ec2.from != ''">({{selected_ec2.from}})</span></strong></div>
              <div
                style="display: flex; justify-content: space-between; align-items: center; gap: 10; flex-wrap: wrap;">
                <div>
                  <label><strong>CPU</strong></label>
                  <div>
                    <span *ngIf="selected_ec2.cpu && selected_ec2.cpu == 'LOADING'">
                      <img src="../assets/img/loading_2.svg" alt="loading scans" width="10"
                        style="width: 15px; margin: 10px;">
                    </span>
                    <span *ngIf="selected_ec2.cpu && selected_ec2.cpu != 'LOADING'">
                      {{selected_ec2.cpu}}
                    </span>
                  </div>
                </div>
                <div>
                  <label><strong>RAM</strong></label>
                  <div>
                    <span *ngIf="selected_ec2.ram && selected_ec2.ram == 'LOADING'">
                      <img src="../assets/img/loading_2.svg" alt="loading scans" width="10"
                        style="width: 15px; margin: 10px;">
                    </span>
                    <span *ngIf="selected_ec2.ram && selected_ec2.ram != 'LOADING'">
                      {{selected_ec2.ram}}
                    </span>
                  </div>
                </div>
                <div>
                  <label><strong>Disk</strong></label>
                  <div>
                    <span *ngIf="selected_ec2.disk && selected_ec2.disk == 'LOADING'">
                      <img src="../assets/img/loading_2.svg" alt="loading scans" width="10"
                        style="width: 15px; margin: 10px;">
                    </span>
                    <span *ngIf="selected_ec2.disk && selected_ec2.disk != 'LOADING'">
                      {{selected_ec2.disk}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Severity</label>
        <select class="form-control" id="serverityCodeSelect"
          [attr.disabled]="minfyTicketServiceSelected != 'others' && supportType != 'aws' && minfyTicketCategorySelected != 'Others' ? 'true': null"
          [(ngModel)]="ticketSeverity" data-live-search="true">
          <option value="low">Low (low)</option>
          <option value="normal">Normal (medium)</option>
          <option value="high">High (high)</option>
          <option value="urgent">Urgent (critical)</option>
        </select>
      </div>

      <div class="mb-3" *ngIf="supportType == 'aws' && !awsDisabled">
        <label for="" class="form-label">Service</label>
        <select class="form-control" id="serviceCodeSelect" [(ngModel)]="serviceSelected"
          (change)="changeService($event)" data-live-search="true">
          <option value="low" [ngValue]="service" *ngFor="let service of services">
            {{ service.name }}
          </option>
        </select>
        <span style="color: red;" *ngIf="!errors.service.is_valid">
          <i class="fa fa-exclamation-circle" style="margin-right: 5px;" aria-hidden="true"></i>
          {{errors.service.message}}
        </span>
      </div>
      <!-- [attr.selected]="categories.selected.code == category.code" [attr.selected]="services.selected.code == service.code" -->
      <div class="mb-3" *ngIf="supportType == 'aws' && !awsDisabled">
        <label for="" class="form-label">Category</label>
        <select class="form-control" id="categoryCodeSelect" [(ngModel)]="categorySelected" data-live-search="true">
          <option value="low" [ngValue]="category" *ngFor="let category of categoriesList">
            {{ category.name }}
          </option>
        </select>
        <span style="color: red;" *ngIf="!errors.category.is_valid">
          <i class="fa fa-exclamation-circle" style="margin-right: 5px;" aria-hidden="true"></i>
          {{errors.category.message}}
        </span>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Subject</label>
        <input type="text" class="form-control" (keyup)="updateError('subject')"
          [ngStyle]="{border: !errors.subject.is_valid ? '1px solid red' : ''}" [(ngModel)]="ticketSubject" />
        <span style="color: red;" *ngIf="!errors.subject.is_valid">
          <i class="fa fa-exclamation-circle" style="margin-right: 5px;" aria-hidden="true"></i>
          {{errors.subject.message}}
        </span>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Description</label>
        <textarea [(ngModel)]="ticketDescription" name="" class="ckeditor" style="width: 100%;" rows="10"></textarea>
        <span style="color: red;" *ngIf="!errors.description.is_valid">
          <i class="fa fa-exclamation-circle" style="margin-right: 5px;" aria-hidden="true"></i>
          {{errors.description.message}}
        </span>
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Attachments</label>
        <div>
          <div class="support_types_container files_container">
            <div class="support_type_container" *ngFor="let d of attachedfileData; let indexx = index">
              <div class="support_type_box">
                <div class="support_type_logo">
                  <i class="fa fa-file-text" aria-hidden="true"></i>
                </div>
                <div>
                  <div class="support_type_text">{{ d.fileName }}</div>
                  <div class="support_type_desc">
                    {{ niceBytes(d.fileSize) }}
                  </div>
                </div>
                <div class="support_type_remove">
                  <i class="fa fa-times pointer" aria-hidden="true" (click)="deleteSeletedFile(indexx)"></i>
                </div>
              </div>
            </div>
            <div class="support_type_text attachment_add">
              <div class="flex-center-align" [attr.disabled]="!writeAccess"
                [ngStyle]="{'pointer-events': !writeAccess && 'none'}" (click)="triggerFIle()">
                <div><i class="fa fa-plus" aria-hidden="true"></i></div>
              </div>
            </div>
            <input #attachmenrs type="file" name="" multiple id="attachments" class="form-control"
              (change)="getFile($event)" style="display: none" />
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Additional Contacts</label>
        <div class="additional_contacts">
          <div class="additional_contact" *ngFor="
              let contact of additionalContacts;
              let i = index;
              trackBy: trackByFn
            ">
            <input type="text" name="contact_value" class="form-control" *ngIf="additionalContacts.length - 3 < i"
              (keyup)="checkForLast(contact, i)" [(ngModel)]="additionalContacts[i]" />
            <input type="text" name="contact_value" class="form-control" *ngIf="additionalContacts.length - 3 >= i"
              [(ngModel)]="additionalContacts[i]" />
            <div>
              <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add"
                *ngIf="additionalContacts.length == i + 1" (click)="addAdditionalContact()"></i>
            </div>
            <div>
              <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove"
                *ngIf="additionalContacts.length > 1" (click)="deleteSelectedAdditionalContact(i)"></i>
            </div>
          </div>
        </div>
        <span style="color: red;" *ngIf="!errors.contacts.is_valid">
          <i class="fa fa-exclamation-circle" style="margin-right: 5px;" aria-hidden="true"></i>
          {{errors.contacts.message}}
        </span>
      </div>
    </div>
    <a href="#" class="btn btn-primary" [ngClass]="{
        disabled_btn:
          (supportType == 'minfy' && minfyDisabled) ||
          (supportType == 'aws' && awsDisabled) ||
          !writeAccess
      }" (click)="createTicket($event)" [attr.disabled]="
        (supportType == 'minfy' && minfyDisabled) ||
        (supportType == 'aws' && awsDisabled)
      ">Create</a>
  </div>
</div>

<instacne-start-stop-v2 *ngIf="command && writeAccess" [modalTitle]="modalTitle" [action]="action" [command]="command"
  [instances]="[selected_ec2]" (hideModal)="hideStartStop($event)"></instacne-start-stop-v2>