import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['./milestones.component.css', './../../warquiz.component.css'],
})
export class MilestonesComponent implements OnInit {
  @Input('workloadId') workloadId: string;
  @Input('milestones') milestones: string;
  @Input('loading') loading: any;
  @Output('milestone') milestone = new EventEmitter<any>();

  headers: any = [
    {
      id: 'name',
      name: 'Name',
      click: true,
    },
    {
      id: 'creation_time',
      name: 'Created On',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {}

  selectMilestone(milestone: any) {
    this.milestone.emit(milestone);
  }
}
