<div class="card">
  <div class="card-header">
    <h5>
      Enable Support Plan
    </h5>
  </div>
  <div class="card-body">

    <div class="form-row">
      <div class="mb-3">
        <label for="" class="form-label">Support Plan Type</label>
        <select class="form-control" id="supportPlanTypeSelect" [(ngModel)]="supportPlanType" data-live-search="true">
          <option value="">---Choose Support plan---</option>
          <option value="developer">Developer</option>
          <option value="business">Business</option>
          <option value="enterprise">Enterprise</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="" class="form-label">Discount</label>
        <input type="text" class="form-control" [(ngModel)]="discount" />
      </div>
      <div class="mb-3">
        <label for="" class="form-label">Date Range</label>
        <input id="dateRange" class="datetimepicker form-control" type="text" [(ngModel)]="dateRange" />
      </div>
    </div>
    <button class="btn btn-light" style="background-color: #5864FF; color: white;"
      (click)="enableSupport()">Enable</button>
  </div>
</div>