<div class="minfy-ui-multi-select-dropdown" #maindoprdown>
  <div class="flex-options">
    <div *ngIf="getSelected().length > 0" class="selected-options">
      <div *ngFor="let option of getSelected(2);" class="selected-item">
        <span>{{option.value}}</span>
        <span><i class="remove-item fa fa-times" (click)="removeOption(option.index)" aria-hidden="true"></i></span>
      </div>
    </div>
    <div *ngIf="getSelected().length == 0">
      No {{title}} selected
    </div>
    <div class="flex-end-align">
      <div *ngIf="getSelected().length > 0" class="counter-selected">
        <span>
          {{getSelected().length}}
        </span>
        <span><i class="remove-item fa fa-times" (click)="removeOption(-1)" aria-hidden="true"></i></span>
      </div>
      <div style="padding: 0px 8px;">
        <i class="fa fa-caret-down remove-item" aria-hidden="true" (click)="showOptions()"></i>
      </div>
    </div>
  </div>
  <div class="absolute-dropdown" *ngIf="showoptions">
    <div class="flex-between-align search-bar">
      <input type="text" autocomplete="null" [(ngModel)]="searchText" placeholder="Search">
      <span><i class="fa fa-search" aria-hidden="true"></i></span>
    </div>
    <div>
      <div (click)="alterOption($event, option, i)" class="dropdown-search-result"
        *ngFor="let option of filterSearchResult(); let i = index">
        <span *ngIf="option.selected">
          <input type="checkbox" checked>
        </span>
        <span *ngIf="!option.selected">
          <input type="checkbox">
        </span>
        <label [attr.for]="option.value + '_id'">{{option.value}}</label>
      </div>
    </div>
  </div>
</div>