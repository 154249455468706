import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../api/api.service';
import { NotifierService } from '../../_services/notifier.service';
import * as moment from 'moment';

@Component({
  templateUrl: './amis.component.html',
})
export class AMIsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';

  funRef: any;
  createScan: any = false;

  scans: any = [];

  scansHeaders: any = [
    {
      id: 'scan_name',
      name: 'Scan Name',
    },
    {
      id: 'scrt',
      name: 'Scan Creation Time',
      alter: (data: any, value: any): String => {
        let date = moment(data[value]).format('DD/MM/YYYY hh:mm A');
        return date;
      },
    },
    {
      id: 'scot',
      name: 'Scan Completion Time',
      alter: (data: any, value: any): String => {
        if (data[value] && data[value] != '-') {
          let date = moment(data[value]).format('DD/MM/YYYY hh:mm A');
          return date;
        }
        return '-';
      },
    },
    {
      id: 'status',
      name: 'Status',
    },
    {
      id: 'ta',
      name: 'Total AMIs',
      alter: (data: any, value: any): String => {
        return data[value] ? data[value].toString() : '-';
      },
    },
    {
      id: 'ts',
      name: 'Total Snapshots Attached',
      alter: (data: any, value: any): String => {
        return data[value] ? data[value].toString() : '-';
      },
    },
  ];
  fetch_scans: boolean = false;

  accountId: any;
  regionId: any;
  currentMessage: any;
  notifications: any = [];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.funRef = {};

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    this.createScan = false;
    await this.loadScans();
  }

  createRuleTrigger() {
    this.createScan = true;
  }

  async loadScans() {
    this.fetch_scans = true;
    this.scans = [];

    let data: any = {
      action: 'list_scans',
      account_id: this.accountId,
      region_name: this.regionId,
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiUrl: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/amis`;

    let result = await this.apiServer.postDataPromis(apiUrl, data, header);

    if ((result.s == '1' || result.status == '1') && result.scans) {
      this.scans = result.scans;
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }

    this.fetch_scans = false;
  }

  close(evnet_: any) {
    if (evnet_) {
      this.load();
    }
    this.createScan = false;
  }

  async downloadReport(scan: any) {
    this.notifier.loading(true);
    let data: any = {
      action: 'presigned_url',
      file_path: scan['file_path'],
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiUrl: string = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/amis`;

    let result = await this.apiServer.postDataPromis(apiUrl, data, header);

    if (result.s == '1' || result.status == '1') {
      var link = document.createElement('a');
      link.download =
        scan['file_path'].split('/')[scan['file_path'].split('/').length - 1];
      link.href = result.presign_url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  callFunction(value: any, param: any) {
    this.funRef[value](this, param);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
