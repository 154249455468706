import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './view.ec2-group.component.html',
})
export class ViewEC2GroupComponenet implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  groups: any = [];
  showGroup: any;
  updateGroup: any;
  groupAction: string;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  headers: any = [
    {
      id: 'clientId',
      name: 'Client ID',
    },
    { id: 'accountId', name: 'Account ID' },
    { id: 'groupName', name: 'Group Name' },
  ];

  instancesHeader: any = [
    {
      id: 'instanceId',
      name: 'Instance ID',
    },
    {
      id: 'instanceName',
      name: 'Instance Name',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null) {
        return;
      }

      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    let data = { clientId: this.userId, action: 'fetch' };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2groups`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.groups = result.list;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  showGroupInstances(group: any) {
    this.showGroup = group;
  }

  hideGroupInstances(event: any) {
    this.showGroup = null;
  }

  updateGroupInstances(group: any) {
    this.groupAction = 'update';
    this.updateGroup = group;
  }

  async hideUpdateGroupInstances(event: any) {
    if (event) {
      this.updateGroup = event;
      await this.load();
    }
    this.updateGroup = null;
  }

  createEC2Group() {
    this.groupAction = 'save';
    this.updateGroup = {
      clientId: this.userId,
      groupName: '',
      instanceList: [],
      accountId: this.accountId,
    };
  }

  callFunction(name: any, param: any) {
    return;
  }
}
