<div class="card">
  <div class="card-header">
    <h5>Single Master Job</h5>
  </div>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; align-items: center">
      <button
        class="btn btn-light"
        style="background-color: #5864ff; color: white"
        (click)="status()"
      >
        Refresh
      </button>
    </div>
    <div class="container" style="width: 500px">
      <form name="discountForm" (submit)="run()">
        <div>
          <label>Account ID</label>
          <input
            class="form-control"
            name="accountID"
            type="text"
            [ngModel]="accountId"
            disabled
          />
        </div>
        <div>
          <label>Current Month</label>
          <input
            class="form-control"
            name="dateRange"
            type="text"
            id="currentMonth"
          />
        </div>
        <div>
          <label>Next Month</label>
          <input
            class="form-control"
            name="dateRange"
            type="text"
            id="nextMonth"
          />
        </div>
        <div class="button-container">
          <button
            class="btn btn-light"
            style="background-color: #5864ff; color: white"
            type="submit"
            [disabled]="isRunning"
          >
            Run job
          </button>
          <span class="warning-message" *ngIf="isRunning">
            Job is still running. Please wait...
          </span>
        </div>
      </form>
    </div>
  </div>
</div>

<style>
  form div {
    margin: 10px 0;
  }
</style>
