import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  CanDeactivate,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../_services';

declare let window: any;

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //const currentUser = this.authenticationService.currentUserValue;

    //this.route.queryParams
    //  .subscribe(params => {
    //    console.log(params);
    //  else {

    //    }
    //  }
    //);

    //let queryString:any = {};
    //location.search.substr(1).split("&").forEach(function (pair) {
    //  if (pair === "") return;
    //  var parts = pair.split("=");
    //  queryString[parts[0]] = parts[1] &&
    //    decodeURIComponent(parts[1].replace(/\+/g, " "));
    //});

    //console.log("queryString", queryString);
    //if (queryString.t) {
    //  localStorage.setItem('t', queryString.t);
    //  localStorage.setItem('t', queryString.t);
    //  localStorage.setItem('t', queryString.uid);

    //  localStorage.setItem('accountId', queryString.accountId);
    //  localStorage.setItem('region', queryString.region);

    //}
    const currentUser = localStorage.getItem('t');
    if (currentUser) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    let redirect: string = window.location.pathname + window.location.search;
    window.location.href = `/login?redirectURL=${redirect}`;
    return false;
  }

  qs(key: string) {
    key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, '\\$&'); // escape RegEx meta chars
    var match = location.search.match(
      new RegExp('[?&]' + key + '=([^&]+)(&|$)')
    );
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
}

export interface CanDeactivateComponent {
  canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable()
export class canDeactivateGuard
  implements CanDeactivate<CanDeactivateComponent>
{
  canDeactivate(component: CanDeactivateComponent) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
