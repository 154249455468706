import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import * as moment from 'moment';
import { CustomDatePipe } from '../margin-dashboard/customDate.component';
import { filter } from 'rxjs/operators';
declare let Jhxlsx: any;
@Component({
  templateUrl: './FinanceYearReport-unbilledpnl.component.html',
  styleUrls: ['./FinanceYearReport-unbilledpnl.component.css'],
})
export class FinanceUnbilledPnl implements OnInit, OnDestroy {
  currentMessage: any;
  accountId: string;
  dates: any[] = [];
  startMonth = 4;
  endMonth = 2;
  currentYear = new Date().getFullYear();
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  //   result_data: any = [];
  result_data: any;
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  main_header: any = [
    {
      name: 'Master Account ID',
      id: 'mid',
      rowspan: true,
      num: 2,
      type: 'String',
      filter: true,
    },
    {
      name: 'Customer Name',
      id: 'customerName',
      rowspan: true,
      num: 2,
      type: 'String',
      filter: true,
    },
  ];
  sub_header = [
    {
      name: 'PnL (%)',
      id: 'plPercentageForUnbill',
    },
  ];
  ngOnInit(): void {
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      }
    });
    let current = moment(`${this.currentYear}-${this.startMonth}`, 'YYYY-MM');

    while (
      current.month() !== this.endMonth ||
      current.year() !== this.currentYear + 1
    ) {
      this.dates.push(current.format('MM-yyyy'));
      current.add(1, 'month');
    }
    this.dates.push(current.format('MM-yyyy'));

    this.fetchData();
  }
  async fetchData() {
    this.result_data = [];
    this.notifier.loading(true);

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    for (let start = 0; start <= 12; start += 4) {
      let data = {
        a: 'unbilledSummary',
        isDevUser: '0',
        dates: this.dates.slice(start, start + 4),
      };
      let apiUrl = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchchildaccwithcosts/fetchchildaccwithcostsv2`;
      let result = await this.apiServer.postDataPromis(apiUrl, data, header);
      if (result.status == '1' || result.s == '1') {
        if (result.financeYearDate) {
          this.notifier.loading(false);
          Object.keys(result.financeYearDate).map((key) => {
            this.result_data.push(result.financeYearDate[key]);
          });
        }
      }
    }
    if (this.result_data.length == 0) {
      this.notifier.alert('Info', '', 'data is missing.', 'info', 5000);
      this.notifier.loading(false);
    }
  }

  exportToCsv() {
    const updateDate: string[] = [];
    this.dates.forEach((date) => {
      updateDate.push(date);
      updateDate.push('');
    });
    this.notifier.loading(true);
    let data: any = [
      {
        sheetName: 'Finanace Year details for PnL',
        data: [
          [
            { text: 'S.N' },
            ...this.main_header.map((h:any) => {
              return { text: h.name };
            }),
            ...updateDate.map((date) => {
              return { text: date };
            }),
          ],
        ],
      },
    ];
    let subcol = [{ text: '' }, { text: '' }, { text: '' }];

    this.dates.map((d) => {
      this.sub_header.map((sb) => {
        subcol.push({ text: sb.name });
      });
    });
    data[0]['data'].push(subcol);
    let count = 1;
    this.result_data.map((item: any) => {
      let data_: any = [];
      data_.push(
        { text: count },
        { text: item.mid },
        { text: item.customerName }
      );
      count += 1;
      this.dates.forEach((date) => {
        if (item.pnlData[date] == undefined) {
          data_.push({ text: '--' }, { text: '--' });
        } else {
          data_.push(
            {
              text: item.pnlData[date].pl ? item.pnlData[date].pl : '--',
            },
            {
              text: item.pnlData[date].plPercentage
                ? item.pnlData[date].plPercentage
                : '--',
            }
          );
        }
      });
      data[0]['data'].push(data_);
    });
    var options = {
      fileName: 'Finanace Year details for PnL',
    };
    Jhxlsx.export(data, options);
    this.notifier.loading(false);
  }
  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
