<div class="pipeline-container">
  <div class="pipeline-steps" *ngIf="pipelineDetail">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button *ngFor="let tab of getObjectKeys(pipelineDetail)" class="nav-link"
          [ngClass]="{active: selectedEnv == tab}" id="{{tab}}-tab" data-bs-toggle="tab"
          [attr.data-bs-target]="'#' + tab" type="button" role="tab" style="text-transform: capitalize;"
          (click)="selectedEnv = tab" [attr.aria-controls]="tab" aria-selected="true">{{ tab }}</button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent" style="margin-top: 12px;">
      <div class="tab-pane fade show" [ngClass]="{active: selectedEnv == tab}"
        *ngFor="let tab of getObjectKeys(pipelineDetail)" id="{{tab}}" role="tabpanel"
        [attr.aria-labelledby]="tab+'-tab'">
        <ng-container *ngFor="let step of getObjectKeys(pipelineDetail[tab])">
          <div class="pipeline-step-container">
            <div class="pipeline-step"
              [ngClass]="{success_strip: pipelineDetail[tab][step]['last_execution_status'] == 'Succeeded', failed_strip: pipelineDetail[tab][step]['last_execution_status'] == 'Failed'}">
              <div class="pipeline-step-header">
                <div class="status_text_header">
                  <span class="success_check" *ngIf="pipelineDetail[tab][step]['last_execution_status'] == 'Succeeded'">
                    <i class="fa-regular fa-circle-check"></i>
                  </span>
                  <span class="failed_check" *ngIf="pipelineDetail[tab][step]['last_execution_status'] == 'Failed'">
                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                  </span>
                  <span style="color: inherit;">
                    {{step}}
                  </span>
                </div>
                <div>
                  <pipeline-status [status]="pipelineDetail[tab][step]['last_execution_status']">
                  </pipeline-status>
                </div>
              </div>
              <div class="pipeline-step-body">
                <div class="pipeline_actions">
                  <div *ngFor="let action of pipelineDetail[tab][step]['actions']" style="padding: 6px; margin: 10px 0px; border: 1px solid rgb(212, 211, 211);
  border-radius: 5px;">
                    <span class="pipeline_action_container">
                      <span class="pipeline_action_header">
                        <span class="action_header_text_container">
                          <span *ngIf="action['status'] == 'Succeeded'">
                            <i class="fa-regular fa-circle-check"></i>
                          </span>
                          <span *ngIf="action['status'] == 'Failed'">
                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                          </span>
                          <span style="font-weight: bold; font-size: 1.2em;">
                            {{ action['name'] }}
                          </span>
                          <span style="font-size: 0.9em; opacity: 0.8;" *ngIf="action['time']">
                            {{ timeSince(action['time']) }} ago
                          </span>
                        </span>
                      </span>
                      <ng-container *ngIf="action['error_details']">
                        <span>
                          {{ action['error_details']['message'] }}
                        </span>
                      </ng-container>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pipeline-icon-container">
              <i class="fa-solid fa-arrow-down"></i>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="pipeline-activities">
    <div class="pipeline-step-header">
      <div class="status_text_header">
        Activities
      </div>
    </div>
    <div class="pipeline-step-body">
      <div style="display: flex; justify-content:space-between;">
        <div class="pipeline-activity">
          <div style="display: flex; justify-content: flex-start; gap: 6px;">
            <span style="font-weight: bold; text-transform: capitalize;">{{user_favicon(pipeline["created_by"],
              true)["user_full_name"]}}</span>
            <span>commited</span>
          </div>
          <div>
            Initial Commit
          </div>
        </div>
        <div style="margin-top: 10px;">3 months ago</div>
      </div>
      <div style="display: flex; justify-content:space-between;">
        <div class="pipeline-activity">
          <div style="display: flex; justify-content: flex-start; gap: 6px;">
            <span style="font-weight: bold; text-transform: capitalize;">{{user_favicon(pipeline["created_by"],
              true)["user_full_name"]}}</span>
            <span>commited</span>
          </div>
          <div>
            Fixed Issue
          </div>
        </div>
        <div style="margin-top: 10px;">3 months ago</div>
      </div>
    </div>
    <div class="pipeline-step-footer">
      <button class="btn btn-light" style="width: 100%; text-align: center;">
        <a href="#" class="minfy-stretched-link" style="width: 100%;">View All Activities</a>
      </button>
    </div>
  </div>
</div>