<div class="main_container">
  <div class="container">
    <div class="container-header space-between">
      <div>
        Build Files
      </div>
      <div>
        <i class="fa fa-times" style="cursor: pointer;" aria-hidden="true" (click)="hideModel()"></i>
      </div>
    </div>
    <div class="container-body">
      <div class="files_container">
        <div *ngFor="let file of files" class="file_container">
          <div class="flex-start">
            <i class="fas fa-file" style="font-size: 20px;"></i>
            <span>
              {{ file['file_name'] }}
            </span>
          </div>
          <div class="flex-start">
            <span>{{ file['save_path'] }}/</span>
            <button class="btn btn-success-soft" (click)="download(file['file_path'])">
              <i class="fas fa-download"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>