import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

declare let window: any;

@Component({
  selector: 'minfyui-change-request-ui',
  templateUrl: './changerequest.component.html',
  styleUrls: ['./changerequest.component.css'],
})
export class ChangerequestComponent implements OnInit {
  @Input('instance') instance: any;
  instances: any = ['i-asfds123'];
  @Input('accountId') accountId: any;
  @Input('region') region: any;
  @Input('type') type: string;
  @Output('hideModal') hideModal = new EventEmitter<any>();
  jsonObjs: any = {
    CRFlow: {
      type: 'CRFlow',
      components: {
        storage: {
          volumes: [
            {
              current: {
                type: 'gp3',
                size: '200',
              },
              required: {
                type: 'gp3',
                size: '200',
              },
              name: '',
              id: 'v-124324312432',
              existing: true,
            },
            {
              existing: false,
              name: 'New EBS Volume',
              id: '',
              type: 'gp3',
              size: '200',
            },
          ],
          selected: false,
        },
        compute: {
          current: {
            instanceType: 't2.micro',
            ram: '1',
            cpu: '1',
          },
          required: null,
          selected: true,
        },
      },
      metadata: {
        emails: [],
        region_id: '',
        account_id: '',
        resource_ids: [],
        more_details: '',
      },
    },
    'New Instance': {
      type: 'New Instance',
      components: {
        storage: {
          volumes: [],
          selected: false,
        },
        compute: {
          selected: false,
          instances: [],
        },
      },
      metadata: {
        emails: [],
        region_id: '',
        account_id: '',
        resource_ids: [],
        more_details: '',
      },
    },
  };
  changeRequestObj: any = {
    type: '',
    components: {
      storage: {
        volumes: [
          {
            current: {
              type: 'gp3',
              size: '200',
            },
            required: {
              type: 'gp3',
              size: '200',
            },
            name: '',
            id: 'v-124324312432',
            existing: true,
          },
          {
            existing: false,
            name: 'New EBS Volume',
            id: '',
            type: 'gp3',
            size: '200',
          },
        ],
        selected: false,
      },
      compute: {
        current: {
          instanceType: 't2.micro',
          ram: '1',
          cpu: '1',
        },
        required: null,
        selected: true,
      },
    },
    metadata: {
      emails: [],
      region_id: '',
      account_id: '',
      resource_ids: [],
      more_details: '',
    },
  };
  currentType: string = '';
  stepCount: number = 0;
  currentStepObj: any = {
    display: true,
    stepCount: 0,
  };
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';

  ngOnInit(): void {
    this.changeRequestObj = this.jsonObjs[this.type];
    if (this.type == 'CRFlow') {
      this.instance['instanceType'] = this.instance['instanceName'];
      this.changeRequestObj['metadata']['resource_ids'].push('i-1231safdsfsf');
      this.changeRequestObj['components']['compute']['current'] = {
        instanceType: this.instance['instanceType'],
        ram: this.instance['ram'],
        cpu: this.instance['cpu'],
      };
      this.fetchCRs();
    }
    this.changeRequestObj['metadata']['region_id'] = this.region;
    this.changeRequestObj['metadata']['account_id'] = this.accountId;
    let selectedClientData = JSON.parse(localStorage.getItem('clientList'));
    let client_id;
    if (this.urlPrefix == 'admin') {
      client_id = selectedClientData.find((client: any) => {
        return client.emailId == localStorage.getItem('selectedClientList');
      })['emailId'];
    } else {
      client_id = localStorage.getItem('eId');
    }
    this.changeRequestObj['metadata']['emails'] = [client_id, ''];
    this.setMainContainerHeight();
  }

  validate() {
    if (this.currentStepObj['stepCount'] == 0) {
      if (this.getSelectedComponents().length > 0) {
        return true;
      } else {
        return false;
      }
    }
    if (this.currentStepObj['stepCount'] == 1) {
      if (this.changeRequestObj['components']['compute']['required'] == null) {
        return false;
      } else {
        return true;
      }
    }
    if (this.currentStepObj['stepCount'] == 3) {
      if (
        this.changeRequestObj['metadata']['emails'].filter(
          (email: any) => email != ''
        ).length == 0
      ) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  getSelectedComponents() {
    return Object.keys(this.changeRequestObj['components']).filter(
      (component: any) => {
        return this.changeRequestObj['components'][component].selected;
      }
    );
  }

  hideModalCaller() {
    this.hideModal.emit(true);
  }

  setMainContainerHeight(height?: number) {
    let main_element: any = document.querySelector('.main_contaniner');
    let child_element: any = document.querySelector(
      '.minfy-change-request-container'
    );
    setTimeout(() => {
      if (height) {
        let header: any = document.querySelector('.change_request_header');
        let footer: any = document.querySelector('.change_request_footer');
        let calc = header.offsetHeight + footer.offsetHeight + height;
        main_element.style.maxHeight = calc + 'px';
        main_element.style.height = calc + 'px';
        return;
      }

      main_element.style.maxHeight = child_element.offsetHeight + 'px';
      main_element.style.height = child_element.offsetHeight + 'px';
    }, 1);
  }

  scrollloading: boolean = false;
  uuid: string;
  checkForMore(event: any) {
    if (this.scrollloading) {
      return;
    }
    let scrollHeight = event.target.scrollHeight;
    let scrollTop = event.target.scrollTop;
    let offsetHeight = event.target.offsetHeight;
    if (Math.ceil(scrollTop + offsetHeight) > scrollHeight - 300) {
      this.setScrollLoading(true);
      this.uuid = window.uuidv4();
    }
  }

  setScrollLoading(event: boolean) {
    this.scrollloading = event;
  }

  changePage(count: number) {
    if (
      count == 1 &&
      count < this.stepCount &&
      !this.changeRequestObj['components']['compute']['selected']
    ) {
      count--;
    }
    if (
      count == 2 &&
      count < this.stepCount &&
      !this.changeRequestObj['components']['storage']['selected']
    ) {
      count--;
    }
    if (
      count == 1 &&
      count > this.stepCount &&
      !this.changeRequestObj['components']['compute']['selected']
    ) {
      count++;
    }
    if (
      count == 2 &&
      count > this.stepCount &&
      !this.changeRequestObj['components']['storage']['selected']
    ) {
      count++;
    }
    this.currentStepObj['stepCount'] = count;
    this.currentStepObj['display'] = false;
    setTimeout(() => {
      this.currentStepObj['display'] = true;
    }, 1);
    setTimeout(() => {
      this.stepCount = count;
      this.setMainContainerHeight();
    }, 200);
  }

  getSelectedTypes() {
    return Object.keys(this.currentStepObj['components']).filter((key: any) => {
      return this.currentStepObj['components'][key]['selected'];
    });
  }

  loadingCR: boolean = false;
  request_id: string;
  loadingCRs: boolean = false;
  crs: any = [];

  async fetchCRs() {
    this.loadingCRs = true;
    let data = {
      action: 'list_change_requests',
      id_token: localStorage.getItem('idT'),
      account_id: this.accountId,
      region_id: this.region,
      resource_id: this.changeRequestObj['metadata']['resource_ids'][0],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.crs = result.crs;
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.setMainContainerHeight();
    this.loadingCRs = false;
  }

  async saveAndSendOTP() {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const account = selectedAccountData.data;
        if (!account.jira_org_id || !account.jira_project_id) {
          this.notifier.alert(
            'Info',
            '',
            'Managed Services support via SWAYAM is not enabled for your account. Please contact the admin.',
            'info',
            5000
          );
        } else {
          this.loadingCR = true;
          if (this.changeRequestObj['components']['compute']['selected']) {
            Object.keys(
              this.changeRequestObj['components']['compute']['required']
            ).forEach((key: any) => {
              if (key == 'cpu' || key == 'ram') {
                this.changeRequestObj['components']['compute']['required'][
                  key
                ] =
                  this.changeRequestObj['components']['compute']['required'][
                    key
                  ].toString();
              }
            });
          } else {
            delete this.changeRequestObj['components']['compute'];
          }
          if (!this.changeRequestObj['components']['storage']['selected']) {
            delete this.changeRequestObj['components']['storage'];
          }

          this.changeRequestObj['metadata']['emails'] = this.changeRequestObj[
            'metadata'
          ]['emails'].filter((email: string) => {
            return email != '';
          });

          this.setMainContainerHeight();

          let data = {
            action: 'ec2_change_request',
            id_token: localStorage.getItem('idT'),
            cr_data: this.changeRequestObj,
            group_name: localStorage.getItem('selectedAccountGroup'),
            client_id: localStorage.getItem('selectedClientList'),
            account_id: this.accountId,
            region_id: this.region,
            jira_project_id: account.jira_project_id,
            jira_organization_id: account.jira_org_id,
            login_user_name: localStorage.getItem('un'),
            login_user_email: localStorage.getItem('eId'),
            resource_ids: this.changeRequestObj['metadata']['resource_ids'],
          };

          let header = {
            Authorization: localStorage.getItem('t'),
          };
          let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

          let result = await this.apiServer.postDataPromis(
            apiURL,
            data,
            header
          );

          if (result.status == '1' || result.s == '1') {
            this.request_id = result['request_id'];
            this.changePage(4);
          } else {
            this.notifier.alert('Info', '', result.error_message, 'info', 5000);
          }
          this.loadingCR = false;
        }
      }
    } catch (err) {}
  }

  async validateOTP() {
    this.loadingCR = true;
    let data = {
      action: 'ec2_change_request_validate',
      id_token: localStorage.getItem('idT'),
      request_id: this.request_id,
      otp: this.changeRequestObj['otp'],
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.message, 'success', 5000);
      this.hideModalCaller();
    } else {
      this.notifier.alert('Success', '', result.error_message, 'success', 5000);
    }
    this.loadingCR = false;
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  getCRIDS() {
    return this.crs
      .map((cr: any) => {
        return cr['cr_id'];
      })
      .join(',');
  }
}
