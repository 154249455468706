<div class="step_2_container">
  <div class="font-bold" style="margin-bottom: 10px;">Details</div>
  <div class="row">
    <div class="form-group">
      <label>Emails</label>
      <div class="additional_contacts">
        <div class="additional_contact" style="width: auto" *ngFor="
                    let contact of emails['emails'];
                    let i = index;
                    trackBy: trackByFn
                  ">
          <input type="text" name="contact_value" class="form-control" *ngIf="emails['emails'].length - 3 < i; i"
            (keyup)="checkForLast(contact, i)" [(ngModel)]="emails['emails'][i]" />
          <input type="text" name="contact_value" class="form-control" *ngIf="emails['emails'].length - 3 >= i"
            [(ngModel)]="emails['emails'][i]" />
          <div>
            <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add" *ngIf="emails['emails'].length == i + 1"
              (click)="addAdditionalContact()"></i>
          </div>
          <div>
            <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove" *ngIf="emails['emails'].length > 1"
              (click)="deleteSelectedAdditionalContact(i)"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label style="margin: 8px 0px;">More Details</label>
      <textarea class="form-control" name="minfy_more_details" [(ngModel)]="emails['more_details']"
        style="resize: none; min-height: 100px; width: 100%;" id="" cols="30" rows="10"></textarea>
    </div>
  </div>
</div>