import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from './../../../_services/notifier.service';
declare var window: any;
@Component({
  templateUrl: './sg.component.html',
})
export class sgComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  sgTabs: any = [];
  sgList: any = [];

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;

  jobDetails: any = null;
  scanAccess: boolean = true;
  funRef: any;

  headers: any = [
    {
      id: 'sg_name',
      name: 'Security Group Name',
      filter: true,
      click: 'loadAttachedInstances',
    },
    {
      id: 'sg_id',
      name: 'Security Group ID',
      filter: true,
    },
    {
      id: 'instance_attched',
      name: 'Instances(EC2/RDS)',
    },
  ];

  headersNested: any = [
    {
      id: 'sg_name',
      name: 'Security Group Name',
      filter: true,
      click: 'loadNested',
    },
    {
      id: 'sg_id',
      name: 'Security Group ID',
      filter: true,
    },
    {
      id: 'instance_attched',
      name: 'Instances(EC2/RDS)',
    },
  ];

  portwiseheader: any = [
    {
      id: 'port_range',
      name: 'Port Range',
      click: 'loadAttachedInstances',
    },
    {
      id: 'total_sgs',
      name: 'Total Security Groups',
    },
  ];

  attachedInstancesHeader: any = [
    {
      id: 'instance_name',
      name: 'Instance Name',
    },
    {
      id: 'instance_id',
      name: 'Instance ID',
    },
    {
      id: 'service',
      name: 'Service',
    },
  ];
  currentTab: String;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    //this.load();
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      console.log('ebsComponent', msg);

      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    this.funRef = {
      loadAttachedInstances: this.loadAttachedInstances,
    };

    window.ref = this;
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    let data = {
      action: 'sg_summary',
      region_name: this.regionId,
      account_id: this.accountId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/sg`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    this.sgTabs = [];
    if (result.status == '1' && !result.error_message) {
      result['open_to_world_sg']['metadata'] = {
        str: 'Open To World (SG Wise)',
        id_str: '#' + result['open_to_world_sg']['onclick'],
      };
      result['open_to_world_port']['metadata'] = {
        str: 'Open To World (Port Wise)',
        id_str: '#' + result['open_to_world_port']['onclick'],
      };
      this.sgTabs.push(result['open_to_world_sg']);
      this.sgTabs.push(result['open_to_world_port']);
      if (this.sgTabs.length > 0) {
        this.getSecurityGroups(this.sgTabs[0].onclick);
      }
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async getSecurityGroups(clickID: String) {
    this.currentTab = clickID;
    this.notifier.loading(true);
    let data = {
      action: clickID,
      region_name: this.regionId,
      account_id: this.accountId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/sg`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.sgList = result[`${clickID}`];
    } else {
      this.sgList = [];
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  getHeader(tabName?: String): any {
    if (tabName == 'open_to_world_sg') {
      return this.headers;
    } else if (tabName == 'open_to_world_port') {
      return this.portwiseheader;
    } else if (this.currentTab == 'open_to_world_sg') {
      return this.attachedInstancesHeader;
    } else {
      return this.headersNested;
    }
  }

  getHeaderNested() {
    return this.attachedInstancesHeader;
  }

  createJobTrigger: boolean = false;

  createJob(event: any) {
    this.createJobTrigger = true;
  }

  hideCreateJob(event: any) {
    this.createJobTrigger = null;
  }

  hideNested(event: any) {}

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  async loadAttachedInstances(ref: any, param: any) {
    if (ref.currentTab != 'open_to_world_port' && param.showNested) {
      param['showNested'] = false;
      return;
    } else if (ref.currentTab == 'open_to_world_port' && param.showNested) {
      param.showNested = false;
      return;
    } else if (ref.currentTab == 'open_to_world_port' && !param.showNested) {
      param.showNested = true;
      param.attachedInstances = param.sg_details;
      return;
    }

    if (Number(param.instance_attched) == 0) {
      ref.notifier.alert(
        'Info',
        '',
        'No Attached Instances Found',
        'info',
        5000
      );
      return;
    }
    ref.notifier.loading(true);
    let data = {
      action: 'attched_instances',
      region_name: ref.regionId,
      account_id: ref.accountId,
      sg_id: param.sg_id,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/compliance/sg`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      //this.attachedInstances = result.instance_details;

      for (let i = 0; i < result.instance_details.length; i++) {
        if (result.instance_details[i].sg_details) {
          result.instance_details[i].nestedData =
            result.instance_details[i].sg_details;
          //result.instance_details[i] = result.instance_details;
        }
      }
      param.attachedInstances = result.instance_details;

      param.showNested = true;
    } else {
      ref.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    ref.notifier.loading(false);
  }

  nestedLoader() {
    return this.load3rdLevelData;
  }

  async load3rdLevelData(ref: any, param: any) {
    ref = window.ref;
    if (Number(param.instance_attched) == 0) {
      ref.notifier.alert(
        'Info',
        '',
        'No Attached Instances Found',
        'info',
        5000
      );
      return;
    }
    ref.notifier.loading(true);
    let data = {
      action: 'attched_instances',
      region_name: ref.regionId,
      account_id: ref.accountId,
      sg_id: param.sg_id,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/compliance/sg`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      //this.attachedInstances = result.instance_details;

      for (let i = 0; i < result.instance_details.length; i++) {
        if (result.instance_details[i].sg_details) {
          result.instance_details[i].nestedData =
            result.instance_details[i].sg_details;
          //result.instance_details[i] = result.instance_details;
        }
      }
      param.nestedData = result.instance_details;

      param.showNested = true;
    } else {
      ref.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    ref.notifier.loading(false);
  }
}
