import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';

import { APIService } from './../../../api/api.service';
import { NotifierService } from './../../../_services/notifier.service';

declare let flatpickr: any;
declare let $: any;

@Component({
  templateUrl: './supportplan.component.html',
})
export class supportPlanComponent implements OnInit, OnDestroy {
  uid = localStorage.getItem('t');
  t = localStorage.getItem('t');
  ut = localStorage.getItem('ut');
  eId = localStorage.getItem('eId');

  loading = true;
  supportPlanType: any;
  discount: any;
  dateRange: any;

  showDetails = false;
  accountId = '';
  region = '';
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';

  currentMessage: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.accountId = localStorage.getItem('accountId');
    this.region = localStorage.getItem('regionId');

    $('#supportPlanTypeSelect').selectpicker();

    flatpickr('#dateRange', {
      dateFormat: 'd-m-Y',
      mode: 'range',
      showMonths: 2,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.dateRange = dateStr;
      },
    });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.region = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = { lid: this.accountId, action: 'fetchsupportplan' };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/prod/msopsusers/billing/enablesupportplan`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      if (result.msg == 'No Data Found.') {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      } else {
        this.discount = Number(result.discount);
        this.dateRange = result.start_date + ' to ' + result.end_date;
        this.supportPlanType = result.support_plan;
        $('#supportPlanTypeSelect').val(result.support_plan);
        setTimeout(() => {
          $('#supportPlanTypeSelect').selectpicker('refresh');
        }, 100);
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async enableSupport() {
    this.notifier.loading(true);
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      lid: this.accountId,
      action: 'add',
      support_plan: $('#supportPlanTypeSelect').val(),
      start_date: this.dateRange.split(' to ')[0],
      end_date: this.dateRange.split(' to ')[1],
      discount: this.discount,
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://api.swayam.cloud/prod/msopsusers/billing/enablesupportplan`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }
}
