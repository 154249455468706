<div class="card">
  <h5 class="card-header">temp header sp detials</h5>
  <div
    style="
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      margin: 10px;
    "
  >
    <select
      style="width: 300px"
      class="form-select"
      data-live-search="true"
      (change)="onAccountSelectionChange($event)"
    >
      <option value="">Select month range</option>
      <option [value]="account" *ngFor="let account of dummyData">
        {{ account }}
      </option>
    </select>

    <input
      class="datetimepicker"
      type="text"
      placeholder="select a month"
      style="padding: 6px"
    />
    <button class="btn btn-primary-soft" (click)="load()">Refresh</button>
  </div>
  <div class="card-body">
    <table class="table table-docs table-hover" style="position: relative">
      <thead></thead>
      <tbody></tbody>
    </table>
  </div>
</div>
