import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from './../../../_services/notifier.service';
declare let window: any;
@Component({
  templateUrl: './advisor.component.html',
  styleUrls: ['./advisor.component.css'],
})
export class advisorComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  summary: any = {};
  costOptimizingList: any = [];
  securityList: any = [];
  tabSummary: any = {};
  faultToleranceList: any = [];
  performanceList: any = [];
  serviceLimitList: any = [];
  createTicket: any;
  current_filter: string = 'all';
  regionId: any;
  accountId: any;
  currentMessage: any;

  funRef: any;

  headers: any = [
    {
      id: 'name',
      name: 'Name',
      click: 'readMore',
    },
    {
      id: 'timestamp',
      name: 'Timestamp',
    },
    {
      id: 'description',
      name: 'Description',
      overflow: true,
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    this.funRef = {
      readMore: this.readMore,
    };
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  changeFilter(event: any) {
    this.current_filter = event.target.value;
  }

  filterData(data: any) {
    if (this.current_filter == 'all') {
      return data;
    }
    return data.filter((check: any) => {
      return check.status == this.current_filter;
    });
  }

  async load() {
    await this.loadSummary();
    await this.loadCostOptimizaing();
  }

  async loadSummary(nextToken?: any) {
    if (!nextToken) {
      this.notifier.loading(true);
      this.summary = [];
    }
    let data = {
      action: 'get_trusted_advisor_checks_summary',
      region_name: this.regionId,
      account_id: this.accountId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Compliance.Advisor', 'Summary Fetched');
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.summary = result.summary;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    if (result.nextToken) {
      this.loadSummary(result.nextToken);
    }
    if (!nextToken) {
      this.notifier.loading(false);
    }
  }

  async loadCostOptimizaing(nextToken?: any) {
    if (!nextToken) {
      this.notifier.loading(true);
      this.costOptimizingList = [];
    }
    let data = {
      action: 'get_trusted_advisor_checks',
      region_name: this.regionId,
      account_id: this.accountId,
      check_type: 'cost_optimizing',
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'Compliance.Advisor',
      'CostOptimizing Fetched'
    );
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.costOptimizingList = result.checks;
      this.tabSummary = result.summary;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    if (result.nextToken) {
      this.loadCostOptimizaing(result.nextToken);
    }
    if (!nextToken) {
      this.notifier.loading(false);
    }
  }

  overFlowText(text: string): string {
    if (text.length > 100) {
      return text.toString().slice(0, 100) + '...';
    }
    return text.toString();
  }

  readMore(ref: any, param: any) {
    if (param.showNested) {
      param.showNested = false;
    } else {
      param.showNested = true;
    }
  }

  async loadSecurity(nextToken?: any) {
    if (!nextToken) {
      this.notifier.loading(true);
      this.securityList = [];
    }
    let data = {
      action: 'get_trusted_advisor_checks',
      region_name: this.regionId,
      account_id: this.accountId,
      check_type: 'security',
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Compliance.Advisor', 'Security Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.securityList = result.checks;
      this.tabSummary = result.summary;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    if (result.nextToken) {
      this.loadSecurity(result.nextToken);
    }
    if (!nextToken) {
      this.notifier.loading(false);
    }
  }
  async loadFaultTolerance(nextToken?: any) {
    if (!nextToken) {
      this.notifier.loading(true);
      this.faultToleranceList = [];
    }
    let data = {
      action: 'get_trusted_advisor_checks',
      region_name: this.regionId,
      account_id: this.accountId,
      check_type: 'fault_tolerance',
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'Compliance.Advisor',
      'Fault-Tolerance Fetched'
    );
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.faultToleranceList = result.checks;
      this.tabSummary = result.summary;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    if (result.nextToken) {
      this.loadFaultTolerance(result.nextToken);
    }
    if (!nextToken) {
      this.notifier.loading(false);
    }
  }
  async loadPerformace(nextToken?: any) {
    if (!nextToken) {
      this.notifier.loading(true);
      this.performanceList = [];
    }
    let data = {
      action: 'get_trusted_advisor_checks',
      region_name: this.regionId,
      account_id: this.accountId,
      check_type: 'performance',
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Compliance.Advisor', 'Performance Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.performanceList = result.checks;
      this.tabSummary = result.summary;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    if (result.nextToken) {
      this.loadPerformace(result.nextToken);
    }
    if (!nextToken) {
      this.notifier.loading(false);
    }
  }
  async loadServiceLimits(nextToken?: any) {
    if (!nextToken) {
      this.notifier.loading(true);
      this.serviceLimitList = [];
    }
    let data = {
      action: 'get_trusted_advisor_checks',
      region_name: this.regionId,
      account_id: this.accountId,
      check_type: 'service_limits',
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'Compliance.Advisor',
      'ServiceLimits Fetched'
    );
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.serviceLimitList = result.checks;
      this.tabSummary = result.summary;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    if (result.nextToken) {
      this.loadServiceLimits(result.nextToken);
    }
    if (!nextToken) {
      this.notifier.loading(false);
    }
  }

  createTicketTrigger(param: any) {
    console.log(param);
    this.createTicket = param;
  }

  hideCreateTicket(event: any) {
    this.createTicket = null;
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
