import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../api/api.service';
import { NotifierService } from './../../../_services/notifier.service';
declare var hljs: any;
declare var flatpickr: any;
declare let window: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './config.component.html',
})
export class configComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  view_type: String = 'counts';
  configList: any = [];
  countSelected: any = null;
  resourcesList: any = [];
  resourceType: String;
  resourcecIdentifier: String;
  resourcesNextToken: string;
  innerData: any;
  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  funRef: any;
  dateRange: string;
  resourceHistory: any;

  headers: any = [
    {
      id: 'resourceType',
      name: 'Resource Type',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'load_resources',
    },
    {
      id: 'count',
      name: 'Count',
    },
  ];

  deletedResources: boolean = false;

  resources_headers: any = [
    {
      id: 'resourceId',
      name: 'Resource Identifier',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'load_resource_timeline',
    },
    {
      id: 'resourceType',
      name: 'Resource Type',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      console.log('ebsComponent', msg);

      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load_count();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load_count();
      }
    });

    setTimeout(() => {
      let start_date = moment().startOf('month').format('DD-MMM-YYYY');
      let end_date = moment().startOf('day').format('DD-MMM-YYYY');

      this.dateRange = start_date + ' to ' + end_date;
      flatpickr('.datetimepicker', {
        dateFormat: 'd-M-Y',
        mode: 'range',
        maxDate: new Date(),
        showMonths: 2,
        onChange: (selectedDates: any, dateStr: any, instance: any) => {
          if (!dateStr.includes('to')) return;
          this.dateRange = dateStr;
          this.load_resource_timeline(this, this.innerData);
        },
      });
    }, 500);

    this.funRef = {
      load_resources: this.load_resources,
      load_resource_timeline: this.load_resource_timeline,
    };
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load_count() {
    this.notifier.loading(true);
    this.view_type = 'counts';
    let data = {
      action: 'config_get_discovered_resource_counts',
      region_name: this.regionId,
      account_id: this.accountId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Compliance.Cofig', 'Count Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.configList = result.resource_counts;
    } else {
      let error = result.error;

      if (!error) {
        error = result.error_message;
      }

      if (!error) {
        error = result.msg;
      }

      if (error) {
        error = 'Some Error!';
      }

      this.notifier.alert('Info', '', error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  getDeletedResources(event: any) {
    if (event.target.checked) {
      this.deletedResources = true;
    } else {
      this.deletedResources = false;
    }
    this.load_resources(this, this.countSelected);
  }

  loadMoreResources() {
    this.load_resources(this, this.countSelected);
  }

  async load_resources(ref: any, id: any) {
    ref.notifier.loading(true);
    ref.view_type = 'resources';
    ref.resourceType = id.resourceType;
    ref.countSelected = id;
    if (!ref.resourcesNextToken) {
      ref.resourcesList = [];
    }
    let data = {
      action: 'config_list_discovered_resources',
      region_name: ref.regionId,
      account_id: ref.accountId,
      resource_type: ref.countSelected.resourceType,
      include_deleted_resources: ref.deletedResources.toString(),
      next_token: ref.resourcesNextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Compliance.Advisor', 'Resources Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/compliance`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      ref.resourcesList = [...ref.resourcesList, ...result.resources];
    } else {
      ref.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.next_token && result.next_token != '') {
      ref.resourcesNextToken = result.next_token;
    } else {
      ref.resourcesNextToken = null;
    }

    ref.notifier.loading(false);
  }
  async load_resource_timeline(ref: any, id: any) {
    ref.notifier.loading(true);
    ref.view_type = 'resource_timeline';
    ref.resourcecIdentifier = id.resourceId;
    ref.innerData = id;
    let start_date;
    let end_date;
    if (!(this.dateRange && this.dateRange.includes('to'))) {
      start_date = moment().startOf('month').format('YYYY-MM-DD');
      end_date = moment().startOf('day').format('YYYY-MM-DD');
    } else {
      let dRange = this.dateRange.toString().split(' to ');
      start_date = moment(dRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD');
      end_date = moment(dRange[1], 'DD-MMM-YYYY').format('YYYY-MM-DD');
    }
    let data = {
      action: 'config_get_resource_history',
      region_name: ref.regionId,
      account_id: ref.accountId,
      resource_type: ref.resourceType,
      resource_id: ref.resourcecIdentifier,
      start_time: start_date,
      end_time: end_date,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'Compliance.Advisor',
      'Resource-TimeLine Fetched'
    );
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/compliance`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      ref.innerData['resource_history'] = result.resource_history;
      ref.resourceHistory = result.resource_history;
    } else {
      ref.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    ref.notifier.loading(false);
  }

  goBackToCounts() {
    this.view_type = 'counts';
    this.resourcesNextToken = '';
  }
  goBackToResources() {
    this.view_type = 'resources';
    this.resourcesNextToken = '';
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  showDateFormat(d: string, format: string) {
    return moment(d).format(format);
  }

  parseJson(d: string) {
    var hlt = hljs.highlight('json', JSON.stringify(JSON.parse(d), null, 4));
    return hlt.value;
  }
}
