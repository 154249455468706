<div class="leftmenu">
  <div class="offcanvas-body px-0">



    <button class="menuCloseButton" (click)="hideLeftMenu()" id="menuClosebtn"
      style=" background:none; border:none; margin-left: 15px; margin-bottom:5px">
      <i class="fa fa-arrow-circle-right fa-rotate-180"></i>
    </button>

    <button class="" (click)="showLeftMenu()" id="menuShowbtn"
      style="display:none; background:none; border:none; margin-left: 15px; margin-bottom:5px">
      <i class="fa fa-arrow-circle-right"></i>
    </button>


    <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu">

      <li class="dropdown" *ngIf="checkIfAvaForUser('cbs')">
        <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown"
          aria-expanded="false">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Anomalies.svg"
            style="width: 40px;" alt=""> Pipelines
        </a>
        <ul class="dropdown-menu text-small shadow sub-menu" aria-labelledby="dropdown">
          <li *ngIf="checkIfAvaForUser('cbs:top10bills')">
            <a class="dropdown-item" routerLink="/dev/pipelines">
              <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Dashboard.svg"
                style="width: 40px" alt="" /><span>Pipelines</span>
            </a>
          </li>
        </ul>
      </li>
      <!--<li class="nav-item" *ngIf="checkIfAvaForUser('BackupReport')">
    <a routerLink="/backupreport" class="nav-link text-truncate" title="Backup Report">
      <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Backup.svg"
           style="width: 40px;" alt=""> Backup Report
    </a>
  </li>-->


    </ul>
  </div>
</div>

<div class="" style="position: fixed; top: 0; width: 100%; z-index: 99">
  <div class="row">
    <div class="col" style="padding-right: 0">
      <nav class="navbar navbar-expand-lg top-menu">
        <div class="container-fluid">
          <div class="nav-item" style="width: 175px">
            <a routerLink="/dash/" class="nav-link text-truncate" style="padding: 0">
              <img style="width: 115px" src="../assets/img/swayam_logo_v3.0.svg" />
            </a>
          </div>

          <form class="flex-grow-1" name="topsearchbox" style="position: relative" id="topsearchbox">
            <div class="input-group">
              <input name="searchValue" type="text" class="form-control" [(ngModel)]="searchValue" placeholder="Search"
                aria-label="Search" aria-describedby="btnGroupAddon" (input)="onSearchChange()" (focus)="showSearch()"
                maxlength="20" />
              <!--(focusout)="hideSearch()"-->
              <div class="input-group-text" id="btnGroupAddon">
                <i class="icon bi-search" aria-hidden="true"></i>
              </div>
            </div>
          </form>

          <div id="searchbackground" (click)="hideSearch()"
            *ngIf="showSearchResult && searchValue && searchValue.length > 1"></div>
          <div id="searchResult" *ngIf="showSearchResult && searchValue && searchValue.length > 1">
            <div class="container">
              <div class="row">
                <div class="col">
                  <h3>Search Results for '{{ searchValue }}'</h3>
                </div>
              </div>
              <div class="row">
                <!--<div class="col-4" style="padding:0">
                  <ul class="list-group">
                    <li class="list-group-item" *ngFor="let i of searchData.category">{{i.name}}</li>
                 
                  </ul>
                </div>-->
                <div class="col-12">
                  <ul class="list-group">
                    <li class="list-group-item" *ngFor="let i of searchResults">
                      <div (click)="goTO(i.link)" style="cursor: pointer">
                        <img src="{{ i.icon }}" class="icon" style="height: 25px" />
                        {{ i.name }}
                        <p>
                          {{ i.desc }}
                        </p>
                      </div>
                    </li>
                    <!--active-->
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
              <ng-container *ngIf="!developersAccess">
                <li class="nav-item dropdown searchbox" id="accountGroups" *ngIf="
                    accountGroups &&
                    accountGroups.list &&
                    accountGroups.list.length > 0
                  " style="border-left: 1px solid #fff">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    {{ accountGroups.selected }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <div class="flex-grow-1">
                        <div class="input-group">
                          <i _ngcontent-bob-c49="" aria-hidden="true" class="icon bi-search"
                            style="position: absolute; right: 10px"></i>
                          <input type="text" style="
                              border-radius: 0;
                              margin-top: -10px;
                              background: transparent;
                            " class="form-control" placeholder="Search" aria-label="Search"
                            aria-describedby="btnGroupAddon" [(ngModel)]="accountGroupSearchTerm" />
                        </div>
                      </div>
                      <content *ngFor="let i of accountGroups.list">
                        <div *ngIf="
                            searchOnList(i.groupName, accountGroupSearchTerm)
                          " (click)="selectAccountGroup(i)">
                          <span class="dropdown-item">{{ i.groupName }}</span>
                        </div>
                      </content>
                    </li>
                  </ul>
                </li>

                <li class="nav-item dropdown searchbox" id="clientNames" *ngIf="
                    clientNames && clientNames.list && clientNames.list.length > 0
                  ">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    {{ clientNames.selected }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <div class="flex-grow-1">
                        <div class="input-group">
                          <i _ngcontent-bob-c49="" aria-hidden="true" class="icon bi-search"
                            style="position: absolute; right: 10px"></i>
                          <input type="text" style="
                              border-radius: 0;
                              margin-top: -10px;
                              background: transparent;
                            " class="form-control" placeholder="Search" aria-label="Search"
                            aria-describedby="btnGroupAddon" [(ngModel)]="clientNameSearchTerm" />
                        </div>
                      </div>
                      <content *ngFor="let i of clientNames.list">
                        <div *ngIf="searchOnList(i.cmpName, clientNameSearchTerm)" (click)="selectclientName(i)">
                          <span class="dropdown-item">{{ i.cmpName }}</span>
                        </div>
                      </content>
                    </li>
                  </ul>
                </li>

                <li class="nav-item dropdown searchbox" id="accountIds" *ngIf="accountIds">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    {{ accountIds.selected }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <div class="flex-grow-1">
                        <div class="input-group">
                          <i _ngcontent-bob-c49="" aria-hidden="true" class="icon bi-search"
                            style="position: absolute; right: 10px"></i>
                          <input type="text" style="
                              border-radius: 0;
                              margin-top: -10px;
                              background: transparent;
                            " class="form-control" placeholder="Search" aria-label="Search"
                            aria-describedby="btnGroupAddon" [(ngModel)]="accountIdSearchTerm" />
                        </div>
                      </div>
                      <content *ngFor="let i of accountIds.list">
                        <div *ngIf="searchOnList(i.accountName, accountIdSearchTerm)" (click)="selectAccountId(i)">
                          <span class="dropdown-item">{{ i.accountName }} ({{ i.accountId }})</span>
                        </div>
                      </content>
                    </li>
                  </ul>
                </li>
              </ng-container>

              <li class="nav-item dropdown searchbox" id="regions" style="width: 250px">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ selectedRegion }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li *ngFor="let i of regions">
                    <span class="dropdown-item" (click)="setRegion(i)">{{
                      i.name
                      }}</span>
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown">
                <a class="d-block text-decoration-none dropdown-toggle" id="dropdownUser1"
                  style="color: #fff; margin-top: 10px" data-bs-toggle="dropdown" aria-expanded="true">
                  <!--<img src="../../assets/img/passport.png" class="rounded-circle" width="32" height="32" />-->
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <span style="color: white; font-family: inherit; margin: 0px 10px">{{ username }}</span>
                </a>
                <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1" style="
                    position: absolute;
                    inset: 0px auto auto 0px;
                    transform: translate(-25px, 34px);
                  " data-popper-placement="bottom-start">
                  <!--<li><a class="dropdown-item" href="#">Settings</a></li>
                  <li><a class="dropdown-item" href="#">Profile</a></li>-->
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <button class="dropdown-item" (click)="logout()">
                      Sign out
                    </button>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="dropdown text-end"></div>

            <div style="width: 75px"></div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>



<div class="content  min-vh-100 rightcontent">
  <router-outlet></router-outlet>
</div>




<!--<router-outlet></router-outlet>-->