<div class="alarm_screen" style="box-shadow: 1px 3px 1px #80808024">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform: capitalize">
        {{ action == "update" ? "Update" : "Add" }} CFRC
      </h4>
    </div>

    <div class="overflow-auto">

      <div class="alert alert-primary flex-between" *ngIf="action !== 'update'">
        <div>Apply discount for all irrespective of region and usage type</div>
        <label class="switch">
          <input type="checkbox" [(ngModel)]="selectAll" (change)="onSelectAllChange()" />
          <span class="slider round"></span>
        </label>
      </div>
      <!-- Original Form (shown when Select All is unchecked or action is not update) -->
      <div class="alarm_body" style="padding: 1px 25px 5px 25px">

        <div class="row" *ngIf="action !== 'update'">
          <div class="flex-between">
            <label>Linked Account List</label>
            <div class="flex-start filters">
              <div class="flex-start">
                <input type="checkbox" id="select_all_accounts" (change)="selectAllchilds($event)" />
                <label for="select_all_accounts">Select All</label>
              </div>
            </div>
          </div>

          <select class="form-control" id="linkedAccounts" [(ngModel)]="ri.linkedAccounts" data-live-search="true"
            multiple required>
            <option *ngFor="let master of linkedAccounts['list']" [value]="master.accountId">
              {{ master.accountId }} ({{master.accountName}})
            </option>
          </select>
        </div>
        <div [ngStyle]="{'display': (!selectAll) ? 'block' : 'none'}">
          <!-- Region Selection -->
          <div class="row" *ngIf="action !== 'update'">
            <div class="flex-between">
              <label>Region</label>
              <div class="flex-start filters">
                <div class="flex-start">
                  <input type="checkbox" id="select_all_regions" (change)="selectAllRegions($event)" />
                  <label for="select_all_regions">Select All</label>
                </div>
                <div class="flex-start" *ngIf="regions_reg && regions_reg.length > 0">
                  <span class="nav-link" (click)="selectFiltertedRegions($event)">Select Filtered</span>
                </div>
              </div>
            </div>
            <select class="form-control" id="regions_reg" (change)="fetchPlatformsTypes()" [(ngModel)]="ri.regionn"
              data-live-search="true" multiple required>
              <option *ngFor="let type of regions_reg" [value]="type">
                {{ type }}
              </option>
            </select>
          </div>

          <!-- Region (Update) -->
          <div class="row" *ngIf="action == 'update'">
            <label>Region</label>
            <select class="form-control" id="regions_reg" [(ngModel)]="ri.regionn" readonly>
              <option [value]="ri.regionn">{{ ri.regionn }}</option>
            </select>
          </div>

          <!-- Usage Type Selection -->
          <div class="row" *ngIf="action !== 'update'">
            <div class="flex-between">
              <label>Usage Type</label>
              <div class="flex-start filters">
                <div class="flex-start">
                  <input type="checkbox" id="select_all_usage_types" (change)="selectAllUsageTypes($event)" />
                  <label for="select_all_usage_types">Select All</label>
                </div>
                <div class="flex-start" *ngIf="usageType && usageType.length > 0">
                  <span class="nav-link" (click)="selectFiltertedUsageTypes($event)">Select Filtered</span>
                </div>
              </div>
            </div>
            <select class="form-control" id="platformsList" (change)="fetchRICost()" [(ngModel)]="ri.usageType"
              data-live-search="true" multiple required>
              <option *ngFor="let platform of usageType" [value]="platform">
                {{ getDisplayName(platform) }}
              </option>
            </select>
          </div>

          <!-- Usage Type (Update) -->
          <div class="row" *ngIf="action == 'update'">
            <label>Usage Type</label>
            <select class="form-control" id="platformsList" [(ngModel)]="ri.usageType" readonly>
              <option [value]="ri.usageType">
                {{ getDisplayName(ri.usageType) }}
              </option>
            </select>
          </div>

          <!-- Unit and Price Per Unit (Shown when region and usageType have only one selection) -->
          <div class="row" *ngIf="
            !selectAll &&
            ri.regionn.length <= 1 &&
            ri.usageType.length <= 1 &&
            ri.usageType !== 'alls'
          ">
            <label>Unit</label>
            <input type="text" style="padding-left: 12px" class="form-control" [(ngModel)]="ri.unit" readonly />
          </div>
          <div class="row" *ngIf="
            !selectAll &&
            ri.regionn.length <= 1 &&
            ri.usageType.length <= 1 &&
            ri.usageType !== 'alls'
          ">
            <label>Price Per Unit</label>
            <input type="text" style="padding-left: 12px" class="form-control" [(ngModel)]="ri.ppu" readonly />
          </div>

          <!-- Discount Type Selection -->
          <div class="row" *ngIf="action !== 'update'">
            <label>Discount Type</label>
            <div class="form-check" style="padding-left: 30px">
              <input class="form-check-input" type="radio" name="discountType" id="percentage" value="percentage"
                [(ngModel)]="ri.discountType" checked />
              <label class="form-check-label" for="percentage">Percentage</label>
            </div>
            <div class="form-check" style="padding-left: 30px">
              <input class="form-check-input" type="radio" name="discountType" id="value" value="value"
                [(ngModel)]="ri.discountType" />
              <label class="form-check-label" for="value">Value</label>
            </div>
          </div>

          <!-- Discount Type (Update) -->
          <!-- <div class="row" *ngIf="action == 'update'">
          <div class="form-check" style="padding-left: 30px;">
            <input class="form-check-input" type="radio" name="discountType" id="percentage" value="percentage"
              [(ngModel)]="ri.discountType" checked readonly >
            <label class="form-check-label" for="percentage">Percentage</label>
          </div>
          <div class="form-check" style="padding-left: 30px;">
            <input class="form-check-input" type="radio" name="discountType" id="value" value="value"
              [(ngModel)]="ri.discountType" readonly >
            <label class="form-check-label" for="value">Value</label>
          </div>
        </div> -->

          <!-- Discount Value or Percentage -->
          <div class="row" *ngIf="action !== 'update'">
            <label>{{
              ri.discountType == "value" && !selectAll
              ? "Discount Value:"
              : "Discount Percentage:"
              }}</label>

            <div class="percentage-input-group">
              <input type="text" class="form-control" [(ngModel)]="ri.discountPercentage"
               required   (keydown)="validateNumericInput($event)" (paste)="onPaste($event)" />
              <span class="input-group-text" *ngIf="ri.discountType !== 'value'">%</span>
            </div>
          </div>

          <div class="row" *ngIf="action == 'update'">
            <label>{{
              ri.discountType == "value" && !selectAll
              ? "Discount Value:"
              : "Discount Percentage:"
              }}</label>

            <div class="percentage-input-group">
              <input type="text" class="form-control" [(ngModel)]="disc" required (keydown)="validateNumericInput($event)" (paste)="onPaste($event)"/>

              <span class="input-group-text" *ngIf="ri.discountType === 'percentage' && !selectAll">%</span>
            </div>
          </div>

          <!-- Period -->
          <div class="row" [ngStyle]="{ display: action != 'update' ? 'block' : 'none' }">
            <label>Period: </label>
            <input type="text" id="dataRangePicker" [(ngModel)]="dateRange" class="form-control"
              style="width: 300px; padding-left: 12px" placeholder="Select date" />
          </div>

          <div class="row" [ngStyle]="{ display: action == 'update' ? 'block' : 'none' }">
            <label>Period: </label>
            <input type="text" id="dataRangePicker" [(ngModel)]="dateRange" class="form-control"
              style="width: 300px; padding-left: 12px" placeholder="Select date" disabled="" />
          </div>
        </div>


      </div>

      <!-- Alternative Form (shown when Select All is checked or action is update with usageType = 'all') -->
      <div class="new_form" [ngStyle]="{ display: selectAll ? 'block' : 'none' }" style="padding: 1px 25px 5px 25px">

        <!-- <div class="row">
        <div class="flex-between">
            <label>Linked Account List</label>
            <div class="flex-start filters">
                <div class="flex-start">
                    <input
                        type="checkbox"
                        id="select_all_accounts"
                        (change)="selectAllchilds($event)"
                    />
                    <label for="select_all_accounts">Select All</label>
                </div>
            </div>
        </div>

        <select
        class="form-control"
        id="linkedAccounts" 
        [(ngModel)]="ri.linkedAccounts" 
        data-live-search="true"
        multiple 
        required
       >
            <option
                *ngFor="let master of linkedAccounts['list']"
                [value]="master.accountId"              
            >
                {{ master.accountId }} ({{master.accountName}})
            </option>
        </select>
    </div> -->

        <!-- Usage Type -->
        <div class="row">
          <label>Usage Type</label>
          <input style="padding-right: 12px" type="text" class="form-control"
            [ngModel]="selectAll ? 'All' : ri.usageType" [attr.disabled]="selectAll ? true : null" />
        </div>

        <!-- Discount Type (Always Percentage) -->
        <div class="row">
          <label>Discount Type</label>
          <div class="form-check" style="padding-left: 30px">
            <input class="form-check-input" type="radio" name="newDiscountType" id="newPercentage" value="percentage"
              checked disabled />
            <label class="form-check-label" for="newPercentage">Percentage</label>
          </div>
          <div class="form-check" style="padding-left: 30px">
            <input class="form-check-input" type="radio" name="newDiscountType" id="newValue" value="value" disabled />
            <label class="form-check-label" for="newValue">Value</label>
          </div>
        </div>

        <!-- Discount Percentage -->
        <div class="row" *ngIf="action !== 'update'">
          <label>Discount Percentage:</label>
          <div class="percentage-input-group">
            <input type="text" class="form-control" [(ngModel)]="ri.discountPercentage" required (keydown)="validateNumericInput($event)" (paste)="onPaste($event)"/>
            <span class="input-group-text" *ngIf="ri.discountType !== 'value'">%</span>
          </div>
        </div>

        <div class="row" *ngIf="ri.usageType === 'all' && action == 'update'">
          <label>Discount Percentage:</label>
          <div class="percentage-input-group">
            <input type="text" class="form-control" [(ngModel)]="disc"  required (keydown)="validateNumericInput($event)" (paste)="onPaste($event)" />
            <span class="input-group-text" *ngIf="ri.discountType !== 'value'">%</span>
          </div>
        </div>
        <!-- Period -->
        <div class="row" [ngStyle]="{ display: action !== 'update' ? 'block' : 'none' }">
          <label>Period:</label>
          <input type="text" id="dataRangePicker" [(ngModel)]="dateRange" class="form-control"
            style="width: 300px; padding-left: 12px" placeholder="Select date" />
        </div>

        <div class="row" [ngStyle]="{
            display:
              action === 'update' && ri.usageType === 'all' ? 'block' : 'none'
          }">
          <label>Period:</label>
          <input type="text" id="dataRangePicker" [(ngModel)]="dateRange" class="form-control"
            style="width: 300px; padding-left: 12px" placeholder="Select date" disabled="" />
        </div>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="alarm_actions">
      <div style="display: flex; justify-content: flex-end">
        <button class="btn" (click)="updateEntry()" style="
            background-color: #5864ff;
            color: white;
            text-transform: capitalize;
            margin-right: 20px;
          ">
          {{ action == "update" ? "Update" : "Add" }}
        </button>
        <button class="btn btn-light" (click)="close(false)">Cancel</button>
      </div>
    </div>
  </div>

  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>
</div>