import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

declare let humanizeDuration: any;

@Component({
  selector: 'createrule-backup',
  templateUrl: './createrule.backup.component.html',
  styleUrls: ['./../../backupv2.component.css'],
})
export class CreateRuleBackupv2Component implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';

  funRef: any;

  retentions: any = {
    backup_frequency: {
      value: 0,
      type: 'minutes',
    },
    backup_retention: {
      value: 0,
      type: 'minutes',
    },
    log_retention: {
      value: 0,
      type: 'minutes',
    },
  };

  rulesHeaders: any = [
    {
      id: 'key',
      name: 'Key',
    },
    {
      id: 'value',
      name: 'Value',
    },
  ];

  @Input('action') action: any;

  @Input('rule') rule: any;

  @Output('close') closeEmitter = new EventEmitter<boolean>();

  accountId: any;
  regionId: any;
  currentMessage: any;
  notifications: any = [];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.funRef = {};

    this.rule.tag_key_value_pairs = this.rule.tag_key_value_pairs.map(
      (obj: any) => {
        obj = obj.split(':');
        return { key: obj[0], value: obj[1] };
      }
    );

    this.minstohuman(this.rule.backup_retention, 'backup_retention');
    this.minstohuman(this.rule.backup_frequency, 'backup_frequency');
    this.minstohuman(this.rule.log_retention, 'log_retention');
    this.rule.status = this.rule.status ? 'true' : 'false';

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  addResource() {
    this.rule.tag_key_value_pairs.push({ key: '', value: '' });
  }

  removeResource(index: number) {
    this.rule.tag_key_value_pairs.splice(index, 1);
  }

  minstohuman(value: any, param: string) {
    let milli = Number(value) * 1000 * 60;
    let human = humanizeDuration(milli);
    value = moment.duration(value, 'minutes');

    if (human.indexOf('year') > -1 || human.indexOf('years') > -1) {
      this.retentions[param]['type'] = 'years';
      this.retentions[param]['value'] = value.years();
      return;
    }
    if (human.indexOf('month') > -1 || human.indexOf('months') > -1) {
      this.retentions[param]['type'] = 'months';
      this.retentions[param]['value'] = value.months();
      return;
    }
    if (human.indexOf('day') > -1 || human.indexOf('days') > -1) {
      this.retentions[param]['type'] = 'days';
      this.retentions[param]['value'] = value.days();
      return;
    }
    if (human.indexOf('hour') > -1 || human.indexOf('hours') > -1) {
      this.retentions[param]['type'] = 'hours';
      this.retentions[param]['value'] = value.hours();
      return;
    }
    if (human.indexOf('minutes') > -1 || human.indexOf('minute') > -1) {
      this.retentions[param]['type'] = 'minutes';
      this.retentions[param]['value'] = value.minutes();
      return;
    }
  }

  async load() {}

  humantominutes(param: any) {
    let type = this.retentions[param]['type'];
    let value = Number(this.retentions[param]['value']);
    if (type == 'hours') {
      return value * 60;
    }
    if (type == 'days') {
      return value * 60 * 24;
    }
    if (type == 'months') {
      return value * 60 * 24 * moment().daysInMonth();
    }
    if (type == 'years') {
      return value * 60 * 24 * moment().daysInMonth() * 365;
    }
    return value;
  }

  async updateRule() {
    this.notifier.loading(true);
    let data = {
      action: this.action,
      login_user_name: this.userId,
      account_id: this.accountId,
      region_name: this.regionId,
      rule_name: this.rule.rule_name,
      backup_frequency: this.humantominutes('backup_frequency'),
      backup_retention: this.humantominutes('backup_retention'),
      log_retention: this.humantominutes('log_retention'),
      rule_id:
        this.action == 'create_backup_rule' ? undefined : this.rule.rule_id,
      status: this.rule.status == 'true',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/backup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      let check = true;
      if (
        this.action == 'update_backup_rule' &&
        this.rule.tag_key_value_pairs.length > 0
      ) {
        check = await this.updateResources();
      }
      if (check) {
        this.notifier.alert('Success', '', result.message, 'success', 5000);
        this.rule.backup_frequency = this.humantominutes('backup_frequency');
        this.rule.backup_retention = this.humantominutes('backup_retention');
        this.rule.log_retention = this.humantominutes('log_retention');
        this.rule.status = this.rule.status == 'true';
        this.rule.tag_key_value_pairs = this.rule.tag_key_value_pairs.map(
          (resource: any) => {
            return `${resource.key}:${resource.value}`;
          }
        );
        this.notifier.loading(false);

        this.close(this.rule);
      }
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async updateResources() {
    let data = {
      action: 'add_resources_to_backup_rule',
      login_user_name: this.userId,
      account_id: this.accountId,
      region_name: this.regionId,
      rule_id: this.rule.rule_id,
      backup_type: this.rule.backup_type || 'backup_by_tag',
      tag_key_value_pairs: this.rule.tag_key_value_pairs.map(
        (resource: any) => {
          return `${resource.key}:${resource.value}`;
        }
      ),
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/backup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    let check = false;

    if (result.s == '1' || result.status == '1') {
      check = true;
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
      check = false;
    }

    return check;
  }

  close(rule: any = false) {
    if (this.rule) {
      this.closeEmitter.emit(rule);
    } else {
      this.closeEmitter.emit(false);
    }
  }

  callFunction(value: any, param: any) {
    this.funRef[value](this, param);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
