import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

declare let $: any;

@Component({
  selector: 'ec2-group',
  templateUrl: './ec2-group.component.html',
  styleUrls: ['./ec2-group.component.css'],
})
export class updateEC2Group implements OnInit, OnDestroy {
  @Input('group') group: any;
  @Input('action') action: any;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  instances: any = [];
  regionId: any;
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.group = { ...this.group };
    $('#instancesList').selectpicker();
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.load();
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.load();
        this.regionId = d.value;
      }
    });
  }

  async load() {
    await this.listInstances();
  }

  async updateEntry() {
    if (this.action == 'local') {
      this.close(true);
      return;
    }
    this.notifier.loading(true);
    const selectedInstances = $('#instancesList').val();
    let data = {
      clientId: this.userId,
      groupName: this.group.groupName,
      action: this.action,
      accountId: this.accountId,
      instanceList: this.instances
        .filter((instance: any) => {
          return selectedInstances.indexOf(instance.instanceId) > -1;
        })
        .map((instance: any) => {
          return {
            instanceId: instance.instanceId,
            instanceName: instance.tagName
              ? instance.tagName
              : instance.instanceName,
          };
        }),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2groups`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      if (this.action == 'add') {
        this.close();
      } else {
        this.close(true);
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  close(sendData: boolean = false) {
    if (sendData) {
      const selectedInstances = $('#instancesList').val();
      this.group['instanceList'] = this.instances
        .filter((instance: any) => {
          return selectedInstances.indexOf(instance.instanceId) > -1;
        })
        .map((instance: any) => {
          return {
            instanceId: instance.instanceId,
            instanceName: instance.tagName,
          };
        });
      this.hideModel.emit(this.group);
    } else {
      this.hideModel.emit(false);
    }
  }

  async listInstances(nextToken?: any) {
    this.notifier.loading(true);
    let data = {
      action: 'testListInstancesWithPagination',
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken ? nextToken : undefined,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances `;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.instances = result.instanceDetails;
      for (let i = 0; i < this.group.instanceList.length; i++) {
        if (
          !this.instances.find((instance: any) => {
            return instance.instanceId == this.group.instanceList[i].instanceId;
          })
        ) {
          this.instances.push(this.group.instanceList[i]);
          $('#instancesList').selectpicker('refresh');
        }
      }
      setTimeout(() => {
        $('#instancesList').selectpicker('refresh');
        let selectedValues = $('#instancesList').val();
        $('#instancesList').val([
          ...selectedValues,
          ...this.group.instanceList.map((element: any) => {
            return element.instanceId;
          }),
        ]);
        selectedValues = $('#instancesList').val();
        this.group.instanceList.forEach((element: any) => {
          if (selectedValues.indexOf(element) < -1) {
            $('#instancesList').val([...selectedValues, element]);
          }
        });
        $('#instancesList').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }
}
