import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'disks-ec2',
  templateUrl: './disks.component.html',
})
export class DisksEC2Component implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  @Input() instance: any;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  diskDetailsList: any = [];
  funRef: any;
  accountId: any;
  regionId: any;

  headers: any = [
    {
      id: 'disk_name',
      name: 'Name',
    },
    {
      id: 'disk_file_system',
      name: 'File System',
    },
    {
      id: 'disk_size',
      name: 'Size (MB)',
    },
    {
      id: 'disk_used_size',
      name: 'Used (MB)',
    },
    {
      id: 'disk_free_size',
      name: 'Free (MB)',
    },
    {
      id: 'disk_used',
      name: 'Used (%)',
    },
    {
      id: 'disk_free',
      name: 'Free (%)',
    },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.funRef = {};
    this.load();
  }
  async load() {
    await this.loadDisks();
  }

  async loadDisks() {
    this.notifier.loading(true);
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { site_247_project_id } = selectedAccountData.data;

        let data = {
          action: 'disk_details',
          zaaid: site_247_project_id,
          instance_name: this.instance.tagName,
        };

        let header = {
          Authorization: localStorage.getItem('t'),
        };
        // let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ebs/diskdetails`;
        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        console.log(result);

        if (result.s == '1' || result.status == '1') {
          this.diskDetailsList = result.disk_data;
        } else {
          this.notifier.alert('Info', '', result.error_message, 'info', 5000);
        }
      }
    } catch (err) {}
    this.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  callFunction(name: any, value: any) {
    this.funRef[name](this, value);
  }
  ngOnDestroy(): void {}
}
