import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { CommonModule } from '@angular/common';

import { DashComponent } from './dash/dash.component';
import { DashIndexComponent } from './dash/index.component';

import { DashcComponent } from './dashc/dash.component';
import { DashcIndexComponent } from './dashc/index.component';

import { DashClientComponent } from './client/dash.component';
import { DashClientIndexComponent } from './client/index.component';

import { headerPrintComponent } from './component/header/header';

import { NumbersOnly } from './_directives/dirNumber';
import { DateFormat } from './_directives/dirDateFormat';

import { createTicketComponent } from './views/ticket/create.component';
import { viewTicketComponent } from './views/ticket/view/view.ticket.component';
import { knowledgebaseComponent } from './views/knowledgebase/knowledgebase.component';

// compliance
import { ebsComponent } from './views/compliance/ebs/ebs.component';
import { s3Component } from './views/compliance/s3/s3.component';
import { cloudTrailComponent } from './views/compliance/cloudtrail/cloudtrail.component';
import { configComponent } from './views/compliance/config/config.component';
import { iamComponent } from './views/compliance/iam/iam.component';
import { patchComponent } from './views/compliance/patchreport/patch.component';

import { securityHubComponent } from './views/compliance/security_hub/security.hub.component';
import { inspectorComponent } from './views/compliance/inspector/inspector.component';
import { sgComponent } from './views/compliance/security_groups/sg.component';
import { tagsComponent } from './views/compliance/tags/tags.component';
import { advisorComponent } from './views/compliance/advisor/advisor.component';
import { otherCostOptimizationComponent } from './views/costoptimization/othercostoptimization/othercostopt.component';
import { floatingComponent } from './views/costoptimization/floatingreport/floating.component';

//SLA
import { CvsRComponent } from './views/sla/createdvsresolved/createdvsresolved.component';
import { FirstResponseSLAComponent } from './views/sla/firstresponsesla/firstresponsesla.component';
import { ResolutionSLA } from './views/sla/resolutionsla/resolutionsla.component';

// Monitoring
import { MonitoringComponent } from './views/monitoring/monitoring.component';

// Operations
import { ec2OperationsComponent } from './views/operations/ec2/ec2.operations.component';
import { rdsOperationsComponent } from './views/operations/rds/rds.component';
import { ebsOpsComponent } from './views/operations/ebs/ebs.operations.component';
import { sgOperationsComponent } from './views/operations/security_groups/sg.operations.component';

// Anomalies
import { dashboardAnomaliesComponent } from './views/anomalies/dashboard/dashboard.anomalies.component';
import { triggerHistoryAnomaliesComponent } from './views/anomalies/trigger_history/trigger_history.anomalies.component';
import { exceptionsAnomaliesComponent } from './views/anomalies/exceptions/exceptions.anomalies.component';

// BackupReport
import { backupReportComponent } from './views/backupreport/backupreport.component';

// BOM
import { viewBOMComponent } from './views/BOM/view/view.bom.component';
import { deltaReportBOMComponent } from './views/BOM/deltareport/deltareport.bom.component';

// Reports
import { viewReportsComponent } from './views/reports/view/view.reports.component';
import { DiskDetailsReportsComponent } from './views/reports/view/components/diskdetails/diskdetails.view.reports.component';
import { DailyBackupViewReportsComponent } from './views/reports/view/components/dailybackup/dailybackup.view.reports.component';
import { InventoryReportsComponent } from './views/reports/view/components/inventory/inventory.view.reports.component';
import { MISReportsComponent } from './views/reports/view/components/mis/mis.view.reports.component';
import { SGReportsComponent } from './views/reports/view/components/sg/sg.view.reports.component';
import { UptimeReportsComponent } from './views/reports/view/components/uptime/uptime.view.reports.component';

// Billing
import { BillingComponent } from './views/billing/billing.component';

// Settings
import { auditSettingsComponent } from './views/settings/audit/audit.settings.component';
import { ViewUserSettingsComponent } from './views/settings/user/view/view.user.component';
import { ProfileSettingsComponent } from './views/settings/user/profile/profile.settings.component';
import { InviteUserComponent } from './views/settings/user/invite/invite.user.component';

import { modalPopupComponent } from './component/modal/modal';
import { TagsModalComponent } from './component/modal/tags/tags.modal.component';
import { nTableComponent } from './component/nTable/nTable';

import { nTableNestedComponent } from './component/nTableNested/nTable';

// Utils
import { ec2DetailsViewComponent } from './component/ec2detailsview/ec2.details.view.component';
import { VolumeEC2Component } from './component/ec2detailsview/components/volume/volume.ec2.components';
import { ReplyTicketPopup } from './views/ticket/view/components/reply/reply.ticket.component';
import { ConfirmBox } from './component/confirm/confirm.component';
import { AddKBComponent } from './component/kb/add/add.kb.component';
import { StartStopInstanceComponent } from './views/operations/ec2/components/startstop/startstop.ec2.component';
import { TagsOperationsComponent } from './views/operations/tags/tags.operations.component';
import { CreateJobComponent } from './views/costoptimization/floatingreport/createjob/createjob.component';
import { CreateAlarmAnomaliesComponent } from './views/anomalies/dashboard/createalarm/createalarm.anomalies.component';
import { CreateExceptionAnomaliesComponent } from './views/anomalies/exceptions/updaterule/updaterule.anomalies.component';
import { CreateScanBackupReportComponent } from './views/backupreport/createscan/createscan.backupreport.component';
import { enableReportsComponent } from './views/reports/enable/enable.reports.component';

import { UpdateBOMComponent } from './views/BOM/view/update/update.bom.component';
import { DeleteBOMComponent } from './views/BOM/view/delete/delete.bom.component';
import { UploadBOMComponent } from './views/BOM/upload/upload.bom.component';
import { ErrorUploadBOM } from './views/BOM/upload/error/error.upload.bom.component';
import { DisksEC2Component } from './component/ec2detailsview/components/disks/disks.component';
import { EC2BillingComponent } from './views/billing/components/ec2/ec2.billing.component';
import { RDSBillingComponent } from './views/billing/components/rds/rds.billing.component';
import { CloudFrontBillingComponent } from './views/billing/components/cloudfront/cloudfront.billing.component';
import { AnalyticsBillingComponent } from './views/billing/components/analytics/analytics.component';
import { updateEC2Group } from './views/settings/ec2-group/components/ec2-group.component';
import { ViewEC2GroupComponenet } from './views/settings/ec2-group/view.ec2-group.component';
import { PoliciesComponenet } from './views/settings/policies/manage.policies.component';
import { UpdatePoliciesComponent } from './views/settings/policies/components/update.policies.component';
import { ForgotPasswordComponent } from './login/forgot_password/forgot.component';
import { RegisterComponent } from './login/register/register.component';
import { RICoverageCBSComponenet } from './views/cbs/ricoverage/ricoverage.component';
import { RIPurchasedComponent } from './views/cbs/ripurchased/ripurchased.component';
import { MinfyRIAccountComponent } from './views/cbs/minfyriaccount/minfyriaccount.component';
import { OrganizationsCBSComponent } from './views/cbs/organizations/organizations.component';
import { RIOrderComponent } from './views/cbs/enableri/riorder.component';
import { EnableRIUpdateComponent } from './views/cbs/enableri/update/update.enableri.component';
import { ViewCFRCComponent } from './views/cbs/cfrc/view/cfrc.view.component';
import { EnableCFRCComponent } from './views/cbs/cfrc/enable/cfrc.enable.component';
import { MasterSummaryComponent } from './views/cbs/mastersummary/mastersummary.component';
import { EnableMasterComponent } from './views/cbs/enablemaster/enablemaster.component';
import { DiscountComponent } from './views/cbs/discount/discount.component';
import { RIOrderBillingComponent } from './views/billing/ri/billing.ri.component';
import { supportPlanComponent } from './views/cbs/supportplan/supportplan.component';
import { OnBoardedMasterComponent } from './views/cbs/onboardedmaster/onboardedmaster.component';
import { Backupv2Component } from './views/backupv2/backupv2.component';
import { RuleBackupv2Component } from './views/backupv2/components/view/rule.backup.component';
import { CreateRuleBackupv2Component } from './views/backupv2/components/create/createrule.backup.component';
import { DeleteRuleBackupv2Component } from './views/backupv2/components/delete/delete.component';
import { AMIsComponent } from './views/amis/amis.component';
import { CreateScanAMIsReportComponent } from './views/amis/createscan/createscan.amis.component';
import { StatusAMIsComponent } from './views/amis/amis_status/amis_status.component';
import { RDSEnableRIUpdateComponent } from './views/cbs/rdsenableri/update/rdsupdate.enableri.component';
import { RDSRIOrderComponent } from './views/cbs/rdsenableri/rdsriorder.component';
import { AgeingSnapshotsComponent } from './views/reports/view/components/ageingsnapshots/ageingsnapsots.component';
import { BetaTagsCostOptimizationComponent } from './views/beta/tagscostoptimization/tagscostoptimization.component';
import { TagsCostOptimizationCreateJobComponent } from './views/beta/tagscostoptimization/createjob/createjob.component';
import { BetaCreateTicketComponent } from './views/beta/ticket/create.component';
import { BetaIAMUsersComponent } from './views/beta/iamusers/iamusers.component';
import { BetaTicketEC2Start } from './views/beta/ticket/components/startstop/ticket.ec2startstop.component';
import { BillingDownloadAll } from './views/billing/downloadall/downloadall.component';
import { BetaEc2CostOptimizationComponent } from './views/beta/ec2costoptimization/ec2costoptimization.component';
import { BetaEC2CostOptimizationCreateJobComponent } from './views/beta/ec2costoptimization/createjob/createjob.component';
import { BetaEBSCostOptimizationComponent } from './views/beta/ebscostoptimization/ebscostoptimization.component';
import { ec2CostOptimizationComponent } from './views/costoptimization/ec2costoptimization/ec2costoptimization.component';
import { BetaIAMAgeReportComponent } from './views/beta/iam_age_report/iam_age_report.component';
import { BetaIAMGroupsComponent } from './views/beta/iam_groups/iam_groups.component';
import { BetaS3AgeReportComponent } from './views/beta/s3_ageing_report/s3_ageing.component';
import { StartStopv2InstanceComponent } from './component/startstopv2/startstop.ec2.component';
import { CBSDashComponent } from './cbsDash/dash.component';
import { UploadBOMv2Component } from './views/BOMv2/upload/upload.bom.component';
import { Betaec2OperationsComponent } from './views/beta/operations/ec2/ec2.operations.component';
import { MetricsComponent } from './views/beta/ec2costoptimization/metrics/metrics.ec2.component';
import { BetaDetailsIAMGroupsComponent } from './views/beta/iam_groups/components/details.iam.groups.component';
import { Step3UploadBOMv2Component } from './views/BOMv2/upload/components/step3/step3.uploadbomv2.component';
import { Step4UploadBOMv2Component } from './views/BOMv2/upload/components/step4/step4.bomupload.component';
import { BetardsCostOptimizationComponent } from './views/beta/tagscostoptimization copy/rdscostoptimization.component';
import { RDSCostOptimizationCreateJobComponent } from './views/beta/tagscostoptimization copy/createjob/createjob.component';
import { BetaDailyBillingComponent } from './views/beta/billing/billing.component';
import { viewSchedulertComponent } from './viewsnew/scheduler/view.scheduler.component';
import { ridetailsComponent } from './views/beta/ridetails/ridetails.component';
import { Step5UploadBOMv2Component } from './views/BOMv2/upload/components/step5/step5.bomupload.component';
import { ConsolicatedReportComponent } from './views/reports/view/components/consolidatedreport/consolidatedreport.component';
import { multiSelectDropdownComponent } from './component/multiselect/multiselect.component';
import { SGComplianceCreateJobComponent } from './views/compliance/security_groups/createjob/createjob.component';
import { ErrorInviteUser } from './views/settings/user/invite/error/error.invite.user.component';
import { LaunchInstanceComponent } from './views/BOMv2/upload/components/launch/launch.instance.component';
import { AMIsLaunchInstanceComponent } from './views/BOMv2/upload/components/launch/components/amis/amis.launch.component';
import { selectLaunchDropdownComponent } from './views/BOMv2/upload/components/dropdown/dropdown.launch.component';
import { NetworkLaunchInstanceComponent } from './views/BOMv2/upload/components/launch/components/network/network.launch.component';
import { StorageLaunchInstanceComponent } from './views/BOMv2/upload/components/launch/components/storage/storage.launch.component';
import { TagsKeyPairLaunchInstanceComponent } from './views/BOMv2/upload/components/launch/components/tags_keypair/tagskeypair.launch.component';
import { OSCheckUploadBOM } from './views/BOMv2/upload/components/step5/oscheck/oscheck.upload.bom.component';
import { DetailsLaunchInstanceComponent } from './views/BOMv2/upload/components/launch/components/details/details.launch.component';
import { LaunchUploadBOM } from './views/BOMv2/upload/components/launch/components/error/error.upload.bom.component';
import { VPCLaunchDropdownComponent } from './views/BOMv2/upload/components/dropdown/components/vpc/vpc.dropdown.launch.component';
import { SGLaunchDropdownComponent } from './views/BOMv2/upload/components/dropdown/components/sg/sg.dropdown.launch.component';
import { SubnetLaunchDropdownComponent } from './views/BOMv2/upload/components/dropdown/components/subnet/subnet.dropdown.launch.component';
import { AMILaunchDropdownComponent } from './views/BOMv2/upload/components/dropdown/components/ami/ami.dropdown.launch.component';
import { KeyLaunchDropdownComponent } from './views/BOMv2/upload/components/dropdown/components/keypair/keypair.dropdown.launch.component';
import { RoleLaunchDropdownComponent } from './views/BOMv2/upload/components/dropdown/components/role/role.dropdown.launch.component';
import { Bomv2Module } from './bomv2/bomv2.module';
import { MinfyRiDetailsComponent } from './views/cbs/minfyridetails/minfyridetails.component';
import { BillingDashboardComponent } from './views/billing/dashboard/billingdashboard.component';
import { BudgetComponent } from './views/cbs/budget/budget.component';
import { UpdateBudgetComponent } from './views/cbs/budget/components/update/update.budget.component';
import { deleteBudgetComponent } from './views/cbs/budget/components/delete/delete.budget.component';
import { BudgetBIllingComponent } from './views/billing/budget/budget.component';
import { HighestBillComponent } from './views/cbs/highestbill/highestbill.component';
import { BetaEc2CostOptimizationComponentAll } from './views/beta/ec2costoptimizationall/ec2costoptimization.component';
import { EC2ThreshholdComponent } from './views/cbs/ec2threshhold/ec2threshhold.component';
import { UpdateEC2ThreshholdComponent } from './views/cbs/ec2threshhold/components/update/update.ec2threshhold.component';
import { deleteEC2ThreshholdComponent } from './views/cbs/ec2threshhold/components/delete/delete.ec2threshhold.component';
import { InstancesSelectionComponent } from './views/cbs/ec2threshhold/components/update/components/instances/instances.component';
import { WarQuizComponent } from './views/beta/warquiz/warquiz.component';
import { RulesetsUploadBOMv2Component } from './views/BOMv2/upload/components/rulesets/rulesets.uploadbomv2.component';
import { CostvarianceComponent } from './views/cbs/costvariance/costvariance.component';
import { DatepickerComponent } from './views/BOMv2/upload/components/step3/components/datepicker/datepicker.component';
import { ChangerequestComponent } from './views/BOMv2/upload/components/changerequest/changerequest.component';
import { Step1InstanceSelectorComponent } from './views/BOMv2/upload/components/changerequest/components/step1-instance-selector/step1-instance-selector.component';
import { Step2UserDetailsComponent } from './views/BOMv2/upload/components/changerequest/components/step2-user-details/step2-user-details.component';
import { ChangerequesttypeComponent } from './views/BOMv2/upload/components/changerequest/components/changerequesttype/changerequesttype.component';
import { Step1StorageSelectorComponent } from './views/BOMv2/upload/components/changerequest/components/step1-storage-selector/step1-storage-selector.component';
import { OtpValidationCreateCrComponent } from './views/BOMv2/upload/components/changerequest/components/otp-validation-create-cr/otp-validation-create-cr.component';
import { CrdetailsComponent } from './views/BOMv2/upload/components/changerequest/changerequestapproval/components/crdetails/crdetails.component';
import { PatchreportComponent } from './views/reports/view/components/patchreport/patchreport.component';
import { ChangerequestapprovalComponent } from './views/BOMv2/upload/components/changerequest/changerequestapproval/changerequestapproval.component';
import { ConfirmactionComponent } from './views/BOMv2/upload/components/changerequest/changerequestapproval/components/crdetails/components/confirmaction/confirmaction.component';
import { ViewcrsComponent } from './views/BOMv2/viewcrs/viewcrs.component';
import { StatusComponent } from './views/BOMv2/viewcrs/components/status/status.component';
import { CrDetailsComponent } from './views/BOMv2/viewcrs/components/cr-details/cr-details.component';
import { NewInstanceComponent } from './views/BOMv2/upload/components/changerequest/new/new-instance/new-instance.component';
import { OtpvalidationComponent } from './views/BOMv2/upload/components/changerequest/new/new-instance/components/otpvalidation/otpvalidation.component';
import { PipelinesDashComponent } from './pipelines-dash/pipelines-dash.component';
import { PipelinesDevComponent } from './views/beta/pipelines-dev/pipelines-dev.component';
import { PipelineComponent } from './views/beta/pipelines-dev/components/pipeline/pipeline.component';
import { PipelineStatusComponent } from './views/beta/pipelines-dev/components/status/status.component';
import { CreateWorkloadComponent } from './views/beta/warquiz/components/createscan/createscan.amis.component';
import { SsbComponent } from './views/ssb/ssb.component';
import { SsbCheckComponentComponent } from './views/ssb/components/ssb-check-component/ssb-check-component.component';
import { CreateJobSSBReportComponent } from './views/ssb/components/ssb-create-scan/create-job-ssb.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PipelineCreateComponent } from './views/beta/pipelines-dev/components/pipeline-create/pipeline-create.component';
import { BudgetsAllComponent } from './views/cbs/budgets-all/budgets-all.component';
import { PipelineBuildToolsComponent } from './views/beta/pipelines-dev/components/pipeline-build-tools/pipeline-build-tools.component';
import { canDeactivateGuard } from './_helpers/auth.guard';
import { FindingsComponent } from './views/compliance/findings/findings.component';
import { QuestionsComponent } from './views/beta/warquiz/components/questions/questions.component';
import { WorkloadOverviewComponent } from './views/beta/warquiz/components/workload-overview/workload-overview.component';
import { LensOverviewComponent } from './views/beta/warquiz/components/lens-overview/lens-overview.component';
import { MilestonesComponent } from './views/beta/warquiz/components/milestones/milestones.component';
import { CreateComponent } from './views/beta/warquiz/components/milestones/create/create.component';
import { PropertiesComponent } from './views/beta/warquiz/components/workload-overview/properties/properties.component';
import { SaveDialogComponent } from './views/beta/warquiz/components/questions/components/save-dialog/save-dialog.component';
import { ErpIntegrationsComponent } from './views/cbs/erp-integrations/erp-integrations.component';
import { AddComponent } from './views/cbs/erp-integrations/components/add/add.component';
import { MinfyriwrComponent } from './views/cbs/minfyriwr/minfyriwr.component';
import { ErpPlReportComponent } from './views/cbs/erp-pl-report/erp-pl-report.component';
import { PoAlertComponent } from './views/cbs/po-alert/po-alert.component';
import { AddPOReportComponent } from './views/cbs/po-alert/components/add/add.component';
import { PnlDashComponent } from './views/cbs/erp-pl-report/pnldashboard/pnldash.component';
import { ConvertionRateComponent } from './views/cbs/erp-pl-report/convertionrate/convertionrate.component';
import { SalesReportComponent } from './views/cbs/sales-report/sales-report.component';
import { CfrcreportComponent } from './views/cbs/cfrcreport/cfrcreport.component';
import { RiInventoryComponent } from './views/cbs/ri-inventory/ri-inventory.component';
import { SpInventoryComponent } from './views/cbs/sp-inventory/sp-inventory.component';
import { MRRDiscountComponent } from './views/cbs/mrr-discount/mrr.component';
import { MRRUpdateComponent } from './views/cbs/mrr-discount/update/update.mrr.component';
import { Minfyridetailswithsputilizedamount } from './views/cbs/minfyridetailswithsputilizedamount/minfyridetailswithsputilizedamount.component';
import { MarginConsolidation } from './views/cbs/erp-pl-report/margin-consolidation/marginconsolidation.component';
import { MarginDashboard } from './views/cbs/erp-pl-report/margin-dashboard/margin.component';
import { UploadBOMComponentNew } from './views/BOM/BOM/upload/upload.bom.component';
import { CustomDatePipe } from './views/cbs/erp-pl-report/margin-dashboard/customDate.component';
import { PnlSummaryComponent } from './views/cbs/erp-pl-report/pnlsummary/pnlsummary.component';
import { UnbilledPnlComponent } from './views/cbs/erp-pl-report/unbilled-pnl/unbilled-pl.component';
import { FinanacePnl } from './views/cbs/erp-pl-report/FinanaceYearReport-pnl/FinanaceYearReport-pnl.component';
import { FinanceUnbilledPnl } from './views/cbs/erp-pl-report/FinanceYearReport-unbilledpnl/FinanceYearReport-unbilledpnl.component';
import { CfrcEnableV3 } from './views/cbs/cfrcenable-v3/cfrcenable-v3.component';
import { CfrcEnableV3Update } from './views/cbs/cfrcenable-v3/cfrcupdate-v3/cfrcupdate-v3.component';
import { SingleMasterJob } from './views/cbs/single-master/master-job.component';
import { RDSRIComponent } from './views/cbs/rdsri/rdsri.component';
import { RDSRIEnableRIUpdateComponent } from './views/cbs/rdsri/update/rdsupdate.enableri.component';
import { onboardingEditUpdateComponent } from './views/cbs/onboardedmaster/update/onboardededit.component';
import { EnableCFRCOrderComponent } from './views/cbs/cfrc/enableorder/cfrc.enable.component';
import { EnableCFRCUpdateComponent } from './views/cbs/cfrc/enableorder/update/update.enableri.component';
import { WrpnlComponent } from './views/cbs/wrpnl/wrpnl.component';
import { S3Component } from './views/cbs/s3/s3.component';
import { UpdateS3Component } from './views/cbs/s3/update/updates3.component';
import { NonNhaComponent } from './views/cbs/mrr-discount/update/non_NHA/non_nha.component';
import { NonNhaPopUpComponent } from './views/cbs/mrr-discount/update/non_NHA/popup/popup.component';


@NgModule({
  declarations: [
    CreateAlarmAnomaliesComponent,
    CreateExceptionAnomaliesComponent,
    AppComponent,
    LoginComponent,
    DashComponent,
    CBSDashComponent,
    DashIndexComponent,
    DashcComponent,
    DashcIndexComponent,
    headerPrintComponent,
    NumbersOnly,
    DateFormat,
    DashClientComponent,
    DashClientIndexComponent,
    createTicketComponent,
    viewTicketComponent,
    knowledgebaseComponent,
    ebsComponent,
    s3Component,
    WrpnlComponent,
    cloudTrailComponent,
    configComponent,
    iamComponent,
    patchComponent,
    securityHubComponent,
    inspectorComponent,
    sgComponent,
    tagsComponent,
    advisorComponent,
    otherCostOptimizationComponent,
    floatingComponent,
    ec2OperationsComponent,
    CvsRComponent,
    FirstResponseSLAComponent,
    ResolutionSLA,
    MonitoringComponent,
    rdsOperationsComponent,
    ebsOpsComponent,
    sgOperationsComponent,
    dashboardAnomaliesComponent,
    triggerHistoryAnomaliesComponent,
    exceptionsAnomaliesComponent,
    backupReportComponent,
    viewBOMComponent,
    deltaReportBOMComponent,
    viewReportsComponent,
    DiskDetailsReportsComponent,
    DailyBackupViewReportsComponent,
    InventoryReportsComponent,
    MISReportsComponent,
    SGReportsComponent,
    UptimeReportsComponent,
    BillingComponent,
    auditSettingsComponent,
    ViewUserSettingsComponent,
    ProfileSettingsComponent,
    InviteUserComponent,
    modalPopupComponent,
    TagsModalComponent,
    nTableComponent,
    ec2DetailsViewComponent,
    VolumeEC2Component,
    ReplyTicketPopup,
    ConfirmBox,
    AddKBComponent,
    StartStopInstanceComponent,
    TagsOperationsComponent,
    CreateJobComponent,
    CreateScanBackupReportComponent,
    enableReportsComponent,
    UpdateBOMComponent,
    DeleteBOMComponent,
    UploadBOMComponent,
    UploadBOMComponentNew,
    ErrorUploadBOM,
    nTableNestedComponent,
    DisksEC2Component,
    EC2BillingComponent,
    RDSBillingComponent,
    CloudFrontBillingComponent,
    AnalyticsBillingComponent,
    updateEC2Group,
    ViewEC2GroupComponenet,
    PoliciesComponenet,
    UpdatePoliciesComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    RICoverageCBSComponenet,
    RIPurchasedComponent,
    MinfyRIAccountComponent,
    OrganizationsCBSComponent,
    RIOrderComponent,
    EnableRIUpdateComponent,
    ViewCFRCComponent,
    EnableCFRCComponent,
    MasterSummaryComponent,
    EnableMasterComponent,
    DiscountComponent,
    RIOrderBillingComponent,
    supportPlanComponent,
    OnBoardedMasterComponent,
    Backupv2Component,
    RuleBackupv2Component,
    CreateRuleBackupv2Component,
    DeleteRuleBackupv2Component,
    AMIsComponent,
    CreateScanAMIsReportComponent,
    StatusAMIsComponent,
    RDSEnableRIUpdateComponent,
    RDSRIOrderComponent,
    AgeingSnapshotsComponent,
    TagsCostOptimizationCreateJobComponent,
    BetaTagsCostOptimizationComponent,
    BetaCreateTicketComponent,
    BetaIAMUsersComponent,
    BetaTicketEC2Start,
    BillingDownloadAll,
    BetaEc2CostOptimizationComponent,
    BetaEC2CostOptimizationCreateJobComponent,
    BetaEBSCostOptimizationComponent,
    ec2CostOptimizationComponent,
    BetaIAMAgeReportComponent,
    BetaIAMGroupsComponent,
    BetaS3AgeReportComponent,
    StartStopv2InstanceComponent,
    UploadBOMv2Component,
    Betaec2OperationsComponent,
    MetricsComponent,
    BetaDetailsIAMGroupsComponent,
    Step3UploadBOMv2Component,
    Step4UploadBOMv2Component,
    BetaDailyBillingComponent,
    BetardsCostOptimizationComponent,
    RDSCostOptimizationCreateJobComponent,
    viewSchedulertComponent,
    ridetailsComponent,
    Step5UploadBOMv2Component,
    RulesetsUploadBOMv2Component,
    ConsolicatedReportComponent,
    multiSelectDropdownComponent,
    SGComplianceCreateJobComponent,
    ErrorInviteUser,
    LaunchInstanceComponent,
    AMIsLaunchInstanceComponent,
    selectLaunchDropdownComponent,
    NetworkLaunchInstanceComponent,
    StorageLaunchInstanceComponent,
    TagsKeyPairLaunchInstanceComponent,
    OSCheckUploadBOM,
    DetailsLaunchInstanceComponent,
    LaunchUploadBOM,
    VPCLaunchDropdownComponent,
    SGLaunchDropdownComponent,
    SubnetLaunchDropdownComponent,
    AMILaunchDropdownComponent,
    KeyLaunchDropdownComponent,
    RoleLaunchDropdownComponent,
    MinfyRiDetailsComponent,
    BillingDashboardComponent,
    BudgetComponent,
    UpdateBudgetComponent,
    deleteBudgetComponent,
    BudgetBIllingComponent,
    HighestBillComponent,
    BetaEc2CostOptimizationComponentAll,
    EC2ThreshholdComponent,
    UpdateEC2ThreshholdComponent,
    deleteEC2ThreshholdComponent,
    InstancesSelectionComponent,
    WarQuizComponent,
    CostvarianceComponent,
    DatepickerComponent,
    ChangerequestComponent,
    Step1InstanceSelectorComponent,
    Step2UserDetailsComponent,
    ChangerequesttypeComponent,
    Step1StorageSelectorComponent,
    OtpValidationCreateCrComponent,
    ChangerequestapprovalComponent,
    CrdetailsComponent,
    PatchreportComponent,
    ConfirmactionComponent,
    ViewcrsComponent,
    StatusComponent,
    CrDetailsComponent,
    NewInstanceComponent,
    OtpvalidationComponent,
    PipelinesDashComponent,
    PipelinesDevComponent,
    StatusComponent,
    PipelineComponent,
    PipelineStatusComponent,
    CreateWorkloadComponent,
    SsbComponent,
    SsbCheckComponentComponent,
    CreateJobSSBReportComponent,
    PipelineCreateComponent,
    BudgetsAllComponent,
    PipelineBuildToolsComponent,
    FindingsComponent,
    QuestionsComponent,
    WorkloadOverviewComponent,
    LensOverviewComponent,
    MilestonesComponent,
    CreateComponent,
    PropertiesComponent,
    SaveDialogComponent,
    ErpIntegrationsComponent,
    AddComponent,
    MinfyriwrComponent,
    ErpPlReportComponent,
    PoAlertComponent,
    AddPOReportComponent,
    PnlDashComponent,
    ConvertionRateComponent,
    SalesReportComponent,
    CfrcreportComponent,
    RiInventoryComponent,
    SpInventoryComponent,
    MRRDiscountComponent,
    MRRUpdateComponent,
    Minfyridetailswithsputilizedamount,
    MarginConsolidation,
    MarginDashboard,
    CustomDatePipe,
    PnlSummaryComponent,
    UnbilledPnlComponent,
    FinanacePnl,
    FinanceUnbilledPnl,
    CfrcEnableV3,
    CfrcEnableV3Update,
    SingleMasterJob,
    RDSRIComponent,
    RDSRIEnableRIUpdateComponent,
    onboardingEditUpdateComponent,
    EnableCFRCOrderComponent,
    EnableCFRCUpdateComponent,
    WrpnlComponent,
    S3Component,
    UpdateS3Component,
    NonNhaComponent,
    NonNhaPopUpComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    Bomv2Module,
    NgxPaginationModule,
    CommonModule,
  ],
  providers: [canDeactivateGuard],
  bootstrap: [AppComponent],
  exports: [NumbersOnly, DateFormat],
})
export class AppModule {}
