import { Component, Input, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css', './../../../warquiz.component.css'],
})
export class PropertiesComponent implements OnInit {
  @Input('workload') workload: any;
  @Input('lensesData') lensesData: any;
  environments: any = {
    PRODUCTION: 'Production',
    PREPRODUCTION: 'Pre Production',
  };

  constructor(private notifier: NotifierService) {}

  ngOnInit(): void {}

  commaSeperated(lenses: any) {
    if (lenses && lenses.length > 0) {
      return lenses
        .map((lens: any) => {
          return this.lensesData[lens];
        })
        .join(', ');
    } else {
      return '-';
    }
  }

  copyURL(url: string) {
    if (url) {
      const textArea = document.createElement('textarea');
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
      this.notifier.alert('Success', '', 'Copied', 'success', 5000);
    }
  }
}
