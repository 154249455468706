import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../../../api/api.service';
import { NotifierService } from '../../../../../_services/notifier.service';

declare let $: any;

@Component({
  selector: 'update-budget',
  templateUrl: './update.budget.component.html',
  styleUrls: ['./update.budget.component.css'],
})
export class UpdateBudgetComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('minfy_ui_cbs_budgets_update') attachmentElement: ElementRef;
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading: any = {};
  accountId: any;
  regionId: any;
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  @Output('fetchBudget') fetchBudget = new EventEmitter<any>();
  @Input() budget: any;
  @Input('existingBudgets') existingBudgets: any;
  budgetRaw: any;
  destroyed: boolean = false;
  current_year: string;
  previous_year: string;
  variances: any = [
    {
      id: 'enabled',
      name: 'Enabled',
    },
    {
      id: 'disabled',
      name: 'Disabled',
    },
    {
      id: 'weekly',
      name: 'Weekly',
    },
  ];

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['budget']) {
      this.budgetRaw = JSON.parse(JSON.stringify(this.budget));
      if (this.budgetRaw['eid'].length == 0) {
        this.budgetRaw['eid'] = [''];
      }
      let current_year = moment().format('YYYY-MM');
      let previous_year = moment().subtract(1, 'months').format('YYYY-MM');
      this.current_year = current_year;
      this.previous_year = previous_year;

      this.loading['bills'] = true;
      let promises: any = [];
      if (this.budgetRaw['action'] == 'save') {
        promises.push(
          this.fetchMonthlyData(
            this.budgetRaw.mid.accountId,
            this.budgetRaw.lid,
            current_year
          )
        );
        promises.push(
          this.fetchMonthlyData(
            this.budgetRaw.mid.accountId,
            this.budgetRaw.lid,
            previous_year
          )
        );
      }
      Promise.all(promises).then((values: any) => {
        this.loading['bills'] = false;
      });
      if (this.budgetRaw['action'] == 'save') {
        $('#linkedAccountIDSelect').selectpicker();
      }
    }
  }

  async ngOnInit() {
    this.budgetRaw = JSON.parse(JSON.stringify(this.budget));
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
    });
    if (this.budgetRaw['action'] == 'save') {
      await this.loadLinkedAccountIDs();
    }
    this.loadBills();
  }

  ngOnDestroy(): void {
    this.destroyed = true;
  }

  loadBills() {
    let promises: any = [];
    this.loading['bills'] = true;

    if (
      !this.checkForExists(this.budgetRaw['lid']) &&
      this.budgetRaw['action'] == 'save'
    ) {
      let lid = this.lids.find((lid: any) => {
        return lid.lid == this.budgetRaw['lid'];
      });
      if (lid && lid['eid']) {
        this.budgetRaw['eid'] = [lid['eid']];
      } else {
        this.budgetRaw['eid'] = [''];
      }
      promises.push(
        this.fetchMonthlyData(
          this.budgetRaw.mid.accountId,
          this.budgetRaw.lid,
          this.current_year
        )
      );
      promises.push(
        this.fetchMonthlyData(
          this.budgetRaw.mid.accountId,
          this.budgetRaw.lid,
          this.previous_year
        )
      );
      this.budgetRaw['budget'] = '0';
      this.budgetRaw['threshHold'] = '80';
    } else if (
      this.checkForExists(this.budgetRaw['lid']) &&
      this.budgetRaw['action'] == 'save'
    ) {
      this.fetchBudget.emit(this.budgetRaw['lid']);
    } else {
      this.loading['bills'] = true;
      promises.push(
        this.fetchMonthlyData(
          this.budgetRaw.mid.accountId,
          this.budgetRaw.lid,
          this.current_year
        )
      );
      promises.push(
        this.fetchMonthlyData(
          this.budgetRaw.mid.accountId,
          this.budgetRaw.lid,
          this.previous_year
        )
      );
    }
    Promise.all(promises).then((values: any) => {
      this.loading['bills'] = false;
    });
  }

  deleteSelectedAdditionalContact(index: number) {
    this.budgetRaw['eid'].splice(index, 1);
  }

  checkForLast(value: any, index: number) {
    if (
      value != '' &&
      value.length > 0 &&
      this.budgetRaw['eid'].length - 1 == index
    ) {
      this.budgetRaw['eid'].push('');
    } else if (
      value == '' &&
      value.length == 0 &&
      this.budgetRaw['eid'].length - 2 == index &&
      this.budgetRaw['eid'][this.budgetRaw['eid'].length - 1] == ''
    ) {
      this.budgetRaw['eid'].splice(this.budgetRaw['eid'].length - 1, 1);
    }
  }

  addAdditionalContact() {
    this.budgetRaw['eid'].push('');
  }

  trackByFn() {
    return true;
  }

  close(event: any) {
    if (event) {
      delete this.budgetRaw['mid'];
      this.hideModel.emit(this.budgetRaw);
    } else {
      this.hideModel.emit(false);
    }
  }

  checkForExists(lid: any) {
    return this.existingBudgets.indexOf(lid) > -1;
  }

  getHTML(lid: any) {
    return `
      <div class='flex_option'>
                  <span>${lid.lid} ${
      lid.accountName && lid.accountName != '-' ? `(${lid.accountName})` : ''
    }
    </span>
                ${
                  this.checkForExists(lid.lid)
                    ? `<span style='float: right;'><i class="fa fa-check-circle" style='color: #0cbc87;' aria-hidden="true"></i></span>`
                    : ''
                }
              </div>
    `;
  }

  fetchingLinkedAccountIDs: boolean = false;
  lids: any = [];
  async loadLinkedAccountIDs() {
    this.fetchingLinkedAccountIDs = true;
    let data: any = {
      action: 'fetchChildAccounts',
      mid: this.budgetRaw.mid.accountId,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchenabledmasteraccounts`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result['message'] && !result.lids) {
      this.notifier.alert('Info', '', result['message'], 'info', 5000);
      this.fetchingLinkedAccountIDs = false;
      return;
    }
    if (result.status == '1' || result.s == '1') {
      this.lids = result.lids;
      if (this.budgetRaw.action == 'save') {
        this.budgetRaw.lid = this.lids[0]['lid'];
      }
      setTimeout(() => {
        $('#linkedAccountIDSelect').selectpicker('refresh');
      }, 200);
    } else {
      this.notifier.alert(
        'Error',
        '',
        result.error || result.msg,
        'error',
        5000
      );
    }
    this.fetchingLinkedAccountIDs = false;
  }

  bills: any = {};
  async fetchMonthlyData(masterAccId: any, lid: any, monthyear: any) {
    this.loading[lid] = true;
    let data = {
      masterAccId: masterAccId,
      month_year: moment(monthyear, 'YYYY-MM').format('MM-YYYY'),
      lid: [lid],
      userType: 'C',
      action: 'supportPlanDetails',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/getservicelevelbill`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.bills[monthyear] = result['finalTotalCost'].toFixed(2);
    } else {
      this.bills[monthyear] = 'No Bill Found';
    }

    this.loading[lid] = false;
  }

  updating: boolean = false;
  async updateBudget() {
    if (!this.budgetRaw.lid) {
      this.notifier.alert(
        'Error',
        '',
        'Please select Linked Account ID',
        'error',
        5000
      );
      return;
    }
    if (this.budgetRaw.budget == null) {
      this.notifier.alert(
        'Error',
        '',
        'Please enter valid Budget value',
        'error',
        5000
      );
      return;
    }
    if (!this.budgetRaw.budget || this.budgetRaw.budget <= 0) {
      this.notifier.alert(
        'Error',
        '',
        'Please enter Budget Value',
        'error',
        5000
      );
      return;
    }
    if (this.budgetRaw.threshHold == null) {
      this.notifier.alert(
        'Error',
        '',
        'Please enter valid ThreshHold value',
        'error',
        5000
      );
      return;
    }
    if (!this.budgetRaw.threshHold || this.budgetRaw.threshHold <= 0) {
      this.notifier.alert(
        'Error',
        '',
        'Please enter ThreshHold Value',
        'error',
        5000
      );
      return;
    }
    if (
      this.budgetRaw.eid.filter((email: any) => {
        return email != '';
      }).length == 0
    ) {
      this.notifier.alert(
        'Error',
        '',
        'Please provide at leats 1 Email ID',
        'error',
        5000
      );
      return;
    }
    this.updating = true;
    let data: any = {
      a: this.budgetRaw.action,
      eid: this.budgetRaw.eid
        .filter((email: any) => {
          return email != '';
        })
        .join(','),
      mid: this.budgetRaw.mid.accountId,
      lid: this.budgetRaw.lid,
      threshHold: this.budgetRaw.threshHold,
      dailyVariance: this.budgetRaw.dailyVariance,
      budget: this.budgetRaw.budget,
      email: this.userId,
    };

    if (this.budgetRaw['action'] == 'save') {
      data['ac'] =
        this.lids.find((lid: any) => {
          return lid.lid == this.budgetRaw.lid;
        })['accountName'] || '-';
    } else {
      data['ac'] = this.budgetRaw['accountName'];
    }

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/budgetalarm`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.status == '1' || result.s == '1') {
      this.close(true);
    } else {
      this.notifier.alert(
        'Error',
        '',
        result.error || result.msg,
        'error',
        5000
      );
    }

    this.updating = false;
  }
}
