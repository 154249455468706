<div class="card">
  <div class="card-header">
    <span class="title" style="font-size: 22px; vertical-align: middle">Consolidated Report</span>
  </div>
  <div class="card-body" style="width: 100%;">
    <div class="report_container">
      <div class="report_main">
        <div *ngIf="loading" class="loader_container">
          <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 20px; margin: 10px;">
        </div>
        <div class="donwload_section">
          <div [ngStyle]="{display: !loading && reportStatus.sUrl ? 'block': 'none'}">
            Report is ready to download
          </div>
          <div [ngStyle]="{display: !loading && !reportStatus.sUrl ? 'block': 'none'}">
            {{reportStatus.msg}}
          </div>
          <div class="report_actions">
            <button [ngClass]="{active: !loading && reportStatus.sUrl}" class="btn btn-success-soft" type="button"
              (click)="downloadReport()">
              <i class="fa fa-download" aria-hidden="true"></i>
              <span>
                Download
              </span>
            </button>
            <button [ngClass]="{active: !loading && !reportStatus.sUrl}" class="btn btn-primary-soft" type="button"
              (click)="load()">
              <i class="fa fa-refresh" aria-hidden="true"></i>
              <span>
                Refresh
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .report_container {
    display: flex;
    justify-content: center;
    margin: 20px;
  }

  .report_main {
    padding: 20px;
    border: 1px solid #dad9d9;
    max-width: 80vw;
    min-width: 600px;
  }

  .loader_container {
    display: grid;
    place-items: center;
  }

  .donwload_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .report_actions button {
    display: none;
  }

  .report_actions button.active {
    display: flex !important;
    gap: 12px !important;
    justify-content: flex-start !important;
    align-items: center !important;
    border-radius: 0px !important;
  }

  .report_actions button span {
    color: inherit !important;
  }
</style>