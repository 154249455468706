import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as moment from 'moment';
import { DownloadAll } from './downloadAll';

declare let flatpickr: any;
declare let window: any;
declare let $: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './organizations.component.html',
})
export class OrganizationsCBSComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accounts: any = [];

  accountId: any;
  regionId: any;
  currentMessage: any;

  headers: any = [
    {
      id: 'childAccountId',
      name: 'Organization ID',
      click: 'loadChildAccounts',
    },
    {
      id: 'childAccountName',
      name: 'Organization Name',
    },
    {
      id: 'childAccountCount',
      name: 'Child Account Count',
    },
  ];

  funRef: any = {};

  childAccountHeaders: any = [
    {
      name: 'Account ID',
      id: 'awsAccountId',
    },
    {
      name: 'Account Name',
      id: 'awsAccountName',
    },
    {
      name: 'Email',
      id: 'email',
    },
    {
      name: 'Status',
      id: 'status',
    },
    {
      name: 'Joined Time Stamp',
      id: 'joinedTimeStamp',
    },
  ];

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.funRef = {
      loadChildAccounts: this.loadChildAccounts,
    };

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  interval: any = null;
  async downloadAll() {
    let apiURL: string = 'https://api.swayam.cloud/v3/admin/billing/orgreport';

    let data: any = {
      a: 'fetchsummary',
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let downloadall = new DownloadAll(this.apiService, apiURL, data, header);

    this.notifier.alert('Info', '', 'Download Requested', 'info', 5000);

    this.interval = setInterval(async () => {
      let result: any = await downloadall.requestDownload();

      if (result.status) {
        if (result.message == 'success') {
          clearInterval(this.interval);
          this.interval = null;
        }
      } else {
        this.notifier.alert('Info', '', result.message, 'info', 5000);
        clearInterval(this.interval);
        this.interval = null;
      }
    }, 5000);
  }

  async load() {
    this.notifier.loading(true);

    let data = {};
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://rddbcejif4.execute-api.ap-south-1.amazonaws.com/dev/msopsusers/billing/getmasteraccountandcountwithsts`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.accounts = result.accountDetails;
      this.accounts = this.accounts.map((account: any) => {
        account['expanded'] = false;
        account['loading'] = false;
        account['data'] = [];
        return account;
      });
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async loadChildAccounts(ref: any, param: any) {
    if (param.expanded) {
      param.expanded = false;
      return;
    }
    param['loading'] = true;

    param.expanded = true;
    ref.notifier.loading(true);
    let data = {
      accountId: param.childAccountId,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://rddbcejif4.execute-api.ap-south-1.amazonaws.com/dev/msopsusers/billing/listawsaccountswithstsassumerole`;
    let result = await ref.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1' || result.accountDetails) {
      param['data'] = result.accountDetails;
    } else {
      ref.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    ref.notifier.loading(false);
    param['loading'] = true;
  }

  async exportMainData(param: any) {
    this.notifier.loading(true);
    let data = {
      accountId: param.childAccountId,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://rddbcejif4.execute-api.ap-south-1.amazonaws.com/dev/msopsusers/billing/organisationdownloadcsv`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1' || result.accountDetails) {
      let element = document.createElement('table');
      element.innerHTML += `
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>Account ID</th>
            <th>Account Name</th>
            <th>Status</th>
            <th>Email</th>
            <th>Joined Method</th>
            <th>Joined Time Stamp</th>
            <th>Customer Name</th>
            <th>Sales Spoc</th>
            <th>Company Name</th>
            <th>Account Type</th>
          </tr>
        </thead>
        <tbody>
          ${result.accountDetails.map((account: any, index: any) => {
            return `
              <tr>
                <td>${index + 1}</td>
                <td>${account.awsAccountId || ''}</td>
                <td>${account.awsAccountName || ''}</td>
                <td>${account.status || ''}</td>
                <td>${account.email || ''}</td>
                <td>${account.joinedMethod || ''}</td>
                <td>${account.joinedTimeStamp || ''}</td>
                <td>${account.customerName || ''}</td>
                <td>${account.salesSpoc || ''}</td>
                <td>${account.companyName || ''}</td>
                <td>${account.accountType || ''}</td>
              </tr>
            `;
          })}
        </tbody>
      `;
      element.style.display = 'none';
      element.setAttribute('id', 'downloadTableOnly');
      document.body.appendChild(element);
      setTimeout(() => {
        window.exportToExcel(
          'downloadTableOnly',
          `${param.childAccountId}_Accounts`,
          'xlsx'
        );
        document.body.removeChild(element);
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  callFunction(param: any, id: any) {
    this.funRef[param](this, id);
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    this.currentMessage.unsubscribe();
  }
}
