<div class="card">
  <h5 class="card-header">Users</h5>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; align-items: center;" *ngIf="writeAccess">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;"
        (click)="createUser()">Create</button>
    </div>


    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of urlPrefix == 'client' ? userHeaders: adminUsersheader">{{h.name}}</th>
          <th>Update</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let i of userList; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of urlPrefix == 'client' ? userHeaders: adminUsersheader">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
          <td><span class="btn" (click)="updateUserAction(i)">Update</span></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<update-user *ngIf="writeAccess && updateUser" [action]="userAction" [userDetails]="updateUser"
  (hideModel)="hideUpdateUser($event)"></update-user>