<div class="card mt-20" style="border: none;" *ngIf="!showDetailKB">
  <h5 class="card-header">Knowledge Base</h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->
    <div class="support_filter" style="justify-content: flex-end;">
      <div class="support_select">
        <div class="support_header">Filter</div>
        <div class="support_types_container">
          <div>
            <input type="text" [(ngModel)]="searchKey" />
            <button class="btn btn-light" (click)="load()">
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-docs table-hover mt-20">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">
            {{h.name}}
            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,kbList)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': kbList.length > 0 ? 'table-row-group' : 'none'}"
        *ngFor="let i of kbList; let j = index">
        <tr *ngIf="notifier.filterTableColums(headers,i)">
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': kbList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="card mt-20" style="border: none;" *ngIf="showDetailKB && kbDetails">
  <h5 class="card-header">
    <button class="btn btn-light" (click)="goBack()">
      < Back</button>
        <span>KB Details - {{ kbDetails.ticket_id }}</span>
  </h5>
  <div class="card-body">
    <div class="panel panel-default">

      <div class="panel-body">
        <div class="row">
          <div class="col-md-6">
            <b>Subject</b>
            <p id="kbsubject">
              {{kbDetails.title}}
            </p>
          </div>
          <div class="col-md-6">
            <b>Status</b>
            <p id="kbstatus">{{kbDetails.status}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b>KB Id</b>
            <p id="kbid">{{kbDetails.id}}</p>
          </div>
          <div class="col-md-6">
            <b>Version</b>
            <p id="kbVersion">-</p>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-default">
      <div class="panel-heading" style="font-size:medium">Description</div>
      <div class="panel-body" style="max-height: 500px;overflow-y: auto;">
        <div class="row">
          <div class="col-md-12" id="kbDescription" [innerHTML]="kbDetails.body">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>