<div class="fixed_container">
  <div class="side_container">
    <div class="fixed_body">
      <div class="fixed_header">
        <p>
          Download Reports
        </p>
      </div>
      <div class="fixed_body_container">
        <table style="border-collapse: collapse;">
          <thead>
            <tr>
              <th style="width: 50px;">
              </th>
              <th>
                Linked Account ID
              </th>
              <th>

              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let lid of data['lids']">
              <td style="padding: 10px; width: 50px;">
                <input type="checkbox" [(ngModel)]="lid.checked">
              </td>
              <td>
                {{lid.lid}}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="download_actions">
      <button class="btn btn_blue" (click)="download()">Download</button>
      <button class="btn btn-light" (click)="close()">Cancel</button>
    </div>
  </div>
</div>