<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader mb-2">Save Milestone</div>
    <div class="KBBody">
      <div class="col-mb-6 mb-2">
        <label>Workload</label>
        <div>{{workload['name']}} ({{workload['id']}})</div>
      </div>
      <div class="col-mb-6 mb-10">
        <label>Milestone Name </label>
        <span>
          <input class="form-control custom-input" type="text" [(ngModel)]="scan_name">
        </span>
      </div>
      <div class="KBActions">
        <button class="btn btn-success" *ngIf="!loading" (click)="yes()">Save</button>
        <button class="btn btn-success" *ngIf="loading" [attr.disabled]="true">
          <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin-right: 10px;" />
          Save
        </button>
        <button class="btn btn-light" (click)="no(false)">Cancel</button>
      </div>
    </div>
  </div>
</div>