<table class="table table-docs" style="width: 100%;">
  <thead>
    <tr>
      <th></th>
      <th>OS</th>
      <th>Instance Type</th>
      <th>Instance Count</th>
      <th>AWS Total Hours</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let instance of instances; let i = index">
      <td>
        <input type="checkbox" [(ngModel)]="instance.selected" (change)="checkForSelection(i)">
      </td>
      <td>{{instance.os}}</td>
      <td>{{instance.instanceType}}</td>
      <td>{{instance.instancesCount}}</td>
      <td>{{instance.awsTotalHours}}</td>
    </tr>
  </tbody>
</table>