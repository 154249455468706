<div class="card mt-20" style="border: none; position: relative; height: 100%;">
  <h5 class="card-header">RI Report</h5>
  <div class="card-body">
    <div class="ricov_date_container">
      <input type="text" id="monthDate" [(ngModel)]="dateRange">
    </div>
    <div class="ridata_container">
      <div class="grid-child">
        <div style="margin-bottom: 15px;">
          <h5>Billing Type</h5>
        </div>
        <div class="type_option" [ngClass]="{'typeSelecetd': type == 'customerRI'}" (click)="changeType('customerRI')">
          <div class="type_text">
            Customer RI
          </div>
        </div>
        <div class="type_option" [ngClass]="{'typeSelecetd': type == 'customerRIUnUsed'}"
          (click)="changeType('customerRIUnUsed')">
          <div class="type_text">
            Customer RI(Unused RI)
          </div>
        </div>
        <div class="type_option" [ngClass]="{'typeSelecetd': type == 'minfyRI'}" (click)="changeType('minfyRI')">
          <div class="type_text">
            Minfy RI
          </div>
        </div>
        <div class="type_option" [ngClass]="{'typeSelecetd': type == 'od'}" (click)="changeType('od')">
          <div class="type_text">
            OD
          </div>
        </div>
        <div class="type_option" [ngClass]="{'typeSelecetd': type == 'spot'}" (click)="changeType('spot')">
          <div class="type_text">
            SP
          </div>
        </div>
      </div>
      <div class="grid-child">
        <div style="margin-bottom: 15px;">
          <h5>Accounts</h5>
        </div>
        <div class="type_option" *ngFor="let account of getAccounts()"
          [ngClass]="{'typeSelecetd': selectedAccount.accountId == account}" (click)="changeSelectedAccount(account)">
          <div class="type_text">
            {{account}}
          </div>
        </div>
      </div>
      <div class="grid-child">
        <div style="margin-bottom: 15px;">
          <h5>Report</h5>
        </div>
        <div class="table_container">
          <table class="table table-docs table-hover">
            <thead>
              <tr>
                <th *ngFor="let h of headers">{{ h.name }}</th>
              </tr>
            </thead>

            <tbody [ngStyle]="{'display': selectedAccount.data.length > 0 ? 'table-row-group' : 'none'}">
              <tr *ngFor="let i of selectedAccount.data">
                <td *ngFor="let h of headers">
                  <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
                </td>
              </tr>
              <tr>
                <td *ngFor="let h of headers">
                  <span *ngIf="!h.click" style="font-weight: bold;" [innerHTML]="sumAccount()[h.id]"> </span>
                </td>
              </tr>
            </tbody>
            <tbody [ngStyle]="{'display': selectedAccount.data.length == 0 ? 'table-row-group' : 'none'}">
              <tr *ngIf="!loadingReportData">
                <td [attr.colspan]="headers.length">
                  <div style="text-align: center;">No Data Found</div>
                </td>
              </tr>
              <tr *ngIf="loadingReportData">
                <td [attr.colspan]="headers.length">
                  <div style="text-align: center; position: relative; height: 80px;">
                    <img src="../assets/img/loading.svg"
                      style="width: 80px; height: 80px; position: absolute; top: 0; left: 50%; transform: translateX(-50%);"
                      alt="" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>