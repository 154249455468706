<div class="card">
  <h5 class="card-header">Exceptions</h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">{{h.name}}</th>
          <th *ngIf="writeAccess">Update</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': exceptions.length > 0 ? 'table-row-group' : 'none'}"
        *ngFor="let i of exceptions; let j = index">
        <tr>
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
          <td *ngIf="writeAccess"><button class="btn btn-light" (click)="triggerUpdateRule(i)"><i
                class="fa fa-pencil"></i></button> </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': exceptions.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 2">
            <div style="text-align: center;">No Exceptions Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<updaterule-anomalies *ngIf="updateRule" [rule]="updateRule" (hideModel)="hideUpdateException($event)">
</updaterule-anomalies>