import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import * as moment from 'moment';
declare let $: any;
declare let window: any;
declare let Jhxlsx: any;
@Component({
  templateUrl: './marginconsolidation.component.html',
})
export class MarginConsolidation implements OnInit, OnDestroy {
  currentMessage: any;
  accountId: string;
  marginConsolidationData: any;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  discountType: any;
  mainHeader: any = [
    {
      id: 'key',
      name: 'Margin percentage',
      sort: { sort: true, direction: 'Asc' },
      filter: true,
      clickId: 'key',
      click: (value: any) => this.toggleExpanded(value),
    },
    {
      id: 'value',
      name: 'Total count',
      sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
  ];
  subheader: any = [
    {
      id: 'mId',
      name: 'Master account ID',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'lId',
      name: 'Child account ID',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'awsTotalCost',
      name: 'AWS total cost',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'refund',
      name: 'Margin',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'marginPerc',
      name: 'Margin Percentage',
      sort: { sort: true, direction: 'Asc' },
    },
  ];
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    $('.datetimepicker').val(moment().startOf('month').format('MM-YYYY'));
    this.discountType = 'All';
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      }
    });
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'mm-yyyy',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });
    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) => {
        this.load();
      },
    };
  }

  async load() {
    this.fetchMarginConsolidationData();
  }

  async fetchMarginConsolidationData() {
    this.notifier.loading(true);
    let data = {
      a: 'consolidation',
      m: $('#monthDate').val(),
      discountType: this.discountType,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiUrl = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchchildaccwithcosts/fetchchildaccwithcostsv2`;
    let result = await this.apiServer.postDataPromis(apiUrl, data, header);
    if (result.status == '1' || result.s == '1') {
      if (result.marginConsolidation) {
        this.marginConsolidationData = Object.keys(
          result.marginConsolidation
        ).map((key: string) => {
          return {
            key: key,
            value: result.marginConsolidation[key].length.toString(),
            data: result.marginConsolidation[key],
          };
        });
        this.notifier.loading(false);
      } else {
        this.notifier.alert(
          'Info',
          '',
          'Margin consolidation data is missing.',
          'info',
          5000
        );
        this.notifier.loading(false);
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.marginConsolidationData = [];
      // set empty value for this
      this.notifier.loading(false);
    }
  }

  expandedState: { [key: string]: boolean } = {};

  toggleExpanded(key: string) {
    this.expandedState[key] = !this.expandedState[key];
  }

  exportEach(maps: any) {
    this.notifier.loading(true);
    let margin_perc: any;
    let data: any = [
      {
        sheetName: 'Margin consolidation Report',
        data: [
          this.subheader.map((h: any) => {
            return { text: h.name };
          }),
        ],
      },
    ];
    let sheetData: any = maps;
    let headers: any = this.subheader;

    sheetData.map((item: any) => {
      margin_perc = item['marginPerc'];
      let data_: any = [];
      headers.forEach((h: any) => {
        data_.push({ text: h.alter ? h.alter(item, h.id) : item[h.id] });
      });

      data[0]['data'].push(data_);
    });

    var options = {
      fileName:
        'Margin consolidate excel' +
        ' - ' +
        margin_perc +
        '(' +
        $('#monthDate').val() +
        ')',
    };

    Jhxlsx.export(data, options);
    this.notifier.loading(false);
  }
  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  s3Url: any;
  async downloadAll() {
    this.notifier.loading(true);
    let data = {
      a: 'export_consolidation',
      m: $('#monthDate').val(),
      discountType: this.discountType,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchchildaccwithcosts/fetchchildaccwithcostsv2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.s3Url = result.s3Url;
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.notifier.loading(false);
    }
    if (this.s3Url) {
      window.open(this.s3Url, '_blank');
      this.notifier.loading(false);
    } else {
      console.error('error in fetching excel');
      this.notifier.loading(false);
    }
  }
  onDiscountTypeChange(event: any) {
    this.discountType = event.target.value;
    this.fetchMarginConsolidationData();
  }
  async refresh() {
    console.log('refresh start');
    this.discountType = 'All';
    this.fetchMarginConsolidationData();
  }
}
