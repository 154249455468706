import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../../../api/api.service';
import { NotifierService } from '../../../../../_services/notifier.service';
declare let window: any;
@Component({
  selector: 'step5-uploadbom-v2',
  styleUrls: [
    './../../upload.bom.component.css',
    './step5.bomupload.component.css',
  ],
  templateUrl: './step5.bomupload.component.html',
})
export class Step5UploadBOMv2Component implements OnInit, OnDestroy {
  @Input('headers') headers: any;
  @Output('launch') launch: any = new EventEmitter<any>();
  @Input('startdate') startDate: any;
  @Input('dateRange') dateRange: any;
  @Input('dollarValue') dollarValue: any;
  @Input('bom_id') bom_id: any;
  @Output('newInstance') newInstance: any = new EventEmitter<boolean>();
  CRType: string;
  selectedHeaders: any = [];
  @Input('pricingStrategy') pricingStrategy: any;
  userId = localStorage.getItem('eId');
  plans: any = [
    {
      plan: 'OD',
      duration: 2,
      slug: 'odCosts',
    },
    {
      plan: '1YNU',
      duration: 12,
      slug: 'ri1Yr',
    },

    {
      plan: '1YCNU',
      duration: 12,
      slug: 'riconv1Yr',
    },
    { plan: 'S1YNU', duration: 12, slug: 'cost_1YrSavings' },
    { plan: 'S1YAU', duration: 12, slug: 'cost_1YrAuSavings' },
    { plan: 'S1YPU', duration: 12, slug: 'cost_1YrPuSavings' },
    {
      plan: '3YNU',
      duration: 36,
      slug: 'ri3Yr',
    },
    {
      plan: '3YCNU',
      duration: 36,
      slug: 'riconv3Yr',
    },
    { plan: 'S3YNU', duration: 36, slug: 'cost_3YrSavings' },
    { plan: 'S3YAU', duration: 36, slug: 'cost_3YrAuSavings' },
    { plan: 'S3YPU', duration: 36, slug: 'cost_3YrPuSavings' },
    {
      plan: '1 YNU Spot Min',
      duration: 12,
    },
    {
      plan: '1 YCNU Spot Min',
      duration: 12,
    },
    {
      plan: '1 YNU Spot Max',
      duration: 12,
    },
    {
      plan: '1 YCNU Spot Max',
      duration: 12,
    },
    {
      plan: '3 YNU Spot Min',
      duration: 36,
    },
    {
      plan: '3 YCNU Spot Min',
      duration: 36,
    },
    {
      plan: '3 YNU Spot Max',
      duration: 36,
    },
    {
      plan: '3 YCNU Spot Max',
      duration: 36,
    },
  ];

  loading: boolean = true;
  regionId: any;
  accountId: any;
  currentMessage: any;
  months: any = {};

  data: any;
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.data = {};
    this.selectedHeaders = this.headers.filter((header: any) => {
      if (header.id != 'exclude') {
        return true;
      }
      return false;
    });

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
      this.load();
    });
    this.loading = false;
  }

  stratLess: any = ['network'];
  checkForStratLess(id: string) {
    return this.stratLess.indexOf(id) > -1;
  }

  load() {
    Object.keys(this.pricingStrategy).forEach((key: any) => {
      if (
        (this.pricingStrategy[key] &&
          this.pricingStrategy[key]['plans'] &&
          this.pricingStrategy[key]['plans'].length > 0) ||
        this.stratLess.indexOf(this.pricingStrategy[key]['env_type']) > -1
      ) {
        this.fetchPrices(
          key,
          this.pricingStrategy[key]['env_type'],
          this.stratLess.indexOf(this.pricingStrategy[key]['env_type']) > -1
        );
      } else {
        return;
      }
    });
  }

  loadCol(env: any) {
    let arr: any[] = [];
    let temp: any = JSON.parse(JSON.stringify(this.pricingStrategy));
    if (temp[env] && temp[env]['plans'] && temp[env]['plans'].length > 0) {
      temp[env]['plans'] = temp[env]['plans'].map((strat: any) => {
        if (strat.plan == 'OD' && strat.period == 'Months') {
          let moment_obj = moment(this.dateRange.date, 'DD-MMM-YYYY').add(
            strat.duration,
            'months'
          );
          strat.duration = moment
            .duration(
              moment_obj.diff(moment(this.dateRange.date, 'DD-MMM-YYYY'))
            )
            .asDays();
          strat.duration = Math.ceil(strat.duration);
        }
        return strat;
      });
    }
    let dt = temp[env]['plans'].filter((strat: any) => {
      return strat.plan == 'OD';
    });
    dt.forEach((strat: any) => {
      let obj: any = {};
      if (!this.startDate) {
        this.startDate = moment().format('DD-MMM-YYYY');
      }

      let start = moment(this.startDate, 'DD-MMM-YYYY').add(
        strat.duration,
        'days'
      );
      let end = moment(this.startDate, 'DD-MMM-YYYY').startOf('month');

      let months = start.diff(end, 'months', true);
      months = Math.ceil(months);

      obj['colspan'] = months;
      obj['hours_per_month'] = strat['hours_per_month'];
      arr.push(obj);
    });

    return arr;
  }

  getSimilarInstances(os: any, env: any) {
    return this.data[env.key].filter((os_: any) => {
      return (
        os_.instanceName == os.instanceName &&
        os.volumeSize == os_.volumeSize &&
        os.os == os_.os &&
        os_.launchStatus == false
      );
    });
  }

  confirmOs: any = [];
  clickedInstance: any;
  launchInstance(os: any, env: any) {
    os['env'] = env;
    this.clickedInstance = os;

    const similar = this.getSimilarInstances(os, env);
    if (similar && similar.length > 1) {
      this.confirmOs = similar;
    } else {
      this.triggerLaucnhInstances([os]);
    }
  }

  triggerLaucnhInstances(os: any) {
    this.launch.emit(os);
  }

  confirmCheck(event: any) {
    if (event == 'Single') {
      this.triggerLaucnhInstances([this.clickedInstance]);
    } else if (event == true) {
      this.triggerLaucnhInstances([...this.confirmOs]);
    }
    this.confirmOs = [];
  }

  getIntances(header: string) {
    if (this.data[header]) {
      return this.data[header];
    } else {
      return [];
    }
  }

  getTotalColumns(value: any) {
    let total: any = [];
    this.pricingStrategy[value].forEach((strat: any) => {
      let arr = Array(this.getDuration(strat.plan).duration)
        .fill(1)
        .map((x, i) => {
          return { month: i, plan: strat.plan };
        });
      total = [...total, ...arr];
    });
    return total;
  }

  getStringArrayValue(str: string, index: number) {
    return Number(str.replace(/\[|\]/g, '').split(',')[index]).toFixed(3);
  }

  toFixed(value: any) {
    return Number(value).toFixed(3);
  }

  getPrice(value: any, strategy: string, index: number) {
    let strat = this.getDuration(strategy);
    let dummy: any = { month: index, value: 0.0 };
    if (!strat.slug) {
      return '';
    }
    let strat_ = strat.slug;
    if (value[strat_]) {
      let obj = value[strat_].find((i: any) => {
        return i.month == index;
      });
      if (obj) {
        obj['value'] =
          typeof obj['value'] == 'string'
            ? Number(obj['value']).toFixed(4)
            : obj['value'].toFixed(4);
        return obj;
      } else {
        return dummy;
      }
    } else {
      return dummy;
    }
  }

  // setPrice(event: any, value: any, strategy: string, index: number) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   let strat = this.getDuration(strategy);
  //   let strat_ = strat.slug;
  //   if (value[strat_]) {
  //     let obj = value[strat_].find((i: any) => {
  //       return i.month == index;
  //     });
  //     if (obj) {
  //       obj['value'] = Number(event.target.value);
  //     }
  //   }
  // }

  getColumnTotal(value: any, month: number, plan: string) {
    let total = 0;
    let strat = this.getDuration(plan);
    if (!strat.slug) {
      return '';
    }
    let strat_ = strat.slug;

    this.getIntances(value).forEach((os: any) => {
      let obj = os[strat_].find((i: any) => {
        return i.month == month;
      });
      if (obj) {
        total +=
          typeof obj['value'] == 'string' ? Number(obj['value']) : obj['value'];
      }
    });
    return total.toFixed(2);
  }

  getArrayByLength(len: number): number[] {
    return Array(len)
      .fill(1)
      .map((x, i) => i);
  }

  getArray(plan: string) {
    return Array(this.getDuration(plan).duration)
      .fill(1)
      .map((x, i) => i); // [0,1,2,3,4]
  }

  getDuration(plan_str: string) {
    return this.plans.find((plan: any) => {
      return plan.plan == plan_str;
    });
  }

  instancesSelected: any = {};
  selectInstance(value: any) {
    if (this.instancesSelected[value.guid]) {
      delete this.instancesSelected[value.guid];
      return;
    }
    this.instancesSelected[value.guid] = value;
  }

  getSelectedInstances() {
    return Object.keys(this.instancesSelected);
  }

  launchSelected() {
    this.triggerLaucnhInstances(Object.values(this.instancesSelected));
  }

  checkForChecked(guid: string) {
    return this.instancesSelected[guid] ? true : false;
  }

  async loadInstanceStatus(os: any) {
    if (!os) {
      return false;
    }
    let data = {
      action: 'ec2_status',
      account_id: this.accountId,
      region_name: this.regionId,
      resource_ids: [os.resourceId],
      login_user_name: this.userId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick(
      'BOMv2.Components.Step5',
      'EC2 InstanceStatus Fetched'
    );
    let apiURL = `${APIService.API_ENDPOINTV3}/admin/operations/ec2`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1') {
      if (result[os['resourceId']]) {
        os['State'] = result[os['resourceId']]['state'];
      }
    } else {
      if (result.error_message.indexOf('InvalidInstanceID.NotFound') > -1) {
        os['State'] = 'terminated';
      } else {
        this.notifier.alert(
          'Error',
          '',
          result.error_message || result.message,
          'error',
          5000
        );
        os.State = '-';
      }
    }
    return true;
  }

  loadingObj: any = {};
  async fetchPrices(sheet_name: string, env: string, raw: boolean = false) {
    return new Promise(async (resolve: any, reject: any) => {
      this.loadingObj[sheet_name] = true;
      if (this.months[sheet_name]) {
        delete this.months[sheet_name];
      }
      let selectedClientData = JSON.parse(localStorage.getItem('clientList'));

      let client_id = selectedClientData.find((client: any) => {
        return client.emailId == localStorage.getItem('selectedClientList');
      })['clientIdList'];
      let data = {
        action: 'fetch',
        accountId: this.accountId,
        clientId: client_id,
        sheetName: sheet_name,
        env: env,
        region: this.regionId,
        bomId: this.bom_id,
        allPrices: raw,
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      window.analyticsSendEventClick(
        'BOMv2.Components.Step5',
        'Prices Fetched'
      );
      let apiURL = `${APIService.API_ENDPOINTV3}/admin/bom/pricingmodals`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        this.data[sheet_name] = {};
        if (result.bomData) {
          this.data[sheet_name] = result.bomData;
          if (!this.months[sheet_name]) {
            this.months[sheet_name] = {};
          }
          this.data[sheet_name]
            .filter((os: any) => {
              return (
                os['resourceId'] &&
                os['resourceId'] != '' &&
                os['resourceId'] != '-'
              );
            })
            .forEach((os: any) => {
              os['State'] = 'LOADING';
              this.loadInstanceStatus(os);
            });
          this.plans.forEach((plan: any) => {
            this.data[sheet_name].forEach((os: any) => {
              if (Object.keys(os).indexOf(plan.slug) > -1) {
                if (
                  this.months[sheet_name][plan.plan] &&
                  this.months[sheet_name][plan.plan]['total'] <
                    os[plan.slug].length
                ) {
                  this.months[sheet_name][plan.plan]['total'] =
                    os[plan.slug].length;
                } else if (!this.months[sheet_name][plan.plan]) {
                  this.months[sheet_name][plan.plan] = {};
                  this.months[sheet_name][plan.plan]['start'] =
                    os[plan.slug][0]['month'];
                  this.months[sheet_name][plan.plan]['total'] =
                    os[plan.slug].length;
                  this.months[sheet_name][plan.plan]['dates'] = os[
                    plan.slug
                  ].map((date: any) => {
                    return date.monthyear || date.monthYear;
                  });
                  this.months[sheet_name][plan.plan]['indexs'] = os[
                    plan.slug
                  ].map((date: any) => {
                    return date.month;
                  });
                  this.months[sheet_name][plan.plan]['totalDays'] = os[
                    plan.slug
                  ].map((date: any) => {
                    return date.totalDays;
                  });
                }
              }
            });
          });
        }
      } else {
        this.months[sheet_name] = {};
        // this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
      this.loadingObj[sheet_name] = false;

      console.log(this.pricingStrategy);

      resolve(true);
    });
  }

  parsedDate(value: string) {
    return moment(value, 'M-YYYY').format('MMM YY');
  }

  changeRequestInstance: any;
  async changeRequest(instance: any, type: string) {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const account = selectedAccountData.data;
        if (!account.jira_project_id || !account.jira_org_id) {
          this.notifier.alert(
            'Info',
            '',
            'Managed Services support via SWAYAM is not enabled for your account. Please contact the admin.',
            'info',
            5000
          );
          this.loading = false;
          return;
        }
      }
    } catch (err) {}
    this.CRType = type;
    if (type == 'CRFlow') {
      this.changeRequestInstance = instance;
    } else if (type == 'New Instance') {
      this.newInstance.emit(true);
    }
  }

  getDiffDays(startdate: string, enddate: string) {
    var current = moment(startdate, 'MMM DD, YYYY').startOf('day');
    var given = moment(enddate, 'MMM DD, YYYY').endOf('day');

    //Difference in number of days
    return Math.ceil(moment.duration(given.diff(current)).asDays());
  }

  getMoreDetails(
    monthyear: string,
    totaldays: any,
    length: number,
    current: number
  ) {
    // let startdate: string;
    // let enddate: string;

    // if (current == 0) {
    //   startdate = moment(monthyear, 'M-YYYY')
    //     .endOf('month')
    //     .subtract(totaldays - 1, 'days')
    //     .format('MMM DD, YYYY');

    //     enddate = moment(startdate, 'MMM DD, YYYY').endOf('month').format('MM ')
    // }

    // if (current == length - 1) {
    //   enddate = moment(monthyear, 'M-YYYY')
    //     .startOf('month')
    //     .add(totaldays, 'days')
    //     .format('MMM DD, YYYY');
    // }

    // if (current != length - 1 && current != 0) {
    //   startdate = moment(monthyear, 'M-YYYY')
    //     .startOf('month')
    //     .format('MMM DD, YYYY');
    //   enddate = moment(monthyear, 'M-YYYY')
    //     .endOf('month')
    //     .format('MMM DD, YYYY');
    // }

    return `Total Days: ${totaldays}`;
  }

  getSortedKeys(obj: any) {
    if (obj == undefined) {
      return;
    }
    let dt: any = [];
    Object.keys(obj).forEach((key: any) => {
      obj[key]['plan'] = key;
      dt.push(obj[key]);
    });
    dt = dt.sort((p1: any, p2: any) => {
      return p1.start - p2.start;
    });
    let keys = dt.map((obj: any) => {
      return obj.plan;
    });
    return keys;
  }

  getKeys(obj: any) {
    if (obj == undefined) {
      return [];
    }
    let keys = Object.keys(obj);
    this.getSortedKeys(obj);
    return keys;
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  ngOnDestroy(): void {}
}
