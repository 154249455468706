import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

declare let $: any;
declare let window: any;
@Component({
  templateUrl: './minfyridetails.component.html',
  styleUrls: ['./minfyridetails.component.css'],
})
export class MinfyRiDetailsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading: boolean = true;
  list: any = [];
  filterType: string = 'ALL';

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  funRef: any = {};
  dateRange: string;
  customHeaders: any = {
    'Total OD': {
      sort: { sort: true, direction: 'Asc' },
    },
    'Last 7 Days OD': {
      sort: { sort: true, direction: 'Asc' },
    },
  };

  headers: any = [
    {
      id: 'accountId',
      name: 'Account ID',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
    },
    {
      id: 'accountName',
      name: 'Account Name',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
    },
    {
      id: 'sp_purchased',
      name: 'SP Purchased',
      total: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'sp_utilized',
      name: 'SP Utilized (%)',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'sp_covered',
      name: 'SP Covered (%)',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'sp_unutilized',
      name: 'SP Un-utilized (%)',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'ri_purchased',
      name: 'RI Purchased',
      total: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'ri_utilized',
      name: 'RI Utilized (%)',
      sort: { sort: true, direction: 'Asc' },
    },
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private zone: NgZone
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.funRef = {};

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null) {
        return;
      }

      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load(true);
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load(true);
      }
    });

    $('.datetimepicker').val(moment().startOf('month').format('YYYY-MM'));
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        minViewMode: 1,
        format: 'yyyy-mm',
        orientation: 'bottom auto',
        endDate: new Date(),
      })
      .on('changeDate', function (selected: any) {
        window.angularComponentReference.zone.run(() => {
          window.angularComponentReference.componentFn();
        });
      });

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) => {
        this.load();
      },
      component: this,
    };
  }

  ngOnDestroy(): void {
    this.dateRange = 'destroyed';
    this.currentMessage.unsubscribe();
  }

  position: any = {
    x: '0px',
    y: '0px',
    overflow: false,
    overflowwidth: '0px',
    pointer: '0px',
    width: '',
    height: '',
  };
  setPosition(event: any) {
    if (
      window.innerWidth <
      event.target.querySelector('.message_back').offsetWidth
    ) {
      this.position.overflow = true;

      if (
        window.innerWidth >
        event.target.querySelector('.message_back').offsetWidth - 110
      ) {
        this.position.overflowwidth = window.innerWidth - 50 + 'px';
      } else {
        this.position.overflowwidth = window.innerWidth - 110 + 'px';
      }
    }
    if (
      window.innerWidth - event.clientX >
      event.target.querySelector('.message_back').offsetWidth / 2
    ) {
      this.position.x =
        window.innerWidth -
        event.clientX -
        event.target.querySelector('.message_back').offsetWidth / 2 +
        'px';
    } else {
      this.position.x = 60 + 'px';
    }
    this.position.pointer =
      window.innerWidth -
      event.clientX +
      event.offsetX -
      event.target.offsetWidth +
      'px';
    this.position.y =
      event.clientY + event.target.offsetHeight - event.offsetY + 'px';
    this.position.width =
      event.target.querySelector('.message_grid').offsetWidth + 30 + 'px';
    this.position.height =
      event.target.querySelector('.message_grid').offsetHeight + 30 + 'px';
    if (
      window.innerHeight - event.clientY <
      event.target.querySelector('.message_grid').offsetHeight
    ) {
      this.position.height = window.innerHeight - event.clientY;
    }
  }

  dateParser(date: string) {
    return moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY');
  }

  toFixed(value: string | number, decimal: number) {
    return Number(value).toFixed(decimal);
  }

  getColumnTotal(h: any) {
    let total: number = 0;
    this.list.forEach((account: any) => {
      if (account[h] && account[h] != '-') {
        total += Number(account[h]);
      }
    });
    return total.toFixed(2);
  }

  lastdate: string = '';
  async load(refresh: boolean = false, nextToken: any = undefined) {
    if (refresh || !nextToken) {
      this.list = [];
    }
    this.loading = true;
    let monthYear = moment().startOf('month').format('YYYY-MM');

    let dRange = $('.datetimepicker').val();
    if (dRange) {
      dRange = dRange.toString().split('-');
      monthYear = dRange[0] + '-' + dRange[1];
    }
    this.dateRange = monthYear;
    let data = {
      monthYear: monthYear,
      action: 'fetch',
      nt: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchsputilization`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (data.monthYear != this.dateRange) {
      return;
    }
    if (
      (result.statusCode == '1' || result.s == '1') &&
      result.details &&
      result.details.length > 0
    ) {
      result.details = result.details.map((ri: any) => {
        ri['Last 7 Days OD'] = ri['day_wise']
          ? ri['day_wise'].reduce(
              (acc: number, obj: any) => acc + Number(obj.total_od),
              0
            )
          : 0;
        ri['ri_utilized'] =
          ri['ri_utilized'] && ri['ri_utilized'] != '-'
            ? Number(ri['ri_utilized']).toFixed(2)
            : '-';
        ri['sp_purchased'] =
          ri['sp_purchased'] && ri['sp_purchased'] != '-'
            ? Number(ri['sp_purchased']).toFixed(4)
            : '-';
        ri['ri_purchased'] =
          ri['ri_purchased'] && ri['ri_purchased'] != '-'
            ? Number(ri['ri_purchased']).toFixed(4)
            : '-';
        ri['sp_utilized'] =
          ri['sp_utilized'] && ri['sp_utilized'] != '-'
            ? Number(ri['sp_utilized']).toFixed(2)
            : '-';
        ri['total OD'] =
          ri['total OD'] && ri['total OD'] != '-'
            ? Number(ri['total OD']).toFixed(4)
            : '-';
        return ri;
      });
      this.list = [...this.list, ...result.details];
    }

    if (result.nt) {
      this.load(false, result.nt);
    } else {
      this.loading = false;
    }
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
