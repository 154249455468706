import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

declare var hljs: any;
@Component({
  //moduleId: module.id.toString(),
  templateUrl: './trigger_history.anomalies.component.html',
})
export class triggerHistoryAnomaliesComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  history: any = [];

  funRef: any;

  accountId: any;
  regionId: any;
  currentMessage: any;

  headers: any = [
    {
      id: 'exception_name',
      name: 'Rule Name',
    },
    {
      id: 'exception_type',
      name: 'Rule Type',
    },
    { id: 'monitor_name', name: 'Monitor Name' },
    { id: 'monitor_id', name: 'Monitor ID' },
    { id: 'monitor_type', name: 'Monitor Type' },
    { id: 'attribute_name', name: 'Attribute Name' },
    { id: 'trigger_time', name: 'Trigger Time' },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.funRef = {};

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    await this.loadHistory();
    this.notifier.loading(false);
  }

  destroyed = false;
  async loadHistory(nextToken: any = '') {
    if (this.destroyed) {
      return;
    }
    if (nextToken == '') {
      this.history = [];
    }
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { site_247_project_id } = selectedAccountData.data;
        let data = {
          action: 'get_trigger_history',
          zaaid: site_247_project_id,
          rule_type: 'exception',
          next_token: nextToken != '' && nextToken ? nextToken : undefined,
        };
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        //https://api.swayam.cloud/v3/admin/support
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/anomalies`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (result.status == '1' || result.s == '1') {
          this.history = [...this.history, ...result.history.triggers];
        } else {
          this.notifier.alert('Info', '', result.error, 'info', 5000);
        }

        if (result.history.next_token) {
          this.loadHistory(result.history.next_token);
        }
      } else {
        this.notifier.alert(
          'Error',
          '',
          selectedAccountData.msg,
          'error',
          5000
        );
      }
    } catch (err: any) {
      console.log(err);
      this.notifier.alert('Info', '', err.msg, 'info', 5000);
    }
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  async showData(ref: any, id: any) {
    ref.activeMonitor = id;
    ref.notifier.loading(true);
    await ref.loadAnomalies(ref);
    ref.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
