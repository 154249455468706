import { APIService } from 'src/app/api/api.service';

export class DownloadAll {
  link: string = null;
  status: string = null;
  error: string = null;
  apiUrl: string;
  downloaded: boolean = false;
  data: any;
  header: any;
  constructor(
    private apiService: APIService,
    apiUrl: string,
    data: any,
    header: any
  ) {
    this.apiUrl = apiUrl;
    this.data = data;
    this.header = header;
  }

  async fetchStatus() {
    let result: any = await this.apiService.postDataPromis(
      this.apiUrl,
      this.data,
      this.header
    );

    if (result.status == '1' || result.s == '1') {
      this.link = result.preUrl;
      this.status = result.msg;
      this.error = null;
    } else {
      this.error = result.error;
    }
  }

  async requestDownload() {
    if (this.downloaded) {
      return { message: 'success', status: 1 };
    }
    await this.fetchStatus();
    if (this.link) {
      this.download();
    }

    return { status: this.error ? 0 : 1, message: this.error || this.status };
  }

  async download() {
    var link = document.createElement('a');
    link.download = 'Organizations';
    link.href = this.link;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.downloaded = true;
  }
}
