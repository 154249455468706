<div class="card" *ngIf="!missing_patches">
  <h5 class="card-header">Patch Report</h5>
  <div class="card-body">
    <!--<h5 class="card-title">Case Details</h5>-->

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">{{h.name}}</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': patchReportList.length > 0 ? 'table-row-group': 'none'}">
        <tr *ngFor="let i of patchReportList; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': patchReportList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="display: flex; justify-content: flex-end; align-items: center;" *ngIf="nextToken">
      <button style="background-color: #5864ff; color: white;" class="btn btn-light"
        (click)="loadMorePatches()">Next</button>
    </div>
  </div>
</div>
<div class="card" *ngIf="missing_patches">
  <h5 class="card-header">
    <span><button class="btn btn-light" (click)="goBack()">
        < Back</button></span>
    <span>
      Missing Patches
    </span>
    <button style="float: right;" class="btn btn-success-soft" (click)="export()"
      *ngIf="innerData.length > 0 && !loading">Export</button>
    <span style="float: right;" *ngIf="loading">
      <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
    </span>
  </h5>
  <div class="card-body">
    <table class="table table-docs table-hover" id="patch-reports-id">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of innerdataHeaders">{{h.name}}</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': innerData.length > 0 ? 'table-row-group': 'none'}">
        <tr *ngFor="let i of innerData; let j = index">
          <td>{{j+1}}</td>
          <td *ngFor="let h of innerdataHeaders">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="loading">
        <tr>
          <td style="text-align: center;" [attr.colspan]="innerdataHeaders.length + 1">
            <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="innerData.length == 0 && !loading">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>