<div class="card" *ngIf="!selectedWorkload">
  <h5 class="card-header">
    <div class="flex-between">
      <div>
        Workloads
      </div>
      <div class="flex-start">
        <button class="btn btn-light m-0" (click)="load()">
          Refresh</button>
        <button class="btn btn-primary-soft m-0" (click)="createWorkload = true"><i class="fa fa-plus"
            aria-hidden="true"></i>
          Create Workload</button>
      </div>
    </div>
  </h5>
  <div class="card-body">
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th>Owner</th>
          <th>Name</th>
          <th>Last Updated</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="workloads.length > 0">
          <tr *ngFor="let workload of workloads; let i = index">
            <td>
              {{ i + 1 }}
            </td>
            <td>{{ accountId }}</td>
            <td *ngIf="workload['ScanStatus'] == 'COMPLETED'" style="cursor: pointer;"
              (click)="selectWorkload(workload)"><span class="nav-link">{{ workload.name }}</span>
            <td *ngIf="!workload['ScanStatus'] || workload['ScanStatus'] != 'COMPLETED'">{{ workload.name }}
            </td>
            <td>{{ workload.creation_time }}</td>
            <td>
              <div class="flex-start" *ngIf="!workload['ScanStatus'] || workload['ScanStatus'] == 'SCANNING'">
                <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 14px;" />
              </div>
              <div *ngIf="workload['ScanStatus'] && workload['ScanStatus'] != 'SCANNING'">
                {{ workload['ScanStatus'] }}
              </div>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="workloads.length == 0 && !loading['workloads']">
          <tr>
            <td colspan="5" style="text-align: center;">No Workloads found</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="loading['workloads']">
          <tr>
            <td colspan="5" style="text-align: center;">
              <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="selectedWorkload && !selectedLenses && !selectedPiller && !showQuestions">
  <div class="card">
    <h5 class="card-header flex-start" *ngIf=" !milestone">
      <button class="btn btn-light" (click)="selectedWorkload = null">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        Workloads</button>
      <span>
        {{ selectedWorkload['name'] }}
      </span>
    </h5>
    <h5 class="card-header flex-start" *ngIf="milestone">
      <button class="btn btn-light" (click)="milestone = null">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        {{ selectedWorkload['name'] }} </button>
      <span>
        Milestones > {{ milestone['name'] }}
      </span>
    </h5>
    <div class="card-body">
      <app-workload-overview [lenses]="lenses || []" [pillers]="pillers || []"
        [questions]="milestone ? milestone['questions'] : questions" [workload]="selectedWorkload" [loading]="loading"
        (selectLens)="selectLens($event)" (showQuestions)="selectPiller(null, $event)"
        (saveMilestone)="showMileStonePopup = true" [isMilestone]="milestone ? true : false" [milestones]="milestones"
        (milestone)="loadMilestone($event)"></app-workload-overview>
    </div>
  </div>
</div>
<div *ngIf="selectedWorkload && selectedLenses && !selectedPiller && !showQuestions">
  <div class="card">
    <h5 class="card-header flex-start">
      <button class="btn btn-light" (click)="selectedLenses = null">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        {{ milestone ? 'Milestone' : 'Workload' }} Overview</button>
      <span>
        {{ milestone ? milestone['name'] : selectedWorkload['name'] }} - {{ lensesData[selectedLenses] }}
      </span>
    </h5>
    <div class="card-body">
      <app-lens-overview [lenses]="lenses" [pillers]="pillers" [questions]="milestone ? milestone.questions : questions"
        [workload]="selectedWorkload" [loading]="loading" (selectPiller)="selectPiller($event)"
        [selectedLens]="selectedLenses" [isMilestone]="milestone != null ? true : false"
        (showQuestions)="selectPiller($event, null)"></app-lens-overview>
    </div>
  </div>
</div>
<div *ngIf="selectedWorkload && selectedLenses && showQuestions">
  <app-questions [indexs]="currentQuestionIndexs" [lens]="selectedLenses" [pillers]="pillers"
    [questions]="milestone ? milestone.questions : questions" [piller]="selectedPiller || pillers[0]"
    [workload]="selectedWorkload" (removePiller)="hideQuestions($event)" [isMilestone]="milestone ? true : false"
    [milestone]="milestone"></app-questions>
</div>
<create-workload *ngIf="createWorkload" [accountId]="accountId" [regionId]="regionId"
  (hideModal)="hideCreateWorkload($event)"></create-workload>

<app-create *ngIf="showMileStonePopup && selectedWorkload" [accountId]="accountId" [regionId]="regionId"
  (hideModal)="hideMilestonePopup($event)" [workload]="selectedWorkload"></app-create>