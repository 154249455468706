<div class="card mt-20" style="border: none; position: relative; height: 100%;">
  <h5 class="card-header">RI Purchased Report</h5>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; align-items: center; margin: 10px 0px;">
      <input type="text" id="monthDate" [(ngModel)]="dateRange">
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': linkedAccounts.length > 0 ? 'table-row-group' : 'none'}">
        <tr *ngFor="let i of linkedAccounts; let j = index">
          <td>{{j + 1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': linkedAccounts.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 1">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>