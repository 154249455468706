import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './highestbill.component.html',
})
export class HighestBillComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  s3List: any = [];
  filterType: string = 'ALL';

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  headers: any = [
    {
      id: 'mid',
      name: 'Master Account ID',
    },
    {
      id: 'lid',
      name: 'Linked Account ID',
    },
    { id: 'finalTotalBill', name: 'Total Bill' },
  ];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null) {
        return;
      }

      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load(true);
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load(true);
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load(refresh: boolean = false) {
    if (refresh) {
      this.s3List = [];
    }
    this.notifier.loading(true);
    let data = { a: 'fetchtop10' };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/costvariance `;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.s3List = result.data.map((bill: any) => {
        bill['finalTotalBill'] = bill['finalTotalBill'].toFixed(2);
        return bill;
      });
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    return;
  }
}
