<ng-container *ngIf="!createPipeline">
  <div class="card" *ngIf="!pipelineID">
    <h5 class="card-header float-between">
      <span>
        Pipelines
      </span>
      <div class="flex-start">
        <button class="btn btn-primary-soft" (click)="createPipeline = true">
          Create Pipeline
        </button>
        <button class="btn btn-success-soft" *ngIf="selectedPipelineIndex > -1"
          style="display: flex; gap: 8px; align-items: center;" (click)="showBuildFiles = true">
          <span style="color: inherit;">
            Build Files
          </span>
          <i class="fa fa-download" aria-hidden="true"></i>
        </button>
      </div>
    </h5>
    <div class="card-body">
      <div class="float-right">
        <input type="text" class="form-control" style="margin-bottom: 5px; width: 250px;" placeholder="Search..."
          [(ngModel)]="searchKey">
      </div>
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>Repo Name</th>
            <th>Created By</th>
            <th>Created</th>
            <th>Clone</th>
          </tr>
        </thead>
        <tbody>
          <tr style="cursor: pointer;" *ngFor="let pipeline of filteredPipeLines(); let i = index"
            (click)="toggleIndex(i)">
            <td><input type="radio" style="pointer-events: none;"
                [attr.checked]="selectedPipelineIndex == i ? true: null">
            </td>
            <td>
              <div>
                <span>
                  <a class="minfy-stretched-link" routerLink="/dev/pipelines" (click)="selectedPipeline = pipeline"
                    [queryParams]="{id:pipeline['repo_name']}">
                    {{ pipeline["repo_name"] }}
                  </a>
                </span>
                <span class="light-text">
                  <i class="fa-solid fa-code-branch"></i>
                  <span>
                    {{ pipeline["prod_branch"] }}
                  </span>
                </span>
              </div>
            </td>
            <td>
              <span class="user_favicon">{{
                user_favicon(pipeline["created_by"], true)["user_full_name"]
                }}</span>
            </td>
            <td>
              <span>
                {{ timeSince(pipeline["created_at"]) }} ago
              </span>
            </td>
            <td>
              <button *ngIf="pipeline['repo_url']" class="btn btn-primary-soft"
                style="display: flex; gap: 8px; align-items: center;" (click)="copyURL(pipeline['repo_url'])">
                <span style="color: inherit;">
                  Clone
                </span>
                <i class="fa fa-clone" aria-hidden="true"></i>
              </button>
              <span *ngIf="!pipeline['repo_url']">-</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card" *ngIf="selectedPipeline && pipelineID">
    <h5 class="card-header float-between">
      <div>
        <button class="btn btn-light" (click)="showPipelines()">
          < Back</button>
            Pipeline - {{ pipelineID }}
      </div>
      <div class="flex-start">
        <button class="btn btn-primary-soft" style="display: flex; gap: 8px; align-items: center;"
          (click)="copyURL(selectedPipeline['repo_url'])">
          <span style="color: inherit;">
            Clone
          </span>
          <i class="fa fa-clone" aria-hidden="true"></i>
        </button>
        <button class="btn btn-success-soft" style="display: flex; gap: 8px; align-items: center;"
          (click)="showBuildFiles = true">
          <span style="color: inherit;">
            Build Files
          </span>
          <i class="fa fa-download" aria-hidden="true"></i>
        </button>
      </div>
    </h5>
    <div class="card-body">
      <app-pipeline [pipeline]="selectedPipeline" [pipelineID]="pipelineID"></app-pipeline>
    </div>
  </div>
</ng-container>

<app-pipeline-create *ngIf="createPipeline" (hide)="hide($event)"></app-pipeline-create>
<app-pipeline-build-tools *ngIf="showBuildFiles" [runtime]="getRuntime()" (hide)="showBuildFiles = false">
</app-pipeline-build-tools>