<div class="alarm_screen" style="box-shadow:  1px 3px 1px #80808024 ">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform:capitalize">{{action == 'update' ? 'Update' : 'Add'}} RI</h4>
    </div>
    <div class="alarm_body" style=" padding: 1px 25px 5px 25px">
      <div
      class="row" *ngIf="action !== 'update'"
    >

    <div class="flex-between p-0">
      <label>Linked Account List</label>
      <div class="flex-start filters">
        <div class="flex-start">
          <input type="checkbox" id="select_all_accounts" (change)="selectAllchilds($event)" />
          <label for="select_all_accounts">Select All</label>
        </div>
      </div>
    </div>

    <select class="form-control" id="childs" [(ngModel)]="ri.lids"
    data-live-search="true" multiple required>
    <option *ngFor="let master of linkedAccounts['list']" [value]="master.accountId">
      {{ master.accountId }} ({{master.accountName}})
    </option>
  </select>
    </div>
      <div class="row">
        <label>DB Instance Type</label>
        <select class="form-control" id="instanceTypes" (change)="fetchRICost()" [(ngModel)]="ri.dbInstanceType"
          data-live-search="true">
          <option *ngFor="let type of dbInstanceTypes" value="{{type}}">{{type}}</option>
        </select>
      </div>
      <div class="row">
        <label>DB Engine</label>
        <select class="form-control" id="engines" (change)="fetchRICost()" [(ngModel)]="ri.dbEngine"
          data-live-search="true">
          <option *ngFor="let platform of dbEngines" value="{{platform}}">{{platform}}</option>
        </select>
      </div>
      <div class="row">
        <label>Region</label>
        <select class="form-control" id="regionsList" (change)="fetchRICost()" [(ngModel)]="ri.region"
          data-live-search="true">
          <option *ngFor="let region of regions" value="{{region.id}}">{{region.name}}</option>
        </select>
      </div>
      <div class="row">
        <label>Deployment Type</label>
        <select class="form-control" id="deploymentType" (change)="fetchRICost()" [(ngModel)]="ri.deploymentType"
          data-live-search="true">
          <option value="Single-AZ">Single-AZ</option>
          <option value="Multi-AZ">Multi-AZ</option>
        </select>
      </div>
      <div class="row">
        <label>Quantity</label>
        <input type="number" class="form-control" [(ngModel)]="ri.quantity">
      </div>
      <div class="row">
        <label>RI Type</label>
        <select class="form-control" id="billingTypes" [(ngModel)]="ri.riType" (change)="fetchRICost()"
          data-live-search="true">
          <option value="OnDemand" selected="">OnDemand</option>

          <option value="RI 1yr NoUpfront" selected="">RI 1yr NoUpfront</option>
          <option value="RI 1yr AllUpfront">RI 1yr AllUpfront</option>
          <option value="RI 1yr PartialUpfront">RI 1yr PartialUpfront</option>
          <option value="RI 3yr AllUpfront">RI 3yr AllUpfront</option>
          <option value="RI 3yr PartialUpfront">RI 3yr PartialUpfront</option>

          <option value="ap-northeast-3">RI 1yr NoUpfront</option>

        </select>
      </div>
      <div class="row" [ngStyle]="{display: ri.riType == 'OnDemand' ? 'none': 'block'}">
        <label>RI hourly cost</label>
        <input type="text" class="form-control" [(ngModel)]="ri.riCost">
      </div>
      <div class="row">
        <label>Discount percentage:</label>
        <input type="text" class="form-control" [(ngModel)]="ri.discountPercentage">
      </div>
      <div class="row">
        <label>Period:</label>
        <input class="datetimepicker" type="text" [(ngModel)]="dateRange" />
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="updateEntry()"
            style="background-color: #5864ff; color: white; text-transform:capitalize; margin-right:20px">
            {{action == 'add' ? 'Add' : action}}
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>


  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
  </style>