import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import * as moment from 'moment';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

@Component({
  selector: 'deleterule-backup',
  templateUrl: './delete.component.html',
  styleUrls: ['./../../backupv2.component.css'],
})
export class DeleteRuleBackupv2Component implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';

  funRef: any;

  confirm_text: string = '';

  @Input('rule') rule: any;

  @Output('close') closeEmitter = new EventEmitter<boolean>();

  accountId: any;
  regionId: any;
  currentMessage: any;
  notifications: any = [];

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.funRef = {};

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {}

  async updateRule() {
    this.notifier.loading(true);
    let data = {
      action: 'delete_backup_rule',
      login_user_name: this.userId,
      account_id: this.accountId,
      region_name: this.regionId,
      rule_id: this.rule.rule_id,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/backup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.notifier.alert('Success', '', result.message, 'success', 5000);
      this.notifier.loading(false);

      this.close(this.rule);
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  close(rule: any = false) {
    if (this.rule) {
      this.closeEmitter.emit(rule);
    } else {
      this.closeEmitter.emit(false);
    }
  }

  callFunction(value: any, param: any) {
    this.funRef[value](this, param);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
