<div class="alarm_screen">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4>Launch Instance</h4>
    </div>
    <div class="alarm_body">
      <div class="row">
        Do you want to launch Instance?

      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="uploadFile()" style="background-color: #5864ff; color: white">
            Launch
          </button>
          <button class="btn btn-light" (click)="close()">Cancel</button>
        </div>
      </div>
    </div>
  </div>