<div class="modal show" tabindex="-1">
  <div class="modal-dialog" style="max-width: 900px !important">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">
          <h5>{{ modalTitle }}</h5>
          <span>A tag is a custom label that you assign to an AWS resource. You can
            use tags to help organize and identify your instances.</span>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="close(true)"></button>
      </div>
      <div class="modal-body">
        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of header">{{ h.name }}</th>
              <th></th>
            </tr>
          </thead>

          <tbody [ngStyle]="{'display': tags.length > 0 ? 'table-row-group' : 'none'}"
            *ngFor="let i of tags; let j = index">
            <tr>
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of header">
                <span *ngIf="
                    i.editable == 'VALUE_ONLY' &&
                    h.id == 'value' &&
                    i.type == 'Dropdown'
                  ">
                  <select class="form-control" [(ngModel)]="i.value">
                    <option *ngFor="let value of i.options" [attr.checked]="value.value == i.value"
                      value="{{ value.value }}">
                      {{ value.str }}
                    </option>
                  </select>
                </span>
                <span *ngIf="
                    i.editable == 'VALUE_ONLY' &&
                    h.id == 'value' &&
                    i.type == 'String'
                  ">
                  <input class="form-control" type="text" [(ngModel)]="i[h.id]" />
                </span>
                <span *ngIf="i.editable == 'VALUE_ONLY' && h.id != 'value'">
                  <span>{{ i[h.id] }}</span>
                </span>
                <span *ngIf="i.editable == 'ALL'">
                  <input class="form-control" type="text" [(ngModel)]="i[h.id]" />
                </span>
              </td>
              <td>
                <span class="btn btn-danger" *ngIf="i.removeTag" (click)="removeTag(j)">Remove</span>
              </td>
            </tr>
          </tbody>
          <tbody [ngStyle]="{'display': tags.length == 0 ? 'table-row-group' : 'none'}">
            <tr>
              <td [attr.colspan]="header.length + 2">
                <div style="text-align: center;">No Tags Found</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="tags && tags.length < maxTags">
          <button class="btn btn-light" (click)="addTag()">Add Tag</button>
        </div>
        <div *ngIf="tags && tags.length < maxTags">
          You can add {{ maxTags - tags.length }} more tags.
        </div>
        <div *ngIf="tags && tags.length == maxTags">
          You can't add more Tags
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" [ngClass]="{'click-disabled': !writeAccess}"
          [attr.disabled]="!writeAccess ? true : null" (click)="saveTags()">
          Save Tags
        </button>
        <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="close(true)">
          Close
        </button>
      </div>
    </div>
  </div>
</div>