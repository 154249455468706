import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { APIService } from './../api/api.service';
//import * as $ from 'jquery';
//import * as moment from 'moment';
declare let window: any;
declare let echarts: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './index.component.html',
})
export class DashcIndexComponent implements OnInit {
  uid = localStorage.getItem('t');
  t = localStorage.getItem('t');

  loading = false;

  public jobList: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService
  ) {}

  ngOnInit() {
    this.loadJobMap();
  }

  showoptions(elemId: string) {
    let v = <HTMLElement>document.getElementById(elemId);
    console.log(v.style.display);
    if (v && v.style.display != 'block') {
      v.style.display = 'block';
    } else {
      v.style.display = 'none';
    }
  }

  async loadJobMap() {
    let data = {
      uid: this.uid,
      t: this.t,
    };

    let result = await this.apiServer.postDataPromis(
      `${APIService.API_ENDPOINT}dash/jobflow/search`,
      data
    );
    console.log(result.em);
    if (result.s == '1') {
      this.loading = false;
      this.jobList = result.em;
    } else {
      console.log(result.error);
      this.loading = false;
      alert(result.error);
      //this.error = result.error;
    }

    return;
  }
}
