<div class="card">
  <h5 class="card-header">Policies</h5>
  <div class="card-body">

    <div style="display: flex; justify-content: flex-end; align-items: center; gap: 8px;">
      <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="load()">Refresh</button>
      <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="createPolicy()"
        *ngIf="writeAccess">Create</button>
    </div>

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>S.N.</th>
          <th *ngFor="let h of headers">{{h.name}}</th>
          <th *ngIf="writeAccess">Update</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': policies.length > 0 ? 'table-row-group' : 'none'}"
        *ngFor="let i of policies; let j = index">
        <tr>
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
              <span (click)="callFunction(h.click,i)">
                {{i[h.id]}}
              </span>
            </span>
            <span *ngIf="!h.click">
              {{i[h.id]}}
            </span>
          </td>
          <td *ngIf="writeAccess"><button class="btn btn-light" (click)="updatePolicyAction(i)"><i
                class="fa fa-pencil"></i></button> </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': policies.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 2 + (writeAccess ? 1 : 0)">
            <div style="text-align: center;">No Policies Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<update-policy *ngIf="writeAccess && updatePolicy" [action]="policyAction" [group]="updatePolicy"
  (hideModel)="hideUpdatePolicy($event)"></update-policy>