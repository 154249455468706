<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader">New Scan</div>
    <div class="KBBody">
      <div class="col-mb-6">
        <label>Account ID: </label>
        <span>{{accountId}}</span>
      </div>
      <div class="col-mb-6">
        <label>Region: </label>
        <span>{{regionId}}</span>
      </div>
      <div class="col-mb-6 mb-2">
        <label>Scan Name: </label>
        <div>
          <input style='width: 300px;' class="form-control" type="text" [(ngModel)]="scan_name">
        </div>
        <div *ngIf="errors['scan_name']['is_error']" style="color: rgb(216, 55, 55); font-weight: bold;">
          *{{ errors['scan_name']['error'] }}
        </div>
      </div>
      <div class="KBActions">
        <button class="btn btn-success" (click)="yes()">Create</button>
        <button class="btn btn-light" (click)="no()">Cancel</button>
      </div>
    </div>
  </div>
</div>