import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';

declare let $: any;
declare let window: any;
@Component({
  templateUrl: './tags.operations.component.html',
})
export class TagsOperationsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  instancesList: any = [];
  showTagsModal: any = null;
  instance: any = null;
  tagList: any = null;
  selectedTag: any = null;
  valueList: any = null;
  selectedValue: any = null;
  command: string = null;
  modalTitle: any;
  destroyed: boolean = false;
  headers: any = [
    {
      id: 'tagName',
      name: 'Tag Name',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
    {
      id: 'instanceType',
      name: 'Instance Type',
      sort: { sort: true, direction: 'Asc' },
      filter: true,
    },
    { id: 'availabilityZone', name: 'Availability Zone' },
    {
      id: 'statusCheck',
      name: 'Status Check',
      color: (value: string) => (value != '2/2' ? 'red' : 'green'),
    },
    {
      id: 'ipdata',
      name: 'Private IP / Public IP',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'instanceStatus',
      name: 'Instance Status',
      statusCheck: true,
    },
  ];

  funRef: any;
  action: any;
  accountId: any;
  regionId: any;
  currentMessage: any;
  filterText = '';
  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      $('#tagListID').selectpicker();
      $('#valueListID').selectpicker();
    }, 100);

    this.funRef = {
      showData: this.showData,
    };

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      this.hideMdoal(true);
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.loadTags();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.loadTags();
      }
    });
  }

  async loadInstances(nextToken?: any) {
    this.notifier.loading(true);
    let data = {
      action: 'testListInstancesBasedOnTagsWithPagination',
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken,
      tagKey: this.selectedTag,
      tagValue: this.selectedValue,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Operations.Tags', 'EC2 Instances Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.status == '1' || result.s == '1') {
      this.instancesList = result.instanceDetails;
      let dt: any = [];
      this.instancesList.forEach((instance: any, index: number) => {
        instance['actionStart'] = false;
        if (instance.instanceStatus == 'running') {
          dt.push(instance);
        }
        instance.ipdata =
          (instance.privateIp ? instance.privateIp.toString() : '') +
          (instance.publicIp ? '/' + instance.publicIp.toString() : '');
      });
      if (result.nextToken && result.nextToken != '') {
        await this.loadInstances(result.nextToken);
      }
      if (dt.length > 0) {
        await this.fetchInstanceAZ(dt);
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async loadTags(nextToken: any = undefined) {
    this.notifier.loading(true);
    if (!nextToken) {
      this.tagList = [];
    }
    let data = {
      action: 'listEc2Tags',
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Operations.Tags', 'Ec2Tags Fetched');
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.status == '1' || result.s == '1') {
      if (result.tagKeyList && result.tagKeyList.length > 0 && !nextToken) {
        this.tagList = [...this.tagList, ...result.tagKeyList];
      }
      setTimeout(() => {
        $('#tagListID').selectpicker('refresh');
      }, 100);
      if (result.tagKeyList && result.tagKeyList.length > 0 && !nextToken) {
        this.selectedTag = this.tagList[0];
        await this.loadValues();
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    if (result.nextToken) {
      await this.loadTags(result.nextToken);
    }
    this.notifier.loading(false);
  }

  async loadValues() {
    this.notifier.loading(true);
    let data = {
      action: 'listEc2TagsValues',
      accountId: this.accountId,
      region: this.regionId,
      nextToken: '',
      tagKey: this.selectedTag,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Operations.Tags', 'Ec2TagsValues Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.status == '1' || result.s == '1') {
      this.valueList = result.tagKeyList;
      this.selectedValue = this.valueList[0];
      setTimeout(() => {
        $('#valueListID').selectpicker('refresh');
      }, 100);
      await this.loadInstances();
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  changeTag() {
    this.instance = null;
    this.loadValues();
  }

  changeValue() {
    this.instance = null;
    this.loadInstances();
  }

  async fetchInstanceAZ(instances: any, nextToken?: any) {
    let data = {
      action: 'fetchStatusCheckResult',
      instanceIdList: instances.map((instance: any) => {
        return instance.instanceId;
      }),
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Operations.Tags', 'InstanceAZ Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listec2instances`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (this.destroyed) {
      return;
    }

    if (result.s == '1') {
      result.statusCheckDataList.forEach((instance: any) => {
        let instance_ = this.instancesList.find(
          (element: any) => element.instanceId == instance.instanceId
        );
        instance_ = { ...instance_, ...instance };
        this.instancesList[
          this.instancesList.findIndex(
            (element: any) => element.instanceId == instance.instanceId
          )
        ] = instance_;
      });
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
  }

  instanceStartStop(event: any) {
    this.instancesList.forEach((instance: any) => {
      instance['actionStart'] = false;
    });
    this.instance['actionStart'] = true;
    if (event == 'start') {
      this.modalTitle = 'start';
      this.action = 'start';
    } else if (event == 'stop') {
      this.action = 'stop';
      this.modalTitle = 'stop';
    }
    this.command = event;
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  showData(ref: any, value: any) {
    if (ref.instance) {
      ref.instance['actionStart'] = false;
    }
    value['actionStart'] = true;
    ref.instance = value;
  }

  showTags(data: any) {
    this.showTagsModal = data;
  }

  getStartTriggerInstances() {
    return this.instancesList
      ? this.instancesList.filter((instance: any) => {
          return instance.actionStart;
        })
      : [];
  }

  singleStartStop(event: any, instance: any, trigger: any) {
    this.instancesList.forEach((instance: any) => {
      instance['actionStart'] = false;
    });
    instance.actionStart = true;
    this.triggerInstances(event, trigger);
  }

  triggerInstances(event: any, trigger: string) {
    event.preventDefault();
    if (trigger == 'run') {
      this.modalTitle = 'start';
    } else if (trigger == 'stop') {
      this.modalTitle = 'stop';
    }
    this.command = trigger;
  }

  hideMdoal(event: any) {
    this.showTagsModal = null;
  }

  hideDetails(event: any) {
    this.instance['actionStart'] = false;
    this.instance = null;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
