<div class="card mt-20" style="border: none; position: relative; height: 100%;">
  <h5 class="card-header">New CFRC Order Report</h5>
  <div class="card-body">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <span style="text-align: start;"><b>CFRC Period:</b> {{ period }}</span>
      <div style="display:flex;gap:12px">
        <button type="button" class="btn btn-outline-danger" (click)="deleteall = true">Delete All</button>
        <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="addPurchase()">Add
          Purchase Details</button>
      </div>
    </div>
    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <ng-container *ngFor="let h of headers; let i = index">
            <th *ngIf="i < headers.length - 3">{{ h.name }}</th>
          </ng-container>
          <th>Update</th>
          <th>Delete</th>
        </tr>
      </thead>

      <tbody *ngFor="let i of riData; let j = index"
        [ngStyle]="{'display': riData.length > 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td>{{j + 1}}</td>
          <ng-container *ngFor="let h of headers; let k = index">
            <td *ngIf="k < headers.length - 3">
              <span *ngIf="i.usageType === 'all' && (h.id === 'ppu' || h.id === 'region')">-</span>
              <span *ngIf="h.id === 'ppu' && i.usageType !== 'all'" [innerHTML]="(i[h.id] || '-')"></span>
              <span [innerHTML]="
      h.id === 'discountPercentage' ? 
        (i.discountType === 'value' ? (i.discount || '-') : 
        (i.discountPercentage ? (i.discountPercentage + '%') : (i.discount || '-'))) 
      : ''
">
</span>
       <span *ngIf="h.id !== 'ppu' && h.id !== 'discountPercentage' && h.id !== 'region'" 
                    [innerHTML]="i[h.id] || '-'"></span>
              
            </td>
          </ng-container>
          <td><span (click)="updateTrigger(i)" class="btn btn-light"><i class="fa fa-pencil"></i></span></td>
          <td><span (click)="confirmdeletion(i)" class="btn btn-light"><i class="fa fa-trash"></i></span></td>
        </tr>
      </tbody>
      <tbody  [ngStyle]="{'display': riData.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers && headers.length ? headers.length - 3 + 3 : 3">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- <div style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 999; background-color: rgba(0, 0, 0, 0.1);"
  [ngStyle]="{'display': unsavedRI.length > 0 ? 'block': 'none'}">
  <div
    style="position: relative; margin: 10px; gap: 10px; background-color: white; border-radius: 10px; padding: 75px 20px 20px 20px; box-shadow: 1px 1px 3px 1px grey; display: flex; flex-direction: column; max-height: calc(100% - 20px);">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <h4>Unsaved RI Order</h4>
      <div style="display: flex; justify-content: flex-end; align-items: center;">
        <label>Period: </label>
        <input
          type="text"
          id="dataRangePicker"
          [(ngModel)]="dateRange"
          class="form-control"
          style="width: 300px"
          placeholder="Select date"
        />
        <button class="btn btn-light" style="background-color: #5864FF; color: white;" (click)="addPurchase()">Add
          Purchase Details</button>
      </div>
    </div>
    <div style="position: absolute; top: 10px; right: 10px; padding: 10px; cursor: pointer;" (click)="unsavedRI = []"
      class="btn btn-light">
      X
    </div>
    <div style="overflow: auto; flex: 1;">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <ng-container *ngFor="let h of headers; let i = index">
              <th *ngIf="i < headers.length - 3">{{ h.name }}</th>
            </ng-container>
            <th>Update</th>
            <th>Delete</th>
          </tr>
        </thead>

        <tbody *ngFor="let i of unsavedRI; let j = index"
          [ngStyle]="{'display': unsavedRI.length > 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td>{{j + 1}}</td>
            <ng-container *ngFor="let h of bef_headers; let k = index">
            <td *ngIf="k < headers.length - 3">
              <span *ngIf="h.id === 'ppu'" [innerHTML]="'$' + (i[h.id] || 0)"></span>
              <span *ngIf="h.id === 'discountPercentage'" [innerHTML]="(i[h.id] || 0) + '%'"></span>
              <span *ngIf="h.id !== 'ppu' && h.id !== 'discountPercentage'" [innerHTML]="i[h.id] || '-'"></span>
            </td>
          </ng-container>
            <td><span (click)="updateUnsavedRI(i, j)" class="btn btn-light"><i class="fa fa-pencil"></i></span></td>
            <td><span (click)="deleteUnsavedRI(j)" class="btn btn-light"><i class="fa fa-trash"></i></span></td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{'display': unsavedRI.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="headers.length + 3">
              <div style="text-align: center;">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="display: flex; justify-content: flex-end; align-items: center; padding: 15px;">
      <button class="btn btn-light" (click)="saveDetails()" style="background-color: #5864FF; color: white;">
        Save Details
      </button>
    </div>
  </div>
</div> -->

<div *ngIf="delete" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); backdrop-filter: blur(5px); display: flex; align-items: center; justify-content: center;">
  <div class="card" style="height: 200px; width: 60%; padding: 18px; background: white; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);justify-content: space-between;">
    <h4>Are you sure you want to delete?</h4>
    <div style="text-align: end;">
      <button class="btn btn-danger" style="margin: 0px 4px;" (click)="deleteTrigger(indexdelete)">Delete</button>
      <button class="btn" style="margin: 0px 4px; background-color: rgb(228, 230, 232);" (click)="delete = false">Cancel</button>
    </div>
  </div>
</div>
<div *ngIf="deleteall" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); backdrop-filter: blur(5px); display: flex; align-items: center; justify-content: center;">
  <div class="card" style="height: 200px; width: 60%; padding: 18px; background: white; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);justify-content: space-between;">
    <h4>This Delete action will delete all the entries. You want to continue?</h4>
    <div style="text-align: end;">
      <button class="btn btn-danger" style="margin: 0px 4px;" (click)="deleteTriggerall()">Yes</button>
      <button class="btn" style="margin: 0px 4px; background-color: rgb(228, 230, 232);" (click)="deleteall = false">Cancel</button>
    </div>
  </div>
</div>



<enablecfrc-update *ngIf="updateRI" [action]="updateAction" [ri]="updateRI" (hideModel)="closeUpdateTrigger($event)">
</enablecfrc-update>