import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
import * as moment from 'moment';

declare let flatpickr: any;
declare let $: any;
declare let window: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './cfrc.view.component.html',
})
export class ViewCFRCComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  extend: boolean = false;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  cfrcData: any = [];
  funRef: any;
  accountId: any;
  regionId: any;
  currentMessage: any;
  dateRange: any;
  editable: any = [];

  editableHeader: any = [
    {
      id: 'S.N.',
      name: 'S.N.',
    },
    {
      id: 'region',
      name: 'Region',
    },
    {
      id: 'description',
      name: 'Description',
    },
    {
      id: 'totalCost',
      name: 'Cost per Hour',
      editable: true,
    },
  ];

  headers: any = [
    {
      id: 'region',
      name: 'Region',
    },
    { id: 'description', name: 'Description' },
    {
      id: 'totalCost',
      name: 'Cost per Hour',
    },
  ];
  cfrc_start_date: any;
  cfrc_end_date: any;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.funRef = {};
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    flatpickr('#dataRangePicker', {
      dateFormat: 'Y-M-d',
      mode: 'range',
      showMonths: 2,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.dateRange = dateStr;
      },
    });

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  ranges: any = [];
  selectedRange: any = null;

  filteredCFRCs() {
    return this.cfrcData.filter((cfrc: any) => {
      let temp = `${cfrc['startDate']} - ${cfrc['endDate']}`;
      return this.selectedRange == temp;
    });
  }

  async load() {
    this.notifier.loading(true);
    this.cfrcData = [];
    this.ranges = [];
    let data = {
      lid: this.accountId,
      userType: 'C',
      action: 'fetchCustomCFRCDetails',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      if (result.cloudFrontDetailsList) {
        result.cloudFrontDetailsList.forEach((cfrc: any, index: number) => {
          cfrc['S.N.'] = index + 1;
          let temp = `${cfrc['startDate']} - ${cfrc['endDate']}`;
          if (this.ranges.indexOf(temp) < 0) {
            this.ranges.push(temp);
          }
        });
        this.selectedRange = this.ranges[0];
        this.cfrc_start_date = result.startDate;
        this.cfrc_end_date = result.endDate;
        this.cfrcData = result.cloudFrontDetailsList;
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  extendCFRC() {
    this.extend = true;
    this.editable = this.cfrcData;
    // 2023-Jul-01 to 2023-Aug-31

    this.dateRange = `${moment(
      this.ranges[this.ranges.length - 1].split(' - ')[1],
      'YYYY-MM-DD'
    )
      .add(1, 'days')
      .format('YYYY-MMM-DD')} to ${moment(
      this.ranges[this.ranges.length - 1].split(' - ')[1],
      'YYYY-MM-DD'
    )
      .add(1, 'year')
      .format('YYYY-MMM-DD')}`;

    $('#dataRangePicker').val(this.dateRange);
  }

  closeUpload() {
    this.extend = false;
    this.editable = [];
  }

  async saveDetails() {
    let sDate = $('#monthDate').val();
    sDate = moment(sDate, 'MM/YYYY').format('MM-YYYY');
    let dateRange: any;
    try {
      dateRange = this.dateRange.split('to');
    } catch (err) {
      this.notifier.alert(
        'Error',
        '',
        'CFRC Date Range is Required',
        'error',
        5000
      );
      return;
    }

    let valid: boolean = true;
    let dt = this.editable.map((cost: any) => {
      if (!cost['totalCost'] || cost['totalCost'] == '') {
        valid = false;
      }
      return cost;
    });
    if (!dateRange && dateRange.length == 0) {
      this.notifier.alert(
        'Error',
        '',
        'CFRC Date Range is Required',
        'error',
        5000
      );
      return;
    }
    if (!valid) {
      this.notifier.alert(
        'Error',
        '',
        'All fields are mandatory',
        'error',
        5000
      );
      return;
    }

    let startDate = moment(dateRange[0].trim(), 'YYYY-MMM-DD').format(
      'YYYY-MM-DD'
    );
    let endDate = moment(dateRange[1].trim(), 'YYYY-MMM-DD').format(
      'YYYY-MM-DD'
    );
    this.notifier.loading(true);
    let data: any = {
      month_year: sDate,
      lid: this.accountId,
      startDate: startDate,
      endDate: endDate,
      userType: 'C',
      action: 'saveCustomCFRCDetails',
      cloudFrontDetailsList: dt,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.closeUpload();
      this.load();
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
