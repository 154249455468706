<div class="alarm_screen">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4>Update Rule</h4>
    </div>
    <div class="alarm_body">
      <div class="row">
        <label>Rule Type</label>
        <div>
          <input type="radio" name="exception_type" value='exception' [(ngModel)]="rule.exception_type">
          <label for="exception_id">Exception</label>
        </div>
        <div>
          <input type="radio" name="exception_type" value='supression' [(ngModel)]="rule.exception_type">
          <label for="supression_id">Supression</label>
        </div>
      </div>
      <div class="row">
        <label>Rule Name</label>
        <input type="text" [(ngModel)]="rule.exception_name" name="" id="" class="form-control" />
      </div>
      <div class="row">
        <label>Time Windows</label>
        <div>
          <input type="radio" name="time_window" [(ngModel)]="rule.time_range" value="all">
          <label for="all_id">All</label>
        </div>
        <div>
          <input type="radio" name="time_window" [(ngModel)]="rule.time_range" value="week1">
          <label for="week_1_id">Week 1</label>
        </div>
      </div>
      <div class="row">
        <label>Threshold</label>
        <div>
          <input type="number" class="form-control" [(ngModel)]="rule.threshold " />
        </div>
      </div>
    </div>
    <div class="alarm_actions">
      <div style="display: flex; justify-content: flex-end">
        <button class="btn" (click)="createAlarm()" style="background-color: #5864ff; color: white">
          Update
        </button>
        <button class="btn btn-light" (click)="close()">Cancel</button>
      </div>
    </div>
  </div>
</div>