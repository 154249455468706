<div class="card mt-20" style="border: none; position: relative; height: 100%;">
  <h5 class="card-header">Budgets</h5>
  <div class="card-body">
    <div *ngIf="!loading" style="display: flex; gap: 10px; align-items: center; justify-content: flex-end;">
      <span>
        <button class="btn btn-primary-soft" (click)="load()">Refresh</button>
      </span>
      <span>
        <button class="btn btn-success-soft" (click)="exportBudgets()">Export</button>
      </span>
    </div>
    <table class="table table-docs table-hover" id="budgets_table">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
          <th>Email IDs</th>
        </tr>
      </thead>

      <tbody *ngFor="let i of budgets; let j = index"
        [ngStyle]="{'display': budgets.length > 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td>{{j + 1}}</td>
          <td *ngFor="let h of headers">
            <span style="text-transform: capitalize;" *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
            <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || 0" (click)="callFunction(h.click, i, j)">
            </span>
          </td>
          <td>
            <span style="cursor: pointer; border-bottom: 2px dotted grey; padding: 4px;"
              (click)="selectedLinkedAccountID = i">
              Email IDs ({{ i.eid.length }})
            </span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="loading">
        <tr>
          <td [attr.colspan]="headers.length + 3" style="text-align: center;">
            <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': !loading && budgets.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 3">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<modal-popup *ngIf="selectedLinkedAccountID" [showModal]="selectedLinkedAccountID"
  (hideModal)="selectedLinkedAccountID = null" [data]="selectedLinkedAccountID.eid" [modalTitle]="'Budget Email IDs'"
  [headers]="[{'id' : 'eid', 'name': 'Email IDs'}]" [maxWidth]="'500px'"></modal-popup>