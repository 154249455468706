<div class="card">
  <h5 class="card-header">MIS Report</h5>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; gap: 10px;">
      <div>
        <select [(ngModel)]="frequency" (change)="frequencyChange()" class="form-control" style="width: 300px;">
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>
      <div [ngStyle]="{'display': frequency == 'weekly' ? 'block': 'none'}">
        <input class="dayDate form-control" type="text" id="dayDate" [(ngModel)]="dateRange" />
      </div>
      <div [ngStyle]="{'display': frequency == 'monthly' ? 'block': 'none'}">
        <input class="monthpicker form-control" type="text" id="monthDate" [(ngModel)]="dateRange" />
      </div>
    </div>

    <div class="card" style="border-top: none !important">
      <div class="card-header">
        <span>Summary of Daily Tickets Raised</span>
      </div>

      <div class="card-body">
        <div style="display: flex; align-items: center; justify-content: flex-end;" *ngIf="tickets.length > 0">
          <button class="btn btn-light" style="background-color: #5864FF; color: white;"
            (click)="exportTickets()">Export</button>
        </div>
        <table class="table table-docs table-hover" id="ticketsTable">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of ticketHeader">{{ h.name }}</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let i of tickets; let j = index">
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of ticketHeader">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{ i[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ i[h.id] }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="display: flex; margin: 10px; justify-content: right;" *ngIf="nextExists">
          <button class="btn btn-light" style="background-color: #5864FF; color: white;"
            (click)="loadTickets()">Next</button>
        </div>
      </div>
    </div>
    <div class="card" style="border-top: none !important"
      [ngStyle]="{'display': frequency == 'daily' ? 'block' : 'none'}">
      <div class="card-header">
        <span>Daily Backup Report</span>
      </div>

      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->
        <div style="display: flex; align-items: center; justify-content: flex-end;" *ngIf="instances.length > 0">
          <button class="btn btn-light" style="background-color: #5864FF; color: white;"
            (click)="exportDailyBackup()">Export</button>
        </div>

        <table class="table table-docs table-hover" id="dailyBackupTable">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of instancesHeader">{{ h.name }}</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let i of instances; let j = index">
              <td>{{ j + 1 }}</td>
              <td *ngFor="let h of instancesHeader">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                  <span>
                    {{ i[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ i[h.id] }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>