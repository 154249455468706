<h5 class="card-header">Floating Report</h5>
<div style="display: flex; margin-top: 10px; justify-content: flex-end; gap: 8px;">
  <button class="btn" style="background-color: #5864ff; color: white" (click)="refresh()">
    Refresh
  </button>
  <button class="btn" style="background-color: #5864ff; color: white" (click)="createJob($event)">
    Scan
  </button>
</div>
<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#job-details" type="button"
      role="tab" aria-controls="job-details" aria-selected="true" (click)="currentPage = 'job_details'">
      Scan
    </a>
    <a class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#floating-amis" type="button" role="tab"
      aria-controls="floating-amis" aria-selected="true" (click)="currentPage = 'floating_amis'">
      Floating AMIs
    </a>
    <a class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#floating-snapshots" type="button"
      role="tab" aria-controls="floating-snapshots" aria-selected="true" (click)="currentPage = 'floating_snapshots'">
      Floating Snapshots
    </a>
    <a class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#unused-volumes" type="button" role="tab"
      aria-controls="unused-volumes" aria-selected="true" (click)="currentPage = 'floating_volumes'">
      Unused Volumes
    </a>
  </div>
</nav>
<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane active" id="job-details" role="tabpanel" aria-labelledby="job-details-tab">
    <div class="card">
      <div class="card-body">
        <!--<h5 class="card-title">Case Details</h5>-->

        <table class="table table-docs table-hover">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of jobDetailsHeaders">
                {{ h.name }}
              </th>
            </tr>
          </thead>

          <tbody *ngIf="jobDetails">
            <tr>
              <td>1</td>
              <td *ngFor="let h of jobDetailsHeaders">
                <span *ngIf="h.click" class="btn" (click)="callFunction(h.click, jobDetails)">
                  <span>
                    {{ jobDetails[h.id] }}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{ jobDetails[h.id] }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!jobDetails">
            <tr>
              <td [attr.colspan]="jobDetailsHeaders.length + 1">
                <div style="text-align: center;">No Scans Found</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="floating-amis" role="tabpanel" aria-labelledby="floating-amis-tab">
    <div class="card">
      <div class="card-body">
        <div *ngIf="jobDetails && jobDetails['job_status'] && jobDetails['job_status'] == 'Completed'"
          class="alert alert-success floating_reports_download_container" role="alert">
          <span>
            Floating AMIs Report is ready to download
          </span>
          <span>
            <button class="btn btn-success" (click)="exportData()">Download</button>
          </span>
        </div>
        <div *ngIf="jobDetails && jobDetails['job_status'] && jobDetails['job_status'] == 'initiated'"
          class="alert alert-info floating_reports_download_container" role="alert">
          <span>
            Floating AMIs Report is not ready please wait
          </span>
          <span>
            <button class="btn btn-info" (click)="loadJobDetails(false)">Refresh</button>
          </span>
        </div>
        <div *ngIf="!jobDetails" class="alert alert-info floating_reports_download_container" role="alert">
          <span>No Scans Found</span>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="floating-snapshots" role="tabpanel" aria-labelledby="floating-snapshots-tab">
    <div class="card">
      <div class="card-body">
        <div *ngIf="jobDetails && jobDetails['job_status'] && jobDetails['job_status'] == 'Completed'"
          class="alert alert-success floating_reports_download_container" role="alert">
          <span>
            Floating Snapshots Report is ready to download
          </span>
          <span>
            <button class="btn btn-success" (click)="exportData()">Download</button>
          </span>
        </div>
        <div *ngIf="jobDetails && jobDetails['job_status'] && jobDetails['job_status'] == 'initiated'"
          class="alert alert-info floating_reports_download_container" role="alert">
          <span>
            Floating Snapshots Report is not ready please wait
          </span>
          <span>
            <button class="btn btn-info" (click)="loadJobDetails(false)">Refresh</button>
          </span>
        </div>
        <div *ngIf="!jobDetails" class="alert alert-info floating_reports_download_container" role="alert">
          <span>No Scans Found</span>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane" id="unused-volumes" role="tabpanel" aria-labelledby="unused-volumes-tab">
    <div class="card">
      <div class="card-body">
        <div *ngIf="jobDetails && jobDetails['job_status'] && jobDetails['job_status'] == 'Completed'"
          class="alert alert-success floating_reports_download_container" role="alert">
          <span>
            Unused Volumes Report is ready to download
          </span>
          <span>
            <button class="btn btn-success" (click)="exportData()">Download</button>
          </span>
        </div>
        <div *ngIf="jobDetails && jobDetails['job_status'] && jobDetails['job_status'] == 'initiated'"
          class="alert alert-info floating_reports_download_container" role="alert">
          <span>
            Unused Volumes Report is not ready please wait
          </span>
          <span>
            <button class="btn btn-info" (click)="loadJobDetails(false)">Refresh</button>
          </span>
        </div>
        <div *ngIf="!jobDetails" class="alert alert-info floating_reports_download_container" role="alert">
          <span>No Scans Found</span>
        </div>
      </div>
    </div>
  </div>
</div>
<create-job-element *ngIf="createJobTrigger" (hideModal)="hideCreateJob($event)"></create-job-element>