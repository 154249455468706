import { Component, OnInit, OnDestroy } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  templateUrl: './floating.component.html',
  styleUrls: ['./floating.component.css'],
})
export class floatingComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  jobDetails: any = null;
  floatingAMIs: any = [];
  floatingSnapshots: any = [];
  unusedVolumes: any = [];
  funRef: any;
  tagsList: any = null;
  tagsModalTitle: any = 'Tags';
  modalHeader: any;
  createJobTrigger: boolean = null;
  nextTokens: any = {
    floatingAMI: undefined,
    floatingSnapshots: undefined,
    unusedVolumes: undefined,
  };
  currentPage: String = 'job_details';

  destroyed: boolean = false;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;

  jobDetailsHeaders: any = [
    { id: 'created_on', name: 'Created On' },
    { id: 'region_name', name: 'Region' },
    { id: 'job_status', name: 'Job Status' },
    { id: 'total_snapshots', name: 'Total Snapshots' },
    { id: 'total_orphan_snapshots', name: 'Total Floating Snapshots' },
    { id: 'total_amis', name: 'Total AMIs' },
    { id: 'total_orphan_amis', name: 'Total Floating AMIs' },
    { id: 'total_volumes', name: 'Total Volumes' },
    { id: 'total_ununsed_volumes', name: 'Total Unused Volumes' },
    { id: 'total_instances', name: 'Total Instances' },
  ];

  floatingAMIsHeaders: any = [
    { id: 'ami_id', name: 'AMI ID', filter: true },
    { id: 'instance_id', name: 'Instance ID', filter: true },
  ];

  floatingSnapshotsHeaders: any = [
    { id: 'snapshot_id', name: 'Snapshot ID', filter: true },
    { id: 'volume_id', name: 'Volume ID', filter: true },
    { id: 'created_on', name: 'Created On' },
    { id: 'instance_id', name: 'Instance ID', filter: true },
  ];

  unusedVolumesHeaders: any = [
    { id: 'volume_id', name: 'Volume ID', filter: true },
    { id: 'size', name: 'Size', filter: true },
    { id: 'type', name: 'Type' },
  ];

  tagsHeader: any = [
    {
      id: 'Key',
      name: 'Key',
    },
    {
      id: 'Value',
      name: 'Value',
    },
  ];

  snapshotsHeader: any = [
    {
      id: 'snapsnot',
      name: 'Snapshot',
    },
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    this.funRef = {};
  }

  refresh_: boolean = false;
  refresh() {
    this.loadJobDetails(false);
  }

  async load() {
    await this.loadJobDetails(false);
  }

  async loadJobDetails(change_page: boolean = true) {
    if (change_page) {
      this.currentPage = 'job_details';
    }
    this.notifier.loading(true);
    let data = {
      action: 'get_latest_job_details',
      account_id: this.accountId,
      region_name: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/orphans`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.items) {
      if (Object.keys(result.item).length == 0) {
        this.jobDetails = null;
      } else {
        this.jobDetails = result.item;
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    if (!this.destroyed) {
      this.notifier.loading(false);
    }
    this.refresh_ = false;
    return true;
  }

  async exportData() {
    this.notifier.loading(true);

    let action: string;
    if (this.currentPage == 'floating_amis') {
      action = 'orphan_amis_download';
    }
    if (this.currentPage == 'floating_snapshots') {
      action = 'orphan_snapshots_download';
    }
    if (this.currentPage == 'floating_volumes') {
      action = 'orphan_volumes_download';
    }
    let data = {
      action: action,
      account_id: this.accountId,
      region_name: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/orphans`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      var link = document.createElement('a');
      link.download = this.currentPage + '.csv';
      link.href = result.presigned_url;
      link.click();
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {}

  createJob(event: any) {
    this.createJobTrigger = true;
  }

  hideCreateJob(event: any) {
    this.createJobTrigger = null;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
