import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

declare let Jhxlsx: any;

@Component({
  templateUrl: './budgets-all.component.html',
})
export class BudgetsAllComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';

  accountId: any;
  regionId: any;
  currentMessage: any;
  selectedLinkedAccountID: any;

  budgets: any = [];

  headers: any = [
    {
      id: 'lid',
      name: 'Linked Account ID',
    },
    {
      id: 'accountName',
      name: 'Account Name',
    },
    {
      id: 'budget',
      name: 'Budget',
    },
    {
      id: 'threshHold',
      name: 'ThreshHold (%)',
    },
    {
      id: 'dailyVariance',
      name: 'Variance Report',
    },
  ];

  funRef: any = {};
  lids: boolean = false;

  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  destroyed: boolean = false;
  loading: boolean = false;
  async load(nextToken: any = undefined) {
    this.loading = true;
    if (!nextToken) {
      this.budgets = [];
    }
    if (this.destroyed) {
      return;
    }
    let data: any = {
      a: 'fetchall',
      nt: nextToken,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/budgetalarm`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      result['lids'] = result['lids'].map((lid: any) => {
        lid['eid'] = lid['eid']
          .split(',')
          .filter((email: any) => {
            return email != '';
          })
          .map((email: any) => {
            return { eid: email };
          });
        return lid;
      });
      this.budgets = [...this.budgets, ...result.lids];
    }

    if (result.nt) {
      this.load(result.nt);
    } else {
      this.loading = false;
    }
  }

  callFunction(param: any, id: any, index: number) {}

  exportBudgets() {
    var dict: any = {
      sheetName: 'Budgets',
      data: [],
    };
    let headers: any = [{ text: 'Sl. No.' }];
    this.headers.forEach((h: any) => {
      headers.push({ text: h['name'] });
    });
    headers.push({ text: 'Email IDs' });
    dict.data.push(headers);
    this.budgets.forEach((lid: any, index: number) => {
      let data = [];
      data.push({ text: index + 1 });
      this.headers.forEach((h: any) => {
        data.push({ text: lid[h.id] });
      });
      data.push({
        text: lid['eid'].map((mail: any) => mail['eid']).join(', '),
      });
      dict.data.push(data);
    });
    var options = {
      fileName: `Budgets`,
    };
    Jhxlsx.export([dict], options);
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
