import {
  Component,
  OnDestroy,
  OnInit,
  NgZone,
  AfterViewInit,
} from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import * as moment from 'moment';
declare let $: any;
declare let window: any;
declare let flatpickr: any;
@Component({
  templateUrl: './cfrcenable-v3.component.html',
  styleUrls: ['./cfrcenable-v3.component.css'],
})
export class CfrcEnableV3 implements OnInit, OnDestroy {
  editMode: boolean = false;
  masterAccounts: any = [];
  fetchAccountID: string;
  accountId: any;
  editable: any = [];
  cfrcData: any = [];
  regionId: any;
  funRef: any;
  dateRange: any;
  currentMessage: any;
  updateCFRC: any;
  updateAction: any = 'update';
  unsavedCFRC: any = [];
  deleteCFRC: any;
  unsavedUpdateId: number = -1;

  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';

  constructor(
    private apiService: APIService,
    public notifier: NotifierService,
    private zone: NgZone
  ) {}
  headers: any = [
    {
      name: 'Region',
      id: 'region',
      selector: true,
      editable: () => {
        return this.editMode;
      },
    },
    {
      name: 'Description',
      id: 'description',
    },
    {
      name: 'Cost per Hour',
      id: 'costPerHour',
      editable: () => {
        return this.editMode;
      },
    },
  ];
  editableHeader: any = [
    {
      name: 'Region',
      id: 'editRegion',
      editable: true,
    },
    {
      name: 'Cost per Hour',
      id: 'editCost',
      editable: true,
    },
  ];
  ngOnInit(): void {
    this.funRef = {};
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.load(),
      component: this,
    };

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
    flatpickr('#dataRangePicker', {
      dateFormat: 'Y-M-d',
      mode: 'range',
      showMonths: 2,
      defaultDate: this.dateRange ? this.dateRange.split(' to ') : null,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.dateRange = dateStr;
      },
    });
  }

  async load() {
    this.notifier.loading(true);
    await this.loadMasterAccounts();
    this.notifier.loading(false);
  }

  async loadMasterAccounts() {
    if (
      this.masterAccounts.length > 0 &&
      this.fetchAccountID == this.accountId
    ) {
      await this.loadCFRCData($('#masterAccountSelect').val());
      return;
    }
    this.fetchAccountID = this.accountId;
    let data = {
      lid: [this.accountId],
      userType: 'C',
      action: 'fetchMasterAccList',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.masterAccounts = result.masterAccList;
      if (this.masterAccounts && this.masterAccounts.length > 0) {
        setTimeout(async () => {
          $('#masterAccountSelect').selectpicker('refresh');
          $('#masterAccountSelect').val(this.masterAccounts[0]);
          $('#masterAccountSelect').selectpicker('refresh');
          await this.loadCFRCData(this.masterAccounts[0]);
        }, 100);
      } else {
        setTimeout(async () => {
          $('#masterAccountSelect').val('');
          $('#masterAccountSelect').selectpicker('refresh');
        }, 100);
        this.masterAccounts = [];
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
  }
  async loadCFRCData(masterAccId: string) {
    let data = {
      action: 'fetch',
      accountId: masterAccId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-enable-v3`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      if (result.cloudFrontDetailsList) {
        this.cfrcData = result.data;
      } else {
        this.cfrcData = [];
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }
  }

  updateTrigger(id: any) {
    this.updateAction = 'update';
    this.updateCFRC = id;
  }

  deleteUnsavedCFRC(id: number) {
    this.unsavedCFRC.splice(id, 1);
  }

  updateUnsavedCFRC(id: any, index: number) {
    this.updateAction = 'add';
    this.unsavedUpdateId = index;
    this.updateCFRC = id;
  }

  closeUpdateTrigger(event: any) {
    if (event) {
      if (this.updateAction == 'update') {
        this.load();
      } else {
        if (this.unsavedUpdateId > -1) {
          this.unsavedCFRC[this.unsavedUpdateId] = event.data[0];
          this.unsavedUpdateId = -1;
        } else {
          this.unsavedCFRC.push(event.data[0]);
        }
      }
    }
    this.updateCFRC = null;
  }
  async deleteTrigger(id: any) {
    if (confirm('Are you sure you want to delete this record?')) {
      this.notifier.loading(true);
      let data: any = {
        lid: this.accountId,
        a: 'delete',
        serviceName: id.serviceName,
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-enable-v3`;
      let result = await this.apiService.postDataPromis(apiURL, data, header);
      if (result.status == '1' || result.s == '1') {
        this.notifier.alert('Success', '', result.msg, 'success', 5000);
        this.load();
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }

      this.notifier.loading(false);
    } else {
      return;
    }
  }

  closeDeleteTrigger(event: any) {
    this.deleteCFRC = null;
  }

  addCfrc() {
    this.updateAction = 'add';
    this.unsavedUpdateId = -1;
    this.updateCFRC = {
      region: '',
      description: '',
      costPerHour: 0,
    };
  }

  async saveDetails() {
    console.log('data to save', this.unsavedCFRC);
    this.notifier.loading(true);
    let data: any = {
      action: 'add',
      data: this.unsavedCFRC,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-enable-v3`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    console.log('result of api', result);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.unsavedCFRC = [];
      this.load();
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  callFunction(param: any, id: any) {
    this.funRef[param](this, id);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
