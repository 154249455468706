<div class="card mt-20" style="border: none; position: relative; height: 100%;">
  <h5 class="card-header">EC2 Usage Alarms</h5>
  <div class="card-body">
    <div style="display: flex; gap: 10px; align-items: center; justify-content: flex-end;">
      <span style="font-weight: bold">Master Accounts: </span>
      <span>
        <select style="width: 300px" class="form-control" (change)="triggerFetchLinkedAccounts()"
          [(ngModel)]="selectedMasterAccount" id="masterAccountSelect" data-live-search="true">
          <option [ngValue]="account" *ngFor="let account of masterAccounts">
            {{ account.accountId }}
          </option>
        </select>
      </span>
      <img src="../assets/img/loading_2.svg" *ngIf="fetchingMasterAccounts" alt="loading scans"
        style="width: 18px; margin: 10px" />
      <span>
        <button class="btn btn-light" (click)="addAlarm()"
          style="display: flex; justify-content: flex-start; align-items: center; gap: 8px;">
          <i class="fa fa-plus" style="color: inherit;"></i>
          <span>Add Alarm</span>
        </button>
      </span>
      <span>
        <button class="btn btn-primary-soft" (click)="refresh()">Refresh</button>
      </span>
    </div>
    <table class="table table-docs table-hover" id="budgets_table">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th *ngFor="let h of headers">{{ h.name }}</th>
          <th>Actions</th>
        </tr>
      </thead>

      <tbody *ngFor="let i of linkedAccountIDs; let j = index"
        [ngStyle]="{'display': linkedAccountIDs.length > 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td>{{j + 1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="!h.click" [innerHTML]="i[h.id] || 0"> </span>
            <span class="btn btn-link" *ngIf="h.click" [innerHTML]="i[h.id] || 0" (click)="callFunction(h.click, i, j)">
            </span>

          </td>
          <td>
            <span (click)="deleteAlarmTrigger(i, j)">
              <i class="fa fa-trash delete" aria-hidden="true" style="cursor: pointer;"></i>
            </span>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="fetchingLInkedAccountIDs">
        <tr>
          <td [attr.colspan]="headers.length + 3" style="text-align: center;">
            <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
          </td>
        </tr>
      </tbody>
      <tbody
        [ngStyle]="{'display': !fetchingLInkedAccountIDs && linkedAccountIDs.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 3">
            <div style="text-align: center;">No Data Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<update-ec2threshhold *ngIf="updateAlarm" [budget]="updateAlarm" (hideModel)="updateUI($event)">
</update-ec2threshhold>
<delete-ec2threshhold *ngIf="deleteAlarm" [lid]="deleteAlarm" [mid]="selectedMasterAccount"
  (hideModel)="updateDeleteAlarmUI($event)"></delete-ec2threshhold>