<div class="confirmContainer">
  <div class="confirmBox">
    <div class="KBHeader mb-2">Add ERP</div>
    <div class="KBBody">
      <div class="col-mb-6 mb-10">
        <label>Account ID </label>
        <span>
          <input class="form-control custom-input" type="text" [(ngModel)]="accountId">
        </span>
      </div>
      <div class="col-mb-6 mb-10">
        <label>Customer ID</label>
        <span>
          <input class="form-control custom-input" type="text" [(ngModel)]="customerId">
        </span>
      </div>
      <div class="col-mb-6 mb-10">
        <label>Customer Name </label>
        <span>
          <input class="form-control custom-input" type="text" [(ngModel)]="scan_name">
        </span>
      </div>
      <div class="col-mb-6 mb-10">
        <label>ERP Order ID </label>
        <span>
          <input class="form-control custom-input" type="text" [(ngModel)]="erpOrderId">
        </span>
      </div>
      <div class="KBActions">
        <button class="btn btn-success" *ngIf="!loading" (click)="yes()">Add</button>
        <button class="btn btn-success" *ngIf="loading" [attr.disabled]="true">
          <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin-right: 10px;" />
          Save
        </button>
        <button class="btn btn-light" (click)="no(false)">Cancel</button>
      </div>
    </div>
  </div>
</div>