import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';

import * as moment from 'moment';

declare let flatpickr: any;
declare let $: any;
declare let window: any;
@Component({
  templateUrl: './view.ticket.component.html',
  styleUrls: ['./../ticket.component.css'],
})
export class viewTicketComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  tickets: any = [];
  totalTickets: number = 0;
  correspondence: any = [];
  activeTicket: any = null;
  supportType: any = 'minfy';
  minfyDisabled: boolean = false;
  awsDisabled: boolean = false;
  includeResolved: boolean = false;
  createTicket: any = null;
  dateRange: string;
  replyTicket_: any = null;
  modalTitle: any;
  Trigger: boolean = false;
  resolveTicket = false;
  addKB: any;
  funRef: any;
  nextToken: any = null;
  viewer: any;
  filterKeys: any = [
    {
      id: 'summary',
      name: 'Ticket Summary',
    },
    {
      id: 'issue_key',
      name: 'Ticket Id',
    },
    {
      id: 'priority',
      name: 'Priority',
    },
  ];

  dropDownKeyValues: any = [];
  loadingSearchValues: boolean = false;

  filterKey: string = this.filterKeys[0].id;
  filterValue: string = '';

  accountId: any;
  regionId: any;
  currentMessage: any;

  showInnerData: boolean = false;
  minfyHeaders: any = [
    {
      id: 'issue_key',
      name: 'Issue Key',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
    {
      id: 'summary',
      name: 'Summary',
    },
    {
      id: 'created',
      name: 'Created On',
      pipe: this.filterDate,
    },
    { id: 'reporter', name: 'Reporter' },
    { id: 'priority', name: 'Priority' },
    { id: 'status', name: 'Status' },
  ];

  awsHeaders: any = [
    {
      id: 'caseId',
      name: 'Case ID',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
    {
      id: 'timeCreated',
      name: 'Created On',
      pipe: this.filterDate,
    },
    {
      id: 'subject',
      name: 'Subject',
    },
    {
      id: 'severityCode',
      name: 'Service Code',
    },
    {
      id: 'categoryCode',
      name: 'Category Code',
    },
    {
      id: 'severityCode',
      name: 'Severity Code',
    },
    {
      id: 'status',
      name: 'Status',
    },
  ];

  correspondenceHeader: any = [
    { id: 'submittedBy', name: 'Sender' },
    { id: 'timeCreated', name: 'Time' },
    { id: 'body', name: 'Message' },
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.funRef = {
      showData: this.showData,
    };
    $('#search_dropdown').selectpicker();

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });

    setTimeout(() => {
      let start_date = moment().startOf('month').format('DD-MMM-YYYY');
      let end_date = moment().startOf('day').format('DD-MMM-YYYY');

      this.dateRange = start_date + ' to ' + end_date;
      flatpickr('.datetimepicker', {
        dateFormat: 'd-M-Y',
        mode: 'range',
        maxDate: new Date(),
        showMonths: 2,
        onChange: (selectedDates: any, dateStr: any, instance: any) => {
          if (!dateStr.includes('to')) return;
          this.dateRange = dateStr;
          this.load();
        },
      });
      if (this.filterKey == 'priority' || this.filterKey == 'reporter') {
        this.checkForValues();
      }
    }, 500);
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.minfyDisabled = false;
    this.awsDisabled = false;
    this.activeTicket = null;
    this.createTicket = null;
    this.notifier.loading(true);
    this.tickets = [];
    this.totalTickets = 0;
    this.nextToken = '';
    await this.checkMinfySupport();
    this.notifier.loading(false);
  }

  getSummary() {
    let summary: any = {};
    this.tickets.forEach((log: any) => {
      if (summary.hasOwnProperty(log.status)) {
        summary[log.status] += 1;
      } else {
        summary[log.status] = 1;
      }
    });
    return summary;
  }

  getKeys() {
    return Object.keys(this.getSummary());
  }

  getValue(key: string) {
    let data: any = this.getSummary();
    return data[key];
  }

  getPercentage(val: any) {
    var r = 25;
    var c = Math.PI * (r * 2);

    if (val < 0) {
      val = 0;
    }
    if (val > 100) {
      val = 100;
    }

    let pct = ((100 - val) / 100) * c;

    return pct;
  }

  async checkMinfySupport() {
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (selectedAccountData.error) {
        console.log(selectedAccountData.error);
      } else {
        const account = selectedAccountData.data;
        if (
          !account.jira_org_id ||
          !account.jira_org_name ||
          !account.jira_project_id ||
          !account.jira_project_key ||
          !account.jira_project_name
        ) {
          this.minfyDisabled = true;
          this.notifier.alert(
            'Info',
            '',
            'Managed Services support via SWAYAM is not enabled for your account. Please contact the admin.',
            'info',
            5000
          );
          await this.changeSupportType('aws');
        } else {
          this.minfyDisabled = false;
          await this.changeSupportType('minfy');
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  async changeSupportType(support: string) {
    this.supportType = support;
    this.nextToken = null;
    if (support == 'minfy') {
      this.notifier.loading(true);
      if (!this.minfyDisabled) {
        await this.loadMinfySupportTickets();
      }
      this.notifier.loading(false);
    } else {
      this.notifier.loading(true);
      await this.loadAWSSupportTickets();
      this.notifier.loading(false);
    }
  }

  async loadMinfySupportTickets() {
    if (!this.nextToken) {
      this.tickets = [];
    }
    this.notifier.loading(true);
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { jira_org_name, jira_project_key } = selectedAccountData.data;
        let start_date;
        let end_date;
        if (!(this.dateRange && this.dateRange.includes('to'))) {
          start_date = moment().startOf('month').format('YYYY-MM-DD');
          end_date = moment()
            .startOf('day')
            .add('days', 1)
            .format('YYYY-MM-DD');
        } else {
          let dRange = this.dateRange.toString().split(' to ');
          start_date = moment(dRange[0], 'DD-MMM-YYYY').format('YYYY-MM-DD');
          end_date = moment(dRange[1], 'DD-MMM-YYYY')
            .add('days', 1)
            .format('YYYY-MM-DD');
        }
        let data = {
          action: 'jira_list',
          account_id: this.accountId,
          include_resolved: this.includeResolved.toString(),
          jira_project_key: jira_project_key,
          jira_organization_key: jira_org_name,
          start_date: start_date,
          end_date: end_date,
          filters:
            this.filterValue == ''
              ? undefined
              : {
                  reporter:
                    this.filterKey == 'reporter' ? this.filterValue : undefined,
                  summary:
                    this.filterKey == 'summary' ? this.filterValue : undefined,
                  issue_key:
                    this.filterKey == 'issue_key'
                      ? this.filterValue
                      : undefined,
                  priority:
                    this.filterKey == 'priority' ? this.filterValue : undefined,
                },
          next_token: this.nextToken ? this.nextToken : undefined,
        };
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        //https://api.swayam.cloud/v3/admin/support
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (result.status == '1' || result.s == '1') {
          this.tickets = [...this.tickets, ...result.issues];
          this.totalTickets = result.all_issues || result.total;
          if (result.next_token && result.next_token != '') {
            this.nextToken = result.next_token;
          } else {
            this.nextToken = null;
          }
        } else {
          this.notifier.alert('Info', '', result.error_message, 'info', 5000);
        }
      } else {
        this.notifier.alert(
          'Error',
          '',
          selectedAccountData.msg,
          'error',
          5000
        );
      }
    } catch (err: any) {
      console.log(err);
      this.notifier.alert('Info', '', err.msg, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async loadAWSSupportTickets() {
    if (!this.nextToken) {
      this.tickets = [];
    }
    this.notifier.loading(true);
    let data = {
      action: 'list',
      account_id: this.accountId,
      include_resolved: this.includeResolved.toString(),
      next_token: this.nextToken ? this.nextToken : undefined,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.tickets = [...this.tickets, ...result.cases];
      this.totalTickets = result.all_issues || result.total;
      if (result.next_token && result.next_token != '') {
        this.nextToken = result.next_token;
      } else {
        this.nextToken = null;
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        'Business support plan or Enterprise support plan is required for this feature',
        'info',
        5000
      );
      this.changeSupportType('minfy');
      this.awsDisabled = true;
    }
    this.notifier.loading(false);
  }

  filterDate(dateString: any) {
    return moment(dateString).format('DD-MMM-YYYY HH:MM:SS');
  }

  async refreshCorrespondence() {
    this.notifier.loading(true);
    this.correspondence = [];
    await this.loadCorrespondence(this);
    this.notifier.loading(false);
  }

  async loadCorrespondence(ref: any) {
    let data = {
      action: 'jira_comm',
      issue_key: ref.activeTicket.issue_key,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      ref.correspondence = result.issues_details.comments;
      ref.correspondence = ref.correspondence.map((chat: any) => {
        let chatLines = chat.body.split('\n');
        if (chatLines.length > 0) {
          let chats = [];
          for (let i = 0; i < chatLines.length; i++) {
            let chatElement = document.createElement('p');
            chatElement.classList.add('chat_td');
            chatElement.style.margin = '0';
            chatElement.innerHTML = chatLines[i];
            chats.push(chatElement.outerHTML);
            chatElement = undefined;
          }
          chat.body = chats.join(' ');
        }
        if (chat.attachments && chat.attachments.length > 0) {
          chat.attachments = chat.attachments.map((attachment: any) => {
            if (attachment.file_type.indexOf('image') > -1) {
              attachment['src'] = 'LOADING';
            }
            this.loadCorrespondenceImage(attachment);
            return attachment;
          });
        }
        if (chat.body) {
          chat.body = chat.body.replaceAll('\n', '');
        }
        return chat;
      });
      ref.activeTicket.participants =
        result.issue_details && result.issues_details.participants
          ? result.issues_details.participants.join()
          : [];
    } else {
      ref.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
  }

  viewImage(attachment: any) {
    this.viewer = { ...attachment };
    this.viewer.show = false;
    this.viewer.width = 50;
    setTimeout(() => {
      this.viewer.show = true;
    }, 1);
  }

  hideImage() {
    this.viewer.show = false;
    setTimeout(() => {
      this.viewer = null;
    }, 200);
  }

  async downloadAttachment(attachment: any) {
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      url: attachment['file_url'],
      action: 'download_jira_attachments',
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.status == 1) {
      let url = `data:${attachment.file_type};base64,` + result.data;
      const fileName = attachment.file_name;
      var el = document.createElement('a');
      el.setAttribute('href', url);
      el.setAttribute('download', fileName);
      el.click();
      el = undefined;
    }
  }

  clearFilters() {
    this.filterValue = '';
    $('#search_dropdown').val('');
    $('#search_dropdown').selectpicker('refresh');
    this.load();
  }

  async checkForValues() {
    this.filterValue = '';
    if (this.filterKey != 'priority' && this.filterKey != 'reporter') {
      return;
    }
    this.dropDownKeyValues = [];
    setTimeout(() => {
      $('#search_dropdown').selectpicker('refresh');
    }, 100);
    try {
      let selectedAccountData: any = await this.fetchSelectedAccountDetails();
      if (!selectedAccountData.error) {
        const { jira_org_name, jira_project_key } = selectedAccountData.data;
        let header = {
          Authorization: localStorage.getItem('t'),
        };

        let data = {
          filter_name: this.filterKey,
          jira_project_key: jira_project_key,
          jira_organization_key: jira_org_name,
          action: 'jira_filter_values',
        };

        this.loadingSearchValues = true;
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

        let result = await this.apiServer.postDataPromis(apiURL, data, header);

        if (result.status == '1' || result.status == 1) {
          this.dropDownKeyValues = result.filter_values.map((value: any) => {
            return {
              id: value,
              name: value,
            };
          });
          setTimeout(() => {
            $('#search_dropdown').selectpicker('refresh');
          }, 100);
        } else {
          this.notifier.alert('Info', '', result.message, 'info', 5000);
        }
      } else {
        console.log(selectedAccountData.error);
      }
    } catch (err) {
      console.log(err);
    }
    this.loadingSearchValues = false;
  }

  async loadCorrespondenceImage(attachment: any) {
    if (attachment.file_type.indexOf('image') < 0) {
      return;
    }
    attachment['src'] = 'LOADING';
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      url: attachment['file_url'],
      action: 'download_jira_attachments',
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);
    if (result.status == '1' || result.status == 1) {
      attachment.src = `data:${attachment.file_type};base64,` + result.data;
    }
  }

  async loadAWSCorrespondence(ref: any) {
    ref.notifier.loading(true);
    let data = {
      action: 'comm',
      account_id: this.accountId,
      case_id: ref.activeTicket.caseId,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      ref.correspondence = result.communications;
      ref.correspondence = ref.correspondence.map((chat: any) => {
        let chatLines = chat.body.split('\n');
        if (chatLines.length > 0) {
          let chats = [];
          for (let i = 0; i < chatLines.length; i++) {
            let chatElement = document.createElement('p');
            chatElement.classList.add('chat_td');
            chatElement.style.margin = '0';
            chatElement.innerHTML = chatLines[i];
            chats.push(chatElement.outerHTML);
            chatElement = undefined;
          }
          chat.body = chats.join(' ');
        }
        if (chat.attachments && chat.attachments.length > 0) {
          chat.attachments = chat.attachments.map((attachment: any) => {
            if (attachment.file_type.indexOf('image') > -1) {
              attachment['src'] = 'LOADING';
            }
            this.loadCorrespondenceImage(attachment);
            return attachment;
          });
        }
        if (chat.body) {
          chat.body = chat.body.replaceAll('\n', '');
        }
        return chat;
      });
      ref.activeTicket.participants =
        result.issue_details && result.issues_details.participants
          ? result.issues_details.participants.join()
          : [];
    } else {
      ref.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
    ref.notifier.loading(false);
  }

  async fetchSelectedAccountDetails() {
    return new Promise((resolve, reject) => {
      let selectedAccounts: any = localStorage.getItem('accountIdData');
      let selectedAccountDetail: any;
      if (selectedAccounts) {
        selectedAccounts = JSON.parse(selectedAccounts);
        selectedAccountDetail = selectedAccounts.list.find((account: any) => {
          return account.accountId == this.accountId;
        });
        resolve({ error: false, data: selectedAccountDetail });
      } else {
        reject({ error: true, msg: 'No Account is Selected' });
      }
    });
  }

  goBack() {
    this.activeTicket = null;
    this.correspondence = [];
  }

  async showData(ref: any, id: any) {
    ref.activeTicket = id;
    ref.notifier.loading(true);
    if (ref.supportType == 'minfy') {
      await ref.loadCorrespondence(ref);
    } else if (ref.supportType == 'aws') {
      await ref.loadAWSCorrespondence(ref);
    }
    ref.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  replyTicket() {
    this.modalTitle = 'Reply';
    this.replyTicket_ = this.activeTicket;
  }

  reOpenTicket() {
    this.modalTitle = 'Reopen';
    this.replyTicket_ = this.activeTicket;
  }

  resolveTicketTrigger() {
    this.resolveTicket = true;
  }

  async hideResolveTicket(event: any) {
    if (event.status) {
      this.notifier.loading(true);
      let data = {
        account_id: this.accountId,
        case_id: this.activeTicket.caseId,
        action: 'resolve',
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.status) {
        window.analyticsSendEventClick('Tickets', `Ticket Resolved`);

        this.notifier.alert(
          'Resolved',
          '',
          'Ticket Resolved Successfully!',
          'success',
          5000
        );
      } else {
        this.notifier.alert('Info', '', result.error_message, 'info', 5000);
      }
      this.notifier.loading(false);
    } else {
      this.resolveTicket = false;
    }
  }

  addToKB() {
    this.addKB = this.activeTicket;
    this.addKB['history'] = this.correspondence
      .map((chat: any) => {
        return chat.body;
      })
      .join('\n');

    this.addKB.new = true;
    if (this.supportType == 'minfy') {
      this.addKB['subject'] = this.addKB['summary'];
      delete this.addKB['summary'];
    }
  }

  createTicketTrigger(ticket: any) {
    this.createTicket = ticket;
    if (this.createTicket.summary) {
      this.createTicket['subject'] = this.createTicket.summary;
    }
  }

  hideCreateTicket(event: any) {
    this.createTicket = null;
  }

  hideAddKB(event: any) {
    this.addKB = null;
  }

  hideModal(event: any) {
    this.replyTicket_ = null;
    if (event) {
      this.refreshCorrespondence();
    }
  }

  export() {
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    window.exportToExcel('view-tickets-table', 'ticketlist', 'csv');
  }

  print_r(object: any, html: any) {
    if (html) return '<pre>' + JSON.stringify(object, null, 4) + '</pre>';
    else return JSON.stringify(object, null, 4);
  }
}
