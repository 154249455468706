import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

@Component({
  selector: 'reports-patchreport',
  templateUrl: './patchreport.component.html',
  styleUrls: ['./patchreport.component.css'],
})
export class PatchreportComponent implements OnInit {
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.load();
  }
  loading: boolean = false;
  presigned_url: string;
  scan_time: string;
  message: string;
  async load() {
    this.loading = true;
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let data = {
      a: 'fetchPatchReport',
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/admin/reports/fetchconsoliatedreports`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.presigned_url = result.sUrl;
      this.scan_time = moment(result.st, 'YYYYMMDD_hhmmss').format(
        'MMM DD, YYYY hh:mm A'
      );
      if (result.msg) {
        this.message = result.msg;
      }
    } else {
      this.notifier.alert('Info', '', result.message, 'info', 5000);
    }
    this.loading = false;
  }

  downloadReport() {
    var link = document.createElement('a');
    link.download = 'PatchReport.csv';
    link.href = this.presigned_url;
    link.click();
  }
}
