<div class="alarm_screen">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4>Two Factor Authentication</h4>
    </div>
    <div class="alarm_body">
      <div class="row">
        We strongly recommend you to enable 2FA on your account!
      </div>
      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="uploadFile()" style="background-color: #5864ff; color: white">
            Keep Enabled
          </button>
          <button class="btn btn-light" (click)="close()">Diable anyway!</button>
        </div>
      </div>
    </div>
  </div>
</div>