import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  templateUrl: './downloadall.component.html',
  styleUrls: ['./downloadall.component.css'],
  selector: 'download-all-billing',
})
export class BillingDownloadAll implements OnInit, OnDestroy {
  @Input('data') data: any;
  @Input('month_year') month_year: any;
  @Input('region_name') region_name: any;
  @Output('hideModal') hideModal = new EventEmitter<any>();
  constructor() {}
  ngOnInit(): void {
    console.log(this.data, this.month_year, this.region_name);
  }
  ngOnDestroy(): void {}

  download() {
    console.log(this.data);
  }

  close() {
    this.hideModal.emit(true);
  }
}
