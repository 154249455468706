import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
declare let window: any;
@Component({
  templateUrl: './rds.component.html',
})
export class rdsOperationsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  rdsList: any = [];
  rdsClusterList: any = [];
  showTagsModal: any;
  command: any = null;
  instance: any;
  showVPCPopup: boolean = false;
  modalTitle: string;
  action: string = null;
  VPCList: any = [];

  rdsHeaders: any = [
    {
      id: 'dbName',
      name: 'DB Name',
      alternate: 'dbInstanceIdentifier',
    },
    { id: 'dbEngine', name: 'DB Engine' },
    { id: 'dbInstanceType', name: 'Storage Type' },
    {
      id: 'allocatedtorage',
      name: 'Allocated Storage',
    },
    {
      id: 'dbConnectionCount',
      name: 'DB Connection Count',
    },
    {
      id: 'cpuUtilization',
      name: 'CPU Utilization',
    },
    {
      id: 'freeableMemory',
      name: 'Freeable Memory',
    },
    {
      id: 'backupRetentionPeriod',
      name: 'Backup Retention Period',
    },
    {
      id: 'dbStatus',
      name: 'DB Status',
    },
  ];
  rdsClsuterHeaders: any = [
    {
      id: 'clusterIdentifier',
      name: 'Cluster Identifier',
      click: 'loadRDSInstances',
    },
    { id: 'dbEngine', name: 'DB Type' },
    { id: 'size', name: 'Size' },
    { id: 'status', name: 'DB Status' },
  ];

  rdsClsuterInstanceHeaders: any = [
    {
      id: 'clusterIdentifier',
      name: 'Cluster Identifier',
    },
    {
      id: 'dbEngine',
      name: 'DB Type',
    },
    {
      id: 'dbInstanceType',
      name: 'Size',
    },
    {
      id: 'dbConnectionCount',
      name: 'DB Connection Count',
    },
    {
      id: 'cpuUtilization',
      name: 'CPU Utilization',
    },
    {
      id: 'freeableMemory',
      name: 'Freeable Memory',
    },
    {
      id: 'dbStatus',
      name: 'DB Status',
    },
  ];

  VPCGroupHeader: any = [
    {
      id: 'sg_id',
      name: 'SG Id',
    },
    {
      id: 'sg_status',
      name: 'Status',
    },
  ];

  funRef: any;

  accountId: any;
  regionId: any;
  currentMessage: any;
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.funRef = {
      showData: this.showData,
      loadRDSInstances: this.showCluster,
    };

    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  async load() {
    this.notifier.loading(true);
    let data = {
      emailId: 'rubeena.j@minfytech.com',
      accountId: this.accountId,
      region: this.regionId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    window.analyticsSendEventClick('Operations.Rds', 'RDS Instances Fetched');
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/listrds`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.rdsList = result.rdsDetailsList;
      this.rdsClusterList = result.clusterDetailsList;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }

  loadVPCGroup(param: any) {
    let dt: any = param.split(', ');
    dt = dt.map((sg: any) => {
      return { sg_id: sg.split(':')[0], sg_status: sg.split(':')[1] };
    });
    this.VPCList = dt;
    this.showVPCPopup = true;
  }

  hidePop(event: any) {
    this.showVPCPopup = false;
  }

  async showData() {}

  showTags(value: any, identifier: string) {
    let dt = value;
    dt['identifier'] = identifier;
    if (identifier == 'rdsCluster') {
      dt['dbInstanceIdentifier'] = dt['clusterIdentifier'];
    }
    console.log(dt);
    this.showTagsModal = dt;
  }

  startStopTrigger(i: any, trigger: string, action: string) {
    this.action = action;
    this.command = trigger;
    if (trigger == 'run') {
      this.modalTitle = 'start';
    } else if (trigger == 'stop') {
      this.modalTitle = 'stop';
    }
    i['instanceId'] = i['dbInstanceIdentifier'] || i['clusterIdentifier'];
    i['tagName'] = i['dbInstanceIdentifier'] || i['clusterIdentifier'];
    i['instanceStatus'] = i['dbStatus'] || i['status'];
    this.instance = [i];
  }

  showCluster(ref: any, i: any) {
    if (i.showNested) {
      i.showNested = false;
    } else {
      i.showNested = true;
    }
  }

  hideMdoal(event: any) {
    this.showTagsModal = null;
    this.instance = null;
  }

  hideNested(event: any) {}

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
