<div class="card mt-0">
  <div class=" card-header" *ngIf="!isMilestone">
    <nav class="mar-top-19">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button"
          role="tab" aria-controls="overview" aria-selected="true">
          Overview
        </a>
        <a class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#milestones" type="button" role="tab"
          aria-controls="milestones" aria-selected="true">
          Milestones
        </a>
        <a class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#properties" type="button" role="tab"
          aria-controls="properties" aria-selected="true">
          Properties
        </a>
      </div>
    </nav>
  </div>
  <div class="card-body">
    <div class="tab-content" [ngClass]="{'tab-content': !isMilestone}" id="nav-tabContent">
      <div class="tab-pane" [ngClass]="{'active': !isMilestone}" id="overview" role="tabpanel"
        aria-labelledby="overview-tab">
        <div class="review-alert mb-3"
          *ngIf="questions.length - fetchTotalAnsweredQuestions().length > 0 && lenses.length > 0 && !isMilestone">
          <div>
            <i class="fa fa-info-circle" aria-hidden="true"></i>
          </div>
          <div class="alert-body">
            <div>
              <div class="alert-title">Continue Reviewing</div>
              <div>You have unanswered questions. Continue reviewing and answer the questions for each lens.</div>
            </div>
            <div>
              <button class="btn btn-primary-soft continue-lenses" *ngIf="lenses.length > 0">
                <span>Continue Reviewing</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
                <div class="continue-lenses-absolute">
                  <div *ngFor="let lens of lenses" class="button-lens" (click)="showQuestions(lens['lens'])">
                    {{ lensesData[lens['lens']] }}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="card mt-0">
          <div class="card-header flex-between">
            <h5 *ngIf="!isMilestone">Workload Overview</h5>
            <h5 *ngIf="isMilestone">Milestone Overview</h5>
            <span class="nav-link" *ngIf="!isMilestone" (click)="saveMilestone.emit(true)">Save Milestone</span>
          </div>
          <div class="card-body">
            <div class=" progress-container mb-3">
              <label>Questions Answered ({{fetchTotalAnsweredQuestions().length}}/{{questions.length}})</label>
              <div class="flex-left">
                <div class="progress" [ngClass]="{loading: loading['questions']}">
                  <ng-container *ngIf="!loading['questions']">
                    <div [ngStyle]="{'background-color': lens['color'],width: loaded ? ((filterQuestions(lens['lens'],
                      'lensId', true).length / questions.length) * 100 + '%'): '0%'} " class="progress-indicator"
                      *ngFor="let lens of lenses">
                      <div class="progress-tooltop">
                        <div class="flex-between">
                          <div class="white-no-wrap">
                            {{ lensesData[lens['lens']] }}
                          </div>
                          <div>
                            {{filterQuestions(lens['lens'],
                            'lensId', true).length}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="progress-container mb-3">
              <label>Risks</label>
              <div class="flex-left">
                <div class="progress" [ngClass]="{loading: loading['questions']}">
                  <ng-container *ngIf="!loading['questions']">
                    <div
                      [ngStyle]="{'background-color': '#c94d6d', width: loaded ? (fetchQuestionsRisks('High').length / questions.length) * 100 + '%' : '0px'} "
                      class="progress-indicator">
                      <div class="progress-tooltop">
                        <h5>High</h5>
                        <div *ngFor="let lens of lenses">
                          <div class="flex-between">
                            <div class="white-no-wrap">
                              {{ lensesData[lens['lens']] }}
                            </div>
                            <div>
                              {{fetchQuestionsRisks('High', lens['lens']).length}}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div
                      [ngStyle]="{'background-color': '#e48b1f', width: loaded ? (fetchQuestionsRisks('Medium').length / questions.length) * 100 + '%' : '0%'} "
                      class="progress-indicator">
                      <div class="progress-tooltop">
                        <h5>Medium</h5>
                        <div *ngFor="let lens of lenses">
                          <div class="flex-between">
                            <div class="white-no-wrap">
                              {{ lensesData[lens['lens']] }}
                            </div>
                            <div>
                              {{fetchQuestionsRisks('Medium', lens['lens']).length}}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div
                      [ngStyle]="{'background-color': '#e7c443', width: loaded ? (fetchQuestionsRisks('Low').length / questions.length) * 100 + '%' : '0px'} "
                      class="progress-indicator">
                      <div class="progress-tooltop">
                        <h5>Low</h5>
                        <div *ngFor="let lens of lenses">
                          <div class="flex-between">
                            <div class="white-no-wrap">
                              {{ lensesData[lens['lens']] }}
                            </div>
                            <div>
                              {{fetchQuestionsRisks('Low', lens['lens']).length}}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header flex-between">
            <h5>Lenses</h5>
          </div>
          <div class="card-body" *ngIf="!loading['questions']">
            <table class="table table-docs">
              <thead>
                <tr>
                  <th>Sl. No.</th>
                  <th>Name</th>
                  <th>Questions Answered</th>
                  <th>High Risks</th>
                  <th>Medium Risks</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let lens of lenses; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td><span class="nav-link" (click)="selectLens.emit(lens['lens'])">{{ lensesData[lens['lens']]
                      }}</span></td>
                  <td>{{ filterQuestions(lens['lens'],
                    'lensId', true).length}}/{{ filterQuestions(lens['lens'],
                    'lensId', false).length }}</td>
                  <td>{{ fetchQuestionsRisks('High', lens['lens']).length }}</td>
                  <td>{{ fetchQuestionsRisks('Medium', lens['lens']).length }}</td>
                  <td>
                    <span class="btn btn-success-soft" (click)="downloadReport(lens['lens'])">
                      <i class="fa fa-download mr-5" aria-hidden="true"></i>
                      Download
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tab-pane" *ngIf="!isMilestone" id="milestones" role="tabpanel" aria-labelledby="milestones-tab">
        <app-milestones [loading]="loading" *ngIf="!isMilestone" [workloadId]="workload['id']" [milestones]="milestones"
          (milestone)="milestone.emit($event)"></app-milestones>
      </div>
      <div class="tab-pane" *ngIf="!isMilestone" id="properties" role="tabpanel" aria-labelledby="properties-tab">
        <app-properties [workload]="workload" [lensesData]="lensesData"></app-properties>
      </div>
    </div>
  </div>
</div>