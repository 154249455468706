<div class="card-header" style="margin-top: 19px; height: 60px">
  <span class="title" style="font-size: 22px; line-height: 45px; vertical-align: middle">RDS Cost Controls</span>

  <button class="btn btn-primary-soft" style="float: right; margin: 0 10px" (click)="refresh()">
    <i class="fa fa-refresh" aria-hidden="true"></i>
    Refresh
  </button>
  <button class="btn btn-success-soft" style="float: right; margin: 0 10px"
    *ngIf="loading_recom == false && currentPage == 'recomondations'" (click)="downloadReport()"><i
      class="fa fa-download" aria-hidden="true"></i>
    Download</button>
  <button *ngIf="scanAccess" class="btn btn-success-soft" style="float: right; margin: 0 10px"
    (click)="createJob($event)">
    <i class="fa fa-search" aria-hidden="true"></i>
    Scan
  </button>
</div>

<nav style="margin-top: 19px">
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#job-details" type="button"
      role="tab" aria-controls="job-details" aria-selected="true" (click)="loadJobDetails()">
      Scan
    </a>
    <a class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#floating-amis" type="button" role="tab"
      aria-controls="floating-amis" aria-selected="true" (click)="loadrecomondations()">
      Recommendations
    </a>
  </div>
</nav>
<div>
  <!--<div style="width: 100%; height: 500px; position: relative;">-->
  <div style="
      overflow: auto;
      background: #fff;
    ">
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane active" id="job-details" role="tabpanel" aria-labelledby="job-details-tab">
        <div class="card" style="margin-top: 0px;">
          <div class="card-body" *ngIf="jobDetails && !fetching" style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            ">
            <div style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
              ">
              <div class="bold_text">Scanned On</div>
              <div>{{ jobDetails["scandate"] }}</div>
            </div>
            <div style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
              ">
              <div class="bold_text">Status</div>
              <div>{{ jobDetails["accountStatus"] }}</div>
            </div>
          </div>
          <div *ngIf="!jobDetails && fetching" class="card-body" style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            ">
            <span class="center">
              <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" style="width: 25px; margin: 10px" />
            </span>
          </div>
          <div *ngIf="!jobDetails && !fetching" class="card-body" style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            ">
            <span class='center'>
              No Scans Found. <span *ngIf="scanAccess"> <span class="btn_" (click)="createJob($event)">Click
                  here</span> to create one.</span></span>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="floating-amis" role="tabpanel" aria-labelledby="floating-amis-tab">
        <div class="card" style="margin-top: 0px;">
          <!-- <div style="width: 100%; height: 100px; background-color: red;"></div> -->
          <div class="card-body">
            <!-- <div style="display: flex; justify-content: flex-start; padding: 10px; align-items: center; gap: 10px;">
              <div>Potential Saving: </div>
              <div>
                <span class="green_text" style="margin-left: 5px; font-size: 1.5em;"
                  [innerHTML]="getTotalSavings() + ' USD'"></span>
              </div>
              <div *ngIf="loading_recom">
                <img src="../assets/img/loading_2.svg" alt="loading scans" width="10"
                  style="width: 15px; margin: 10px;">
              </div>
            </div> -->
            <div style="overflow: auto; max-width: 100%;">
              <table class="table table-docs table-hover cost-table" id="tags_cost_optimization">
                <thead>
                  <tr>
                    <th>S.N.</th>
                    <th *ngFor="let h of ec2CostHeaders">
                      {{ h.name }}
                      <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h, recomondations)">
                        <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                        <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
                      </span>

                      <span *ngIf="h.filter" class="inputseach">
                        <input type="text" [(ngModel)]="h.filterText" />
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </span>
                    </th>
                    <th>Reason</th>
                    <th>More Details</th>
                  </tr>
                </thead>

                <tbody [ngStyle]="{
                  display: recomondations.length ? 'table-row-group' : 'none'
                }" *ngFor="let i of recomondations; let j = index">
                  <tr *ngIf="notifier.filterTableColums(ec2CostHeaders, i)">
                    <td>{{ j + 1 }}</td>
                    <td *ngFor="let h of ec2CostHeaders">
                      <div *ngIf="h.click" class="btn" (click)="callFunction(h.click, i)">
                        <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" [innerHTML]="loadHTML(i[h.id])">
                        </div>
                      </div>
                      <div [ngStyle]="{'text-align': h.center ? 'center': 'left'}" *ngIf="!h.click"
                        [innerHTML]="loadHTML(i[h.id])">
                      </div>
                    </td>
                    <td style="overflow: auto;">
                      <div class="failed_ami" (mouseenter)="setPosition($event)">
                        <div class="status_container"
                          style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                          <div
                            style="border-bottom: 1px dotted black; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width: 500px "
                            [innerHTML]="i['reason']">

                          </div>
                        </div>
                        <div class="failed_ami_message_container"
                          [ngStyle]="{right: position.x ? position.x : 0, top: position.y ? position.y : 0, height: position.height, width: position.width}">
                          <div class="message_back">
                            <div>
                              <div class="message_grid"
                                [ngStyle]="{'max-width': position.overflow ? position.overflowwidth : '', 'overflow-x': position.overflow ? 'auto' : 'visible'}">
                                <div style="width: 600px; white-space: pre-wrap;">
                                  {{i['reason']}}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style="display: flex; height: 20px;">
                            <div class="showdown" [ngStyle]="{right: position.pointer}"></div>
                          </div>
                          <div class="message_container">
                            <div>
                              <div class="message_grid"
                                [ngStyle]="{'max-width': position.overflow ? position.overflowwidth : '', 'overflow-x': position.overflow ? 'auto' : 'visible'}">
                                <div style="width: 600px; white-space: pre-wrap;">
                                  {{i['reason']}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="failed_ami" (mouseenter)="setPosition($event)">
                        <div class="status_container">
                          <div style="border-bottom: 1px dotted black;">
                            More Details
                          </div>
                        </div>
                        <div class="failed_ami_message_container"
                          [ngStyle]="{right: position.x ? position.x : 0, top: position.y ? position.y : 0}">
                          <div class="message_back">
                            <div>
                              <div class="message_grid">
                                <table>
                                  <thead>
                                    <tr>
                                      <th *ngFor="let h of moreDetailsHeaders">{{h.name}}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td *ngFor="let h of moreDetailsHeaders">{{i[h.id]}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="showdown"></div>
                          <div class="message_container">
                            <div>
                              <div class="message_grid">
                                <table>
                                  <thead>
                                    <tr>
                                      <th *ngFor="let h of moreDetailsHeaders">{{h.name}}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td *ngFor="let h of moreDetailsHeaders">{{i[h.id]}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="recomondations.length == 0" [ngStyle]="{
                  display:
                    recomondations.length == 0 ? 'table-row-group' : 'none'
                }">
                  <tr>
                    <td [attr.colspan]="ec2CostHeaders.length + 3">
                      <div style="text-align: center">No Recommendations Found</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="nextToken"
                style="padding: 15px; display: flex; justify-content: center; align-items: center;">
                <img src="../assets/img/loading_2.svg" alt="loading scans" width="10"
                  style="width: 20px; margin: 10px;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="currentPage == 'recomondations'" style="color: #f597a0; padding: 15px">
    * Disclaimer: The Cost Controls Recommendations given below are based on
    the infra usage. The recommended instances may not be the best choice for
    the applications that are running on them. Please try the recommendation on
    test/dev environment. Customers are requested to use these recommendation at
    their own risk. Swayam and its promoters should not be held responsible for
    any performance degradation either in part or in whole due to the changes
    recommended here.
    <br /><br />
    * Some recommendations are related to usage of discount plans. Please be
    careful before accepting/applying them as the changes can be irreversible
    resulting in a short/long term revenue loss
  </div>
</div>
<rds-cost-create-job-element *ngIf="createJobTrigger" (hideModal)="hideCreateJob($event)">
</rds-cost-create-job-element>