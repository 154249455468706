import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import { CustomDatePipe } from './customDate.component.js';
import { number } from 'echarts';
interface ResultData {
  [key: string]: any;
}
declare let Jhxlsx: any;
@Component({
  templateUrl: './margin.component.html',
  styleUrls: ['./margin.component.css'],
})
export class MarginDashboard implements OnInit, OnDestroy {
  discountType: any;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  transformedData: any;
  dates: string[] = [];
  currentMessage: any;
  h: any;
  result_data: ResultData;
  process_data: any;
  sorting_method: string = 'asc';
  accountId: string;

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  main_header = [
    {
      name: 'Margin (%) Range.',
      rowspan: true,
      num: 2,
      sort: { sort: true, direction: 'Asc', col: 'main' },
      id: 'key',
    },
  ];
  sub_header = [
    {
      name: 'Total Count',
      id: 'count',
      sort: { sort: true, direction: 'Asc', col: 'count' },
    },
    {
      name: 'Total Margin',
      id: 'refundTotal',
      sort: { sort: true, direction: 'Asc', col: 'refund' },
    },
  ];

  async refresh() {
    this.fetchData();
  }

  async fetchData() {
    this.notifier.loading(true);
    let data = {
      a: 'fetchMarginDash',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiUrl = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/fetchchildaccwithcosts/fetchchildaccwithcostsv2`;
    let result = await this.apiServer.postDataPromis(apiUrl, data, header);
    if (result.status == '1' || result.s == '1') {
      if (result.marginDash) {
        this.result_data = result.marginDash;
        this.process_data = Object.keys(this.result_data).map((key) => {
          return {
            key: key,
            result: this.result_data[key],
          };
        });
        this.notifier.loading(false);
      } else {
        this.notifier.alert('Info', '', 'data is missing.', 'info', 5000);
        this.notifier.loading(false);
      }
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
      this.process_data = {};
      this.notifier.loading(false);
    }
  }
  ngOnInit(): void {
    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.refresh();
      }
    });
    this.dates.push(
      moment().subtract(2, 'month').startOf('month').format('MM-YYYY')
    );

    for (let i = 1; i >= 0; i--) {
      this.dates.push(
        moment().subtract(i, 'month').startOf('month').format('MM-YYYY')
      );
    }
    this.fetchData();
  }

  process_data_fun(col: any, date: any) {
    this.process_data = [...this.process_data];
    if (this.sorting_method == 'asc') {
      this.sorting_method = 'desc';
      if (col === 'main') {
        this.process_data.sort((a: any, b: any) => {
          return a.key - b.key;
        });
      } else if (col === 'count') {
        this.process_data.sort((a: any, b: any) => {
          return (
            (a.result[date] ? a.result[date]['count'] : 0) -
            (b.result[date] ? b.result[date]['count'] : 0)
          );
        });
      } else if (col === 'refund') {
        this.process_data.sort((a: any, b: any) => {
          return (
            (a.result[date] ? a.result[date]['refundTotal'] : 0) -
            (b.result[date] ? b.result[date]['refundTotal'] : 0)
          );
        });
      }
    } else {
      this.sorting_method = 'asc';
      if (col === 'main') {
        this.process_data.sort((a: any, b: any) => {
          return b.key - a.key;
        });
      } else if (col === 'count') {
        this.process_data.sort((a: any, b: any) => {
          return (
            (b.result[date] ? b.result[date]['count'] : 0) -
            (a.result[date] ? a.result[date]['count'] : 0)
          );
        });
      } else if (col === 'refund') {
        this.process_data.sort((a: any, b: any) => {
          return (
            (b.result[date] ? b.result[date]['refundTotal'] : 0) -
            (a.result[date] ? a.result[date]['refundTotal'] : 0)
          );
        });
      }
    }
  }

  exportToCSV() {
    const updateDate: string[] = [];
    this.dates.forEach((date) => {
      updateDate.push(date);
      updateDate.push('');
    });
    this.notifier.loading(true);
    let data: any = [
      {
        sheetName: 'Margin Details',
        data: [
          [
            ...this.main_header.map((h) => {
              return { text: h.name };
            }),
            ...updateDate.map((date) => {
              return { text: date };
            }),
          ],
        ],
      },
    ];

    data[0]['data'].push([
      { text: '' },
      { text: '' },
      ...this.sub_header.map((h) => {
        return { text: h.name };
      }),
      ...this.sub_header.map((h) => {
        return { text: h.name };
      }),
      ...this.sub_header.map((h) => {
        return { text: h.name };
      }),
    ]);
    let sheetData: any = this.process_data;

    sheetData.map((item: any) => {
      let data_: any = [];
      data_.push({ text: item.key });
      this.dates.forEach((date) => {
        data_.push({
          text: item.result[date] ? item.result[date]['count'] : 0,
        });
        data_.push({
          text: item.result[date] ? item.result[date]['refundTotal'] : 0,
        });
      });
      data[0]['data'].push(data_);
    });
    var options = {
      fileName: 'Margin dashboard details',
    };
    Jhxlsx.export(data, options);
    // check
    this.notifier.loading(false);
  }
  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }
}
