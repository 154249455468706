import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

@Component({
  templateUrl: './details.iam.groups.component.html',
  selector: 'iam-groups-details',
})
export class BetaDetailsIAMGroupsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  @Input('action') action: any;
  @Input('title') title: any;
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  fetching: boolean = false;

  destroyed: boolean = false;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  funRef: any;

  headers: any = [
    { id: 'groupName', name: 'Group Name', filter: true },
    { id: 'createdDate', name: 'Created Date', filter: true },
  ];

  groups: any = [];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.refresh();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.refresh();
      }
    });
    this.funRef = {};
  }

  refresh_: boolean = false;
  refresh() {
    this.refresh_ = true;
    if (this.title == 'Groups') {
      this.loadGroups(this.action[0]);
      this.loadGroups(this.action[1]);
    } else {
      this.loadGroups(this.action);
    }
  }

  nextToken: any = null;
  async loadGroups(action: any, nextToken: any = '') {
    if (this.destroyed) {
      return true;
    }
    this.fetching = true;
    if (nextToken == '') {
      this.refresh_ = true;
      this.groups = [];
    }
    let data = {
      aId: this.accountId,
      eId: this.userId,
      a: action,
      nT: nextToken ? nextToken : undefined,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/compliance/iamageingreport`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (
      result.status == '200' ||
      result.status == '1' ||
      result.status_code == 200 ||
      result.groups
    ) {
      this.groups = [...this.groups, ...result.groups];
    } else {
      this.groups = [];
      if (result.message != 'NO RECORD FOUND') {
        this.notifier.alert('Info', '', result.message, 'info', 5000);
      }
    }

    if (result.nextToken) {
      await this.loadGroups(action, result.nextToken);
    } else {
      this.nextToken = null;
    }
    if (!this.destroyed) {
      this.fetching = false;
    }

    this.fetching = false;
    return true;
  }

  downloadReport() {}

  callFunction(name: any, param: any) {}

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
