import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as echarts from 'echarts';
import * as moment from 'moment';

declare var $: any;
declare var bootstrap: any;
import { APIService } from '../../api/api.service';
import { NotifierService } from './../../_services/notifier.service';
//import * as $ from 'jquery';
//import * as moment from 'moment';
declare let window: any;
/*declare let echarts: any;*/

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './component.html',
  styleUrls: ['./../ticket/ticket.component.css'],
})
export class knowledgebaseComponent implements OnInit {
  userId = localStorage.getItem('eId');

  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;

  showDetailKB = false;
  searchKey = 'I';

  kbList: any = [];
  kbDetails: any;

  headers: any = [
    {
      id: 'id',
      name: 'Id',
      show: true,
      sort: { sort: true, direction: 'Asc' },
      type: 'String',
      filter: true,
      style: { width: 0 },
      click: 'showData',
    },
    { id: 'status', name: 'Status' },
    { id: 'title', name: 'Title' },
  ];

  funRef: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}

  ngOnInit() {
    this.load();

    this.funRef = {
      showData: this.showData,
    };
  }

  async load() {
    this.notifier.loading(true);
    let data = {
      action: 'jira_kb_search',
      search_phrase: this.searchKey,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/support`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      this.kbList = result.results;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async showData(ref: any, id: any) {
    ref.notifier.loading(true);
    let data = {
      action: 'get_kb_content',
      id: id.id,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${ref.urlPrefix}/support`;

    let result = await ref.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.status == '1' || result.s == '1') {
      ref.kbDetails = result.page;
      ref.kbDetails.ticket_id = id.id;
      ref.showDetailKB = true;
    } else {
      ref.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    ref.notifier.loading(false);
  }

  goBack() {
    this.showDetailKB = false;
    this.kbDetails = null;
  }

  callFunction(name: any, param: any) {
    this.funRef[name](this, param);
  }
}
