<div class="card">
  <div class="card-header" style="display: flex; justify-content: space-between; align-items: center">
    <div *ngIf="!activeAccount && !analytics">
      <button class="btn btn-light" *ngIf="lid" (click)="hide()">
        < Back</button>
          Daily Cost Variance
          <span *ngIf="lid"> - {{lid}}</span>
    </div>
    <div *ngIf="activeAccount">
      <button class="btn btn-light" (click)="goBack()">&lt; Back</button>
      <span>Bills - {{ activeAccount.lid }}
        {{
        activeAccount.accname ? "(" + activeAccount.accname + ")" : ""
        }}</span>
    </div>
    <!-- <button style="float: right;" class="btn btn_blue" (click)="downloadAl()">Download All</button> -->

  </div>
  <div class="card-body">
    <div>
      <div style="display: flex; justify-content: flex-end; gap: 10px; align-items: center; gap: 10px;">
        <div>
          <span style="font-weight: bold">Master Accounts</span>
          <span>
            <select style="width: 300px" class="form-control" id="masterAccountSelect" data-live-search="true"
              (change)="fetchMasterAccounts()">
              <option [value]="account" *ngFor="let account of masterAccounts">
                {{ account }}
              </option>
            </select>
          </span>
        </div>
        <div>
          <span style="font-weight: bold">Range</span>
          <span>
            <select class="form-select" [(ngModel)]="range" (change)="fetchMasterAccounts()">
              <option value="last_7_days">Last 7 days</option>
              <option value="this_week">This Week</option>
              <option value="last_week">Last Week</option>
              <option value="this_month">This Month</option>
              <option value="last_month">Last Month</option>
              <option value="last_3_month">Last 3 Months</option>
              <option value="last_6_month">Last 6 Months</option>
            </select>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card" [ngStyle]="{ display: !activeAccount && !analytics ? 'block' : 'none' }">
  <div class="card-body">
    <div style="text-align: right;" *ngIf="range == 'last_6_month' && loadHeaders().length < 6">
      * Baseline month to view Billing Variance Report is April 2022
    </div>
    <div style="display: flex; align-items: center; justify-content: space-between; gap: 10px; padding: 10px 0px;">
      <div>
        Estimated Usage Details: <span style="font-weight: bold;">{{loadRangeString()}}</span>
      </div>
      <div style="display: flex; justify-content: flex-end; align-items: center; gap: 10px;">
        <div>
          Total: <span style="font-weight: bold;" [innerHTML]="loadAllColumnTotalParsed()"></span>
        </div>
        <div
          *ngIf="(range == 'this_month' || range == 'last_month' || customRange == 'month') && !activeService && urlPrefix == 'admin'">
          Total: {{monthTotal(fetchMonthYear()) + ' (Monthly)'}}
          <ng-container *ngIf="!loadingObj[fetchMonthYear()]">
            <span class="green_fancy"
              *ngIf="checkForVariation( monthTotal(fetchMonthYear()), loadAllColumnTotalParsed(true))['check']">
              <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>
            </span>
            <span class="red_fancy"
              [attr.title]="'Variation: ' +checkForVariation( monthTotal(fetchMonthYear()), loadAllColumnTotalParsed(true))['variation']"
              *ngIf="!checkForVariation( monthTotal(fetchMonthYear()), loadAllColumnTotalParsed(true))['check']">
              <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>
            </span>
          </ng-container>
          <span *ngIf="loadingObj[getValidMonth(fetchMonthYear())]">
            <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
          </span>
        </div>
        <div *ngIf="isExportable">
          <button class="btn btn-success-soft" (click)="exportData()">Export</button>
        </div>
      </div>
    </div>
    <div *ngIf="breadCrum.length > 1" class="breadcrums">
      <div *ngFor="let value of breadCrum; let i = index" class="breadCrum_item">
        <span (click)="i + 1 != breadCrum.length && navigate(value)"
          [ngStyle]="{cursor: i + 1 != breadCrum.length && 'pointer', color: i + 1 != breadCrum.length && 'blue'}">
          {{value.value}}
        </span>
        <span style="margin: 0px 8px;" *ngIf="i + 1 != breadCrum.length">
          >
        </span>
      </div>
    </div>
    <div style="width: 100%; overflow-x: auto;">
      <table class="table table-docs table-hover" id="daily_bill_table">
        <thead>
          <tr>
            <td style="font-weight: bold;">S. N.</td>
            <td style="font-weight: bold;">Service Name</td>
            <td style="font-weight: bold;  text-align: right;">Total</td>
            <td style="font-weight: bold;  text-align: right;"
              *ngIf="(range == 'this_month' || range == 'last_month' || customRange == 'month') && !activeService && urlPrefix == 'admin'">
              Total (Monthly)
              <span *ngIf="loadingObj[fetchMonthYear()]">
                <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
              </span>
            </td>
            <ng-container *ngFor="let h of loadHeaders(); let h_index = index">
              <td style="text-align: right;" (mouseover)="changeColor(h_index)" (mouseout)="resetColor(h_index)"
                (click)="fetchRangeData(h)">
                <div style="white-space: nowrap;">
                  {{ parsedHeader(h) }} <span *ngIf="parsedHeader_days(h)">({{parsedHeader_days(h)}} Days)</span>
                </div>
                <div style="font-weight: bold; white-space: nowrap;">
                  <span style="white-space: nowrap;" [innerHTML]="loadColumnTotalParsed(h)">
                  </span>
                  <span class="compare_indicator"
                    [ngStyle]="{backgroundColor: compareValue(loadColumnTotal(h), loadColumnTotal(loadHeaders()[h_index - 1]), h_index + 1 == loadHeaders().length)['type'], color: compareValue(loadColumnTotal(h), loadColumnTotal(loadHeaders()[h_index - 1]), h_index + 1 == loadHeaders().length)['color']}"
                    *ngIf="h_index > 0 && compareValue(loadColumnTotal(h), loadColumnTotal(loadHeaders()[h_index - 1]), h_index + 1 == loadHeaders().length)['indicator'] != 0">
                    <span style="color: inherit;">
                      {{compareValue(loadColumnTotal(h), loadColumnTotal(loadHeaders()[h_index - 1]), h_index + 1 ==
                      loadHeaders().length)['change']}}%
                    </span>
                    <span>
                      <i class="fa fa-caret-up" style="color: inherit;"
                        *ngIf="compareValue(loadColumnTotal(h), loadColumnTotal(loadHeaders()[h_index - 1]), h_index + 1 == loadHeaders().length)['indicator'] == -1"
                        aria-hidden="true"></i>
                      <i class="fa fa-caret-down" style="color: inherit;"
                        *ngIf="compareValue(loadColumnTotal(h), loadColumnTotal(loadHeaders()[h_index - 1]), h_index + 1 == loadHeaders().length)['indicator'] == 1"
                        aria-hidden="true"></i>
                    </span>
                  </span>
                </div>
              </td>
              <td style="text-align: right;"
                *ngIf="(range == 'last_3_month' || range == 'last_6_month') && customRange == null && !activeService && urlPrefix == 'admin'">
                <div style="white-space: nowrap;">
                  {{ parsedHeader(h) }} (Monthly)
                  <div style="font-weight: bold">
                    <ng-container *ngIf="!loadingObj[getValidMonth(h)]">
                      ${{monthTotal(h)}}
                      <span class="green_fancy" *ngIf="checkForVariation( monthTotal(h), loadColumnTotal(h))['check']">
                        <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>

                      </span>
                      <span class="red_fancy"
                        [attr.title]="'Variation: ' +checkForVariation( monthTotal(h), loadColumnTotal(h))['variation']"
                        *ngIf="!checkForVariation( monthTotal(h), loadColumnTotal(h))['check']">
                        <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>

                      </span>

                    </ng-container>
                    <span *ngIf="loadingObj[getValidMonth(h)]">
                      <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
                    </span>
                  </div>
                </div>
              </td>
            </ng-container>

          </tr>
        </thead>

        <tbody [ngStyle]="{ display: appended.length > 0 ? 'table-row-group' : 'none' }">
          <ng-container *ngFor="let service of appended; let j = index">
            <tr>
              <td>{{j + 1}}</td>
              <td *ngIf="checkIfClickable(service)" (click)="expandService(service)">
                <span class="cliakable">{{service}}</span>
              </td>
              <td *ngIf="!checkIfClickable(service)"
                style="max-width: 600px; overflow: hidden; white-space: no-wrap; text-overflow: ellipsis;">
                <span style="white-space: nowrap; text-overflow: ellipsis;">
                  {{service}}
                </span>
              </td>
              <td [innerHTML]="fetchServiceTotal(service)" style="text-align: right;"></td>
              <td style="text-align: right;"
                *ngIf="(range == 'this_month' || range == 'last_month' || customRange == 'month') && !activeService && urlPrefix == 'admin'">
                <div style="white-space: nowrap;">
                  <span [innerHTML]="'$'+getMonthlyValue('MONTHLY', service).toFixed(2) + ' '"></span>
                  <span class="green_fancy"
                    *ngIf="checkForVariation( getMonthlyValue('MONTHLY', service), fetchServiceTotal(service, true) ? fetchServiceTotal(service, true)['finalTotalBill']: '0.00')['check']">
                    <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>

                  </span>
                  <span class="red_fancy"
                    [attr.title]="'Variation: ' +checkForVariation( getMonthlyValue('MONTHLY', service), fetchServiceTotal(service, true) ? fetchServiceTotal(service, true)['finalTotalBill']: '0.00')['variation']"
                    *ngIf="!checkForVariation( getMonthlyValue('MONTHLY', service), fetchServiceTotal(service, true) ? fetchServiceTotal(service, true)['finalTotalBill']: '0.00')['check']">
                    <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>

                  </span>
                </div>
              </td>
              <ng-container *ngFor="let h of loadHeaders(); let h_index = index">
                <td class="value_container" (mouseover)="changeColor(h_index)" (click)="fetchRangeData(h)"
                  (mouseout)="resetColor(h_index)" style="text-align: right;">
                  <span [innerHTML]="loadPrasedValue(loadValue(service, h) || 0)">
                  </span>
                  <span class="compare_indicator" [ngStyle]="{backgroundColor: compareValue(loadValue(service, h)['finalTotalBill'], loadValue(service, loadHeaders()[h_index -
                                  1])['finalTotalBill'], h_index + 1 == loadHeaders().length)['type'], color: compareValue(loadValue(service, h)['finalTotalBill'], loadValue(service, loadHeaders()[h_index -
                                  1])['finalTotalBill'], h_index + 1 == loadHeaders().length)['color']}" *ngIf="loadValue(service, h) && loadValue(service, h)['finalTotalBill'] && loadValue(service, loadHeaders()[h_index -1]) && loadValue(service, loadHeaders()[h_index -1])['finalTotalBill'] && compareValue(loadValue(service, h)['finalTotalBill'], loadValue(service, loadHeaders()[h_index -
                                  1])['finalTotalBill'], h_index + 1 == loadHeaders().length)['indicator'] != 0">
                    <span>
                      {{compareValue(loadValue(service, h)['finalTotalBill'], loadValue(service, loadHeaders()[h_index -
                      1])['finalTotalBill'], h_index + 1 == loadHeaders().length)['change']}}%
                    </span>
                    <span>
                      <i class="fa fa-caret-up" *ngIf="compareValue(loadValue(service, h)['finalTotalBill'], loadValue(service, loadHeaders()[h_index -
                                  1])['finalTotalBill'], h_index + 1 == loadHeaders().length)['indicator'] == -1"
                        aria-hidden="true"></i>
                      <i class="fa fa-caret-down" *ngIf="compareValue(loadValue(service, h)['finalTotalBill'], loadValue(service, loadHeaders()[h_index -
                                  1])['finalTotalBill'], h_index + 1 == loadHeaders().length)['indicator'] == 1"
                        aria-hidden="true"></i>
                    </span>
                  </span>
                </td>
                <td style="text-align: right;"
                  *ngIf="(range == 'last_3_month' || range == 'last_6_month') && customRange == null && !activeService && urlPrefix == 'admin'">
                  <div style="white-space: nowrap;">
                    ${{getMonthlyValue(h, service).toFixed(2)}}
                    <span class="green_fancy"
                      *ngIf="checkForVariation( getMonthlyValue(h, service), loadValue(service, h) ? loadValue(service, h)['finalTotalBill']: '0.00')['check']">
                      <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>

                    </span>
                    <span class="red_fancy"
                      [attr.title]="'Variation: ' +checkForVariation( getMonthlyValue(h, service), loadValue(service, h) ? loadValue(service, h)['finalTotalBill']: '0.00')['variation']"
                      *ngIf="!checkForVariation( getMonthlyValue(h, service), loadValue(service, h) ? loadValue(service, h)['finalTotalBill']: '0.00')['check']">
                      <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>

                    </span>
                  </div>
                </td>
              </ng-container>
            </tr>
            <ng-container *ngIf="checkIfClickable(service) && expanded[service]">
              <ng-container *ngFor="let sub_servive of subServices(service)">
                <tr>
                  <td></td>
                  <td><span class="cliakable" (click)="loadMore(sub_servive)"
                      style="white-space: nowrap; text-overflow: ellipsis;">{{sub_servive}}</span></td>
                  <td [innerHTML]="fetchSubServiceTotal(service, sub_servive)" style="text-align: right;"></td>
                  <td style="text-align: right;"
                    *ngIf="(range == 'this_month' || range == 'last_month' || customRange == 'month') && !activeService && urlPrefix == 'admin'">
                    <div style="white-space: nowrap;">
                      <span [innerHTML]="'$'+getMonthlyValue('MONTHLY', service, sub_servive).toFixed(2) + ' '"></span>
                      <span class="green_fancy"
                        *ngIf="checkForVariation( getMonthlyValue('MONTHLY', service, sub_servive), fetchSubServiceTotal(service, sub_servive, true) ? fetchSubServiceTotal(service, sub_servive, true)['finalTotalBill']: '0.00')['check']">
                        <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>

                      </span>
                      <span class="red_fancy"
                        [attr.title]="'Variation: ' +checkForVariation( getMonthlyValue('MONTHLY', service, sub_servive), fetchSubServiceTotal(service, sub_servive, true) ? fetchSubServiceTotal(service, sub_servive, true)['finalTotalBill']: '0.00')['variation']"
                        *ngIf="!checkForVariation( getMonthlyValue('MONTHLY', service, sub_servive), fetchSubServiceTotal(service, sub_servive, true) ? fetchSubServiceTotal(service, sub_servive, true)['finalTotalBill']: '0.00')['check']">
                        <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>

                      </span>
                    </div>
                  </td>
                  <ng-container *ngFor="let h of loadHeaders(); let h_index = index">
                    <td class="value_container" (mouseover)="changeColor(h_index)" (click)="fetchRangeData(h)"
                      (mouseout)="resetColor(h_index)" style="text-align: right;">
                      <span style="white-space: nowrap;"
                        [innerHTML]="loadPrasedValue(loadNestedValue(service, h, sub_servive) || 0)">
                      </span>
                      <span class="compare_indicator"
                        [ngStyle]="{backgroundColor: compareValue(loadNestedValue(service, h, sub_servive)['finalTotalBill'], loadNestedValue(service, loadHeaders()[h_index -
                                              1], sub_servive)['finalTotalBill'], h_index + 1 == loadHeaders().length)['type'], color: compareValue(loadNestedValue(service, h, sub_servive)['finalTotalBill'], loadNestedValue(service, loadHeaders()[h_index -
                                              1], sub_servive)['finalTotalBill'], h_index + 1 == loadHeaders().length)['color']}"
                        *ngIf="loadNestedValue(service, h, sub_servive) && loadNestedValue(service, h, sub_servive)['finalTotalBill'] && loadNestedValue(service, loadHeaders()[h_index -1], sub_servive) && loadNestedValue(service, loadHeaders()[h_index -1], sub_servive)['finalTotalBill'] && compareValue(loadNestedValue(service, h, sub_servive)['finalTotalBill'], loadNestedValue(service, loadHeaders()[h_index -
                                              1], sub_servive)['finalTotalBill'], h_index + 1 == loadHeaders().length)['indicator'] != 0">
                        <span>
                          {{compareValue(loadNestedValue(service, h, sub_servive)['finalTotalBill'],
                          loadNestedValue(service, loadHeaders()[h_index -
                          1], sub_servive)['finalTotalBill'], h_index + 1 == loadHeaders().length)['change']}}%
                        </span>
                        <span>
                          <i class="fa fa-caret-up" style="color: inherit;"
                            *ngIf="compareValue(loadNestedValue(service, h, sub_servive)['finalTotalBill'], loadNestedValue(service, loadHeaders()[h_index -
                                              1], sub_servive)['finalTotalBill'], h_index + 1 == loadHeaders().length)['indicator'] == -1" aria-hidden="true"></i>
                          <i class="fa fa-caret-down" style="color: inherit;"
                            *ngIf="compareValue(loadNestedValue(service, h, sub_servive)['finalTotalBill'], loadNestedValue(service, loadHeaders()[h_index -
                                              1], sub_servive)['finalTotalBill'], h_index + 1 == loadHeaders().length)['indicator'] == 1" aria-hidden="true"></i>
                        </span>
                      </span>
                    </td>
                    <td style="text-align: right;"
                      *ngIf="(range == 'last_3_month' || range == 'last_6_month') && customRange == null && !activeService && urlPrefix == 'admin'">
                      <div style="white-space: nowrap;">
                        ${{getMonthlyValue(h, service, sub_servive).toFixed(2)}}
                        <span class="green_fancy"
                          *ngIf="checkForVariation( getMonthlyValue(h, service, sub_servive), loadNestedValue(service, h, sub_servive) ? loadNestedValue(service, h, sub_servive)['finalTotalBill']: '0.00')['check']">
                          <i class="fa fa-check-circle green_fancy" aria-hidden="true"></i>

                        </span>
                        <span class="red_fancy"
                          [attr.title]="'Variation: ' +checkForVariation( getMonthlyValue(h, service), loadNestedValue(service, h, sub_servive) ? loadNestedValue(service, h, sub_servive)['finalTotalBill']: '0.00')['variation']"
                          *ngIf="!checkForVariation( getMonthlyValue(h, service, sub_servive), loadNestedValue(service, h, sub_servive) ? loadNestedValue(service, h, sub_servive)['finalTotalBill']: '0.00')['check']">
                          <i class="fa fa-times-circle red_fancy" aria-hidden="true"></i>

                        </span>
                      </div>
                    </td>
                  </ng-container>

                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
        </tbody>
        <tbody [ngStyle]="{ display: appended.length == 0 ? 'table-row-group' : 'none' }">
          <tr>
            <td [attr.colspan]="loadHeaders().length + 3" style="text-align: center;">
              No Bills Found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>