<ng-container>
  <div class="card" *ngIf="!readOnly">
    <h5 class="card-header">
      <button class="btn btn-light" (click)="hide(false)">
        < Back</button>
          New CR - Add New Instance
    </h5>
  </div>
  <div class="card">
    <h5 class="card-header">
      CR Details
    </h5>
    <div class="card-body details_container">
      <label>Name</label>
      <input class="form-control" type="text" [attr.disabled]='true' [ngModel]="instanceObj['metadata']['name']">
      <label>Company</label>
      <input class="form-control" type="text" [attr.disabled]='true' [ngModel]="instanceObj['metadata']['company']">
      <label>Email</label>
      <div class="additional_contacts" *ngIf="!readOnly">
        <div class="additional_contact" style="width: auto" *ngFor="
                          let contact of instanceObj['metadata']['emails'];
                          let i = index;
                          trackBy: trackByFn
                        ">
          <input type="text" name="contact_value" class="form-control"
            *ngIf="instanceObj['metadata']['emails'].length - 3 < i; i" (keyup)="checkForLast(contact, i)"
            [(ngModel)]="instanceObj['metadata']['emails'][i]" />
          <input type="text" name="contact_value" class="form-control"
            *ngIf="instanceObj['metadata']['emails'].length - 3 >= i"
            [(ngModel)]="instanceObj['metadata']['emails'][i]" />
          <div>
            <i class="fa fa-plus-circle pointer" aria-hidden="true" title="add"
              *ngIf="instanceObj['metadata']['emails'].length == i + 1" (click)="addAdditionalContact()"></i>
          </div>
          <div>
            <i class="fa fa-times-circle pointer" aria-hidden="true" title="remove"
              *ngIf="instanceObj['metadata']['emails'].length > 1" (click)="deleteSelectedAdditionalContact(i)"></i>
          </div>
        </div>
      </div>
      <div *ngIf="readOnly">
        {{instanceObj['metadata']['emails'].join(', ')}}
      </div>
      <label>Account ID</label>
      <input class="form-control" type="text" [attr.disabled]='true' [ngModel]="instanceObj['metadata']['account_id']">
      <label>Date</label>
      <input class="form-control" type="text" [attr.disabled]="true" [ngModel]="instanceObj['metadata']['date']">
      <label>Request Priority</label>
      <select class="form-select" [attr.disabled]="readOnly ? true: null">
        <option value="low" selected>Low</option>
        <option value="medium">Medium</option>
        <option value="high">High</option>
      </select>
      <label>CR Supporting Document</label>
      <input type="file" class="form-control" *ngIf="!readOnly">
    </div>
  </div>
  <div class="card">
    <h5 class="card-header">
      Server Details
    </h5>
    <div class="card-body">
      <div class="grid_form">
        <table class="table table-docs">
          <thead>
            <tr>
              <th>Server Type</th>
              <th>DB Platform</th>
              <th>Component</th>
              <th>App Name</th>
              <th>No. of Instances</th>
              <th>CPU</th>
              <th>RAM(GiB)</th>
              <th>OS</th>
              <th>Storage Type</th>
              <th>Storage Qty</th>
              <th>Storage Unit</th>
              <th *ngIf="!readOnly">Action</th>
            </tr>
          </thead>
          <tbody *ngIf="instanceObj['components']['compute']['instances'].length > 0">
            <tr *ngFor="let instance of instanceObj['components']['compute']['instances']; let i = index">
              <td>
                <select [(ngModel)]="instance['serverType']" class="form-select" *ngIf="!readOnly">
                  <option value="{{ type['id'] }}" *ngFor="let type of serverTypes">{{ type['name'] }}</option>
                </select>
                <span *ngIf="readOnly">
                  {{ getValue(serverTypes, instance['serverType']) }}
                </span>
              </td>
              <td>
                <select [(ngModel)]="instance['dbPlatform']" placeholder="Select DB Platform"
                  [attr.disabled]="instance['serverType'] == 'appserver' ? true : null" class="form-select"
                  *ngIf="!readOnly">
                  <option value="{{ db['id'] }}" *ngFor="let db of dbTypes">{{db['name']}}</option>
                </select>
                <span *ngIf="readOnly">
                  {{getValue(dbTypes, instance['dbPlatform'])}}
                </span>
              </td>
              <td>
                <select [(ngModel)]="instance['component']" class="form-select" *ngIf="!readOnly"
                  placeholder="Select Component">
                  <option value="{{component['id']}}" *ngFor="let component of components">{{ component['name'] }}
                  </option>
                </select>
                <span *ngIf="readOnly">
                  {{getValue(components, instance['component'])}}
                </span>
              </td>
              <td>
                <input type="text" [(ngModel)]="instance['appName']" *ngIf="!readOnly"
                  [attr.placeholder]="'Prod Server - ' + (i + 1)" class="form-control">
                <span *ngIf="readOnly">{{instance['appName']}}</span>
              </td>
              <td>
                <input type="number" *ngIf="!readOnly" [(ngModel)]="instance['noOfInstances']" placeholder="Eg. 1"
                  class="form-control">
                <span *ngIf="readOnly">{{ instance['noOfInstances'] }}</span>
              </td>
              <td>
                <input type="number" *ngIf="!readOnly" [(ngModel)]="instance['cpu']" class="form-control"
                  placeholder="Eg. 1">
                <span *ngIf="readOnly">{{ instance['cpu'] }}</span>
              </td>
              <td>
                <input type="number" [(ngModel)]="instance['ram']" *ngIf="!readOnly" class="form-control"
                  placeholder="Eg. 2">
                <span *ngIf="readOnly">{{ instance['ram'] }}</span>
              </td>
              <td>
                <select [(ngModel)]="instance['os']" *ngIf="!readOnly" class="form-select"
                  [attr.disabled]="instance['component'] == 'rds' ? 'true' : null">
                  <option value="{{os}}" *ngFor="let os of platformTypes;">{{os}}</option>
                </select>
                <span *ngIf="readOnly">{{instance['os'] }}</span>
              </td>
              <td>
                <select [(ngModel)]="instance['storageType']" class="form-select" *ngIf="!readOnly"
                  [attr.disabled]="instance['component'] == 'rds' ? 'true' : null">
                  <option value="{{ storagetype['id'] }}" *ngFor="let storagetype of storageTypes">
                    {{storagetype['name']}}</option>
                </select>
                <span *ngIf="readOnly">{{ getValue(storageTypes, instance['storageType']) }}</span>
              </td>
              <td>
                <input type="number" [(ngModel)]="instance['storageQty']" *ngIf="!readOnly" placeholder="Eg. 100"
                  class="form-control">
                <span *ngIf="readOnly">{{ instance['storageQty'] }}</span>
              </td>
              <td>
                <select [(ngModel)]="instance['storageUnit']" *ngIf="!readOnly" class="form-select">
                  <option value="{{ unit['id'] }}" *ngFor="let unit of storageUnits">{{ unit['name'] }}</option>
                </select>
                <span *ngIf="readOnly">
                  {{ getValue(storageUnits, instance['storageUnit']) }}
                </span>
              </td>
              <td *ngIf="!readOnly">
                <button class="btn btn-danger-soft button" [attr.disabled]="readOnly ? true: null"
                  (click)="removeInstance(i)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="instanceObj['components']['compute']['instances'].length == 0">
            <tr>
              <td [attr.colspan]="readOnly ? 11 : 12" style="text-align: center;">
                No Servers
              </td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-primary-soft" (click)="addInstance()" *ngIf="!readOnly">
          <i class="fa fa-plus"></i>
          Add Server
        </button>
      </div>
    </div>
  </div>
  <div class="card">
    <h5 class="card-header">
      Storage Details
    </h5>
    <div class="card-body">
      <table class="table table-docs">
        <thead>
          <tr>
            <th>Storage Name</th>
            <th>Storage Type</th>
            <th>Storage Qty</th>
            <th>Storage Unit</th>
            <th *ngIf="!readOnly">Action</th>
          </tr>
        </thead>
        <tbody *ngIf="instanceObj['components']['storage']['volumes'].length > 0">
          <tr *ngFor="let storage of instanceObj['components']['storage']['volumes']; let i = index">
            <td><input type="text" [attr.placeholder]="'Volume - ' + (i + 1)" [(ngModel)]="storage['name']"
                class="form-control" *ngIf="!readOnly">
              <span *ngIf="readOnly">{{storage['name']}}</span>
            </td>
            <td>
              <select [(ngModel)]="storage['type']" *ngIf="!readOnly" class="form-select">
                <option value="{{ unit['id'] }}" *ngFor="let unit of storageTypes">{{ unit['name'] }}</option>
              </select>
              <span *ngIf="readOnly">
                {{ getValue(storageTypes, storage['type']) }}
              </span>
            </td>
            <td>
              <input type="number" *ngIf="!readOnly" [(ngModel)]="storage['size']" class="form-control"
                placeholder="Eg. 200">
              <span *ngIf="readOnly">{{ storage['size'] }}</span>
            </td>
            <td>
              <select [(ngModel)]="storage['unit']" *ngIf="!readOnly" class="form-select">
                <option value="{{ unit['id'] }}" *ngFor="let unit of storageUnits">{{ unit['name'] }}</option>
              </select>
              <span *ngIf="readOnly">
                {{ getValue(storageUnits, storage['unit']) }}
              </span>
            </td>
            <td *ngIf="!readOnly">
              <button class="btn btn-danger-soft button" [attr.disabled]="readOnly ? true: null"
                (click)="removeStorage(i)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="instanceObj['components']['storage']['volumes'] == 0">
          <tr>
            <td [attr.colspan]="readOnly ? 4 : 5" style="text-align: center;">
              No Volumes
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-primary-soft" (click)="addStorage()" *ngIf="!readOnly">
        <i class="fa fa-plus"></i>
        Add Storage
      </button>
    </div>
  </div>
  <div class="card">
    <h5 class="card-header">More Details</h5>
    <div class="card-body details_container">
      <label style="margin: 2px;">Note</label>
      <textarea style="width: 100%; resize: none; height: 100px;" *ngIf="!readOnly" class="form-control"
        [(ngModel)]="instanceObj['metadata']['more_details']"></textarea>
      <span *ngIf="readOnly">{{ instanceObj['metadata']['more_details'] }}</span>
    </div>
  </div>

  <div class="card" *ngIf="!readOnly">
    <div style="text-align: center;" class="card-body">
      <button class="btn btn-primary-soft" (click)="saveAndSendOTP()" [attr.disabled]="loadingCR ? true : null"
        [ngStyle]="{ 'padding-left': loadingCR ? '30px' : undefined }" style="
        position: relative;
        ">
        <span *ngIf="loadingCR" style="
                      position: absolute;
                      top: 50%;
                      left: 5px;
                      transform: translateY(-50%);
                    ">
          <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 16px" />
        </span>
        <span style="color: inherit">Save</span>
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="request_id && !readOnly">
  <minfyui-new-cr-otp-validation [accountId]="instanceObj['metadata']['account_id']" [request_id]="request_id"
    [region]="instanceObj['metadata']['region_id']" [otpValidationObj]="instanceObj"
    (hideModal)="hideModalCaller($event)"></minfyui-new-cr-otp-validation>
</ng-container>