<div class="card" *ngIf="!selectedCR">
  <div class="card-header">
    <h5>Change Requests</h5>
  </div>
  <div class="card-body">
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th>
            CRID
          </th>
          <th>
            Resource ID
          </th>
          <th>
            Initiator
          </th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cr of crs; let i = index" (click)="selectedCR = cr">
          <td [ngClass]="{highlight: crExists(cr['cr_id'])}">{{ i + 1}}</td>
          <td>{{cr['cr_id']}}</td>
          <td>{{cr['resource_id']}}</td>
          <td>{{cr['user_name']}}</td>
          <td>
            <changerequest-status [status]="cr['status']"></changerequest-status>
          </td>
        </tr>
        <tr [ngStyle]="{display: loading ? 'table-row' : 'none'}">
          <td [attr.colspan]="5" style="text-align: center;">
            <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
          </td>
        </tr>
        <tr [ngStyle]="{display: crs.length == 0 && !loading ? 'table-row' : 'none'}">
          <td [attr.colspan]="5" style="text-align: center;">
            No Change Requests Found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-container *ngIf="selectedCR">
  <app-cr-details [account_id]="accountId" [region_id]="regionId" [cr_detail]="selectedCR"
    (hideModel)="selectedCR = null"></app-cr-details>
</ng-container>