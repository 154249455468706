import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

declare let window: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './view.user.component.html',
})
export class ViewUserSettingsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  userList: any;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;

  userHeaders: any = [
    {
      id: 'clientName',
      name: 'Client Name',
    },
    { id: 'emaiId', name: 'Email ID' },
    { id: 'clientCmpName', name: 'Company Name' },
    {
      id: 'mobileNumber',
      name: 'Phone Number',
    },
    {
      id: 'accountGroup',
      name: 'AWS Account Groups',
    },
  ];

  adminUsersheader: any = [
    {
      id: 'userName',
      name: 'User Name',
    },
    {
      id: 'userId',
      name: 'User ID',
    },
    {
      id: 'cmpName',
      name: 'Company Name',
    },
    {
      id: 'phNumber',
      name: 'Phone Number',
    },
    {
      id: 'accountGroup',
      name: 'Account Groups',
    },
  ];
  updateUser: any;
  userAction: string;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async load() {
    this.notifier.loading(true);
    let data: any = {
      clientId: this.userId,
      action: 'fetchAllClientUsers',
      ut: this.urlPrefix,
    };

    if (this.urlPrefix == 'admin') {
      data = { userId: this.userId };
    }

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/${
      this.urlPrefix == 'client' ? 'invitev2' : 'list'
    }`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.userList = result.cl || result.details;
      this.userList = this.userList.map((user: any) => {
        if (this.urlPrefix == 'admin') {
          user['accountGroup'] = user.accountGroup.join(', ');
        } else {
          user['accountGroup'] = user.accountDetailsList
            .map((account: any) => {
              return account.accountId;
            })
            .join(', ');
        }
        return user;
      });
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  updateUserAction(group: any) {
    this.userAction = 'update';
    this.updateUser = group;
  }

  async hideUpdateUser(event: any) {
    if (event) {
      this.updateUser = event;
      await this.load();
    }
    this.updateUser = null;
  }

  createUser() {
    this.userAction = 'add';
    if (this.urlPrefix == 'client') {
      this.updateUser = {
        clientId: window.uuidv4(),
        clientName: '',
        clientCmpName: localStorage.getItem('com'),
        emaiId: '',
        mobileNumber: '',
        accountDetailsList: [],
        policyStatus: false,
        otpReq: true,
      };
    } else {
      this.updateUser = {
        userName: '',
        userId: '',
        phNumber: '',
        cmpName: localStorage.getItem('com'),
        userType: 'user',
        accountGroup: [],
      };
    }
  }

  callFunction(name: any, param: any) {}
}
