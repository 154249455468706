import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './profile.settings.component.html',
})
export class ProfileSettingsComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  userDetails: any = {};
  changePassword: any = {
    oldPass: '',
    newPass: '',
    newConfPass: '',
  };

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  async changePasswordAction() {
    if (this.changePassword.newPass != this.changePassword.newConfPass) {
      this.notifier.alert('Info', '', 'Passwords do not Match', 'info', 5000);
      return;
    }
    this.notifier.loading(true);
    let data = {
      eId: this.userId,
      oldPswd: this.changePassword.oldPass,
      newPswd: this.changePassword.newPass,
      ut: this.urlPrefix,
      at: localStorage.getItem('t'),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/changepassword`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.s == '1' || result.status == '1') {
      this.changePassword = {
        oldPass: '',
        newPass: '',
        newConfPass: '',
      };
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.load();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async load() {
    this.notifier.loading(true);
    let data = { eId: this.userId, a: 'fetch' };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/update`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.s == '1' || result.status == '1') {
      this.userDetails = result;
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async updateUser() {
    this.notifier.loading(true);
    let data = {
      eId: this.userId,
      un: this.userDetails.un,
      mn: this.userDetails.ph,
      cn: this.userDetails.com,
      des: this.userDetails.des,
      a: 'update',
      ut: this.urlPrefix,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/update`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.s == '1' || result.status == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.load();
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {}
}
