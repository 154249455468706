<div class="card">
  <div class="card-header">Patch Report</div>
  <div class="card-body">
    <div *ngIf="!loading && presigned_url" class="alert alert-success floating_reports_download_container" role="alert">
      <span>
        <div style="color: inherit;">
          Patch Report is ready to download.
        </div>
        <div style="color: inherit;">
          Last scan time <strong style="color: inherit;">{{scan_time}}</strong>
        </div>
      </span>
      <span>
        <button class="btn btn-light" style="margin-right: 10px;" (click)="load()">Refresh</button>
        <button class="btn btn-success" (click)="downloadReport()">Download</button>
      </span>
    </div>
    <div *ngIf="loading" class="alert alert-info floating_reports_download_container" role="alert">
      <span>
        Checking for Patch Report
      </span>
      <span>
        <img src="../assets/img/loading_2.svg" alt="loading scans" style="width: 18px; margin: 10px" />
      </span>
    </div>
    <div *ngIf="!loading && !presigned_url" class="alert alert-info floating_reports_download_container" role="alert">
      <span *ngIf="message">
        {{message}}
      </span>
      <span *ngIf="!message">
        Patch Report not Found
      </span>
      <span>
        <button class="btn btn-light" style="margin-right: 10px;" (click)="load()">Refresh</button>
      </span>
    </div>
  </div>
</div>