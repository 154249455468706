import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';

declare let $: any;
declare let window: any;

@Component({
  selector: 'rds-billing',
  templateUrl: 'rds.billing.component.html',
  styleUrls: ['./../../billing.component.css'],
})
export class RDSBillingComponent implements OnInit, OnDestroy, OnChanges {
  @Input('activeAccount') activeAccount: any;
  @Input('monthYear') monthYear: any;
  @Input('diff_days') diff_days: any;

  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  loadMore: any;
  showConditionalElement = false;

  rdsData: any = [];
  rdsHeaders: any = [
    {
      id: 'resourceId',
      name: 'Resource Name',
      export: true,
    },
    {
      id: 'databaseEngine',
      name: 'Database Engine',
      export: true,
    },
    {
      id: 'region',
      name: 'Region',
      export: true,
    },
    {
      id: 'availabilityType',
      name: 'Availability Type',
      export: true,
      alter: (value: any, param: any) => {
        return value[param] && value[param] != '' ? value[param] : '-';
      },
    },
    {
      id: 'riApplied',
      name: 'RI Applied',
      export: true,
    },
    {
      id: 'riType',
      name: 'RI Type',
      export: true,
    },
    {
      id: 'riHourlyCost',
      name: 'RI Hourly Cost',
      export: true,
      alter: (value: any, param: any) => {
        return !isNaN(value[param]) &&
          value['riApplied'] &&
          value['riApplied'].toLowerCase() == 'yes'
          ? Number(value[param]).toFixed(2)
          : '-';
      },
    },
    {
      id: 'totalHours',
      name: 'Usage',
      export: true,
      alter: (value: any, param: any) => {
        return isNaN(value[param]) ? '-' : Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'totalCost',
      name: 'Total Cost',
      export: false,
      alter: (value: any, param: string) => {
        return isNaN(value[param])
          ? '-'
          : value['riApplied'] && value['riApplied'].toLowerCase() == 'yes'
          ? value['riType'] != 'unUsedCustomerRI'
            ? `<span class='line_through'>${Number(value[param]).toFixed(
                2
              )}</span> <span>${Number(value['riTotalCost']).toFixed(2)}</span>`
            : Number(value['riTotalCost']).toFixed(2)
          : Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'totalCost',
      name: 'Total Cost',
      export: true,
      hide: true,
      alter: (value: any, param: string) => {
        return isNaN(value[param])
          ? '-'
          : value['riApplied'] && value['riApplied'].toLowerCase() == 'yes'
          ? value['riType'] != 'unUsedCustomerRI'
            ? `<span>${Number(value[param]).toFixed(2)}</span>`
            : Number(value['riTotalCost']).toFixed(2)
          : Number(value[param]).toFixed(2);
      },
    },
    {
      id: 'riTotalCost',
      name: 'Final Cost',
      export: true,
      alter: (value: any, param: string) => {
        if (!value[param] || value[param] == '-') {
          return isNaN(value['totalCost'])
            ? '-'
            : Number(value['totalCost']).toFixed(2);
        } else {
          return isNaN(value[param])
            ? '-'
            : value['riApplied'] && value['riApplied'].toLowerCase() == 'yes'
            ? Number(value[param]).toFixed(2)
            : Number(value['totalCost']).toFixed(2);
        }
      },
    },
  ];

  accountId: any;
  regionId: any;
  currentMessage: any;
  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private zone: NgZone
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeAccount) {
      if (!this.monthYear || !this.activeAccount) {
        this.notifier.alert('Info', '', 'Failed to Load Data', 'info', 5000);
      } else {
        this.rdsData = [];
        this.loadMore = null;
        this.loadRDSData();
      }
    }
  }

  ngOnInit(): void {}

  async loadRDSData() {
    if (!this.loadMore) {
      this.rdsData = [];
    }
    this.notifier.loading(true);
    let data = {
      month_year: this.monthYear,
      lid: this.activeAccount.lid,
      userType: 'C',
      action: 'fetchRds',
      masterAccId: $('#masterAccountSelect')
        ? $('#masterAccountSelect').val()
        : undefined,
      more_instances: this.loadMore ? this.loadMore : undefined,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/ec2breakup`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if ((result.status == '1' || result.s == '1') && result.rdsDetailsList) {
      this.rdsData = [...this.rdsData, ...result.rdsDetailsList];
    }

    if (result.more_instances) {
      this.loadMore = result.more_instances;
    } else {
      this.loadMore = null;
    }
    this.notifier.loading(false);
  }

  export() {
    let total: any = {
      totalHours: 0,
      riTotalCost: 0,
    };
    let element = document.createElement('table');
    element.innerHTML += `
        <thead>
          <tr>
            <td>
              Month Year: ${this.monthYear}
            </td>
            <td>
              Account ID: ${this.activeAccount.lid}
            </td>
          </tr>
          <tr>
            <th>Sl. No.</th>
            ${this.rdsHeaders.map((h: any) => {
              if (h.export) {
                if (h.displayConditional) {
                  if (!h.displayConditional) {
                    return '';
                  } else {
                    return `<th>${h.name}</th>`;
                  }
                } else {
                  return `<th>${h.name}</th>`;
                }
              } else {
                return '';
              }
            })}
          </tr>
        </thead>
        <tbody>
            ${this.rdsData.map((ec2: any, index: any) => {
              return `
                <tr>
                  <td>${index + 1}</td>
                  ${this.rdsHeaders.map((h: any) => {
                    if (!h.export) {
                      return '';
                    }
                    if (
                      (h.id == 'totalHours' || h.id == 'riTotalCost') &&
                      h.export
                    ) {
                      let value = h.alter ? h.alter(ec2, h.id) : ec2[h.id];
                      value = parseFloat(value);
                      total[h.id] += value;
                    }
                    return h.export
                      ? `
                      <td>
                        <span>${
                          ((h.alter ? h.alter(ec2, h.id) : ec2[h.id]) != '0' &&
                            (h.alter ? h.alter(ec2, h.id) : ec2[h.id])) != 0
                            ? h.alter
                              ? h.alter(ec2, h.id)
                              : ec2[h.id] != undefined && ec2[h.id] != null
                              ? ec2[h.id]
                              : ''
                            : '-'
                        }</span>
                      </td>
                    `
                      : '';
                  })}
                </tr>
              `;
            })}
            <tr>
                <td></td>
                ${this.rdsHeaders.map((h: any, index: any) => {
                  if (
                    index + 1 < this.rdsHeaders.length &&
                    this.rdsHeaders[index + 1].id == 'totalHours'
                  ) {
                    return '<td>SUB TOTAL</td>';
                  }
                  if (
                    (h.id == 'totalHours' || h.id == 'riTotalCost') &&
                    h.export
                  ) {
                    return h.export
                      ? `
                      <td>
                        <span>${total[h.id]}</span>
                      </td>
                    `
                      : '';
                  }
                  return '<td></td>';
                })}
            </tr>
        </tbody>
      `;
    window.exportToExcelElement(
      element,
      `${this.activeAccount.lid}_${this.monthYear}`,
      'csv'
    );
    return;
    // exportToExcel("viewTicketTable","ticketlist", "xlsx");
    if (this.diff_days > 0) {
      this.showConditionalElement = true;
    }

    setTimeout(() => {
      window.exportToExcel(
        'rds-table',
        'rds-bills' + `_${this.activeAccount.lid}`,
        'csv'
      );
      setTimeout(() => {
        this.showConditionalElement = false;
      }, 100);
    }, 100);
  }

  ngOnDestroy(): void {}
}
