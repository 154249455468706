<div class="card" style="border:none">
  <h5 class="card-header">EC2 Instances Based on Tags
  </h5>
  <div class="card-body" style="border:none">
    <!--<h5 class="card-title">Case Details</h5>-->

    <div style="display: flex; justify-content: flex-end; gap: 8px; align-items: center; margin: 10px 0px 10px 10px;">
      <div>Search By Tags</div>
      <div class="tag-list">
        <select class="form-control" [(ngModel)]="selectedTag" data-live-search="true" id="tagListID"
          (change)="changeTag()">
          <option *ngFor="let tag of tagList" value="{{tag}}">{{tag}}</option>
        </select>
      </div>
      <div class="value-list">
        <select class="form-control" [(ngModel)]="selectedValue" data-live-search="true" id="valueListID"
          (change)="changeValue()">
          <option *ngFor="let value of valueList" value="{{value}}">{{value}}</option>
        </select>
      </div>
      <div class="dropdown" [ngStyle]="{'display': getStartTriggerInstances().length > 0 ? 'block': 'none'}"
        *ngIf="writeAccess">
        <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
          id="instanceAction">
          Action
        </button>
        <div class="dropdown-menu" aria-labelledby="instanceAction">
          <a class="dropdown-item" href="#" (click)="triggerInstances($event, 'run')">Start</a>
          <a class="dropdown-item" href="#" (click)="triggerInstances($event, 'stop')">Stop</a>
        </div>
      </div>
    </div>

    <table class="table table-docs table-hover">
      <thead>
        <tr>
          <th></th>
          <th>S.N.</th>
          <th *ngFor="let h of headers">
            {{h.name}}



            <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,instancesList)">

              <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
              <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
            </span>

            <span *ngIf="h.filter " class="inputseach">
              <input type="text" [(ngModel)]="h.filterText" />
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </th>
          <th>Tags</th>
          <th *ngIf="writeAccess">Start / Stop</th>
        </tr>
      </thead>

      <tbody [ngStyle]="{'display': instancesList.length > 0 ? 'table-row-group' : 'none'}"
        *ngFor="let i of instancesList; let j = index">
        <tr *ngIf="notifier.filterTableColums(headers,i)">
          <td><input type="checkbox" name="" id="" [(ngModel)]="i.actionStart"></td>
          <td>{{j+1}}</td>
          <td *ngFor="let h of headers">
            <span *ngIf="h.click" (click)="callFunction(h.click, i)" class="btn light">
              <span>
                {{i[h.id]}}
              </span>
            </span>
            <div *ngIf="!h.click">
              <div *ngIf="h.statusCheck">
                <div class='status_div' [ngClass]="{'danger': i[h.id] == 'stopped', 'success': i[h.id] == 'running'}">
                  <div class='status_indicator'></div>
                  <div class='status_text'>{{i[h.id]}}</div>
                </div>
              </div>
              <span *ngIf="!h.statusCheck">
                {{i[h.id]}}
              </span>
            </div>
          </td>
          <td><span class="btn light" (click)="showTags(i)">Tags</span> </td>
          <td *ngIf="writeAccess && i.instanceStatus == 'running'">
            <span class="btn" (click)="singleStartStop($event, i, 'stop')">Stop</span>
          </td>
          <td *ngIf="writeAccess && i.instanceStatus != 'running'">
            <span class="btn" (click)="singleStartStop($event, i, 'run')">Start</span>
          </td>
        </tr>
      </tbody>
      <tbody [ngStyle]="{'display': instancesList.length == 0 ? 'table-row-group' : 'none'}">
        <tr>
          <td [attr.colspan]="headers.length + 4">
            <div style="text-align: center;">No Instances Found</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<tags-modal *ngIf="showTagsModal" modalTitle="Tags" instanceID={{showTagsModal.instanceId}} accountID={{accountId}}
  regionID={{regionId}} serviceName="ec2" (hideModal)="hideMdoal($event)"></tags-modal>
<ec2-details-view *ngIf="instance" [instance]="instance" (hideModal)="hideDetails($event)"
  (instanceStartStop)="instanceStartStop($event)"></ec2-details-view>
<instacne-start-stop *ngIf="command && writeAccess" [modalTitle]="modalTitle" [action]="action" [command]="command"
  [instances]="getStartTriggerInstances()" (hideModal)="command = null"></instacne-start-stop>