<div class="card" [ngStyle]="{display: selectedLinkedAccount ? 'none' : 'block'}">
  <div class="card-header" style="display: flex; justify-content: space-between; align-items: center">
    <h5>Billing Dashboard</h5>
  </div>
  <div class="card-body">
    <div style="display: flex; gap: 10px; align-items: center; justify-content: flex-end;">
      <span style="font-weight: bold">Master Accounts: </span>
      <span>
        <select class="form-control" [(ngModel)]="selectedMasterAccount" (change)="load(false)" id="masterAccountSelect"
          data-live-search="true">
          <option [attr.value]="account" *ngFor="let account of masterAccounts">
            {{ account }}
          </option>
        </select>
      </span>
      <img src="../assets/img/loading_2.svg" *ngIf="loading['master']" alt="loading scans"
        style="width: 18px; margin: 10px" />
      <span style="font-weight: bold">Month Year: </span>
      <input class="datetimepicker" id="datetimepicker" type="text" (change)="load(false)" [(ngModel)]="dateRange" />
      <button class="btn btn-primary-soft" (click)="load()">Refresh</button>
      <button class="btn btn-success-soft" (click)="export()" *ngIf="appended.length > 0">Export</button>
    </div>
  </div>
</div>
<div class="card" *ngIf="!selectedLinkedAccount">
  <div class="card-body" style="overflow-x: auto;">
    <table class="table table-docs table-hover" id="billing-dashboard">
      <thead>
        <tr>
          <th>S.N.</th>
          <th>Service Name</th>
          <th (click)="selectedLinkedAccount = h.id" *ngFor="
              let h of headers
            " title="View Daily Cost Variance Report">
            <span>
              <span>
                {{ h.name }}
              </span>
              <span>
                ${{toFixed(fetchTotalAccountService(h.id), 2)}}
              </span>
              <span><img src="../assets/img/loading_2.svg" *ngIf="loading[h.id] || loading['master']"
                  alt="loading scans" style="width: 18px; margin: 10px" /></span>
            </span>
          </th>
          <th>Total for Services
            <span>${{fetchFinalCost()}}</span>
          </th>
        </tr>
      </thead>

      <tbody *ngIf="appended.length > 0">
        <tr *ngFor="let i of appended; let j = index">
          <td>{{ j + 1 }}</td>
          <td>{{i}}</td>
          <td *ngFor="
              let h of headers
            ">
            <span>
              {{ (responseData[h.id] && responseData[h.id][i] && toFixed(responseData[h.id][i]['finalTotalBill'], 2)) ||
              '-' }}
            </span>
          </td>
          <td>
            {{toFixed(fetchTotalBillService(i), 2)}}
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="appended.length == 0">
        <tr>
          <td [attr.colSpan]="headers.length + 3" style="text-align: center;">
            <span>No Data Found</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<cost-variance-report *ngIf="selectedLinkedAccount" [mid]="selectedMasterAccount" [lid]="selectedLinkedAccount"
  (close)="close($event)"></cost-variance-report>