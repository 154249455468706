<h1>View Reports</h1>
<div>
  <div class="row" style="display: flex; justify-content: flex-end;">
    <select class="form-control" style="width: 33%;" [(ngModel)]="reportType">
      <option value="sg">Security Groups Report</option>
      <option value="inventory">Inventory Report</option>
      <option value="dailybackup">Daily Backup Report</option>
      <option value="mis">MIS Report</option>
      <option value="uptime">Uptime Report</option>
      <option value="diskdetails">Disk Details Report</option>
      <option value="aging_snapshots">Snapshot Ageing Report</option>
      <option value="infra_report">Consolidated Infra Report</option>
      <option value="compliance_report">Consolidated Compliance Report</option>
      <option value="patch_report" *ngIf="urlPrefix == 'admin'">Patch Report</option>
    </select>
  </div>
  <div>
    <diskdetails-reports *ngIf="reportType == 'diskdetails'"></diskdetails-reports>
    <snapshots-backupreport [customHeader]="customHeader" *ngIf="reportType == 'dailybackup'"></snapshots-backupreport>
    <inventory-reports *ngIf="reportType == 'inventory'"></inventory-reports>
    <mis-reports *ngIf="reportType == 'mis'"></mis-reports>
    <sg-reports *ngIf="reportType == 'sg'" [sgFilter]="[]" [filterRequired]="false"></sg-reports>
    <uptime-reports *ngIf="reportType == 'uptime'"></uptime-reports>
    <snapshots-aging-report *ngIf="reportType == 'aging_snapshots'"></snapshots-aging-report>
    <consolidated-infra-report *ngIf="reportType == 'infra_report' || reportType == 'compliance_report'"
      [reportType]="reportType">
    </consolidated-infra-report>
    <reports-patchreport *ngIf="reportType == 'patch_report' && urlPrefix == 'admin'">
    </reports-patchreport>
  </div>
</div>