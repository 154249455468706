import {
  Component,
  OnDestroy,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NotifierService } from 'src/app/_services/notifier.service';
import { APIService } from 'src/app/api/api.service';
import { param } from 'jquery';

declare let $: any;
declare let window: any;
declare let Jhxlsx: any;

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './convertionrate.component.html',
  styleUrls: ['.././erp-pl-report.component.css']
})
export class ConvertionRateComponent implements OnInit {
  curnrate:any =[];
  editMode: boolean = false;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  currentrate: any =[];
  alldata: any =[];
  add: boolean = false;
  er:string;
  monthyear: any;
  monthYear: any;
  rate: string;
  viewall:boolean = false;
  editingIndex: number | null = null;
  editingAmount: number | null = null;
  email: string;
  editableRow: number | undefined;
  editedCurrency:string;
  editing: boolean = false;
  changedrate: any;
  rateToUpdate:number;
  funRef: any;
  extend: boolean = false;
  headers: any = [
    {
      id: 'month',
      name: 'Month',
    },
    { id: 'rate', 
    name: 'Currency Rate' },
    {
      id: 'updatedby',
      name: 'Updated by',
    },
    {
      id: 'updatedon',
      name: 'Updated On',
    },
  ];



  constructor(
    private apiServer: APIService,
    public notifier: NotifierService,
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute
  ) {}




  loadMasterAccounts(){}
  ngOnInit(): void {

    this.funRef = {};


    window.angularComponentReference = {
      zone: this.zone,
      componentFn: (docName: any, fileSize: any, targetDocBase64: any) =>
        this.loadAllData(),
      component: this,
    };

    let temp = moment();
  $('#monthDate').val(`${temp.format('MM')}-${temp.format('YYYY')}`);

  $('#monthDate')
    .datepicker({
      autoclose: true,
      minViewMode: 1,
      format: 'mm-yyyy',
      orientation: 'bottom auto',
      endDate: new Date(),
    })
    .on('changeDate', function (selected: any) {
      window.angularComponentReference.zone.run(() => {
        window.angularComponentReference.componentFn();
      });
    });
    this.loadAllData();
    // console.log($('#monthDate').val());
  }


  toggleEdit(){
    this.editMode = !this.editMode;
    // if(!this.editMode){
    //   this.alldata.forEach(data => {
    //     data.editMode= false;
    //   });
    }
    enableEditing(){
      // const rowIndexToEdit = 0;
      // this.editableRow = rowIndexToEdit;
      this.editMode = !this.editMode;
      // if(this.editableRow !== undefined){
        // this.editedCurrency = this.alldata[this.editableRow].rate;
        // console.log(this.alldata[this.editableRow].rate);
      }
    
      test(row: any){
        // let row = closest('tr');
        // let ri = Array.from(row.parentElement.children).indexOf(row);
        let datainput = row.querySelector('#changerate');
        console.log(datainput.value);
        // this.changedrate= document.getElementById("rate");
      }

    async save(rate:any, monthYear:any){
      this.notifier.loading(true);
      // console.log(monthYear);
      // console.log(rate);
      this.email = localStorage.getItem("eId");

        let data = {
          a: 'update',
        monthYear: monthYear,
        rate: rate,
        email: this.email,
        };
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/currencyrate`;
        let result = await this.apiServer.postDataPromis(apiURL, data, header);
        
        if (result.status == '1' || result.s == '1') {
          this.notifier.loading(false);
          this.loadAllData();
          if(result.error){
            this.notifier.alert("Info","",result.error,"info", 5000);

          }
        }
        else{
        this.notifier.loading(false);
        this.notifier.alert("Info","",result.error,"info", 5000);
        // this.er = 'No Data Found';
        }
        // item.isEdit = false;

    }
  

    async addnew(){
      this.add = false;
      this.notifier.loading(true);
      this.email = localStorage.getItem("eId");
console.log(this.rate);
      let data = {
        a: 'add',
      monthYear: $('#monthDate').val(),
      rate: this.rate,
      email: this.email,
      };
      console.log($('#monthDate').val());
      console.log(this.rate)
      // console.log($('#monthDate').val());
    // this.monthyear = ($('#monthDate').val());
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/currencyrate`;
      let result = await this.apiServer.postDataPromis(apiURL, data, header);
    
      if (result.status == '1' || result.s == '1') {
        this.notifier.loading(false);
        this.loadAllData();
        
        if(result.error){
          this.notifier.alert("Info","",result.error,"info", 5000);
        }
    }
    else{
      this.notifier.loading(false);
      this.notifier.alert("Info","",result.error,"info", 5000);
      // this.alldata=[];
      // this.er = 'No Data Found';
    }
    this.rate = '';
    this.monthYear = '';
    }

    close(){
      this.rate = '';
    this.monthYear = ''; 
    }

//   async loadData(){
//     this.notifier.loading(true);
//     let data = {
//       a: 'fetch',
//       monthYear: $('#monthDate').val(),
//     };
//     // console.log($('#monthDate').val());
//  this.monthyear = ($('#monthDate').val());
//     let header = {
//       Authorization: localStorage.getItem('t'),
//     };
//     let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/currencyrate`;
//     let result = await this.apiServer.postDataPromis(apiURL, data, header);

//     if (result.status == '1' || result.s == '1') {
//       this.notifier.loading(false);
//       this.currentrate = result;
//       if(result.error){
//         this.currentrate=[];
//       }
//   }
//   else{
//     this.notifier.loading(false);
//     this.currentrate=[];
//     this.er = 'No Data Found';
//   }
// }

async loadAllData(){
this.viewall=true;
this.notifier.loading(true);
let data = {
  a: 'fetchall',
};
// console.log($('#monthDate').val());
this.monthyear = ($('#monthDate').val());
let header = {
  Authorization: localStorage.getItem('t'),
};
let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/currencyrate`;
let result = await this.apiServer.postDataPromis(apiURL, data, header);

if (result.status == '1' || result.s == '1') {
  result.data = result.data.sort((a: any, b: any) => {
    let date_a: any = new Date(a["monthYear"].slice(3) + '-' + a["monthYear"].slice(0, 2) + '-01');
let date_b: any = new Date(b["monthYear"].slice(3) + '-' + b["monthYear"].slice(0, 2) + '-01');
    return Date.parse(date_b) - Date.parse(date_a);
  });
  this.alldata = result.data;

  this.notifier.loading(false);
  // this.alldata = result.data;
  // this.alldata.forEach((data:any) =>)
  this.alldata.forEach((data: any) => {
          data["updatedon"] = moment(data["updatedon"]).format("MMM DD, YYYY hh:mm A");
  });
  if(result.error){
    this.alldata=[];
  }
}
else{
this.notifier.loading(false);
this.alldata=[];
// this.er = 'No Data Found';
}
}

//  checkrefresh(){
//   if(!this.viewall){
//     this.loadData();
//   }
//   else if(this.viewall){
//     this.loadAllData();
//   }
//  }
editmode(){
this.editMode = !this.editMode;
this.editingAmount = null;
this.editingIndex = null; 
}
saveDetails(){
 if (this.editingIndex !== null && this.editingAmount !== null){
  this.alldata[this.editingIndex].amount = this.editingAmount;
 }
 this.editmode();
}



















async onAdd(){
    this.add = false;
    this.notifier.loading(true);
    this.email = localStorage.getItem("eId");
    let data = {
      a: 'add',
    monthYear: this.monthYear, 
    rate: this.rate,
    email: this.email,
    };
    // console.log($('#monthDate').val());
  // this.monthyear = ($('#monthDate').val());
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/billing/currencyrate`;
    let result = await this.apiServer.postDataPromis(apiURL, data, header);
  
    if (result.status == '1' || result.s == '1') {
     
      this.notifier.loading(false);
      this.loadAllData();
      
      if(result.error){
        this.notifier.alert("Info","",result.error,"info", 5000);
      }
  }
  else{
    this.notifier.loading(false);
    this.notifier.alert("Info","",result.error,"info", 5000);
    // this.alldata=[];
    // this.er = 'No Data Found';
  }

}

// onEdit(userObj: any){
// //   this.alldata.forEach((element: { isEdit: boolean; }) => {
// //     element.isEdit = false;
// //   });
// // userObj.isEdit = true;
// this.editing = true;
// }


































onEdit(item: any){
  item.isEdit = true;
}
validateField(item: any){
if(item !== ''){
  return false;
}
else{
  return true;
}
}

validateForm(obj:any){
  if(obj.rate != '' && obj.monthYear != ''){
    this.save(obj.rate, obj.monthYear);
    obj.isEdit = false;
    return false;
  }
  else{
    return true;
  }
}

onCancel(item: any){
  item.isEdit = false;
}
}