import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../api/api.service';
import { NotifierService } from '../../../_services/notifier.service';
import * as moment from 'moment';

declare let window: any;

@Component({
  templateUrl: './ebscostoptimization.component.html',
  styleUrls: ['./ebscostoptimization.component.css'],
})
export class BetaEBSCostOptimizationComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  jobDetails: any = null;
  recomondations: any = [];
  funRef: any;
  tagsList: any = null;
  tagsModalTitle: any = 'Tags';
  modalHeader: any;
  createJobTrigger: boolean = null;
  nextTokens: any = {
    recomondations: undefined,
  };
  currentPage: String = 'job_details';
  fetching: boolean = false;

  destroyed: boolean = false;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;

  ec2CostHeaders: any = [
    { id: 'volumeName', name: 'Volume Name', filter: true },
    { id: 'volumeType', name: 'Volume Type', filter: true, center: true },
    {
      id: 'currentPrice',
      name: 'Current Cost',
      filter: true,
      center: true,
    },
    {
      id: 'recType',
      name: 'Recommended',
      filter: true,
      center: true,
    },

    {
      id: 'reccPrice',
      name: 'Recommended Cost',
      filter: true,
      center: true,
    },
    {
      id: 'savings_html',
      name: 'Savings',
      filter: true,
      center: true,
    },
  ];

  moreDetailsHeaders: any = [
    { id: 'volumeId', name: 'Volume ID' },
    { id: 'instanceId', name: 'Instance ID' },
    { id: 'volumeSize', name: 'Volume Size' },
    { id: 'iops', name: 'IOPS' },
    { id: 'createdTime', name: 'Created Time' },
  ];

  constructor(
    private apiServer: APIService,
    public notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.refresh();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.refresh();
      }
    });
    this.funRef = {};
  }

  position: any = {
    x: '0px',
    y: '0px',
    overflow: false,
    overflowwidth: '0px',
    pointer: '0px',
  };
  setPosition(event: any) {
    if (
      window.innerWidth <
      event.target.querySelector('.message_back').offsetWidth
    ) {
      this.position.overflow = true;

      if (
        window.innerWidth >
        event.target.querySelector('.message_back').offsetWidth - 110
      ) {
        this.position.overflowwidth = window.innerWidth - 50 + 'px';
      } else {
        this.position.overflowwidth = window.innerWidth - 110 + 'px';
      }
    }
    if (
      window.innerWidth - event.clientX >
      event.target.querySelector('.message_back').offsetWidth / 2
    ) {
      this.position.x =
        window.innerWidth -
        event.clientX -
        event.target.querySelector('.message_back').offsetWidth / 2 +
        'px';
      this.position.pointer =
        event.target.querySelector('.message_back').offsetWidth / 2 - 20 + 'px';
    } else {
      this.position.pointer = 100 + 'px';
      this.position.x = 60 + 'px';
    }
    this.position.y =
      event.clientY + event.target.offsetHeight - event.offsetY + 'px';
  }

  refresh_: boolean = false;
  refresh() {
    this.refresh_ = true;
    this.loadrecomondations();
  }

  loading_recom: boolean = false;
  async loadrecomondations(nextToken: string = '') {
    this.nextToken = nextToken == '' ? null : nextToken;
    this.loading_recom = true;
    this.currentPage = 'recomondations';
    let currentAccountId = this.accountId;
    let currentRegionId = this.regionId;
    if (this.destroyed) {
      return true;
    }
    if (nextToken == '') {
      this.refresh_ = true;
      this.recomondations = [];
      this.notifier.loading(true);
    }
    let data = {
      accountId: this.accountId,
      region: this.regionId,
      nextToken: nextToken != '' ? nextToken : undefined,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/optimisation/ebs`;

    if (nextToken != '' && this.refresh_) {
      nextToken = '';
      this.recomondations = [];
      return true;
    }

    this.refresh_ = false;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.status_code == 1 || result.volumelists) {
      if (
        currentAccountId != this.accountId ||
        currentRegionId != this.regionId
      ) {
        return true;
      }
      result.volumelists = result.volumelists
        .filter((recom: any) => {
          return recom.volumeType != recom.recType;
        })
        .map((recom: any) => {
          recom['createdTime'] = moment(recom['createdTime']).format(
            'DD/MM/YYYY hh:mm A'
          );
          recom['savings_html'] = `<span class='${
            Number(recom.savings) > 0
              ? 'green_text'
              : Number(recom.savings) < 0
              ? 'red_text'
              : ''
          }'>$${recom.savings}</span>`;
          recom['accountID'] = currentAccountId;
          recom['regionID'] = currentRegionId;
          return recom;
        });
      this.recomondations = [...this.recomondations, ...result.volumelists];
    } else {
      this.loading_recom = false;
      if (result.message != 'NO RECORD FOUND') {
        this.notifier.alert('Info', '', result.message, 'info', 5000);
      }
    }

    if (result.nextToken) {
      this.loadrecomondations(result.nextToken);
    } else {
      this.nextToken = null;
      this.loading_recom = false;
    }
    if (!this.destroyed) {
      this.notifier.loading(false);
    }

    return true;
  }

  nextToken: any;

  downloadReport() {
    let element = document.createElement('table');
    element.innerHTML += `
        <thead>
          <tr>
            <th>Sl. No.</th>
            ${[...this.ec2CostHeaders, ...this.moreDetailsHeaders].map(
              (h: any) => {
                return `<th>${h.name}</th>`;
              }
            )}
          </tr>
        </thead>
        <tbody>
            ${this.recomondations.map((ec2: any, index: any) => {
              return `
                <tr>
                  <td>${index + 1}</td>
                  ${[...this.ec2CostHeaders, ...this.moreDetailsHeaders].map(
                    (h: any) => {
                      return `<td>
                      ${ec2[h.id]}
                    </td>`;
                    }
                  )}
                </tr>
              `;
            })}
        </tbody>
      `;
    window.exportToExcelElement(
      element,
      `EBS Cost Controls - ${this.accountId}`,
      'csv'
    );
  }

  getTotalSavings(): String {
    let total = 0;
    this.recomondations.forEach((recom: any) => {
      total += isNaN(recom.savings) ? 0 : Number(recom.savings);
    });
    return total.toFixed(2);
  }

  getTotalCost(): String {
    let total = 0;
    this.recomondations.forEach((recom: any) => {
      total += isNaN(recom.present_price_plain)
        ? 0
        : Number(recom.present_price_plain);
    });
    return total.toFixed(2);
  }

  loadHTML(value: any) {
    if (value == 'LOADING_PRICE_THROUGH_API') {
      return `<span class='center'>
        <img src="../assets/img/loading_2.svg" alt="loading scans" width="18" style="width: 25px; margin: 10px;">
      </span>`;
    } else {
      return `<span>${value}</span>`;
    }
  }

  callFunction(name: any, param: any) {}

  createJob(event: any) {
    this.createJobTrigger = true;
  }

  hideCreateJob(event: any) {
    this.createJobTrigger = null;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.currentMessage.unsubscribe();
  }
}
