<div *ngIf="action == 'update'">
  <div *ngIf="(mrr.serviceName == 'Amazon Elastic Compute Cloud')">
    <fieldset class="border p-4 rounded">
      <legend class="w-auto px-3 text-primary">EC2</legend>

      <fieldset class="border p-4 rounded">
        <legend class="w-auto px-3" style="font-size: 20px;">Instance</legend>
        <!-- EC2 Instance -->
        <div class="mb-4">
          <label class="form-label fw-bold d-block">RI/SP Applied:</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="ec2riApplied"
              [(ngModel)]="mrrDatasub.instance.ri_sp.isMrr" (change)="resetPercentage(mrrDatasub.instance.ri_sp)"
              [value]="true" />
            <label class="form-check-label me-3">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="ec2riApplied"
              [(ngModel)]="mrrDatasub.instance.ri_sp.isMrr" (change)="resetPercentage(mrrDatasub.instance.ri_sp)"
              [value]="false" checked />
            <label class="form-check-label">No</label>
          </div>
          <div *ngIf="mrrDatasub.instance.ri_sp.isMrr" class="mt-2">
            <label class="me-2">MRR Percentage:</label>
            <input type="number" class="form-control w-auto margin_left_0"
              [(ngModel)]="mrrDatasub.instance.ri_sp.perc" />
          </div>
        </div>

        <!-- OD -->
        <div class="mb-4">
          <label class="form-label fw-bold d-block">OD Applied:</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="ec2odApplied" [(ngModel)]="mrrDatasub.instance.od.isMrr"
              (change)="resetPercentage(mrrDatasub.instance.od)" [value]="true" />
            <label class="form-check-label me-3">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="ec2odApplied" [(ngModel)]="mrrDatasub.instance.od.isMrr"
              [value]="false" (change)="resetPercentage(mrrDatasub.instance.od)" checked />
            <label class="form-check-label">No</label>
          </div>
          <div *ngIf="mrrDatasub.instance.od.isMrr" class="mt-2">
            <label class="me-2">MRR Percentage:</label>
            <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="mrrDatasub.instance.od.perc" />
          </div>
        </div>

        <!-- Spot -->
        <div class="mb-4">
          <label class="form-label fw-bold d-block">Spot Applied:</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="ec2spotApplied"
              (change)="resetPercentage(mrrDatasub.instance.spot)" [(ngModel)]="mrrDatasub.instance.spot.isMrr"
              [value]="true" />
            <label class="form-check-label me-3">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="ec2spotApplied"
              [(ngModel)]="mrrDatasub.instance.spot.isMrr" [value]="false"
              (change)="resetPercentage(mrrDatasub.instance.spot)" checked />
            <label class="form-check-label">No</label>
          </div>
          <div *ngIf="mrrDatasub.instance.spot.isMrr" class="mt-2">
            <label class="me-2">MRR Percentage:</label>
            <input type="number" class="form-control w-auto margin_left_0"
              [(ngModel)]="mrrDatasub.instance.spot.perc" />
          </div>
        </div>
      </fieldset>
      <!-- EBS -->
      <div class="mb-4">
        <label class="form-label fw-bold d-block">EBS Applied:</label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="ec2ebsApplied" [(ngModel)]="mrrDatasub.ebs.isMrr"
            (change)="resetPercentage(mrrDatasub.ebs)" [value]="true" />
          <label class="form-check-label me-3">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="ec2ebsApplied" [(ngModel)]="mrrDatasub.ebs.isMrr"
            (change)="resetPercentage(mrrDatasub.ebs)" [value]="false" checked />
          <label class="form-check-label">No</label>
        </div>
        <div *ngIf="mrrDatasub.ebs.isMrr" class="mt-2">
          <label class="me-2">MRR Percentage:</label>
          <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="mrrDatasub.ebs.perc" />
        </div>
      </div>

      <!-- Others -->
      <div>
        <label class="form-label fw-bold d-block">Others Applied:</label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="ec2othersApplied" [(ngModel)]="mrrDatasub.others.isMrr"
            (change)="resetPercentage(mrrDatasub.others)" [value]="true" />
          <label class="form-check-label me-3">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="ec2othersApplied" [(ngModel)]="mrrDatasub.others.isMrr"
            (change)="resetPercentage(mrrDatasub.others)" [value]="false" checked />
          <label class="form-check-label">No</label>
        </div>
        <div *ngIf="mrrDatasub.others.isMrr" class="mt-2">
          <label class="me-2">MRR Percentage:</label>
          <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="mrrDatasub.others.perc" />
        </div>
      </div>
    </fieldset>
  </div>

  <div *ngIf="(mrr.serviceName == 'Amazon Relational Database Service')">
    <fieldset class="border p-4 rounded">
      <legend class="w-auto px-3 text-primary">RDS</legend>

      <fieldset class="border p-4 rounded">
        <legend class="w-auto px-3" style="font-size: 20px;">Instance</legend>
        <!-- EC2 Instance -->
        <div class="mb-4">
          <label class="form-label fw-bold d-block">RI/SP Applied:</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="rdsriApplied"
              [(ngModel)]="mrrDatasub.instance.ri_sp.isMrr" [value]="true" />
            <label class="form-check-label me-3">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="rdsriApplied"
              [(ngModel)]="mrrDatasub.instance.ri_sp.isMrr" [value]="false" checked />
            <label class="form-check-label">No</label>
          </div>
          <div *ngIf="mrrDatasub.instance.ri_sp.isMrr" class="mt-2">
            <label class="me-2">MRR Percentage:</label>
            <input type="number" class="form-control w-auto margin_left_0"
              [(ngModel)]="mrrDatasub.instance.ri_sp.perc" />
          </div>
        </div>

        <!-- OD -->
        <div class="mb-4">
          <label class="form-label fw-bold d-block">OD Applied:</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="rdsodApplied" [(ngModel)]="mrrDatasub.instance.od.isMrr"
              [value]="true" />
            <label class="form-check-label me-3">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="rdsodApplied" [(ngModel)]="mrrDatasub.instance.od.isMrr"
              [value]="false" checked />
            <label class="form-check-label">No</label>
          </div>
          <div *ngIf="mrrDatasub.instance.od.isMrr" class="mt-2">
            <label class="me-2">MRR Percentage:</label>
            <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="mrrDatasub.instance.od.perc" />
          </div>
        </div>

        <!-- Spot -->

      </fieldset>

      <!-- Others -->
      <div>
        <label class="form-label fw-bold d-block">Others Applied:</label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="rdsothersApplied" [(ngModel)]="mrrDatasub.others.isMrr"
            [value]="true" />
          <label class="form-check-label me-3">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="rdsothersApplied" [(ngModel)]="mrrDatasub.others.isMrr"
            [value]="false" checked />
          <label class="form-check-label">No</label>
        </div>
        <div *ngIf="mrrDatasub.others.isMrr" class="mt-2">
          <label class="me-2">MRR Percentage:</label>
          <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="mrrDatasub.others.perc" />
        </div>
      </div>
    </fieldset>
  </div>

  <div *ngIf="(mrr.serviceName == 'Amazon Simple Storage Service')">
    <fieldset class="border p-4 rounded">
      <legend class="w-auto px-3 text-primary">
        S3 Discount
      </legend>

      <div class="mb-4">
        <label class="form-label fw-bold d-block">S3 MRR Applied</label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="s3Applied" [(ngModel)]="mrrDatasub.s3Mrr.isMrr"
            [value]="true" />
          <label class="form-check-label me-3">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="riApplied" [(ngModel)]="mrrDatasub.s3Mrr.isMrr"
            [value]="false" checked />
          <label class="form-check-label">No</label>
        </div>
      </div>
      <div *ngIf="mrrDatasub.s3Mrr.isMrr" class="mt-2">
        <div style="display: flex;justify-content: space-between;">
          <label class="me-2">S3 Percentage</label>
          <span class="view-details" style="cursor: pointer; color: blue;"
            (click)="openPopup('Amazon Simple Storage Service')">View Details</span>
        </div>
        <input type="number" style="margin-left: 0px;" class="form-control w-auto margin_left_0"
          [(ngModel)]="mrrDatasub.s3Mrr.perc" />

      </div>
    </fieldset>
  </div>

  <div *ngIf="(mrr.serviceName == 'Amazon CloudFront')">
    <fieldset class="border p-4 rounded">
      <legend class="w-auto px-3 text-primary">
        CFRC Discount
      </legend>

      <div class="mb-4">
        <div style="display: flex;justify-content: space-between;">
          <label class="form-label fw-bold d-block">CFRC Applied:</label>
          <span class="view-details" style="cursor: pointer; color: blue;" (click)="openPopup('Amazon CloudFront')">View
            Details</span>
        </div>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="cfrcApplied" [(ngModel)]="mrrDatasub.cfrcMrr.isMrr"
            [value]="true" />
          <label class="form-check-label me-3">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="cfrcApplied" [(ngModel)]="mrrDatasub.cfrcMrr.isMrr"
            [value]="false" checked />
          <label class="form-check-label">No</label>
        </div>
        <div *ngIf="mrrDatasub.cfrcMrr.isMrr" class="mt-2">
          <label class="me-2">CFRC Percentage:</label>
          <input type="number" class="margin_left_0 form-control w-auto" [(ngModel)]="mrrDatasub.cfrcMrr.perc" />
        </div>
      </div>
    </fieldset>
  </div>
</div>

<div *ngIf="action !== 'update'">

  <div class="row" *ngIf="(showEC2DiscountFields || isAllSelect)">
    <fieldset class="border p-4 rounded">
      <legend class="w-auto px-3 text-primary">EC2</legend>

      <fieldset class="border p-4 rounded">
        <legend class="w-auto px-3" style="font-size: 20px;">Instance</legend>
        <!-- EC2 Instance -->
        <div class="mb-4">
          <label class="form-label fw-bold d-block">RI/SP Applied:</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="ec2riApplied" [(ngModel)]="subEc2.instance.ri_sp.isMrr"
              (change)="resetPercentage(subEc2.instance.ri_sp)" [value]="true" />
            <label class="form-check-label me-3">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="ec2riApplied" [(ngModel)]="subEc2.instance.ri_sp.isMrr"
              (change)="resetPercentage(subEc2.instance.ri_sp)" [value]="false" checked />
            <label class="form-check-label">No</label>
          </div>
          <div *ngIf="subEc2.instance.ri_sp.isMrr" class="mt-2">
            <label class="me-2">MRR Percentage:</label>
            <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="subEc2.instance.ri_sp.perc" />
          </div>
        </div>

        <!-- OD -->
        <div class="mb-4">
          <label class="form-label fw-bold d-block">OD Applied:</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="ec2odApplied" [(ngModel)]="subEc2.instance.od.isMrr"
              (change)="resetPercentage(subEc2.instance.od)" [value]="true" />
            <label class="form-check-label me-3">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="ec2odApplied" [(ngModel)]="subEc2.instance.od.isMrr"
              (change)="resetPercentage(subEc2.instance.od)" [value]="false" checked />
            <label class="form-check-label">No</label>
          </div>
          <div *ngIf="subEc2.instance.od.isMrr" class="mt-2">
            <label class="me-2">MRR Percentage:</label>
            <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="subEc2.instance.od.perc" />
          </div>
        </div>

        <!-- Spot -->
        <div class="mb-4">
          <label class="form-label fw-bold d-block">Spot Applied:</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="ec2spotApplied" [(ngModel)]="subEc2.instance.spot.isMrr"
              (change)="resetPercentage(subEc2.instance.spot)" [value]="true" />
            <label class="form-check-label me-3">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="ec2spotApplied" [(ngModel)]="subEc2.instance.spot.isMrr"
              (change)="resetPercentage(subEc2.instance.spot)" [value]="false" checked />
            <label class="form-check-label">No</label>
          </div>
          <div *ngIf="subEc2.instance.spot.isMrr" class="mt-2">
            <label class="me-2">MRR Percentage:</label>
            <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="subEc2.instance.spot.perc" />
          </div>
        </div>
      </fieldset>
      <!-- EBS -->
      <div class="mb-4">
        <label class="form-label fw-bold d-block">EBS Applied:</label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="ec2ebsApplied" [(ngModel)]="subEc2.ebs.isMrr"
            (change)="resetPercentage(subEc2.ebs)" [value]="true" />
          <label class="form-check-label me-3">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="ec2ebsApplied" [(ngModel)]="subEc2.ebs.isMrr"
            (change)="resetPercentage(subEc2.ebs)" [value]="false" checked />
          <label class="form-check-label">No</label>
        </div>
        <div *ngIf="subEc2.ebs.isMrr" class="mt-2">
          <label class="me-2">MRR Percentage:</label>
          <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="subEc2.ebs.perc" />
        </div>
      </div>

      <!-- Others -->
      <div>
        <label class="form-label fw-bold d-block">Others Applied:</label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="ec2othersApplied" [(ngModel)]="subEc2.others.isMrr"
            (change)="resetPercentage(subEc2.others)" [value]="true" />
          <label class="form-check-label me-3">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="ec2othersApplied" [(ngModel)]="subEc2.others.isMrr"
            (change)="resetPercentage(subEc2.others)" [value]="false" checked />
          <label class="form-check-label">No</label>
        </div>
        <div *ngIf="subEc2.others.isMrr" class="mt-2">
          <label class="me-2">MRR Percentage:</label>
          <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="subEc2.others.perc" />
        </div>
      </div>
    </fieldset>
  </div>
  <div class="row" *ngIf="(showRDSDiscountFields || isAllSelect)">
    <fieldset class="border p-4 rounded">
      <legend class="w-auto px-3 text-primary">RDS</legend>

      <fieldset class="border p-4 rounded">
        <legend class="w-auto px-3" style="font-size: 20px;">Instance</legend>
        <!-- EC2 Instance -->
        <div class="mb-4">
          <label class="form-label fw-bold d-block">RI/SP Applied:</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="rdsriApplied" [(ngModel)]="subRds.instance.ri_sp.isMrr"
              [value]="true" (change)="resetPercentage(subRds.instance.ri_sp)" />
            <label class="form-check-label me-3">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="rdsriApplied" [(ngModel)]="subRds.instance.ri_sp.isMrr"
              [value]="false" (change)="resetPercentage(subRds.instance.ri_sp)" checked />
            <label class="form-check-label">No</label>
          </div>
          <div *ngIf="subRds.instance.ri_sp.isMrr" class="mt-2">
            <label class="me-2">MRR Percentage:</label>
            <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="subRds.instance.ri_sp.perc" />
          </div>
        </div>

        <!-- OD -->
        <div class="mb-4">
          <label class="form-label fw-bold d-block">OD Applied:</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="rdsodApplied" [(ngModel)]="subRds.instance.od.isMrr"
              (change)="resetPercentage(subRds.instance.od)" [value]="true" />
            <label class="form-check-label me-3">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="rdsodApplied" [(ngModel)]="subRds.instance.od.isMrr"
              (change)="resetPercentage(subRds.instance.od)" [value]="false" checked />
            <label class="form-check-label">No</label>
          </div>
          <div *ngIf="subRds.instance.od.isMrr" class="mt-2">
            <label class="me-2">MRR Percentage:</label>
            <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="subRds.instance.od.perc" />
          </div>
        </div>

        <!-- Spot -->

      </fieldset>

      <!-- Others -->
      <div>
        <label class="form-label fw-bold d-block">Others Applied:</label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="rdsothersApplied" [(ngModel)]="subRds.others.isMrr"
            (change)="resetPercentage(subRds.others)" [value]="true" />
          <label class="form-check-label me-3">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="rdsothersApplied" [(ngModel)]="subRds.others.isMrr"
            (change)="resetPercentage(subRds.others)" [value]="false" checked />
          <label class="form-check-label">No</label>
        </div>
        <div *ngIf="subRds.others.isMrr" class="mt-2">
          <label class="me-2">MRR Percentage:</label>
          <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="subRds.others.perc" />
        </div>
      </div>
    </fieldset>
  </div>
  <div *ngIf="(showS3DiscountFields || isAllSelect)">
    <fieldset class="border p-4 rounded">
      <legend class="w-auto px-3 text-primary">
        S3 Discount
      </legend>


      <div class="mb-4">
        <label class="form-label fw-bold d-block">S3 MRR Applied</label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="s3Applied" [(ngModel)]="s3Mrr.isMrr"
            (change)="resetPercentage(s3Mrr)" [value]="true" />
          <label class="form-check-label me-3">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="riApplied" [(ngModel)]="s3Mrr.isMrr"
            (change)="resetPercentage(s3Mrr)" [value]="false" checked />
          <label class="form-check-label">No</label>
        </div>
      </div>
      <div *ngIf="s3Mrr.isMrr" class="mt-2">
        <div style="display: flex;justify-content: space-between;">
          <label class="me-2">S3 Percentage</label>
          <span class="view-details" style="cursor: pointer; color: blue;"
            (click)="openPopup('Amazon Simple Storage Service')">View Details</span>
        </div>

        <input type="number" class="form-control w-auto margin_left_0" [(ngModel)]="s3Mrr.perc" />
      </div>
    </fieldset>
  </div>

  <div *ngIf="(showCloudFrontDiscountFields || isAllSelect)">
    <fieldset class="border p-4 rounded">
      <legend class="w-auto px-3 text-primary">
        CFRC Discount
      </legend>

      <div class="mb-4">
        <label class="form-label fw-bold d-block">CFRC Applied:</label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="cfrcApplied" [(ngModel)]="cfrcMrr.isMrr"
            (change)="resetPercentage(cfrcMrr)" [value]="true" />
          <label class="form-check-label me-3">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="cfrcApplied" [(ngModel)]="cfrcMrr.isMrr"
            (change)="resetPercentage(cfrcMrr)" [value]="false" checked />
          <label class="form-check-label">No</label>
        </div>
        <div *ngIf="cfrcMrr.isMrr" class="mt-2">
          <div style="display: flex;justify-content: space-between;">
            <label class="me-2">CFRC Percentage:</label>

            <span class="view-details" style="cursor: pointer; color: blue;"
              (click)="openPopup('Amazon CloudFront')">View Details</span>
          </div>
          <input type="number" class="margin_left_0 form-control w-auto" [(ngModel)]="cfrcMrr.perc" />
        </div>
      </div>
    </fieldset>
  </div>
</div>

<app-popup *ngIf="isPopupVisible" [popupTitle]="popupTitle" [mid]="mid" (hidemodel)="hidemodel()"></app-popup>