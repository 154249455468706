<div class="minfy-ui-multi-select-dropdown" #maindoprdown>
  <div class="flex-options cursor_pointer02" (click)="showOptions()">
    <div *ngIf="!selected || selected == ''">
      No {{title}} selected
    </div>
    <div class="flex-end-align">
      <div *ngIf="selected" class="counter-selected">
        <ng-container *ngIf="title == 'VPC'">
          <vpc-option-launch-select-dropdown class="flex-1" [vpc]="getSelected()"></vpc-option-launch-select-dropdown>
        </ng-container>
        <ng-container *ngIf="title == 'Security Group'">
          <sg-option-launch-select-dropdown class="flex-1" [sg]="getSelected()"></sg-option-launch-select-dropdown>
        </ng-container>
        <ng-container *ngIf="title == 'Subnet'">
          <subnet-option-launch-select-dropdown class="flex-1" [subnet]="getSelected()">
          </subnet-option-launch-select-dropdown>
        </ng-container>
        <ng-container *ngIf="title == 'AMI'">
          <ami-option-launch-select-dropdown class="flex-1" [ami]="getSelected()"></ami-option-launch-select-dropdown>
        </ng-container>
        <ng-container *ngIf="title == 'Key Pair'">
          <keypair-option-launch-select-dropdown class="flex-1" [key]="getSelected()">
          </keypair-option-launch-select-dropdown>
        </ng-container>
        <ng-container *ngIf="title == 'Role'">
          <role-option-launch-select-dropdown class="flex-1" [role]="getSelected()">
          </role-option-launch-select-dropdown>
        </ng-container>
      </div>
    </div>
    <div>
      <i class="fa fa-caret-down"></i>
    </div>
  </div>
  <div class="absolute-dropdown" *ngIf="showoptions">
    <div class="flex-between-align search-bar">
      <input type="text" autocomplete="null" [(ngModel)]="searchText" placeholder="Search">
      <span><i class="fa fa-search" aria-hidden="true"></i></span>
    </div>
    <div class="dropdown-search_40">
      <div (click)="alterOption($event, option)" class="dropdown-search-result"
        *ngFor="let option of filterSearchResult(); let i = index">
        <span *ngIf="checkForSeleceted(option)" class="check_for_44">
        </span>
        <span *ngIf="!checkForSeleceted(option)" class="check_for_46">
        </span>
        <ng-container *ngIf="title == 'VPC'">
          <vpc-option-launch-select-dropdown class="flex-1" [vpc]="option"></vpc-option-launch-select-dropdown>
        </ng-container>
        <ng-container *ngIf="title == 'Security Group'">
          <sg-option-launch-select-dropdown class="flex-1" [sg]="option"></sg-option-launch-select-dropdown>
        </ng-container>
        <ng-container *ngIf="title == 'Subnet'">
          <subnet-option-launch-select-dropdown class="flex-1" [subnet]="option">
          </subnet-option-launch-select-dropdown>
        </ng-container>
        <ng-container *ngIf="title == 'AMI'">
          <ami-option-launch-select-dropdown class="flex-1" [ami]="option"></ami-option-launch-select-dropdown>
        </ng-container>
        <ng-container *ngIf="title == 'Key Pair'">
          <keypair-option-launch-select-dropdown class="flex-1" [key]="option">
          </keypair-option-launch-select-dropdown>
        </ng-container>
        <ng-container *ngIf="title == 'Role'">
          <role-option-launch-select-dropdown class="flex-1" [role]="option">
          </role-option-launch-select-dropdown>
        </ng-container>
      </div>
    </div>
  </div>
</div>