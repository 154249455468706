import { Component, OnInit } from '@angular/core';
import { APIService } from 'src/app/api/api.service';
import { NotifierService } from 'src/app/_services/notifier.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bomv2-viewcrs',
  templateUrl: './viewcrs.component.html',
  styleUrls: ['./viewcrs.component.css'],
})
export class ViewcrsComponent implements OnInit {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  crs: any = [];
  loading: boolean = false;

  accountId: any;
  regionId: any;
  currentMessage: any;

  funRef: any = {};
  redirectCRID: any = [];

  constructor(
    private notifier: NotifierService,
    private apiService: APIService,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.router.queryParams.subscribe((res) => {
      if (res['cr_id']) {
        this.redirectCRID = res['cr_id'].split(',');
      }
    });
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.funRef = {};

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  selectedCR: any;
  async load() {
    this.loading = true;
    this.selectedCR = null;
    this.crs = [];
    let data = {
      action: 'list_change_requests',
      id_token: localStorage.getItem('idT'),
      account_id: this.accountId,
      region_id: this.regionId,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/operations/ec2`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      if (this.redirectCRID.length == 1) {
        let cr = result.crs.find((cr: any) => {
          return cr['cr_id'] == this.redirectCRID[0];
        });
        if (cr) {
          this.selectedCR = cr;
        }
      }
      this.crs = result.crs;
    } else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }

    this.loading = false;
  }
  crExists(cr_id: string) {
    return this.redirectCRID.indexOf(cr_id) > -1;
  }
}
