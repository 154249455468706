import { Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from '../../../../../api/api.service';
import { NotifierService } from '../../../../../_services/notifier.service';

@Component({
  selector: 'rulesets-uploadbom-v2',
  styleUrls: [
    './../../upload.bom.component.css',
    './rulesets.uploadbomv2.component.css',
  ],
  templateUrl: './rulesets.uploadbomv2.component.html',
})
export class RulesetsUploadBOMv2Component implements OnInit, OnDestroy {
  loading: boolean = true;
  regionId: any;
  accountId: any;
  currentMessage: any;
  rulesetsInterface: any = {};
  rulesetsOptions: string[] = [];
  selectedRuleSet: string = '';
  addedRulesetsOptions: any = [];
  constructor(
    private notifier: NotifierService,
    private apiService: APIService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
      }
    });
    this.fetchRuleSetInterface();
    this.loading = false;
  }

  getKeys(rule: any) {
    return Object.keys(this.rulesetsInterface[rule]);
  }

  addRuleSet() {
    if (this.addedRulesetsOptions.indexOf(this.selectedRuleSet) < 0) {
      this.addedRulesetsOptions.push(this.selectedRuleSet);
      this.selectedRuleSet = this.selectableRuleSet()[0];
    }
  }

  removeRuleSet(index: any) {
    this.addedRulesetsOptions.splice(index, 1);
    if (this.selectableRuleSet().length > 0) {
      this.selectedRuleSet = this.selectableRuleSet()[0];
    }
  }

  fetching: boolean = true;
  async fetchRuleSetInterface() {
    this.fetching = true;
    let data = {
      action: 'get_rule_set',
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/admin/bom/process`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1') {
      this.rulesetsInterface = result.rules_set;
      this.rulesetsOptions = Object.keys(this.rulesetsInterface);
      this.selectedRuleSet = this.rulesetsOptions[0];
    } else {
      this.notifier.alert('Error', '', result.error_message, 'error', 5000);
    }
    this.fetching = false;
    return true;
  }

  selectableRuleSet() {
    return this.rulesetsOptions.filter((rule: any) => {
      return this.addedRulesetsOptions.indexOf(rule) < 0;
    });
  }

  ngOnDestroy(): void {}
}
