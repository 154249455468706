<div class="card mt-20" style="border: none; position: relative; height: 100%;">
    <h5 class="card-header">Enable WR</h5>
    <div class="card-body">
      <div style="margin: 10px;">
        <label style="margin-right: 10px;">Master Account List</label>
        <select name="" id="masterAccountList" class="selectpricket" (change)="fetchChildAccounts()"
          data-live-search="true">
          <option [value]="master" *ngFor="let master of masterAccounts">{{master}}</option>
        </select>
      </div>
      <div style="margin: 10px;">
        <label style="margin-right: 10px;">Linked Account List</label>
        <select name="" id="linkedAccountList" multiple class="selectpricket" data-live-search="true">
          <option [value]="master.accountId" *ngFor="let master of linkedAccounts">{{master.accountId}}
            ({{master.accountName}})</option>
        </select>
      </div>
      <div style="margin: 10px;">
        <button class="btn btn-light" style="background-color: #5864FF; color: white;"
          (click)="submitAccounts()">Submit</button>
      </div>
    </div>
  </div>