import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { APIService } from 'src/app/api/api.service';

@Component({
  selector: 'app-pipeline',
  templateUrl: './pipeline.component.html',
  styleUrls: ['./pipeline.component.css'],
})
export class PipelineComponent implements OnInit, OnChanges {
  @Input('pipeline') pipeline: any;
  @Input('pipelineID') pipelineID: any;
  pipelineDetail: any = {};
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  loading: any = {
    pipeline: false,
  };

  selectedEnv: string;

  constructor(private apiServer: APIService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pipelineID']) {
      this.load();
    }
  }

  ngOnInit(): void {}

  load() {
    this.fetchPipeline();
  }

  timeSince(date: any) {
    let dest: any = new Date(date);
    let today: any = new Date();

    var seconds = Math.floor((today - dest) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + ' years';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' months';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' days';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' hours';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' minutes';
    }
    return Math.floor(seconds) + ' seconds';
  }

  getObjectKeys(obj: any) {
    return Object.keys(obj);
  }

  user_favicon(
    username: string,
    user_name_parsed_requrired: boolean = false
  ): any {
    var user_favicon_text = '';
    var user_names;
    var username_name_parsed = '';
    if (username == '') {
      return 'Anonymous';
    }
    username = username.trim();
    if (!username.includes('@')) {
      user_names = username.split(' ');
    } else {
      user_names = username.split('@')[0].split('.');
    }
    if (user_names.length > 0) {
      user_favicon_text += user_names[0][0].trim();
      username_name_parsed += user_names[0].trim();
      if (user_names.length > 1) {
        if (user_names[user_names.length - 1].length > 0) {
          user_favicon_text += user_names[user_names.length - 1][0].trim();
          username_name_parsed +=
            ' ' + user_names[user_names.length - 1].trim();
        }
      } else {
        user_favicon_text += ' ';
      }
    }
    if (user_name_parsed_requrired) {
      return {
        user_full_name: username_name_parsed.trim(),
        user_favicon_parsed: user_favicon_text.trim(),
      };
    }
    return user_favicon_text.toUpperCase();
  }

  async fetchPipeline() {
    let data = {
      action: 'pipeline_status',
      module_name: this.pipeline['repo_name'],
      id_token: localStorage.getItem('idT'),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${APIService.API_ENDPOINTV3}/pipeline/automation`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.pipelineDetail = result.pipelines;
      this.selectedEnv = Object.keys(this.pipelineDetail)[0];
    }
  }
}
